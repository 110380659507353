
module.exports = function (app) {
    require("./coverage/changeMyCoverage.js")(app);
    require("./coverage/bdc/bdc.js")(app);
    app.controller('pages.coverage', ["$scope", "session", "services", "teamsite", "brand", "displayOrHideItemsWrapper", "storageOld", "$state", "sessionData",
        function ($scope, session, services, teamsite, brand, displayOrHideItemsWrapper, storageOld, $state, sessionData) {

            $scope.headings = "Coverage";
            $scope.sidebar.links = [];
            $scope.sidebar.links.push({
                heading: 'Coverage Summary',
                label: 'Coverage Summary',
                href: '/mbr/#!/coverage/coverage-summary',
                description: '<span data-teamsite="MER_COVERAGE_SUMMARY_LEFTNAV_DESC"></span>'
            });

            //Centers of Excellence Sidebar Link
            var currentPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan(session.data.memberInfo);
            if (currentPlan.ccoeIndicator === true || currentPlan.ccoeIndicator === "true") {
                $scope.sidebar.links.push({
                    heading: "Centers of Excellence",
                    label: "Centers of Excellence",
                    state: "coverage.centersOfExcellence",
                    description: "High-quality and high-value care for your complex medical needs." //todo - put this in teamsite
                });
            }

            // My benefits - if BDC or BDC plus is true show blue distinction link for all other show the new my benefits page.
            if (session.data.bdcIndicator || session.data.bdcPlusIndicator) {
                $scope.sidebar.links.push({
                    heading: "Blue Distinction",
                    label: "Blue Distinction",
                    href: teamsite.get('MBR_COVERAGESUMMARY_LEFTNAV_MYBEN_HREF').then(function (v) {
                        return v.replace(/\{targetSite\}/g, brand.current.umsBrandCode || brand.current.sharedBrandCode);
                    }),
                    //state: 'coverage.bdc',
                    description: 'Know how your medical plan works so you can make the most of it. Explore the basics or learn all the details.'
                });
                $scope.sidebar.links.push({
                    heading: 'UNDERSTAND YOUR PLAN',
                    label: "Medical Benefits",
                    state: "coverage.medicalBenefits",
                    description: 'Know how your medical plan works so you can make the most of it. Explore the basics or learn all the details.'
                });
            }
            else if (brand.current.sharedBrandCode === "mincr") {
                $scope.sidebar.links.push({
                    heading: "Understand Your Plan",
                    label: "Medical Benefits",
                    state: "coverage.medicalBenefits",
                    description: '<span data-teamsite="MBR_COVERAGE_MEDICALBEN_UNDERSTAND_PLAN_CONTENT"></span>'
                });
            }
            else if (session.data.benefits.medical == false) {
                $scope.sidebar.links.push({
                    heading: "UNDERSTAND YOUR PLAN",
                    label: "Member Discounts",
                    state: "coverage.medicalBenefits",
                    description: 'Make the most of the valuable discounts and extras your plan offers.'
                });
            }
            else {
                $scope.sidebar.links.push({
                    heading: 'UNDERSTAND YOUR PLAN',
                    label: "Medical Benefits",
                    state: "coverage.medicalBenefits",
                    description: 'Know how your medical plan works so you can make the most of it. Explore the basics or learn all the details.'
                });
            }

            if (sessionData
                && sessionData.appConfig
                && sessionData.appConfig.COVERAGE
                && sessionData.appConfig.COVERAGE.BENEFIT_SEARCH_MAINTENANCE
                && sessionData.appConfig.COVERAGE.BENEFIT_SEARCH_MAINTENANCE.booleanValue === false) {

                $scope.sidebar.links.push({
                    heading: "Medical Benefit Search",
                    label: "Medical Benefit Search",
                    active: true,
                    description: "Quickly locate your benefit costs and coverage",
                    state: "coverage.benefitSearch"
                });
            }

            // Prescription Benefits:
            // a has RX
            // b  Not IBC, IBC National Alliance or amerihealth
            // c. rx claims is false
            // d. NOT blue extra basic & blue plus
            // e. NOT Blue Dental
            if (session.data.benefits.drug && brand.current.sharedBrandCode !== "inac" && brand.current.sharedBrandCode !== "ibccr" && brand.current.sharedBrandCode != "wybcbs" && brand.current.sharedBrandCode !== "ibcah" && !session.data.rxClaims && !session.data.isBlueExtraPlus && !session.data.isBlueExtraBasic && !session.data.blueEdgeDentalIndv) {
                if (session.data.benefits.drugCancelled) {
                    $scope.sidebar.links.push({
                        heading: '<span data-teamsite="MBR_COVERAGESUMMARY_LEFTNAV_PRESCRIPBEN"></span>',
                        disabled: true,
                        label: '<span data-teamsite="MBR_COVERAGESUMMARY_LEFTNAV_PRESCRIPBEN"></span>',
                        tooltip: teamsite.get('MER_COVERAGE_SUMMARY_PAST_COVERAGE_TOOLTIP'),
                        description: 'Know how your medical plan works so you can make the most of it. Explore the basics or learn all the details.'
                    });
                }
                else {
                    $scope.sidebar.links.push({
                        heading: '<span data-teamsite="MBR_COVERAGESUMMARY_LEFTNAV_PRESCRIPBEN"></span>',
                        label: '<span data-teamsite="MBR_COVERAGESUMMARY_LEFTNAV_PRESCRIPBEN"></span>',
                        href: teamsite.get('MBR_COVERAGESUMMARY_LEFTNAV_PRESCRIPBEN_HREF'),
                        description: 'Know how your medical plan works so you can make the most of it. Explore the basics or learn all the details.'
                    });
                }
            }
            //ND Medicaid Expansion changes
            if((brand.current.sharedBrandCode == "ndbcbs") && (currentPlan.isNDMedicaid)){
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_COVERAGESUMMARY_LEFTNAV_PRESCRIPBEN"></span>',
                    label: '<span data-teamsite="MBR_COVERAGESUMMARY_LEFTNAV_PRESCRIPBEN"></span>',
                    href: teamsite.get('MBR_COVERAGESUMMARY_LEFTNAV_PRESCRIPBEN_HREF'),
                    description: 'Know how your medical plan works so you can make the most of it. Explore the basics or learn all the details.'
                });
            }

            //Orthodontics Info
            if ((session.data.benefits.dental && brand.current.sharedBrandCode != "ibccr" && brand.current.sharedBrandCode != "ibcah" && brand.current.sharedBrandCode != "wybcbs" && brand.current.sharedBrandCode != "ndbcbs" && !session.data.isBlueExtraPlus && !session.data.isBlueExtraBasic && !session.data.blueEdgeDentalIndv)
                || (session.data.benefits.dental && (brand.current.sharedBrandCode == "ibccr" || brand.current.sharedBrandCode == "ibcah") && !session.data.isBlueExtraPlus && !session.data.isBlueExtraBasic && !session.data.directPayIndicator)) {
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_COVERAGESUMMARY_LEFTNAV_VIEWORTHOINFO"></span>',
                    label: '<span data-teamsite="MBR_COVERAGESUMMARY_LEFTNAV_VIEWORTHOINFO"></span>',
                    state: 'coverage.orthodontics',
                    description: 'Know how your medical plan works so you can make the most of it. Explore the basics or learn all the details.'
                });
            }

            // Vision
            // Show this link only if there is one or more vision plan and not BlueDental.
            if (session.data.benefits.vision && session.data.isBlueBrandedVision && !session.data.blueEdgeDentalIndv) {
                if (session.data.benefits.visionCancelled) {
                    $scope.sidebar.links.push({
                        heading: 'Vision Health & Wellness',
                        disabled: true,
                        label: '<span data-teamsite="MBR_COVERAGESUMMARY_LEFTNAV_VISIONHW"></span>',
                        tooltip: teamsite.get('MER_COVERAGE_SUMMARY_PAST_COVERAGE_TOOLTIP'),
                        description: 'Know how your medical plan works so you can make the most of it. Explore the basics or learn all the details.'
                    });
                }
                else {
                    $scope.sidebar.links.push({
                        heading: 'Vision Health & Wellness',
                        label: '<span data-teamsite="MBR_COVERAGESUMMARY_LEFTNAV_VISIONHW"></span>',
                        href: teamsite.get('MBR_COVERAGESUMMARY_LEFTNAV_VISIONHW_HREF'),
                        description: 'Know how your medical plan works so you can make the most of it. Explore the basics or learn all the details.'
                    });
                }
            }

            // Make Changes To Coverage :
            // If HcrProduct has a value (no matter what the value is) then show the this link .
            // If nBlueDental is false then show the link
            if (session.data.hcrProduct && !session.data.blueEdgeDentalIndv && session.data.directPayIndicator && session.data.isSubscriber && brand.current.sharedBrandCode !== 'ndbcbs') {
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_COVERAGESUMMARY_LEFTNAV_MAKECHANGESCOVERG"></span>',
                    label: '<span data-teamsite="MBR_COVERAGESUMMARY_LEFTNAV_MAKECHANGESCOVERG"></span>',
                    state: "coverage.changeMyCoverage",
                    description: 'Know how your medical plan works so you can make the most of it. Explore the basics or learn all the details.'
                });
            }

            var bShowViewOpenReferrals = false;
            if (session.data.isBlueExtraBasic || session.data.isBlueExtraPlus || session.data.blueEdgeDentalIndv) {
                bShowViewOpenReferrals = false;
            }
            else {
                if (session.data.benefits.medical && (brand.current.sharedBrandCode == 'ibcah' || brand.current.sharedBrandCode == 'ibccr')) {
                    if (session.data.lobs.hmo || session.data.lobs.pos) {
                        bShowViewOpenReferrals = true;
                    }
                    else {
                        bShowViewOpenReferrals = false;
                    }
                }
                else {
                    bShowViewOpenReferrals = false;
                }
            }

            if (!session.data.isBlueExtraBasic && !session.data.isBlueExtraPlus && !session.data.blueEdgeDentalIndv && session.data.benefits.medical && (brand.current.sharedBrandCode == 'ibcah' || brand.current.sharedBrandCode == 'ibccr')) {
                if (bShowViewOpenReferrals) {
                    $scope.sidebar.links.push({
                        heading: '<span data-teamsite="MBR_COVERAGESUMMARY_LEFTNAV_VIEWOPENREFER"></span>',
                        label: '<span data-teamsite="MBR_COVERAGESUMMARY_LEFTNAV_VIEWOPENREFER"></span>',
                        href: teamsite.get('MBR_COVERAGESUMMARY_LEFTNAV_VIEWOPENREFER_HREF'),
                        description: 'Know how your medical plan works so you can make the most of it. Explore the basics or learn all the details.'
                    });
                }
            }

            //hide  for AG only users
            if (session.data.benefits.medical || session.data.benefits.drug || session.data.benefits.vision || session.data.benefits.dental) {
                var pos = $scope.sidebar.links.push({
                    heading: "YOUR GUIDE TO HEALTH PLANS",
                    label: "Health Plans 101",
                    state: "coverage.healthInsurance101",
                    description: '<span data-teamsite="MBR_HEALTHINSURANCE101_LEFTNAV_PARAGRAPH"></span>'
                });
                if (brand.current.sharedBrandCode === "mincr" || brand.current.sharedBrandCode === "ndbcbs" || brand.current.sharedBrandCode === "wybcbs") {
                    $scope.sidebar.links[pos - 1].heading = "Health Plans 101";
                    $scope.sidebar.links[pos - 1].label = "Health Plans 101";
                }
            }

            var addSmileForHealthLink = function () {
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_COVERAGE_ORAL_HEALTH_LEFTNAV_HEADER"></span>',
                    label: '<span data-teamsite="MBR_COVERAGE_ORAL_HEALTH_LEFTNAV_HEADER"></span>',
                    state: "coverage.myOralHealth",
                    description: '<span data-teamsite="MBR_COVERAGE_ORAL_HEALTH_LEFTNAV_DESC"></span>'
                });
            };

            var smileForHealthConfig = (session.data && session.data.appConfig && session.data.appConfig.COVERAGE && session.data.appConfig.COVERAGE.SMILE_FOR_HEALTH) ? session.data.appConfig.COVERAGE.SMILE_FOR_HEALTH : null;
            var mdiUserOverrideArray = (smileForHealthConfig !== null && smileForHealthConfig.overrideValue && smileForHealthConfig.overrideValue.length > 0) ? smileForHealthConfig.overrideValue.split(',') : [];

            //  If SmileForHealth is Active in AppConfig OR the current user is setup as an override user.
            if (smileForHealthConfig !== null && (smileForHealthConfig.booleanValue === true || (mdiUserOverrideArray && mdiUserOverrideArray.indexOf(session.data.userName) >= 0))) {
                var callDisplayHide = displayOrHideItemsWrapper.displayOrHideItemsCall(session.data.memberInfo.clientList[0].clientNumber, session.data.groupNumber, ["smileForHealthIndicator"], brand.current.sharedBrandCode);
                callDisplayHide.then(function (data) {
                    if (data && data.qualifierInfo && data.qualifierInfo.smileForHealthIndicator === 'true') {
                        addSmileForHealthLink();
                    }
                });
            }
        }
    ]);

    return [
        {
            state: "coverage",
            postPassword: true,
            appConfigMaintenanceApp: 'COVERAGE',
            appConfigMaintenanceKey: 'FULL_MAINTENANCE',
            url: "/coverage",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: "pages.coverage"
                },
                "content@coverage": {
                    template: require('pages/coverage/medical-benefits.html'),
                    controller: "pages.coverage.medicalBenefits"
                }
            }
        },
        { //Dojo-to-Angular: link this to the side-bar links
            state: "coverage.coverageSummary",
            postPassword: true,
            appConfigMaintenanceApp: 'COVERAGE',
            appConfigMaintenanceKey: 'COVERAGE_SUMMARY_MAINTENANCE',
            url: "/coverage-summary",
            views: {
                "content@coverage": {
                    template: require('pages/coverage/coverage-summary.html'),
                    controller: "pages.coverage.coverageSummary"
                }
            }
        },
        {
            state: "coverage.medicalBenefits",
            postPassword: true,
            appConfigMaintenanceApp: 'COVERAGE',
            appConfigMaintenanceKey: 'MEDICAL_BENEFITS_MAINTENANCE',
            url: "/medical-benefits",
            views: {
                "content@coverage": {
                    template: require('pages/coverage/medical-benefits.html'),
                    controller: "pages.coverage.medicalBenefits"
                }
            }
        },
        {
            state: "coverage.benefitSearch",
            url: "/benefit-search",
            appConfigMaintenanceApp: 'COVERAGE',
            appConfigMaintenanceKey: 'BENEFIT_SEARCH_MAINTENANCE',
            postPassword: true,
            resolve: {
                synonymsList: ['benefitSearchService', function (benefitSearchService) {
                    return benefitSearchService.findAllBenefits();
                }]

            },
            views: {
                "content@coverage": {
                    template: require('pages/coverage/benefitSearch.html'),
                    controller: "pages.coverage.benefitSearch"
                }
            }
        },
        {
            state: "coverage.healthInsurance101",
            postPassword: true,
            appConfigMaintenanceApp: 'COVERAGE',
            appConfigMaintenanceKey: 'HEALTHINSURANCE101_MAINTENANCE',
            url: "/health-insurance-101",
            resolve: {
                resourcesList: ["contentResourceServices", function (contentResourceServices) {
                    var CONTENT_TYPE = "Bluecore Resources";
                    return contentResourceServices.getResourceIdListAsync(CONTENT_TYPE);
                }]
            },
            views: {
                "content@coverage": {
                    template: require('pages/coverage/health-insurance-101.html'),
                    controller: "pages.coverage.healthInsurance101"
                }
            }
        },
        {
            state: "coverage.changeMyCoverage",
            postPassword: true,
            appConfigMaintenanceApp: 'COVERAGE',
            appConfigMaintenanceKey: 'MAKE_CHANGES_MAINTENANCE',
            url: "/change-my-coverage",
            views: {
                "content@coverage": {
                    template: require('pages/coverage/change-my-coverage.html'),
                    controller: "pages.coverage.changeMyCoverage"
                }
            }
        },
        {
            state: "coverage.myOralHealth",
            postPassword: true,
            appConfigMaintenanceApp: 'COVERAGE',
            appConfigMaintenanceKey: 'MY_ORAL_HEALTH_MAINTENANCE',
            url: "/my-oral-health",
            views: {
                "content@coverage": {
                    template: require('pages/coverage/myOralHealth.html'),
                    controller: "pages.coverage.myOralHealth"
                }
            }
        },
        {

            state: "coverage.centersOfExcellence",
            postPassword: true,
            appConfigMaintenanceApp: "COVERAGE",
            appConfigMaintenanceKey: "COE_MAINTENANCE",
            url: "/centers-of-excellence",
            views: {
                "content@coverage": {
                    template: require("pages/coverage/centers-of-excellence.html"),
                    controller: "pages.coverage.centersOfExcellence"
                }
            }
        },

        {
            state: "coverage.orthodontics",
            postPassword: true,
            url: "/orthodontics",
            views: {
                "content@coverage": {
                    template: require('pages/coverage/orthodontics.html')

                }
            }
        },
        {
            state: "coverage.bdc",
            postPassword: true,
            url: "/bdc",
            views: {
                "content@coverage": {
                    template: require('pages/coverage/bdc/bdc.html'),
                    controller: 'bdcController'
                },
                "rightColumn@coverage": {
                    template: require('pages/coverage/bdc/bdcRightColumn.html'),
                    controller: 'bdcController'
                }
            }
        }

    ];
};