/**
 * Created by lidrcux on 7/30/2018.
 */
module.exports = function(app) {
    var secondTimeAroundFlag;//medicalCoverage is run twice. Its own template is calling it as a controller, which was probably originally an oversight. Problem is, is that if it only gets run once, the CSG fails to load, probably due to a myriad of async issues. When it runs through the 2nd time, the part of the grid with the *'s will fail in users such as victor1001, so I added this flag to cut the 2nd time around from happening for that part of the grid.
        app
        .controller('medCovCtrl', ['$scope','$cookies','$compile', 'services', 'session', 'teamsite','finder-logic2','coremetrics','modal','sessionData','brand','$sce',
            function($scope,cookies,$compile, services, session, teamsite,finderLogic,coremetrics,modal,sessionData, brand, $sce) {

                $scope.covType = "Medical";
                bShowSiteByPlanIfNeeded = true;
                $scope.medDataShow = true;
                $scope.bPlanDatesValid = true;

                $scope.isPlanCancelled = function (currentProd) {
                    var bRetVal = false;
                    var nMemberIdx = 0;
                    var mbr = currentProd.memberList[nMemberIdx];
                    if (mbr.coverageCancellationDate != '')
                        bRetVal = ((new Date(mbr.coverageCancellationDate)) < (new Date()));

                    return bRetVal;
                };


                var hasCoverage = function (curGrp, strPlan) {
                    var bFound = false;
                    for (iPrdIdx = 0; iPrdIdx < curGrp.productList.length; ++iPrdIdx) {
                        var curPrd = curGrp.productList[iPrdIdx];
                        if (curPrd.benefitPlanType === strPlan) {
                            bFound = true;
                            break;
                        }
                    }
                    return bFound;
                };

                var checkDupl;



                services.rbsmbl.memberInfo().fetch().then(function (fullMemberInfo) {

                    finderLogic.setFullMemberInfo(fullMemberInfo.data.payload);
                    //var theDomain = finderLogic.theDomain();
                    var ums = fullMemberInfo.data.payload;
                    //var nClntIdx = 0;
                    var curClnt = ums.clientList[0];
                    var curGrp = finderLogic.getPortalSelectedPlan(fullMemberInfo);
                    var curPrd = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical");


                    //$scope.umi = ums.umi;
                    //$scope.clientNumber = (curClnt && curClnt.clientNumber) ? curClnt.clientNumber : "0";
                    //$scope.groupNumber = (curClnt.groupList[0] && curClnt.groupList[0].groupNumber) ? curClnt.groupList[0].groupNumber : "0";

                    if ((cookies.get('mbrGroupNumber') || '') != '') {
                        for (iClntIdx = 0; iClntIdx < ums.clientList.length; ++iClntIdx) {
                            var tmpClnt = ums.clientList[iClntIdx];
                            for (iGrpIdx = 0; iGrpIdx < tmpClnt.groupList.length; ++iGrpIdx) {
                                if (tmpClnt.groupList[iGrpIdx].groupNumber == cookies.get('mbrGroupNumber')) {
                                    curClnt = tmpClnt;
                                    curGrp = tmpClnt.groupList[iGrpIdx];
                                    for (iPrdIdx = 0; iPrdIdx < curGrp.productList.length; ++iPrdIdx) {
                                        var tmpPrd = curGrp.productList[iPrdIdx];
                                        if (tmpPrd.benefitPlanType === "Medical") {
                                            curPrd = tmpPrd;

                                            var setBenefitBeginDate = function (product) {
                                                if (product.benefitAccumulationStartDate !== ""
                                                    && product.benefitAccumulationStartDate != product.coverageBeginDate) {
                                                    return product.benefitAccumulationStartDate;
                                                }
                                                return product.coverageBeginDate;
                                            };
                                            var setBenefitEndDate = function (product) {
                                                if (product.benefitAccumulationEndDate !== ""
                                                    && product.benefitAccumulationEndDate != product.coverageEndDate) {
                                                    return product.benefitAccumulationEndDate;
                                                }
                                                return product.coverageEndDate;
                                            };

                                            $scope.myCoverageBeginDate = setBenefitBeginDate(curPrd);
                                            var coverageConBeginDate = curPrd.coverageConBeginDate;
                                            $scope.myCoverageEndDate = setBenefitEndDate(curPrd);



                                            if (((coverageConBeginDate || '') == '')
                                                || (($scope.myCoverageEndDate || '') == '')
                                                || (($scope.myCoverageBeginDate || '') == '')) {
                                                return $scope.bPlanDatesValid = false;
                                            }
                                            var dtToday = new Date();
                                                dtStartConDate = new Date(coverageConBeginDate);

                                            if (dtStartConDate > dtToday) {
                                                 $scope.bPlanDatesValid = false;
                                            }
                                            $scope.bCancelled = false;
                                            $scope.bCancelled = $scope.isPlanCancelled(curPrd);

                                            $scope.bMultiGroup =(bShowSiteByPlanIfNeeded && (curClnt.groupList.length > 1));

                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (curPrd != null) {
                        $scope.bMedicalExists = true;

                        //Benefit Booklet

                        //Cost Share Grid
                        teamsite.get('MER_COVERAGE_SUMMARY_CSGRID_PRIMARY_CARE_VISIT_HDR').then(function(response){
                            $scope.PRIMARY_CARE_VISIT_HDR = response;
                            return $scope.PRIMARY_CARE_VISIT_HDR;
                        });

                        teamsite.get('MER_COVERAGE_SUMMARY_CSGRID_SPECIALIST_VISIT_HDR').then(function(response){
                            $scope.SPECIALIST_VISIT_HDR = response;
                            return $scope.SPECIALIST_VISIT_HDR;
                        });

                        teamsite.get('MER_COVERAGE_SUMMARY_CSGRID_EMERGENCY_VISIT_HDR').then(function (response){
                            $scope.EMERGENCY_VISIT_HDR = response;
                            return $scope.EMERGENCY_VISIT_HDR;
                        });

                        teamsite.get('MER_COVERAGE_SUMMARY_CSGRID_COINSURANCE_HDR ').then(function(response){
                            $scope.COINSURANCE_HDR = response;
                            return $scope.COINSURANCE_HDR;
                        });

                        teamsite.get('MER_COVERAGE_SUMMARY_CSGRID_IN_NETWORK_HDR').then(function(response){
                            $scope.IN_NETWORK_HDR = response;
                            return $scope.IN_NETWORK_HDR;
                        });

                        teamsite.get('MER_COVERAGE_SUMMARY_CSGRID_OUT_OF_NETWORK_HDR').then(function(response){
                            $scope.OUT_OF_NETWORK_HDR = response;
                            return $scope.OUT_OF_NETWORK_HDR;
                        });

                        teamsite.get('MER_COVERAGE_SUMMARY_CSGRID_PREFERRED_VALUE_HDR').then(function(response){
                            $scope.PREFERRED_VALUE_HDR = response;
                            return $scope.PREFERRED_VALUE_HDR;
                        });

                        teamsite.get('MER_COVERAGE_SUMMARY_CSGRID_HOMEHOST_VALUE_HDR').then(function(response){
                            $scope.HOMEHOST_VALUE_HDR = response;
                            return $scope.HOMEHOST_VALUE_HDR;
                        });
                        teamsite.get('MER_COVERAGE_SUMMARY_CSGRID_HOMEHOST_REGION_VALUE_HDR').then(function(response){
                            $scope.HOMEHOST_REGION_VALUE_HDR = response;
                            return $scope.HOMEHOST_REGION_VALUE_HDR;
                        });

                        teamsite.get('MER_COVERAGE_SUMMARY_CSGRID_ENHANCED_VALUE_HDR').then(function(response){
                            $scope.ENHANCED_VALUE_HDR = response;
                            return $scope.ENHANCED_VALUE_HDR;
                        });

                        teamsite.get('MER_COVERAGE_SUMMARY_CSGRID_HOMEHOST_REGION_VALUE2_HDR').then(function(response){
                            $scope.HOMEHOST_REGION_VALUE2_HDR = response;
                            return $scope.HOMEHOST_REGION_VALUE2_HDR;
                        });

                        teamsite.get('MER_COVERAGE_SUMMARY_CSGRID_STANDARD_VALUE_HDR').then(function(response){
                            $scope.STANDARD_VALUE_HDR = response;
                            return $scope.STANDARD_VALUE_HDR;
                        });


                        teamsite.get('MER_COVERAGE_SUMMARY_CSGRID_HOMEHOST_REGION_VALUE3_HDR').then(function(response){
                            $scope.HOMEHOST_REGION_VALUE3_HDR = response;
                            return $scope.HOMEHOST_REGION_VALUE3_HDR;
                        });

                        var csbParams = {
                            brand: ums.brand
                        };
                        csbParams.payload = {
                            // TODO : Set Proper values here from curGrp and curPrd
                            "startDate": curPrd.coverageBeginDate.split("/").join(""), // "05012015", //TheDate.formatDate(curPrd.coverageBeginDate, "MMddyyyy") , // "05012015",  // curPrd.coverageBeginDate,  // 05/01/2014
                            "endDate": curPrd.coverageEndDate.split("/").join(""), // "04302016", //TheDate.formatDate(curPrd.coverageEndDate, "MMddyyyy") , // "04302016",   // curPrd.coverageEndDate   // 04/30/2015
                            "groupNum": curGrp.groupNumber,
                            "lob": "M",
                            "productId": curPrd.productNumber,
                            "memberPin": curPrd.memberList[0].pinNumber,
                            //PEIP
                            "indCoverageLevel":"",
                            "famCoverageLevel": ""
                        };
                        var dtDate = new Date();
                        var dateToday = new Date();
                        if ( (curPrd.coverageBeginDate < dtToday) && (curPrd.coverageEndDate > dateToday) )
                            dtDate = dateToday ;
                        else if (curPrd.coverageBeginDate > dateToday )
                            dtDateToUse = curPrd.coverageBeginDate ;
                        else if (curPrd.coverageEndDate < dateToday)
                            dtDate = curPrd.coverageEndDate ;

                        var dateISOString = dtDate.toISOString();

                        var dtDateToUse = dateISOString.slice(0,10);


                        var costLevelParams = {
                            "payload": {  // TODO : Set Proper values here from curGrp and curPrd
                                "fromDate": dtDateToUse,  // "05012014",  // curPrd.coverageBeginDate,  // 05/01/2014
                                "toDate": dtDateToUse, // "04302015",   // curPrd.coverageEndDate   // 04/30/2015
                                "groupNumber": curGrp.groupNumber,
                                "productLineCode": "M",
                                "agreementId": ums.agreementId.slice(0,-1),//"L56854744",
                                "pin": curPrd.memberList[0].pinNumber
                            }
                        };
                        $scope.bShowEntireGrid = (((curPrd.lob == "PPO") || (curPrd.lob == "POS") || (curPrd.lob == "HMO") || (curGrp.atLeastOneEpoExistsIndicator == "true")) && ((ums.brand.toLowerCase() != "ibc") && (ums.brand.toLowerCase() != "ibccr") && (ums.brand.toLowerCase() != "ibcah")) );
                        services.rbsmbl.memberCostLevel().fetch(costLevelParams).then(function (response) {
                            if ((response != null) && (response.data != null) && (response.data.MemberCostLevelCodeResponse != null) && (response.data.MemberCostLevelCodeResponse.baseMessage != null) && (response.data.MemberCostLevelCodeResponse.baseMessage.returnCode != 404)) {

                                csbParams.payload.indCoverageLevel = response.data.MemberCostLevelCodeResponse.payload.memberCostLevel.costLevelCode;
                                csbParams.payload.famCoverageLevel = response.data.MemberCostLevelCodeResponse.payload.familyMemberCostLevel.costLevelCode;
                                $scope.bShowMnPeipGrid = true;

                            }
                            else{
                                $scope.bShowMnPeipGrid = false;
                            }
                            var costShareGridFunction = function () {
                            services.rbsmbl.costShareBenefit().fetch(csbParams).then(function (csbResp) {

                                //var csbResp = JSON.parse(csbResp.data.payload.replace("/:,/g", ": 0,"));
                                if (csbParams.payload.indCoverageLevel == '' && csbParams.payload.famCoverageLevel == '') {
                                    // Normal style user a.k.a. not minCR - Nov 2017 changes
                                    if (csbResp && csbResp.data.payload && csbResp.data.payload.coverageDetails) {
                                        // Set up the Cost Share Grid. //ERC was replaced with EMS

                                        // HardCodings here, need to be updated
                                        //var arrCOIAllowedRollupNetworkNames = ['Preferred In-Network', 'Enhanced-In-Network ', 'Enhanced-In-Network', 'Standard-In-Network', 'In Network'];
                                        var arrCSB = csbResp.data.payload.coverageDetails;
                                        var arrIgnoredRollupNetworkNames = ["Product Wide", "Product Wide ", "In and Out of Network Combined"];
                                        var nPVCol = 0, nEVCol = 0, nSVCol = 0, nOONCol = 0;  // made ints instead of bools since I need to count how many are visible.
                                        var bIndividual = false, bFamily = false;

                                        $scope.CSGHtmlHere = "";

                                        //Checks if indiviual breakpoint is missing.
                                        function isMissingIndividualBreakpoint(csbResp) {
                                            var summary = csbResp.data.payload.coverageSummary;
                                            var details = csbResp.data.payload.coverageDetails;
                                            var individualBKPIsMissing = false;

                                            if ((typeof(summary.accumulations.breakpointF) !== 'undefined' ||
                                                typeof(summary.accumulations.breakpointI) !== 'undefined') &&
                                                summary.accumulations.familyOrIndividual === "F") {

                                                individualBKPIsMissing = true;

                                                details.forEach(function (detail) {
                                                    if (detail.programName === 'BKP' && detail.programDescription === "BREAKPOINT INDIVIDUAL") {
                                                        individualBKPIsMissing = false;
                                                    }
                                                });
                                            }

                                            return individualBKPIsMissing;
                                        }

                                        if (isMissingIndividualBreakpoint(csbResp)) {
                                            arrCSB.push({
                                                accums: "NO",
                                                benefitValue: csbResp.data.payload.coverageSummary.accumulations.breakpointI,
                                                combined: "NO",
                                                netPresOrder: "2",
                                                networkName: "In Network",
                                                programDescription: "BREAKPOINT INDIVIDUAL",
                                                programName: "BKP",
                                                rollupNetworkName: "In Network"
                                            });
                                        }

                                        arrCSB.forEach(function (curCSB) {
                                            if (arrIgnoredRollupNetworkNames.indexOf(curCSB.rollupNetworkName) == -1) {
                                                if ((parseFloat(curCSB.benefitValue || '') >= 0) || (curCSB.programName == "COI")) {
                                                    if ((curCSB.programDescription.indexOf(" FAMILY") > 0) || (curCSB.programDescription.indexOf(" TWO PARTY") > 0))
                                                        bFamily = true;
                                                    if (curCSB.programDescription.indexOf(" INDIVIDUAL") > 0)
                                                        bIndividual = true;
                                                }
                                            }
                                        });

                                        var arrRowOrder = {};

                                        arrRowOrder.PDD = {
                                            "order": 1,
                                            "unit": "$",
                                            "checkDuplVals": false,
                                            "height": (bFamily ? 74 : 56)
                                        };
                                        arrRowOrder.PHS = {
                                            "order": 2,
                                            "unit": "%",
                                            "checkDuplVals": false,
                                            "height": 56
                                        };
                                        arrRowOrder.PHY = {
                                            "order": 2,
                                            "unit": "$",
                                            "checkDuplVals": false,
                                            "height": 56
                                        };
                                        arrRowOrder.PCP = {
                                            "order": 2,
                                            "unit": "$",
                                            "checkDuplVals": false,
                                            "height": 56
                                        };
                                        arrRowOrder.PCS = {
                                            "order": 2,
                                            "unit": "%",
                                            "checkDuplVals": false,
                                            "height": 56
                                        };
                                        arrRowOrder.SPC = {
                                            "order": 3,
                                            "unit": "$",
                                            "checkDuplVals": false,
                                            "height": 56
                                        };
                                        arrRowOrder.SPS = {
                                            "order": 3,
                                            "unit": "%",
                                            "checkDuplVals": false,
                                            "height": 56
                                        };
                                        arrRowOrder.ERS = {
                                            "order": 4,
                                            "unit": "%",
                                            "checkDuplVals": true,
                                            "height": 56
                                        }; // CR00602068
                                        arrRowOrder.ERM = {
                                            "order": 4,
                                            "unit": "$",
                                            "checkDuplVals": true,
                                            "height": 56
                                        };
                                        arrRowOrder.EAS = {
                                            "order": 4,
                                            "unit": "%",
                                            "checkDuplVals": true,
                                            "height": 56
                                        }; //CR00602068
                                        arrRowOrder.ERA = {
                                            "order": 4,
                                            "unit": "$",
                                            "checkDuplVals": true,
                                            "height": 56
                                        };
                                        arrRowOrder.EMR = {
                                            "order": 4,
                                            "unit": "$",
                                            "checkDuplVals": true,
                                            "height": 56
                                        };
                                        arrRowOrder.EMS = {
                                            "order": 4,
                                            "unit": "%",
                                            "checkDuplVals": true,
                                            "height": 56
                                        };
                                        arrRowOrder.COI = {
                                            "order": 5,
                                            "unit": "%",
                                            "checkDuplVals": false,
                                            "height": 56
                                        };
                                        arrRowOrder.BKPI = {
                                            "order": 6,
                                            "unit": "$",
                                            "checkDuplVals": false,
                                            "height": 70
                                        };
                                        arrRowOrder.BKPF = {
                                            "order": 7,
                                            "unit": "$",
                                            "checkDuplVals": false,
                                            "height": 70
                                        };
                                        arrRowOrder.OOP = {
                                            "order": 8,
                                            "unit": "$",
                                            "checkDuplVals": false,
                                            "height": 88
                                        };
                                        arrRowOrder.TMOOP = {
                                            "order": 9,
                                            "unit": "$",
                                            "checkDuplVals": false,
                                            "height": 115
                                        };

                                        var arrPivot = [];

                                        arrCSB.forEach(function (curCSB) {

                                            if (curCSB.programName === "BKP") {
                                                if (curCSB.programDescription === "BREAKPOINT FAMILY") {
                                                    curCSB.programName = "BKPF";
                                                }
                                                else {
                                                    curCSB.programName = "BKPI";
                                                }
                                            }


                                            if (arrIgnoredRollupNetworkNames.indexOf(curCSB.rollupNetworkName) == -1) {
                                                if ((parseFloat(curCSB.benefitValue || '') >= 0) || (curCSB.programName == "COI")) {
                                                    var strRowIdx = arrRowOrder[curCSB.programName].order;
                                                    // CR00574527, Pcmh-Copay should be displayed as BDTC
                                                    curCSB.m = curCSB.programDescription.replace(" PCMH COPAY", " BDTC");
                                                    if (!arrPivot[strRowIdx]) arrPivot[strRowIdx] = []; // Create a new starter array, this will be an empty array for now.
                                                    var objCell = {
                                                        "benefitValue": parseFloat(curCSB.benefitValue),
                                                        "networkName": curCSB.programDescription.split(" ").pop().toLowerCase(),
                                                        "unit": arrRowOrder[curCSB.programName].unit,
                                                        "checkDuplVals": arrRowOrder[curCSB.programName].checkDuplVals,
                                                        "height": arrRowOrder[curCSB.programName].height
                                                    };

                                                    // HardCodings here, need to be updated
                                                    var nColIdx = 0;
                                                    switch (curCSB.rollupNetworkName) {
                                                        case "Preferred-In-Network":
                                                            nPVCol = 1;
                                                            nColIdx = 0;
                                                            break;
                                                        case "Preferred-In-Network ":
                                                            nPVCol = 1;
                                                            nColIdx = 0;
                                                            break;
                                                        case "Preferred In-Network":
                                                            nPVCol = 1;
                                                            nColIdx = 0;
                                                            break;
                                                        case "Preferred In-Network ":
                                                            nPVCol = 1;
                                                            nColIdx = 0;
                                                            break;
                                                        case "Enhanced-In-Network":
                                                            nEVCol = 1;
                                                            nColIdx = 1;
                                                            break;
                                                        case "Enhanced-In-Network ":
                                                            nEVCol = 1;
                                                            nColIdx = 1;
                                                            break;
                                                        case "Enhanced In-Network":
                                                            nEVCol = 1;
                                                            nColIdx = 1;
                                                            break;
                                                        case "Enhanced In-Network ":
                                                            nEVCol = 1;
                                                            nColIdx = 1;
                                                            break;
                                                        case "Standard-In-Network":
                                                            nSVCol = 1;
                                                            nColIdx = 2;
                                                            break;
                                                        case "Standard-In-Network ":
                                                            nSVCol = 1;
                                                            nColIdx = 2;
                                                            break;
                                                        case "Standard In-Network":
                                                            nSVCol = 1;
                                                            nColIdx = 2;
                                                            break;
                                                        case "Standard In-Network ":
                                                            nSVCol = 1;
                                                            nColIdx = 2;
                                                            break;
                                                        case "In Network":
                                                            nPVCol = 1;
                                                            nColIdx = 0;
                                                            break;
                                                        case "Out of Network":
                                                            nOONCol = 1;
                                                            nColIdx = 3;
                                                            break;
                                                    }

                                                    if ((arrPivot[strRowIdx][nColIdx] == null) || (arrPivot[strRowIdx][nColIdx].benefitValue == 0))
                                                        arrPivot[strRowIdx][nColIdx] = objCell;
                                                    else if ((strRowIdx == 2) && (arrPivot[strRowIdx][nColIdx].networkName == objCell.networkName)) {
                                                        if (objCell.programDescription) {
                                                            if (objCell.programDescription.indexOf(" PCMH ") > 0)
                                                                arrPivot[strRowIdx][nColIdx] = objCell;
                                                            else if ((arrPivot[strRowIdx][nColIdx].benefitValue > objCell.benefitValue) && (arrPivot[strRowIdx][nColIdx].programDescription.indexOf(" PCMH ") == -1))
                                                                arrPivot[strRowIdx][nColIdx] = objCell;
                                                        }
                                                        else {
                                                            //  chk programDesc is null .
                                                            // console.log('objCell.programDescription is null, objCell is ', objCell);
                                                        }
                                                    }
                                                    else if ((strRowIdx <= 3) && (arrPivot[strRowIdx][nColIdx].networkName == objCell.networkName) && (arrPivot[strRowIdx][nColIdx].benefitValue > objCell.benefitValue))
                                                        arrPivot[strRowIdx][nColIdx] = objCell;
                                                    else if ((strRowIdx == 4) && (arrPivot[strRowIdx][nColIdx].networkName == objCell.networkName) && (arrPivot[strRowIdx][nColIdx].benefitValue < objCell.benefitValue))
                                                        arrPivot[strRowIdx][nColIdx] = objCell;
                                                    else if ((arrPivot[strRowIdx][nColIdx].unit == "%") && (objCell.unit == "$"))
                                                        arrPivot[strRowIdx][nColIdx] = objCell;
                                                }
                                            }
                                        });


                                        //added logic for EPO/HMO/IPA
                                        $scope.bShowEPOLine = ((curGrp.atLeastOneEpoExistsIndicator == "true") || ((curPrd.lob == "HMO") && (curPrd.alphaPrefix != "JOL") && (curPrd.alphaPrefix != "JOF")) || (curPrd.lob == "PDG"));
                                        if ($scope.bShowEPOLine) {
                                            nOONCol = 0;
                                        }

                                        //adding conditional messages above the grid.
                                        $scope.bIsHomeHost = (csbResp.data.payload.coverageSummary.isHomeHost === true) || (csbResp.data.payload.coverageSummary.isHomeHost === 'true');
                                        var isHomeHost = $scope.bIsHomeHost;

                                        $scope.bShowNO_TIERS_HOMEHOST_EPO_ONLY = ((isHomeHost) && (curGrp.atLeastOneEpoExistsIndicator == "true") && (csbResp.data.payload.coverageSummary.numberOfTiers <= 2) && (ums.brand.toLowerCase() != 'ms'));
                                        $scope.bShowTIERED_HOMEHOST_EPO_ONLY = ((isHomeHost) && (curGrp.atLeastOneEpoExistsIndicator == "true") && (csbResp.data.payload.coverageSummary.numberOfTiers >= 3) && (ums.brand.toLowerCase() != 'ms'));
                                        $scope.bShowNO_TIERS_HOMEHOST_PPO_ONLY = ((isHomeHost) && (curGrp.atLeastOneEpoExistsIndicator != "true") && (curPrd.lob == "PPO") && (csbResp.data.payload.coverageSummary.numberOfTiers <= 2) && (ums.brand.toLowerCase() != 'ms'));
                                        $scope.bShowTIERED_HOMEHOST_PPO_ONLY = ((isHomeHost) && (curGrp.atLeastOneEpoExistsIndicator != "true") && (curPrd.lob == "PPO") && (csbResp.data.payload.coverageSummary.numberOfTiers >= 3) && (ums.brand.toLowerCase() != 'ms'));

                                        $scope.bShow_HOMEHOST_WV_PPO_ONLY = ((isHomeHost) && (curGrp.atLeastOneEpoExistsIndicator != "true") && (curPrd.lob == "PPO") && (ums.brand.toLowerCase() == 'ms'));

                                        $scope.bShowIN_OUTNETWORK_PPOLine = (((nPVCol == 1) && (nSVCol == 0) && (nEVCol == 0) && (nOONCol == 1)) && (curPrd.lob == "PPO"));
                                        $scope.bShowIN_NETWORKONLY_IPA_HMO_EPOLine = (((nPVCol == 1) && (nSVCol == 0) && (nEVCol == 0) && (nOONCol == 0)) && ((curPrd.lob == "IPA") || (curGrp.atLeastOneEpoExistsIndicator == "true") || ((curPrd.lob == "HMO") && ((curPrd.alphaPrefix != "JOL") || (curPrd.alphaPrefix != "JOF"))) ));

                                        $scope.bShowIN_OUTNETWORK_HMOPOS = (((nPVCol == 1) && (nSVCol == 0) && (nEVCol == 0) && (nOONCol == 1)) && ((curPrd.lob == "HMO") && ((curPrd.alphaPrefix == "JOL") || (curPrd.alphaPrefix == "JOF"))));

                                        $scope.bShow2IN_OUTNETWORK_PPOLINE = (((nSVCol == 1) && (nEVCol == 1) && (nPVCol == 0) && (nOONCol == 1)) && (curPrd.lob == "PPO"));
                                        $scope.bShow2IN_NOOUTOFNETWORK_EPOLINE = (((nSVCol == 1) && (nEVCol == 1) && (nPVCol == 0) && (nOONCol == 0)) && (curGrp.atLeastOneEpoExistsIndicator == "true"));

                                        $scope.bShow3IN_OUTNETWORK_PPOLINE = (((nSVCol == 1) && (nEVCol == 1) && (nPVCol == 1) && (nOONCol == 1)) && (curPrd.lob == "PPO"));
                                        $scope.bShow3IN_NOOUTOFNETWORK_EPOLINE = (((nSVCol == 1) && (nEVCol == 1) && (nPVCol == 1) && (nOONCol == 0)) && (curGrp.atLeastOneEpoExistsIndicator == "true"));

                                        if (nPVCol + nEVCol + nSVCol + nOONCol > 0) {

                                            // We have some CSG to display
                                            $scope.CSGHtmlHere = "<div class='leftCol_Grid colshadow colshadow'><table>";
                                            if (nPVCol + nEVCol + nSVCol <= 1) $scope.CSGHtmlHere += "<tr><td class='thickpadd  forSwipe_thin' ></td>";
                                            if (nPVCol + nEVCol + nSVCol > 1) $scope.CSGHtmlHere += "<tr><td class='thickpadd   forSwipe_thick'></div>";
                                            if (arrPivot[1]) $scope.CSGHtmlHere += "<tr height=" + (bFamily ? "74px" : "56px") + "><td  class='CSG_C0_D'>" + (bFamily ? "Family " : "") + "Deductible</td></tr>";
                                            if (arrPivot[2]) $scope.CSGHtmlHere += "<tr height='56px'><td class='" + ((arrPivot[2] == null) && (arrPivot[3] == null) && (arrPivot[4] != null) ? "CSG_C1_P" : "CSG_C0_P") + "'" + "> " + $scope.PRIMARY_CARE_VISIT_HDR + " </td></tr>";
                                            if (arrPivot[3]) $scope.CSGHtmlHere += "<tr height='56px'><td  class='CSG_C0_S'>" + $scope.SPECIALIST_VISIT_HDR + "</td></tr>";
                                            if (arrPivot[4]) $scope.CSGHtmlHere += "<tr height='56px'><td  class='CSG_C0_E'>" + $scope.EMERGENCY_VISIT_HDR + "</td></tr>";
                                            if (arrPivot[5]) $scope.CSGHtmlHere += "<tr height='56px'><td  class='CSG_C0_C'>" + $scope.COINSURANCE_HDR + "</td></tr>";
                                            if (arrPivot[6]) $scope.CSGHtmlHere += "<tr height='70px'><td  class='CSG_C0_B'>" + "Individual Breakpoint</td></tr>";
                                            if (arrPivot[7]) $scope.CSGHtmlHere += "<tr height='70px'><td  class='CSG_C0_B'>" + "Family Breakpoint</td></tr>";
                                            if (arrPivot[8]) $scope.CSGHtmlHere += "<tr height='88px'><td  class='CSG_C0_T'>" + (bFamily ? "Family " : "") + "Out-of-Pocket Maximum</td></tr>";
                                            if (arrPivot[9]) $scope.CSGHtmlHere += "<tr height='115px'><td  class='CSG_C0_M'>" + (bFamily ? "Family " : "") + "Total Maximum Out-of-Pocket</td></tr>";
                                            $scope.CSGHtmlHere += "</table></div><div class='rightCol_Grid' data-scroll-container='coverageTableScroll'><table id='tblCSB' style='width: 445px;'><tr>";

                                            var bIsHomeHost = (csbResp.data.payload.coverageSummary.isHomeHost === true) || (csbResp.data.payload.coverageSummary.isHomeHost === 'true');
                                            bIsHomeHost = bIsHomeHost && ( (ums.brand.toLowerCase() == 'hmbcbs') || (ums.brand.toLowerCase() == 'pbs') || (ums.brand.toLowerCase() == 'de') || (ums.brand.toLowerCase() == 'ms') );
                                            var bHomeHostWritten = false;
                                            $scope.bIsBalanceBilling = bIsHomeHost && (ums.brand.toLowerCase() == 'hmbcbs' || ums.brand.toLowerCase() == 'pbs');

                                            if ((nPVCol + nEVCol + nSVCol > 1) && (bIsHomeHost) && (ums.brand.toLowerCase() == 'ms')) {
                                                if (nPVCol > 0 && (nEVCol + nSVCol) > 0){
                                                    nPVCol = 0;
                                                }

                                                else if (nEVCol > 0 && nSVCol > 0)
                                                    nEVCol = 0;
                                                else if (nSVCol > 0)
                                                    nSVCol = 0;
                                            }

                                            if (nPVCol + nEVCol + nSVCol > 0)
                                                $scope.CSGHtmlHere += "<th colspan='" + (nPVCol + nEVCol + nSVCol) + "' class='CSG_C1_i' >" + $scope.IN_NETWORK_HDR + "</th>";
                                            if (nOONCol > 0)
                                                $scope.CSGHtmlHere += "<th colspan='1' class= 'CSG_C1_o'>" + $scope.OUT_OF_NETWORK_HDR + ((bIsHomeHost && (ums.brand.toLowerCase() == 'hmbcbs' || ums.brand.toLowerCase() == 'pbs')) ? "** " : "") + "</th>";
                                            $scope.CSGHtmlHere += "</tr>";


                                            if (nPVCol + nEVCol + nSVCol > 1) {
                                                if (nPVCol + nEVCol > 0) $scope.CSGHtmlHere += "<tr>";

                                                if (nPVCol > 0) {
                                                    if (!bIsHomeHost)
                                                        $scope.CSGHtmlHere += "<th class='tier_header cellshadow CSG_C2_p'>" + $scope.PREFERRED_VALUE_HDR + "</th>";
                                                    else {
                                                        $scope.CSGHtmlHere += "<th class='tier_header cellshadow CSG_C2_p'>" + $scope.HOMEHOST_VALUE_HDR + "</th>";
                                                        $scope.bHomeHostWritten = true;
                                                        if ((csbResp.data.payload.coverageSummary.numberOfTiers == 3) && (ums.brand.toLowerCase() == 'ms') && (ums.brand.toLowerCase() != 'de')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header cellshadow CSG_C2_p'>" + $scope.PREFERRED_VALUE_HDR + "</th>";
                                                        }
                                                        if ((csbResp.data.payload.coverageSummary.numberOfTiers == 3) && (ums.brand.toLowerCase() != 'ms') && (ums.brand.toLowerCase() == 'de')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header cellshadow CSG_C2_p'>" + $scope.HOMEHOST_REGION_VALUE_HDR + "</th>";//"Tier1"
                                                        }
                                                    }
                                                }

                                                if (nEVCol > 0) {
                                                    if (!bIsHomeHost){
                                                        $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol == 0 ? "cellshadow" : "") + " CSG_C2_e' >" + $scope.ENHANCED_VALUE_HDR + "</th>";
                                                    }
                                                    else {
                                                        if ((nPVCol > 0) && (ums.brand.toLowerCase() != 'de') && (ums.brand.toLowerCase() != 'ms')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol == 0 ? "cellshadow" : "") + " CSG_C2_e' >" + $scope.ENHANCED_VALUE_HDR + "</th>";
                                                        }
                                                        if ((nPVCol == 0) && (ums.brand.toLowerCase() != 'de') && (ums.brand.toLowerCase() != 'ms')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol == 0 ? "cellshadow" : "") + " CSG_C2_e' >" + $scope.HOMEHOST_VALUE_HDR + "</th>";
                                                        }
                                                        if ((nPVCol > 0) && (ums.brand.toLowerCase() != 'de') && (ums.brand.toLowerCase() == 'ms')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol == 0 ? "cellshadow" : "") + " CSG_C2_e'>" + $scope.ENHANCED_VALUE_HDR + "</th>";
                                                        }
                                                        if ((nPVCol > 0) && (ums.brand.toLowerCase() == 'de') && (ums.brand.toLowerCase() != 'ms')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol == 0 ? "cellshadow" : "") + " CSG_C2_e'>" + $scope.HOMEHOST_REGION_VALUE2_HDR + "</th>";//"Tier2"
                                                        }
                                                        if ((nPVCol == 0) && (ums.brand.toLowerCase() != 'de') && (ums.brand.toLowerCase() == 'ms')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol == 0 ? "cellshadow" : "") + " CSG_C2_e' >" + $scope.ENHANCED_VALUE_HDR + "</th>";
                                                        }
                                                        if ((nPVCol == 0) && (ums.brand.toLowerCase() == 'de') && (ums.brand.toLowerCase() != 'ms')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol == 0 ? "cellshadow" : "") + " CSG_C2_e' >" + $scope.HOMEHOST_REGION_VALUE_HDR + "</th>";//"Tier1"
                                                        }
                                                    }
                                                }
                                                if (nSVCol > 0) {
                                                    if (!bIsHomeHost)
                                                        $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol + nEVCol == 0 ? "cellshadow" : "") + " CSG_C2_s' >" + $scope.STANDARD_VALUE_HDR + "</th>";
                                                    else {
                                                        if ((nPVCol == 0) && (nEVCol == 0) && (ums.brand.toLowerCase() != 'de') && (ums.brand.toLowerCase() != 'ms')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol + nEVCol == 0 ? "cellshadow" : "") + " CSG_C2_s'>" + $scope.HOMEHOST_VALUE_HDR + "</th>";
                                                        }
                                                        if ((nPVCol > 0) || (nEVCol > 0) && (ums.brand.toLowerCase() != 'de') && (ums.brand.toLowerCase() != 'ms')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol + nEVCol == 0 ? "cellshadow" : "") + " CSG_C2_s'>" + $scope.STANDARD_VALUE_HDR + "</th>";
                                                        }
                                                        if ((nPVCol == 0) && (nEVCol == 0) && (ums.brand.toLowerCase() == 'de') && (ums.brand.toLowerCase() != 'ms')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol + nEVCol == 0 ? "cellshadow" : "") + " CSG_C2_s'>" + $scope.HOMEHOST_REGION_VALUE_HDR + "</th>";//"Tier1"
                                                        }
                                                        if ((nPVCol == 0) && (nEVCol == 0) && (ums.brand.toLowerCase() != 'de') && (ums.brand.toLowerCase() == 'ms')) {
                                                            $scope.CSGHtmlHere += "";//"<th class='tier_header " + (nPVCol + nEVCol == 0 ? "cellshadow" : "") + " CSG_C2_s' >" + $scope.HOMEHOST_REGION_VALUE_HDR + "</th>";
                                                        }
                                                        if ((nPVCol == 0) && (nEVCol > 0) && (ums.brand.toLowerCase() == 'de') && (ums.brand.toLowerCase() != 'ms')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol + nEVCol == 0 ? "cellshadow" : "") + " CSG_C2_s' >" + $scope.HOMEHOST_REGION_VALUE2_HDR + "</th>"; //"Tier2"
                                                        }
                                                        if ((nPVCol == 0) && (nEVCol > 0) && (ums.brand.toLowerCase() != 'de') && (ums.brand.toLowerCase() == 'ms')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol + nEVCol == 0 ? "cellshadow" : "") + " CSG_C2_s'>" + $scope.HOMEHOST_REGION_VALUE2_HDR + "</th>";
                                                        }
                                                        if ((nPVCol > 0) && (nEVCol > 0) && (ums.brand.toLowerCase() == 'de') && (ums.brand.toLowerCase() != 'ms')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol + nEVCol == 0 ? "cellshadow" : "") + " CSG_C2_s' >" + $scope.HOMEHOST_REGION_VALUE3_HDR + "</th>";//"Tier3"
                                                        }
                                                        if ((nPVCol > 0) && (nEVCol > 0) && (ums.brand.toLowerCase() != 'de') && (ums.brand.toLowerCase() == 'ms')) {
                                                            $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol + nEVCol == 0 ? "cellshadow" : "") + " CSG_C2_s'>" + $scope.HOMEHOST_REGION_VALUE3_HDR + "</th>";
                                                        }
                                                    }
                                                }

                                                if (!( bIsHomeHost && (nPVCol == 0) && (nEVCol == 0) && (ums.brand.toLowerCase() != 'de') && (ums.brand.toLowerCase() == 'ms'))) {
                                                    if (nOONCol > 0)
                                                        $scope.CSGHtmlHere += "<th class='tier_header " + (nPVCol + nEVCol + nSVCol == 0 ? "cellshadow" : "") + " tier_header_OON'>&nbsp;</th>";
                                                    $scope.CSGHtmlHere += "</tr>";
                                                }
                                            }
                                            var strRowClass = "odd";

                                            strRowClass = GenerateCSGHtmlAndFlipRowClass(arrPivot[1], 1, nPVCol, nEVCol, nSVCol, nOONCol, strRowClass);
                                            strRowClass = GenerateCSGHtmlAndFlipRowClass(arrPivot[2], 2, nPVCol, nEVCol, nSVCol, nOONCol, strRowClass);
                                            strRowClass = GenerateCSGHtmlAndFlipRowClass(arrPivot[3], 3, nPVCol, nEVCol, nSVCol, nOONCol, strRowClass);
                                            strRowClass = GenerateCSGHtmlAndFlipRowClass(arrPivot[4], 4, nPVCol, nEVCol, nSVCol, nOONCol, strRowClass);
                                            strRowClass = GenerateCSGHtmlAndFlipRowClass(arrPivot[5], 5, nPVCol, nEVCol, nSVCol, nOONCol, strRowClass);
                                            strRowClass = GenerateCSGHtmlAndFlipRowClass(arrPivot[6], 6, nPVCol, nEVCol, nSVCol, nOONCol, strRowClass);
                                            strRowClass = GenerateCSGHtmlAndFlipRowClass(arrPivot[7], 7, nPVCol, nEVCol, nSVCol, nOONCol, strRowClass);
                                            strRowClass = GenerateCSGHtmlAndFlipRowClass(arrPivot[8], 8, nPVCol, nEVCol, nSVCol, nOONCol, strRowClass);
                                            strRowClass = GenerateCSGHtmlAndFlipRowClass(arrPivot[9], 9, nPVCol, nEVCol, nSVCol, nOONCol, strRowClass);

                                            $scope.CSGHtmlHere += "</table></div>";


                                        }
                                        else
                                        //$scope.bHideEntireGrid = true ;  // No column wants to be displayed, perhaps only product wide and in and out network entries. Hide entire grid area
                                            $scope.bShowEntireGrid = false;
                                    }
                                    else {
                                        $scope.bShowEntireGrid = false;
                                    }
                                    function TableFunction() {

                                        arrCsbTbl = angular.element(document.getElementById("tblCSB"));

                                        if (arrCsbTbl.length == 1) {

                                            var arrTrCheckDuplCells = arrCsbTbl.find("tr.CheckDuplCellValues");
                                            function objectValues(obj) {
                                                var res = [];
                                                for (var i in obj) {
                                                    if (obj.hasOwnProperty(i)) {
                                                        res.push(obj[i]);
                                                    }
                                                }
                                                return res;
                                            }

                                            objectValues(arrTrCheckDuplCells).forEach(function (trCheckDuplCells) {
                                                var arrTdCells = document.querySelectorAll("tr.CheckDuplCellValues > td");
                                                for (var nCellIdx = 0; nCellIdx < arrTdCells.length - 1; ++nCellIdx) {
                                                    // Only process visible cells - duplicate cells get hidden, no need to check these
                                                    if ((arrTdCells[nCellIdx + 1].style.display != "none") && (arrTdCells[nCellIdx].innerHTML == arrTdCells[nCellIdx + 1].innerHTML)) {
                                                        // Next cell has same value as current cell - hide it and center current
                                                        arrTdCells[nCellIdx + 1].style.display = "none";
                                                        arrTdCells[nCellIdx].style.textAlign = "center";
                                                        arrTdCells[nCellIdx].style.paddingRight = "44px";
                                                        var nTotColSpan = 2;
                                                        // Check if other next cells also have same value
                                                        for (nCellIdx2 = nCellIdx + 2; nCellIdx2 < arrTdCells.length; ++nCellIdx2) {
                                                            //nTotColSpan = 2;
                                                            if (arrTdCells[nCellIdx].innerHTML == arrTdCells[nCellIdx2].innerHTML) {
                                                                arrTdCells[nCellIdx2].style.display = "none";
                                                                nTotColSpan += 1;
                                                            }
                                                        }
                                                        arrTdCells[nCellIdx].colSpan = nTotColSpan;
                                                        //arrTdCells[nCellIdx].innerHTML += " **";
                                                    }
                                                }
                                            });
                                        }

                                        var covDet = csbResp.data.payload.coverageDetails;
                                        if(!secondTimeAroundFlag){
                                            covDet.forEach(function(eachCovDet) {
                                                if (eachCovDet.combined != "NO") {
                                                    var nPivotRowIdx = arrRowOrder[eachCovDet.programName].order;
                                                    var strNetworkCode = eachCovDet.networkName.trim().replace('-', ' ').split(' ')[0].toUpperCase();
                                                    var arrCurCell = arrCsbTbl.find('tr.arrPivotRow' + nPivotRowIdx + ' td.netCode' + strNetworkCode);

                                                    if (arrCurCell.length == 1) {

                                                        var nCurCellIdx = arrCurCell[0].cellIndex;
                                                        var arrCombineWith = eachCovDet.combined.replace('[', '').replace(']', '').split(',');

                                                        arrCombineWith.forEach(function (strCombineWith) {
                                                            var arrOthCell = arrCsbTbl.find('tr.arrPivotRow' + nPivotRowIdx + ' td.netCode' + strCombineWith.replace(" ", "").toUpperCase());
                                                            if (arrOthCell.length == 1) {
                                                                var nOthCellIdx = arrOthCell[0].cellIndex;
                                                                var currCrossCell = arrCurCell[0].querySelectorAll('.crossacr');
                                                                var nextCrossCell = arrOthCell[0].querySelectorAll('.crossacr');

                                                                if (currCrossCell.length == 0) arrCurCell[0].innerHTML += "&nbsp;" + '<span class="crossacr">' + "*" + '</span>';
                                                                if (nextCrossCell.length == 0) arrOthCell[0].innerHTML += "&nbsp;" + '<span class="crossacr">' + "*" + '</span>';
                                                                if (arrCurCell[0].innerHTML == arrOthCell[0].innerHTML) {
                                                                    if (nCurCellIdx < nOthCellIdx) {
                                                                        arrCurCell[0].colSpan += 1;
                                                                        arrCurCell[0].style.textAlign = "center";
                                                                    } else if (nCurCellIdx > nOthCellIdx) {
                                                                        arrCurCell[0].style.display = "none";
                                                                    }
                                                                }

                                                                else {
                                                                    if (nCurCellIdx < nOthCellIdx) {

                                                                        var chElems = document.getElementsByClassName('bbNote');
                                                                        // iterate to apply style to each child elements
                                                                        for (var i = 0; i < chElems.length; i += 1) {
                                                                            chElems[i].style.display = "block";
                                                                        }
                                                                    }
                                                                }


                                                            }
                                                        });
                                                    }

                                                }
                                            })

                                        }
                                        secondTimeAroundFlag = true;
                                    }

                                    var arrCsbTbl = [];
                                    setTimeout(TableFunction,4000);
                                }
                                else {
                                    // minCR style user - Nov 2017 changes
                                    $scope.CSGHtmlHere = "";
                                    if (csbResp && csbResp.data.payload && csbResp.data.payload.coverageDetails) {
                                        var arrCSB = csbResp.data.payload.coverageDetails;

                                        $scope.CSGHtmlHere += "<div class='mnRightCol_Grid' data-scroll-container='coverageTableScroll'><table id='tblCSB' style='width: 445px;'>";
                                        $scope.CSGHtmlHere += "<tr><th>&nbsp;</th><th class='CSG_C1_i'>" + csbResp.data.payload.coverageDetails[0].rollupNetworkName + "</th></tr>";
                                        var strOddEven = "odd";
                                        var bFamily = false;

                                        arrCSB.forEach(function (curCSB) {
                                            if ((parseFloat(curCSB.benefitValue || '') >= 0) || (curCSB.programName == "COI")) {
                                                if ((curCSB.programDescription.indexOf(" FAMILY") > 0) || (curCSB.programDescription.indexOf(" TWO PARTY") > 0))
                                                    bFamily = true;
                                            }
                                        });
                                        strOddEven = GenerateTableRow(arrCSB, "PDD", (bFamily ? "Family <br/>" : " ") + "Deductible", strOddEven, "$ ", "");
                                        strOddEven = GenerateTableRow(arrCSB, "PHS", (bFamily ? "Family <br/>" : " ") + "Deductible", strOddEven, "% ", "");
                                        strOddEven = GenerateTableRow(arrCSB, "PHY", (bFamily ? "Family <br/>" : " ") + "Deductible", strOddEven, "$ ", "");

                                        strOddEven = GenerateTableRow(arrCSB, "PCP", "Primary<br/>Care Visit", strOddEven, "$ ", "");
                                        strOddEven = GenerateTableRow(arrCSB, "PCS", "Primary<br/>Care Visit", strOddEven, "% ", "");


                                        strOddEven = GenerateTableRow(arrCSB, "SPC", "Specialist<br/>Visit ", strOddEven, "$ ", "");
                                        strOddEven = GenerateTableRow(arrCSB, "SPS", "Specialist<br/>Visit ", strOddEven, "% ", "");

                                        strOddEven = GenerateTableRow(arrCSB, "EMR", "ER Visit", strOddEven, "$ ", "");
                                        strOddEven = GenerateTableRow(arrCSB, "EMS", "ER Visit", strOddEven, "% ", "");
                                        strOddEven = GenerateTableRow(arrCSB, "ERM", "ER Visit", strOddEven, "% ", "");
                                        strOddEven = GenerateTableRow(arrCSB, "EAS", "ER Visit", strOddEven, "% ", "");
                                        strOddEven = GenerateTableRow(arrCSB, "ERA", "ER Visit", strOddEven, "$ ", "");
                                        strOddEven = GenerateTableRow(arrCSB, "ERS", "ER Visit", strOddEven, "$ ", "");

                                        strOddEven = GenerateTableRow(arrCSB, "COI", "ER Visit", strOddEven, "% ", "");

                                        strOddEven = GenerateTableRow(arrCSB, "OOP", (bFamily ? "Family Out<br/>" : "Out ") + "of Pocket Maximum", strOddEven, "$ ", "");
                                        strOddEven = GenerateTableRow(arrCSB, "TMOOP", (bFamily ? "Family " : "") + "Total<br />Maximum<br />>Out-of-Pocket", strOddEven, "$ ", "");

                                        $scope.CSGHtmlHere += "</table></div>";
                                        //$scope.CSGHtmlHere = $sce.parseAsHtml($scope.CSGHtmlHere);

                                        $scope.bShowEntireGrid = bShowMnPeipGrid;



                                    }
                                    else {
                                        $scope.bShowEntireGrid = false;
                                    }
                                }
                            }, function (csbErr) {
                                $scope.bshowEntireGrid = false;
                            });
                        }
                            setTimeout(costShareGridFunction,8000);
                            });
                        }
                });

                // for PEIP
                var GenerateTableRow = function(covDetArr, strNodeToAdd, strNameToUse, strOddEven, strPrefix, strPostfix) {
                    var that = this ; bAdded = 0;

                    covDetArr.forEach( function(covDet) {
                        if (covDet.programName ==strNodeToAdd ) {
                            $scope.CSGHtmlHere += "<tr class='cap-case'><td  class='mnPEIP_LeftCell cellshadow' >" + strNameToUse + "</td><td class='mnPEIP_Row_" + strOddEven + "'>" + strPrefix +  covDet.benefitValue + strPostfix + "</td></tr>";
                            bAdded = 1 ;
                        }
                    });
                    return (bAdded == 1 ? (strOddEven == "even" ? "odd" : "even") : strOddEven) ;
                };

                var /**
                 * @return {string}
                 */
                    /**
                 * @return {string}
                 */
                GenerateCSGHtmlAndFlipRowClass = function(objPvt, nPvtIdx, nPVCol, nEVCol, nSVCol, nOONCol, strOddEven) {
                    if (objPvt) {
                        var bIsTMOOP = (nPvtIdx == 8), bRowCheckCellVals = false, nRowHeight = objPvt.height, arrDataCells=[];
                        for (var tColIdx = 0 ; tColIdx < 4 ; tColIdx++) {
                            if (objPvt[tColIdx] != undefined) {
                               this.bRowCheckCellVals = objPvt[tColIdx].checkDuplVals;
                                nRowHeight = objPvt[tColIdx].height;
                                break;
                            }
                        }
                        var chkdupldone = this.bRowCheckCellVals ? " CheckDuplCellValues " : "";
                        $scope.CSGHtmlHere += "<tr class='CSG_Row_" + strOddEven + "_i cap-case " + chkdupldone + " arrPivotRow" + nPvtIdx + "' height='" + nRowHeight + "'>";
                        if (nPVCol > 0) $scope.CSGHtmlHere += "<td class='cellshadow netCodePREFERRED netCodeIN netCodeINN'>" + GetCSGCellContent(objPvt, nPvtIdx, 0) + "</td>";
                        if (nEVCol > 0) $scope.CSGHtmlHere += "<td class='" + (nPVCol == 0 ? "cellshadow " : "") + " netCodeENHANCED'>" + GetCSGCellContent(objPvt, nPvtIdx, 1) + "</td>";
                        if (nSVCol > 0) $scope.CSGHtmlHere += "<td class='" + (nPVCol + nEVCol == 0 ? "cellshadow " : "") + " netCodeSTANDARD'>" + GetCSGCellContent(objPvt, nPvtIdx, 2) + "</td>";
                        if (nOONCol > 0)$scope.CSGHtmlHere += "<td class='" + (nPVCol + nEVCol + nSVCol == 0 ? "cellshadow " : "") + " OONetwork_" + strOddEven + " netCodeOON netCodeOUT'>" + GetCSGCellContent(objPvt, nPvtIdx, 3) + "</td>";
                        $scope.CSGHtmlHere += "</tr>" ;
                        return (strOddEven == "even" ? "odd" : "even") ;
                    }
                    else
                        return strOddEven;
                };

                var /**
                 * @return {string}
                 */
                GetCSGCellContent = function(objInput, nRowIdx, nColIdx) {
                    var objPvtCell = objInput[nColIdx] ;
                    var strRetval = "See <br/>benefit booklet " ;
                    if((objPvtCell != undefined ) && objPvtCell.networkName == "bdtc"){
                        objPvtCell.networkName  = "BDTC";
                    }

                    if (objPvtCell)
                        strRetval = (objPvtCell.unit == "$" ? "$ " : "") + objPvtCell.benefitValue + (objPvtCell.unit == "%" ? " %" : "") + (((nRowIdx == 1) || (nRowIdx >= 6)) ?  "" :  "<br />" + objPvtCell.networkName);
                    else {
                        if (nRowIdx == 1)
                            strRetval = "No Deductible";
                        else if (nRowIdx == 6 || nRowIdx == 7)
                            strRetval = "No Max Amount";
                        else if (nRowIdx == 8)
                            strRetval = "No Out-of- <br/> Pocket Maximum";
                        else if (nRowIdx == 9) {
                            if (nColIdx == 3)
                                strRetval = "No Max Amount" ;
                            else
                                strRetval = "No Total Maximum <br/>  Out-of-Pocket" ;
                        }

                    }

                    return strRetval;
                };
                services.rbsmbl.AccountSettingsRetrive()
                    .retriveAccountSettings(session.data.groupNumber, "vbidSettings", session.data.memberInfo.brand, session.data.memberInfo.userName)
                    .then(function(accountSettingsData) {

                              if (accountSettingsData && accountSettingsData.data && accountSettingsData.data.payload) {

                                  $scope.accountRetrieveData = accountSettingsData.data.payload;
                                  var acctData = accountSettingsData.data.payload;



                                  var fixDate = (acctData.selCommPrefEffDt) ? acctData.selCommPrefEffDt : "No Date Set";

                                  if (acctData.selPref && acctData.selCommPrefEffDt && acctData.deviceCode) {
                                      $scope.showVBID = true;
                                      $scope.optInVBID = (acctData.deviceCode === 'Y');
                                      $scope.enrolledVBID = $scope.optInVBID ? "Enrolled" : "Unenrolled";
                                      $scope.iconVBID = $scope.optInVBID ? "icon-checkmark" : "icon-close-red";
                                      $scope.classVBID = $scope.optInVBID ? "enrolled" : "unenrolled";
                                      $scope.dateVBID = fixDate;
                                  }


                              }
                          });
                $scope.toolTipVBID = '<p>With Blue Care Advantage, you still have access to all the great benefits you enjoy under Security Blue HMO ValueRx - plus some valuable extras designed just for you. Refer to your Notice of Benefits for additional information.</p>';
                $scope.toolTipVBIDAlt = '<p>With Blue Care Advantage, you still have access to all the great benefits you enjoy under Security Blue HMO ValueRx - plus some valuable extras designed just for you. Refer to your Notice of Benefits for additional information.</p>';

            }])
        .filter('html', function($sce) {

        return function(val) {
            return $sce.trustAsHtml(val);
        };
    })
        .directive('medicalCoverageSummary', function() {
        return {
            restrict: 'A',
            replace: true,
            template: require('directives/coverage-summary/medicalCoverage.html')
        };
    });
};