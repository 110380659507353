/**
 * Created by lid8gag on 7/27/2018.
 */
module.exports = function(app) {
    app.directive('coverageWellnessPrograms', function() {
        return {
            restrict: 'A',
            template: require('directives/coverage-summary/wellnessPrograms.html'),
            controller: ['$scope', 'sessionData', 'teamsite', 'finder-logic2',  function($scope, sessionData, teamsite, finderLogic) {
                var ums = sessionData.memberInfo;

                var showOtherPlansLink = function () {
                    var medicalMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical");
                    var drugMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Drug");
                    var dentalMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Dental");
                    var visionMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Vision");
                    var spendingMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Spending Accounts");

                    if ((medicalMod !== null) || (dentalMod !== null) || (drugMod !== null) || (visionMod !== null) || (spendingMod !== null)) {
                        return false;
                    } else {
                        return true;
                    }
                };

                var curClnt = ums.clientList[0];
                var curGrp = finderLogic.getPortalSelectedPlan(ums);
                var curPrd = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Wellness Programs");

                $scope.arrWellProg = [];
                $scope.isSharecareMember = false;

                teamsite.get('SHARECARE_SOFTLAUNCH_GROUPS').then(function (data) {
                    $scope.shareCareGroup = data;

                    if ($scope.shareCareGroup.indexOf(ums.clientList[0].groupList[0].groupNumber)>-1) {
                        $scope.isSharecareMember = true;
                    }
                });

                var strBenefitPlanName = "";
                var iClntIdx= "";
                var iGrpIdx= "";
                var iPrdIdx= "";

                var objProdPtr = {"ums": ums, "PlanName": strBenefitPlanName, "iClntIdx": iClntIdx, "iGrpIdx": iGrpIdx, "iPrdIdx": iPrdIdx};

                $scope.bMultiGroup = (showOtherPlansLink()) && (curClnt.groupList > 1);

                for (var i = 0; i < curGrp.productList.length; i++) {
                    if (curGrp.productList[i].benefitPlanType === 'Wellness Programs') {
                        var curPlan = curGrp.productList[i].benefitPlanName;

                        if (!$scope.arrWellProg.includes(curPlan)) {
                            $scope.arrWellProg.push(curPlan);
                        }
                    }
                }

                if (!$scope.isSharecareMember) {
                    $scope.arrWellProg = $scope.arrWellProg.filter(function(plnName){
                        return plnName.indexOf("WebMD") == -1;
                    });
                }
            }]
        };
    });
};