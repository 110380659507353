module.exports = function(app) {

    app.controller('pages.secureRegistration.secureRegister.step1', ["$state", "$scope", "services", "coremetrics",
        function($state, $scope, services, coremetrics) {

            $scope.sectionHeading = "Please answer the following security questions that relate to you and your company. When you have supplied all of the information, click next.";

            $scope.questions = [];

            var userId = $state.params.userId || "";
            var token = $state.params.token || "";
            $scope.isWY = $scope.brandData.ubk == '6012' ? true : false;
            if (userId === "" || token === "") {
                $state.go('secureRegistration.error', {status: "NO_USER"});
            }

            $scope.showMessage = $state.params.showMessage || false;
            $scope.currentRetryCount = 0;
            //CR00779941 - Wrong phone number issue
            if($scope.brandData.sharedBrandCode.toLowerCase() === "ndbcbs"){
                $scope.techNumber = "1-800-544-8467";
            }else if($scope.brandData.sharedBrandCode.toLowerCase() === "mincr"){
                $scope.techNumber = $scope.brandData.phoneNumbers.mcutechincal;
            }else {
                $scope.techNumber = $scope.brandData.phoneNumbers.technical;
            }
            $scope.tty = $scope.brandData.phoneNumbers.technicalTTY || "";

            services.rbsmbl.securityQuestions({userid: userId}).fetch(token).then(
                function(response) {
                    var base = services.rbsmbl.securityQuestions().parseBase(response);
                    if (base && base.returnCode && base.returnCode !== "200") {
                        $state.go('secureRegistration.error', { status: base.returnCode, statusText: base.returnCodeDescription });

                    } else {
                        $scope.currentRetryCount = (response && response.data && response.data.payload && response.data.payload.retryCount ? response.data.payload.retryCount : 0);
                        var questions = services.rbsmbl.securityQuestions().parse(response);

                        if ($scope.currentRetryCount >= 3) {
                            // In-case service doesn't return error for max retries reached
                            $state.go('secureRegistration.error', { status: "185652", statusText: "" });

                        } else if (questions.length < 3) {
                            // Not enough questions returned...
                            $state.go('secureRegistration.error', { status: "QUESTIONS_LENGTH", statusText: "" });

                        } else {
                            for (var i = 0; i < questions.length; i++) {
                                var question = questions[i];
                                question.validation = getValidation(question);
                                $scope.questions.push(question);
                            }
                        }
                    }
                },
                function(error) {
                    if (error && error.data && error.data.returnCode) {
                        $state.go('secureRegistration.error', { status: error.data.returnCode, statusText: error.data.returnCodeDescription });
                    } else {
                        //console.log(error);
                        $state.go('secureRegistration.error', error);
                    }
                }
            );

            function getValidation(question) {
                var validation = { pattern: "", message: "", size: "" };
                switch(question.type) {
                    case "email":
                        validation.pattern = /^[a-z0-9_.+-]+@[a-z0-9-]+\.[a-z0-9-.]+$/i;
                        validation.message = "Please enter a valid email address";
                        break;
                    case "phone":
                        validation.pattern = /[0-9]{10,10}/;
                        validation.message = "Please enter a 10 digit phone number";
                        break;
                    case "zip":
                        validation.pattern = /[0-9]{5,5}/;
                        validation.message = "Please enter a 5 digit zip code";
                        break;
                    /*case "text":
                        validation.pattern = /^[A-z_.+-]+$/;
                        validation.message = "Please enter text only";
                        break;*/
                    case "number":
                        validation.pattern = /^[0-9]+$/;
                        validation.message = "Please enter numbers only";
                        break;
                    case "alphanumeric":
                        validation.pattern = /^[A-z0-9]+$/;
                        validation.message = "Please enter text and/or numbers only";
                        break;
                    default:
                        break;
                }
                validation.size = (question.size > 0 ? question.size : "");
                return validation;
            }

            $scope.nextStep = function() {
                if ($scope.securityQuestionsForm.$valid) {

                    var payload = {
                        userId: userId,
                        retryCount: $scope.currentRetryCount,
                        Answers: {
                            Answer: []
                        }
                    };
                    for (var i = 0; i < $scope.questions.length; i++) {
                        var question = $scope.questions[i];
                        payload.Answers.Answer.push({ key: question.key, reply: question.answer });
                    }

                    services.rbsmbl.validateAnswers().postData(payload, token).then(
                        function(response) {
                            var base = services.rbsmbl.validateAnswers().parseBase(response);

                            if (base && base.returnCode && base.returnCode !== "200") {
                                $state.go('secureRegistration.error', { status: base.returnCode, statusText: base.returnCodeDescription });
                            } else {
                                $scope.$parent.myChevron.state = "secureRegistration.step2";
                                $scope.$parent.myChevron.step = 2;
                                $scope.$parent.goto(2, { userId: userId, token: token });
                            }
                        },
                        function(error) {
                            if (error && error.data && error.data.returnCode) {
                                if (error.data.returnCode === "185657" || error.data.returnCode === 185657) {
                                    $scope.$parent.myChevron.state = "secureRegistration.step1";
                                    $scope.$parent.myChevron.step = 1;
                                    $state.go('secureRegistration.step1',{ userId: userId, token: token, showMessage: true}, {reload: true});
                                } else {
                                    $state.go('secureRegistration.error', { status: error.data.returnCode, statusText: error.data.returnCodeDescription });
                                }
                            } else {
                                //console.log(error);
                                $state.go('secureRegistration.error', error);
                            }
                        }
                    );
                }
            };

            $scope.cancelButton = function() {
                location.replace("/home/");
            };

        }
    ]);
};
