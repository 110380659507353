module.exports = function(app) {
    app.directive('planProgressNew', function() {
        return {
            replace: true,
            restrict: 'A',
            template: require('directives/planProgressNew/planProgress5.html'),
            scope: {
                coverageStatus: '@planProgressNew'
            },
            controller: ['$scope', '$rootScope','services', '$q', '$filter', 'sessionData', '$cookies','brand', 'teamsite',
                function($scope, $rootScope, services, $q, $filter, sessionData, $cookies,brand, teamsite) {
                    //$scope.shouldShow = "wybcbs" == brand.current.sharedBrandCode.toLowerCase() || "ndbcbs" == brand.current.sharedBrandCode.toLowerCase();
                    $scope.isNDBCBS = "ndbcbs" == brand.current.sharedBrandCode.toLowerCase();
                    var memberPlanData= {};

                    var planProgress= {};
                    $scope.heading = 'plan progress';
                    $scope.coverageStatus = 'active';
                    $scope.today = $filter('date')(new Date(), 'MM/dd/yyyy');
                    $scope.hasShowableAccums = false;
                    $scope.viewState = 'loading';
                    $scope.ppToolTip = "<span data-teamsite='PLANPROGRESS_TOOLTIP_V3'></span>";

                    $scope.plans = {};
                    var plans ={};
                    $scope.plans.activePlans = [];
                    $scope.plans.previousPlans = [];
                    $scope.plans.filter = $rootScope.sidebar.data.accumsFilter;
                    $rootScope.$watch("sidebar.data.accumsFilter", function(newValue){
                        $scope.plans.filter = newValue;
                    });

                    $scope.healthPlansLink = '/mhs/#/healthPlans';


                    services.rbsmbl.memberPlan().fetch().then(function(response){
                        if(response && response.data && response.data.activePlanProgressQuery[0]){
                        memberPlanData.activeQuery = response.data.activePlanProgressQuery[0];}
                        else{
                            $scope.viewState = "noAccums"
                        }

                        if( memberPlanData.activeQuery) {
                            services.rbsmbl.progressV5().fetch(memberPlanData.activeQuery).then(function (activeProgress) {
                                planProgress.active = activeProgress;
                                plans = planProgress.active.data.plan;

                                if (!plans || !plans.length || angular.equals(plans[0], {})) {
                                    $scope.viewState = 'coverageNotFound';
                                    return;
                                }

                                $scope.viewState = 'success';
                                $scope.plans.activePlans = prepData(plans);

                            }, function (error) {
                                //$scope.shouldShow = true;
                                $scope.viewState = 'error';
                                $scope.errorId = 'GENERALERROR';
                            });
                        }

                    }, function(error){
                        //$scope.shouldShow = true;
                        $scope.viewState = 'error';
                        $scope.errorId = 'GENERALERROR';

                    });

                    function prepData(plans){
                        var plan;
                        var i;
                        var plansList = [];

                        setUniqueNamesForIndividuals(plans);
                        for (i = 0; i < plans.length; i++) {
                            plan = plans[i];

                            plan.isEmptyAccumList = isEmptyAccumList(plan);
                            //setAccumDescForEachIndividuals(plan);
                            setIsFamilyAccumForEachAccums(plan);

                            plan.isFamilyPlan = isPlanFamilyPlan(plan);
                            plan.isIndividual = !plan.isFamilyPlan;

                            plan.planStartDate = formatDate(plan.benefitStartDate);
                            plan.planEndDate = formatDate(plan.benefitEndDate);

                            plan.accums = plan.accumulations;
                            plan.familyAccumsList = [];
                            plan.individualAccumsList = [];
                            plan.accums.forEach(
                                function(accum) {
                                    switch(accum.descriptorCd) {
                                        case 'FIN':
                                            if(accum.isFamilyAccum){
                                                accum.familyAccum.accumDesc = accum.familyAccum.accumDesc.replace("facility", "institutional");
                                            } else{
                                                accum.individualAccum.accumDesc = accum.individualAccum.accumDesc.replace("facility", "institutional");}
                                            break;
                                        default :
                                            break;
                                    }
                                    if (accum.isFamilyAccum) {
                                        accum.isOpen = true;
                                        plan.familyAccumsList.push(accum);
                                    } else {
                                        plan.individualAccumsList.push(accum);
                                    }
                                }
                            );
                            plansList.push(plan);
                        }
                        return plansList;
                    }


                    $rootScope.$on('updatePlanProgressAccums', function(event, resultAccumObj, selectedMember) {
                        $scope.heading = 'estimated plan progress';
                    });

                    $rootScope.$on('recompilePlanProgressMeter', function(event) {
                        $scope.heading = 'plan progress';
                    });


                    $scope.doesAccumHaveIndividualAccums = doesAccumHaveIndividualAccums;
                    $scope.toggleDetails = function(toggle){
                        toggle.isOpen = !toggle.isOpen;
                    };


                    function hasValue(obj) {
                        return obj != null && Object.keys(obj).length !== 0;
                    }

                    function isPlanFamilyPlan(planData) {


                        var isFamilyPlan = false;

                        var j;

                        for (j = 0; j < planData.accumulations.length; j++) {
                            if (hasValue(planData.accumulations[j].familyAccum)) {
                                return true;
                            }
                        }


                        return isFamilyPlan;
                    }


                    function formatDate(date) {
                        var year = date.slice(0, 4);
                        var month = date.slice(4, 6);
                        var day = date.slice(6, 8);
                        return month + "/" + day + "/" + year;
                    }


                    function setUniqueNamesForIndividuals(plans) {
                        var p;
                        var j = 0;
                        var k = 0;
                        var firstNameIndividualMap = {};
                        var individual = null;

                        for (p = 0; p < plans.length; p++) {
                            aPlan = plans[p];

                            if (!aPlan.accumulations || !aPlan.accumulations.length) {
                                $scope.viewState ='noAccums';
                                continue;
                            }

                            for (j = 0; j < aPlan.accumulations.length; j++) {
                                    if (!aPlan.accumulations[j].individualAccum ||
                                        !aPlan.accumulations[j].individualAccum.individuals ||
                                        !aPlan.accumulations[j].individualAccum.individuals.length) {
                                        continue;
                                    }

                                for (k = 0; k < aPlan.accumulations[j].individualAccum.individuals.length; k++) {
                                        individual = aPlan.accumulations[j].individualAccum.individuals[k];
                                        if (firstNameIndividualMap[individual.firstName]) {
                                            if (firstNameIndividualMap[individual.firstName][individual.dob]) {
                                                firstNameIndividualMap[individual.firstName][individual.dob].push(individual);
                                            } else {
                                                firstNameIndividualMap[individual.firstName][individual.dob] = [individual];
                                            }
                                        } else {
                                            firstNameIndividualMap[individual.firstName] = {};
                                            firstNameIndividualMap[individual.firstName][individual.dob] = [individual]
                                        }
                                }
                            }
                        }


                        for (var nameKey in firstNameIndividualMap) {
                            for (var dobKey in firstNameIndividualMap[nameKey]) {
                                if (Object.keys(firstNameIndividualMap[nameKey]).length > 1) {
                                    firstNameIndividualMap[nameKey][dobKey].forEach(function(individual) {
                                        individual.uniqueName = individual.firstName + " (" + formatDate(individual.dob) + ")";
                                    });
                                } else {
                                    firstNameIndividualMap[nameKey][dobKey].forEach(function(individual) {
                                        individual.uniqueName = individual.firstName;
                                    });
                                }
                            }
                        }


                    }


                    function setIsFamilyAccumForEachAccums(aPlan) {

                        if (aPlan.accumulations && aPlan.accumulations.length) {
                            aPlan.accumulations.forEach(setIsFamilyAccum);
                        }

                    }

                    function setIsFamilyAccum(accumulation) {
                        if (hasValue(accumulation.familyAccum)) {
                            accumulation.isFamilyAccum = true;
                        } else{
                            accumulation.isFamilyAccum = false;
                        }
                    }


                    function isEmptyAccumList(planProgress) {

                        return !(planProgress || planProgress.accums || planProgress.accums.length);

                    }

                    function doesAccumHaveIndividualAccums(accum) {
                        return (accum &&
                        accum.individualAccum &&
                        accum.individualAccum.individuals &&
                        accum.individualAccum.individuals.length)
                    }


                }
            ]
        }
    })

};