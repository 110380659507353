module.exports = function (app) {
    
  app.controller("pages.formsLibrary.formsLib", ["$state", "$scope", "$q", "navigation", 
    "services", "forms", "session","sessionData","$cookies", "modal", "$window", 
    "coremetrics", "coremetricsConversionTracker", "formsLibraryService","invisibleForm", 
    "teamsite", "brand",
      function ($state, $scope, $q, navigation,
        services, forms, session, sessionData, 
        $cookies, modal, $window, coremetrics, 
        coremetricsConversionTracker, formsLibraryService, 
        invisibleForm, teamsite, brand) {
          window.document.title = "Forms Library";

          $scope.searchInput = '';
        $scope.searchResults = [];
        $scope.showLoginBox = [];
        $scope.showNotOnPlatform = [];
        $scope.showSearchResults = false;
        $scope.isClaimsForm = false;
          
      
        $scope.$watch(function () {
          return sessionData.loggedIn;
        }, function (newValue, oldValue) {
          $scope.isUserLoggedIn = !!newValue;
          $scope.hideLoginButton = $scope.isUserLoggedIn;

          $scope.filterForms();
        });
          
         
        var base64ToArrayBuffer = function(convertBase64PDF) {
          var binaryString = window.atob(convertBase64PDF);
          var binaryLen = binaryString.length;
          var conversionBytes = new Uint8Array(binaryLen);
          for (var i = 0; i < binaryLen; i++){
            var ascii = binaryString.charCodeAt(i);
            conversionBytes[i] = ascii;
          }
          return conversionBytes;
        };
          
        var saveByteArray = function(reportName, pdfArr) {
          var blob = new Blob([pdfArr], {type: "application/pdf"});
          //firefox
          var link = document.createElement('a');
          var evtClick = document.createEvent('MouseEvents');
          var blobURL = window.URL.createObjectURL(blob);
          window.open(blobURL);
        };
           
        var convertToPDF = function(pdfBaseImage, fileName, pdfArr) {
         if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var byteCharacters = atob(pdfBaseImage);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i =0; i < byteCharacters.length; i++){
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {type: 'application/pdf'});
          
          window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            var pdfArr = base64ToArrayBuffer(pdfBaseImage);
            saveByteArray(fileName, pdfArr);
          }
        };

          /*  RF799982 and RF789335 - Added formLink in the parameter and uploaded the documents in the server for prepopulating the data. */
        $scope.getDocument = function(formName, formLink, buttonName) {
            var formsData = formName;
            var pdfClicked = buttonName;                
            var downloadPdf = false;

            if (formsData === "Prescription Drug Reimbursement Form"){
              formsData = "Prescription_Drug_Reimbursement_Form";
            }else if(formsData ==="Specialty Drug Request Form"){
              formsData = "Specialty_Drug";
            }else if (formsData === "Home Delivery Order Form (A) & Health Allergy & Medication Questionnaire (B)" && pdfClicked === "PDF_A"){                  
              formsData = "Home_Delivery_Order_Form";
            }else if(formsData === "Home Delivery Order Form (A) & Health Allergy & Medication Questionnaire (B)" && pdfClicked === "PDF_B"){
              formsData = "Health_Allergy_Medication_Questionnaire";                 
            }

            var supressFormsPrePopulate = sessionData.appConfig && 
            sessionData.appConfig.FORMS_LIBRARY && 
            sessionData.appConfig.FORMS_LIBRARY.PDF_PRE_POPULATION_SUPPRESS && 
            sessionData.appConfig.FORMS_LIBRARY.PDF_PRE_POPULATION_SUPPRESS.booleanValue;

            if(supressFormsPrePopulate === false){
              $scope.isClaimsForm = true;
              if (formsData === "Authorization for Disclosure of Health Information"){
                formsData = "priv-auth-disclosure";
              }else if(formsData === "International Claim Form"){
                formsData = "International-Claim-Form-US-fillable";
              }else if (formsData === "Member Submitted Health Insurance Claim Form"){
                formsData = "Medical_Claim_Form";
              }else if(formsData === "Member Submitted Major Medical Insurance Claim Form"){
                formsData = "Major-Medical-Claim-Form";
              }else if(formsData === "Member Submitted Health Insurance Claim Form for Medicare Advantage Out-of-Network UPMC Claims"){
                formsData = "Major-Medical-Claim-Form-For-Medicare-UPMC-Claim";
              }
            }
            
            services.rbsmbl.pdfPrepopulation().fetch(formsData, downloadPdf).then(function(response){
              var response = response && response.data && response.data.payload;
              if (response) {
                var responseData = response;
                var pdfBaseImage = responseData.base64ImageContent;

                if(formsData === "Prescription_Drug_Reimbursement_Form") {
                  var fileName = 'Prescription_Drug_Reimbursement_Form.pdf';
                  var pdfArr = base64ToArrayBuffer(pdfBaseImage);
                  
                  return convertToPDF(pdfBaseImage, fileName, pdfArr);   

                } else if (formsData === "Specialty_Drug") {                          
                  var fileName = 'Specialty_Drug_Request_Form.pdf';
                  var pdfArr = base64ToArrayBuffer(pdfBaseImage);  

                  return convertToPDF(pdfBaseImage, fileName, pdfArr); 
                   
                } else if (formsData === "Home_Delivery_Order_Form") {
                  var fileName = 'Home_Delivery_Order_Form.pdf';
                  var pdfArr = base64ToArrayBuffer(pdfBaseImage);  
                  return convertToPDF(pdfBaseImage, fileName, pdfArr);

                } else if(formsData === "Health_Allergy_Medication_Questionnaire"){
                  var fileName = 'Health_Allergy_&_Medication_Questionnaire.pdf';
                  var pdfArr = base64ToArrayBuffer(pdfBaseImage);  
                     
                  return convertToPDF(pdfBaseImage, fileName, pdfArr); 

                } else if(formsData === "priv-auth-disclosure"){
                  var fileName = 'priv-auth-disclosure.pdf';
                  var pdfArr = base64ToArrayBuffer(pdfBaseImage);  
                     
                  return convertToPDF(pdfBaseImage, fileName, pdfArr);  

                } else if(formsData === "International-Claim-Form-US-fillable"){
                  var fileName = 'International-Claim-Form-US-fillable.pdf';
                  var pdfArr = base64ToArrayBuffer(pdfBaseImage);  
                     
                  return convertToPDF(pdfBaseImage, fileName, pdfArr);  

                } else if(formsData === "Medical_Claim_Form"){
                  var fileName = 'Medical_Claim_Form.pdf';
                  var pdfArr = base64ToArrayBuffer(pdfBaseImage);  
                     
                  return convertToPDF(pdfBaseImage, fileName, pdfArr);  

                } else if(formsData === "Major-Medical-Claim-Form"){
                  var fileName = 'Major-Medical-Claim-Form.pdf';
                  var pdfArr = base64ToArrayBuffer(pdfBaseImage);  
                     
                  return convertToPDF(pdfBaseImage, fileName, pdfArr);  

                } else if(formsData === "Major-Medical-Claim-Form-For-Medicare-UPMC-Claim"){
                  var fileName = 'Major-Medical-Claim-Form-For-Medicare-UPMC-Claim.pdf';
                  var pdfArr = base64ToArrayBuffer(pdfBaseImage);  
                     
                  return convertToPDF(pdfBaseImage, fileName, pdfArr);  

                }                        
              }else{
                  /*RF799982 and RF789335 - If the response from the service is not found and if there is a valid form link, then open the link in a new tab */
                  if(formLink != undefined && formLink != null && formLink != ""){
                      window.open(formLink,"_blank");
                  }
              }
            }, function (error) {
              
            });            
        };

        var searchMinimumCharacters = 2;
        $scope.navigation = navigation.data;

        $scope.forgotCoremetrics = function () {
            coremetrics.elementTag('FORGOT: USERNAME OR PASSWORD', 'FORGOT');
        };

        $scope.loginInfo = {
            username: "",
            password: "",
            rememberUserName: false
        };

        $scope.performSso = function (ssoOptions) {
            if (ssoOptions && ssoOptions.isSsoForm && ssoOptions.targetType) {
                formsLibraryService.performSso(ssoOptions.targetType, 'cmMain');
            }
        };

        $scope.filterForms = function () {
            formsLibraryService.filterFormsByEntitlements(sessionData).then(function (filteredForms) {
                forms = filteredForms;
      
                    $scope.libraryData = formsLibraryService.getDrawersAndCategories(filteredForms);
            }, function (error) {
                $scope.libraryData = formsLibraryService.getDrawersAndCategories(forms);
            });
        };
        
        $scope.login2 = function (form) {
            var deferred = $q.defer();
            $scope.loggingIn = true;
            $scope.showInvalidUserPass = false;
            services.rbsmbl.loginversion().fetch($scope.loginInfo.username).then(function (response) {
                if (response && response.data && response.data.payload) {
                    if (response.data.payload.loginVersion == 'MER_LOGIN_V1') {
                        services.rbsmbl.login().loginWithRedirect($scope.loginInfo.username, response.data.payload.convertCase ? $scope.loginInfo.password.toUpperCase() : $scope.loginInfo.password, null, null, true).then(function (response2) {
                            coremetricsConversionTracker.finish('LOGIN', 'LOGIN');
                            $scope.loggingIn = false;
                            var redirectUrl = '';

                            if (form.interactiveLink) {
                                redirectUrl = form.interactiveLink;
                            } else if (form.interactiveLink && form.isExternal) { // Added this else if for AEM forms. 
                                redirectUrl = form.interactiveLink;
                                $window.open(redirectUrl, '_blank');
                                $window.open('/mbr/#!/landing', '_self');    
                            } else if (form.docLink) {
                                redirectUrl = form.docLink;
                            } else {
                                redirectUrl = '/home/';
                            }
                            if (formsLibraryService.isUserEntitledToForm(form)) {
                                if (form.ssoOptions && form.ssoOptions.isSsoForm) {
                                    $scope.performSso(form.ssoOptions);
                                } else if (form.isExternal) {
                                    $window.open(redirectUrl, '_blank');
                                    $window.open('/mbr/#!/landing', '_self');
                                } else {
                                    $window.open(redirectUrl, '_self');
                                }
                            } else {
                                $window.location = '/mbr/?formsLibraryErrorModal=true#!/landing';
                            }

                            $scope.showLoginBox[form['id']] = $scope.showMobileLoginBox = false;
                            $scope.showLoginMessage = true;

                            deferred.resolve(true);
                        }, function (error) {
                            $scope.loginInfo.username = "";
                            $scope.loginInfo.password = "";
                            $scope.loggingIn = false;
                            $scope.loginErrorContent = error.specificErrorContent;

                            // If the error wasn't just due to a wrong user/pass combo
                            $scope.showLoginBox[form['id']] = $scope.showMobileLoginBox = false;
                            $scope.showLoginMessage = true;

                            // If error was simply due to wrong username or password, hide the loginMessageBox and display the error inside the login form
                            if (error.data && error.data.baseMessage) {
                                switch (error.data.baseMessage.returnCodeDescription) {
                                  case "GEN-NORECCHMECS":
                                  case "GEN-NOMATCH":
                                  case "USER-NOT-ON-FILE":
                                  $scope.showLoginBox[form['id']] = $scope.showMobileLoginBox = true;
                                  $scope.showInvalidUserPass = true;
                                  $scope.showLoginMessage = false;
                                  break;
                                  case "GEN-NOTONPLATFORM":
                                      var userNotOnPlatform = error.data.baseMessage.additionalInfo[0].key;
                                      if (userNotOnPlatform === "showBanner" || userNotOnPlatform === "showBanner-2" || userNotOnPlatform === "showBanner-3") {
                                          $scope.showNotOnPlatform[form['id']] = true;
                                          $scope.showPlatformMessage = error.data.baseMessage.additionalInfo[0].value;
                                      }
                                  break;
                                }
                            }
                            deferred.reject(false);
                        });
                    } else if (response.data.payload.loginVersion === 'BV_LOGIN') {
                        if (response.data.payload.convertCase === true) {
                          $scope.loginInfo.username = $scope.loginInfo.username.toUpperCase();
                          $scope.loginInfo.password = $scope.loginInfo.password.toUpperCase();
                        }
                        services.wcs.ltpalogin({"storeId": brand.current.storeId}).postData({},
                        {
                          username: $scope.loginInfo.username,
                          password: $scope.loginInfo.password
                        }).then(function (response) {
                            session.start().then(function () {
                              var returnDefer = $q.defer();
                              returnDefer.resolve(response);
                            });
                            coremetricsConversionTracker.finish('LOGIN', 'LOGIN');
                            $scope.loggingIn = false;
                            deferred.resolve(true);
                        },
                        function (error) {
                            $scope.loginInfo.username = "";
                            $scope.loginInfo.password = "";
                            $scope.loggingIn = false;
                            if (error.data && error.data.baseMessage) {
                              $scope.loginErrorContent = services.rbsmbl.login().getErrorMessage(error.data.baseMessage.returnCodeDescription) || {};
                            } else {
                              $scope.loginErrorContent = {};
                            }

                            // If the error wasn't just due to a wrong user/pass combo
                            $scope.showLoginBox[form['id']] = $scope.showMobileLoginBox = false;
                            $scope.showLoginMessage = true;

                            // If error was simply due to wrong username or password, hide the loginMessageBox and display the error inside the login form
                            if (error.data && error.data.baseMessage) {
                                switch (error.data.baseMessage.returnCodeDescription) {
                                  case "GEN-NORECCHMECS":
                                  case "GEN-NOMATCH":
                                  case "USER-NOT-ON-FILE":
                                  $scope.showLoginBox[form['id']] = $scope.showMobileLoginBox = true;
                                  $scope.showInvalidUserPass = true;
                                  $scope.showLoginMessage = false;
                                }
                            }
                            deferred.reject(error);
                        });
                    } else {
                        invisibleForm.submit({
                            name: "loginForm2",
                            action: '/chmptl/chm/jsp/logon.do',
                            inputs: {
                              loginid: $scope.loginInfo.username,
                              password: response.data.payload.convertCase ? $scope.loginInfo.password.toUpperCase() : $scope.loginInfo.password
                            }
                        });
                        deferred.resolve(true);
                    }
                } else {
                  // TODO FIXME handle error gracefully
                  $scope.loggingIn = false;
                  deferred.reject(false);
                }
            }, function (error) {
              // TODO FIXME handle error
              $scope.loggingIn = false;
              deferred.reject(error);
            });

            return deferred.promise;
        };

        $scope.logInButton = function (form) {
          $scope.showLoginBox[form["id"]] = !$scope.showLoginBox[form["id"]];
          coremetrics.pageviewTag('LOGIN', 'LOGIN');0.
          coremetricsConversionTracker.start('LOGIN', 'LOGIN');
        };

        $scope.newWindowLoggedIn = function (form) {
          if (form.interactiveLink && form.isExternal) { // Added this else if for AEM forms. 
            redirectUrl = form.interactiveLink;
            $window.open(redirectUrl, '_blank');
            $window.open('/mbr/#!/landing', '_self'); 
          }                    
        };

        $scope.filterForms();

        $scope.closeInstructions = function (form) {
          modal.close(form);
        };
        $scope.closeNotOnPlatform = function (form) {
           $scope.showNotOnPlatform[form['id']] = false;
        };

        $scope.openInstructions = function(form) {
          var modalScope = $scope.$new(true);
          modalScope.form = form;
          modalScope.loginInfo = $scope.loginInfo;
          modalScope.login2 = $scope.login2;
          modalScope.performSso = $scope.performSso;
          modalScope.closeInstructions = $scope.closeInstructions;
          modal.open({
              template: require('./modals/formInstructionsModal.html'),
              controller: require('./modals/formInstructionsModal.js'),
              scope: modalScope,
              classes: 'form-instructions-size'
          });
        };


        var scrubInput = function(input) {
          var cleanInput = [];
          if (input) {
              //removes all non-alphanumeric characters and any egregious whitespace...
              var s = input.replace(/[^\w\s]/g, '').replace(/\s\s+/g, ' ');
              //...and lists all cleaned up search terms in an array
              cleanInput = s.split(' ');
              for (var x = cleanInput.length - 1; x >= 0; x--) {
                  if (cleanInput[x] === "") {
                      cleanInput.splice(x, 1);
                  }
              }
          }
          return cleanInput;
        };

        function alphabetize(a,b) {
          if (a.name < b.name){ return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        }

        var matchAll = function(searchList) {
            if (searchList.length === 0) { return []; }

            var matchingForms = [];
            var match = function(searchList, index, form) {
                var term = searchList[index].toLowerCase();
                if ((!form.name || form.name.toLowerCase().indexOf(term) < 0) &&
                  (!form.tags || form.tags.toLowerCase().indexOf(term) < 0) &&
                  (!form.desc || form.desc.toLowerCase().indexOf(term) < 0) &&
                  (!form.category || form.category.toLowerCase().indexOf(term) < 0)) {
                  return false;
                }
                if (index+1 < searchList.length) {
                  return match(searchList, index+1, form);
                } else {
                  return true;
                }
            };
            for (var x = 0; x < forms.length; x++) {
              if (match(searchList, 0, forms[x])) {
                matchingForms.push(forms[x]);
              }
            }
            return matchingForms.length > 1 ? matchingForms.sort(alphabetize) : matchingForms;
        };

        $scope.search = function() {
            var searchList = scrubInput($scope.searchInput);
            if (!$scope.searchInput || $scope.searchInput.length < searchMinimumCharacters || searchList.length === 0) {
              $scope.searchResults = [];
              $scope.showSearchResults = false;
            } else {
              $scope.searchResults = matchAll(searchList);
              $scope.showSearchResults = true;
            }
        };
    }
  ]);
};
