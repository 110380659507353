module.exports = function(app) {
    app.factory('wellnessSearchResultsService', ['services',
        function(services) {
            var getwellnessSearchResults;

            getwellnessSearchResults = function(queryString,category,pageLoc,resultsPerPage,aggregate,sharecare) {
                var searchResultsPromise = services.rbsmbl.wellnessSearchResults().fetch(queryString,category,pageLoc,resultsPerPage,aggregate,sharecare);

                return searchResultsPromise.then(
                    function(response) {
                        var searchResults = response.data.payload.results;
                        var refineCategories = response.data.payload.categoryResults;
                        var searchResultsCount = response.data.payload.totalNoOfRecords;

                        return{
                            searchResults: searchResults,
                            refineCategories: refineCategories,
                            searchResultsCount: searchResultsCount
                        };
                    },
                    function(err) {
                        console.error('getwellnessSearchResults failed with error', err);
                        return searchResultsPromise;
                    }
                );
            };

            return {
                getwellnessSearchResults: getwellnessSearchResults
            };
        }
    ]);
};
