module.exports = function (app) {
    app.controller('pages.account.membership-information', ['$scope', '$timeout','sessionData', 'finder-logic2', 'services',
        function ($scope, $timeout, sessionData, finderLogic, services) {
            $scope.shouldShowDOB = false;
            $scope.memberInfoDrawer = false;
            $scope.showPastCov = false;
            $scope.showFutureCov = false;
            $scope.showNoPlansMessage = false;


            var states = services.rbsmbl.memberInfo().getCoverageDetails(),
                hasActivePlans = states.current.nCount > 0,
                hasPastPlans = states.past.nCount > 0,
                hasFuturePlans = states.future.nCount > 0;

                if(hasActivePlans == false ){
                //     policyState =  "future";
                //     self.model.state = 2;
                    $scope.showIdCard = true;
                    $scope.showPastCov = false;
                //     self.model.showCancel = false ;
                    $scope.showFutureCov = true;
                  	$scope.masterShowDate = false;
                }
                if(hasActivePlans == false && hasFuturePlans == false){
                //     policyState = "past";
                //     self.model.state = 1;
                    $scope.showIdCard = true;
                    $scope.showPastCov = true;
                    $scope.showFutureCov = false;
                //     self.model.showCancel = false ;
                   $scope.model.masterShowDate = true;
                }
                if (!hasActivePlans && !hasFuturePlans && !hasPastPlans) {
                //     // User has no plans, show error message
                   $scope.showPastCov = false;
                   $scope.showFutureCov = false;
                   $scope.showNoPlansMessage = true;
                }




            //SET HEADING
            var ums = sessionData.memberInfo;
			$scope.heading = ums.clientList[0].clientName;
			$scope.groupNum = ums.clientList[0].groupList[0].groupNumber;

			//LIST MEMBERS IN DRAWER HEADER
			var memberList = services.rbsmbl.memberInfo().getUniqueMemberNamesArray();
			if(memberList&& memberList.length){
				$scope.memberList = memberList;
			}

			console.log($scope.memberList);
			if($scope.memberList[0]){
				$timeout(function(){
					jQuery('[data-drawer-header]')[0].click();
				}, 500);
			}
			//add bday if duplicate names exist
			$scope.shouldShowDOB = {};

			var duplicateNames = {}
			//check if member has same name and display birthday if so
			for (var i = 0; i < $scope.memberList.length; i++) {
				var member = $scope.memberList[i];

					
				for (var j = i+1; j<$scope.memberList.length; j++) {
					var otherMember = $scope.memberList[j];
					if (!$scope.shouldShowDOB[j]
						&& member.firstName.toUpperCase() === otherMember.firstName.toUpperCase()
						&& member.lastName.toUpperCase() === otherMember.lastName.toUpperCase()) {
						$scope.shouldShowDOB[j] = true;
						$scope.shouldShowDOB[i] = true;
					} 
				}
			}

			//GET ACTIVE PRODUCTS & SET DRAWER CONTENT **NEEDS TO GO IN REPEATER FOR ACTIVE PRODUCTS
			var theProductList = finderLogic.portalSelectedPlanActiveProducts();
			console.log(theProductList);

			/*
			Each repeat is a group repeat, and then the coverage included items are the products in that group.
			Different MemberId

			Repeat over the groups in the Grouplist for the unique umi, and then the productList of activeProducts is the coverage included icons.

			*/
		
			//member id is different for each benefitPlanName
			//LANIER2011 HAS FUTURE COVERAGE

			if(theProductList.length>0){
				var memIDprefix = theProductList[0].alphaPrefix;
				$scope.memUMI= ums.umi;
			
				for (var i = 0; i < theProductList.length; i ++){
					$scope.activePlans = theProductList;
				}
			}
			


			//loop thru benefit plans
			//create memberid
			//check if memberid is same
			//if same push benefit type to coverage list
			//if memberid is diffent create new section w/ new coverage list






			



			
			var getUniqueMemberNames = services.rbsmbl.memberInfo().getUniqueMemberNames();
			var getUniqueProducts = services.rbsmbl.memberInfo().getUniqueProducts();
			var umiSuffix = services.rbsmbl.memberInfo().umiSuffix();
			var getPortalSelectedClient = services.rbsmbl.memberInfo().getPortalSelectedClient();
			var currentMember = services.rbsmbl.memberInfo().currentMember();
			var normalizePlanType = services.rbsmbl.memberInfo().normalizePlanType();
			var getSessionData = services.rbsmbl.memberInfo().getSessionData();
			var getMemberContactList = services.rbsmbl.memberInfo().getMemberContactList();
			var getCoverageDetails = services.rbsmbl.memberInfo().getCoverageDetails();
		
			







			

			


	
                //check if member has same name and display birthday if so
            //     for (var i = 0; i < $scope.memberList.length; i++) {
            //     	var member = $scope.memberList[i];
            //     	if (!member.shouldShowDOB) {
            //     		for (var j = i; j<$scope.memberList.length; j++) {
            //     			var otherMember = $scope.memberList[j+1];
            //     			if (!otherMember.shouldShowDOB
            //     				&& member.member.firstName.toUpperCase() === otherMember.member.firstName.toUpperCase()
            //     				&& member.member.lastName.toUpperCase() === otherMember.member.lastName.toUpperCase()) {
            //     				$scope.memberList[i].shouldShowDOB = true;

            //     			$scope.memberList[j+1].shouldShowDOB = true;
            //     		}
            //     	}
            //     }
            // }


  


        }
    ]);
}