module.exports = function(bootstrap) {

    function planProgressV3($scope, $rootScope, services, $q, $filter, sessionData, $cookies, coremetrics, brand, teamsite, $state, finderLogic) {
        $scope.shouldShow = false;

        var planProgress = services.rbsmbl.planProgressV3().fetch();

        var hasProducts = Boolean(services.rbsmbl.memberInfo().getUniqueProducts().length);

        var shouldSuppressModule = sessionData.appConfig && sessionData.appConfig.GLOBAL &&
            sessionData.appConfig.GLOBAL.SUPPRESS_PLAN_PROGRESS_MODULE &&
            sessionData.appConfig.GLOBAL.SUPPRESS_PLAN_PROGRESS_MODULE.booleanValue;

        var enableDropDown = sessionData.appConfig && sessionData.appConfig.GLOBAL
                && sessionData.appConfig.GLOBAL.ENABLE_PLAN_PROGRESS_DROPDOWN
                && sessionData.appConfig.GLOBAL.ENABLE_PLAN_PROGRESS_DROPDOWN.booleanValue;

        var suppressionList = [];
        // $scope.shouldShow = !sessionData.blueEdgeDentalIndv && hasProducts && !shouldSuppressModule;        
        
        teamsite.get("PLANPROGRESS_SUPRESSED_GROUPS").then(function(response) {        
            if (response) {
                response.split(",");
                suppressionList = response;
                var currentGroupNumber = $cookies.get('mbrGroupNumber');
                var showPlannedProgressModule = (suppressionList.indexOf(currentGroupNumber) === -1) ? true :  false;
                if (showPlannedProgressModule) {
                    $scope.shouldShow = !sessionData.blueEdgeDentalIndv && hasProducts && !shouldSuppressModule;
                    if($scope.shouldShow) {
                        $scope.populatePlannedProgressModule();
                    }
                }
            } else {
                console.log('Teamsite Failed.');
            }
        });

        $scope.isGroupSuppressed = function(groupNumber) {
            return isGroupInSuppressionList(groupNumber, suppressionList);
        };

        $scope.areAllGroupsSuppressed = function(plans) {

            if(!plans || !plans.length || !suppressionList || !suppressionList.length){
                return false;
            }

            var aPlan, i;
            for (i = 0; i < plans.length; i++) {
                aPlan = plans[i];
                for (i = 0; i < plans.length; i++) {
                    if (suppressionList.indexOf(aPlan.groupNumber) === -1) {
                        return false;
                    }
                }
            }

            return true;
        };

        $scope.shouldBeExpanded = function(isOpen){
            return enableDropDown? isOpen: true;
        };

        $scope.populatePlannedProgressModule = function() {
        //check for V3 when service is finished!!!!!            
            $scope.heading = 'plan progress';
            $scope.coverageStatus = 'active';
            $scope.today = $filter('date')(new Date(), 'MM/dd/yyyy');
            $scope.hasShowableAccums = false;
            $scope.viewState = 'loading';
            $scope.ppToolTip = "<span data-teamsite='PLANPROGRESS_TOOLTIP_V3'></span>";

            $scope.plans = {};
            $scope.plans.activePlans = [];
            $scope.plans.previousPlans = [];
            $scope.plans.filter = $rootScope.sidebar.data.accumsFilter;
            $rootScope.$watch("sidebar.data.accumsFilter", function(newValue, oldValue){
                $scope.plans.filter = newValue;
            });
            var pageName = $state.current.name;
            // $scope.healthPlansLink = '/login/#/claims/healthPlans';
            $scope.healthPlansLink = '/mhs/#/healthPlans';
            if (pageName.replace(/\.(.*)/, '') === 'claimsAndSpending'){
                // $scope.healthPlansLink = '/login/#/claims-and-spending/healthPlans';
                $scope.healthPlansLink = '/mhs/#/healthPlans';
            }

            planProgress.then(
                function(response) {
                    console.log('V3 response', response);
                    var plans = response.data.payload.activePlan;

                    if (!plans || !plans.length) {
                        $scope.viewState = 'coverageNotFound';
                        return;
                    }


                    $scope.viewState = 'success';

                    function prepData(plans){
                        var plan;
                        var i;
                        var plansList = [];

                        setUniqueNamesForIndividuals(plans);
                        for (i = 0; i < plans.length; i++) {
                            plan = plans[i];

                            plan.isEmptyAccumList = isEmptyAccumList(plan);
                            setAccumDescForEachIndividuals(plan);
                            setIsFamilyAccumForEachAccums(plan);

                            plan.isFamilyPlan = isPlanFamilyPlan(plan);
                            plan.isIndividual = !plan.isFamilyPlan;

                            plan.planStartDate = formatDate(plan.benefitStartDate);;
                            plan.planEndDate = formatDate(plan.benefitEndDate);

                            //TODO fix to handle more than one lob
                            plan.accums = plan.lobList[0] ? plan.lobList[0].accumulations : [];

                            plan.familyAccums = [];
                            plan.individualAccums = [];
                            plan.accums.forEach(
                                function(accum) {
                                    if (accum.isFamilyAccum) {
                                        plan.familyAccums.push(accum);
                                    } else {
                                        plan.individualAccums.push(accum);
                                    }
                                }
                            );
                            plansList.push(plan);
                        }
                        var isWpaCre = finderLogic.getPropertyByBrandCode(brand.current.sharedBrandCode, 'regionIsWpaCre');
                        var isDe = finderLogic.getPropertyByBrandCode(brand.current.sharedBrandCode, 'regionIsDe');
                        var isWv = finderLogic.getPropertyByBrandCode(brand.current.sharedBrandCode, 'regionIsWv');
                        $scope.showAccumDetailText = false;
                        if (isWpaCre || isDe || isWv){
                            $scope.showAccumDetailText = true;
                        }
                        return plansList;
                    }
                    $scope.plans.activePlans = prepData(plans);

                    if (response.data.payload.hasOwnProperty('previousPlan')){
                        plans = response.data.payload.previousPlan;
                        $scope.plans.previousPlans = prepData(plans);
                    }
                    console.log($scope.plans);
                },
                function(error) {
                    $scope.shouldShow = true;
                    $scope.viewState = 'error';
                    $scope.errorId = 'GENERALERROR';
                }
            );
        }

        $rootScope.$on('updatePlanProgressAccums', function(event, resultAccumObj, selectedMember) {
            $scope.heading = 'estimated plan progress';
        });

        $rootScope.$on('recompilePlanProgressMeter', function(event) {
            $scope.heading = 'plan progress';
        });


        $scope.doesAccumHaveIndividualAccums = doesAccumHaveIndividualAccums;
        $scope.toggleDetails = toggleDetails;


    }

    function isGroupInSuppressionList(groupNumber, suppressionList) {
        if (!suppressionList || !suppressionList.length) {
            return false;
        }

        if (suppressionList.indexOf(groupNumber) === -1) {
            return false;
        }

        return true;
    }

    function hasValue(obj) {
        return obj != null && Object.keys(obj).length !== 0;
    }

    function isPlanFamilyPlan(planData) {
        if (!planData || !planData.lobList || !planData.lobList.length) {
            return false;
        }

        var isFamilyPlan = false;

        var i, j;
        for (i = 0; i < planData.lobList.length; i++) {
            if (!planData.lobList[i].accumulations) {
                continue;
            }

            for (j = 0; j < planData.lobList[i].accumulations.length; j++) {
                if (hasValue(planData.lobList[i].accumulations[j].familyAccum)) {
                    return true;
                }
            }
        }

        return isFamilyPlan;
    }


    function formatDate(date) {
        var year = date.slice(0, 4);
        var month = date.slice(4, 6);
        var day = date.slice(6, 8);
        return month + "/" + day + "/" + year;
    }

    function hasPlanData(plans) {
        return Boolean(!plans || !plans.length);
    }

    function setUniqueNamesForIndividuals(plans) {
        var p = 0;
        var i = 0;
        var j = 0;
        var k = 0;
        var firstNameIndividualMap = {};
        var individual = null;

        for (; p < plans.length; p++) {
            aPlan = plans[p];
            if (!aPlan.lobList || !aPlan.lobList.length) {
                continue;
            }


            for (i = 0; i < aPlan.lobList.length; i++) {

                if (!aPlan.lobList[i].accumulations || !aPlan.lobList[i].accumulations.length) {
                    continue;
                }

                for (j = 0; j < aPlan.lobList[i].accumulations.length; j++) {
                    if (!aPlan.lobList[i].accumulations[j].individualAccum ||
                        !aPlan.lobList[i].accumulations[j].individualAccum.individuals ||
                        !aPlan.lobList[i].accumulations[j].individualAccum.individuals.length) {
                        continue;
                    }

                    for (k = 0; k < aPlan.lobList[i].accumulations[j].individualAccum.individuals.length; k++) {
                        individual = aPlan.lobList[i].accumulations[j].individualAccum.individuals[k];
                        if (firstNameIndividualMap[individual.firstName]) {
                            if (firstNameIndividualMap[individual.firstName][individual.dob]) {
                                firstNameIndividualMap[individual.firstName][individual.dob].push(individual);
                            } else {
                                firstNameIndividualMap[individual.firstName][individual.dob] = [individual];
                            }
                        } else {
                            firstNameIndividualMap[individual.firstName] = {};
                            firstNameIndividualMap[individual.firstName][individual.dob] = [individual]
                        }
                    }
                }
            }
        }


        for (var nameKey in firstNameIndividualMap) {
            for (var dobKey in firstNameIndividualMap[nameKey]) {
                if (Object.keys(firstNameIndividualMap[nameKey]).length > 1) {
                    firstNameIndividualMap[nameKey][dobKey].forEach(function(individual) {
                        individual.uniqueName = individual.firstName + " (" + formatDate(individual.dob) + ")";
                    });
                } else {
                    firstNameIndividualMap[nameKey][dobKey].forEach(function(individual) {
                        individual.uniqueName = individual.firstName;
                    });
                }
            }
        }


    }

    function setAccumDescForIndividual(accumulation) {
        var i;
        var individualAccum = accumulation.individualAccum;
        if (individualAccum && individualAccum.individuals) {
            for (i = 0; i < individualAccum.individuals.length; i++) {
                individualAccum.individuals[i].accumDesc = individualAccum.accumDesc;
            }
        }
    }

    function setIsFamilyAccumForEachAccums(aPlan) {
        var i = 0;
        var j = 0;

        if (!aPlan.lobList || !aPlan.lobList.length) {
            return;
        }


        for (i = 0; i < aPlan.lobList.length; i++) {

            if (aPlan.lobList[i].accumulations && aPlan.lobList[i].accumulations.length) {
                aPlan.lobList[i].accumulations.forEach(setIsFamilyAccum)
            }
        }

    }

    function setIsFamilyAccum(accumulation) {
        if (hasValue(accumulation.familyAccum)) {
            accumulation.isFamilyAccum = true;
        } else if (accumulation == null) {
            accumulations.isFamilyAccum = false;
        }
    }

    function setAccumDescForEachIndividuals(aPlan) {

        if (!aPlan.lobList || !aPlan.lobList.length) {
            return;
        }

        var i = 0;
        for (i = 0; i < aPlan.lobList.length; i++) {
            if (aPlan.lobList[i].accumulations && aPlan.lobList[i].accumulations.length) {
                aPlan.lobList[i].accumulations.forEach(setAccumDescForIndividual);
            }
        }

    }

    function isEmptyAccumList(planProgress) {
        if (!planProgress || !planProgress.lobList || !planProgress.lobList.length) {
            return true;
        }

        var i = 0;
        for (; i < planProgress.lobList.length; i++) {
            if (planProgress.lobList[i].accumulations && planProgress.lobList[i].accumulations.length) {
                return false;
            }
        }

        return true;
    }

    function doesAccumHaveIndividualAccums(accum) {
        if (accum &&
            accum.individualAccum &&
            accum.individualAccum.individuals &&
            accum.individualAccum.individuals.length
        ) {
            return true;
        } else {
            return false;
        }
    }

    function toggleDetails(accum) {
        accum.isOpen = !(accum.isOpen == true);
    }

    bootstrap.directive('planProgressV3', function() {
        return {
            replace: true,
            restrict: 'A',
            template: require('directives/planProgressV3/planProgressV3.html'),
            scope: {
                coverageStatus: '@planProgressV3'
            },
            controller: ['$scope', '$rootScope', 'services', '$q', '$filter', 'sessionData', '$cookies', 'coremetrics', 'brand', 'teamsite', '$state', 'finder-logic2',
                planProgressV3
            ]
        }
    });
};