module.exports = function (app) {
    app
        .controller('spendingAlertCtrl',["$scope","$window", "$filter", "$sce", "$cookies", "sessionData", "services", "teamsite",
            function($scope, $window, $filter, $sce, cookies, sessionData, services, teamsite) {

                $scope.alerts = {};
                $scope.showAlertMessage = false;



                var useThis = function(tsValue, fallBackValue) {
                    var strThis = (tsValue && tsValue.length > 0) ? tsValue : fallBackValue;
                    return strThis;

                };


                teamsite.get('MBR_MBRHOME_ALERTS_SPENDING_OUTAGE_AFTER_EFFECTIVE').then(function(resp){
                    $scope.MBR_MBRHOME_ALERTS_SPENDING_OUTAGE_AFTER_EFFECTIVE = resp;
                    return $scope.MBR_MBRHOME_ALERTS_SPENDING_OUTAGE_AFTER_EFFECTIVE;
                });

                teamsite.get('MBR_MBRHOME_ALERTS_SPENDING_OUTAGE_AFTER_EFFECTIVE_2').then(function(resp){
                    $scope.MBR_MBRHOME_ALERTS_SPENDING_OUTAGE_AFTER_EFFECTIVE_2 = resp;
                    return $scope.MBR_MBRHOME_ALERTS_SPENDING_OUTAGE_AFTER_EFFECTIVE_2;
                });

                teamsite.get('MBR_MBRHOME_ALERTS_SPENDING_OUTAGE').then(function(resp){
                    $scope.MBR_MBRHOME_ALERTS_SPENDING_OUTAGE = resp;
                    return $scope.MBR_MBRHOME_ALERTS_SPENDING_OUTAGE;
                });
                teamsite.get('MBR_MBRHOME_ALERTS_SPENDING_OUTAGE_2').then(function(resp){
                    $scope.MBR_MBRHOME_ALERTS_SPENDING_OUTAGE_2 = resp;
                    return $scope.MBR_MBRHOME_ALERTS_SPENDING_OUTAGE_2;
                });
                teamsite.get('MBR_MBRHOME_ALERTS_SERVICE_DOWN_SABLACKOUT').then(function(resp){
                    $scope.MBR_MBRHOME_ALERTS_SERVICE_DOWN_SABLACKOUT = resp;
                    return $scope.MBR_MBRHOME_ALERTS_SERVICE_DOWN_SABLACKOUT;
                });


                var blackoutAlert = function() {
                    var today = new Date();
                    var todayMonth = today.getMonth();
                    todayMonth = todayMonth + 1;

                    var ed = sessionData.memberInfo.clientList[0].spendingAccountBlackoutenddate;
                    var endDate  = new Date(ed);
                    var endMonth = endDate.getMonth();
                    endMonth = endMonth + 1;
                    var endDay = endDate.getDate();
                    var endYear = endDate.getFullYear();
                    var end =  endMonth+ "/" + endDay + "/" + endYear;

                    var bd = sessionData.memberInfo.clientList[0].spendingAccountBlackoutstartdate;
                    var begDate  = new Date(bd);
                    var begMonth = begDate.getMonth();
                    begMonth  = begMonth + 1;
                    var begDay = begDate.getDate();
                    var begYear = begDate.getFullYear();
                    var beg = begMonth+ "/" + begDay + "/" + begYear;

                    $scope.showAlertMessage = sessionData.memberInfo.clientList[0].spendingAccountBlackoutIndicator;
                    if ($scope.showAlertMessage == true) {
                        console.log("///////////////////////////////////");
                        console.log("Disable spending tab");
                        console.log("///////////////////////////////////")
                        //angular.element(document).ready(function () {
                            //console.log('page loading completed');
                           // var navTab = angular.element($window.document.querySelectorAll("a[href='/spa/#/spendingSummary']"));
                            //var menuEle = navTab.querySelectorAll("menu");
                            //console.log ("menu elements",menuEle);

                        //});

                        //disable the Spending Tab
                        //$window.header.disableTab('spending', $scope.alerts.serviceDown) ;



                        var blackoutAlert, blackoutAlert2;
                        var shouldShowAlternateMessage = (todayMonth === endMonth
                        && today <= endDate);

                        if (shouldShowAlternateMessage) {

                            blackoutAlert = useThis($scope.MBR_MBRHOME_ALERTS_SPENDING_OUTAGE_AFTER_EFFECTIVE, 'We are performing scheduled spending account site enhancements from {{begDate}} through {{endDate}}.');
                            blackoutAlert2 = useThis($scope.MBR_MBRHOME_ALERTS_SPENDING_OUTAGE_AFTER_EFFECTIVE_2, 'You received a letter in the mail containing detailed information about what to expect during the enhancement period.  If you have any questions or concerns, please call the number on the back of your member ID card. We&#39;re sorry for the inconvenience and thank you for your patience.');
                        } else {

                            blackoutAlert = useThis($scope.MBR_MBRHOME_ALERTS_SPENDING_OUTAGE, 'We are performing scheduled spending account site enhancements from {{begDate}} through {{endDate}}.');
                            blackoutAlert2 = useThis($scope.MBR_MBRHOME_ALERTS_SPENDING_OUTAGE_2, 'Have questions? Please call the number on the back of your member ID card.');
                        }

                        blackoutAlert = blackoutAlert.split('{{begDate}}').join(beg);
                        blackoutAlert = blackoutAlert.split('{{endDate}}').join(end);
                        $scope.alerts.spendingAccountBlackout = blackoutAlert;
                        $scope.alerts.spendingAccountBlackout_2 = blackoutAlert2;

                        $scope.alerts.serviceDown = useThis($scope.MBR_MBRHOME_ALERTS_SERVICE_DOWN_SABLACKOUT, 'The spending tab is temporarily unavailable.');

                    }

                };


                blackoutAlert();

            }

        ])

        .filter('html', function($sce) {

                    return function(val) {
                        return $sce.trustAsHtml(val);
                    };
                })
        .directive('spendingAlertDirective', function() {
                       return {
                           restrict: 'A',

                           replace: true,
                           template: require('directives/landing/spendingAlerts.html')


                       };
                   });



};