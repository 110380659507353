module.exports = function(bootstrap) {
    bootstrap.run(['Service', 'appId', function(Service, appId) {
        new Service(
            {
                name: 'messageReadFlag',
                group: 'rbsmbl',
                url: '/rbsmbl/x-services/member/messages/v1/readstatusflag',

                update: function(messageList) {
                    var requestPayload = {
                        "reqUpdateMessageReadFlagRequest": {
                            "baseMessage": {
                                "exchangeType": "SENDREQUEST",
                                "headerVersion": "1.0",
                                "username": appId
                            },
                            "payload": {
                                "reqUpdateMessageReadFlagData": messageList
                            }
                        }
                    };

                    return this.post(requestPayload);
                }
            }
        );
    }]);
};