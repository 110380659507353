
module.exports = function(app) {

    var coverageInactive = require("../../modals/covInactiveModal");
    var coverageFuture = require("../../modals/covFutureModal");
    var coverageNoPay = require("../../modals/covNoPayModal");
    var coverageCancelled = require("../../modals/covCancelledModal");
    var bbErrorModal = require("../../modals/benefitBookletError");

    app

        .controller('topBlkCtrl',["$scope","$window", "$filter", "$q", "$cookies", "sessionData", "services", "teamsite", "coremetrics", "brand", "modal", "session", "finder-logic2", "claimService", "spendingActionsNeeded", "emailConfirmationAlert",
            function($scope,$window, $filter, $q, cookies,sessionData, services, teamsite, coremetrics, brand, modal, session, finderLogic, claimService, spendingActionsNeeded, emailConfirmationAlert){

                var useThis = function(tsValue, fallBackValue) {
                    return  (tsValue && tsValue.length > 0) ? tsValue : fallBackValue;

                };
                var setHideCoverageSection = function() {
                    $scope.showCoverage = false;
                    this.covItemsNumber = 0;
                    // this.model.coverageHeaderIsTwoLines = false;

                };
                var doNotDisplayCoverageSection = function(accums, co_s) {
                    var doNotDisplay = false;
                    // we must have accums or co_s AND we must have one of the two tierCodes and it cant be ONA
                    if (((accums == null) && (co_s == null)) || (
                        (!accums.tierCode || accums.tierCode == 'ONA'|| accums.tierCode == '') &&
                        (!co_s.tierCode || co_s.tierCode == 'ONA' || co_s.tierCode == '')
                        )) {
                        return true;
                    }
                    // we don't get here if both accums and co_s are null OR, the tierCodes are both ONA, OR ""
                    // do not display it if the two tierCodes are NOT the same
                    ///if (accums.tierCode != co_s.tierCode) {
                    //  return true;
                    //}
                    return doNotDisplay;
                };
                var displayCoverageInfoSection = function(coverageSummary) {

                    // this is called after the getCoverageSectionInfo ws method has returned

                    var accums = (coverageSummary.accumulations) ? coverageSummary.accumulations : null;

                    var co_s = (coverageSummary.costShareBenefits) ? coverageSummary.costShareBenefits : null;
                    if (doNotDisplayCoverageSection(accums, co_s)) {
                        return;
                    }


                    $scope.showCoverage = false;

                    $scope.arrCoverageDetails = [];

                    var controllingTierCode;
                    if (accums.tierCode && accums.tierCode != 'ONA') {
                        controllingTierCode = accums.tierCode;
                    } else { controllingTierCode = co_s.tierCode; // it can't be ONA here

                    }

                    var cSH;
                    //  MAKE THE CORRECT SECTION HEADER TEXT =====================================
                    switch (controllingTierCode) {
                        case 'INP':
                            if (((coverageSummary.isHomeHost === true) || (coverageSummary.isHomeHost === 'true')) &&
                                (brand.current.sharedBrandCode.toLowerCase() !='hmbcbsde')&& (brand.current.sharedBrandCode.toLowerCase()!='hmbcbswv')) {
                                cSH =  useThis($scope.MBR_MBRHOME_COVERAGE_HOMEHOST_TIERHEADER, 'Home Host <br />In-Network Coverage');
                                $scope.coverageHeaderIsTwoLines = true;
                            }
                            else if((coverageSummary.isHomeHost === true)&&
                                (brand.current.sharedBrandCode.toLowerCase()!='hmbcbsde')&& (brand.current.sharedBrandCode.toLowerCase() =='hmbcbswv')){
                                cSH =  useThis($scope.MBR_MBRHOME_COVERAGE_HOMEHOST__REGION_TIERHEADER,  "In-Network Coverage");
                                $scope.coverageHeaderIsTwoLines = true;
                            }
                            else if((coverageSummary.isHomeHost === true)&&
                                (brand.current.sharedBrandCode.toLowerCase()=='hmbcbsde')&& (brand.current.sharedBrandCode.toLowerCase()!='hmbcbswv')){
                                cSH =  useThis("Level1 <br /> In-Network Coverage");
                                $scope.coverageHeaderIsTwoLines = true;
                            }
                            else {
                                cSH = useThis($scope.MBR_MBRHOME_COVERAGE_TIERHEADERFOUR, 'Preferred Value <br />In-Network Coverage');
                                $scope.coverageHeaderIsTwoLines = true;
                            }
                            break;
                        case 'INE':
                            if (((coverageSummary.isHomeHost === true) || (coverageSummary.isHomeHost === 'true'))&&
                                (brand.current.sharedBrandCode.toLowerCase() !='hmbcbsde')&& (brand.current.sharedBrandCode.toLowerCase()!='hmbcbswv')) {
                                cSH =  useThis($scope.MBR_MBRHOME_COVERAGE_HOMEHOST__TIERHEADER, 'Home Host <br />In-Network Coverage');
                                $scope.coverageHeaderIsTwoLines = true;
                            }
                            else if((coverageSummary.isHomeHost === true)&&
                                (brand.current.sharedBrandCode.toLowerCase() !='hmbcbsde')&& (brand.current.sharedBrandCode.toLowerCase()=='hmbcbswv')){
                                cSH =  useThis($scope.MBR_MBRHOME_COVERAGE_HOMEHOST__REGION_TIERHEADER,  "In-Network Coverage");
                                $scope.coverageHeaderIsTwoLines = true;
                            }
                            else if((coverageSummary.isHomeHost === true)&&
                                (brand.current.sharedBrandCode.toLowerCase() =='hmbcbsde')&& (brand.current.sharedBrandCode.toLowerCase()!='hmbcbswv')){
                                cSH =  useThis( "Level1 <br /> In-Network Coverage");
                                $scope.coverageHeaderIsTwoLines = true;
                            }
                            else {
                                cSH = useThis($scope.MBR_MBRHOME_COVERAGE_TIERHEADERTHREE, 'Enhanced Value <br />In-Network Coverage');
                                $scope.coverageHeaderIsTwoLines = true;
                            }
                            break;
                        case 'INS':
                            cSH = useThis($scope.MBR_MBRHOME_COVERAGE_TIERHEADERNONE, 'In-Network Coverage');
                            $scope.coverageHeaderIsTwoLines = false;
                            break;
                        case 'INA':
                            cSH = useThis($scope.MBR_MBRHOME_COVERAGE_TIERHEADERNONE, 'In-Network Coverage');
                            $scope.coverageHeaderIsTwoLines = false;
                            break;
                        case 'LVL1':
                            cSH = useThis($scope.MBR_MBRHOME_COVERAGE_LEVEL1HEADER, 'Level 1 Coverage');
                            $scope.coverageHeaderIsTwoLines = false;
                            break;
                        case 'LVL2':
                            cSH = useThis($scope.MBR_MBRHOME_COVERAGE_LEVEL2HEADER, 'Level 2 Coverage');
                            $scope.coverageHeaderIsTwoLines = false;
                            break;
                        case 'LVL3':
                            cSH = useThis($scope.MBR_MBRHOME_COVERAGE_LEVEL3HEADER, 'Level 3 Coverage');
                            $scope.coverageHeaderIsTwoLines = false;
                            break;
                        case 'LVL4':
                            cSH = useThis($scope.MBR_MBRHOME_COVERAGE_LEVEL4HEADER, 'Level 4 Coverage');
                            $scope.coverageHeaderIsTwoLines = false;
                            break;
                        case 'LVL5':
                            cSH = useThis($scope.MBR_MBRHOME_COVERAGE_LEVEL5HEADER, 'Level 5 Coverage');
                            $scope.coverageHeaderIsTwoLines = false;
                            break;
                        default:
                            cSH = 'Coverage Summary';
                            $scope.coverageHeaderIsTwoLines = false;
                            break;
                    }            $scope.coverageSectionHeader = cSH;

                    var hasBKPI = false; // Display individual breakpoint and don't display co-pay if individual breakpoint is present to save room.
                    var hasBKPF = false; // Display family breakpoint and don't display co-insurance if family breakpoint is present to save room.
                    if (accums.tierCode && accums.tierCode != 'ONA') {
                        // if we have the properties and values we need, populate the Deduct and OOPmax part of the array and show Coverage
                        if ((accums != null) && (
                            ( accums.deductible && accums.deductible.length > 1 ) ||
                            ( accums.outOfPocketMaximum && accums.outOfPocketMaximum.length > 1)
                            )) {
                            $scope.showCoverage = true;

                            $scope.coverageTooltipMessage = useThis($scope.MBR_MBRHOME_COVERAGE_SUMMARYTTIP,'Your costs vary with different levels of coverage. See your ' +
                                                                    '<a href={tipLink2} alt="Go to Coverage Summary page" tabindex="0">Coverage Summary</a> for details.' );

                            $scope.tipLink2 = useThis($scope.MBR_MBRHOME_COVERAGE_SUMMARYTTIP_HREF, '/mbr/#!/coverage/coverage-summary');

                            //$scope.coverageTooltipMessage = $scope.coverageTooltipMessage.replace(tipLink, tipLink2);

                            // MAKE CORRECT VALUES FOR Deduct, OOPmax, and Breakpoint =====================================
                            var deductible_label, oop_label, bkp_label;
                            switch (accums.familyOrIndividual) {
                                case 'F':
                                    deductible_label = (accums.tierCode.indexOf("LVL")!= -1)? useThis($scope.MBR_MBRHOME_COVERAGE_DEDUCTIBLE, 'Deductible'):useThis($scope.MBR_MBRHOME_COVERAGE_FAMDEDUCTIBLE, 'Family Deductible');
                                    //oop_label = (accums.tierCode.indexOf("LVL")!= -1)? useThis($scope.MBR_MBRHOME_COVERAGE_OOPMAX, 'Out-of-Pocket Max'): useThis($scope.MBR_MBRHOME_COVERAGE_FAMOOPMAX, 'Family Out-of-Pocket Max');
                                    oop_label = (accums.tierCode.indexOf("LVL")!= -1)? useThis($scope.MBR_MBRHOME_COVERAGE_OOPMAX, 'Total Maximum<br />Out-of-Pocket'): useThis($scope.MBR_MBRHOME_COVERAGE_FAMOOPMAX, 'Family Total Maximum<br />Out-of-Pocket');
                                    break;
                                case 'I':
                                    deductible_label = useThis($scope.MBR_MBRHOME_COVERAGE_DEDUCTIBLE, 'Deductible');
                                    oop_label = useThis($scope.MBR_MBRHOME_COVERAGE_OOPMAX, 'Out-of-Pocket Max');//Total Maximum Out-of-Pocket. Old value. matched as per the teamsite value.
                                    bkp_label = useThis($scope.MBR_MBRHOME_COVERAGE_BP, 'Breakpoint');
                                    break;
                                default:
                                    deductible_label = 'Deductible';
                                    oop_label = 'Out-of-Pocket Max';
                                    bkp_label = 'Breakpoint';
                                    break;
                            }
                            if (deductible_label && accums.deductible) {
                                 var cad = $filter('currency')(accums.deductible,"$",0);
                                // console.log("currency accums deductible",cad);
                                $scope.arrCoverageDetails.push({'theDesc': deductible_label, 'theValue': cad, theSortKey: 'a'});
                            }
                            if (oop_label && accums.outOfPocketMaximum) {

                                $scope.arrCoverageDetails.push({'theDesc': oop_label, 'theValue': $filter('currency')(accums.outOfPocketMaximum,'$',0), theSortKey: 'd'});
                            }
                            // Breakpoint can have both Individual and Family at the same time so account for these separate.
                            if(typeof(accums.breakpointI) !== 'undefined' || typeof(accums.breakpointF) !== 'undefined'){
                                switch(accums.familyOrIndividual){
                                    case "F":
                                        hasBKPF = true;
                                        bkp_label = useThis($scope.MBR_MBRHOME_COVERAGE_FAMBP, 'Family Breakpoint');

                                        $scope.arrCoverageDetails.push({'theDesc': bkp_label, 'theValue': accums.breakpointF, theSortKey: 'c'});
                                    case "I":
                                        hasBKPI = true;
                                        bkp_label = useThis($scope.MBR_MBRHOME_COVERAGE_BP, 'Individual Breakpoint');

                                        $scope.arrCoverageDetails.push({'theDesc': bkp_label, 'theValue': accums.breakpointI, theSortKey: 'b'});
                                }
                            }
                        }
                    }

                    var arrCps = (coverageSummary.costShareBenefits && coverageSummary.costShareBenefits.copayInfo) ? coverageSummary.costShareBenefits.copayInfo : null;
                    // COPAYS   PHY/SPC/EMR  =========================================================================
                    var hasEmr, hasSpc, hasPhy = false;
                    if (arrCps != null && arrCps.length && arrCps.length > 0 && !hasBKPI) {

                        for(var i = 0; i < arrCps.length; i++) {
                            switch(arrCps[i].copayCode) {
                                case 'SPC':
                                    arrCps[i].sortKey = 'b';
                                    arrCps[i].theLabel = 'Specialist:';  // TS st
                                    hasSpc = true;
                                    break;
                                case 'PCP':
                                    arrCps[i].sortKey = 'd';
                                    arrCps[i].theLabel = 'PCP:';
                                    hasPhy = true;
                                    break;
                                case 'EMR':
                                case 'ERA':
                                    arrCps[i].sortKey = 'c';
                                    arrCps[i].theLabel = 'ER:';
                                    hasEmr = true;
                                    break;
                                default :
                                    arrCps[i].sortKey = 'a';
                                    arrCps[i].theLabel = 'PCP:';
                                    break;
                            } }  // end of setting sortKey
                        arrCps.sort(function(firstItem, nextItem) {
                            if (firstItem.sortKey < nextItem.sortKey) {
                                return -1;
                            }
                            if (firstItem.sortKey > nextItem.sortKey) {
                                return 1;
                            }
                            return 0;
                        });
                        if (arrCps.length && arrCps.length > 0) {
                            var theString = '';
                            for (var j = 0; j < arrCps.length; j++) {
                                if ((arrCps[j].copayValue != '')) {
                                    theString += arrCps[j].theLabel + ' ';
                                    theString += $filter('currency')(arrCps[j].copayValue, '$',0) + ' ';
                                    theString += '/' + ' ';
                                }
                            }
                            // remove the last "/"
                            theString = theString.substring(0,theString.length - 3);
                            // display the label and the values -----------------------------------
                            if (theString != '') {
                                $scope.arrCoverageDetails.push({'theDesc': 'Copay', 'theValue': theString, theSortKey: 'b'});
                            }
                            $scope.showCoverage = true;

                        }
                    }
                    // COINSURANCE ===================================================================
                    if (coverageSummary && coverageSummary.costShareBenefits && coverageSummary.costShareBenefits.coinsurance && !hasBKPF)  {
                        var coIns = coverageSummary.costShareBenefits.coinsurance.toString();
                        $scope.arrCoverageDetails.push({'theDesc': 'Coinsurance', 'theValue': coIns + '%', theSortKey: 'c'});
                        $scope.showCoverage = true;
                    }
                    $scope.arrCoverageDetails.sort(function(firstItem, nextItem) {
                        if (firstItem.theSortKey < nextItem.theSortKey) {
                            return -1;
                        }
                        if (firstItem.theSortKey > nextItem.theSortKey) {
                            return 1;
                        }
                        return 0;
                    });

                    // ToDo: remove this for production - once service is up
//            if (window.location.host.indexOf('denv') > -1 )
//                // remove one or more items to handle each possible layout
//                arr.splice(1,2);
//            }

                    var covItemsNumber = $scope.arrCoverageDetails.length;

                    if (covItemsNumber == 0) { setHideCoverageSection(); }

                };


                $scope.selectedPlan = {};
                $scope.selectedMember = {};
                $scope.coverageSummary = {};
                $scope.volatileInfo = {};

                $scope.claimsStatus = 'loading';
                $scope.spendingStatus = 'loading';
                $scope.hsaEligible = 'loading'; // 'okay'
                $scope.showGoPaperless = false;
                $scope.showCoverageSummaryTooltip = false;

                $scope.numberOfNewClaims = 0;
                $scope.numberOfSpendingAlerts = 0;
                $scope.isNDBCBS = brand.current.sharedBrandCode.toLowerCase() === "ndbcbs" ? true : false;
                $scope.isWYBCBS = brand.current.sharedBrandCode.toLowerCase() === "wybcbs" ? true : false;
                $scope.isMINCR = brand.current.sharedBrandCode.toLowerCase() === "mincr" ? true : false;

                $scope.isCustomClient = false;
                var PCPData = [];

                teamsite.get('MBR_COSTS_MAY_VARY_TOOLTIP').then(
                    function(response){
                        $scope.coverageTooltipMessage = response;
                    },
                    function(error){
                        console.error(error);
                        $scope.coverageTooltipMessage = "";
                    }
                );
                teamsite.get('MBR_MBRHOME_COVERAGE_HOMEHOST_TIERHEADER').then(function(response){
                    $scope.MBR_MBRHOME_COVERAGE_HOMEHOST_TIERHEADER = response;
                    return $scope.MBR_MBRHOME_COVERAGE_HOMEHOST_TIERHEADER;
                });

                var theBrand = session.data.memberInfo.brand;
                services.rbsmbl.memberInfo().fetch().then(function (fullMemberInfo) {
                    finderLogic.setFullMemberInfo(fullMemberInfo.data.payload);

                    var ums = fullMemberInfo.data.payload;
                    //var curClnt = ums.clientList[0];
                    var curGrp = finderLogic.getPortalSelectedPlan(fullMemberInfo);
                    console.log("current group", curGrp);
//business chk needed for 2 plans in one group
                    $scope.planName = '';
                    if ((cookies.get('mbrGroupNumber') || '') != '') {
                        for (iClntIdx = 0; iClntIdx < ums.clientList.length; ++iClntIdx) {
                            var curClnt = ums.clientList[iClntIdx];
                            // var tmpClnt = ums.clientList[iClntIdx];
                            for (iGrpIdx = 0; iGrpIdx < curClnt.groupList.length; ++iGrpIdx) {
                                var curGrp = curClnt.groupList[iGrpIdx];
                                if (curGrp.groupNumber == cookies.get('mbrGroupNumber')) {
                                    for (iPrdIdx = 0; iPrdIdx < curGrp.productList.length; iPrdIdx++) {

                                        var strBenefitPlanName = '' ;

                                            if(curGrp.hiosName != "" && curGrp.hiosName != null&& curGrp.hiosName.length > 0) {
                                                $scope.planName = curGrp.hiosName;   // planName;
                                                if (curGrp.qhpLevel !="" && curGrp.qhpLevel != null && curGrp.qhpLevel.length > 0) {
                                                    $scope.planName = curGrp.qhpLevel + "-" + curGrp.productList[iPrdIdx].benefitPlanName;
                                                }
                                            }
                                            else {
                                                if(curGrp.productList[iPrdIdx].benefitPlanType === "Medical" || $scope.planName == '') {
                                                    $scope.planName = curGrp.productList[iPrdIdx].benefitPlanName;
                                                }
                                            }
                                    }

                                }
                            }
                        }


                    }


                    var hasCoverage = function (curGrp, strPlan) {
                        var bFound = false;
                        for (iPrdIdx = 0; iPrdIdx < curGrp.productList.length; ++iPrdIdx) {
                            var curProd = curGrp.productList[iPrdIdx];
                            if (curProd.benefitPlanType === strPlan) {
                                bFound = true;
                                break;
                            }
                        }
                        return bFound;
                    };

                    var prdBB = ums.clientList[0].groupList[0].productList[0];
                    //Benefit Booklet
                    var bbParams = {
                        brand: ums.brand
                    };

                    bbParams.payload = {
                        "visionCoverage": hasCoverage(curGrp, "Vision"),
                        "icisClientId": curClnt.clientId,
                        "icisClientNumber": curClnt.clientNumber,
                        "groupNumber": curGrp.groupNumber,
                        "medicalCoverage": hasCoverage(curGrp, "Medical"),
                        "drugCoverage": hasCoverage(curGrp, "Drug"),
                        "coverageCancelDate": (prdBB.memberList[0].coverageCancellationDate != "" ? prdBB.memberList[0].coverageCancellationDate : "00/00/0000"),
                        "curEffDate": (prdBB.coverageBeginDate != "" ? prdBB.coverageBeginDate : "00/00/0000"), // thePlan.coverageBeginDate,
                        "dentalCoverage": hasCoverage(curGrp, "Dental")
                    };

                    var fileName;
                    var bbResponse;
                    var coverageCheckDeferred;

                    var shouldShowBenefitBookletByDefault = function () {
                        if (sessionData.appConfig.LANDING.SUPRESS_BENEFIT_BOOKLET_LINK.booleanValue === true) {
                            return false;
                        }
                        return true;
                    };
                    $scope.showBBLink = false;

                    services.rbsmbl.BenefitBooklet().fetch(bbParams).then(
                        function (data) {
                            bbResponse = data && data.data && data.data.payload;
                            fileName = bbResponse && bbResponse.url;
                            //$scope.linkName = bbResponse && bbResponse.linkName;


                            $scope.showBBLink = !!fileName;
                            //console.log("showbblink filename exists",fileName);
                            if (!$scope.showBBLink) {
                                $scope.showBBLink = shouldShowBenefitBookletByDefault();
                                //$scope.linkName = (!$scope.linkName) ? 'Current Plan-Year' : $scope.linkName;
                            }
                        }, function (errorObj) {
                            $scope.showBBLink = shouldShowBenefitBookletByDefault();
                            //$scope.linkName = (!$scope.linkName) ? 'Current Plan-Year' : $scope.linkName;
                        }
                    );
                    teamsite.get('MBR_BENEFITBOOKLET_QUALTRICS_LINK').then(function (response){
                        $scope.qualtricsLink = response;
                    });
                    $scope.bbClick = function () {
                        if (!fileName) {
                            modal.open({
                                classes: "benefit-booklet-modal",
                                template: require('./landingBBerror.html'),
                                controller: bbErrorModal,
                                scope: $scope
                            });
                        }
                        else{
                            window.open(fileName);
                            if(sessionData.appConfig.LANDING.SHOW_BENEFIT_BOOKLET_QUALTRICS_SURVEY.booleanValue === true){

                                modal.open({
                                    template:'<iframe width="355" height="320" src="'+$scope.qualtricsLink+'"></iframe>'
                                })}
                            $scope.closeModal();
                        }
                    };

                    //Cost Share Benefit
                    var csbParams = {
                        brand: ums.brand
                    };
                    var staticGrp = finderLogic.getPortalSelectedPlan(fullMemberInfo);
                    var curPrd =finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical");
                    csbParams.payload = {
                        // TODO : Set Proper values here from curGrp and curPrd
                        "startDate": curPrd.coverageBeginDate.split("/").join(""), // "05012015", //TheDate.formatDate(curPrd.coverageBeginDate, "MMddyyyy") , // "05012015",  // curPrd.coverageBeginDate,  // 05/01/2014
                        "endDate": curPrd.coverageEndDate.split("/").join(""), // "04302016", //TheDate.formatDate(curPrd.coverageEndDate, "MMddyyyy") , // "04302016",   // curPrd.coverageEndDate   // 04/30/2015
                        "groupNum": staticGrp.groupNumber,
                        "lob": "M",
                        "productId": curPrd.productNumber,
                        "memberPin": curPrd.memberList[0].pinNumber,
                        //PEIP
                        "indCoverageLevel":"",
                        "famCoverageLevel": ""
                    };
                    var dtDate = new Date();
                    var dateToday = new Date();
                    if ( (curPrd.coverageBeginDate < dateToday) && (curPrd.coverageEndDate > dateToday) )
                        dtDate = dateToday ;
                    else if (curPrd.coverageBeginDate > dateToday )
                        dtDateToUse = curPrd.coverageBeginDate ;
                    else if (curPrd.coverageEndDate < dateToday)
                        dtDate = curPrd.coverageEndDate ;

                    var dateISOString = dtDate.toISOString();

                    var dtDateToUse = dateISOString.slice(0,10);


                    var costLevelParams = {
                        "payload": {  // TODO : Set Proper values here from curGrp and curPrd
                            "fromDate": dtDateToUse,  // "05012014",  // curPrd.coverageBeginDate,  // 05/01/2014
                            "toDate": dtDateToUse, // "04302015",   // curPrd.coverageEndDate   // 04/30/2015
                            "groupNumber": curGrp.groupNumber,//if this fails, try staticGrp.groupNumber
                            "productLineCode": "M",
                            "agreementId": ums.agreementId.slice(0,-1),//"L56854744",
                            "pin": curPrd.memberList[0].pinNumber
                        }
                    };

                    setHideCoverageSection();  // initialize the section to be hidden
                    services.rbsmbl.memberCostLevel().fetch(costLevelParams).then(function (response) {
                        //var coverageRequestObj = that.loadCoverageInfoObject();
                        //if (response) {

                            if ((response != null) &&
                                (response.data != null) &&
                                (response.data.MemberCostLevelCodeResponse != null) &&
                                (response.data.MemberCostLevelCodeResponse.baseMessage != null) &&
                                (response.data.MemberCostLevelCodeResponse.baseMessage.returnCode != 404)) {
                                csbParams.payload.indCoverageLevel = response.data.MemberCostLevelCodeResponse.payload.memberCostLevel.costLevelCode;
                                csbParams.payload.famCoverageLevel = response.data.MemberCostLevelCodeResponse.payload.familyMemberCostLevel.costLevelCode;
                            }
                            services.rbsmbl.costShareBenefit().fetch(csbParams).then(function (csbResp) {

                                var getCoverageSummary = function(coverage) {
                                    return coverage &&
                                        coverage.data &&
                                        coverage.data.payload &&
                                        coverage.data.payload.coverageSummary;
                                };
                                 if (!!getCoverageSummary(csbResp)) {
                                     var coverageSummary = getCoverageSummary(csbResp);
                                     //$scope.showCoverageSummaryTooltip = $scope.setCoverageSummaryTooltipVisibility();
                                     // console.log("coverage summary", coverageSummary);
                                     displayCoverageInfoSection(coverageSummary);
                                 } else {throw new Error('The coverage section web service returned null.');
                                             }
                                               }, function (csberr) {$scope.coverageStatus = csberr.status;
                                                throw new Error('The coverage summary service is not available');
                            });
                        //} else {
                        /*    $scope.showCoverage = false;
                            console.log("some error");
                            //that.model.covItemsNumber = 0;

                        }*/
                        // }
                    }, function(objError){
                        // cost level service error.
                        console.log("cost share level service failure", objError);
                    });



                    // Prior Auth Logic
                    var  getLOB = function () {
                        var planObj = finderLogic.getPortalSelectedPlan(ums)
                        var prods = planObj.productList;
                        for (var i = 0; i < prods.length; i++) {
                            if (prods[i].benefitPlanType.toLowerCase() === 'medical') {
                                return prods[i].lob.toUpperCase();
                            }
                        }
                    };
                    $scope.showPriorAuth = false;
                    var dateObj = new Date();
                    //var fullMemberInfo = finderLogic.setFullMemberInfo(fullMemberInfo.data.payload);
                    services.rbsmbl.getMessages().fetch(dateObj,dateObj, ums ,theBrand).then(function(response) {

                        if (response && response.data && response.data.payload) {
                            $scope.showPriorAuth = response.data.payload.unread > 0;
                        }
                        else {
                            $scope.showPriorAuth = false;
                        }


                    },function(error) {$scope.showPriorAuth = false;});

                    // PCP Logic---------------------------------------------------------------------------------------

                    var showPcpModal = function(){
                        teamsite.get('MBR_MBRHOME_CHOOSE_YOUR_PCP_MODAL').then(function(resp) {
                            $scope.chooseYourPcpModal = JSON.parse(resp);
                        }).then(function(){
                            $scope.goToProviderSearch= function() {
                                var pvdURL = "/find-a-doctor/#/home?pcpIndex=1&pcp=true&returnUrl=" + location.host + "/mbr/%23!/account/pi";
                                location.href = pvdURL;
                            }
                            modal.open({
                                template: require('./chooseYourPcpModal.html'),
                                scope: $scope,
                                heading: $scope.chooseYourPcpModal.heading,
                                classes: "sizeOfPcpWindow"
                            });
                        })
                    }

                    var showPCP =  function() {
                        $scope.showPCP = false;
                        $scope.typecheck = false;

                        var pcpEnabled= false,
                            pcpPopupEnabled = false,
                            selectedPlan = finderLogic.getPortalSelectedPlan(ums);

                        if (sessionData.appConfig && sessionData.appConfig.LANDING && sessionData.appConfig.LANDING.PCP_ALERT) {
                            pcpEnabled = sessionData.appConfig.LANDING.PCP_ALERT.booleanValue;
                        }

                        if (sessionData.appConfig && sessionData.appConfig.LANDING && sessionData.appConfig.LANDING.PCP_POPUP) {
                            pcpPopupEnabled = sessionData.appConfig.LANDING.PCP_POPUP.booleanValue;
                        }

                        if (!pcpEnabled && !pcpPopupEnabled) {
                            return false;
                        }
                        else {
                             var dummyPcpPorObject,
                                dummyPcpPorCodes;
                            try {
                                teamsite.get('MBR_MGRLANDING_ALERTS_POR').then(function(resp){
                                    $scope.MBR_MBRLANDING_ALERTS_POR = JSON.parse(resp)
                                }).then(function(resp2){
                                    teamsite.get('MBR_MGRLANDING_ALERTS_PCP').then(function(resp2){
                                        $scope.MBR_MBRLANDING_ALERTS_PCP = JSON.parse(resp2.value);
                                    });
                                }).then(function(){
                                    if ($scope.MBR_MGRLANDING_ALERTS_POR
                                        && $scope.MBR_MGRLANDING_ALERTS_POR.teaserLink
                                        && $scope.MBR_MGRLANDING_ALERTS_POR.teaserLink.indexOf('{location}') >= 0) {

                                        var splitPOR = $scope.MBR_MGRLANDING_ALERTS_POR.teaserLink.split('{location}');
                                        $scope.MBR_MGRLANDING_ALERTS_POR.teaserLink = splitPOR[0] + location.host + splitPOR[1];
                                    }
                                    if ($scope.MBR_MBRLANDING_ALERTS_PCP
                                        && $scope.MBR_MBRLANDING_ALERTS_PCP.teaserLink
                                        && $scope.MBR_MBRLANDING_ALERTS_PCP.teaserLink.indexOf('{location}') >= 0) {

                                        var splitPCP = $scope.MBR_MBRLANDING_ALERTS_PCP.teaserLink.split('{location}');
                                        $scope.MBR_MBRLANDING_ALERTS_PCP.teaserLink = splitPCP[0] + location.host + splitPCP[1];
                                    }
                                })

                            } catch(error) {
                                dummyPcpPorCodes = ["IP6666","000999999"]; //default to hard coded values if the json fails
                                console.error("Error with the JSON content for: MBR_MGRLANDING_ALERTS_POR, MBR_MGRLANDING_ALERTS_PCP or LANDING_PCP_POR_DUMMY_CODES");
                                console.error(error);
                            }

                            var isHMO = false;
                            // generate cookie for PCP/POR
                            for (var i = 0; i < selectedPlan.productList.length; i++) {
                                if ((getLOB() && (getLOB() === 'HMO' || getLOB() === 'POS' || getLOB() === 'GPO')) || (selectedPlan.productList[i].benefitPlanName.toUpperCase() === 'IPA')) {
                                    cookies.put("PCP", true); // this is PCP
                                    $scope.typeCheck = true;
                                }
                                if ((getLOB()) && (getLOB() === 'PPO' || getLOB() === 'EPO')) {
                                    cookies.put("PCP", false); // this is POR, and we check for active coverage differently
                                    $scope.typeCheck = false;
                                }
                                if (!isHMO) {
                                    isHMO = (getLOB() === 'HMO');
                                }
                            }
                            pcpPopupEnabled = pcpPopupEnabled && !(localStorage.getItem('ignorePCPModal') || false) && isHMO;
                            if (!pcpEnabled && !pcpPopupEnabled) {
                                return false;
                            }
                            var listOfMembers = [] ;
                            for (iClntIdx = 0 ; iClntIdx < ums.clientList.length ; ++iClntIdx) {
                                curClnt = ums.clientList[iClntIdx];
                                for (iGrpIdx = 0 ; iGrpIdx < curClnt.groupList.length ; ++iGrpIdx){
                                    if (curClnt.groupList[iGrpIdx].groupNumber == cookies.get("mbrGroupNumber")) {
                                        if (curClnt.groupList[iGrpIdx].productList.length > 0)
                                            var curPrd = curClnt.groupList[iGrpIdx].productList[0];
                                        for (iMbrIdx = 0 ; iMbrIdx < curPrd.memberList.length ; ++iMbrIdx) {
                                            var curMbr = curPrd.memberList[iMbrIdx];
                                            listOfMembers.push({  //  = curClnt.groupList[iGrpIdx].productList[0].memberList;
                                                ciid: "",
                                                clientNumber: curClnt.clientNumber,
                                                covCancellationDate: curMbr.coverageCancellationDate,
                                                coverageEffectiveDate: curMbr.coverageEffectiveDate,
                                                dob: curMbr.contact.birthDate,
                                                ecId: curMbr.ecId,
                                                firstName: curMbr.contact.firstName,
                                                gender: curMbr.gender,
                                                groupNumber: cookies.get("mbrGroupNumber"),
                                                lastName: curMbr.contact.lastName,
                                                middleName: curMbr.contact.middleName,
                                                pinNumber: curMbr.pinNumber,
                                                placement: 0,
                                                productIdx: 0,
                                                relationship: curMbr.relationship,
                                                relationshipCode: curMbr.relationshipCode,
                                                showDOB: false,
                                                suffix: ""
                                            });
                                        }
                                    }
                                }
                            }
                            //We want to loop only once if the first and only member is a subscriber. (Otherwise we'll get stuck if there are no dependents)
                            var loopSubDepTimes = null;
                            var clientLength = listOfMembers.length;
                            if (clientLength == 1 && (listOfMembers[0].relationship == "Subscriber" || listOfMembers[0].relationship == "Dependent" || listOfMembers[0].relationship == "Spouse")){
                                loopSubDepTimes = 1;
                            }
                            else {
                                loopSubDepTimes = 2;
                            }
                            var umi = ums.umi;
                            var subdepLoop = 0;
                            for (var i = 0; i < loopSubDepTimes; i++) {
                                var firstName = (listOfMembers[i] ? listOfMembers[i].firstName.toUpperCase() : '');
                                var memberProfilePayload = {
                                    memberId: umi,
                                    memberIdType: "UMI",
                                    dob: (listOfMembers[i] ? listOfMembers[i].dob : ''),
                                    firstName: firstName
                                };
                                // dojo looked like: MemberProfile.getData(memberProfilePayload, false).then(lang.hitch(this, function (memberProfileData) {
                                    services.rbsmbl.memberProfile().fetch(memberProfilePayload,false).then(function (memberProfileData) {
                                    var mbrGroupNumberCookie = cookies.get("mbrGroupNumber");
                                    var memberProfileIndex;
                                    //We match the cookie with the service response here
                                    //Right now we are finding the right profile only based on contractId - we might need to narrow down more...
                                    for (var k = 0; k < memberProfileData.data.payload.memberProfile.length; k++) {
                                        if (memberProfileData.data.payload.memberProfile[k].contractId == mbrGroupNumberCookie) {
                                            memberProfileIndex = k;
                                            break;
                                        }
                                    }
                                    var pcpPayload = {
                                        agreementNumber: memberProfileData.data.payload.memberProfile[memberProfileIndex].agrNbr,
                                        contractHolderId: memberProfileData.data.payload.memberProfile[memberProfileIndex].contractHolderId,
                                        clientId: memberProfileData.data.payload.memberProfile[memberProfileIndex].clientId,
                                        clientName: memberProfileData.data.payload.memberProfile[memberProfileIndex].clientName,
                                        contractId: memberProfileData.data.payload.memberProfile[memberProfileIndex].contractId,
                                        internalContractId: memberProfileData.data.payload.memberProfile[memberProfileIndex].intContractId,
                                        productIndicator: ""
                                    };

                                    teamsite.get('LANDING_PCP_POR_DUMMY_CODES').then(function(resp){
                                        dummyPcpPorObject = JSON.parse(resp);
                                        dummyPcpPorCodes = dummyPcpPorObject.dummyPcpPorCodes || ["IP6666","000999999"]; //default to hard coded values if the json fails
                                    }).then(function(){
                                        if (listOfMembers[subdepLoop].relationship == "Subscriber") {
                                            //Call the subscriber service so we can get their specific pcpNbr/Name as well as items needed for submit.
                                            services.rbsmbl.subscriberPCP().fetch(pcpPayload).then(function (PCPSubscriberData) {
                                                var pcpObject = {PCPNumber: null};
                                                if(dummyPcpPorCodes.indexOf(PCPSubscriberData.data.payload.pcpNbr) === -1){
                                                    pcpObject.PCPNumber = PCPSubscriberData.data.payload.pcpNbr;
                                                }
                                                //Push the data so it is available to the model and submit information.
                                                PCPData.push(pcpObject);
                                                //If we don't resolve/populate here and there is only one subscriber, it won't get done!
                                                if (loopSubDepTimes == 1) {
                                                    //if (!this.model.hideClaimsAlert + !this.model.hideSpendingAlert + !this.model.hideHSAAlert + this.model.showMsgCntr < 4 && !PCPData[0].PCPNumber)this.model.showPCP = true;
                                                    if(PCPData[0].PCPNumber){
                                                        $scope.showPCP = false;
                                                    }
                                                    else {
                                                        $scope.showPCP = pcpEnabled;

                                                        if (pcpPopupEnabled) {
                                                            showPcpModal();
                                                        }
                                                    }
                                                }
                                            }, function (error) {
                                                console.log("PCPSubscriber SERVICE ERROR!");
                                                console.log(error);
                                            });
                                        }
                                        else {   // if dependent call this service...
                                            //Call the dependent service so we can get their specific pcpNbr/Name as well as items needed for submit.
                                            services.rbsmbl.dependentPCP().fetch(pcpPayload).then(function (PCPDependentData) {
                                                for (var k = 0; k < PCPDependentData.data.payload.length; ++k) {
                                                    var pcpObject = {PCPNumber: null};
                                                    if(dummyPcpPorCodes.indexOf(PCPDependentData.data.payload[k].pcpNbr) === -1){
                                                        pcpObject.PCPNumber = PCPDependentData.data.payload[k].pcpNbr
                                                    }
                                                    //Push the data so it is available to the model and submit information.
                                                    PCPData.push(pcpObject);
                                                }
                                                if(PCPData[0].PCPNumber){
                                                    $scope.showPCP = false;
                                                }
                                                else {
                                                    $scope.showPCP = pcpEnabled;

                                                    if (pcpPopupEnabled) {
                                                        showPcpModal();
                                                    }
                                                }
                                            }, function (error) {
                                                console.log("PCPDependent SERVICE ERROR!");
                                                console.log(error);
                                            });
                                        }
                                        subdepLoop++;
                                    })
                                }, function (error) {
                                    console.log("memberProfile SERVICE ERROR!");
                                    console.log(error);
                                });
                            }
                        }
                    };
                    showPCP();
                });

                $scope.cmChoosePcp = function(isPCP) {
                    var that = this;
                    if(that.cmChoosePcpClicked != true) {
                        var elementTag = (isPCP ? 'PCP Alert' : 'POR Alert');
                        btsGlobal.coremetrics.elementTag(elementTag,'MEMBER:MEMBER HOME');
                        that.cmChoosePcpClicked = true;
                    }
                };
                $scope.cmMemberInfo = function() {
                    var that = this;
                    if(that.cmMemberInfoClicked != true){
                        //btsGlobal.coremetrics.elementTag('View Membership Information','MEMBER:MEMBER HOME');
                        that.cmMemberInfoClicked = true;
                    }
                };

                $scope.viewIDCard = function() {
                    console.log("button clicked");
                    var that = this;
                    if(that.cmViewCardLink != true) {
                        //btsGlobal.coremetrics.elementTag('View ID Card','MEMBER:MEMBER HOME');
                        that.cmViewCardLink = true;
                    }
                    console.log("hello cmViewCardLink", that.cmViewCardLink);
                    console.log("hello2 idCard", this.idCard);
                    if (this.idCard) {
                        if (this.idCard['id'].split('-')[0] == 0) {//0 = past
                            if(that.cmInactiveCard != true) {
                                //btsGlobal.coremetrics.pageviewTag('MEMBER:MEMBER HOME:INACTIVE COVERAGE MODAL','MEMBER:MEMBER HOME');
                                that.cmInactiveCard = true;
                            }
                            modal.open({
                                template: require('modals/covInactiveModal.html'),
                                controller: coverageInactive,
                                scope: $scope
                            });
                        }
                        else if (this.idCard['id'].split('-')[0] == 2) {//2 = future
                            if ((brand.current.sharedBrandCode.toLowerCase() == "ibccr") || (brand.current.sharedBrandCode.toLowerCase() == "ibcah")) {
                                var statusCheckDeferred = $q.defer();
                                statusCheckDeferred.resolve(then(function(response){
                                    this.viewIDCardOrStatusModal(response.data, this.idCard['id']);
                                }));
                                this.validateBeforeViewIdCards(this.idCard['id'], statusCheckDeferred);
                            }
                            else {
                                if(that.cmFutureCard != true){
                                    //btsGlobal.coremetrics.pageviewTag('MEMBER:MEMBER HOME:PRE EFFECTIVE COVERAGE MODAL','MEMBER:MEMBER HOME');
                                    that.cmFutureCard = true;
                                }
                               modal.open({
                                    template: require('modals/covFutureModal.html'),
                                    controller: coverageFuture,
                                    scope: $scope
                                });
                            }
                        }
                        else {
                            if (this.idCard.cancelled) {
                                if (that.cmCancelledModal != true) {
                                    btsGlobal.coremetrics.pageviewTag('MEMBER:MEMBER HOME:CANCELLED COVERAGE MODAL',
                                                                      'MEMBER:MEMBER HOME');
                                    that.cmCancelledModal = true;
                                }
                                modal.open({
                                    template: require('modals/covCancelledModal.html'),
                                    controller: coverageCancelled,
                                    scope: $scope
                                });
                            }
                            else if (this.idCard.hcrProduct && this.idCard.paid !== "paid") {
                                if (that.cmPreEffectuatedCard != true) {
                                    btsGlobal.coremetrics.pageviewTag('MEMBER:MEMBER HOME:PRE EFFECTUATED MODAL', 'MEMBER:MEMBER HOME');
                                    that.cmPreEffectuatedCard = true;
                                }
                                modal.open({
                                    template: require('modals/covNoPayModal.html'),
                                    controller: coverageNoPay,
                                    scope: $scope
                                });
                            }
                            else if (brand.current.sharedBrandCode == "ibccr" || brand.current.sharedBrandCode == "ibcah") {
                                var statusCheckDeferred = $q.defer();
                                statusCheckDeferred.resolve(then(function (response) {
                                    this.viewIDCardOrStatusModal(response.data, this.idCard['id']);
                                }));
                                this.validateBeforeViewIdCards(this.idCard['id'], statusCheckDeferred);
                            }
                            else {
                                var idCard_URL;
                                teamsite.get('MBR_MBRHOME_RIBBON_IDCARD_HREF').then(function(response){
                                    idCard_URL = response;
                                    return idCard_URL ;
                                });
                                $window.location = idCard_URL + '?c=' + this.idCard['id'];
                            }
                        }
                    }
                    else {

                        $window.location.replace("/mbr/#!/account/view-id-card");
                    }
                };



                // selected plan from view site by plan
                services.rbsmbl.vsbpPlans().fetch(theBrand).then(
                    function(plans){
                        var hasSelectedPlan = function(plans) {
                            return plans &&
                                plans.data &&
                                plans.data.payload &&
                                plans.data.payload.planInfo &&
                                plans.data.payload.planInfo.plans &&
                                plans.data.payload.planInfo.plans.selected
                        };
                        var hasSelectedMember = function(plans){
                            return plans &&
                                plans.data &&
                                plans.data.payload &&
                                plans.data.payload.planInfo &&
                                plans.data.payload.planInfo.members;
                        };
                        if (hasSelectedPlan(plans)) {
                            $scope.selectedPlan = angular.extend($scope.selectedPlan, plans.data.payload.planInfo.plans.selected);
                        }
                        if (hasSelectedMember(plans)){
                            $scope.selectedMember = angular.extend($scope.selectedMember, plans.data.payload.planInfo.members);
                        }
                    },
                    function(error){
                        // what to do when this fails?
                        console.log(error);
                    }
                );

                // CLAIMS =================================================================================

                services.rbsmbl.volatileMemberInfo().fetch().then(
                    function(volatileStuff) {
                        var hasVolatileData = function(volatileStuff) {
                            return volatileStuff &&
                                volatileStuff.data &&
                                volatileStuff.data.payload;
                        };
                        $scope.volatileInfo = hasVolatileData(volatileStuff) ?
                            angular.extend($scope.volatileInfo, hasVolatileData(volatileStuff)) : {};
                        $scope.loadingAlerts = false;
                    },
                    function(err) {
                        throw new Error(err);
                    }
                );




                var getNumberOfNewClaims = function (lastClaimPaymentVisitDate) {
                    var canHaveClaims = !!(session.data.benefits.dental == true ||
                    session.data.benefits.drug == true ||
                    session.data.benefits.medical == true
                    );

                    // THIS IS MISSING A lastClaimPaymentVisitDate
                    if (lastClaimPaymentVisitDate && canHaveClaims) {
                        var thisEndDate = session.startTime.toJSON().substring(0,10).replace(/-/g, '');
                        var startDate = Date.parse(lastClaimPaymentVisitDate) - (60 * 24 * 60 * 60 * 1000);
                        startDate = $filter('date')(new Date(startDate), 'yyyyMMdd');
                        var claimsDateRange = {
                            endDate: thisEndDate,
                            startDate: startDate  // this is to be 60
                        };
                        var claimsResponse = claimService.getSummary(JSON.stringify(claimsDateRange));
                        claimsResponse.then(
                            function (claimsResp) {
                                var numberOfClaims = 0;
                                console.log("claims response",claimsResp.summary);
                                if (claimsResp && claimsResp.summary && claimsResp.summary.totalClaimsSinceLastVisit) {

                                    numberOfClaims = claimsResp.summary.totalClaimsSinceLastVisit;
                                }
                                $scope.numberOfNewClaims  = numberOfClaims;
                                $scope.claimsStatus = 'okay';
                            },
                            function(error){
                                $scope.claimsStatus = 'error';
                                console.log(error);
                            });
                    }
                };
                $scope.$watch(function() {return $scope.volatileInfo.lastClaimPaymentVisitDate;}, getNumberOfNewClaims);


                // SPENDING ===============================================================================
                teamsite.get('MBR_MBRHOME_ALERTS_SERVICE_DOWN_SABLACKOUT').then(function(blackoutResp){
                    $scope.MBR_MBRHOME_ALERTS_SERVICE_DOWN_SABLACKOUT = blackoutResp;
                    return $scope.MBR_MBRHOME_ALERTS_SERVICE_DOWN_SABLACKOUT;
                });
                $scope.SABlackout = session.data.memberInfo.clientList[0].spendingAccountBlackoutIndicator;
                $scope.SABlackoutTooltip =  useThis($scope.MBR_MBRHOME_ALERTS_SERVICE_DOWN_SABLACKOUT, '{{MBR_MBRHOME_ALERTS_SERVICE_DOWN_SABLACKOUT}}');
                var getSpendingAccountUpdates = function(plan) {
                    var umiWithSuffix = session.data.memberInfo.umi + '0';
                    $scope.spendingStatus = 'loading';
                    if (session.data.benefits.spending) {
                        var spendingActionsNeededResponse =
                            spendingActionsNeeded.theActions(umiWithSuffix,
                                                             plan.clientNumber,
                                                             session.data.memberInfo.brand);
                        var hasSpendingUpdates = function(theActions) {
                            var hasSuccessfulStatusCode = function(theActions){
                                return theActions &&
                                    theActions.data &&
                                    theActions.data.repActionNeededItemsReply &&
                                    theActions.data.repActionNeededItemsReply.baseMessage &&
                                    theActions.data.repActionNeededItemsReply.baseMessage.returnCode === 200;
                            };
                            var hasSpendingItems = function(theActions) {
                                return theActions &&
                                    theActions.data &&
                                    theActions.data.repActionNeededItemsReply &&
                                    theActions.data.repActionNeededItemsReply.payload &&
                                    theActions.data.repActionNeededItemsReply.payload.repActionNeededItemsData &&
                                    theActions.data.repActionNeededItemsReply.payload.repActionNeededItemsData.actionNeededItems &&
                                    theActions.data.repActionNeededItemsReply.payload.repActionNeededItemsData.actionNeededItems.length > 0;
                            };
                            return hasSuccessfulStatusCode(theActions) && hasSpendingItems(theActions);
                        };
                        spendingActionsNeededResponse.then(
                            function (theActions) {
                                if (hasSpendingUpdates(theActions)) {
                                    var arrS = theActions.data.repActionNeededItemsReply.payload.repActionNeededItemsData.actionNeededItems;
                                    var arrNamesOnly = [];
                                    var arrNoDups = [];
                                    for (var i = 0; i < arrS.length; i++) {
                                        arrNamesOnly.push(arrS[i].name);
                                    }
                                    for (var j = 0; j < arrNamesOnly.length; j++) {
                                        var thisName = arrNamesOnly[j];
                                        // that name is NOT in arrNoDups already, push it there
                                        if (arrNoDups.indexOf(thisName) == -1) {
                                            arrNoDups.push(arrNamesOnly[j]);
                                        }
                                    }
                                    var the_count = arrNoDups.length; // alertArray.length;

                                    $scope.numberOfSpendingAlerts = the_count;
                                    $scope.spendingStatus = (parseInt(the_count) > 0) ? 'okay' : 'do_not_show';
                                } else {
                                    $scope.spendingStatus = '';
                                }
                            },
                            function(error){
                                $scope.spendingStatus == 'error';
                            }
                        );
                    }
                };
                $scope.reloadSpendingService = function(){
                    getSpendingAccountUpdates();
                }

                // MESSAGE CENTER ALERTS =================================================================
                $scope.showMessageCenter = false;
                services.rbsmbl.countUnreadMessages().fetch(session.data.memberInfo).then(
                    function(response) {
                        $scope.numNewMessage = response && response.data && response.data.payload &&
                            response.data.payload[0] && response.data.payload[0].unreadMessagesCount || 0;

                        if ($scope.numNewMessage > 0) {
                            $scope.showMessageCenter = true;
                        }
                    },
                    function (error){
                        console.error(error);
                    }
                );

                $scope.goToMessageCenter = function(url){
                    coremetrics.elementTag('Customer Service Message Center','MEMBER:MEMBER HOME');
                    window.location.href = url;
                };

                // HSA ===================================================================================
                var isEligibleForHSA = function(currentMember) {
                    console.log("member", currentMember);
                    var allowHsa = false;
                    if (currentMember.loggedIn.type == "Subscriber") {
                        // Must be subscriber
                        var clients = session.data.memberInfo.clientList;
                        // console.log("clients", clients);
                        for (var i = 0; i < clients.length; i++) {
                            var client = clients[i];
                            var clientHasHsa = false;
                            var clientHsaEligible = false;
                            if (client.spendingAccountBlackoutIndicator == "false") {
                                // Not blacked out, loop through groups and check for hsa flag
                                for (var j = 0; j < client.groupList.length; j++) {
                                    var group = client.groupList[j];
                                    if (group.hsaExistsIndicator == "true" || group.hsaEnrollmentInProcessIndicator == "true") {
                                        // Client has an HSA already, don't allow any HSA's for this client.
                                        clientHasHsa = true;
                                    }
                                    if (!clientHasHsa) {
                                        if (group.autoEnrollIndicator == "false"
                                            && group.hsaSetupIndicator == "true"
                                            && group.openHsaAccessIndicator == "true"
                                            && group.hsaExistsIndicator == "false"
                                            && group.hsaEnrollmentInProcessIndicator == "false")
                                        {
                                            // Passes initial checks, check product for HDHP and coverage dates
                                            for (var pl = 0; pl < group.productList.length; pl++) {
                                                var product = group.productList[pl];
                                                var currentDate = new Date();

                                                //don't allow members to open HSAs for expired coverages
                                                if ( product.coverageEndDate != "" && product.coverageEndDate != null) {
                                                    var coverageEndDateParts = product.coverageEndDate.split("/");
                                                    var coverageEndDate = new Date(coverageEndDateParts[2], coverageEndDateParts[0] - 1, coverageEndDateParts[1]);
                                                    if ( coverageEndDate.getTime() < currentDate.getTime() ) {
                                                        continue;
                                                    }
                                                }

                                                //don't allow members with void/cancelled dates to open HSAs
                                                var isCoverageCancelled = false;
                                                if (product.coverageVoid == "true") {
                                                    isCoverageCancelled = true;
                                                }
                                                else if (product.coverageCancelDate != "" ) {
                                                    var cancelDateParts = product.coverageCancelDate.split("/");
                                                    var cancelDate = new Date(cancelDateParts[2], cancelDateParts[0] - 1, cancelDateParts[1]);
                                                    if (cancelDate.getTime() < currentDate.getTime() ) {
                                                        isCoverageCancelled = true;
                                                    }
                                                }
                                                if (isCoverageCancelled) {
                                                    continue;
                                                }

                                                if (product.benefitPlanType.indexOf("Medical") != -1 && product.hdhpIndicator == "true") {
                                                    clientHsaEligible = true;
                                                }
                                            }
                                        }
                                    }
                                }
                                if (!clientHasHsa && clientHsaEligible) {
                                    allowHsa = true;
                                }
                            }
                        }
                    }
                    return allowHsa;
                };

                // HSA eligibility based on selected member
                $scope.$watch(function(){return $scope.selectedMember; },
                              function(selectedMember){
                                  if (selectedMember.umi) {
                                      $scope.hsaEligible = isEligibleForHSA(selectedMember) ? 'okay': 'not_eligible';
                                  }
                              }, true
                );


                // // EMAIL ALERT ============================================================================
                // var getEmailConfirmation = emailConfirmationAlert.theAlert(session.data.memberInfo.brand);
                // getEmailConfirmation.then(function(emailAlertResponse) {
                //     var emailAlert = (emailAlertResponse.data && emailAlertResponse.data.payload
                //         && emailAlertResponse.data.payload.communicationAuthenticationStatusDesc )
                //         ? emailAlertResponse.data.payload.communicationAuthenticationStatusDesc : '';
                //     console.log("email alert service",emailAlert);
                //     if (emailAlert == '') {
                //         console.log('The service at x-services/alerts/email returned an unexpected response.');
                //     }
                //     $scope.showEmailConfirmAlert = ((emailAlert == 'Unauthenticated') || (emailAlert == 'Pending')) ? true : false;
                // });

                // GO PAPERLESS ===========================================================================


               services.rbsmbl.AccountSettingsRetrive()
                    .retriveAccountSettings(session.data.groupNumber, "goPaperlessAlert", session.data.memberInfo.brand, session.data.memberInfo.userName)
                    .then(function(accountSettingsData) {
                              $scope.showGoPaperlessAlert = false;
                              if (accountSettingsData && accountSettingsData.data && accountSettingsData.data.payload) {
                                  // NOTES: "IE" is not semantic - we can't tell what it means just from the letters "IE" but the service guy says
                                  //        it means that the user has chosen to 'go paperless'.  The value "UM" here means US Mail (now that's semantic)
                                  //        The logic below comes from CHMMBR, using bootstrap 3.0.5 in file = articles\account\contactPreferenceHelper.js
                                  //        where they set the values for the properties they ultimately use:
                                  //                     preferences.healthStatements.canView
                                  //                     preferences.healthStatements.selected.originalValue
                                  var acctData = accountSettingsData.data.payload;
                                  var emailConfirmed = acctData && acctData.emailConfirmedInd;

                                  if ($scope.isNDBCBS || $scope.isWYBCBS || $scope.isMINCR ){
                                      $scope.showGoPaperlessAlert = false;
                                  }
                                  else if (
                                      acctData.selectedHealthStatements != 'IE'

                                      && session.data.isSubscriber
                                      && acctData.lobCode.toUpperCase() != 'AG'
                                      && acctData.lobCode.toUpperCase() != 'NC'
                                      &&   // the member does NOT have vision and nothing else
                                      !(acctData.visionCoverage == true
                                      && acctData.medicalCoverage == false
                                      && acctData.dentalCoverage == false
                                      && acctData.DrugCoverage == false
                                      )
                                  ) {

                                      $scope.showGoPaperlessAlert = true;
                                  }
                                  else if (sessionData &&
                                      sessionData.appConfig &&
                                      sessionData.appConfig['LANDING'] &&
                                      sessionData.appConfig['LANDING']['GO_PAPERLESS_ALERT_FORCE']) {
                                      console.log("go paperless checking last else if");
                                      var alwaysGoPaperless;
                                      alwaysGoPaperless = sessionData &&
                                          sessionData.appConfig &&
                                          sessionData.appConfig['LANDING'] &&
                                          sessionData.appConfig['LANDING']['GO_PAPERLESS_ALERT_FORCE'];
                                      console.log("always go paperless indicator:",alwaysGoPaperless.booleanValue);
                                        if (alwaysGoPaperless && alwaysGoPaperless.booleanValue) {
                                                $scope.showGoPaperlessAlert = alwaysGoPaperless.booleanValue == true? true:false;

                                  }
                                  }
                                  $scope.showEmailConfirmAlert = ($scope.showGoPaperlessAlert && (emailConfirmed.toLowerCase() != 'confirmed')) ? true : false;
                                  //email alert only shown if GoPaperlessAlert is shown AND has unconfirmed email.

                              }
                          });
            }])

        .filter("copayLabel", function(){
                    return function($value) {
                        switch($value) {
                            case "EMR":
                                return "ER:";
                            case "SPC":
                                return "Specialist:";
                            case "PHY":
                                return "PCP:";
                            default:
                                return "PCP:";
                        }
                    };
                })
        .filter('html', function($sce) {

                    return function(val) {
                        return $sce.trustAsHtml(val);
                    };
                })

        .directive('landingTopBlock', function() {
                       return {
                           restrict: 'A',

                           replace: true,
                           template: require('directives/landing/topBlock.html')


                       };
                   });


};