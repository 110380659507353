module.exports = function(app) {
    app.directive('healthyPrograms', function () {
        return {
            restrict: 'A',
            template: require('directives/landing/healthyPrograms.html'),
            controller: ['$scope', 'sessionData', 'teamsite', 'finder-logic2', 'services', 'brand', 'modal', 'wellnessUtility', 'vendorSSOUtility', 'coremetrics', 'ebillUserExists', function (
                $scope, sessionData, teamsite, finderLogic, services, brand, modal, wellnessUtility, vendorSSOUtility, coremetrics, ebillUserExists) {
                var useThis = function(tsValue, fallBackValue) {
                    return  (tsValue && tsValue.length > 0 && tsValue != "undefined") ? tsValue : fallBackValue;

                };
                var myPassportModal = require("../../modals/passportModal");
                var myPreeffectiveModal = require("../../modals/preEffectiveModal");
                var ums = sessionData.memberInfo;
                var curClnt = ums.clientList[0];
                var curGrp = finderLogic.getPortalSelectedPlan(ums);
                var tsTitle = '';
                var tsDescription = '';
                var eBillAccountExists = false;
                var aps = [];
                var cmRewardsProgramClicked = false;
                var preEffMemberType = ''
                $scope.brandData = brand.current;
                $scope.arrPrograms = [];
                $scope.isND = ums.brand.toLowerCase() == 'ndbcbs';
                $scope.isWY = ums.brand.toLowerCase() == 'wybcbs';
                services.rbsmbl.eligibility().getData().then(function (objSuccessResponse){
                    var dppIndicator = objSuccessResponse.data.payload.dppIndicator;
                    $scope.showDiabetes = ((dppIndicator == "MA") || (dppIndicator == "C"))
                })
                services.rbsmbl.vbbMemberInfo().getData().then(function(memberData) {
                    $scope.vbbinfoData = memberData.data.payload;
                });
                services.rbsmbl.vsbpPlans().fetch(ums.brand).then(
                    function (plans) {
                        $scope.currentMembers = plans.data.payload.planInfo.members;
                        getEbillUserInfo();
                    }
                );
                teamsite.get('MBR_MBRHOME_PROGRAMS_DISCOUNTS').then(function(data) {$scope.memberDiscountsTsTxt = data; });
                teamsite.get('MBR_MBRHOME_PROGRAMS_VALBASED').then(function(data) {$scope.vbbTsText = data;});
                teamsite.get('MBR_MBRHOME_PROGRAMS_VALBASED_LINK').then(function(link) {$scope.vbbTarget = link;});
                teamsite.get('MBR_MBRHOME_PROGRAMS_WELLCHALLENGES_HREF').then(function(href){$scope.wellnessHref = href;})
                teamsite.get('MBR_GLOBAL_WELLTOK_GROUPS').then(function(grpNums){$scope.wellToKGroups = grpNums});
                teamsite.get('MBR_MBRHOME_PROGRAMS_REWARDS_HREF').then(function(href){$scope.rewardsHref = href});
                teamsite.get('MBR_MBRHOME_PROGRAMS_WELLTOK_HREF').then(function(href){$scope.wellToKHref = href});
                teamsite.get('MBR_MBRHOME_MODAL_DEPENDENT_LTDACCESS').then(function(description){$scope.dependentLtdAccessDescrip = description; })
                teamsite.get('MBR_MBRHOME_MODAL_PAYPREMIUM').then(function(message){$scope.payPremiumMessage = message});
                teamsite.get('MBR_MBRHOME_MODAL_PROCESSING_LTDACCESS').then(function(response) {$scope.processingPaymentDescription = response; });
                teamsite.get('MBR_MBRHOME_MODAL_PREEFFECTIVE_LTDACCESS').then(function(response) {$scope.preeffectiveDescription = response; });
                teamsite.get('MBR_MBRHOME_COVCANMODALTXT').then(function(response) {$scope.cancelledDescription = response; });
                teamsite.get('SHARECARE_SOFTLAUNCH_GROUPS_MN').then(function (data){// Restring Welnesspage changes for MN to 8 Groups
                    $scope.shareCareGroupMN = data;
                    $scope.isSharecareMemberMN = false;
                    if($scope.shareCareGroupMN.indexOf(curClnt.groupList[0].groupNumber)>-1){
                        $scope.isSharecareMemberMN = true;
                    }
                    makeArrayPrograms()
                });
                teamsite.get('SHARECARE_SOFTLAUNCH_GROUPS').then(function (data) {
                    $scope.shareCareGroup = data;
                    if ($scope.shareCareGroup.indexOf(curGrp.groupNumber) > -1) {
                        $scope.isSharecareMember = true;
                    }
                });
                wellnessUtility.getQualifierServiceResults().then(
                    function (data){
                        processQualifiers(data);
                    },
                    function(error) {
                        processQualifiers();
                    }
                );

                var processQualifiers = function(data) {
                    data = data || {};
                    $scope.isHcrPreEffectuated = (data.isHCRPreEffectuated == "true");
                    $scope.isNotHcrPreEffecUser = !$scope.isHcrPreEffecUser;
                    $scope.webMDRewardsIndicator = (data.webMDRewardsIndicator == "true");
                    $scope.wellnessRewardType = data.wellnessRewardType;
                    $scope.webMDChallengesIndicator = (data.webMDChallengesIndicator == "true");
                    $scope.wellnessProfileIndicator = (data.wellnessProfileIndicator == "true");
                    coverageIsCancelledFunction();
                    setPreEffectiveType()
                };

                if ($scope.isSharecareMember) {
                    tsTitle = 'MBR_MBRHOME_PROGRAMS_SHARECARE_TITLE'
                    tsDescription = 'MBR_MBRHOME_PROGRAMS_SHARECARE_DESCRIPTION'
                } else if ($scope.isND) {
                    tsTitle = 'MBR_MBRHOME_PROGRAMS_TITLE_NDBCBS'
                    tsDescription = 'MBR_MBRHOME_PROGRAMS_DESCRIPTION_NDBCBS'
                } else if ($scope.isWY) {
                    tsTitle = 'MBR_MBRHOME_PROGRAMS_TITLE_WYBCBS'
                    tsDescription = 'MBR_MBRHOME_PROGRAMS_DESCRIPTION_WYBCBS'
                } else {
                    tsTitle = 'MBR_MBRHOME_PROGRAMS_TITLE'
                    tsDescription = 'MBR_MBRHOME_PROGRAMS_DESCRIPTION'
                }
                // ARRAY OF PROGRAMS MADE AFTER TS CALL TO AVOID RACE CONDITIONS
                // PREEFFECUATED MODAL CALLED HERE TO MAKE SURE IT HAS TS DATA
                teamsite.get(tsTitle).then(
                    function (response) {
                        $scope.programsTitle = response;
                        makeArrayPrograms();
                    }
                );

                teamsite.get(tsDescription).then(
                    function (response) {
                        $scope.programsDescription = response;
                        makeArrayPrograms();
                        if($scope.isHcrPreEffectuated){
                            popupPreEffectuatedModal()
                        }
                    }
                );

               // Behavior only necessary for web md which is only for ND and WY users

                if($scope.isWY || $scope.isND) {
                    // TEAMSITE CALL IS NOT COMING THROUGH
                    if ($scope.isND) {
                        $scope.programsDescription= useThis($scope.ProgramsDescription, "Access common forms members use most - authorizations, claim forms and more.");
                    }
                    if ($scope.isWY) {
                        $scope.programsTitle = useThis($scope.ProgramsTitle, "Health Assessment")
                    }

                    // GET WELLNESS SCORE ================================================================================
                    $scope.hasScore = false;
                    $scope.noScore = true;
                    services.rbsmbl.wellnessProfileScore().fetch().then(function (theResults) {
                        if (theResults && theResults.data && theResults.data.payload && theResults.data.payload.wellnessProfileScore) {
                            $scope.wellnessScore = theResults.data.payload.wellnessProfileScore;
                            $scope.hasScore = true;
                            $scope.noScore = false;
                        } else {
                            $scope.hasScore = false;
                            $scope.noScore = true;
                        }
                    })
                }

// The finderlogic service only looks at plans with a coverageConBeginDate -- this is in case there is a coverageBeginDate (which is used in Dojo)
                var portalSelectedPlanActiveProducts = function() {
                    if (curGrp && curGrp.productList) {
                        var prods = curGrp.productList;
                        portalSelectedPlanActiveProducts = [];
                        var milliServerDate = Date.parse(new Date()); // use serverDate( ) when that is ready in bts
                        for (var i = 0; i < prods.length; i++) {
                            if (prods[i].coverageConBeginDate != "") {
                                var millisBeginDate = Date.parse(prods[i].coverageConBeginDate);
                            }
                            else {
                                var millisBeginDate = Date.parse(prods[i].coverageBeginDate)
                            }
                            var millisEndDate = Date.parse(prods[i].coverageEndDate);
                            var coverageIsCurrent = false;
                            coverageIsCurrent = ((millisBeginDate < milliServerDate) && (milliServerDate < millisEndDate)); // 12/31/2014
                            if (coverageIsCurrent) {
                                portalSelectedPlanActiveProducts.push(prods[i]);
                            }
                        }
                        return portalSelectedPlanActiveProducts;
                    } else {
                        return null;
                    }
                }

                aps = portalSelectedPlanActiveProducts();
                // in case the custom function doesn't work
                if (aps == "undefined" || aps.length == 0) {
                    aps = finderLogic.portalSelectedPlanActiveProducts(ums);
                }

                // The following two functions are only necessary is the user is preEffectuated
                var coverageIsCancelledFunction = function () {
                    var oneCancelled = 0;
                    if (aps) {
                        aps.map(function (activeProduct) {
                            if (activeProduct && activeProduct.coverageCancelDate && activeProduct.coverageCancelDate != "") {
                                new Date(activeProduct.coverageCancelDate) < new Date() ? oneCancelled += 1 : oneCancelled += 0
                            }
                        })
                        oneCancelled == aps.length ? $scope.coverageIsCancelled = true : $scope.coverageIsCancelled = false
                    }
                };

                // GET EBILL INFO FOR BANNER
                var getEbillUserInfo = function() {
                    ebillUserExists.theUrl(sessionData.memberInfo.brand).then(
                        function (response) {
                            if (response) {
                                ebillAccountExists = (response.data.payload == "Y")
                                if (eBillAccountExists == true) {
                                    services.rbsmbl.AccountSettingsRetrive()
                                        .retriveAccountSettings(curGrp.groupNumber, "ccsAccountSettings", ums.brand, ums.userName)
                                        .then(function (accountSettingsData) {
                                            if (accountSettingsData && accountSettingsData.data && accountSettingsData.data.payload) {
                                                $scope.accountRetrieveData = accountSettingsData.data.payload;
                                                var acctData = accountSettingsData.data.payload;
                                                $scope.zip = acctData.zip;
                                            }
                                        })
                                    var umiSuffix = curClnt.groupList[0].umiAgreementIdSuffix;
                                    services.rbsmbl.searchEbillAccounts().getAccounts(ums.umi + $scope.umiSuffix, ums.firstName, $scope.zip, sessionData.memberInfo.brand).then(function (response) {
                                        $scope.eBillInfo = response.data.payload
                                    })
                                }
                            }
                        })
                }

                // PRE EFFECTUATED BANNER

                // This is not working in angular?
                var goToPayNow = function() {
                    $scope.closeModal();
                    window.location = '#!/landing/payNow'
                };

                var setPreEffectiveType = function() {
                    if ($scope.isHcrPreEffectuated == true) {
                        if ($scope.coverageIsCancelled == true) {
                            preEffMemberType = 'preEffectuatedAndCancelled';
                        } else {
                            if (eBillAccountExists == true) {
                                preEffMemberType = 'adultReadyToPayButHasnt';
                            } else {
                                preEffMemberType = 'adultWithNoBillingAccountId';
                            }
                            if ($scope.currentMembers && $scope.currentMembers.loggedIn.type != "Subscriber") {
                                // we use this member type (juvenille) for Non Subscribers. They get the same popup treatment
                                preEffMemberType = 'juvenilleAndAdultHasntPaid';
                            }
                        }
                    } else {
                        preEffMemberType = "none";
                    }
                }

                var popupPreEffectuatedModal =  function(clickedItem) {
                    var usersFirstTimeOnThisPageThisSession = (sessionStorage.getItem('thisPageOpenedThisSession') != 'true');
                    var aGreyedOutItemWasClicked = (typeof clickedItem != 'undefined');
                    sessionStorage.setItem('thisPageOpenedThisSession', true);
                    if ((preEffMemberType != 'none') && ((aGreyedOutItemWasClicked) || (usersFirstTimeOnThisPageThisSession))) {
                    //     // we run this block only if it is the first time the page has been opened this session
                        sessionStorage.setItem('thisPageOpenedThisSession', true);
                        var that = this;
                        $scope.cancelButtonClass = 'text-l'

                        switch (preEffMemberType) {
                            case 'juvenilleAndAdultHasntPaid':
                                if (that.cmNonPayerModal != true) {
                                    coremetrics.pageviewTag('MEMBER:MEMBER HOME:NON PAYER MODAL', 'MEMBER:MEMBER HOME');
                                    that.cmNonPayerModal = true;
                                }
                                $scope.modalTitle = "Unpaid Premium";
                                $scope.modalContent = useThis($scope.dependentLtdAccessDescrip, 'We have not received your first premium payment for the upcoming plan year. Until we do you will have limited access to sections and features of this site.</br></br> Please talk to the person responsible for paying your premium to be sure you are not receiving this message in error.');
                                $scope.modalButtonOneText = 'OK';
                                $scope.modalButtonTwoText = '';
                                $scope.cancelButtonClass = 'juvenileCancelButtonClass'
                                break;
                            case 'adultReadyToPayButHasnt':
                                if (that.cmPayPremiumModal != true) {
                                    coremetrics.pageviewTag('MEMBER:MEMBER HOME:MAKE PAYMENT MODAL', 'MEMBER:MEMBER HOME');
                                    that.cmPayPremiumModal = true;
                                }

                                var msg = useThis($scope.payPremiumMessage, "Your bill account #: <br /><br />We have not received your first premium payment for the upcoming plan year. Until we do you will have limited access to sections and features of this site.<br /><br />Your first payment is due [month, DD, YYYY]. If your payment is not received by the due date, your coverage will be cancelled. Keep in mind, it may take 24-48 hours for your payment to be processed.");
                                msg = msg.split('#:').join($scope.eBillInfo.accounttnumber + ':');
                                msg = msg.split('[month, DD, YYYY]').join($scope.eBillInfo.paymtduedate);

                                $scope.modalTitle = "Pay Your Premium"
                                $scope.modalContent = msg;
                                $scope.modalButtonOneText = 'Cancel';
                                $scope.modalButtonTwoText = 'Pay Now';
                                $scope.buttonTwoDisplay = true;
                                $scope.buttonTwoFunction = goToPayNow;
                                break;
                            case 'adultWithNoBillingAccountId':
                                if (that.cmPolicyProcessingModal != true) {
                                    coremetrics.pageviewTag('MEMBER:MEMBER HOME:POLICY PROCESSING MODAL', 'MEMBER:MEMBER HOME');
                                    that.cmPolicyProcessingModal = true;
                                }
                                $scope.modalTitle = "Processing Enrollment";
                                $scope.modalContent = useThis($scope.processingPaymentDescription, "We are currently processing your enrollment. <br /><br />We have not received your first premium payment for the upcoming plan year. Until we do you will have limited access to sections and features of this site.");
                                $scope.modalButtonOneText = 'OK';
                                $scope.cancelButtonClass = 'juvenilleCancelButtonClass'
                                break;
                            case 'preEffective':
                                $scope.modalTitle = 'LIMITED ACCESS';
                                $scope.modalContent = useThis($scope.preeffectiveDescription, "Your plan period has not yet started. Until it does, you will have limited access to sections and features of this site.");
                                $scope.modalButtonOneText = 'OK';
                                if(that.cmLimitedAccess != true) {
                                    coremetrics.pageviewTag('MEMBER:MEMBER HOME:LIMITED ACCESS MODAL','MEMBER:MEMBER HOME');
                                    that.cmLimitedAccess = true;
                                }
                                break;

                            case 'preEffectuatedAndCancelled':
                                $scope.modalTitle = "YOU HAVE LIMITED ACCESS TO THIS WEBSITE";
                                $scope.modalContent = useThis($scope.cancelledDescription, "Our records show that you do not have a health plan with us at this time. You will have limited access to sections and features of this site. If you think this is an error, let us know. You can use the Contact Us link at the bottom of this page. We’ll get back to you within 2-3 business days.");
                                $scope.modalButtonOneText = 'OK';
                                $scope.cancelButtonClass = 'juvenilleCancelButtonClass'
                                break;
                        }
                        if (preEffMemberType == 'preEffective') {
                            modal.open({
                                classes: "wellnessLinksModals",
                                template: require('modals/preEffectiveModal.html'),
                                controller: myPreeffectiveModal,
                                scope: $scope}
                            );
                        }
                        else {
                            modal.open({
                                classes: "wellnessLinksModals",
                                template: require('modals/payNowModal.html'),
                                controller: myPreeffectiveModal,
                                scope: $scope
                            })
                        }
                    }
                }

                $scope.cmHPLauncher = function(localId) {
                    switch (localId) {
                        case 'idMemberDiscounts':
                            if(this.cmMemberDiscountsClicked != true){
                                coremetrics.elementTag('Member discounts','MEMBER:MEMBER HOME');
                                this.cmMemberDiscountsClicked = true;
                            }
                            break;
                        case 'idVBB':
                            if(this.cmValueBasedClicked != true){
                                coremetrics.elementTag('Value Based BenefitsP','MEMBER:MEMBER HOME');
                                this.cmValueBasedClicked = true;
                            }
                            break;
                        case 'idWellnessChallenges':
                            if(that.cmWellnessChallengesClicked != true){
                                // Site promotion tag
                                coremetrics.createManualLinkClickTag($scope.wellnessHref,null,null);
                                this.cmWellnessChallengesClicked = true;
                            }
                            break;
                        case 'idRewards':
                            if(this.cmRewardsProgramClicked != true){
                                coremetrics.elementTag('Rewards Program','MEMBER:MEMBER HOME');
                                this.cmRewardsProgramClicked = true;
                            }
                            break;
                        case 'idWellnessProfile':
                            if(this.cmWellnessProfileClicked != true){
                                coremetrics.elementTag('Wellness Profile1','MEMBER:MEMBER HOME');
                                this.cmWellnessProfileClicked = true;
                            }
                            break;
                        case 'idPassportRewards':
                            if(this.cmPassportRewardsClicked != true){
                                coremetrics.elementTag('Passport Rewards','MEMBER:MEMBER HOME');
                                this.cmPassportRewardsClicked = true
                            }
                            break;
                        case 'idDPP':
                            if(this.cmDPP != true){
                                coremetrics.elementTag('LANDING DPP LINK' ,'MEMBER:MEMBER HOME');
                                this.cmDPP = true;
                            }
                    }
                }

                // Value Based Benefits
                var lowercaseBrand = ums.brand.toLowerCase();
                var arrayVbsStateCodes = ['hmbcbs', 'hbcbs', 'highmarkbcbs', 'hbs', 'pbs', 'hmbs',"hmbcbswv", "ms", "highmarkbcbswv", "mbh:wv","hmbcbsde", "highmarkbcbsde", "de","pp","hhic","az","fl", "mbh:fl","neb","inac", "ibc", 'mincr', 'la'];
                var memberIsInVbbBrand = (arrayVbsStateCodes.indexOf(lowercaseBrand)>-1);
                var hasActiveMedicalPlan = sessionData.benefits.medicalActive;
                var hasVbbObject = false;
                var vbbObject = 0;

                if (aps) {
                    for (var i = 0; i < aps.length; i++) {
                        if (aps[i].valueBasedBenefits && typeof aps[i].valueBasedBenefits.valueBasedBenefitsSignedUp == 'boolean') {
                            vbbObject += 1;
                        }
                    }
                    vbbObject > 0 ? hasVbbObject = true : hasVbbObject = false;
                }

                $scope.showValueBased = (memberIsInVbbBrand && hasActiveMedicalPlan && hasVbbObject) ? true : false;

                // Functions necessary to calculate whether programs appear

                var hasThisPlanType = function(stringName) {
                    if (aps != null) {
                        for (var i = 0; i < aps.length; i++) {
                            if (aps[i].benefitPlanName.toUpperCase() == stringName) {
                                return true;
                            }
                            else return false;
                        }
                    }
                        else return false;

                }

                var coreShowProgramGroup = function() {
                    if (typeof $scope.membInCoreProgramGroup == 'undefined') {
                        var isHHIC = $scope.brandData.brandVisibility.indexOf('hhic') > -1;
                        // Show for WPA, DE, CRE, WV ---------------------------------
                        var theRegion = $scope.brandData.domain;
                        var theseRegions = (theRegion == 'highmarkbcbs.com' || theRegion == 'highmarkbcbsde.com' ||
                            theRegion == 'highmarkblueshield.com' || theRegion == 'highmarkbcbswv.com' ) ? true : false;

                        // IBC National Alliance and Minnesota National Alliance are handled here
                        var isNationalAllianceMember = ($scope.brandData.sharedBrandCode == 'ibc') ||
                            ($scope.brandData.sharedBrandCode == 'mn');

                        var brandState = $scope.brandData.sharedBrandCode;
                        var arrayStateCodes = ['hmbcbs', 'hbcbs', 'highmarkbcbs', 'hbs', 'pbs', 'hmbs', 'hmbcbswv', 'ms', 'highmarkbcbswv', 'mbh:wv', 'hhic', 'az', 'fl', 'mbh:fl', 'exc', 'lou', 'neb', 'regbcbs', 'regbs', 'wi', 'mn', 'idh', 'la'];
                        var isMemberDiscountsRegion = arrayStateCodes.indexOf(brandState) > -1;

                        $scope.membInCoreProgramGroup = Boolean(isHHIC || theseRegions || isMemberDiscountsRegion || isNationalAllianceMember);
                    }
                }

                var showCMR = function() {
                    if (typeof(aps) == "undefined" || aps == null) {
                        return false;
                    } else {
                        for (var i = 0; i < aps.length; i++) {
                            if (typeof(aps[i].memberList[0].packages) == "undefined") {
                                return false;
                            }
                            else if (aps[i].memberList[0].packages[0].packageCode == "CONDITION MNGT" && aps[i].memberList[0].packages[0].memberEligibility && $scope.brandData.sharedBrandCode.toLowerCase() == 'mincr') {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                    }
                }

                var showECMR = function() {
                    if (typeof(aps) == "undefined" || aps == null) {
                        return false;
                    } else {
                        for (var i = 0; i < aps.length; i++) {
                            if (typeof(aps[i].memberList[0].packages) == "undefined") {
                                return false;
                            }
                            else if (aps[i].memberList[0].packages[0].packageCode == "PROTOCOL COMPL" && aps[i].memberList[0].packages[0].memberEligibility && $scope.brandData.sharedBrandCode.toLowerCase() == 'mincr') {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                    }
                }

                var passportRewards = function() {
                    var planList = ['SECURITYBLUE HMO','SECURITY BLUE HMO','FREEDOMBLUE PPO','FREEDOM BLUE PPO','COMMUNITY BLUE MEDICARE HMO'];
                    return planList.some(hasThisPlanType);
                }

                $scope.cmElementTagClick = function(tagDesc) {
                    if (tagDesc == "passportRewards") {
                        coremetrics.elementTag('Earn Passport Rewards Modal Link', 'Member: Member Home')
                    }
                }

                $scope.cmElementTagClick = function(tagDesc) {
                    if (tagDesc == "passportRewards") {
                        coremetrics.elementTag('Earn Passport Rewards Modal Link', 'Member: Member Home')
                    }
                }

                var displayEarnPassportRewards = function(localId) {
                    if (localId === 'idPassportRewards') {
                        modal.open({
                            classes: "wellnessLinksModals",
                            template: require('modals/passportModal.html'),
                            controller: myPassportModal,
                            scope: $scope
                        });
                    }
                }

                // For WEB MD FOR WY and ND

                var rewardsItem = function() {
                    if (!$scope.membInCoreProgramGroup) {
                        coreShowProgramGroup()
                    }
                    var coreCriteria = $scope.membInCoreProgramGroup;
                    var hasRewardsPlan = false; //this.hasThisPlanType('Service - WebMD Rewards');
                    var hasBlueExtraBasic = sessionData.isBlueExtraBasic;
                    var isWelltokGroup = $scope.wellToKGroups == curGrp.groupNumber;
                    var showRewardsOnly = $scope.brandData.sharedBrandCode.toLowerCase() == 'mincr';
                    hasRewardsPlan = $scope.webMDRewardsIndicator;
                    return (((coreCriteria || showRewardsOnly) && hasRewardsPlan) || (isWelltokGroup) && (!hasBlueExtraBasic));
                }

                // FOR SINGLE SIGN ON

                var doWebMdSso = function(targetTypeId) {
                    var cmKeyValue = '';
                    var theTargetType = '';
                    if (aps == "undefined" || aps.length == 0) {
                        aps = curGrp.productList
                    }
                    var index = aps[0].memberList.map(function(member) {return member.contact.birthDate}).indexOf(ums.dob)
                    if (targetTypeId == 'idRewards') {
                        theTargetType = 'WELLNESS_REWARDS'
                        if ($scope.wellnessRewardType == 'Webmd Rewards') {
                            cmKeyValue = '?cm_sp=WebMD-_-Rewards%20Program%20WebMD-_-Rewards%20Program';
                            doRewardWebMdSso(cmKeyValue, theTargetType)
                        }
                        else if ($scope.wellnessRewardType == 'WellTok') {
                            cmKeyValue = '?cm_sp=Welltok-_-Rewards%20Program%20Welltok-_-Rewards%20Program';
                            if (cmRewardsProgramClicked != true) {
                                cmRewardsProgramClicked = true;
                            }
                            var postData = {
                                "ecid": aps[0].memberList[index].ecId,
                                "umi": ums.umi,
                                "ciid": aps[0].memberList[index].coveredIndividualId,
                                "clientNumber": curClnt.clientNumber,
                                "groupNumber": curGrp.groupNumber,
                            };

                            var wellToKResponse = services.rbsmbl.WellTok().fetch(postData);
                            handleSsoResponse(wellToKResponse, cmKeyValue, "_self");
                        }
                    }
                    else if (targetTypeId == 'idWellnessProfile') {
                        cmKeyValue = '?cm_sp=WebMD-_-Wellness%20Profile-_-Wellness%20Profile';
                        theTargetType = 'WELLNESS_PROFILE';
                        doRewardWebMdSso(cmKeyValue, theTargetType)
                    }
                }

                var doRewardWebMdSso = function(cmKeyValue, theTargetType) {
                    $scope.loadingWellnessProfile = true;
                    var returnUrl = "/login/#/landing";
                    var returnPage = "Member_Home";
                    var myCmSitePromotion = cmKeyValue ? cmKeyValue : '';
                    var ssoParameters = {
                        clientNumber: curClnt.clientNumber,
                        groupNumber: curGrp.groupNumber,
                        targetType: theTargetType,
                        category: 'WELLNESS',
                        returnUrl: returnUrl,
                        returnPage: returnPage,
                        brand: $scope.brandData.sharedBrandCode.toUpperCase()
                    };
                    vendorSSOUtility.performSSO(ssoParameters, '_self', myCmSitePromotion, false);
                    $scope.loadingWellnessProfile = false;
                }

                var handleSsoResponse = function(response, cmKeyValue, windowName) {

                    if (response !== null) {
                        $scope.loadingWellnessProfile = true;
                        response.then(function (vendorSSOStuff) {
                            vendorSSOUtility.genericHandleSSOResponse(vendorSSOStuff, cmKeyValue, windowName);
                            $scope.loadingWellnessProfile = false;
                        }, function (error) {
                            vendorSSOUtility.genericHandleSSOFailure(error, windowName);
                        });
                    } else {
                        vendorSSOUtility.genericHandleSSOFailure("", windowName);
                    }
                }

                // Where PROGRAMS ARE ADDED TO ARRAY IN ORDER TO DISPLAY

                var makeArrayPrograms = function() {
                    coreShowProgramGroup();
                    $scope.arrPrograms = [
                        {   // 'MEMBER DISCOUNTS',
                            localId: 'idMemberDiscounts',
                            the_text: 'Member Discounts',
                            showThis: $scope.membInCoreProgramGroup,
                            appearDisabled: false,
                            imgUrl: 'images/landing/tools-and-programs/memberDiscounts.png',
                            linkTarget: '_target',
                            target: '/login/#/coverage/medical-benefits?scroll=memberDiscounts'
                        },
                        {   // VALUE-BASED BENEFITS
                            localId: 'idVBB',
                            the_text: 'Value-Based Benefits',
                            showThis: $scope.showValueBased,
                            appearDisabled: false,
                            imgUrl: 'images/landing/tools-and-programs/valueBased.png',
                            target: $scope.vbbTarget
                        },
                        {   // 'PASSPORT REWARDS',
                            localId: 'idPassportRewards',
                            the_text: 'Highmark Passport Rewards',
                            showThis: passportRewards(),
                            appearDisabled: false,
                            useFunctionOnClick: true,
                            clickHandler: displayEarnPassportRewards
                        },
                        { // Diabetes Prevention
                            localId: 'idDPP',
                            the_text: 'DIABETES PREVENTION',
                            showThis: $scope.showDiabetes,
                            appearDisabled: false,
                            imgUrl: 'images/landing/tools-and-programs/dppIcon.png',
                            linkTarget: '_self',
                            target: '/login/#/wellness/dppDetails'

                        },
                        {   // CARE MANAGEMENT REWARD
                            localId: 'idCMR',
                            the_text: 'CARE MANAGEMENT REWARD',
                            showThis: showCMR(),
                            appearDisabled: false,
                            imgUrl: 'images/landing/tools-and-programs/cmr.png',
                            linkTarget: '_self',
                            target: '/login/#/wellness/ccs'

                        },
                        {
                            localId: 'idECMR',
                            the_text: 'ENHANCED CARE MANAGEMENT REWARD',
                            showThis: true,
                            showThis: showECMR(),
                            appearDisabled: false,
                            imgUrl: 'images/landing/tools-and-programs/cmr.png',
                            linkTarget: '_self',
                            target: '/login/#/wellness/ccs'
                         }
                    ]

                    // currently these Teamsite calls seem to be for testing links on old testing sites. Not sure if they are necessary or how they should be populated
                    // var tsResponse=this.model.clientGroupProductQualifiedTSContent;
                    //
                    // var additionalLinks=[{
                    //     'linkObj': tsResponse.MBR_MBRHOME_WELLNESS_PROGRAMS_LINK1?JSON.parse(tsResponse.MBR_MBRHOME_WELLNESS_PROGRAMS_LINK1):tsResponse.MBR_MBRHOME_WELLNESS_PROGRAMS_LINK1,
                    //     'imgUrl': tsResponse.MBR_MBRHOME_WELLNESS_PROGRAMS_LINK1_IMG
                    // },{
                    //     'linkObj': tsResponse.MBR_MBRHOME_WELLNESS_PROGRAMS_LINK2?JSON.parse(tsResponse.MBR_MBRHOME_WELLNESS_PROGRAMS_LINK2):tsResponse.MBR_MBRHOME_WELLNESS_PROGRAMS_LINK2,
                    //     'imgUrl': tsResponse.MBR_MBRHOME_WELLNESS_PROGRAMS_LINK2_IMG
                    // }, {
                    //     'linkObj': tsResponse.MBR_MBRHOME_WELLNESS_PROGRAMS_LINK3?JSON.parse(tsResponse.MBR_MBRHOME_WELLNESS_PROGRAMS_LINK3):tsResponse.MBR_MBRHOME_WELLNESS_PROGRAMS_LINK3,
                    //     'imgUrl': tsResponse.MBR_MBRHOME_WELLNESS_PROGRAMS_LINK3_IMG
                    // }
                    // ];
                    //
                    // // filters the additional responsive links
                    // for(i=0; i<additionalLinks.length; i++){
                    //     if(additionalLinks[i].linkObj && additionalLinks[i].linkObj.target && additionalLinks[i].linkObj.target.length>0) {
                    //         additionalLinks[i].showThis=true;
                    //         additionalLinks[i].the_text=additionalLinks[i].linkObj.defaultText;
                    //         additionalLinks[i].target=additionalLinks[i].linkObj.href;
                    //         additionalLinks[i].linkTarget=additionalLinks[i].linkObj.target;
                    //         m.arrPrograms[m.arrPrograms.length + i] = additionalLinks[i];
                    //     }
                    // }

                    if($scope.isHcrPreEffectuated) {
                        var arrItems = ['idWellnessProfile', 'idRewards', 'idVBB'];
                        $scope.arrPrograms.forEach(function (item) {
                            var i = arrItems.indexOf(item.localId);
                            if (i > -1) {
                                if (item.showThis == true) {
                                    item.target = 'javascript: return false';
                                    item.appearDisabled = true;
                                    item.useFunctionOnClick = true;
                                    item.clickHandler = function (localId) {
                                        cmHPLauncher(localId);
                                        popupPreEffectuatedModal(this);
                                    }
                                }
                            }
                        });
                    }
                    if($scope.isSharecareMemberMN) {
                        $scope.arrPrograms.push({    //MN Group Changes
                            localId: 'idShareCare',
                            the_text: 'ShareCare',
                            showThis: true,
                            appearDisabled: false,
                            imgUrl: 'images/landing/tools-and-programs/sharecare_landing.png',
                            linkTarget: '_blank',
                            target: 'https://bluecrossmn.sharecare.com/',
                            sharecareflagdisplay: 'true'

                        })
                        $scope.arrPrograms.push({    //MN Group Changes
                            localId: 'idallwellnessprograms',
                            the_text: 'All Wellness Programs',
                            showThis: true,
                            appearDisabled: false,
                            linkTarget: '',
                            target: '/login/#/wellness',
                            allwellnessflag: 'true'
                        })
                    }
                    if($scope.isND) {
                        // HIPAA
                        $scope.arrPrograms.push({
                            localId: 'idHIPAA',
                            the_text: 'HIPAA AUTHORIZATION',
                            showThis: true,
                            appearDisabled: false,
                            //imgUrl: ' ',
                            linkTarget: '_blank',
                            target: '/login/#/HIPAA'

                        })
                        // PRENATAL
                        $scope.arrPrograms.push( {
                            localId: 'idPRENATAL',
                            the_text: 'PRENATAL PLUS PROGRAM',
                            showThis: true,
                            appearDisabled: false,
                            //imgUrl: ' ',
                            linkTarget:'',
                            target: '/login/#/prenatal'

                        })
                    }
                    if($scope.isWY) {
                        $scope.arrPrograms.push({   // GET STARTED
                            localId: 'idGETSTARTED',
                            the_text: 'GET STARTED',
                            showThis: true,
                            appearDisabled: false,
                            // imgUrl: ' ',
                            linkTarget: '_blank',
                            target: '/emssvc/x-services/secure/sso/outbound/auto/v1/vendor/WEBMDWY'
                        })
                    }
                    if($scope.isWY || $scope.isND) {
                        $scope.arrPrograms.push({// 'WELLNESS CHALLENGES'
                            localId: 'idWellnessChallenges',
                            the_text: 'Wellness Challenges',
                            showThis: $scope.webMDChallengesIndicator,
                            appearDisabled: false,
                            imgUrl: 'images/landing/tools-and-programs/webMDChallenges.png',
                            target:  $scope.wellnessHref
                            // target: '/chmptl/chm/jsp/webMDSingleSignOn.do?startId=713&hmLinkcodeValue=MBR_POST_LAND&hmLinkName=Member Home'},
                        })
                            $scope.arrPrograms.push({   // REWARDS
                            localId: 'idRewards',
                            the_text: 'Rewards Program',
                            showThis: rewardsItem(),
                                appearDisabled: false,
                            imgUrl: 'images/landing/tools-and-programs/webMDRewards.png',
                            target: '',
                            useFunctionOnClick: true,
                            clickHandler: doWebMdSso
                        })
                        $scope.arrPrograms.push({   // WELLNESS PROFILE
                            localId: 'idWellnessProfile',
                            the_text: 'Wellness Profile',
                            subItem: {
                                the_text:'Wellness Profile score: ' + $scope.wellnessScore,
                                localId: 'idWellnessProfileScore',
                                showThis: $scope.hasScore
                            },
                            showThis: $scope.wellnessProfileIndicator,
                            appearDisabled: false,
                            hasWelcomeTooltip: true,
                            imgUrl: 'images/landing/tools-and-programs/wellnessProfile.png',
                            useFunctionOnClick: true,
                            clickHandler: doWebMdSso
                            })
                    }
                }
            }]
        }
    })
}






































