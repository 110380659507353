module.exports = function(app) {
    app.directive('tempTeamsite', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/landing/tempTeamsite.html'),
            scope: true,
            controller: function($scope) {


              $scope.arrTsIds = ['MBR_MBRHOME_ALERTS_SPENDING_OUTAGE',
                    'MBR_MBRHOME_HEALTHTOOLS_PAYPREMIUM_LINK_HREF',
                    'MBR_MBRHOME_ALERTS_SPENDING_OUTAGE_2',
                    'MBR_MBRHOME_ALERTS_SERVICE_DOWN',
                    'MBR_MBRHOME_SMART_MESSAGE',
                    'MBR_MBRHOME_HEALTHTOOLS_TITLE',
                    'MBR_MBRHOME_HEALTHTOOLS_DESCRIPTION',
                    'MBR_MBRHOME_HEALTHTOOLS_COMPCOST_LINK',
                    'MBR_MBRHOME_HEALTHTOOLS_SAVINGSADV_LINK',
                    'MBR_MBRHOME_HEALTHTOOLS_SAVINGSADV_LINK_MINCR',
                    'MBR_MBRHOME_HEALTHTOOLS_PROVIDER_LINK',
                    'MBR_MBRHOME_HEALTHTOOLS_PAYPREMIUM_LINK',
                    'MBR_MBRHOME_HEALTHTOOLS_PROVIDER_LINK_HREF',
                    'MBR_MBRHOME_HEALTHTOOLS_COMPCOST_LINK_HREF',
                    'MBR_MBRHOME_HEALTHTOOLS_SAVINGSADV_LINK_HREF',
                    'MBR_GLOBAL_WELLTOK_GROUPS',
                    'MBR_MBRHOME_PROGRAMS_TITLE',
                    'MBR_MBRHOME_PROGRAMS_SHARECARE_TITLE',
                    'SHARECARE_SOFTLAUNCH_GROUPS',
                    'MBR_MBRHOME_PROGRAMS_DESCRIPTION',
                    'MBR_MBRHOME_PROGRAMS_TITLE_NDBCBS',
                    'MBR_MBRHOME_PROGRAMS_DESCRIPTION_NDBCBS',
                    'MBR_MBRHOME_PROGRAMS_TITLE_WYBCBS',
                    'MBR_MBRHOME_PROGRAMS_DESCRIPTION_WYBCBS',
                    'MBR_MBRHOME_PROGRAMS_DISCOUNTS',
                    'MBR_MBRHOME_PROGRAMS_DISCOUNTS_HREF',
                    'MBR_MBRHOME_PROGRAMS_WELLPROFILE',
                    'MBR_MBRHOME_PROGRAMS_WELLPROFILE_HREF',
                    'MBR_MBRHOME_PROGRAMS_WELLPROFILE_SCORE',
                    'MBR_MBRHOME_PROGRAMS_REWARDS',
                    'MBR_MBRHOME_PROGRAMS_REWARDS_HREF',
                    'MBR_MBRHOME_PROGRAMS_WELLTOK_HREF',
                    'MBR_MBRHOME_PROGRAMS_WELLCHALLENGES',
                    'MBR_MBRHOME_PROGRAMS_WELLCHALLENGES_HREF',
                    'MBR_MBRHOME_PROGRAMS_VALBASED_SIGNUP',
                    'MBR_MBRHOME_PROGRAMS_VALBASED_SIGNUP_HREF',
                    'MBR_MBRHOME_PROGRAMS_VALBASED',
                    'MBR_MBRHOME_PROGRAMS_VALBASED_LINK',
                    'MBR_MBRHOME_MODALTITLE_PAYPREMIUM',
                    'MBR_MBRHOME_MODAL_PAYPREMIUM',
                    'MBR_MBRHOME_MODALTITLE_PROCESSING_LTDACCESS',
                    'MBR_MBRHOME_MODAL_PROCESSING_LTDACCESS',
                    'MBR_MBRHOME_MODALTITLE_DEPENDENT_LTDACCESS',
                    'MBR_MBRHOME_MODAL_DEPENDENT_LTDACCESS',
                    'MBR_MBRHOME_MODALTITLE_WAYSTOPAY',
                    'MBR_MBRHOME_MODAL _WAYSTOPAY',
                    'MBR_MBRHOME_MODAL _WAYSTOPAY_ONLINE',
                    'MBR_MBRHOME_MODAL _WAYSTOPAY_PHONE',
                    'MBR_MBRHOME_MODAL _WAYSTOPAY_PRNTMAIL',
                    'MBR_MBRHOME_MODAL_PREEFFECTIVE_LTDACCESS',
                    'MBR_MBRHOME_ESPOTS_MINCR',
                    'MBR_MBRHOME_ESPOT_IDTHEFT_SRC',
                    'MBR_MBRHOME_ESPOT_IDTHEFT_TARGET',
                    'MER_MBRINFO_GRPPEND',
                    'MER_MBRINFO_GRPREADY',
                    'MER_MBRINFO_COVINACTIVE',
                    'MER_MBRINFO_FUTURE',
                    'MER_MBRINFO_NOPAY',
                    'MER_MBRINFO_CANCELLED',
                    'MBR_MBRHOME_COVCANMODALTTL',
                    'MBR_MBRHOME_COVCANMODALTXT',
                    'MER_MBRINFO_FUTURE_IBCCR',
                    'MER_MBRINFO_FUTURE_IBCAH',
                    'MBR_MBRHOME_ALERTS_HSA',
                    'MBR_MBRHOME_ALERTS_HSA_LINK',
                    'MBR_MBRHOME_ALERTS_HSA_LINK_HREF',
                    'MBR_MBRHOME_ALERTS_CLAIMS',
                    'MBR_MBRHOME_ALERTS_CLAIMS_1',
                    'MBR_MBRHOME_ALERTS_CLAIMS_LINK',
                    'MBR_MBRHOME_ALERTS_SPENDING',
                    'MBR_MBRHOME_ALERTS_SPENDING_1',
                    'MBR_MBRHOME_ALERTS_SPENDING_LINK',
                    'MBR_MBRHOME_ALERTS_SPENDING_LINK_HREF',
                    'MBR_MBRHOME_ALERTS_EMAIL',
                    'MBR_MBRHOME_ALERTS_EMAIL_LINK',
                    'MBR_MBRHOME_ALERTS_EMAIL_LINK_HREF',
                    'MBR_MBRHOME_ALERTS_PAPERLESS',
                    'MBR_MBRHOME_ALERTS_PAPERLESS_1',
                    'MBR_MBRHOME_ALERTS_PAPERLESS_LINK',
                    'MBR_MBRHOME_ALERTS_PAPERLESS_LINK_HREF',
                    'MBR_MBRHOME_TOPHEADER_CATEGORYTITLE',
                    'MBR_MBRHOME_RIBBON_MEMINFO',
                    'MBR_MBRHOME_RIBBON_MEMID',
                    'MBR_MBRHOME_RIBBON_GROUPNUM',
                    'MBR_MBRHOME_RIBBON_OTHERMEMINFO',
                  'MBR_MBRHOME_RIBBON_OTHERMEMINFO_NDBCBS',
                    'MBR_MBRHOME_RIBBON_OTHERMEMINFO_HREF',
                    'MBR_MBRHOME_RIBBON_IDCARD',
                    'MBR_MBRHOME_RIBBON_IDCARD_HREF',
                    'MBR_MBRHOME_RIBBON_CVGSUM',
                    'MBR_MBRHOME_RIBBON_CVGSUM_HREF',
                    'MBR_MBRHOME_MSGCNTR_HREF',
                    'MBR_MBRHOME_REVIEW_MSGS',
                    'MBR_MBRHOME_NEW_MSGS',
                    'MBR_MBRHOME_COVERAGE_SUMMARYTTIP',
                    'MBR_MBRHOME_COVERAGE_SUMMARYTTIP_HREF',
                    'MBR_MBRHOME_COVERAGE_DEDUCTIBLE',
                    'MBR_MBRHOME_COVERAGE_COPAYS',
                    'MBR_MBRHOME_COVERAGE_COINSURANCE',
                    'MBR_MBRHOME_COVERAGE_OOPMAX',
                    'MBR_MBRHOME_COVERAGE_FAMDEDUCTIBLE',
                    'MBR_MBRHOME_COVERAGE_TIERHEADER',
                    'MBR_MBRHOME_COVERAGE_TIERHEADERFOUR',
                    'MBR_MBRHOME_COVERAGE_TIERHEADERNONE',
                    'MBR_MBRHOME_COVERAGE_TIERHEADERTHREE'
                  ]

            }
        };
    });
}