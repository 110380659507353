module.exports = function (app) {

    require('./OIStep1')(app);
    require('./OIStep2')(app);
    require('./OIStep3')(app);
    require('./OIStep4')(app);

    app.controller('pages.account.other-insurance', ['$scope', '$state', '$timeout', 'finder-logic2', 'services', 'brand', 'coremetrics',
        function ($scope, $state, $timeout, finderLogic, services, brand, coremetrics) {
            

            var currentPlan = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate('Medical');
            var members = currentPlan.memberList;
            var currGrp = finderLogic.getPortalSelectedPlan()
            var clientInfo = services.rbsmbl.memberInfo().getPortalSelectedClient();
            var theBrand = brand.current.sharedBrandCode;
            var currentPlans = finderLogic.portalSelectedPlanActiveProducts();

            $scope.fixSidebar = function () {
                var oiSidebar = null;
                $scope.sidebar.links.forEach(function (data) {
                    if (data.state === "account.otherInsurance") {
                        oiSidebar = data;
                    }
                });

                $scope.sidebar.links[0].active = false;
                $scope.sidebar.selectedLink = '<span data-teamsite="MBR_COB_LeftNav_Title"></span>'
                $scope.sidebar.current = oiSidebar;
                $scope.sidebar.current.active = true;
                $scope.sidebar.heading = '<span data-teamsite="MBR_COB_LeftNav_Headline"></span>';
                $scope.sidebar.description = '<span data-teamsite="MBR_COB_LeftNav_Description"></span>';
            }

            //Sets up the dropdowns on insurance details screen
            $scope.setUpStepTwo = function () {
                var userMatchMasterList = [];
                for(var i = 0; i < $scope.additionalUsers.length; i++){
                    if (i == 0) {
                        $scope.additionalUsers[i].drawerOpen = 'open';
                    }
                    else{
                        $scope.additionalUsers[i].drawerOpen = '';
                    }
                    $scope.additionalUsers[i].modulo = (i % 2 == 0) ? true : false;

                    $scope.additionalUsers[i].fieldSets2.effectiveDate.value = new Date($scope.additionalUsers[i].fieldSets2.effectiveDate.value)
                    $scope.additionalUsers[i].fieldSets2.cancelDate.value = new Date($scope.additionalUsers[i].fieldSets2.cancelDate.value)
                    $scope.additionalUsers[i].fieldSets2.retirementDate.value = new Date($scope.additionalUsers[i].fieldSets2.retirementDate.value)
                    $scope.additionalUsers[i].fieldSets2.policyDOB.value = new Date($scope.additionalUsers[i].fieldSets2.policyDOB.value)

                    $scope.userMatchList = [
                        {label: 'Select', value: ''}
                    ];

                    //Sets up the copy dropdown for other users
                    for(var j =0; j < $scope.additionalUsers.length; j++){
                        if(!($scope.additionalUsers[j].firstName == $scope.additionalUsers[i].firstName &&
                            $scope.additionalUsers[j].lastName == $scope.additionalUsers[i].lastName &&
                            $scope.additionalUsers[j].dob == $scope.additionalUsers[i].dob)){
                            if($scope.additionalUsers[j].showDOB){
                                var obj = {label: $scope.additionalUsers[j].firstName.toLowerCase() + ' ' + $scope.additionalUsers[j].lastName.toLowerCase() + ' ' + $scope.additionalUsers[j].dob, value: j};
                            }else{
                                var obj = {label: $scope.additionalUsers[j].firstName.toLowerCase() + ' ' + $scope.additionalUsers[j].lastName.toLowerCase(), value: j};
                            }
                            $scope.userMatchList.push(obj);
                        }
                    }
                    userMatchMasterList.push($scope.userMatchList);
                    $scope.additionalUsers.forEach(function (user, i) {
                        user.fieldSets2.userMatchList = userMatchMasterList[i];
                    })

                    if(($scope.additionalUsers[i].relationship == 'Dependent' || $scope.additionalUsers[i].relationship == 'Spouse') && i !== 0){

                        $scope.additionalUsers[i].showCopyDropdown = true;
                    }else{
                        $scope.additionalUsers[i].showCopyDropdown = false;
                    }

                    if ($scope.additionalUsers[i].relationshipCode == '02' ||
                        $scope.additionalUsers[i].relationshipCode == '05' ||
                        $scope.additionalUsers[i].relationshipCode == '07' ||
                        $scope.additionalUsers[i].relationshipCode == '09' ||
                        $scope.additionalUsers[i].relationshipCode == '14' ||
                        $scope.additionalUsers[i].relationshipCode == '17'){
                        $scope.additionalUsers[i].isDependent = true;
                    } else{
                        $scope.additionalUsers[i].isDependent = false;
                    }

                }
            };

            //Needed to process values into labels for screen 4
             var getDropdownLabels = function(){

                for(var i = 0; i <$scope.users1.length; i++){
                    //relationship
                    switch($scope.users1[i].fieldSets2.courtOrder.value){
                        case '0':
                            $scope.users1[i].fieldSets2.courtOrderLabel = 'No';
                            break;
                        case '1':
                            $scope.users1[i].fieldSets2.courtOrderLabel = 'Yes';
                            break;
                        default:
                            $scope.users1[i].fieldSets2.courtOrderLabel = ' ';
                    }

                    //primary parent
                    switch($scope.users1[i].fieldSets2.primaryParent.value){
                        case 'M':
                            $scope.users1[i].fieldSets2.primaryParentsLabel = 'Mother';
                            break;
                        case 'F':
                            $scope.users1[i].fieldSets2.primaryParentsLabel = 'Father';
                            break;
                        case 'J':
                            $scope.users1[i].fieldSets2.primaryParentsLabel = 'Joint';
                            break;
                        default:
                            $scope.users1[i].fieldSets2.primaryParentsLabel = ' ';
                    }

                    switch($scope.users1[i].fieldSets2.custody.value){
                        case 'N':
                            $scope.users1[i].fieldSets2.custodyLabel = 'None';
                            break;
                        case 'M':
                            $scope.users1[i].fieldSets2.custodyLabel = 'Mother';
                            break;
                        case 'F':
                            $scope.users1[i].fieldSets2.custodyLabel = 'Father';
                            break;
                        case 'J':
                            $scope.users1[i].fieldSets2.custodyLabel = 'Joint';
                            break;
                        default:
                            $scope.users1[i].fieldSets2.custodyLabel = ' ';
                    }

                    switch($scope.users1[i].fieldSets2.employmentStatus.value){
                        case 'A':
                            $scope.users1[i].fieldSets2.employmentLabel = 'Active';
                            break;
                        case 'R':
                            $scope.users1[i].fieldSets2.employmentLabel = 'Retired';
                            break;
                        case 'C':
                            $scope.users1[i].fieldSets2.employmentLabel = 'COBRA';
                            break;
                        default:
                            $scope.users1[i].fieldSets2.employmentLabel = ' ';
                    }

                    switch($scope.users1[i].fieldSets2.relationshipToHolder.value){
                        case '1':
                            $scope.users1[i].fieldSets2.relationshipToHolderLabel = 'Self';
                            break;
                        case '2':
                            $scope.users1[i].fieldSets2.relationshipToHolderLabel = 'Spouse';
                            break;
                        case '3':
                            $scope.users1[i].fieldSets2.relationshipToHolderLabel = 'Child';
                            break;
                        case '4':
                            $scope.users1[i].fieldSets2.relationshipToHolderLabel = 'Handicapped Dependent';
                            break;
                        case '5':
                            $scope.users1[i].fieldSets2.relationshipToHolderLabel = 'Other/Domestic Partner';
                            break;
                        default:
                            $scope.users1[i].fieldSets2.relationshipToHolderLabel = '';
                    }

                    switch($scope.users1[i].fieldSets3.eligibilityReason.value){
                        case '0':
                            $scope.users1[i].fieldSets3.eligibilityReasonLabel = 'Age';
                            break;
                        case '1':
                            $scope.users1[i].fieldSets3.eligibilityReasonLabel = 'Disability';
                            break;
                        case '2':
                            $scope.users1[i].fieldSets3.eligibilityReasonLabel = 'End Stage Renal Disease';
                            break;
                        case '3':
                            $scope.users1[i].fieldSets3.eligibilityReasonLabel = 'Disability and End Stage Renal Disease';
                            break;
                        default:
                            $scope.users1[i].fieldSets3.eligibilityReasonLabel = '';
                    }

                }
            };


            var goTo = function (step, isRecoveryFlow, params) {
                // var state = setChevronSelectedSate(step, isRecoveryFlow, params);
                if (step === 1) {
                    $scope.setFourShowSave = false;
                    $scope.showChangesFailed = false;
                    $scope.showChangesSaved = false;
                    $state.go('account.otherInsurance.Step1');
                }
                if (step == 2 ) {
                    $scope.showChangesSaved = false;
                    $scope.setFourShowSave = false;
                    $scope.showChangesFailed = false;
                    // $scope.setUpStepTwo();
                    $state.go('account.otherInsurance.Step2');
                } else if (step==3) {
                    $scope.setFourShowSave = false;
                    $scope.showChangesFailed = false;
                    $scope.showChangesSaved = false;
                    //setUpStepThree();
                    $state.go('account.otherInsurance.Step3');
                } else if (step == 4) {
                    $scope.setFourShowSave = false;
                    $scope.showChangesFailed = false;
                    $scope.showChangesSaved = false;
                    $state.go('account.otherInsurance.Step4');
                } 
                // else {
                //     $state.go('account.otherInsurance.Step' + step);
                // }
            };

            $scope.otherInsuranceChevron = {
                steps: [{
                        label: 'Other Insurance',
                        state: 'account.otherInsurance.Step1'
                    },
                    {
                        label: 'Insurance Details',
                        state: 'account.otherInsurance.Step2'
                    },
                    {
                        label: 'Medicare Coverage',
                        state: 'account.otherInsurance.Step3'
                    },
                    {
                        label: 'Review and Submit',
                        state: 'account.otherInsurance.Step4'
                    }
                ],
                step: 1
            };
            $scope.model = {
                otherInsurancesResponse: null,
                offExchange: true,
                onExchange: true,
                isDependent: false,
                atLeastOneActive: false,
                atLeastOneMedicare: false
            };

            $scope.users1 = [];
            $scope.loadStep = false;
            $scope.additionalUsers = [];
            $scope.medicareUsers = [];
            $scope.reVisit1 = false;
            $scope.reVisit2 = false;
            $scope.reVisit3 = false;
            $scope.cmNextButton = false;
            $scope.showChangesSaved = false;
            $scope.showChangesFailed = false;
            $scope.setFourShowSave = false;
            $scope.preSubmitted = true;
            $scope.goToStep = null;
            $scope.showSpinner = false;

            var cmSetStep = [false, true, false, false, false];
            var cmBackButton = false;
            var cmOtherInsuranceEditvalue = false;

            var screenTwoPreVal = false;
            var binInvalid = false;

            


            // ==============================FUNCTIONS==============================

            $scope.saveStepFunc = function (n) {
                switch (n) {
                    case 1:
                    case 2:
                    case 3:
                        $scope.showChangesFailed = false;
                        $scope.showChangesSaved = true;
                        break;
                    case 4:
                        $scope.showChangesFailed = false;
                        $scope.setFourShowSave = true;
                        $scope.preSubmitted = false;

                        window.scrollTo(0, 0);
                }
            };

            var transformDate = function (dateToParse) {
                console.log("DATE TO TRANSFORM: ", dateToParse)
                if (dateToParse == "Invalid Date") {
                    console.log("###############")
                }
                
                if (dateToParse && dateToParse instanceof Date && !(dateToParse == "Invalid Date")) {
                        console.log("AN OBJECT")
                        console.log("IS A DATE: ", dateToParse instanceof Date);
                        //console.log(dateToParse);
                        //console.log(dateToParse.getFullYear());
                        //console.log(dateToParse.getDate());
                        //console.log(dateToParse.getMonth());

                        var fixedDate = "";
                        var year;
                        var month = new Array();
                        var theMonth;
                        var day;
                        
                        month[0] = "01";
                        month[1] = "02";
                        month[2] = "03";
                        month[3] = "04";
                        month[4] = "05";
                        month[5] = "06";
                        month[6] = "07";
                        month[7] = "08";
                        month[8] = "09";
                        month[9] = "10";
                        month[10] = "11";
                        month[11] = "12";

                        year = dateToParse.getFullYear().toString();
                        theMonth = month[dateToParse.getMonth()];
                        day = dateToParse.getDate();

                        if (day < 10) {
                            day = "0" + day;
                        }

                        if (year.length === 3) {
                            year = "0" + year;
                        }

                        fixedDate =  theMonth + "/" + day + "/" + year;

                        console.log("FIXED DATE: ", fixedDate)


                        return fixedDate;
                } else {
                    // dateToParse ='00/00/0000';
                    console.log(" returning empty date",dateToParse);
                    return dateToParse
                };
            }

            $scope.buildPayload = function (saveStep) {
                var cobData = $scope.users1;

                for (var i = 0; i < cobData.length; i++) {
                    if (saveStep == 'OTHERINSURANCE') {

                        for (var j = 0; j < $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData.length; j++) {
                            if (cobData[i].firstName.toUpperCase() == $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[j].participantInformation.firstName.toUpperCase() &&
                                cobData[i].lastName.toUpperCase() == $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[j].participantInformation.lastName.toUpperCase() &&
                                (cobData[i].dob == $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[j].participantInformation.dateOfBirth ||
                                    cobData[i].dob == $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[j].participantInformation.birthDate)) {

                                var medicare = cobData[i].fieldSets.medicare.value.charAt(0);
                                var active = cobData[i].fieldSets.active.value.charAt(0);
                                $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[j].eligibleForMedicare = medicare;
                                $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[j].hasOtherActiveInsurance = active;
                                if (!$scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[j].participantInformation.cobRecordSequenceNumber) {
                                    $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[j].participantInformation.cobRecordSequenceNumber = i + 1;
                                }

                            }
                        }
                    }

                    //Additional Policies
                    if(saveStep == 'ADDITIONALPOLICY'){
                        $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData.forEach(function (DTOData, j) {
                            var modelCOB = $scope.model.otherInsurancesResponse.cobAdditionalPoliciesInformationStepDTOList.cobAdditionalPoliciesInformationStepDTOData[j];
                            if(cobData[i].firstName.toUpperCase() == DTOData.participantInformation.firstName.toUpperCase() &&
                                cobData[i].lastName.toUpperCase() == DTOData.participantInformation.lastName.toUpperCase() &&
                                cobData[i].dob == DTOData.participantInformation.dateOfBirth ){

                                var step2PolicyDOB = transformDate(cobData[i].fieldSets2.policyDOB.value);
                                var step2EffectiveDate = transformDate(cobData[i].fieldSets2.effectiveDate.value);
                                var step2CancelDate = transformDate(cobData[i].fieldSets2.cancelDate.value);
                                var step2RetirementDate = transformDate(cobData[i].fieldSets2.retirementDate.value);

                                modelCOB.FirstName = cobData[i].fieldSets2.policyFirst.value;
                                modelCOB.LastName = cobData[i].fieldSets2.policyLast.value;
                                modelCOB.dateOfBirth = step2PolicyDOB;
                                modelCOB.effectiveDate = step2EffectiveDate;
                                modelCOB.groupNumber = cobData[i].fieldSets2.groupNumber.value;
                                modelCOB.relationshipToContractHolder = (cobData[i].fieldSets2.relationshipToHolder.value != '' ? cobData[i].fieldSets2.relationshipToHolder.value : '');
                                modelCOB.retirementDate = step2RetirementDate;
                                modelCOB.cancelDate = step2CancelDate;
                                modelCOB.nameOfInsuranceCarrier = cobData[i].fieldSets2.carrierName.value;
                                modelCOB.dependentInformationCourtOrder = (cobData[i].fieldSets2.courtOrder.value != '' ? cobData[i].fieldSets2.courtOrder.value : '');
                                modelCOB.custody = (cobData[i].fieldSets2.custody.value != '' ? cobData[i].fieldSets2.custody.value : '');
                                modelCOB.employmentStatus = (cobData[i].fieldSets2.employmentStatus.value != '' ? cobData[i].fieldSets2.employmentStatus.value : '');
                                modelCOB.policyNumber = cobData[i].fieldSets2.policyNumber.value;
                                modelCOB.parentWithPrimaryMedicalResponsibility = (cobData[i].fieldSets2.primaryParent.value != '' ? cobData[i].fieldSets2.primaryParent.value : '');
                                if(! modelCOB.participantInformation.cobRecordSequenceNumber){
                                    modelCOB.participantInformation.cobRecordSequenceNumber = i + 1;
                                }
                                //removes apostrophes
                                modelCOB.policyNumber = modelCOB.policyNumber ? ((modelCOB.policyNumber).includes("'") ? modelCOB.policyNumber.replace("'", "") : modelCOB.policyNumber) : modelCOB.policyNumber;
                                modelCOB.FirstName =  modelCOB.FirstName ? ((modelCOB.FirstName).includes("'") ? modelCOB.FirstName.replace("'", "" ) : modelCOB.FirstName) : modelCOB.FirstName;
                                modelCOB.LastName =  modelCOB.LastName ? (modelCOB.LastName ? modelCOB.LastName.replace("'", "") : modelCOB.LastName) : modelCOB.LastName;
                                modelCOB.groupNumber = modelCOB.groupNumber ? ((modelCOB.groupNumber).includes("'") ? modelCOB.groupNumber.replace("'", "") : modelCOB.groupNumber) : modelCOB.groupNumber;
                                modelCOB.nameOfInsuranceCarrier =  modelCOB.nameOfInsuranceCarrier ? ((modelCOB.nameOfInsuranceCarrier).includes("'") ? modelCOB.nameOfInsuranceCarrier.replace("'", "") : modelCOB.nameOfInsuranceCarrier) : modelCOB.nameOfInsuranceCarrier;
                                // break;
                            }
                        });
                    }

                    //Medicare
                    if(saveStep == 'MEDICARE'){
                        for(var j=0; j < $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData.length; j++){
                            var modelMedicare = $scope.model.otherInsurancesResponse.cobMedicareInformationStepDTOList.cobMedicareInformationStepDTOData[j];
                            if(cobData[i].firstName.toUpperCase() == $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[j].participantInformation.firstName.toUpperCase() &&
                                cobData[i].lastName.toUpperCase() == $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[j].participantInformation.lastName.toUpperCase() &&
                                cobData[i].dob == $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[j].participantInformation.dateOfBirth ){

                                var step3DisabilityBeginDate = transformDate(cobData[i].fieldSets3.disabilityBeginDate.value);
                                var step3MedicareAEffDate = transformDate(cobData[i].fieldSets3.medicareAEffDate.value);
                                var step3MedicareAEndDate = transformDate(cobData[i].fieldSets3.medicareACanDate.value);
                                var step3MedicareBEffDate = transformDate(cobData[i].fieldSets3.medicareBEffDate.value);
                                var step3MedicareBEndDate = transformDate(cobData[i].fieldSets3.medicareBCanDate.value);
                                var step3MedicareCEffDate = transformDate(cobData[i].fieldSets3.medicareCEffectiveDate.value);
                                var step3MedicareCEndDate = transformDate(cobData[i].fieldSets3.medicareCCancelDate.value);
                                var step3MedicareDEffDate = transformDate(cobData[i].fieldSets3.medicareDEffectiveDate.value);
                                var step3MedicareDEndDate = transformDate(cobData[i].fieldSets3.medicareDCancelDate.value);
                                var step3ssDisabilityStartDate = transformDate(cobData[i].fieldSets3.ssDisabilityStartDt.value);
                                var step3ssDisabilityEndDate = transformDate(cobData[i].fieldSets3.ssDisabilityEndDt.value);

                                modelMedicare.bin = cobData[i].fieldSets3.bin.value;

                                modelMedicare.disabilityBeginDate = step3DisabilityBeginDate;
                                modelMedicare.eligibilityCriteria = cobData[i].fieldSets3.eligibilityReason.value;

                                // Medicare A
                                modelMedicare.medicarePartACancelDate = step3MedicareAEndDate;
                                modelMedicare.medicarePartAEffectiveDate = step3MedicareAEffDate;

                                // Medicare B
                                modelMedicare.medicarePartBCancelDate = step3MedicareBEndDate;
                                modelMedicare.medicarePartBEffectiveDate = step3MedicareBEffDate;

                                // Medicare C
                                var medicareCValue = (cobData[i].fieldSets3.medicareC.value == 'Yes') ? 'Y' : 'N';
                                $scope.medCShow = (cobData[i].fieldSets3.medicareC.value == 'Yes') ? true : false;
                                modelMedicare.medicarePartCYesNo = medicareCValue;
                                modelMedicare.medicarePartCCancelDate = step3MedicareCEndDate;
                                modelMedicare.medicarePartCCarrierName = cobData[i].fieldSets3.medicareCCarrierName.value;
                                modelMedicare.medicarePartCEffectiveDate = step3MedicareCEffDate;
                                modelMedicare.medicarePartCGroupNumber = cobData[i].fieldSets3.medicareCGroupNumber.value;
                                modelMedicare.medicarePartCPolicyNumber = cobData[i].fieldSets3.medicareCPolicyNumber.value;
                                modelMedicare.medicareClaimNumber = cobData[i].fieldSets3.medicareClaimNumber.value;

                                // Medicare D
                                var medicareDValue = (cobData[i].fieldSets3.medicareD.value == 'Yes') ? 'Y' : 'N';
                                $scope.medDShow = (cobData[i].fieldSets3.medicareD.value == 'Yes') ? true : false;
                                modelMedicare.medicarePartDYesNo = medicareDValue;
                                modelMedicare.medicarePartDCancelDate = step3MedicareDEndDate;
                                modelMedicare.medicarePartDCarrierName = cobData[i].fieldSets3.medicareDCarrierName.value;
                                modelMedicare.medicarePartDEffectiveDate = step3MedicareDEffDate;
                                modelMedicare.medicarePartDGroupNumber = cobData[i].fieldSets3.medicareDGroupNumber.value;
                                modelMedicare.medicarePartDPolicyNumber = cobData[i].fieldSets3.medicareDPolicyNumber.value;

                                // ssDisability
                                modelMedicare.socialSecurityDisabilityIncomeStartDate = step3ssDisabilityStartDate;
                                modelMedicare.socialSecurityDisabilityIncomeEndDate = step3ssDisabilityEndDate;
                                var ssDisability = (cobData[i].fieldSets3.ssDisabilityIncome.value == 'Yes') ? 'Y' : 'N';
                                modelMedicare.socialSecurityDisabilityIncome = ssDisability;
                                modelMedicare.pcn = cobData[i].fieldSets3.pcn.value;
                                if(!modelMedicare.participantInformation.cobRecordSequenceNumber){
                                    modelMedicare.participantInformation.cobRecordSequenceNumber = i + 1;
                                }
                                //removes apostrophes
                                modelMedicare.bin ? modelMedicare.bin.replace("'", "") : "";
                                modelMedicare.medicarePartCCarrierName ? modelMedicare.medicarePartCCarrierName.replace("'", "") : "";
                                modelMedicare.medicarePartCGroupNumber ? modelMedicare.medicarePartCGroupNumber.replace("'", "") : "";
                                modelMedicare.medicarePartCPolicyNumber ? modelMedicare.medicarePartCPolicyNumber.replace("'", "") : "";
                                modelMedicare.medicareClaimNumber ? modelMedicare.medicareClaimNumber.replace("'", "") : "";
                                modelMedicare.medicarePartDCarrierName ? modelMedicare.medicarePartDCarrierName.replace("'", "") : "";
                                modelMedicare.medicarePartDGroupNumber ? modelMedicare.medicarePartDGroupNumber.replace("'", "") : "";
                                modelMedicare.medicarePartDPolicyNumber ? modelMedicare.medicarePartDPolicyNumber.replace("'", "") : "";
                                modelMedicare.pcn ? modelMedicare.pcn.replace("'", "") : "";
                                // break;
                            }
                        }
                    }
                }

                return $scope.model.otherInsurancesResponse;
            };

            $scope.saveToServer = function (step, isNextButton, validStep) {

                var saveStep = '';
                var stepSave = '';

                $scope.showChangesSaved = false;

                //Sets step for save
                switch (step) {
                    case 1:
                        saveStep = 'OTHERINSURANCE';
                        break;
                    case 2:
                        saveStep = 'ADDITIONALPOLICY';
                        break;
                    case 3:
                        saveStep = 'MEDICARE';
                        break;
                    case 4:
                        stepSave = 'setFourShowSave';
                        saveStep = 'FINAL';
                };

                var selectedPlan = finderLogic.getPortalSelectedPlan();

                var valid = true;
                var toPostData = [];
                var firstUserInv = true;

                if (step === 1) {
                    var payload = $scope.buildPayload(saveStep)

                    payload.saveStep = saveStep;

                    services.rbsmbl.otherInsurance().updateCOB(payload, selectedPlan, theBrand).then(function (data) { //})

                        $scope.saveStepFunc(step);

                        if (isNextButton && isNextButton === true) {

                            $scope.additionalUsers = [];
                            $scope.medicareUsers = [];

                            $scope.users1.forEach(function (member) {
                                console.log("MEMBER", member);

                                //validate each user's form
                                var isValid = true;
                                // var isValid = member.step1Form.$validate();

                                if (member.fieldSets.active.value == 'Z') {
                                    member.fieldSets.activeLabel = '';
                                } else {
                                    member.fieldSets.activeLabel = member.fieldSets.active.value;
                                }
                                if (member.fieldSets.medicare.value == 'Z') {
                                    member.fieldSets.medicareLabel = '';
                                } else {
                                    member.fieldSets.medicareLabel = member.fieldSets.medicare.value;
                                }

                                // creates list of users who selected yes to additional policies
                                if (member.fieldSets.active.value == "Yes") {
                                    member.userNumberAdditional = '' + i + '';
                                    $scope.additionalUsers.push(member);
                                    member.step1Form.value.active = "Yes";
                                    member.showOnStep.two = true;
                                    if (!$scope.model.atLeastOneActive) {

                                        $scope.model.atLeastOneActive = true;
                                        // $scope.steps.step2 = true;
                                    }
                                    if (member.fieldSets2.carrierName.value != '') {
                                        $scope.reVisit2 = true;
                                    }
                                } else {
                                    member.step1Form.value.active = "No";
                                    member.showOnStep.two = false;
                                }

                                //creates list of users who selected yes to medicare
                                if (member.fieldSets.medicare.value == "Yes") {
                                    $scope.medicareUsers.push(member);
                                    member.step1Form.value.medicare = "Yes";
                                    member.showOnStep.three = true;
                                    if (member.fieldSets3.medicareClaimNumber.value != '') {
                                        $scope.reVisit3 = true;
                                    }
                                    if (!$scope.model.atLeastOneMedicare) {
                                        $scope.model.atLeastOneMedicare = true;
                                        // $scope.steps.step3 = true;
                                    }
                                } else {
                                    member.step1Form.value.medicare = "No";
                                    member.showOnStep.three = false;
                                }
                                console.log("STEP1FORM: ", member.step1Form)

                                if (isValid) {
                                    toPostData.push({
                                        'user': member.firstName + member.dob,
                                        "value": member.step1Form.value
                                    });
                                }
                            });

                            if ($scope.model.atLeastOneActive) {
                                $scope.otherInsuranceChevron.steps[2].validSteps = [1];
                                $scope.otherInsuranceChevron.steps[3].validSteps = [1, 2];
                                // $scope.setUpStepTwo();
                                $scope.goToStep = 2;

                            } else if ($scope.model.atLeastOneMedicare) {
                                if ($scope.model.atLeastOneActive) {
                                    $scope.otherInsuranceChevron.steps[2].validSteps = [1, 2];
                                    $scope.otherInsuranceChevron.steps[3].validSteps = [1, 2, 3];
                                } else {
                                    $scope.otherInsuranceChevron.steps[2].validSteps = [1];
                                    $scope.otherInsuranceChevron.steps[3].validSteps = [1, 3];
                                }
                                setUpStepThree();
                                $scope.goToStep = 3;
                            } else {
                                $scope.otherInsuranceChevron.steps[3].validSteps = [1];
                                $scope.goToStep = 4;
                            }
                            window.scrollTo(0, 0);
                            $scope.showChangesSaved = false;
                        }
                        console.log("SAVED TO SERVER");
                    }).catch(function (error) {
                        $scope.showChangesFailed = true;
                    });
                } else if (step === 2) {

                    if (validStep) {
                        screenTwoPreVal = false;
                        
                        //Builds payload for update
                        var payload = $scope.buildPayload(saveStep);
                        payload.saveStep = saveStep;
                        console.log("THE PAYLOAD: ", payload);
                        
                        services.rbsmbl.otherInsurance().updateCOB(payload, selectedPlan, theBrand).then(function (step2data) {
                            console.log("DATA------------------------------- STEP2",step2data);
                            $scope.saveStepFunc(step);
                            $scope.showChangesSaved = true;
                            
                        }, function(error) {

                            console.log("WHY IT DIDNT SAVE: ", error)
                            $scope.showChangesFailed = true;
                        });
                        
                        // If user clicked the next button
                        if(isNextButton){
                            if ($scope.model.atLeastOneMedicare){
                                // set step3
                                setUpStepThree();
                                $scope.goToStep = 3;
                            } else {
                                //set step4
                                getDropdownLabels();

                                $scope.goToStep = 4;
                            }
                            window.scrollTo( 0, 0);
                            $scope.showChangesSaved = false;

                        }

                    }
                    
                } else if (step === 3) {
                    if (validStep) {
                        screenTwoPreVal = false;

                        //Builds payload for update
                        var payload = $scope.buildPayload(saveStep);
                        payload.saveStep = saveStep;
                        console.log("STEP 3 PAYLOAD: ", payload)

                        services.rbsmbl.otherInsurance().updateCOB(payload, selectedPlan, theBrand).then(function (step3data) {
                            console.log("DATA------------------------------- STEP3", step3data);
                            $scope.saveStepFunc(step);
                            $scope.showChangesSaved = true;

                            
                        }, function(error) {
                            $scope.showChangesFailed = true;
                        });
                        
                        // If user clicked the next button
                        if (isNextButton) {
                            getDropdownLabels();

                            $scope.goToStep = 4;
                            
                            window.scrollTo( 0, 0);
                            $scope.showChangesSaved = false;

                        }

                    }
                } else if (step === 4) {
                    //add cmSaveToServer
                    var payload = $scope.buildPayload(saveStep)

                    payload.saveStep = saveStep;

                    services.rbsmbl.otherInsurance().updateCOB(payload, selectedPlan, theBrand).then(function (data) {
                        console.log("SAVING OTHER INSURANCE: ", data);

                        $scope.saveStepFunc(step);
                        window.scrollTo(0, 0);
                        $scope.showChangesSaved = true;
                    }, function (error) {
                        console.log("DID NOT SAVE: ", error);
                        $scope.showChangesFailed = true;
                    });
                };
            };

            $scope.nextButtonClick = function (step) {
                cmSetStep = [false, false, false, false, false];
                $scope.loadStep = true;
                switch (step) {
                    case 1:
                        if ($scope.cmNextButton !== true) {
                            coremetrics.elementTag('NEXT OTHER INSURANCE DETAILS', 'MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                            $scope.cmNextButton = true;
                        }
                        $scope.saveToServer(1, true);
                        window.scrollTo(0, 0);

                        $scope.showSpinner = true;
                        $timeout(function () {
                            $scope.showSpinner = false;
                            $scope.setUpStepTwo();
                            goTo($scope.goToStep)
                        }, 2300);


                        break;

                    case 2:
                        if($scope.cmNextButton !== true){
                            coremetrics.elementTag('NEXT OTHER INSURANCE DETAILS','MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                            $scope.cmNextButton = true;
                        }

                        $scope.saveToServer(2, true, true);
                        window.scrollTo(0, 0);
                        $scope.showSpinner = true;
                        if ($scope.model.atLeastOneMedicare) {
                            $scope.goToStep = 3;
                            $timeout(function () {
                                $scope.showSpinner = false;
                                goTo($scope.goToStep)
                            }, 2300);

                        } else {
                            $scope.goToStep = 4;
                            $scope.showSpinner = false;
                            goTo($scope.goToStep)
                        }

                        break;
                    case 3:
                        if($scope.cmNextButton !== true){
                            coremetrics.elementTag('NEXT MEDICARE COVERAGE OTHER INSURANCE','MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                            $scope.cmNextButton = true;
                        }
                        $scope.saveToServer(3, true, true)
                        window.scrollTo(0, 0);
                        $scope.showSpinner = true;
                        $timeout(function () {
                            $scope.showSpinner = false;
                            goTo(4)
                        }, 2300);
                        break;

                }
            };

            $scope.cmOtherInsuranceEdit = function () {
                if (cmOtherInsuranceEditvalue !== true) {
                    coremetrics.elementTag('EDIT OTHER INSURANCE YES OR NO','MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                    cmOtherInsuranceEditvalue = true;

                }
                cmSetStep = [false, false, false, false, false];

                goTo(1);
            };




            $scope.backButton =  function(step){
                //Reset cm flag for page views.
                cmSetStep = [false, false, false, false, false];
                
                switch(step){
                    case 2:
                        if(cmBackButton !== true){
                            coremetrics.elementTag('BACK OTHER INSURANCE DETAILS','MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                            cmBackButton = true;
                        }
                        //needs to reset all variables 
                        // $scope.screen = 1;
                        $scope.additionalUsers = [];
                        $scope.medicareUsers = [];
                        // $scope.atLeastOneActive = true;
                        // $scope.atLeastOneMedicare = false;
                        // $scope.steps.step2 = false;
                        // $scope.steps.step3 = false;
                        goTo(1);
                        $scope.showChangesSaved = false;
                        $scope.showChangesFailed = false;
                        // mobileChevron(1);
                        break;
                    case 3:	
                        if(cmBackButton !== true){
                            coremetrics.elementTag('BACK MEDICARE COVERAGE OTHER INSURANCE','MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                            cmBackButton = true;
                        }
                        //sends user to appropriate screen based on if additional policies selected
                        if($scope.additionalUsers.length > 0){
                            // $scope.screen = 2;
                            goTo(2);
                            $scope.showChangesSaved = false;
                            $scope.showChangesFailed = false;
                            // mobileChevron(2);
                        }else{
                            // $scope.screen = 1;
                            // mobileChevron(1);
                            $scope.additionalUsers = [];
                            $scope.medicareUsers = [];
                            // $scope.atLeastOneActive = false;
                            // $scope.atLeastOneMedicare = false;
                            // $scope.steps.step2 = false;
                            // $scope.steps.step3 = false;
                            goTo(1);
                            $scope.showChangesSaved = false;
                            $scope.showChangesFailed = false;
                        }							
                        $scope.showChangesSaved = false;
                        $scope.showChangesFailed = false;
                        
                        break;
                    case 4:
                        if(cmBackButton !== true){
                            coremetrics.elementTag('CANCEL OTHER INSURANCE','MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                            cmBackButton = true;
                        }
                        //this is the cancel button which brings users to screen 1
                        // $scope.screen =1;
                        // mobileChevron(1);
                        $scope.additionalUsers = [];
                        $scope.medicareUsers = [];
                        // $scope.atLeastOneActive = false;
                        // $scope.atLeastOneMedicare = false;
                        // $scope.steps.step2 = false;
                        // $scope.steps.step3 = false;
                        goTo(1);
                        $scope.showChangesSaved = false;
                        $scope.setFourShowSave = false;
                        $scope.showChangesFailed = false;
                        break;
                }
            }

            $scope.getFullMemberList = function (data) {
                var funcMemberList = [];

                for (var i = 0; i < data.length; i++) {
                    var uniqueMemberObj = {
                        'showDOB': false,
                        'clientNumber': clientInfo.clientNumber,
                        'groupNumber': currGrp.groupNumber,
                        'ecId': data[i].ecId,
                        'gender': data[i].gender,
                        'firstName': data[i].contact.firstName,
                        'lastName': data[i].contact.lastName,
                        'suffix': data[i].contact.suffix,
                        'dob': data[i].contact.birthDate,
                        'covCancellationDate': data[i].coverageCancellationDate,
                        'coverageEffectiveDate': data[i].coverageEffectiveDate,
                        'pinNumber': data[i].pinNumber,
                        'relationship': data[i].relationship,
                        'relationshipCode': data[i].relationshipCode,
                        'placement': $scope.getMemberValue(data[i].relationship),
                        'ciid': data[i].coveredIndividualId,
                        'productIdx': i,
                        'uniqueName': $scope.getUniqueMemberName(data[i].contact.firstName, data[i].contact.birthDate)
                    }
                    funcMemberList.push(uniqueMemberObj);
                }
                return funcMemberList;
            };

            $scope.getMemberValue = function (relationship) {
                var memberValue = 0;
                switch (relationship) {
                    case "Subscriber":
                        memberValue = 0;
                        break;
                    case "Spouse":
                        memberValue = 1;
                        break;
                    case "Dependent":
                        memberValue = 2;
                        break;
                    default:
                        memberValue = 3;
                        break;
                }
                return memberValue;
            };

            $scope.getUniqueMemberName = function (name, dob) {
                return name + " (" + dob + ")";
            };

            $scope.removeDuplicateMembers = function (members) {
                var memberHash = {};
                var cleanedList = [];
                for (var i = 0; i < members.length; i++) {
                    if (!memberHash[members[i].coveredIndividualId]) {
                        memberHash[members[i].coveredIndividualId] = true;
                        cleanedList.push(members[i]);
                    }
                }
                return cleanedList;
            };

            $scope.getmemberListInfo = function (memberList) {
                for (var i = 0; i < memberList.length; i++) {
                    memberListInfo.push(memberList[i].contact);
                }
                // console.log("MEMBER LIST", memberListInfo);
                // console.log("DOB", getDOBShowFlag(memberListInfo));
                return memberListInfo
            };

            $scope.getDOBShowFlag = function (list) {
                var tempArray = [];
                list.forEach(function (vala, inda) {
                    var tempObj = {
                        "birthDate": vala.birthDate,
                        person: {
                            "first": vala.firstName,
                            "last": vala.lastName
                        }
                    };
                    tempArray.push(tempObj);
                });
                tempArray.forEach(function (vala, inda) {
                    list.forEach(function (valb, indb) {
                        if (inda != indb) {
                            var tempObj2 = {
                                "birthDate": valb.birthDate,
                                person: {
                                    "first": valb.firstName,
                                    "last": valb.lastName
                                }
                            };
                            var compare = angular.equals(vala.person, tempObj2.person);
                            if (compare && vala.birthDate != valb.birthDate) {
                                valb.showDOB = true;
                            }
                        }
                    });
                });
            };

            $scope.removeDuplicatesPayload = function () {
                var otherInsuranceArray = [];
                var additionalPolicyArray = [];
                var medicareArray = [];
                var isMatch = false;
                var doubleHit;
                for (var i = 0; i < $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData.length; i++) {
                    if (i == 0) {
                        otherInsuranceArray.push($scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[i].participantInformation.firstName.toUpperCase() +
                            ' (' + $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[i].participantInformation.dateOfBirth + ')');
                    }
                    doubleHit = false;
                    for (var j = 0; j < otherInsuranceArray.length; j++) {
                        isMatch = false;
                        if ($scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[i].participantInformation.firstName.toUpperCase() +
                            ' (' + $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[i].participantInformation.dateOfBirth + ')' ==
                            otherInsuranceArray[j]) {
                            isMatch = true;
                            if (i != 0) {
                                $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData.splice(i, 1);
                                break;
                            }
                        }
                    }
                    if (isMatch == false) {
                        otherInsuranceArray.push($scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[i].participantInformation.firstName.toUpperCase() +
                            ' (' + $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[i].participantInformation.dateOfBirth + ')');
                    }
                }
                for (var i = 0; i < $scope.model.otherInsurancesResponse.cobAdditionalPoliciesInformationStepDTOList.cobAdditionalPoliciesInformationStepDTOData.length; i++) {
                    if (i == 0) {
                        additionalPolicyArray.push($scope.model.otherInsurancesResponse.cobAdditionalPoliciesInformationStepDTOList.cobAdditionalPoliciesInformationStepDTOData[i].participantInformation.firstName.toUpperCase() +
                            ' (' + $scope.model.otherInsurancesResponse.cobAdditionalPoliciesInformationStepDTOList.cobAdditionalPoliciesInformationStepDTOData[i].participantInformation.dateOfBirth + ')');
                    }

                    for (var j = 0; j < additionalPolicyArray.length; j++) {
                        isMatch = false;
                        if ($scope.model.otherInsurancesResponse.cobAdditionalPoliciesInformationStepDTOList.cobAdditionalPoliciesInformationStepDTOData[i].participantInformation.firstName.toUpperCase() +
                            ' (' + $scope.model.otherInsurancesResponse.cobAdditionalPoliciesInformationStepDTOList.cobAdditionalPoliciesInformationStepDTOData[i].participantInformation.dateOfBirth + ')' ==
                            additionalPolicyArray[j]) {
                            isMatch = true;
                            if (i != 0) {
                                $scope.model.otherInsurancesResponse.cobAdditionalPoliciesInformationStepDTOList.cobAdditionalPoliciesInformationStepDTOData.splice(i, 1);
                                break;
                            }
                        }
                    }
                    if (isMatch == false) {
                        additionalPolicyArray.push($scope.model.otherInsurancesResponse.cobAdditionalPoliciesInformationStepDTOList.cobAdditionalPoliciesInformationStepDTOData[i].participantInformation.firstName.toUpperCase() +
                            ' (' + $scope.model.otherInsurancesResponse.cobAdditionalPoliciesInformationStepDTOList.cobAdditionalPoliciesInformationStepDTOData[i].participantInformation.dateOfBirth + ')');
                    }
                }
                for (var i = 0; i < $scope.model.otherInsurancesResponse.cobMedicareInformationStepDTOList.cobMedicareInformationStepDTOData.length; i++) {
                    if (i == 0) {
                        medicareArray.push($scope.model.otherInsurancesResponse.cobMedicareInformationStepDTOList.cobMedicareInformationStepDTOData[i].participantInformation.firstName.toUpperCase() +
                            ' (' + $scope.model.otherInsurancesResponse.cobMedicareInformationStepDTOList.cobMedicareInformationStepDTOData[i].participantInformation.dateOfBirth + ')');
                    }

                    for (var j = 0; j < medicareArray.length; j++) {
                        isMatch = false;
                        if ($scope.model.otherInsurancesResponse.cobMedicareInformationStepDTOList.cobMedicareInformationStepDTOData[i].participantInformation.firstName.toUpperCase() +
                            ' (' + $scope.model.otherInsurancesResponse.cobMedicareInformationStepDTOList.cobMedicareInformationStepDTOData[i].participantInformation.dateOfBirth + ')' ==
                            medicareArray[j]) {
                            isMatch = true;
                            if (i != 0) {
                                $scope.model.otherInsurancesResponse.cobMedicareInformationStepDTOList.cobMedicareInformationStepDTOData.splice(i, 1);
                                break;
                            }
                        }
                    }
                    if (isMatch == false) {
                        medicareArray.push($scope.model.otherInsurancesResponse.cobMedicareInformationStepDTOList.cobMedicareInformationStepDTOData[i].participantInformation.firstName.toUpperCase() +
                            ' (' + $scope.model.otherInsurancesResponse.cobMedicareInformationStepDTOList.cobMedicareInformationStepDTOData[i].participantInformation.dateOfBirth + ')');
                    }
                }
                return $scope.model.otherInsurancesResponse;
            };

            $scope.createNewMember = function (member) {
                var memberObj = {
                    participantInformation: {
                        dateOfBirth: member.dob,
                        firstName: member.firstName,
                        lastName: member.lastName
                    }
                };

                return memberObj;
            };

            $scope.mergeUMSCOB = function () {
                var members = user;
                $scope.removeDuplicatesPayload($scope.model.otherInsurancesResponse);

                for (var i = 0; i < members.length; i++) {
                    var memberAdded = false;
                    for (var j = 0; j < $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData.length; j++) {

                        if (members[i].uniqueName == $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[j].participantInformation.firstName.toUpperCase() +
                            ' (' + $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData[j].participantInformation.dateOfBirth + ')') {
                            memberAdded = true;
                        }

                    }
                    
                    if (!memberAdded) {
                        var newMember = $scope.createNewMember(members[i]);
                        var addMember = $scope.createNewMember(members[i]);
                        var medMember = $scope.createNewMember(members[i]);
                        
                        if (members[i].relationship == 'Subscriber') {
                            newMember.participantInformation.gender = members[i].gender;
                            $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData.splice(0, 0, newMember);
                            $scope.model.otherInsurancesResponse.cobAdditionalPoliciesInformationStepDTOList.cobAdditionalPoliciesInformationStepDTOData.splice(0, 0, addMember);
                            $scope.model.otherInsurancesResponse.cobMedicareInformationStepDTOList.cobMedicareInformationStepDTOData.splice(0, 0, medMember);
                        } else if (members[i].relationship == 'Spouse' && $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData.length > 1) {
                            newMember.participantInformation.gender = members[i].gender;
                            $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData.splice(1, 0, newMember);
                            $scope.model.otherInsurancesResponse.cobAdditionalPoliciesInformationStepDTOList.cobAdditionalPoliciesInformationStepDTOData.splice(1, 0, addMember);
                            $scope.model.otherInsurancesResponse.cobMedicareInformationStepDTOList.cobMedicareInformationStepDTOData.splice(1, 0, medMember);

                        } else {
                            newMember.participantInformation.gender = members[i].gender;
                            $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData.push(newMember);
                            $scope.model.otherInsurancesResponse.cobAdditionalPoliciesInformationStepDTOList.cobAdditionalPoliciesInformationStepDTOData.push(addMember);
                            $scope.model.otherInsurancesResponse.cobMedicareInformationStepDTOList.cobMedicareInformationStepDTOData.push(medMember);
                        }
                    }
                }
            };

            $scope.isEquivalent = function (a, b) {
                // Create arrays of property names
                var aProps = Object.getOwnPropertyNames(a);
                var bProps = Object.getOwnPropertyNames(b);

                // If number of properties is different,
                // objects are not equivalent
                if (aProps.length != bProps.length) {
                    return false;
                }

                for (var i = 0; i < aProps.length; i++) {
                    var propName = aProps[i];

                    // If values of same property are not equal,
                    // objects are not equivalent
                    if (a[propName] !== b[propName]) {
                        return false;
                    }
                }

                // If we made it this far, objects
                // are considered equivalent
                return true;
            };

            $scope.populateModelfromUMS = function (umslist) {
                $scope.users1 = [];
                $scope.users1 = umslist;
                var memberNameArray = [];

                for (var i = 0; i < $scope.users1.length; i++) {

                    var member = $scope.users1[i];

                    var memberName = member.firstName + member.lastName;

                    for (var j = 0; j < memberNameArray.length; j++) {
                        if (memberName === memberNameArray[j]) {
                            $scope.users1[i].showDOB = true;
                            $scope.users1[j].showDOB = true;
                        }
                    }

                    if (i === 0 || member.showDOB === false) {
                        memberNameArray.push(memberName);
                    }

                    // build comparing obj for first last dob
                    var test = self;
                    member.compare = {
                        "firstName": member.firstName.toLowerCase(),
                        "lastName": member.lastName.toLowerCase(),
                        "dateOfBirth": member.dob
                    };
                    member.showOnStep = {
                        "one": true,
                        "two": false,
                        "three": false
                    };

                    member.fieldSets = {};
                    member.fieldSets2 = {};
                    member.fieldSets3 = {};

                    // step1
                    member.step1Form = {
                        value: {}
                    };
                    member.fieldSets.active = {
                        value: 'No'
                    }; // model for first step radio inputs
                    member.fieldSets.medicare = {
                        value: 'No'
                    }; // model for first step radio inputs

                    //step2
                    member.step2Form = {
                        value: {}
                    };
                    member.fieldSets2.userMatch = {
                        value: ''
                    };
                    member.fieldSets2.carrierName = {value:''};
                    member.fieldSets2.groupNumber = {value:''};
                    member.fieldSets2.policyNumber = {};
                    member.fieldSets2.policyFirst = {};
                    member.fieldSets2.policyLast = {value:''};
                    member.fieldSets2.effectiveDate = {};
                    member.fieldSets2.cancelDate = {};
                    member.fieldSets2.relationshipToHolder = {value:''};
                    member.fieldSets2.policyDOB = {};
                    member.fieldSets2.employmentStatus = {value:''};
                    member.fieldSets2.custody = {value:''};
                    member.fieldSets2.primaryParent = {};
                    member.fieldSets2.courtOrder = {};
                    member.fieldSets2.retirementDate = {value:''};

                    //step3
                    member.step3Form = {
                        value: {}
                    };
                    member.fieldSets3.checkOtherEffDate = false;
                    member.fieldSets3.medicareClaimNumber = { value :''};
                    member.fieldSets3.eligibilityReason = { value: ''};
                    member.fieldSets3.disabilityBeginDate = {};
                    member.fieldSets3.ssDisabilityIncome = {
                        value: ''
                    };
                    member.fieldSets3.medicareAEffDate = {};
                    member.fieldSets3.medicareACanDate = {};
                    member.fieldSets3.medicareBEffDate = {};
                    member.fieldSets3.medicareBCanDate = {};
                    member.fieldSets3.medicareCEffDate = {};
                    member.fieldSets3.medicareCCanDate = {};
                    member.fieldSets3.medicareDEffDate = {};
                    member.fieldSets3.medicareDCanDate = {};
                    member.fieldSets3.ssDisabilityStartDt = {};
                    member.fieldSets3.ssDisabilityEndDt = {};
                    member.fieldSets3.medicareC = {
                        value: ''
                    };
                    member.fieldSets3.medicareCCarrierName = {};
                    member.fieldSets3.medicareCPolicyNumber = {};
                    member.fieldSets3.medicareCGroupNumber = {};
                    member.fieldSets3.medicareCEffectiveDate = {};
                    member.fieldSets3.medicareCCancelDate = {};
                    member.fieldSets3.medicareD = {
                        value: ''
                    };
                    member.fieldSets3.medicareDCarrierName = {};
                    member.fieldSets3.medicareDPolicyNumber = {};
                    member.fieldSets3.medicareDGroupNumber = {};
                    member.fieldSets3.medicareDEffectiveDate = {};
                    member.fieldSets3.medicareDCancelDate = {};
                    member.fieldSets3.bin = {value:''};
                    member.fieldSets3.pcn = {value:''};

                    // ==================================================STEP DTO==================================================
                    if ($scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData.length > 0) {
                        var oiInfo = $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData;
                            oiInfo.forEach(function (user, index) {
                            var tempCompare = {
                                "firstName": user.participantInformation.firstName.toLowerCase(),
                                "lastName": user.participantInformation.lastName.toLowerCase(),
                                "dateOfBirth": user.participantInformation.dateOfBirth
                            };

                            var compare = $scope.isEquivalent(member.compare, tempCompare);
                            if (compare) {
                                if (user.hasOtherActiveInsurance != "Z_Blank" && user.hasOtherActiveInsurance != undefined) {
                                    $scope.reVisit1 = true;
                                    $scope.users1[i].fieldSets.active.value = (user.hasOtherActiveInsurance == "Y") ? "Yes" : "No";
                                }
                                if (user.eligibleForMedicare != "Z_Blank" && user.eligibleForMedicare != undefined) {
                                    $scope.reVisit1 = true;
                                    $scope.users1[i].fieldSets.medicare.value = (user.eligibleForMedicare == "Y") ? "Yes" : "No";
                                }
                            }
                        });
                    } else {
                        var otherInsBlankObj = {
                            'participantInformation': {
                                'firstName': member.firstName,
                                'lastName': member.lastName,
                                'dateOfBirth': member.dob,
                                'cobRecordSequenceNumber': i + 1,
                                'gender': member.gender
                            }
                        };
                        $scope.model.otherInsurancesResponse.cobOtherInsuranceStepDTOList.cobOtherInsuranceStepDTOData.push(otherInsBlankObj);
                    }

                }
                // ==================================================ADDITIONAL POLICIES==================================================
                $scope.users1.forEach(function (user) {
                    for (var l = 0; l < $scope.model.otherInsurancesResponse.cobAdditionalPoliciesInformationStepDTOList.cobAdditionalPoliciesInformationStepDTOData.length; l++) {
                        var oiInfo = $scope.model.otherInsurancesResponse.cobAdditionalPoliciesInformationStepDTOList.cobAdditionalPoliciesInformationStepDTOData[l];
    
                        var tempCompare = {
                           "firstName": oiInfo.participantInformation.firstName.toLowerCase(),
                           "lastName": oiInfo.participantInformation.lastName.toLowerCase(),
                           "dateOfBirth": oiInfo.participantInformation.dateOfBirth
                        };
                        // // var compare = objectCompare.equals( member.compare , tempCompare );
                        var compare = $scope.isEquivalent(user.compare, tempCompare);
                        
                        if (compare) {
                           if(oiInfo.relationshipToContractHolder != "0_"){
    
                            user.fieldSets2.carrierName.value = oiInfo.nameOfInsuranceCarrier || '';
                            user.fieldSets2.groupNumber.value = oiInfo.groupNumber || '';
                            user.fieldSets2.policyNumber.value = oiInfo.policyNumber || '';
                            user.fieldSets2.policyFirst.value = oiInfo.FirstName || '';
                            user.fieldSets2.policyLast.value = oiInfo.LastName || '';
                            user.fieldSets2.effectiveDate.value = oiInfo.effectiveDate || '';
                            user.fieldSets2.cancelDate.value = oiInfo.cancelDate || '';
                            user.fieldSets2.relationshipToHolder.value = oiInfo.relationshipToContractHolder && oiInfo.relationshipToContractHolder.charAt(0) || '';
                            user.fieldSets2.employmentStatus.value = oiInfo.employmentStatus && oiInfo.employmentStatus.charAt(0) || '';
                            user.fieldSets2.custody.value = oiInfo.custody && oiInfo.custody.charAt(0) || '';
                            user.fieldSets2.primaryParent.value = oiInfo.parentWithPrimaryMedicalResponsibility && oiInfo.parentWithPrimaryMedicalResponsibility.charAt(0) || '';
                            user.fieldSets2.courtOrder.value = oiInfo.dependentInformationCourtOrder && oiInfo.dependentInformationCourtOrder.charAt(0) || '';
                            user.fieldSets2.policyDOB.value = oiInfo.dateOfBirth || '';
                            user.fieldSets2.retirementDate.value = oiInfo.retirementDate || '';
                        }}
    
                        if (oiInfo.policyNumber != '' || oiInfo.policyNumber != null) {
                            $scope.users2 = true;
                        }
    
                        if (user.fieldSets2.relationshipToHolder.value == '0') {
                            user.fieldSets2.relationshipToHolder.value = '';
    
                        }
    
                        if (user.fieldSets2.employmentStatus.value == 'R') {
                            user.retirementSet = true;
                        }
    
                        //Toggles whether to display custody info
                        switch (member.relationshipCode) {
                            case '02':
                            case '05':
                            case '09':
                            case '17':
                            case '07':
                            case '14':
                            {
                                user.isDependent = true;
                                if (user.fieldSets2.custody.value.value == 'M'
                                    || user.fieldSets2.custody.value.value == 'F'
                                    || user.fieldSets2.custody.value.value == 'J') {
                                    user.custodySet = true;
                                }
                            }
                        }
                        if (user.fieldSets2.custody.value.value == 'M'
                            || user.fieldSets2.custody.value.value == 'F'
                            || user.fieldSets2.custody.value.value == 'J') {
                            user.fieldSets2.showCustody = true;
                        } else {
                            user.fieldSets2.showCustody = false;
                        }
                    };
                });

                //=======================================MEDICARE INFORMATION=============================================================
                var medInfo = $scope.model.otherInsurancesResponse.cobMedicareInformationStepDTOList.cobMedicareInformationStepDTOData;

                $scope.users1.forEach(function (user) {

                    medInfo.forEach(function(valb,i){

                        var tempCompare = {
                           "firstName": valb.participantInformation.firstName.toLowerCase(),
                           "lastName": valb.participantInformation.lastName.toLowerCase(),
                           "dateOfBirth": valb.participantInformation.dateOfBirth
                        };
                        var compare = $scope.isEquivalent( user.compare , tempCompare );
                        
                        if(compare){
                            user.fieldSets3.medicareClaimNumber.value = valb.medicareClaimNumber || '';
                            user.fieldSets3.eligibilityReason.value = valb.eligibilityCriteria && valb.eligibilityCriteria.charAt(0) || '';
                            user.fieldSets3.disabilityBeginDate.value = valb.disabilityBeginDate || '';
                            user.fieldSets3.medicareAEffDate.value = valb.medicarePartAEffectiveDate || '';
                            user.fieldSets3.medicareACanDate.value = valb.medicarePartACancelDate || '' || '';
                            user.fieldSets3.medicareBEffDate.value = valb.medicarePartBEffectiveDate || '';
                            user.fieldSets3.medicareBCanDate.value = valb.medicarePartBCancelDate || '';
                            user.fieldSets3.ssDisabilityStartDt.value = valb.socialSecurityDisabilityIncomeStartDate || '';
                            user.fieldSets3.ssDisabilityEndDt.value = valb.socialSecurityDisabilityIncomeEndDate || '';
                            user.fieldSets3.medicareCCarrierName.value = valb.medicarePartCCarrierName || '';
                            user.fieldSets3.medicareCPolicyNumber.value = valb.medicarePartCPolicyNumber || '';
                            user.fieldSets3.medicareCGroupNumber.value = valb.medicarePartCGroupNumber || '';
                            user.fieldSets3.medicareCEffectiveDate.value = valb.medicarePartCEffectiveDate || '';
                            user.fieldSets3.medicareCCancelDate.value = valb.medicarePartCCancelDate || '';
                            user.fieldSets3.medicareDCarrierName.value = valb.medicarePartDCarrierName || '';
                            user.fieldSets3.medicareDPolicyNumber.value = valb.medicarePartDPolicyNumber || '';
                            user.fieldSets3.medicareDGroupNumber.value = valb.medicarePartDGroupNumber || '';
                            user.fieldSets3.medicareDEffectiveDate.value = valb.medicarePartDEffectiveDate || '';
                            user.fieldSets3.medicareDCancelDate.value = valb.medicarePartDCancelDate || '';
                            user.fieldSets3.bin.value = valb.bin || '';
                            user.fieldSets3.pcn.value = valb.pcn || '';
    
    
                            if(valb.socialSecurityDisabilityIncome != "Z_Blank" && valb.socialSecurityDisabilityIncome){
                                user.fieldSets3.ssDisabilityIncome.value = (valb.socialSecurityDisabilityIncome == 'Y') ? 'Yes' : 'No';
                                if(user.fieldSets3.ssDisabilityIncome.value == 'Yes'){
                                    user.fieldSets3.socialSecurityDisabilityIncomeShow = true;
                                }
                            }else{user.fieldSets3.ssDisabilityIncome.value= 'No';}
    
                            if(valb.medicarePartCYesNo != "Z_Blank" && valb.medicarePartCYesNo){
                                user.fieldSets3.medicareC.value = (valb.medicarePartCYesNo == 'Y') ? 'Yes' : 'No';
                                if(user.fieldSets3.medicareC.value == 'Yes'){
                                    user.fieldSets3.medicareCShow = true;
                                }
                            }else{ user.fieldSets3.medicareC.value = 'No';}
    
                            if(valb.medicarePartDYesNo != "Z_Blank" && valb.medicarePartDYesNo){
                                user.fieldSets3.medicareD.value = (valb.medicarePartDYesNo == 'Y') ? 'Yes' : 'No';
                                if(user.fieldSets3.medicareD.value == 'Yes'){
                                    user.fieldSets3.medicareDShow = true;
                                }
                            }else{user.fieldSets3.medicareD.value = 'No';}
    
                            if(valb.medicareClaimNumber != '' || valb.medicareClaimNumber != null){
                                $scope.users3 = true;
                            }
                        }
                    });
                });

            };


            //Forces cm tag for copy selection to fire only once per page view, not once per "user" per page view.
            var cmCopyUserData = false;
                //copies data of the selected member for the current drawer
            $scope.copyUserData = function(user){
                if(cmCopyUserData != true){
                    coremetrics.elementTag('COPY OTHER INSURANCE CORE DATA','MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                    cmCopyUserData = true;
                }
                if(user.fieldSets2.userMatch.value != ''){
                    var matchUser = user.fieldSets2.userMatch.value.value;
                    user.fieldSets2.carrierName.value = $scope.additionalUsers[matchUser].fieldSets2.carrierName.value;
                    user.fieldSets2.groupNumber.value = $scope.additionalUsers[matchUser].fieldSets2.groupNumber.value;
                    user.fieldSets2.policyFirst.value = $scope.additionalUsers[matchUser].fieldSets2.policyFirst.value;
                    user.fieldSets2.policyLast.value = $scope.additionalUsers[matchUser].fieldSets2.policyLast.value;
                    user.fieldSets2.policyNumber.value = $scope.additionalUsers[matchUser].fieldSets2.policyNumber.value;
                    user.fieldSets2.effectiveDate.value = $scope.additionalUsers[matchUser].fieldSets2.effectiveDate.value;
                   // user.fieldSets2.relationshipToHolder.value = $scope.additionalUsers[matchUser].fieldSets2.relationshipToHolder.value;
                    user.fieldSets2.policyDOB.value = $scope.additionalUsers[matchUser].fieldSets2.policyDOB.value;
                    //user.fieldSets2.employmentStatus.value = $scope.additionalUsers[matchUser].fieldSets2.employmentStatus.value;
                    //user.fieldSets2.retirementDate.value = $scope.additionalUsers[matchUser].fieldSets2.retirementDate.value;
                    switch(user.relationshipCode){
                        //dependent codes
                        case '01':
                        case '02':
                        case '05':
                        case '09':
                        case '17':
                        case '07':
                        case '14':{
                            user.fieldSets2.custody.value = $scope.additionalUsers[matchUser].fieldSets2.custody.value;
                            user.fieldSets2.primaryParent.value = $scope.additionalUsers[matchUser].fieldSets2.primaryParent.value;
                            user.fieldSets2.courtOrder.value = $scope.additionalUsers[matchUser].fieldSets2.courtOrder.value;
                        }
                    }
                    user.retirementSet = false;
                    if(user.fieldSets2.employmentStatus.value == 'R'){
                        user.retirementSet = true;
                    }  else {
                        user.retirementSet = false;
                    }
                    user.custodySet = false;
                    if(user.fieldSets2.custody.value == 'M' || user.fieldSets2.custody.value == 'F' || user.fieldSets2.custody.value == 'J'){
                        user.custodySet = true;
                    }
                    else{
                        user.custodySet = false;}
                }

                if(screenTwoPreVal == true){

                    user.step2Form.$validate();
                }
            };
            $scope.editClicked = function (step,userclicked) {
                //Reset cm flags to allow cm tags to fire once per "page" view
                cmResetFlags();
                cmSetStep = [false, false, false, false, false];

                if (step === 2) {
                    if (cmClickEditValue != true) {
                        coremetrics.elementTag('EDIT OTHER INSURANCE DETAILS', 'MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                        coremetrics.pageviewTag('MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE:OTHER INSURANCE DETAILS', 'MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                        cmClickEditValue = true;
                    }
                    // $scope.setUpStepTwo();

                    for(var i = 0; i < $scope.additionalUsers.length; i++){
                        $scope.additionalUsers[i].drawerOpen = '';
                    }

                    userclicked.drawerOpen = 'open';
                    if (userclicked.drawerOpen == "open"){goTo(step);}
                    //goTo(step);

                } else if (step === 3) {
                    if (cmClickEditValue != true) {
                        coremetrics.elementTag('EDIT MEDICARE COVERAGE DETAILS OTHER', 'MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                        coremetrics.pageviewTag('MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE:MEDICARE COVERAGE', 'MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                        cmClickEditValue = true;
                    }
                    //setUpStepThree();
                    for(var i = 0; i < $scope.medicareUsers.length; i++){
                        $scope.medicareUsers[i].drawerOpen = '';
                    }
                    userclicked.drawerOpen = 'open';
                    goTo(step)
                }
               window.scrollTo(0, 0);

            };

            $scope.checkData = function(user){

                $scope.showChangesSaved = false;
                if(user.fieldSets2.userMatch.value != ''){
                    user.fieldSets2.userMatch.value = '';
                }


                //changes info based on copy selection
                for(var i = 0; i < $scope.additionalUsers.length; i++){
                    if($scope.additionalUsers[i].fieldSets2.userMatch.value != '' &&
                        !(user.firstName == $scope.additionalUsers[i].firstName &&
                        user.lastName == $scope.additionalUsers[i].lastName &&
                        user.dob == $scope.additionalUsers[i].birthDate)){

                        $scope.copyUserData($scope.additionalUsers[i]);
                    }
                }

                //retirement check
                user.retirementSet = false;
                if(user.fieldSets2.employmentStatus.value == 'R'){
                    user.retirementSet = true;
                }else{
                    user.fieldSets2.retirementDate.value = '';
                    user.retirementSet = false;
                }

                //custody check
                user.custodySet = false;

                if(user.fieldSets2.custody.value.value == 'M' || user.fieldSets2.custody.value.value == 'F' || user.fieldSets2.custody.value.value == 'J'){
                    user.custodySet = true;

                }else{
                    user.fieldSets2.primaryParent.value = '';
                    user.fieldSets2.courtOrder.value = '';
                    user.custodySet = false;
                }

            };

            $scope.cmOnExMakeChanges = function () {
                var cmOnExMakeChangesClicked;
                if(cmOnExMakeChangesClicked != true){
                    coremetrics.elementTag('MAKE CHANGES TO YOUR COVERAGE OTHER INSURANCE','MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                    cmOnExMakeChangesClicked = true;
                }
            };

            var setUpStepThree = function(){

                for(var i = 0; i < $scope.medicareUsers.length; i++){
                    if (i == 0) {
                        $scope.medicareUsers[i].drawerOpen = 'open';
                    }else{
                        $scope.medicareUsers[i].drawerOpen = '';
                    }
                    
                    $scope.medicareUsers[i].fieldSets3.disabilityBeginDate.value = new Date($scope.medicareUsers[i].fieldSets3.disabilityBeginDate.value);
                    $scope.medicareUsers[i].fieldSets3.medicareACanDate.value = new Date($scope.medicareUsers[i].fieldSets3.medicareACanDate.value);
                    $scope.medicareUsers[i].fieldSets3.medicareAEffDate.value = new Date($scope.medicareUsers[i].fieldSets3.medicareAEffDate.value);
                    $scope.medicareUsers[i].fieldSets3.medicareBCanDate.value = new Date($scope.medicareUsers[i].fieldSets3.medicareBCanDate.value);
                    $scope.medicareUsers[i].fieldSets3.medicareBEffDate.value = new Date($scope.medicareUsers[i].fieldSets3.medicareBEffDate.value);
                    $scope.medicareUsers[i].fieldSets3.medicareCCancelDate.value = new Date($scope.medicareUsers[i].fieldSets3.medicareCCancelDate.value);
                    $scope.medicareUsers[i].fieldSets3.medicareCEffectiveDate.value = new Date($scope.medicareUsers[i].fieldSets3.medicareCEffectiveDate.value);
                    $scope.medicareUsers[i].fieldSets3.medicareDCancelDate.value = new Date($scope.medicareUsers[i].fieldSets3.medicareDCancelDate.value);
                    $scope.medicareUsers[i].fieldSets3.medicareDEffectiveDate.value = new Date($scope.medicareUsers[i].fieldSets3.medicareDEffectiveDate.value);
                    $scope.medicareUsers[i].fieldSets3.ssDisabilityStartDt.value = new Date($scope.medicareUsers[i].fieldSets3.ssDisabilityStartDt.value);
                    $scope.medicareUsers[i].fieldSets3.ssDisabilityEndDt.value = new Date($scope.medicareUsers[i].fieldSets3.ssDisabilityEndDt.value);

                    $scope.eligibilityReasonList = [
                        //{label: 'Select', value: ''},
                        {label: 'Age', value: '0'},
                        {label: 'Disability', value: '1'},
                        {label: 'End Stage Renal Disease', value: '2'},
                        {label: 'Disability and End Stage Renal Disease', value: '3'}
                    ];
                    if($scope.medicareUsers[i].fieldSets3.eligibilityReason.value =='1' ||
                        $scope.medicareUsers[i].fieldSets3.eligibilityReason.value == '2'||$scope.medicareUsers[i].fieldSets3.eligibilityReason.value == '3'){
                        $scope.medicareUsers[i].fieldSets3.disabilityBeginDateShow = true;
                        $scope.medicareUsers[i].isDisabled = true;
                    }
                    else{
                        $scope.medicareUsers[i].fieldSets3.disabilityBeginDateShow = false;
                        $scope.medicareUsers[i].isDisabled= false; }
                }
                //if($scope.medicareUsers[i].fieldSets3.medicareC.value=='No'){
                //    $scope.medicareUsers[i].fieldSets3.medicareCCarrierName.value='';
                //    $scope.medicareUsers[i].fieldSets3.medicareCPolicyNumber.value='';
                //    $scope.medicareUsers[i].fieldSets3.medicareCGroupNumber.value='';
                //    $scope.medicareUsers[i].fieldSets3.medicareCEffectiveDate.value='';
                //    $scope.medicareUsers[i].fieldSets3.medicareCCancelDate.value='';
                //}
                //if($scope.medicareUsers[i].fieldSets3.medicareD.value=='No'){
                //    $scope.medicareUsers[i].fieldSets3.medicareDCarrierName.value='';
                //    $scope.medicareUsers[i].fieldSets3.medicareDPolicyNumber.value='';
                //    $scope.medicareUsers[i].fieldSets3.medicareDGroupNumber.value='';
                //    $scope.medicareUsers[i].fieldSets3.medicareDEffectiveDate.value='';
                //    $scope.medicareUsers[i].fieldSets3.medicareDCancelDate.value = '';
                //    $scope.medicareUsers[i].fieldSets3.pcn.value='';
                //    $scope.medicareUsers[i].fieldSets3.bin.value='';
                //
                //}
            }
            $scope.checkForData = function(user){
                //check for disability
                user.isDisabled= false;
                user.fieldSets3.disabilityBeginDateShow = false;
                if(user.fieldSets3.eligibilityReason.value =='1' ||
                    user.fieldSets3.eligibilityReason.value == '2'||user.fieldSets3.eligibilityReason.value == '3'){
                    user.fieldSets3.disabilityBeginDateShow = true;
                    user.isDisabled = true;
                }
                else{
                    user.fieldSets3.disabilityBeginDateShow = false;
                    user.isDisabled= false; }


                //check for disability income
                $scope.isSSDisabilityIncome=false;

                if(user.fieldSets3.ssDisabilityIncome.value == 'Yes'){
                    $scope.isSSDisabilityIncome= true;
                } else{
                    user.fieldSets3.ssDisabilityIncome.value = 'No'
                    $scope.isSSDisabilityIncome=false;}


                //check for Medicare Part-C
                //This is breaking for multiple users. Took out this logic in HTML
                if(user.fieldSets3.medicareC.value=='Yes'){
                    $scope.isMedicareC= true;}
                else{
                        $scope.isMedicareC = false;
                    user.fieldSets3.medicareCCarrierName.value='';
                    user.fieldSets3.medicareCPolicyNumber.value='';
                    user.fieldSets3.medicareCGroupNumber.value='';
                    user.fieldSets3.medicareCEffectiveDate.value='';
                    user.fieldSets3.medicareCCancelDate.value='';
                    }


                //Check for Medicare Part-D
                //This is breaking for multiple users. Took out this logic in HTML
                if(user.fieldSets3.medicareD.value=='Yes'){
                    $scope.isMedicareD = true;
                }
                else{
                    $scope.isMedicareD = false;
                    user.fieldSets3.medicareDCarrierName.value='';
                    user.fieldSets3.medicareDPolicyNumber.value='';
                    user.fieldSets3.medicareDGroupNumber.value='';
                    user.fieldSets3.medicareDEffectiveDate.value='';
                    user.fieldSets3.medicareDCancelDate.value = '';
                    user.fieldSets3.pcn.value='';
                    user.fieldSets3.bin.value='';
                }

            };


            $scope.radioButtonClick= function(user){
                try{
                    //needed for IE8 radio button
                    var target = this.$evt.target;
                    switch(target.name){
                        case 'socialSec':
                            user.fieldSets3.ssDisabilityIncome.value = target.value;
                            break;
                        case 'medicareC':
                            user.fieldSets3.medicareC.value = target.value;
                            break;
                        case 'medicareD':
                            user.fieldSets3.medicareD.value = target.value;
                            break;
                    }

                }catch(e){
                    //no target
                }
            };

            var cmResetFlags = function () {
                //Reset cm flags to allow cm tags to fire once per "page" view
                cmCopyUserData = false;
                cmMemberClickedValue = false;
                cmNextButton = false;
                cmBackButton = false;
                cmSaveToServerValue = false;
                cmPrint = false;
                cmOtherInsuranceEditValue = false;
                cmClickEditValue = false;
            }

            var cmClickEditValue = false;



            $scope.handleKeyPressEdit = function (step) {
                if (mouseClick(this.$evt) || keyPressEnter(this.$evt)) {
                    switch(step) {
                        case 2:
                            cmOtherInsuranceEdit();
                            break;
                        case 3:
                            clickEdit(step);
                            break;
                    }
                }
            }


            // =======================END OF FUNCTIONS======================
            var memberList = $scope.removeDuplicateMembers(members);
            var memberListInfo = [];
            var DOBInfo;

            memberListInfo = $scope.getmemberListInfo(memberList);
            DOBInfo = $scope.getDOBShowFlag(memberListInfo);

            var user = $scope.getFullMemberList(memberList);
        }
    ]);
    return [{
            state: "account.otherInsurance",
            postPassword: true,
            appConfigMaintenanceApp: 'ACCOUNT_SETTINGS',
            appConfigMaintenanceKey: 'OTHER_INSURANCE_MAINTENANCE',
            url: "/other-insurance",
            // abstract: true,
            views: {
                "content@account": {
                    abstract: true,
                    template: require('pages/account/other-insurance/other-insurance.html'),
                    controller: "pages.account.other-insurance"
                },
                "OIcontent@account.otherInsurance": {
                    template: require('pages/account/other-insurance/OIStep1.html'),
                    controller: "pages.account.other-insurance.OIStep1"
                }
            }
        },
        {
            state: "account.otherInsurance.Step1",
            views: {
                // 'body@': {
                //     template: require('pages/account/other-insurance/other-insurance.html'),
                //     controller: "pages.account.other-insurance"
                // },
                "OIcontent": {
                    template: require('pages/account/other-insurance/OIStep1.html'),
                    controller: "pages.account.other-insurance.OIStep1"
                }
            }
        },
        {
            state: "account.otherInsurance.Step2",
            views: {
                "OIcontent": {
                    template: require('pages/account/other-insurance/OIStep2.html'),
                    controller: "pages.account.other-insurance.OIStep2"
                }

            }
        },
        {
            state: "account.otherInsurance.Step3",
            views: {
                "OIcontent": {
                    template: require('pages/account/other-insurance/OIStep3.html'),
                    controller: "pages.account.other-insurance.OIStep3"
                }
            }
        },
        {

            state: "account.otherInsurance.Step4",
            views: {
                "OIcontent": {
                    template: require('pages/account/other-insurance/OIStep4.html'),
                    controller: "pages.account.other-insurance.OIStep4"
                }
            }
        }
    ];
}