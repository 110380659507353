module.exports = function(app) {
    app.directive('benefitBooklet', function() {
        return {
            restrict: 'A',
            template: require('directives/coverage-summary/benefitBooklet.html'),
            controller: ['$scope', 'sessionData', 'finder-logic2', 'services', 'modal','teamsite','requestAPrintedCopyService',
                function($scope, sessionData, finderLogic, services, modal,teamsite,requestAPrintedCopyService) {

                $scope.getPrintedCopy = function() {
                    requestAPrintedCopyService.requestCopy().then(function(response){
                        var responsePrintedService = response;

                        if (responsePrintedService === 'success') {
                            modal.open({
                                template: require('modals/requestPrintedDrugCoverageModal.html')
                            });
                        } else {
                            modal.open({
                                template: require('modals/requestPrintedDrugCoverageErrorModal.html')
                            })
                        }
                    });
                };
                
                var ums = sessionData.memberInfo;
                var curClnt = ums.clientList[0];
                var curGrp = finderLogic.getPortalSelectedPlan(ums);
                var curPrd = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate($scope.covType);

                var hasCoverage = function (curGrp, strPlan) {
                    var bFound = false;
                    for (iPrdIdx = 0; iPrdIdx < curGrp.productList.length; ++iPrdIdx) {
                        var curPrd = curGrp.productList[iPrdIdx];
                        if (curPrd.benefitPlanType === strPlan) {
                            bFound = true;
                            break;
                        }
                    }
                    return bFound;
                };

                var bbParams = {
                    brand: ums.brand
                };

                bbParams.payload = {
                    "visionCoverage": hasCoverage(curGrp, "Vision"),
                    "icisClientId": curClnt.clientId,
                    "icisClientNumber": curClnt.clientNumber,
                    "groupNumber": curGrp.groupNumber,
                    "medicalCoverage": hasCoverage(curGrp, "Medical"),
                    "drugCoverage": hasCoverage(curGrp, "Drug"),
                    "coverageCancelDate": (curPrd.memberList[0].coverageCancellationDate != "" ? curPrd.memberList[0].coverageCancellationDate : "00/00/0000"),
                    "curEffDate": (curPrd.coverageBeginDate != "" ? curPrd.coverageBeginDate : "00/00/0000"), // thePlan.coverageBeginDate,
                    "dentalCoverage": hasCoverage(curGrp, "Dental")
                };

                // BENEFIT BOOKLET LINKS
                var fileName;
                var bbResponse;
                var bShowANOC;
                var futureBBDeferred;
                var coverageCheckDeferred;

                var shouldShowBenefitBookletByDefault = function () {
                    if (sessionData.appConfig &&
                        sessionData.appConfig.COVERAGE &&
                        sessionData.appConfig.COVERAGE.SUPPRESS_FAILED_BENEFIT_BOOKLET) {
                        return !sessionData.appConfig.COVERAGE.SUPPRESS_FAILED_BENEFIT_BOOKLET.booleanValue;
                    }
                    return true;
                };
                $scope.showBenefitBookletLink = false;
                $scope.hasMedicalBenefitDetails = function () {
                    return $scope.showBenefitBookletLink || $scope.bShowSbcLink;
                };
                services.rbsmbl.BenefitBooklet().fetch(bbParams).then(
                    function (data) {
                        bbResponse = data && data.data && data.data.payload;
                        fileName = bbResponse && bbResponse.url;
                        $scope.linkName = bbResponse && bbResponse.linkName;
                        $scope.bShowANOC = bbResponse && bbResponse.showHeaderLabel;

                        $scope.showBenefitBookletLink = !!fileName;
                        if (!$scope.showBenefitBookletLink) {
                            $scope.showBenefitBookletLink = shouldShowBenefitBookletByDefault();
                            $scope.linkName = (!$scope.linkName) ? 'Current Plan-Year' : $scope.linkName;
                        }
                    }, function (errorObj) {
                        $scope.showBenefitBookletLink = shouldShowBenefitBookletByDefault();
                        $scope.linkName = (!$scope.linkName) ? 'Current Plan-Year' : $scope.linkName;
                    }
                );

                $scope.bbClick = function () {
                    if (!fileName) {
                        modal.open({
                            template: require('modals/benefitBookletError.html'),
                            controller: myModal,
                            scope: $scope
                        });
                    } else if(ums.brand === "HMBCBS" || ums.brand === "PBS"){
                            if (curClnt.groupList[0].onlineBenefitBooklet === "N"){
                            modal.open({
                                template: require("modals/benefitBookletDisclaimer.html"),
                                scope: $scope
                            });

                            $scope.openBenefitBooklet = function () {
                                window.open(fileName);
                                $scope.closeModal();
                            };
                       }
                        else{
                             window.open(fileName);
                             $scope.closeModal();
                         }
                    }
                    else{
                        window.open(fileName);
                        $scope.closeModal();
                    }
                };

                // Request Printed Copy
                // checks if current user is within the list of eoc usergroups coming from teamsite
                teamsite.get('EOC_AND_FORM_GROUPS').then(function(response){

                    $scope.EOC_AND_FORM_GROUPS = response;

                    var eocUserGroups = $scope.EOC_AND_FORM_GROUPS;

                    $scope.showEOCContentGroup = false;
                    var currentGroupNumber  = finderLogic.getPortalSelectedPlan(ums).groupNumber;
                    var shortGroupNumber = currentGroupNumber.slice(1, -1);
                    if(eocUserGroups && eocUserGroups.indexOf(shortGroupNumber)>-1){
                        $scope.showEOCContentGroup = true;
                    }


                    // if current day is beyond 8/31/2018 show content
                    var appConfigRequestCopy = (sessionData.appConfig &&
                    sessionData.appConfig.PRESCRIPTIONS &&
                    sessionData.appConfig.PRESCRIPTIONS.SHOW_EOC_REQUEST_COPY &&
                    sessionData.appConfig.PRESCRIPTIONS.SHOW_EOC_REQUEST_COPY.booleanValue);

                    if(appConfigRequestCopy === true){
                        $scope.showEOCContentTime  = true;
                    }


                    if($scope.showEOCContentGroup === true && $scope.showEOCContentTime === true) {
                        $scope.showEOCHeader = false;
                    } else {
                        $scope.showEOCHeader = true;
                    }
                });

                // Future effective benefit booklet code
                //debugger;
                var futureFilename;
                if(bbParams.payload.curEffDate !== "00/00/0000") {
                    bbParams.payload.curEffDate = bbParams.payload.curEffDate.substring(0, 6) + (parseInt(bbParams.payload.curEffDate.substring(6, 10)) + 1);

                    coverageCheckDeferred = services.rbsmbl.CoverageCheck().getData(curGrp.groupNumber, bbParams.payload.curEffDate.substring(6,10) + "-" + bbParams.payload.curEffDate.substring(0,2) + "-" + bbParams.payload.curEffDate.substring(3,5));
                    coverageCheckDeferred.then(function(data) {
                        futureBBDeferred = services.BenefitBooklet.fetch(bbParams);
                        futureBBDeferred.then(function (data) {
                                                  bbResponse = data && data.data && data.data.payload;
                                                  futureFilename = bbResponse && bbResponse.url;
                                                  $scope.futureLinkName = "Next Plan-Year";

                                                  $scope.showHeaderLabel = $scope.showHeaderLabel || bbResponse.showHeaderLabel;
                                                  bbDeferred.then(function (data) {
                                                       $scope.showFutureBBLink = !!futureFilename && !(futureFilename === fileName);
                                                          //$scope.$apply();
                                                      });
                                                  //$scope.$apply();
                                              },
                                               function (errorObj){
                                                  $scope.showFutureBBLink = false;
                                                  //$scope.$apply();
                                              });
                    });
                }

                $scope.futureBBClick = function() {
                    var brand = ums.brand || brand.current.sharedBrandCode;
                    coremetrics.elementTag('Download Benefit Booklet','MEMBER: YOUR COVERAGE');

                    if (!futureFilename) {
                        modal.open({
                                   template: require('modals/benefitBookletError.html'),
                                   controller: myModal,
                                   scope: $scope
                                   });
                    }
                    else{
                        if(ums.brand === "HMBCBS" || ums.brand === "PBS"){
                            modal.open({
                                       template: require("modals/benefitBookletDisclaimer.html"),
                                       scope: $scope
                                       });

                            $scope.openBenefitBooklet = function () {
                                window.open(futureFilename);
                                $scope.closeModal();
                            };

                        }
                        else{
                            window.open(futureFilename);
                            $scope.closeModal();
                        }

                      }

                };

            }]
        };
    });
};