module.exports = function(app) {
    app.directive('alertsUi', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/landing/alertsUi.html'),
            scope: true,
            controller: function($scope, $element, $attrs, sessionData, spendingActionsNeeded, claimService, emailConfirmationAlert, $filter) {
                $scope.showEmailConfirmAlert = false;
                $scope.showGoPaperless = false;
                $scope.showClaims = false;
                $scope.funcs = {};
                $scope.landingErrors = {};
                $scope.landingErrors.errorSpending;
                var uid = sessionData.memberInfo.userName;

                // GO PAPERLESS ===========================================================================
                services.rbsmbl.AccountSettingsRetrive().retriveAccountSettings($scope.psp.groupNumber, "goPaperlessAlert", sessionData.memberInfo.brand, sessionData.memberInfo.userName).then(function(accountSettingsData) {
                    if (accountSettingsData && accountSettingsData.data && accountSettingsData.data.payload) {
                        // NOTES: "IE" is not semantic - we can't tell what it means just from the letters "IE" but the service guy says
                        //        it means that the user has chosen to 'go paperless'.  The value "UM" here means US Mail (now that's semantic)
                        //        The logic below comes from CHMMBR, using bootstrap 3.0.5 in file = articles\account\contactPreferenceHelper.js
                        //        where they set the values for the properties they ultimately use:
                        //                     preferences.healthStatements.canView
                        //                     preferences.healthStatements.selected.originalValue
                        var acctData = accountSettingsData.data.payload;
                        if (
                            acctData.selectedHealthStatements != 'IE'
                            && sessionData.isSubscriber
                            && acctData.lobCode.toUpperCase() != 'AG'
                            && acctData.lobCode.toUpperCase() != 'NC'
                            && !(   // the member does NOT have vision and nothing else
                                    acctData.visionCoverage == true
                                    && acctData.medicalCoverage == false
                                    && acctData.dentalCoverage == false
                                    && acctData.DrugCoverage == false
                                )
                            ) {
                            $scope.showGoPaperless = true;
                        }


                    }  })

                // EMAIL ALERT ============================================================================
                var getEmailConfirmation = emailConfirmationAlert.theAlert(sessionData.memberInfo.brand);
                getEmailConfirmation.then(function(emailAlertResponse) {
                    var emailAlert = (emailAlertResponse.data && emailAlertResponse.data.payload
                        && emailAlertResponse.data.payload.communicationAuthenticationStatusDesc )
                        ? emailAlertResponse.data.payload.communicationAuthenticationStatusDesc : '';
                    // TODO phil_s if the user is a Sales person do  emailAlert = 'SalesPerson' so alert box does not display
                    if (emailAlert == '') {
                        console.log('The service at x-services/alerts/email returned an unexpected response.');
                        if (window.location.href.indexOf('denv') > -1) {
                            alert('Check the console for EmailAlert service message.');
                        } }
                    $scope.showEmailConfirmAlert = ((emailAlert == 'Unauthenticated') || (emailAlert == 'Pending')) ? true : false;
                });

                // CLAIMS =================================================================================
                // $scope.lastClaimPaymentVisitDate
                $scope.$watch('lastClaimPaymentVisitDate', function () {
                    if ($scope.lastClaimPaymentVisitDate) {
                        var canHaveClaims = (
                        sessionData.benefits.dental == true ||
                        sessionData.benefits.drug == true ||
                            sessionData.benefits.medical == true
                            ) ? true : false;
                        if (canHaveClaims) {
                            $scope.showClaims = true;
                            var thisEndDate = $scope.serverDate.replace(/-/g, '');
                            var startDate = $scope.lastClaimPaymentVisitDate; // 05/27/2016
                            startDate = Date.parse(startDate) - (60 * 24 * 60 * 60 * 1000);
                            startDate = $filter('date')(new Date(startDate), 'yyyyMMdd');
                            var claimsDateRange = {
                                endDate: thisEndDate,
                                startDate: startDate  // this is to be 60
                            };
                            var claimsResponse = claimService.getSummary(JSON.stringify(claimsDateRange));
                            claimsResponse.then(function (claimsResp) {
                                var numberOfClaims = 0;
                                if (claimsResp && claimsResp.summary && claimsResp.summary.totalClaimsSinceLastVisit) {
                                    numberOfClaims = claimsResp.summary.totalClaimsSinceLastVisit;
                                }
                                $scope.NumberOfClaims = numberOfClaims;
                            })
                        } } });

                // SPENDING ALERT ==============================================================================
                $scope.$watch('plans', function() {
                    if ($scope.plans && $scope.plans.plans && $scope.plans.plans.selected && $scope.plans.plans.selected.clientNumber) {
                        $scope.funcs.hitSpendingService();
                    }
                });

                $scope.funcs.hitSpendingService = function() {
                    // alert('Reload this.');
                    var umiWithSuffix = sessionData.memberInfo.umi + '0';
                    $scope.showSpendingAlert = false;
                    if (sessionData.benefits.spending == true) {
                    // if (true == true) {
                        $scope.showSpendingAlert = true;
                        var spendingActionsNeededResponse =
                            spendingActionsNeeded.theActions(umiWithSuffix,
                                                             $scope.plans.plans.selected.clientNumber,
                                                             sessionData.memberInfo.brand);
                        spendingActionsNeededResponse.then(function (theActions) {
                            if (theActions && theActions.data && theActions.data.repActionNeededItemsReply && theActions.data.repActionNeededItemsReply.baseMessage
                                && theActions.data.repActionNeededItemsReply.baseMessage.returnCode) {
                                if (theActions.data.repActionNeededItemsReply.baseMessage.returnCode == '200') {
                                    if (theActions.data.repActionNeededItemsReply.payload
                                        && theActions.data.repActionNeededItemsReply.payload.repActionNeededItemsData
                                        && theActions.data.repActionNeededItemsReply.payload.repActionNeededItemsData.actionNeededItems
                                        && (theActions.data.repActionNeededItemsReply.payload.repActionNeededItemsData.actionNeededItems.length >= 0)) {
                                        $scope.landingErrors.errorSpending = false;
                                        // we want to NOT count duplicate name values
                                        var arrS = theActions.data.repActionNeededItemsReply.payload.repActionNeededItemsData.actionNeededItems;
                                        var arrNamesOnly = [];
                                        var arrNoDups = [];
                                        for (var i = 0; i < arrS.length; i++) {
                                            arrNamesOnly.push(arrS[i].name);
                                        }
                                        for (var j = 0; j < arrNamesOnly.length; j++) {
                                            var thisName = arrNamesOnly[j].name;
                                            // that name is NOT in arrNoDups already, push it there
                                            if (arrNoDups.indexOf(thisName) == -1) {
                                                arrNoDups.push(arrNamesOnly[j].name);
                                            }
                                        }
                                        var the_count = arrNoDups.length; // alertArray.length;

                                        // can't access the model from in here otherwise its fine   data-show="showSpendingAlert"
                                        if (parseInt(the_count) == 0) {
                                            $scope.showSpendingAlert = false;
                                            // this.model.hideSpendingAlert = true;
                                        }
                                        else {
                                            $scope.spendingAlertMsg = 'You have ' + the_count + ' spending account alert(s).';
                                        }
                                    } else {
                                        // we did not get the response from the service that we were expecting
                                        $scope.landingErrors.errorSpending = true;
                                    }
                                } else {
                                    // we got a return code other than 200
                                    var msg = 'We can\'t get the alerts number at the moment. Error = ' +
                                    theActions.data.repActionNeededItemsReply.baseMessage.returnCodeDescription;
                                    $scope.spendingAlertMsg = msg;
                                    $scope.landingErrors.errorSpending = true;
                                }
                            }  });  }
                }

                // END SPENDING ALERT ==============================================================================
            }
        };
    });
}