module.exports = function(app) {
    app.directive('healthcareTools', function() {
        return {
            restrict: 'A',
            template: require('directives/landing/healthcareTools.html'),
            controller: ['$scope', 'teamsite', 'services', 'finderService', 'displayOrHideItemsWrapper', 'finder-logic2', 'session', 'sessionData', 'brand', 'coremetrics', 'vendorSSOUtility', 'pharmacyServiceWrapper', 'cceService', 'modal', 'ebillUserExists', 
                function($scope, teamsite, services, finderService, displayOrHideItemsWrapper, finderLogic, session, sessionData, brand, coremetrics, vendorSSOUtility, pharmacyServiceWrapper, cceService, modal, ebillUserExists) {
                    // console.log("EBILL", ebillUserExists.theUrl(sessionData.memberInfo.brand));

                    var theBrand;
                    var glbFullMemberInfo;
                    var glbCurMember;
                    var glbPsp;
                    var glbGroupNumber;
                    var glbClientNumber;

                    var fullMemberInfo = session.data.memberInfo;
                    var theDomain = finderLogic.theDomain();
                    var fm = glbFullMemberInfo = fullMemberInfo;
                    theBrand = fm.brand.toLowerCase();

                    finderLogic.setFullMemberInfo(fm);
                    glbCurMember = finderLogic.setLibStorageAndReturnCurMember(fullMemberInfo);

                    glbPsp = finderLogic.getPortalSelectedPlan();
                    glbClientNumber = fm.clientList[0].clientNumber;
                    glbGroupNumber = glbPsp.groupNumber;
                    var userHasMedco = false;
                    var userHasPrime = false;
                    if (glbPsp && glbPsp.pharmacyBenefitProviderIndicator) {
                        userHasMedco = (glbPsp.pharmacyBenefitProviderIndicator.toLowerCase() == 'medco');
                        userHasPrime = (glbPsp.pharmacyBenefitProviderIndicator.toLowerCase() == 'prime');
                    }
                    
                    var psp = finderLogic.getPortalSelectedPlan();
                    // console.log("PSP", psp);
                    // Coremetrics Site Promotion parameter query tag.
                    var cmSP;

                    var currentMemberInfo = finderLogic.setLibStorageAndReturnCurMember();
                    var sessionMemberInfo = sessionData.memberInfo;
                    // console.log("*****", finderLogic.setLibStorageAndReturnCurMember());

                    var rxCarveOutVendor;
                    var callDisplayHide = displayOrHideItemsWrapper.displayOrHideItemsCall(glbFullMemberInfo.clientList[0].clientNumber, sessionData.groupNumber, ["rxCarveOutVendor"], theBrand).then(function(data) {
                        rxCarveOutVendor = data.qualifierInfo.rxCarveOutVendor;
                    });

                    var gettsPrescriptionServices = function() {
                        teamsite.get('MBR_MBRHOME_HEALTHTOOLS_RX_LINK').then(function (data) {
                            cmSP = cmSP + data.toUpperCase();
                            cmSP = cmSP.replace(/ /g,'%20');
                            return $scope.targetUrls.prescriptionService = $scope.targetUrls.prescriptionService + cmSP;
                        })
                    }
                    
                    var hitSsoService = function(theTargetType, coreMetricsTagInfo) {
                        var ssoWindowName = "";
        
                        if (!theTargetType) {
                            return;
                        }
                        if (!coreMetricsTagInfo) {
                            coreMetricsTagInfo = 'cmMain';
                        }
                        if (theTargetType == 'drugFormularyDisplay') {
                            var callDrugVendorWrapper = drugVendorWrapper.getDrugVendor(glbClientNumber, glbGroupNumber, 'LANDING', 'DRUG_FORMULARY', theBrand.toUpperCase());
                            ssoWindowName = ('SSO_DRUG_FORMULARY').toLowerCase();
                            handleSsoResponse(callDrugVendorWrapper, ssoWindowName, theTargetType, coreMetricsTagInfo);
        
                        }
                        else if((theTargetType==='PRESCRIPTION_MAIL_ORDER_FORMS') && (brand.current.sharedBrandCode==='ndbcbs')){
                            window.open('https://www.walgreens.com/register/pharmacyRegistration.jsp','_blank');
                        }
                        else {
                            var prescriptServiceSsoResponse = pharmacyServiceWrapper.doPharmacySsoCall(glbFullMemberInfo, glbCurMember, glbPsp, theTargetType);
                            ssoWindowName = ('sso_pharmacy' + glbPsp.pharmacyBenefitProviderIndicator).toLowerCase();
                            handleSsoResponse(prescriptServiceSsoResponse, ssoWindowName, theTargetType, coreMetricsTagInfo);
                        }
                    };

                    var handleSsoResponse = function(prescriptServiceSsoResponse, windowName, theTargetType, coreMetricsTagInfo) {
                        // TODO: Update once IE fix is determined...
                        var cmExt = coreMetricsPromoTags(theTargetType, coreMetricsTagInfo);
                        //vendorSSOUtility.launchNewSsoWindow(windowName);
                        windowName = "_blank";
        
                        if (prescriptServiceSsoResponse !== null) {
                            prescriptServiceSsoResponse.then(function (vendorSSOStuff) {
                                vendorSSOUtility.genericHandleSSOResponse(vendorSSOStuff, cmExt, windowName);
                            }, function(error) {
                                vendorSSOUtility.genericHandleSSOFailure(error, windowName);
                            });
                        } else {
                            vendorSSOUtility.genericHandleSSOFailure("", windowName);
                        }
                    };
                    var coreMetricsPromoTags = function(target, coreMetricsType) {
                        var cmBrand = brand.override.toUpperCase();
                        if (cmBrand == 'MINCR') {
                                return '?cm_sp=Prime-_-Prescription%20Services%20Prime-_-Cost&%20Savings';
                        } else {
                                return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Cost%20&%20Savings';
                        }
                    };

                    //========================================CORE METRICS========================================
                    $scope.cmPrescriptionServices = function (targetUrl) {
                        if (this.cmPrescriptionServicesClicked != true) {
                            coremetrics.createManualLinkClickTag(targetUrl, null, null);
                            this.cmPrescriptionServicesClicked = true;
                        }
                        if ((targetUrl.indexOf('catamaran') > -1) || (targetUrl.indexOf('medco') > -1)) {
                            // these members just go directly to a url
                            var theDomain = 'https://' + window.location.host;
                            theDomain = theDomain.split('den').join('ten');
                            window.open(theDomain + targetUrl);
                            return;
                        } else if (targetUrl.indexOf('://') > -1) {
                            window.open(targetUrl);
                            return;
                        }

                        var prescSvcPayload = {
                            "umi": sessionMemberInfo.umi,
                            "firstName": sessionMemberInfo.firstName,
                            "lastName": sessionMemberInfo.lastName,
                            "dateOfBirth": sessionMemberInfo.dob,
                            "gender": currentMemberInfo.gender,
                            "relationshipCode": currentMemberInfo.relationshipCode,
                            "directPaySeniorMember": psp.directPayIndicator,
                            "targetType": "Coverage",
                            "relationship": currentMemberInfo.relationship,
                            "clientid": sessionMemberInfo.clientList[0].clientNumber,
                            "vendorName": psp.pharmacyBenefitProviderIndicator
                        };

                        var ssoWindowName = "_blank";
                        var sharedCode = brand.current.sharedBrandCode;
                        if(sharedCode.toLowerCase() == "hmbcbswv"){
                            sharedCode = "ms";
                        }

                        hitSsoService('PRESCRIPTION_CLAIMS', 'cmLeftNav');
                    }
                    $scope.cmCareCostEstimator = function () {
                        if (this.cmCareCostEstimatorClick != true) {
                            coremetrics.elementTag('Estimates Costs for Care','MEMBER:MEMBER HOME');
                            this.cmCareCostEstimatorClick = true;
                        }
                    }
                    $scope.cmMyChart = function () {
                        coremetrics.elementTag('MYCHART LINK', 'MEMBER:MEMBER HOME');
                        window.open($scope.targetUrls.myChart, "_self");
                    }
                    $scope.cmSavingsAdvisor = function() {
                        var that = this;
                        var sitePromo="";
                        if(this.cmSavingsAdvisorClicked != true){
                            coremetrics.elementTag('Savings Advisor Link','MEMBER:MEMBER HOME');
                            this.cmSavingsAdvisorClicked = true;
                        }
                        var savingsAdvisorPayload = {
                            "clientNumber": sessionMemberInfo.clientList[0].clientNumber,
                            "groupNumber": psp.groupNumber,
                            "targetType": "landingPage",
                            "vendorName": "",
                            "category":"savingsAdvisor"
                        };
                        that.vendorSSORequest(savingsAdvisorPayload, sitePromo, '_blank');
                    }

                    // ========================================LINK URLS========================================
                    $scope.targetUrls = {
                        "findProvider" : null,
                        "prescriptionService" : null,
                        "CCE" : null,
                        "myChart" : null,
                        "formsLibrary" : null
                    }

                    /*
                    ========================================FIND A PROVIDER========================================
                    */
                    $scope.showFindProvider = true;

                    var providerCmSP;
                    var providerLinkUrl;
                    var getPVDUrl = function() {
                        teamsite.get("MBR_MBRHOME_HEALTHTOOLS_PROVIDER_LINK_HREF").then(function(pvdLink) {
                            providerLinkUrl = pvdLink;
                            teamsite.get("MBR_MBRHOME_HEALTHTOOLS_PROVIDER_LINK").then(function(data) {
                                var newPVDLink = (
                                    sessionData
                                    && sessionData.appConfig
                                    && sessionData.appConfig.LANDING
                                    && sessionData.appConfig.LANDING.NEW_PVD_HREF
                                    && sessionData.appConfig.LANDING.NEW_PVD_HREF.booleanValue
                                    && sessionData.appConfig.LANDING.NEW_PVD_HREF.overrideValue 
                                ) ? sessionData.appConfig.LANDING.NEW_PVD_HREF.overrideValue + "#/home?" : false;
            
                                $scope.targetUrls.findProvider = newPVDLink ? newPVDLink : providerLinkUrl;
            
                                providerCmSP = "&cm_sp=PRISM-_-Find A Provider-_-" + data.toUpperCase();
                                providerCmSP = providerCmSP.replace(/ /g,'%20');
                                $scope.targetUrls.findProvider = $scope.targetUrls.findProvider + providerCmSP;
                            });
                        });
                    }
                    getPVDUrl();

                    /*
                    ========================================FIND PRESCRIPTION========================================
                    */
                   var currentMedicalProduct = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical");
                   if (currentMedicalProduct.memberList[0].coverageCancellationDate =='' || ((new Date(currentMedicalProduct.memberList[0].coverageCancellationDate) > (new Date())))){
                       var currentActiveRxProductList = finderLogic.portalSelectedPlanActiveProducts("Drug");

                       var thisSharedBrandCode = brand.current.sharedBrandCode.toLowerCase();
                       var arrayStateCodes = ["inac", "ibc", "ibccr", "ibx", "ibcah"];
                       var isIbcRelated = (arrayStateCodes.indexOf( thisSharedBrandCode) > -1) ? true : false;

                       var currentRxProductList = finderLogic.portalSelectedPlanActiveProducts('Drug');
                       if (currentMedicalProduct) {
                           var pharmacyBenefitProvider = finderLogic.getPharmacyBenefitProvider(psp);
                           pharmacyBenefitProvider = (pharmacyBenefitProvider == 'None') ? null : pharmacyBenefitProvider;
                           var arrPharmacyProvider = ['Medco', 'Catalyst', 'RX Claim', 'RX Claim – Commercial', 'Prime', 'CVS/Caremark', 'Navitus', 'Envision', 'Optum', 'ESI'];

                           if (arrPharmacyProvider.indexOf(pharmacyBenefitProvider) > -1) {
                               $scope.showPrescriptionServices = true;
                               var url = null;

                               switch(pharmacyBenefitProvider) {
                                   case 'Medco':
                                       url = '/rbsmbl/x-services/singleSignon/v1/prescriptionServices';
                                       break;
                                   case 'Prime':
                                       url = '/rbsmbl/x-services/singleSignon/v1/prescriptionServices';
                                       break;
                                   case 'CVS/Caremark':
                                       url = 'https://www.caremark.com/wps/portal';
                                       break;
                                   case 'Navitus':
                                       url = 'https://members.navitus.com';
                                       break;
                                   case 'Envision':
                                       url = 'https://www.envisionrx.com/resources/pharmacymap.aspx';
                                       break;
                                   case 'Optum':
                                       url = 'https://www.optumrx.com/RxSolWeb/mvc/home.do';
                                       break;
                                   case 'ESI':
                                       url = 'https://www.express-scripts.com/index.html';
                                       break;
                                   default:
                                       url = "/chmptl/chm/jsp/singleSignOn.do?vendor=catamaran";
                               }

                               $scope.targetUrls.prescriptionService = url;
                           } else {
                               // they do not have a pharmacyBenefitProvider but they still may get the item
                               var hasRxClaims = (currentMedicalProduct.rxClaims == 'true') ? true : false;
                               if (!hasRxClaims) {
                                   var medicalPlanDrugCoverage = (currentMedicalProduct.drugCoverage == 'true') ? true : false;
                                   var integratedCovIndicator = (currentMedicalProduct.integratedCoverageIndicator == 'true') ? true : false;
                                   var hasIntegratedDrugCoverage = (medicalPlanDrugCoverage && integratedCovIndicator ) ? true : false;
                                   var theLob = currentMedicalProduct.lob;
                                   var arrPossibleLobValues = ['HMO', 'PPO', 'EPO', 'HDHP', 'CV', 'POS', 'TDL'];
                                   var hasRequiredLob = (arrPossibleLobValues.indexOf(theLob) > -1) ? true : false;
                                   if (hasRequiredLob && hasIntegratedDrugCoverage) {
                                       // if their brand is NOT IBC, show the item and link it like Medco
                                       if (!isIbcRelated) {
                                           teamsite.get('MBR_MBRHOME_HEALTHTOOLS_RX_LINK_HREF').then(function(data){
                                               $scope.targetUrls.prescriptionService = data;
                                               $scope.showPrescriptionServices = true;
                                           })
                                       } else {
                                           // hide the item
                                           $scope.showPrescriptionServices = false;
                                       }
                                   }else{
                                       $scope.showPrescriptionServices = false;
                                   }
                               }
                           }
                       } //END If currentMedicalProduct

                       // Show prescription service link for Standalone Rx users.
                       if (currentActiveRxProductList && currentActiveRxProductList.length) {
                           var currentRxProduct = currentRxProductList[0];

                           var rxPlanDrugCoverage = (currentRxProduct.drugCoverage == 'true') ? true : false;
                           var hasRequiredLob = (currentRxProduct.lob == 'PDG' || currentRxProduct.lob == 'pdg' ) ? true : false;

                           var pharmacyBenefitProvider = finderLogic.getPharmacyBenefitProvider(psp);
                           pharmacyBenefitProvider = (pharmacyBenefitProvider == 'None') ? null : pharmacyBenefitProvider;
                           var arrPharmacyProvider = ['Medco', 'Catalyst', 'RX Claim', 'RX Claim – Commercial', 'Prime'];

                           if (arrPharmacyProvider.indexOf(pharmacyBenefitProvider) > -1) {
                               $scope.showPrescriptionServices = true;

                               if (pharmacyBenefitProvider == 'Medco') {
                                   $scope.targetUrls.prescriptionService = '/rbsmbl/x-services/singleSignon/v1/prescriptionServices';
                               }
                               else if (pharmacyBenefitProvider == 'Prime')
                               {
                                   $scope.targetUrls.prescriptionService = "/chmptl/chm/jsp/singleSignOn.do?vendor=prime";
                               }
                               else {
                                   $scope.targetUrls.prescriptionService = "/chmptl/chm/jsp/singleSignOn.do?vendor=catamaran";
                               }
                           } else {
                               if(rxPlanDrugCoverage && hasRequiredLob ){
                                   if (!isIbcRelated) {
                                       $scope.targetUrls.prescriptionService = teamsite.get('MBR_MBRHOME_HEALTHTOOLS_RX_LINK_HREF');
                                       $scope.showPrescriptionServices = true;
                                   } else {
                                       // hide the item
                                       $scope.showPrescriptionServices = false;
                                   }
                               } else {
                                   $scope.showPrescriptionServices = false;
                               }
                           }
                       } // END If for standalone Rx users

                       if (!$scope.showPrescriptionServices) {
                           // Rx carve-outs do not have drug products or a value returned for pharmacyBenefitProviderIndicator.
                           // So we must check if the member is part of a client that has a Rx carve-out pharmacy vendor.
                           // If Vendor name = ExpressScripts
                           var rxCarveOutVendor;
                           var callDisplayHide = displayOrHideItemsWrapper.displayOrHideItemsCall(glbFullMemberInfo.clientList[0].clientNumber, sessionData.groupNumber, ["rxCarveOutVendor"], theBrand).then(function(data) {
                               rxCarveOutVendor = data.qualifierInfo.rxCarveOutVendor;
                               $scope.targetUrls.prescriptionService = rxCarveOutVendor;
                               pharmacyBenefitProvider = rxCarveOutVendor;
                           });

                           // this.model.memberQualifiers.then(lang.hitch(this, function (response) {
                           //     if (response && response.data && response.data.payload && response.data.payload.qualifierInfo &&
                           //             response.data.payload.qualifierInfo.rxCarveOutVendor && response.data.payload.qualifierInfo.rxCarveOutVendor.length > 0) {
                           //
                           //         m.showPrescriptionServices = true;
                           //         m.targetUrls.prescriptionService = response.data.payload.qualifierInfo.rxCarveOutVendor;
                           //         m.portalSelectedPlan.pharmacyBenefitProviderIndicator = response.data.payload.qualifierInfo.rxCarveOutVendor;
                           //         m.$apply();
                           //     }
                           // }));
                       }


                       // Adding cm_sp parameter for Coremetrics Site Promotion tags.
                       // Prescription Services
                       //Determine if Catamaran, Medco, or Prime
                       if ($scope.targetUrls.prescriptionService) {
                           var doWeirdURLConfig = false;
                           if (pharmacyBenefitProvider === "Medco") {
                               cmSP = "&cm_sp=Medco-_-Prescription Services Medco-_-";
                               doWeirdURLConfig = true;
                           } else if ($scope.targetUrls.prescriptionService.indexOf("catamaran") > -1) {
                               cmSP = "&cm_sp=Catamaran-_-Prescription Services Catamaran-_-";
                               doWeirdURLConfig = true;
                           } else if ($scope.targetUrls.prescriptionService.indexOf("prime") > -1) {
                               cmSP = "&cm_sp=Prime-_-Prescription Services Prime-_-";
                               doWeirdURLConfig = true;
                           }
                           if (doWeirdURLConfig) {
                               gettsPrescriptionServices();
                           } else {
                               var nonSSOVendors = ['CVS/Caremark', 'Navitus', 'Envision', 'Optum', 'ESI'];
                               if (nonSSOVendors.indexOf($scope.targetUrls.prescriptionService) > -1) {
                                   var nonSSOUrl = "";
                                   switch($scope.targetUrls.prescriptionService) {
                                       case 'CVS/Caremark':
                                           nonSSOUrl = 'https://www.caremark.com/wps/portal';
                                           break;
                                       case 'Navitus':
                                           nonSSOUrl = 'https://members.navitus.com';
                                           break;
                                       case 'Envision':
                                           nonSSOUrl = 'https://www.envisionrx.com/resources/pharmacymap.aspx';
                                           break;
                                       case 'Optum':
                                           nonSSOUrl = 'https://www.optumrx.com/RxSolWeb/mvc/home.do';
                                           break;
                                       case 'ESI':
                                           nonSSOUrl = 'https://www.express-scripts.com/index.html';
                                           break;
                                       default:
                                           break;
                                   }
                                   $scope.targetUrls.prescriptionService = nonSSOUrl;
                               }
                           }
                       }
                    }


                    /*
                    ========================================CARE COST ESTIMATOR========================================
                    */
                    
                    $scope.showCareCostEstimator = cceService.shouldDisplay().$$state.value;

                    var isGeneralMills = sessionMemberInfo.clientList[0].clientNumber;
                    if (isGeneralMills === "179986") {
                        $scope.showCastLightTitle = true;
                        $scope.showCompareCostsTitle = false;
                        $scope.targetUrls.CCE = getCastlightUrl();
                    } else {
                        $scope.showCastLightTitle = false;
                        $scope.showCompareCostsTitle = true;
                        $scope.targetUrls.CCE = finderLogic.getCCEUrl();
                    }

                    /*
                    ========================================MY CHART========================================
                    */
                    var theRegion = brand.current.domain;
                    
                    if (theRegion === 'highmarkbcbs.com') {
                            $scope.showMyChart = true;

                            teamsite.get("MBR_MBRHOME_HEALTHTOOLS_MYCHART_LINK_HREF").then(function (data) {
                                $scope.targetUrls.myChart = data;
                            });
                    }

                    /*
                    ========================================VIRTUAL MEDICINE========================================
                    */
                    var myModal = require("../../modals/dentalModal");
                    $scope.popTeladocModal = function() {
                        // NOTE: in finderLogic.getTeamsiteContent( )  we set properties in $scope for the various modals
                        modal.open({
                            template: require('modals/teladocModal.html'),
                            controller: myModal,
                            scope: $scope
                        });
                    };

                    var teleMedicineVendorName = "DEFAULT";

                    var latestCoverage = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate('Medical');
                    if (latestCoverage.coverageCancelDate =='' || (new Date(latestCoverage.coverageCancelDate) > (new Date()))){
                        $scope.FINDER_HIDE_DEFAULT_HERO = false;
                        //TODO: Switch to using AppConfig to check if we should use the default hero or not...
                        if (theBrand.toLowerCase() === 'mincr') {
                            $scope.FINDER_HIDE_DEFAULT_HERO = true;
                        }

                        if (latestCoverage && latestCoverage.telemedicineVendorName != null && latestCoverage.telemedicineVendorName) {
                            teleMedicineVendorName = latestCoverage.telemedicineVendorName.replace(/\s+/g, '').replace("&", "_");
                        }

                        if (theBrand.toLowerCase() === 'mincr') {
                            teleMedicineVendorName = ((teleMedicineVendorName && teleMedicineVendorName.toUpperCase() === 'YES') ? teleMedicineVendorName = "OnlineCareAnywhere" : "DEFAULT");
                        }

                        var teleMedicineKey = "MBR_TELEMEDICINE_PROVIDER_" + teleMedicineVendorName.toUpperCase();
                        var teleMedicineVendorKey = {
                            "SUPRESS_ONLINECARE_GROUPS" : "SUPRESS_ONLINECARE_GROUPS"
                        };
                        teleMedicineVendorKey[teleMedicineKey] = teleMedicineKey;
                        $scope.telephone;
                        teamsite.get(teleMedicineVendorKey[teleMedicineKey]).then(function(data){
                            $scope.telephone = JSON.parse(data).link;
                        }).then(function(){
                            services.rbsmbl.contentInfo().getContent(teleMedicineVendorKey).then(function(content) {
                                if (content && content.data && content.data.payload && content.data.payload.contentResponse){
                                    if (($scope.brandData.sharedBrandCode.toUpperCase() == "MINCR") && (content.data.payload.contentResponse.length > 0)) {
                                        var arrGrps = content.data.payload.contentResponse[0].value.replace(/\s/g, '').split(",");
                                        hasTeleleMedicine = (arrGrps.indexOf(finderLogic.getPortalSelectedPlan().groupNumber) == -1) ;
                                        $scope.showVirtualMedicine = hasTeleleMedicine ;
                                        $scope.showVirtualMedicine = $scope.showVirtualMedicine && $scope.telephone ? true : false;
                                        $scope.teleMedicineContent = JSON.parse(content.data.payload.contentResponse[1].value);
                                    }
                                    else {
                                        hasTeleleMedicine = true;
                                        if(content.data.payload.contentResponse.length > 1) {
                                            $scope.teleMedicineContent = JSON.parse(content.data.payload.contentResponse[1].value);
                                        }
                                        $scope.showVirtualMedicine = true;
                                        $scope.showVirtualMedicine = ($scope.brandData.sharedBrandCode.toUpperCase() === "WYBCBS") ?  false : true;
                                        $scope.showVirtualMedicine = $scope.showVirtualMedicine && $scope.telephone ? true : false;
                                        if($scope.isAlbertsons){
                                            $scope.teleMedicineContent = JSON.parse(content.data.payload.contentResponse[1].value);
                                            $scope.teleMedicineContent.link = "Teledoc";
                                        }else{
                                            $scope.teleMedicineContent = JSON.parse(content.data.payload.contentResponse[1].value);
                                        }

                                    }
                                }
                            }, function(errObj) {console.log('errObj', errObj)});

                        })
                    }else{
                        $scope.showVirtualMedicine = false;
                    }


                    /*
                    ========================================CORE METRICS========================================
                    */
                    $scope.showFormsLibrary = false;

                    var brandCode = brand.current.sharedBrandCode;

                    var formsLibraryBrands = ['hmbcbs', 'hbs', 'hmbcbswv', 'hmbcbsde', 'pp'];
                    if (formsLibraryBrands.indexOf(brandCode.toLowerCase()) > -1) {
                        $scope.showFormsLibrary = true;
                    }  

                    teamsite.get("MBR_MBRHOME_FORMS_LIBRARY_LINK_HREF").then(function (data) {
                        $scope.targetUrls.formsLibrary = data;
                    })

                    /*
                    ========================================Vitals Smart Shopper========================================
                    */
                    var ssModal = require("../../modals/smartShopperModal");

                    $scope.showVitalsSmartshopper = finderLogic.getPortalSelectedPlan().vitalsSmartShopper;

                    $scope.popSmartShopper = function(){
                        modal.open({
                            template: require('modals/smartShopperModal.html'),
                            controller: ssModal,
                            scope: $scope
                        });
                    };

                    /*
                    ========================================Vitals Smart Shopper========================================
                    */
                    var isASubscriber = (currentMemberInfo.relationship === 'Subscriber') ? true : false;

                    var booShowSA =  (psp && psp.chcIndicator && psp.chcIndicator && psp.chcIndicator == 'true') ? true : false;

                    if(booShowSA) {
                        if (brandCode.toLowerCase() !== 'mincr') {
                            if (!isASubscriber) {
                                booShowSA = false;
                            }
                        }
                    }

                    $scope.showSavingsAdvisor = booShowSA;

                    if(brandCode.toLowerCase()==='mincr'){
                        teamsite.get("MBR_MBRHOME_HEALTHTOOLS_SAVINGSADV_LINK_MINCR").then(function (data) {
                            $scope.savingsAdvisorTitle = data;
                        });
                    }
                    else{
                        teamsite.get("MBR_MBRHOME_HEALTHTOOLS_SAVINGSADV_LINK").then(function (data) {
                            $scope.savingsAdvisorTitle = data;
                        });
                    }

                    /*
                    ========================================PAY PLAN PREMIUM========================================
                    */
                    var medicalPlan = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate('Medical');
                    // console.log("MEDICALPLAN", medicalPlan);
                    var isQualifiedSenior = (medicalPlan && medicalPlan.seniorProductIndicator == 'true') ? true : false;
                    var isDirectPay = (psp && psp.directPayIndicator && psp.directPayIndicator == 'true') ? true : false;

                    $scope.showPayPremiumUnregistered = false;
                    $scope.showPayPremiumRegistered = false;
                    
                    if(brandCode === "mincr")
                    {
                        $scope.showPayPlanPremium = (isQualifiedSenior || isDirectPay && isASubscriber);
                    }
                    else{
                        $scope.showPayPlanPremium = (isQualifiedSenior || isDirectPay && isASubscriber);
                    }

                    if ($scope.showPayPlanPremium) {
                        // call the service only if we are showing the Pay Plan Premium item
        
                        // $scope.targetUrls.payPremiumRegistered = null;
                        // $scope.targetUrls.payPremiumUnregistered = null;

                        teamsite.get("MBR_MBRHOME_HEALTHTOOLS_PAYPREMIUM_REGISTERED").then(function (data) {
                            $scope.payPremiumRegisteredTitle = JSON.parse(data)["defaultText"];
                            $scope.targetUrls.payPremiumRegistered = JSON.parse(data)["href"];
                        });
                        teamsite.get("MBR_MBRHOME_HEALTHTOOLS_PAYPREMIUM_UNREGISTERED").then(function (data) {
                            $scope.payPremiumUnregisteredTitle = JSON.parse(data)["defaultText"];
                            $scope.targetUrls.payPremiumUnregistered = JSON.parse(data)["href"];
                        });
                        // $scope.targetUrls.payPremiumRegistered = (m.tsResponse["MBR_MBRHOME_HEALTHTOOLS_PAYPREMIUM_REGISTERED"])
                        //     ? JSON.parse(m.tsResponse["MBR_MBRHOME_HEALTHTOOLS_PAYPREMIUM_REGISTERED"])
                        //     : {"defaultText": "", "href":"", "target":""};
                        // $scope.targetUrls.payPremiumUnregistered = (m.tsResponse["MBR_MBRHOME_HEALTHTOOLS_PAYPREMIUM_UNREGISTERED"])
                        //     ? JSON.parse(m.tsResponse["MBR_MBRHOME_HEALTHTOOLS_PAYPREMIUM_UNREGISTERED"])
                        //     : {"defaultText": "", "href":"", "target":""};

                        services.rbsmbl.ebillUserExists().getTargetUrl(sessionData.memberInfo.brand).then(
                            function (response) {
                                if (response) {
                                    var unregistered = response &&
                                        response.data &&
                                        response.data.payload &&
                                        response.data.payload.ebillAccountexists === "N";

                                        $scope.showPayPremiumUnregistered = unregistered;
                                        $scope.showPayPremiumRegistered = !unregistered;
                                };
                            }, function(errObj) {console.log('ebillUserExists failed ', errObj);}
                        );
        
                        var payPremiumEnabledBrands = ['hmbcbs', 'pbs', 'ms', 'de', 'hbs', 'hmbcbswv', 'hhic', 'fl', 'lou', 'az', 'neb', 'exc', 'regbcbs', 'regbs', 'wi', 'hz', 'inac', 'hmbcbsde', 'mn', 'pp', '6011', 'la'];
                        var shouldGoToBilling = (payPremiumEnabledBrands.indexOf(sessionData.memberInfo.brand.toLowerCase()) !== -1);
                        if(shouldGoToBilling) {
                            teamsite.get("MBR_MBRHOME_HEALTHTOOLS_PAYPREMIUM_REGISTERED_NEW").then(function (data) {
                                $scope.payPremiumRegisteredTitle = JSON.parse(data)["defaultText"];
                                $scope.targetUrls.payPremiumregistered = JSON.parse(data)["href"];
                            });
                            teamsite.get("MBR_MBRHOME_HEALTHTOOLS_PAYPREMIUM_UNREGISTERED_NEW").then(function (data) {
                                $scope.payPremiumUnregisteredTitle = JSON.parse(data)["defaultText"];
                                $scope.targetUrls.payPremiumUnregistered = JSON.parse(data)["href"];
                            });
                        }
                    }

                } // END CONTROLLER FUNCTION
            ] // END CONTROLLER
        };
    });
    
}