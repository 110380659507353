module.exports = function(app){
    var attachFileAlertModal = require("../../modals/attachFileAlertModal");
    app.controller('pages.message-center.contactUs',['$scope', '$state', 'storage', '$filter', 'coremetrics', '$location', 'messageCenterService', 'modal', 'services','$window','teamsite','session','$timeout','virusScanService','brand',
        function($scope, $state, storage, $filter, coremetrics, $location, messageCenterService, modal, services,$window,teamsite,session,$timeout,virusScanService,brand){

            $scope.headings = "contact us";
            $scope.sidebar.links = [];
            $scope.sidebar.links.push({
                heading: 'CONTACT US',
                label: "Customer Service",
                description: "Fill out this short form to send a message to Customer Service." +
                "<p class='margin-top' >Haven't found what you're looking for?  Find <a target='_blank' href='https://www.bluecrossmn.com/healthy/public/personal/home/hosted/customer-service?_ga=2.115058887.1327905810.1549644739-678751799.1541778459'>other ways to contact us</a>.</p>"

            });
            $scope.phoneNumberMaxLength = 10;
            var brand=brand.current.sharedBrandCode;
            $scope.backAction = function() {
                $window.history.back();
            };
            $scope.attachedFiles=[];
            var _attachedFileCounter = 0;
            $scope.backText = "< Back";
            $scope.viewState="";
            $scope.deleteChar = String.fromCharCode(215);
            $scope.firstMoxieLoad = true;

            $scope.hasFileInfo = false;
            $scope.fileStatusIcon = "";
            $scope.hasFileData = false; //hide example table
            $scope.pluginState = false; // disable button until plugin has initialized
            $scope.fileValid = "disabled";
            $scope.virusFound = false;
            $scope.fileChoice = false;
            $scope.showPicker = true;
            $scope.fileCount = 0;
            $scope.newFilePayload = false;
            $scope.newSubmitState = false;
            $scope.reply=false;
            $scope.selectedTopic="";
            var fileArray = [];

            var ahPartner = 'None',
                medicare = 'false',
                medigap ='false',
                blueExtraBasic='false',
                blueExtraPlus ='false',
                isPPO ='false',
                isHMO  = 'false';

            $scope.contactUsForm = {
                planId:"",
                reason:"",
                comments:"",
                phone:"",
                attachment: "",
                callPreference: "",
                leaveMessage:""
            };

            $scope.hideCallPreference=false;
            var messageTopicContent = "";
            $scope.topics = [];


            var _replyMessageId="";

            //if its reply message
            if ($location.search().reply) {
                $scope.reply=true;
                _replyMessageId = $location.search().reply;
               // $scope.$on('replyMessage',function(event,data){
                    $scope._messageToReplyTo= storage.get("replyMessage");
                    $scope.contactUsForm.reason=$scope.reply?$scope._messageToReplyTo.campaignMessageId:"";
               // });

            }


            //Message Topics
            $scope.sidebar.fetchCategories = function() {
                var categoryPromise = messageCenterService.getCategories();
                return categoryPromise.then(function(category) {

                    var categoriesArray = category.data.payload.subcategory;
                    categoriesArray.forEach(function(subcat){
                        var topic={
                            value:subcat.campaignMessage[0].campaignMessageId,
                            label:subcat.subCategoryDesc
                        };
                        $scope.topics.push(topic);
                    })
                })
            };
            $scope.sidebar.fetchCategories();
            //if dates equal return 0; if compareDate greater than Date return 1; if compareDate less than Date then return -1
            var dateCompare = function(compareDate, endDate) {
                while(compareDate.indexOf("/") != -1) {
                    compareDate = compareDate.replace("/", "-");
                }
                while(endDate.indexOf("/") != -1) {
                    endDate = endDate.replace("/", "-");
                }
                var compDt = new Date(compareDate).getTime();
                var dt = new Date(endDate).getTime();

                if(compDt > dt) return 1;
                if(compDt == dt) return 0;
                if(compDt < dt) return -1;
            };
            var _fetchPlansAndCSPhoneNumber = function () {

                if(session.data.memberInfo){
                    var arrProdNames = [];
                    var memberInfo = session.data.memberInfo;
                    $scope.messagePlans =  [];

                    for (var iClntIdx = 0; iClntIdx < memberInfo.clientList.length; ++iClntIdx) {
                        var objClnt = memberInfo.clientList[iClntIdx];
                        for (var iGrpIdx = 0; iGrpIdx < objClnt.groupList.length; ++iGrpIdx) {
                            var objGrp = objClnt.groupList[iGrpIdx];
                            if(objGrp.planPartnerCode==='373' || objGrp.planPartnerCode==='374')
                                ahPartner='AHNJ';
                            else if(objGrp.planPartnerCode==='375')
                                ahPartner='AHPA';

                            if(objGrp.medicareIndicator  === 'true')
                                medicare =    'true';

                            if(objGrp.medigapIndicator==='true')
                                medigap   =   'true';


                            for (var iPrdIdx = 0; iPrdIdx < objGrp.productList.length; ++iPrdIdx) {
                                var objPrd = objGrp.productList[iPrdIdx];

                                if(objPrd.isBlueExtraBasic==='true')
                                    blueExtraBasic =  'true';
                                if(objPrd.isBlueExtraPlus==='true')
                                    blueExtraPlus =   'true';
                                if(objPrd.lob  === 'PPO')
                                    isPPO =   'true';
                                if(objPrd.lob  === 'HMO')
                                    isHMO =   'true';

                                //check benefitPlanType to determine icon used
                                var benefitPlanType=objPrd.benefitPlanType;
                               /* if (objPrd.benefitPlanType == "Medical"){
                                    benefitPlanIcon = "<span class='hm-icon icon-provider'></span>";
                                }
                                if (objPrd.benefitPlanType == "Vision"){
                                    benefitPlanIcon = "<span class='hm-icon icon-vision'></span>";
                                }
                                if (objPrd.benefitPlanType == "Dental"){
                                    benefitPlanIcon = "<span class='hm-icon icon-dental'></span>";
                                }
                                if (objPrd.benefitPlanType == "Wellness Programs"){
                                    benefitPlanIcon = "<span class='hm-icon icon-nutrition'></span>";
                                }
                                if (objPrd.benefitPlanType == "Spending Accounts"){
                                    benefitPlanIcon = "<span class='hm-icon icon-cost'></span>";
                                }
                                if (objPrd.benefitPlanType == "Drug"){
                                    benefitPlanIcon = "<span class='hm-icon icon-pharmacy'></span>";
                                }*/

                                //get proper plan name for display
                                var planNameDisplay = "";
                                if(objGrp.hiosName != "" && objGrp.hiosName != null) {
                                    planNameDisplay = objGrp.hiosName;
                                    if(objGrp.qhpLevel != null && objGrp.qhpLevel != "")
                                        planNameDisplay = planNameDisplay + " " + objGrp.qhpLevel;
                                }
                                else {
                                    planNameDisplay = objPrd.benefitPlanName;
                                }

                                for (var iMbrIdx = 0; iMbrIdx < objPrd.memberList.length; ++iMbrIdx) {
                                    var objMbr = objPrd.memberList[iMbrIdx];
                                    var planStatus='';
                                    var fromDate='';
                                    var toDate='';
                                    var memberIndx =iMbrIdx;
                                    if(objPrd.coverageBeginDate != null && objPrd.coverageBeginDate != "" && (objMbr.coverageCancellationDate == null || objMbr.coverageCancellationDate == "")) {
                                        var strPrdName =  benefitPlanType+planNameDisplay  + objPrd.coverageBeginDate + '-' + objPrd.coverageEndDate ;
                                        fromDate = objPrd.coverageBeginDate;
                                        toDate = objPrd.coverageEndDate;
                                    } else if (objPrd.coverageBeginDate != null && objPrd.coverageBeginDate != "" && objMbr.coverageCancellationDate != null && objMbr.coverageCancellationDate != ""
                                        && (dateCompare(objMbr.coverageCancellationDate, objPrd.coverageEndDate) < 1)) {
                                       var strPrdName =  benefitPlanType+planNameDisplay + '(Cancelled)(' + objPrd.coverageBeginDate + '-' + objMbr.coverageCancellationDate + ')';
                                        planStatus = "Cancelled";
                                        fromDate = objPrd.coverageBeginDate;
                                        toDate = objMbr.coverageCancellationDate;
                                    }else if ((objPrd.coverageBeginDate == null || objPrd.coverageBeginDate == "") && (objMbr.coverageCancellationDate != null && objMbr.coverageCancellationDate != "")
                                        && (dateCompare(objMbr.coverageCancellationDate, objPrd.coverageEndDate) < 1)) {
                                        var strPrdName = benefitPlanType+planNameDisplay + '(Cancelled)(' + objMbr.coverageCancellationDate + ')';

                                        planStatus = "Cancelled";
                                        fromDate =  objMbr.coverageCancellationDate;

                                    } else {
                                        var strPrdName =  benefitPlanType+planNameDisplay + '(' + objPrd.coverageEndDate + ')';
                                        fromDate =  objPrd.coverageEndDate;
                                    }
                                }

                                if (arrProdNames.indexOf(strPrdName)  == -1) {


                                    //If Blue Extra and Medical, then do not show the plan. Else follow BAU
                                    if(!((objPrd.isBlueExtraBasic ===  'true'  ||  objPrd.isBlueExtraPlus  === 'true')
                                        && objPrd.benefitPlanType   === 'Medical')){

                                        // if there is more than one plan, we need the user to make a selection so that the progressive display can proceed.  So, we need to remove the default selection, which happens below.
                                        $scope.messagePlans.push({
                                            "id": objPrd.lob+"_"+objGrp.groupNumber+"_"+objPrd.productNumber + '_' + iPrdIdx,
                                            "label": strPrdName,
                                            "value": objPrd.lob + objGrp.groupNumber + objPrd.productNumber + '_' + iPrdIdx,
                                            "checked": ($scope.messagePlans.length < 0),
                                            "benefitPlanType":benefitPlanType,
                                            "planNameDisplay":planNameDisplay,
                                            "planStatus":planStatus,
                                            "fromDate":fromDate,
                                            "toDate":toDate,
                                            "selectedClientIndex":iClntIdx,
                                            "selectedGroup":iGrpIdx,
                                            "selectedPlan":iPrdIdx,
                                            "selectedMember":memberIndx

                                        });
                                    }

                                    arrProdNames.push(strPrdName);
                                }
                            }
                        }
                    }
                    if($scope.messagePlans.length>0){
                        $scope.viewState="success";
                    }
                    if(arrProdNames.length == 1){
//                        $scope.messagePlans[0].checked = true;
                        $scope.contactUsForm.planId= $scope.messagePlans[0].id;
                    }

                    services.rbsmbl.AccountSettingsRetrive().retriveAccountSettings('','',brand,'').then(function(response){
                        $scope.homePhone =  response.payload.homePhone;
                    });

                    services.rbsmbl.csPhoneNumber().fetch(session.data.memberInfo).then(function (response) {
                        if (response && response.data && response.data.payload) {

                            var CSPhoneNumber = response.data.payload.CSPhoneNumber;

                            if (CSPhoneNumber && CSPhoneNumber.replace(/\s/g, '').length > 0) {
                                var last_four, middle_three, area_code, international, len;
                                len = CSPhoneNumber.length;
                                last_four = CSPhoneNumber.substring(len - 4, len);
                                middle_three = CSPhoneNumber.substring(len - 7, len - 4);
                                area_code = CSPhoneNumber.substring(len - 10, len - 7);
                                international = (len == 11) ? CSPhoneNumber.substring(len - 11, len - 10) + '-' : '';
                                CSPhoneNumber = international + area_code + '-' + middle_three + '-' + last_four;
                                $scope.CSPhoneNumber = CSPhoneNumber;

                            } else {
                                $scope.CSGetNumberFromCard = "the number on the back of your member Id card.";
                            }
                        }
                    });

                    postRender($scope.topics,$scope.messagePlans,$scope.CSPhoneNumber,ahPartner, medicare, medigap, blueExtraBasic, blueExtraPlus, isPPO, isHMO);
                }


            };

            // Filter out service Plans
            var filterOutServicePlans = function (plans) {
                var filteredPlans = [];
                for (var i = 0; i < plans.length; i++){

                    //If contains service splice it out
                    if(plans[i].label.indexOf('Service -') == -1)
                    {
                        filteredPlans.push(plans[i])
                    }

                }

                // If filtered plans have more than 0 show that list
                if (filteredPlans.length>0) {
                    return filteredPlans;
                }
                //else show original list
                else {
                    return plans;
                }
            }

            var postRender=function(topics,messagePlans,csPhone,ahPartner, medicare, medigap, blueExtraBasic, blueExtraPlus, isPPO, isHMO) {
                $scope.messagePlans = filterOutServicePlans(messagePlans);
                //For Minnesota, on the reply page, do not show time preference.
                if (brand === 'mincr' && $scope.reply) {
                    $scope.hideCallPreference = true;
                }

                if ($scope.reply) {
                    $scope.sidebar.links[0].heading = '<span data-teamsite="MSG_REPLY_SEC_TTL"></span>';
                    $scope.sidebar.links[0].label = '<span data-teamsite="MSG_REPLY_LFT_NAV_TTL"></span>';
                    $scope.sidebar.links[0].description = '<span data-teamsite="MSG_REPLY_LFT_NAV_DSC"></span>';

                } else {
                    if (brand == "hmbcbs" || brand == "de" || brand == "pbs") {
                        if (csPhone && csPhone.replace(/\s/g, '').trim().length <= 0) {
                            $scope.sidebar.links[0].description = "Haven't found what you're looking for? Find <a target=\"_blank\" href=\"/chmptl/chm/jsp/contactus.do?oid=-13447&submitbutton=custserv\">other ways to contact Highmark</a>. For technical questions, please call 1-877-298-3918.";
                        } else {
                            $scope.sidebar.links[0].description = "Haven't found what you're looking for? Call " + csPhone + ". Or, for technical questions, please call 1-877-298-3918"
                        }

                    }
                }
            }

            _fetchPlansAndCSPhoneNumber();

            $scope.formatPhone = function(){
                var phoneNumber = $scope.contactUsForm.phone;

                $scope.contactUsForm.phone =  $filter('formatPhone')(phoneNumber);
            };
            var getPayload =function(){
                var memberInfo = session.data.memberInfo;
                var selectedMessagePlan ;
                var messageTopic;
                if($scope.messagePlans){
                    $scope.messagePlans.forEach(function(messagePlan){
                        if(messagePlan.id==$scope.contactUsForm.planId){
                            selectedMessagePlan = messagePlan;
                        }
                    });
                }
                if($scope.topics && $scope.contactUsForm.reason){
                    $scope.topics.forEach(function(topic){
                        if(topic.value==$scope.contactUsForm.reason){
                            messageTopic = topic.label;
                        }
                    })

                }
               if(selectedMessagePlan){
                   var _messageToReplyTo =$scope._messageToReplyTo;
                   var selectedClientIndex = selectedMessagePlan.selectedClientIndex;
                   var selectedGroup = selectedMessagePlan.selectedGroup;
                   var selectedPlan = selectedMessagePlan.selectedPlan;
                   var selectedMember =selectedMessagePlan.selectedMember;
                   var coverageStartDate = memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].coverageBeginDate;
                   if(coverageStartDate == null || coverageStartDate == ""){
                       coverageStartDate = memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].coverageConBeginDate;
                   }
                   var payload= {
                       "agreementId": memberInfo.agreementId,
                           "conversationID": (_messageToReplyTo ? _messageToReplyTo.conversationID : ""),
                           "ecId": (_messageToReplyTo ? _messageToReplyTo.ecid : memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].ecId),
                           "umi": memberInfo.umi,
                           "coveredIndividualId": (_messageToReplyTo ? _messageToReplyTo.ciId : memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].coveredIndividualId),
                           "clientNumber": (_messageToReplyTo ? _messageToReplyTo.clientNumber : memberInfo.clientList[selectedClientIndex].clientNumber),
                           "clientName": (_messageToReplyTo ? _messageToReplyTo.clientName : memberInfo.clientList[selectedClientIndex].clientName),
                           "groupNumber": (_messageToReplyTo ? _messageToReplyTo.groupNumber : memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].groupNumber),
                           "productNumber": (_messageToReplyTo ? _messageToReplyTo.productNumber : memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].productNumber),
                           "benefitPlanType": (_messageToReplyTo ? _messageToReplyTo.benefitPlanType : memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].benefitPlanType),
                           "benefitPlanName": (_messageToReplyTo ? _messageToReplyTo.benefitPlanName : memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].benefitPlanName),
                           "bdUserId" : 'B'+ memberInfo.bvUserId,
                           "clientId" : (_messageToReplyTo ? memberInfo.clientList[selectedClientIndex].clientId : memberInfo.clientList[selectedClientIndex].clientId),
                           "internalGroupNumber" : (_messageToReplyTo ? memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].internalGroupNumber : memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].internalGroupNumber),
                           "firstName": (_messageToReplyTo ? _messageToReplyTo.memberFirstName : memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].contact.firstName),
                           "lastName": (_messageToReplyTo ? _messageToReplyTo.memberLastName : memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].contact.lastName),
                           "middleName": (_messageToReplyTo ? _messageToReplyTo.memberMiddleName : memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].contact.middleName),
                           "suffix": (_messageToReplyTo ? _messageToReplyTo.memberSuffix : memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].contact.suffix),
                           "birthDate": (_messageToReplyTo ? dateStringToMMDDYYYY(_messageToReplyTo.memberBirthDate) : memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].contact.birthDate),
                           "relationshipCode": (_messageToReplyTo ? _messageToReplyTo.campaignMessageRelationshipCode : memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].relationshipCode),
                           "messageID": (_messageToReplyTo ? _messageToReplyTo.campaignMessageId : $scope.contactUsForm.reason),
                           "direction": "inbound",
                           "messageSource": "Member Portal",
                           "subject": (_messageToReplyTo ? _messageToReplyTo.subject : messageTopic),
                           "phoneNumber": $scope.contactUsForm.phone.replace('(', '').replace(')', '').replace(' ', '').replace('+', '').replace('-', ''),
                           "callTimePreference": brand==='mincr' ? "" : $scope.contactUsForm.callPreference,//(window.mcqFlag || brand==='mincr'
                           "leaveMessageInd": $scope.contactUsForm.leaveMessage,
                           "emailAddress": "",
                           "notificationMethod": "",
                           "notificationAddress": "",
                           "createIdentifier": "mbr",
                           "dcn": "",
                           "languageCode": "",
                           "channelCode": "IS",
                           "gender": memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].gender,
                           "enrollmentSourceCode": memberInfo.clientList[selectedClientIndex].enrollmentSourceCode,
                           "brand": brand,
                           "lob": memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].lob,
                           "alphaPrefix": memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].alphaPrefix,
                           //"coverageBeginDate": memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].coverageBeginDate,
                           "coverageBeginDate": coverageStartDate,
                           "coverageEndDate": "",
                           "externalSystemIdentifier": (_messageToReplyTo ? _messageToReplyTo.externalSystemId : ""),
                           "messages": [
                           {
                               "message": $scope.contactUsForm.comments
                           }
                       ],
                           "attachments": $scope.contactUsForm.attachment
                   }
                   console.log("payload"+payload);
                   services.rbsmbl.addMessage().save(payload);
               }

            };

            var addMessage =function(){
                getPayload();
            };

            $scope.submitMessageForm = function(){
                console.log($scope.contactUsForm);
                addMessage();
            };




            // function to format bytes, will move to a filter
            var formatBytes = function(bytes, decimals) {
                if (bytes === 0) {
                    return '0 bytes'
                }
                var k = 1000,
                    dm = decimals + 1 || 3,
                    sizes = [' bytes', ' kb', ' mb'],
                    i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
            };


            var filterTopicOptions = function() {

                var topicOptionsNew = $scope.topics.slice();
                var memberInfo = session.data.memberInfo;
                var selectedPlanId = $scope.contactUsForm.planId;

                if(selectedPlanId) {
                    // console.log("mbrmb:",data);
                    var mcqApplies = false;
                    var mcqInArray = false;
                    var today = new Date();
                    var re = /^(\d{4})-(\d{2})-(\d{2})$/;
                    var re2 = /^(\d{2})\/(\d{2})\/(\d{4})$/;
                    var start = {};
                    var end = {};
                    var selectedProduct = {};
                    var selectedPlanObjId = '';
                    var selectedPlanObjArrIndex;
                    var topicIndex;

                    var valueArr = selectedPlanId.split("_");
                    selectedPlanObjId = valueArr[0]+valueArr[1]+valueArr[2];
                    selectedPlanObjArrIndex = valueArr[3];
                    prodIndex = selectedPlanObjArrIndex;


                    // this block is designed to remove the message topic to contact a nurse if the user does not have a current medical plan. Since the memberInfo svc returns two possible date formats, there are two cases for handling the date

                    // Retrieve the selected plan object
                    for (var iClntIdx = 0; iClntIdx < memberInfo.clientList.length; ++iClntIdx) {
                        var objClnt = memberInfo.clientList[iClntIdx];
                        for (var iGrpIdx = 0; iGrpIdx < objClnt.groupList.length; ++iGrpIdx) {
                            var objGrp = objClnt.groupList[iGrpIdx];
                            //console.log("objGrp:",objGrp);
                            for (var iPrdIdx = 0; iPrdIdx < objGrp.productList.length; ++iPrdIdx) {
                                var objPrd = objGrp.productList[iPrdIdx];
                                if (selectedPlanObjId && selectedPlanObjId === objPrd.lob + objGrp.groupNumber + objPrd.productNumber && parseInt(selectedPlanObjArrIndex) === iPrdIdx) {
                                    selectedProduct = objPrd;
                                    var isHCR = objPrd.hcrProduct;
                                    var effectuatedDate = objPrd.effectuatedDate;
                                    var isDirPay = objGrp.directPayIndicator;

                                    //console.log("iPrdIdx:",iPrdIdx);
                                    break;
                                }
                            }
                        }
                    }

                    // determine if mcq topic is in the array
                    if (topicOptionsNew) {
                        for (var x = 0; x < topicOptionsNew.length; x++) {
                            if (topicOptionsNew[x].label.toUpperCase() === "DISCUSS HEALTH WITH A NURSE") {
                                mcqInArray = true;
                                topicIndex = x;
                                break;
                            }
                        }
                    }
                    // case for handling first date format
                    // both cases create date objects that are ready for >/< comparisons
                    if (selectedProduct.coverageBeginDate && selectedProduct.coverageBeginDate.match(re)) {
                        var startDateArr = selectedProduct.coverageBeginDate.split('-');
                        var endDateArr = selectedProduct.coverageEndDate.split('-');
                        var start = new Date(startDateArr[0], startDateArr[1], startDateArr[2]);
                        var end = new Date(endDateArr[0], endDateArr[1], endDateArr[2]);
                    }

                    //case for handling second date
                    else if (selectedProduct.coverageBeginDate && selectedProduct.coverageBeginDate.match(re2)) {
                        //y,m,d
                        var startDateArr = selectedProduct.coverageBeginDate.split('/');
                        var endDateArr = selectedProduct.coverageEndDate.split('/');
                        var start = new Date(startDateArr[2], startDateArr[0] - 1, startDateArr[1]);
                        var end = new Date(endDateArr[2], endDateArr[0] - 1, endDateArr[1]);
                    }

                    var sharedBrandCode = brand;
                    for (var i = 0; i < topicOptionsNew.length; i++) {
                        if (topicOptionsNew[i].label.toLowerCase() === 'discuss health with a nurse'){
                            if (!(sharedBrandCode.toLowerCase() === 'hmbcbs' || sharedBrandCode.toLowerCase() === 'hbs' ||
                                sharedBrandCode.toLowerCase() === 'hmbcbsde' || sharedBrandCode.toLowerCase() === 'mbh' ||
                                sharedBrandCode.toLowerCase() === 'az' || sharedBrandCode.toLowerCase() === 'pbs' ||
                                sharedBrandCode.toLowerCase() === 'hz' || sharedBrandCode.toLowerCase() === 'fl' ||
                                sharedBrandCode.toLowerCase() === 'ms' || sharedBrandCode.toLowerCase() === 'ibc' ||
                                sharedBrandCode.toLowerCase() === 'exc' || sharedBrandCode.toLowerCase() === 'lou' ||
                                sharedBrandCode.toLowerCase() === 'ne' || sharedBrandCode.toLowerCase() === 'regbcbs' ||
                                sharedBrandCode.toLowerCase() === 'regbs' || sharedBrandCode.toLowerCase() === 'wi' ||
                                sharedBrandCode.toLowerCase() === 'none' || sharedBrandCode.toLowerCase() === 'pp' ||
                                sharedBrandCode.toLowerCase() === 'mn' ||
                                sharedBrandCode.toLowerCase() === 'hhic' || sharedBrandCode.toLowerCase() === 'hmbcbswv' ||
                                sharedBrandCode.toLowerCase() === 'ibccr' || sharedBrandCode.toLowerCase() === 'ibcah' || 
                                sharedBrandCode.toLowerCase() === 'mincr' || sharedBrandCode.toLowerCase() === 'ndbcbs' || 
                                sharedBrandCode.toLowerCase() === 'wybcbs' || sharedBrandCode.toLowerCase() === 'la')) {

                                topicOptionsNew.splice(i,1);
                            }

                        }

                    }

                    // if you find a current medical plan during the iteration, set the member clinical question flag to true

                    if (selectedProduct.benefitPlanType && selectedProduct.benefitPlanType.toUpperCase() === 'MEDICAL'
                        && selectedProduct.coverageCancelDate === ""
                        && (start < today && today < end)
                        && !(isHCR && isDirPay && effectuatedDate === "")) {

                        mcqApplies = true;
                    }
                    else {
                        mcqApplies = false;
                    }

                    if (!mcqApplies && mcqInArray) {
                        var node = topicOptionsNew.splice(topicIndex, 1)[0];
                        $scope.mcqTopicNode = {
                            "index" : topicIndex,
                            "node" : node
                        }

                    }
                    if (!mcqInArray && mcqApplies) {
                        var node = $scope.mcqTopicNode;
                        topicOptionsNew.splice(node.index, 0, node.node);
                    }
                };


                if ((ahPartner === 'AHNJ' && medicare === 'true')
                    || (ahPartner === 'AHNJ' && medigap === 'true')
                    || (blueExtraBasic == 'true' || blueExtraPlus == 'true')
                    || (brand === 'ibccr' && medicare === 'true' && isHMO === 'true')
                    || (brand === 'ibccr' && medicare === 'true' && isPPO === 'true')) {
                    for (var index = 0; index < topicOptionsNew.length; index++) {
                        if (topicOptionsNew[index].label === 'Claim Inquiry' || topicOptionsNew[index].label === 'Spending Accounts') {
                            topicOptionsNew.splice(index,1);
                        }
                    }
                }



                return topicOptionsNew;
            }


            $scope.changePlan=function(){
                if($scope.contactUsForm.reason){
                    $scope.planChangeWarning =true;
                }
                $scope.contactUsForm.reason="";
                $scope.topics = filterTopicOptions();
            };
            var clearContactUsForm = function(){
                $scope.contactUsForm = {
                    planId:"",
                    reason:"",
                    comments:"",
                    phone:"",
                    attachment: "",
                    callPreference: "",
                    leaveMessage:""
                };
            }


            $scope.changeTopic =function(){
                if($scope.contactUsForm.reason && $scope.contactUsForm.reason==='MGC-CHN-HMBCBS'){
                    //show attachments invalidate warning message if nurse line topic is selected
                    if($scope.attachedFiles.length>0){
                        $scope.attachmentWarningCancelButton=function(){
                            $scope.contactUsForm.reason=$scope.selectedTopic;
                            modal.close();
                        }
                        $scope.alertType="nurseLineSelected";
                        modal.open({
                            template: require('modals/attachFileAlertModal.html'),
                            controller: attachFileAlertModal,
                            scope: $scope
                        });
                    }

                }else
                if($scope.contactUsForm.reason){
                    $scope.selectedTopic = $scope.contactUsForm.reason;
                    var addFile = function () {





                        // designate a DOM element as the file picker button
                        var fileInput = new mOxie.FileInput({
                            browse_button: 'contactUs-attachFile',container: 'plugin-container'
                        });

                        fileInput.onmousedown = function (e) {
                         $scope.hasFileData = false; // hide example table
                         $scope.hasFileInfo = false; // hide file info
                         //$scope.pluginState = false; // disable button while processing
                         $scope.fileChoice = false;
                         $scope.showPicker = true;
                         $scope.virusFound = false;
                         coremetrics.elementTag('UPLOAD FILE: CONTACT US', 'MEMBER:MESSAGE CENTER');
                         };
                        fileInput.onmousedown = function(e) {
                            $scope.hasFileData = false; // hide example table
                            $scope.hasFileInfo = false; // hide file info
                            //$scope.pluginState = false; // disable button while processing
                            $scope.fileChoice = false;
                            $scope.showPicker = true;
                            $scope.virusFound = false;
                            coremetrics.elementTag('UPLOAD FILE: SPENDING', 'MEMBER:SPENDING');
                        };

                        // fires when user closes file dialog after a file is selected
                         fileInput.onchange = function (e) {

                         $scope.fileChoice = true;
                         // array of selected files
                         fileArray = e.target.files;
                         $scope.fileCount++;

                         for (var i = 0, theFile; theFile = fileArray[i]; i++) {

                         // check mime type and handle error
                         var fileFilter = "application/pdf,text/plain,image/*,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                         if (!(theFile.type.match(fileFilter.replace(/,/g, "|")))) {
                             console.log("type: ", theFile.type);
                             $scope.$apply(function () {
                                 $scope.pluginState = true;
                                 $scope.fileValid = false;
                                 fileInput.disable(false);
                                 $scope.showPicker = true;
                                 $scope.fileErrorText = "Invalid file type. File must be either TIFF, PDF, or JPEG.";
                            });
                         }
                         // check file size
                         else if (theFile.size > (Math.pow(1024, 2) * 5)) {
                             console.log("size: ", theFile.size);
                             $scope.$apply(function () {
                                 $scope.pluginState = true;
                                 $scope.fileValid = false;
                                 fileInput.disable(false);
                                 $scope.showPicker = true;
                                 $scope.fileErrorText = "\"File is too big.\"";
                            });
                         }
                         // handle the file that passes the type and size tests
                         else {

                         //fileInput.disable(true);

                         $scope.fileValid = true;
                         $scope.fileName = theFile.name;
                         $scope.fileMIME = theFile.type;
                         $scope.fileSize = formatBytes(theFile.size);
                         $scope.rawFileSize = theFile.size;

                         // preload state
                         $scope.$apply(function () {
                             $scope.fileStatusIcon = "loader";
                             $scope.hasFileInfo = true;
                             //$scope.showPicker = false;
                             //smoothFocus.clearTab();

                         });

                         var reader = new mOxie.FileReader();
                         reader.onloadstart = (function (theFile) {

                             return function (e) {
                                 // Increment file counter
                                 _attachedFileCounter++;
                             }
                         })(theFile);
                          reader.onload = (function (theFile) {
                         return function (e) {
                         $scope.fileBinary = e.target.result;

                             var binaryOnly = $scope.fileBinary.split(',')[1];

                             // virus scan
                             services.mhsimb.openAttachment().addFile(binaryOnly,$scope.fileName).then(
                                 function (response) {

                                     var jsonData = xml2json.xml2json(response.data);
                                     console.log("result scan", response.data);
                                    $scope.attachedFiles.push(
                                         {
                                             "attachmentId": _attachedFileCounter,
                                             "attachmentPath": jsonData.attachmentResponseMessage.payload.controlData.DocumentToken,
                                             "fileName": $scope.fileName,
                                             "fileBinary":$scope.fileBinary
                                         }
                                     );

                                     // virus is there
                                     if (parsedResult && parsedResult.baseMessage && parsedResult.baseMessage.returnCodeDescription === "Virus detected") {
                                        /* //$scope.$apply(function () {
                                         $scope.fileBinary = "";
                                         $scope.pluginState = true;
                                         $scope.virusFound = true;
                                         $scope.fileErrorText = "\"Virus detected.  Please try another file\"";
                                         fileInput.disable(false);
                                         $scope.showPicker = true;

                                         $scope.hasFileInfo = false;
                                         fileInput.refresh();*/
                                         //});
                                     }
                                     // no virus
                                     else {
                                         /*$scope.pluginState = false;

                                         fileInput.disable(true);
                                         $scope.showPicker = false;*/
                                         //fileInput.refresh();


                                         // new file receipt required payload

                                         $scope.hasFileData = true;
                                         $scope.fileStatusIcon = "view-eye";


                                         $scope.newFilePayload = true;
                                         $scope.newSubmitState = true;

                                     }
                                 },
                                 function (err) {
                                     console.error('fetch failed in getData', err);
                                    // fileInput.refresh();
                                 }
                             );


                         };
                         })(theFile);

                         console.log("rr", theFile);
                         reader.readAsDataURL(theFile);


                          $scope.showFile = function (attachmentId) {

                            /* if ($scope.hasFileData) {
                             coremetrics.elementTag('VIEW RECEIPT: SPENDING', 'MEMBER:SPENDING');
                             window.URL = window.URL || window.webkitURL;
                             if (window.URL) {
                             window.open($scope.fileBinary);
                             }
                             }*/

                              window.URL = window.URL || window.webkitURL;
                              var uri = null;
                              var link = document.createElement("a");
                              if (window.URL) {
                                  window.open(e.target.result);
                                  uri = window.URL.createObjectURL(base64toBlob.base64toBlob(e.target.result.split(',')[1], theFile.type));
                              } else {
                                  var index = -1;
                                  for (var i = 0; i < _attachedFiles.length; i++) {
                                      if (theFile.name === _attachedFiles[i].fileName) {
                                          index = i;
                                          break;
                                      }
                                  }
                                  uri = appServicesConfig.getServices().downloadAttachment + "?documentToken=" + _attachedFiles[index].attachmentPath + "&mimeType=" + theFile.type + "&fileName=" + theFile.name;
                                  link.target = "_blank";
                              }
                              link.href = uri;
                              link.download = theFile.name;
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);

                         };


                         }

                         }

                         };

                        // initialize plugin
                        fileInput.init();

                        // when the plugin has been initialized, remove disabled class from the button
                        fileInput.onready = function () {
                            $scope.$apply(function () {
                                $scope.pluginState = true;
                            });
                        };


                    }
                   // $timeout(function(){
                    if($scope.firstMoxieLoad){
                        addFile();
                        $scope.firstMoxieLoad=false;
                    }

                    //},1000);
                }
            }






        }]
    );
}