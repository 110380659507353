module.exports = function(app) {

    require('./message-center/messageCenter')(app);
    require('./message-center/contactUs')(app);
    require('./message-center/msgReadMore')(app);

    app.controller('pages.messageCenter', ['$scope', '$state', 'messageCenterService', 'storage', '$filter', 'coremetrics', 'updateVisited', '$location', '$cookies', 'services', function ($scope, $state, messageCenterService, storage, $filter, coremetrics, updateVisited, $location, $cookies, services) {

    }]
    );

    return [
        {
            state: "message",
            postPassword: true,
            appConfigMaintenanceApp: 'MESSAGE_CENTER',
            appConfigMaintenanceKey: 'FULL_MAINTENANCE',
            url: "/msg",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: "pages.messageCenter"
                },
                'content@message': {
                    template: require('pages/message-center/messageCenter.html'),
                    controller: "pages.message-center.messageCenter"
                }

            }
        },
         {
             state: "message.contactUs",
             postPassword: true,
             appConfigMaintenanceApp: 'MESSAGE_CENTER',
             appConfigMaintenanceKey: 'CONTACT_US_MAINTENANCE',
             url: "/contact-us",
             views: {
                 'body@': {
                     template: bootstrap.templates['page-templates/categoryDetail.html'],
                     controller: "pages.message-center.contactUs"
                 },
                 'content@message.contactUs': {
                     template: require('pages/message-center/contactUs.html'),
                     controller: "pages.message-center.contactUs"
                 },
                 'leftColumn@message.contactUs': {

                 }

             }
         },
        {
            state: "message.readMore",
            postPassword: true,
            appConfigMaintenanceApp: 'MESSAGE_CENTER',
            url: "/read-more",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/detailCustom.html'],
                    controller: "pages.message-center.messageCenter"
                },
                'content@message.readMore': {
                    template: require('pages/message-center/msgReadMore.html'),
                    controller: "pages.message-center.messageCenter"
                },
                'leftColumn@message.readMore': {

                }

            }
        }

    ];
}
