module.exports = function(app) {
    app
        .controller('pages.account.other-insurance.OIStep2', ['$scope','coremetrics', function($scope, coremetrics) {

            $scope.fixSidebar();


            $scope.otherInsuranceChevron.step = 2;
            $scope.userType = 'guest';
           // $scope.openDrawer = "";



        $scope.handleKeyPressMemberClicked = function(user) {
                if (mouseClick(this.$evt) || keyPressEnter(this.$evt)) {
                    $scope.memberClicked(user);
                }
            };
        $scope.memberClicked = function (user) {
                user.drawerOpen = (user.drawerOpen === "open") ? "" : "open";
            };

        $scope.relationshipList = [
                // {label: 'Select', value: ''},
                {label: 'Self', value: '1'},
                {label: 'Spouse', value: '2'},
                {label: 'Child', value: '3'},
                {label: 'Handicapped Dependent', value: '4'},
                {label: 'Other/Domestic Partner', value: '5'}
        ];

        $scope.employmentList = [
                // {label: 'Select', value: ''},
                {label: 'Active', value: 'A'},
                {label: 'Retired', value: 'R'},
                {label: 'COBRA', value: 'C'}
        ];
        $scope.custodyList = [
            // {label: 'Select', value: ''},
            {label: 'None', value: 'N'},
            {label: 'Mother', value: 'M'},
            {label: 'Father', value: 'F'},
            {label: 'Joint', value: 'J'}
        ];
        $scope.primaryParentList = [
                // {label: 'Select', value: ''},
                {label: 'Mother', value: 'M'},
                {label: 'Father', value: 'F'},
                {label: 'Joint', value: 'J'}
        ];
        $scope.courtOrderList = [
                // {label: 'Select', value: ''},
                {label: 'No', value: '0'},
                {label: 'Yes', value: '1'}
        ];
        $scope.CarrierMaxLen = 35;
        $scope.groupNumLen = 30;
        $scope.policyNumLen = 17;
        $scope.fnLen = 25;
        $scope.lnLen =35;

        $scope.theSaveButton = false;

        // opens the drawers if the any of the form inputs are invalid
            $scope.submitStep2 = function () {
                if (!$scope.theSaveButton && $scope.Step2Form.$valid) {
                    $scope.nextButtonClick(2);
                } else if ($scope.theSaveButton && $scope.Step2Form.$valid) {
                    $scope.saveToServer(2, false, true);
                } else {
                    for (var i = 0; i < $scope.additionalUsers.length; i++) {
                        $scope.additionalUsers[i].drawerOpen = "open";
                        window.scrollTo(0,0);

                    }
                }
                $scope.theSaveButton = false;
            };

            $scope.cmMemberClickedValue = false;
                $scope.cmMemberClicked = function(step){
                if($scope.cmMemberClickedValue != true){
                    switch(step){
                        case 2:
                            coremetrics.elementTag('VIEW ANOTHER MEMBER OTHER INSURANCE DETAILS','MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                            break;
                        case 3:
                            coremetrics.elementTag('VIEW ANOTHER MEMBER MEDICARE COVERAGE OTHER INSURANCE','MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                            break;
                    }
                    $scope.cmMemberClickedValue = true;
                }
            };

            // $scope.additionalUsers.forEach(function(element){
            //     if(element.fieldSets2.custody.value != '' && element.fieldSets2.custody.value != 'N'){
            //         element.custodySet = true;
            //     }
            // })


}])
        .directive('fourthspace',function()
                       {
                          //directive to validate the whitespace in Policy holder's last name input
                          // allow white space only upto 3 index; invalidate if the input has white space after 3rd index
                           return{
                               require: 'ngModel',
                               link: function(scope,elm,attrs,ctrl)
                           {
                               ctrl.$validators.fourthspace = function(modelValue, viewValue){

                           if (ctrl.$isEmpty(modelValue)){
                               //consider empty value valid
                               return true;
                           }       //trim leading and trailing white spaces
                                   var strtrim = viewValue.trim();
                                    //split input into individual character
                                   var strfinal  =  strtrim.split('');
                                   //find last index of white-space
                                   var strResult =   strfinal.lastIndexOf(' ');
                           if (strResult > 3){
                               //it is invalid
                               return false;
                           }
                            //it is valid
                               return true;}
                           }
                           };
                       })

        .directive('nullInvalid',function()
        {
            return{
                require: 'ngModel',
                link: function(scope,elm,attrs,ctrl)
                {
                    ctrl.$validators.nullInvalid = function(modelValue, viewValue){

                        if (ctrl.$isEmpty(modelValue) || viewValue.value ==''){
                            //consider empty value invalid
                            return false;
                        }
                        //it is valid
                        return true;}
                }
            };
        })
        .directive('iedates', function () {
            return {
                require: 'ngModel',
                link: function (scope, elm, attrs, ctrl) {
                    ctrl.$validators.iedates = function (modelValue, viewValue) {
                        console.log("MODEL VALUE: ", modelValue);
                        console.log("VIEW VALUE: ", viewValue);
                        if (viewValue) {
                            if (!viewValue instanceof Date) {
                                return false
                            } else if (!viewValue.match(/([12]\d{3})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])/)) {
                                return false
                            } else {
                            return true;
                            }
                        } else {
                            return true;
                        }
                    }
                }
            };
        })





};