module.exports = function(app) {
    app
        .controller('pages.account.other-insurance.OIStep3', ['$scope', 'coremetrics', 'services', 'sessionData', function ($scope, coremetrics, services, sessionData) {

            $scope.fixSidebar();
            
            $scope.otherInsuranceChevron.step = 3;

            $scope.handleKeyPressMemberClicked = function(user) {
                if (mouseClick(this.$evt) || keyPressEnter(this.$evt)) {
                    $scope.memberClicked(user);
                }
            };
            $scope.memberClicked = function (user) {
                user.drawerOpen = (user.drawerOpen === "open") ? "" : "open";
            };

            $scope.submitStep3 = function (isSaveButton) {
                if (!isSaveButton && $scope.Step3Form.$valid) {
                    $scope.nextButtonClick(3);
                } else if (isSaveButton && $scope.Step3Form.$valid) {
                    $scope.saveToServer(3, false, true);

                }else {
                    for (var i = 0; i < $scope.medicareUsers.length; i++) {
                        $scope.medicareUsers[i].drawerOpen = "open";

                    }
                }
            };





        }])
        .directive('validCombo',['services','brand',function(services,brand)
        {
            return{
                require:'ngModel',
                scope:
                {
                    validCombo: "=mypcn"
                },
                link: function(scope,elm,attrs,ctrl){
                    var pcnNum = scope.$parent.$parent.medicareForm.pcn.$modelValue;

                    {
                        ctrl.$validators.validCombo = function(modelValue, viewValue){
                            var isValidCombo;
                            if(viewValue && viewValue.length==6) {
                                var binNum = viewValue;
                                var validationObj = {
                                    'bin': binNum,
                                    'pcn': pcnNum
                                };

                                //services.rbsmbl.validateBinPcn().binpcnValidate(validationObj, theBrand).then(function(data){
                                //    console.log("validate bin pcn",data);
                                //     isValidCombo = data.data.payload.validPcnBin;
                                //    return isValidCombo;});
                                return false;
                            }
                            else{
                                return true;
                            }
                        }
                    }
                }
            }
        }])
        .directive('nullValidator',function()
                   {
                       return{
                           require: 'ngModel',
                           link: function(scope,elm,attrs,ctrl)
                           {
                               ctrl.$validators.nullValidator = function(modelValue, viewValue){

                                   if (ctrl.$isEmpty(modelValue)){
                                       //consider empty value valid
                                       return false;
                                   }
                               else{
                                       return true;
                                   }}
                           }
                       };
                   })

        .directive('medClaimNumValidate', function()
                       {
                           return{
                               require: 'ngModel',
                               //scope: {
                               //    criteria: '=medClaimNumValidator'
                               //},
                               link: function(scope,elm,attrs,ctrl)
                               {

                                   ctrl.$validators.noWhiteSpace = function(modelValue, viewValue){

                                       if (viewValue && viewValue!== undefined){
                                       var strtrim = viewValue.trim();
                                       //split input into individual character
                                       var strfinal  =  strtrim.split('');
                                       var strResult =   strfinal.lastIndexOf(' ');

                                           if( strResult > 0){
                                               console.log("white space");
                                               return  false;
                                           }
                                           else{
                                               return true;
                                           }
                                       
                                   }
                                        return true;
                                   };

                                   ctrl.$validators.numOnly = function (modelValue, viewValue) {
                                       if (viewValue && viewValue !== undefined) {
                                           if (/[1-9]/.test(viewValue)) {

                                               return true;
                                           }
                                           else{
                                               return false;
                                           }

                                   }
                                       return true;
                                   };

                                   ctrl.$validators.lettersOnly = function (modelValue, viewValue) {
                                       if (viewValue && viewValue !== undefined) {
                                           if (/[a-zA-Z]/.test(viewValue)) {

                                               return true;
                                           }
                                           else{
                                               return false;
                                           }

                                   }
                                       return true
                                   };

                                   ctrl.$validators.claimPattern = function (modelValue, viewValue) {
                                       if (viewValue && viewValue !== undefined) {
                                           if (viewValue.length == 9) {
                                               if (!/^(?=[a-zA-Z]{0,3}[0-9]{4,9}$)/.test(viewValue)) {
                                                   return false;
                                               }
                                               else {
                                                   return true;
                                               }
                                           }
                                           return true;
                                       }
                                       return true;

                                   };

                               }
                           }
                       })
};