module.exports = ['$scope', "$window", '$http', function($scope, $window, $http) {
    $scope.redirectHome = function(){
        location.replace("/home/");
    };
    
    $scope.goToSite=function(){
       
        $window.open("http://www.whlchoices.com/#/register?ReferURL=https://highmarkbcbs.com/home/");
        $scope.closeModal();
            
    };
}];