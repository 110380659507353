module.exports = function(app) {
    app.directive('coverageSpending', function() {
        return {
            restrict: 'A',
            template: require('directives/coverage-summary/spendingCoverage.html'),
            controller: ['$scope', 'sessionData', 'finder-logic2', function($scope, sessionData, finderLogic) {

                var ums = sessionData.memberInfo;

                var showOtherPlansLink = function () {
                    var medicalMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical");
                    var drugMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Drug");
                    var dentalMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Dental");
                    var visionMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Vision");

                    if ((medicalMod !== null) || (dentalMod !== null) || (drugMod !== null) || (visionMod !== null)) {
                        return false;
                    } else {
                        return true;
                    }
                };

                var curClnt = ums.clientList[0];
                var curGrp = finderLogic.getPortalSelectedPlan(ums);
                var curPrd = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Spending Accounts");

                $scope.spendingPlanName = [];
                $scope.bMultiGroup = (showOtherPlansLink()) && (curClnt.groupList.length > 1);

                for (var i = 0; i < curGrp.productList.length; i++) {
                    if (curGrp.productList[i].benefitPlanType === 'Spending Accounts') {
                        var curPlan = curGrp.productList[i].benefitPlanName;

                        if (!$scope.spendingPlanName.includes(curPlan)) {
                            $scope.spendingPlanName.push(curPlan);
                        }
                    }
                }

            }]
        };
    });
    };
