module.exports = function (app) {
	app.controller('pages.account.member-profile', ['$scope', '$filter', '$timeout', 'sessionData', 'services', 'sessionData', 'Service', 'finder-logic2', 'session', 'coremetrics', 'memberProfileService', 'brand', '$state',
		function ($scope, $filter, $timeout, sessionData, services, sessionData, Service, finderLogic, session, coremetrics, memberProfileService, brand, $state) {

			
			$scope.isEditable = false;
			$scope.isDependent = false;
			$scope.cancelResult = false;
			$scope.shouldShowDOB = false;
			$scope.checkboxShow = false;
			$scope.formInputs = {};
			$scope.memberProfileDrawer = false;

			var initialDemoInfo;

			var theProductList = finderLogic.portalSelectedPlanActiveProducts();


			var thisBrand = brand.current.sharedBrandCode.toLowerCase();

			if(thisBrand == 'hmbcbs' || 'hbs' || 'hmbcbsde'){
				$scope.hcrLink = '/chmptl/chm/jsp/categorydetail.do?oid=-21889&type=channel&parentId=-13548&programId=439888';
			} else if (thisBrand == 'ibccr' || 'ibcah'){
				$scope.hcrLink = '/chmptl/chm/jsp/categorydetail.do?oid=-21884&type=channel&parentId=-13548&programId=439888';
			} else {
				$scope.hcrLink = '/chmptl/chm/jsp/categorydetail.do?oid=-21888&type=channel&parentId=-13548&programId=439888';
			}

			$scope.hcrLinkCoremetrics = function(){
				coremetrics.elementTag('Make Changes to your coverage','MEMBER:ACCOUNT SETTINGS');
			}; 

			
            
				//grab all the inputs from the service file
				memberProfileService.getRaces1().then(function (races) {
					$scope.races1 = races;
				}, function (error) {
					$scope.races1 = [];
				});

				memberProfileService.getRaces2().then(function (races) {
					$scope.races2 = races;
				}, function (error) {
					$scope.races2 = [];
				});

				memberProfileService.getEthnicities().then(function (ethnicities) {
					$scope.ethnicBg = ethnicities;
				}, function (error) {
					$scope.ethnicBg = [];
				});

				memberProfileService.getPrimLanguage().then(function (languages) {
					$scope.primLanguage =  languages;
					$scope.preferredLang = languages;
				}, function (error) {
					$scope.primLanguage = [];
					$scope.preferredLang = [];
				});

				memberProfileService.getComfortLang().then(function (comfortLang) {
					$scope.comfortLang = comfortLang;
				}, function (error) {
					$scope.primLanguage = [];
				});

				memberProfileService.getEnglishComfort().then(function (englishComfort) {
					$scope.englishComfort = englishComfort;
				}, function (error) {
					$scope.primLanguage = [];
				});

				memberProfileService.getEduCompleted().then(function (eduCompleted) {
					$scope.eduCompleted = eduCompleted;
				}, function (error) {
					$scope.eduComplete = [];
				});


				//save the selections
				$scope.save = function () {
					var memberListCopy = angular.copy($scope.memberList);
					memberProfileService.saveDemographicInfo(memberListCopy);
					$scope.formSubmit = true;
					$scope.memberProfileForm.$setPristine();

				};
				$scope.cancel = function(memberList) {
					for (i=0 ; i<memberList.length ; i++){
					$scope.memberList[i].raceInfo.raceList = {value:''};
					$scope.memberList[i].ethnicity.ethnicity= {value:''};
					$scope.memberList[i].language.langSpokenAtHome = {value:''};
					$scope.memberList[i].language.langComfortableSpeaking = {value:''};
					$scope.memberList[i].language.englishLangComfortablity = {value:''};
					$scope.memberList[i].language.preferredWrittenLang = {value:''};
					$scope.memberList[i].education.educationCompleted = {value:''};
					$scope.formInputs.copyToAllUsers =false;
					}
					$scope.memberProfileForm.$setPristine();
					$scope.memberList = initialDemoInfo;
					$scope.formSubmit = false;
				};

				//if checkbox is check, store same value for all dependents on list
				$scope.dependentSame = function(copyToAllUsers) {
					if (copyToAllUsers == true) {
						for (var i = 0; i < $scope.memberList.length; i++) {
							$scope.memberList[i].language.englishLangComfortablity = $scope.memberList[0].language.englishLangComfortablity;
							$scope.memberList[i].language.langComfortableSpeaking = $scope.memberList[0].language.langComfortableSpeaking;
							$scope.memberList[i].language.langSpokenAtHome = $scope.memberList[0].language.langSpokenAtHome;
							$scope.memberList[i].language.preferredWrittenLang = $scope.memberList[0].language.preferredWrittenLang;
							$scope.memberList[i].ethnicity.ethnicity = $scope.memberList[0].ethnicity.ethnicity;
							$scope.memberList[i].raceInfo.raceList  = $scope.memberList[i].raceInfo.raceList ? $scope.memberList[i].raceInfo.raceList : [];

							var race1 = $scope.memberList[0].raceInfo.raceList[0] ? $scope.memberList[0].raceInfo.raceList[0] : {};
							var race2 = $scope.memberList[0].raceInfo.raceList[1] ? $scope.memberList[0].raceInfo.raceList[1] : {};

							$scope.memberList[i].raceInfo.raceList[0] = $scope.memberList[i].raceInfo.raceList[0] ? $scope.memberList[0].raceInfo.raceList[0] : race1;
							$scope.memberList[i].raceInfo.raceList[1] = $scope.memberList[i].raceInfo.raceList[1] ? $scope.memberList[0].raceInfo.raceList[1] : race2;		
														
						}
						
					}
				};


				$scope.setCopyToFalse = function() {
					$scope.formInputs.copyToAllUsers = null;
				};

				//create member array
				memberProfileService.getDemographicInfo().then(function (response) {
					
					var demographics = response.data.payload.demographicInfo.demographicList;
					if(demographics && demographics.length){
						$scope.memberList = demographics;
						initialDemoInfo = demographics;
					}
					//only show checkbox if memberList is > 1 and only show for first member in list
					if($scope.memberList.length > 1){
						$scope.checkboxShow = true;
					}
					for(var k = 0; k < $scope.memberList.length; k++){
						if (theProductList) {
							for (var i = 0; i < theProductList.length; i++) {
								var isHCR = theProductList[i].hcrProduct;
								if (isHCR == 'ON') {
									console.log("member-profile hcr product",theProductList[i].hcrProduct);
									$scope.memberList[k].isEditable = false;
								}
								else{$scope.memberList[k].isEditable = true;}
							}
						}
						if(k==0){
							$scope.memberList[0].isOpen = true;

					}else{$scope.memberList[k].isOpen = false;}}



				


					$scope.shouldShowDOB = {};

					var duplicateNames = {};
					//check if member has same name and display birthday if so
					for (var i = 0; i < $scope.memberList.length; i++) {
						var member = $scope.memberList[i];

							
						for (var j = i+1; j<$scope.memberList.length; j++) {
							var otherMember = $scope.memberList[j];
							if (!$scope.shouldShowDOB[j]
								&& member.member.firstName.toUpperCase() === otherMember.member.firstName.toUpperCase()
								&& member.member.lastName.toUpperCase() === otherMember.member.lastName.toUpperCase()) {
								$scope.shouldShowDOB[j] = true;
								$scope.shouldShowDOB[i] = true;
							} 
						}
					}
			}, function (error) {
					console.log('error');
			});

	}]);
	app.directive('raceObject', function($filter) {
		return {
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel) {
				ngModel.$parsers.push(function(val) {
					return {
						race: val && val.race ? val.race : val,
						effectiveDate: $filter('date')(new Date(), 'MM/dd/yyyy')
					};
				});
				ngModel.$formatters.push(function(val) {
					return  val && val.race ? val.race : val;
				});
			}
		};
	});
}