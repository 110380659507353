module.exports = function(bootstrap) {
    bootstrap.run(['Service', 'appId', function(Service, appId) {
        new Service(
            {
                name: 'eobDocByClaim',
                group: 'rbsmbl',
                url: '/rbsmbl/x-services/claimsData/v1/eobDocByClaim',

                fetch: function(claimNumber) {
                    var
                        claimNumberAsString = String(claimNumber),
                        requestPayload;

                    requestPayload = {
                        "reqEobDocumentByClaimRequest": {
                            "baseMessage": {
                                "exchangeType": "SENDREQUEST",
                                "headerVersion": "1.0",
                                "username": appId
                            },
                            "payload": {
                                "reqEobDocumentByClaim": {
                                    "claimNumber": claimNumberAsString
                                }
                            }
                        }
                    };

                    return this.post(requestPayload);
                }
            }
        );
    }]);
};