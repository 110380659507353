module.exports = function(app) {
    app.directive('programsUi', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/landing/programsUi.html'),
            scope: true,
            controller: function($scope, $element, $attrs, sessionData, teamsite, session, wellnessProfile, invisibleFormService, displayOrHideItemsWrapper, wellnessLogic) {
                $scope.showMemberDiscounts = false;
                $scope.showWellnessProfile = false;
                $scope.showRewardsProgram = false;
                $scope.showValueBased = false;
                $scope.showSomething = false;
                $scope.showCustomerServiceMessaging = false;
                var lowercaseBrand = sessionData.memberInfo.brand.toLowerCase();
                var isInCoreBrands = function () {
                    var arrBrands = ['hmbcbs', 'hbcbs', 'highmarkbcbs', 'hbs', 'pbs', 'hmbs', 'hmbcbswv', 'ms', 'hmbcbsde', 'highmarkbcbsde', 'de',
                        'highmarkbcbswv', 'mbh:wv', 'hhic', 'az', 'fl', 'mbh:fl', 'exc', 'lou', 'neb', 'regbcbs', 'regbs', 'wi', 'mn', 'la'];
                    var isInThere = (arrBrands.indexOf(lowercaseBrand) > -1) ? true : false;
                    return isInThere;
                }
                // VALUE BASED BENEFITS =============================================================================
                // MBR_MBRHOME_PROGRAMS_VALBASED_LINK
                // https://tenv7.highmarkbcbs.com/chmptl/chm/jsp/portal/member/dynamicPageTemplate.do?pageTemplate=ValueBasedDashboard&TabToGo=4
                teamsite.get('MBR_MBRHOME_PROGRAMS_VALBASED_LINK').then(function(vbbTarget) {
                    vbbTarget = $scope.funcs.urlBuilder(vbbTarget);
                    $scope.targetUrls.vbb = vbbTarget;
                });
                var arrayVbsStateCodes = ['hmbcbs', 'hbcbs', 'highmarkbcbs', 'hbs', 'pbs', 'hmbs',"hmbcbswv", "ms", "highmarkbcbswv", "mbh:wv","hmbcbsde", "highmarkbcbsde", "de","pp","hhic","az","fl", "mbh:fl","neb","inac", "ibc", 'mincr'];
                var memberIsInVbbBrand = (arrayVbsStateCodes.indexOf(lowercaseBrand)>-1) ? true : false;
                var hasActiveMedicalPlan = sessionData.benefits.medicalActive;
                // see if the vbb object is on one of the active products
                // I assume that if they have the valueBasedBenefits on the tree and the valueBasedBenefitsSignedUp property is a boolean
                // then they are in a group offering VBB
                var hasVbbObject = false;
                var aps = $scope.activeProducts;
                // sometimes activeProducts is null. It kinda shouldn't happen but it does sometimes
                if (aps) {
                    for (var i = 0; i < aps.length; i++) {
                        if (aps[i].valueBasedBenefits && typeof aps[i].valueBasedBenefits.valueBasedBenefitsSignedUp == 'boolean') {
                            hasVbbObject = true;
                        }
                    }
                }
                $scope.showValueBased = (memberIsInVbbBrand && hasActiveMedicalPlan && hasVbbObject) ? true : false;

                // REWARDS ==========================================================================================
                //         we run this after the planInfo service has returned in a watch below =====================
                var doRewardsStuff = function (currentMember) {
                    var cov = currentMember.coverageMap[0];
                    var displayOrHideItemsResponse = displayOrHideItemsWrapper
                        .displayOrHideItemsCall(cov.clientNumber, cov.groupNumber, ['webMDRewardsIndicator'], lowercaseBrand);
                    displayOrHideItemsResponse.then(function (servicePayload) {
                        // var tt = servicePayload;
                        if (servicePayload && servicePayload.qualifierInfo && servicePayload.qualifierInfo.webMDRewardsIndicator
                            && servicePayload.qualifierInfo.webMDRewardsIndicator == 'true') {
                            $scope.showRewardsProgram = true;
                            // it is defaulted to false, so we don't have to set that here
                        }  });  }

                // WELLNESS PROFILE =================================================================================
                // member is in required region
                var arrWellnessProfileBrands = ['hmbcbs', 'fl', 'ibc', 'hbcbs', 'highmarkbcbs', 'hmbcbswv', 'ms', 'highmarkbcbswv', 'mbh:wv',
                    'hmbcbsde', 'mn', 'highmarkbcbsde', 'de', 'hhic', 'hbs', 'pbs', 'hmbs', 'pp', 'neb', 'az', 'mincr', 'la'];
                var isInWellnessProfileRegion = (arrWellnessProfileBrands.indexOf(lowercaseBrand) > -1);
                // hide it if they are NOT the current member ?  I don't think that (them not being a current member) is possible.
                // if (!this.model.currentMember) {
                //     wellnessProfileItemDisplay = false;
                //     return;
                // }
                // 1.) they are not Blue Extra Basic
                //     sessionData.isBlueExtraBasic
                // 2.) Active current coverage OR Future effective
                var bs = sessionData.benefits;
                var coverageIsActive = ( bs.dentalActive || bs.drugActive || bs.medicalActive || bs.visionActive || bs.wellnessActive )
                $scope.showWellnessProfile = isInWellnessProfileRegion && coverageIsActive;
                var productIsActive = function(theProduct) {
                    var millisNow = Date.parse(session.startTime);
                    // coverageBeginDate  is products begin date
                    // coverageConBeginDate  is members effective date
                    // coverageBegin date has to parse to a date and has to be > Now
                    // AND THEN coverageEndDate either has to be an empty string or a date greater than Now
                    var millisBeginDate = Date.parse(theProduct.coverageBeginDate);
                    var millisEndDate = Date.parse(theProduct.coverageEndDate);
                    // Number.isNaN(Date.parse(theProduct.coverageEndDate) will be true when the end date is ''
                    if (
                        ((millisBeginDate < millisNow) && Number.isNaN(Date.parse(theProduct.coverageEndDate)))
                        || ((millisBeginDate < millisNow) && (millisNow < millisEndDate))
                        ) {
                        return true;
                    } else { return false; }
                }
                // the next method gets the ecId and ciid for the member (we need them in the sso request)
                var parseSessionDataForMemberContact = function() {
                    // the ciid and ecid that we need are deep in sessionData
                    // planInfo gives use the selected clientNumber and groupNumber to get us started
                    var cn = $scope.plans.plans.selected.clientNumber;
                    var gn = $scope.plans.plans.selected.groupNumber;
                    var sdClients = sessionData.memberInfo.clientList;
                    for (var a = 0; a < sdClients.length; a++) {
                        if (sdClients[a].clientNumber == cn) {
                            // this is the client for the selected plan
                            var sdGroups = sdClients[a].groupList;
                            for (var b = 0; b < sdGroups.length; b++) {
                                if (sdGroups[b].groupNumber == gn) {
                                    // this is the group from the selected plan
                                    var sdProducts = sdGroups[b].productList;
                                    for (var c = 0; c < sdProducts.length; c++) {
                                        if (productIsActive(sdProducts[c])) {
                                            // look for the member we want in this product
                                            var sdMembers = sdProducts[c].memberList;
                                            for (var d = 0; d < sdMembers.length; d++) {
                                                var thisMember = sdMembers[d].contact;
                                                var pi = $scope.planInfoLoggedInMemberContact;
                                                if (thisMember.birthDate == pi.birthDate
                                                    && thisMember.firstName == pi.firstName
                                                    && thisMember.lastName == pi.lastName ) {
                                                    return sdMembers[d];
                                                }  }  }  }  }  }  }  }
                };
                $scope.planInfoLoggedInMemberContact = {};
                $scope.sessionDataMemberContact = {};
                $scope.mySessionUrl = $scope.funcs.urlBuilder('');
                teamsite.get('MBR_MBRHOME_PROGRAMS_WELLPROFILE_HREF').then(function (theTsStartId) {
                    $scope.tsStartId = theTsStartId;
                });
                // this is hit when the planInfo service response hits landing.js
                $scope.$watch('plans', function() {
                    if ($scope.plans && $scope.plans.plans) {
                       // WELLNESS PROFILE STUFF ===================================================================
                        var m;
                        if ($scope.plans.members.loggedIn) {
                            m = $scope.plans.members.loggedIn;
                            $scope.planInfoLoggedInMemberContact.birthDate = m.dateOfBirth;
                            $scope.planInfoLoggedInMemberContact.firstName = m.firstName;
                            $scope.planInfoLoggedInMemberContact.lastName = m.lastName;
                        }
                        // NOTE: (pds) This gets ugly because we need some obscure IDs to send to the service
                        //             when the link is clicked. This UI code could be simplified if the service was simplified
                        // The next method gets the ecId and ciid for the member (we need them when the link is clicked)
                        var thisMember = parseSessionDataForMemberContact();
                        $scope.sessionDataMemberContact = thisMember;

                        if ($scope.plans.plans.selected.benefits.isMedical || $scope.plans.plans.selected.benefits.isWellness)
                        $scope.coverageBeginDate = $scope.plans.plans.selected.memberEffectiveDate;
                        // set relationship code
                        var relType = $scope.plans.members.loggedIn.type;  //"Subscriber"

                        if ($scope.sessionDataMemberContact) {
                            $scope.clickWellnessProfileData = {
                                "startid": '',  //NOTE: this value comes from teamsite. Search for tsStartId in this file and in html
                                "ecid": $scope.sessionDataMemberContact.ecId, // "82425169",
                                "email": " ",
                                "address1": " ",
                                "address2": " ",
                                "birthdate": " ",
                                "ciid": $scope.sessionDataMemberContact.coveredIndividualId, //"016608306",
                                "city": " ",
                                //                     Medical coverageBeginDate :"05/01/2015"
                                "coverageStartDate": $scope.coverageBeginDate,  // Medical.coverageBeginDate OR Wellness Programs.coverageBeginDate if no Medical product
                                "firstName": $scope.plans.members.loggedIn.firstName, //"AMY",
                                "lastName": $scope.plans.members.loggedIn.lastName, // "SHORTALL",
                                "memberProfileID": "",
                                "gender": " ",
                                "ssn": " ",
                                "state": " ",
                                "clientName": $scope.plans.plans.selected.clientName,
                                "relationShipCode": $scope.sessionDataMemberContact.relationshipCode, // "20",
                                "umi": sessionData.memberInfo.umi, // "110038749001",
                                "zipCode": " ",
                                "sessionReturnName": "Member_Home",
                                "sessionReturnURL": $scope.mySessionUrl, // "https://tenv6.highmarkbcbs.com",
                                "username": sessionData.basicInfo.loginId, // "AMYS24",
                                "isDemoUser": " ",
                                "clientNumber": $scope.plans.plans.selected.clientNumber, // "099918",
                                "groupNumber": $scope.plans.plans.selected.groupNumber, // "08149300",
                                "vendorName": sessionData.memberInfo.brand, // "HMBCS",
                                "internalGroupId": "000404544",
                                "userId": sessionData.basicInfo.loginId,  // "JIMCOV",
                                "subscriberid": "178647623"
                            }
                        } else {
                            $scope.clickWellnessProfileData = {};
                            throw new Error("The planInfo stub is for JIMCOV. We cant make the WellnessProfileData object for other members when stubs is on.")
                        }

                        // initiate REWARDS STUFF now that planInfo service returned ===================================
                        if (m) {
                            doRewardsStuff(m);
                        } else {
                            throw new Error('The planInfo service did not give us a logged in member object in programsUi.js');
                        }
                    }
                });
                $scope.funcs.clickWellnessProfile = function(postData, startId) {
                    postData.startid = startId;
                    var theService = wellnessProfile.ssoStuff(postData);
                    theService.then(function(theInfo) {
                        if (theInfo) {
                            // the code required here is too complex to try to use an existing handleSSO method
                            // so we are coding it here for now
                            var rdUrl = theInfo.data.payload.redirectionURL;
                            var baseUrl = 'https://' + window.location.host;
                            baseUrl = baseUrl.replace('denv', 'tenv');
                            if (!rdUrl || !rdUrl.length || rdUrl.length == 0) {
                                throw new Error('ERROR: the WellnessProfileSso service response does not have a redirectionUrl property.');
                                // articleModel.loadingWellnessProfile = false;
                                return;
                            }
                            if ( rdUrl.indexOf('webMDCSRErrorPage') > -1 || rdUrl.indexOf('webMDSingleSignOn') > -1 ) {
                                // someone has CSRd in OR it is a sales demo user
                                // articleModel.loadingWellnessProfile = false;
                                window.location.href = baseUrl + rdUrl;
                            } else {
                                var tt = theInfo.data.payload.URLparms;
                                var formOptions = {
                                    'name': 'aFormName',
                                    'method': 'POST',
                                    'action': '/',
                                    'target': '_self',
                                    'inputs': {}
                                }
                                formOptions.action = rdUrl;
                                var urlParams = theInfo.data.payload.URLparms;
                                // NOTE: URLparams from the response is the following for JIMCOV
                                //       "env=tenv7.highmarkbcbs.com%26TLID=12365"
                                //        I am not sure if the "source" property should be added with a %26 or an &
                                urlParams = urlParams + "%26source=" + baseUrl;
                                formOptions.inputs.URLparams = urlParams;
                                formOptions.inputs.cookieData = theInfo.data.payload.cookieData;
                                // NOTE: in denv I get the following error. I think this may go away in tenv and/or production
                                //        SAML ERROR MESSAGE There is a problem in Core ID cookie creation. Either UserName or Passord is invalid
                                invisibleFormService.submit(formOptions);
                            }
                        } else {
                            throw new Error('The wellnessProfile service did not return the expected object.')
                        } });
                }
                // GET WELLNESS SCORE ================================================================================
                $scope.showWellnessScore = false;
                var theScore = wellnessLogic.getWellnessScore();
                theScore.then(function(theResults) {
                    if (theResults && theResults.data && theResults.data.payload && theResults.data.payload.wellnessProfileScore) {
                        $scope.wellnessScore = theResults.data.payload.wellnessProfileScore;
                        $scope.showWellnessScore = true;
                    } })

                // MEMBER DISCOUNTS =================================================================================
                $scope.showMemberDiscounts = isInCoreBrands();
                $scope.targetUrls.memberDiscounts;
                teamsite.get('MBR_MBRHOME_PROGRAMS_DISCOUNTS_HREF').then(function(theUrl) {
                    // var tt =  $scope.funcs.urlBuilder(theUrl);
                    $scope.targetUrls.memberDiscounts = $scope.funcs.urlBuilder(theUrl);
                })
            }
        };
    });
}