module.exports = function(app) {
    app.directive('coverageUi', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/landing/coverageUi.html'),
            scope: true,
            controller: ['$scope', '$element', '$attrs', 'session', 'finder-logic2', 'services', '$filter',
                function($scope, $element, $attrs, sessionData, finderLogic, services, $filter) {

					$scope.memberInfo = sessionData.data.memberInfo;

					var myCurrency = function(theNumber, shouldBold) {
						var formatted = $filter('currency')(theNumber, '$', 0);
						if (shouldBold) {
							formatted = '<b>' + formatted + '</b>';
						}
						return formatted;  }
					var doNotDisplayCoverageSection = function(accums, co_s) {
						// we must have accums or co_s AND we must have one of the two tierCodes and it cant be ONA
						if (
							((accums == null) && (co_s == null)) || (
							(!accums.tierCode || accums.tierCode == 'ONA'|| accums.tierCode == '') &&
							(!co_s.tierCode || co_s.tierCode == 'ONA' || co_s.tierCode == '')
							)) {
							return true;
						}
						return false;
					}
					var displayCoverageInfoSection = function() {
				// this is called after the getCoverageSectionInfo ws method has returned
						var accums = ($scope.coverageInfo.accumulations) ? $scope.coverageInfo.accumulations : null;
						var co_s = ($scope.coverageInfo.costShareBenefits) ? $scope.coverageInfo.costShareBenefits : null;
						if (doNotDisplayCoverageSection(accums, co_s)) {
							return;
						}
						var m = $scope;
						$scope.showCoverage = false; // initialize
						$scope.arrCoverageDetails = {};
						// NOTE (pds): we don't know what tierCode to use to control this header text in the case
						//             where both the accums and the co_s have a non-ONA tierCode so I am assuming:
						var controllingTierCode = 'default';
						if (accums.tierCode && accums.tierCode != 'ONA') {
							controllingTierCode = accums.tierCode;
						} else {
							controllingTierCode = co_s.tierCode; // it can't be ONA here
						}
						m.controllingTierCode = controllingTierCode;

						if (accums.tierCode && accums.tierCode != 'ONA') {
							// if we have the properties and values we need, populate the Deduct and OOPmax part of the array and show Coverage
							if ((accums != null) && (
								( accums.deductible && accums.deductible.length > 1 ) ||
								( accums.outOfPocketMaximum && accums.outOfPocketMaximum.length > 1)
								)) {
								m.showCoverage = true;

								// MAKE CORRECT VALUES FOR Deduct and OOPmax =====================================
								// var deductible_label, oop_label;
								$scope.showFamilyLabel = false;
								$scope.showIndividualLabel = false;
								$scope.showFamilyOop = false;
								$scope.showIndividualOop = false;
								$scope.showFamilyBreakpoint = false;
								$scope.showIndividualBreakpoint = false;
								switch (accums.familyOrIndividual) {
									// refactor this so that is is switching BOOLEANS in HTML to show or hide
									case 'F':
										$scope.showFamilyLabel = true;
										$scope.showFamilyOop = true;
										// deductible_label = this.useThis(ts.MBR_MBRHOME_COVERAGE_FAMDEDUCTIBLE, 'Family Deductible');
	//                                    deductible_label = 'Family Deductible';
	//                                    // oop_label = this.useThis(ts.MBR_MBRHOME_COVERAGE_FAMOOPMAX, 'Family Out of Pocket Max');
	//                                    oop_label = 'Family Total Maximum<br />Out-of-Pocket';
										break;
									case 'I':
										$scope.showIndividualLabel = true;
										$scope.showIndividualOop = true;
										// deductible_label = this.useThis(ts.MBR_MBRHOME_COVERAGE_DEDUCTIBLE, 'Deductible');
	//                                    deductible_label = 'Deductible';
	//                                    // oop_label = this.useThis(ts.MBR_MBRHOME_COVERAGE_OOPMAX, 'Out-of-Pocket Max');
	//                                    oop_label = 'Total Maximum Out-of-Pocket';
										break;
									default:
	//                                    $scope.showIndividualLabel = true;
	//                                    deductible_label = 'Deductible';
	//                                    oop_label = 'Out-of-Pocket Max'
										break;
								}
								if (accums.deductible) {
									accums.deductible = myCurrency(accums.deductible);
									// $scope.arrCoverageDetails.theDesc1 = deductible_label;
									$scope.arrCoverageDetails.theValue1 = accums.deductible;
								} else {
									$scope.showFamilyLabel = false;
									$scope.showIndividualLabel = false;
								}
								if (accums.outOfPocketMaximum) {
									accums.outOfPocketMaximum = myCurrency(accums.outOfPocketMaximum);
									// $scope.arrCoverageDetails.theDesc4 = oop_label;
									$scope.arrCoverageDetails.theValue4 = accums.outOfPocketMaximum;
								}



								// Breakpoint can have both Individual and Family at the same time so account for these separate.
								if (accums.breakpointI) {
									$scope.showIndividualBreakpoint = true;
									arrCoverageDetails.theValueIndvBP = this.myCurrency(accums.breakpointI);
								}
								if (accums.breakpointF) {
									$scope.showFamilyBreakpoint = true;
									arrCoverageDetails.theValueFamBP = this.myCurrency(accums.breakpointF);
								}





								}
							}

						var arrCps = (m.coverageInfo.costShareBenefits && m.coverageInfo.costShareBenefits.copayInfo) ? m.coverageInfo.costShareBenefits.copayInfo : null;
						// COPAYS   PCP, Specialist, ER    PHY/SPC/EMR  ==============================================================
						var hasEmr, hasSpc, hasPhy = false;
						if (arrCps != null && arrCps.length && arrCps.length > 0) {
							// remove items with empty string for the value
							var theLen = arrCps.length;
							for(var i = 0; i < arrCps.length; i++) {
								switch(arrCps[i].copayCode) {
									case 'SPC':
										arrCps[i].sortKey = 'b';
										arrCps[i].theLabel = 'Specialist:';  // TS st
										hasSpc = true;
										break;
									case 'PCP':
										arrCps[i].sortKey = 'd';
										arrCps[i].theLabel = 'PCP:';
										hasPhy = true;
										break;
									case 'EMR':
										arrCps[i].sortKey = 'c';
										arrCps[i].theLabel = 'ER:';
										hasEmr = true
										break;
									default :
										arrCps[i].sortKey = 'a';
										arrCps[i].theLabel = 'PCP:';
										break;
								} }  // end of setting sortKey
							arrCps.sort(function(firstItem, nextItem) {
								if (firstItem.sortKey < nextItem.sortKey) {
									return -1;
								}
								if (firstItem.sortKey > nextItem.sortKey) {
									return 1;
								}
								return 0;
							});
							if (arrCps.length && arrCps.length > 0) {
								var theString = '';
								for (var j = 0; j < arrCps.length; j++) {
									if ((arrCps[j].copayValue != '')) {
										theString += arrCps[j].theLabel + ' ';
										theString += myCurrency(arrCps[j].copayValue, true) + ' ';
										theString += '/' + ' ';
									}
								}
								// remove the last "/"
								theString = theString.substring(0,theString.length - 3);
								// display the label and the values -----------------------------------
								if (theString != '') {
									$scope.arrCoverageDetails.theDesc2 = 'Copay';
									$scope.arrCoverageDetails.theValue2 = theString;
								}
								m.showCoverage = true; // false; // true;
								// m.$apply();
							}
						}
						// COINSURANCE ===================================================================
						if (m.coverageInfo && m.coverageInfo.costShareBenefits && m.coverageInfo.costShareBenefits.coinsurance)  {
							var coIns = m.coverageInfo.costShareBenefits.coinsurance.toString();
							$scope.arrCoverageDetails.theDesc3 = 'Coinsurance';
							$scope.arrCoverageDetails.theValue3 = coIns + '%';
						};
							m.showCoverage = true; // false; // true;
							// m.$apply();
							// sample array item arr.push({'theDesc': oop_label, 'theValue': ci.outOfPocketMaximum, theSortKey: 'd'})
						// }
	//                    arr.sort(function(firstItem, nextItem) {
	//                        if (firstItem.theSortKey < nextItem.theSortKey) {
	//                            return -1;
	//                        }
	//                        if (firstItem.theSortKey > nextItem.theSortKey) {
	//                            return 1;
	//                        }
	//                        return 0;
	//                    });

						// ToDo: remove this for production - once service is up
	//            if (window.location.host.indexOf('denv') > -1 ) {
	//                // remove one or more items to handle each possible layout
	//                arr.splice(1,2);
	//            }

						m.covItemsNumber = $scope.arrCoverageDetails.length;
						if ($scope.arrCoverageDetails.length == 0) {
							m.showCoverage = false;
							m.covItemsNumber = 0;
						 }
						// m.$apply();
					}

                    // We call the planInfo service in landing.js then the following runs when that service returns
                    $scope.$watch('plans', function() {
                    if ($scope.plans && $scope.plans.plans && $scope.plans.plans.selected && $scope.plans.plans.selected.benefits) {
                        $scope.medicalPlanIsSelected = $scope.plans.plans.selected.benefits.isMedical
                        var thisGroupNumber = $scope.plans.plans.selected.groupNumber;
                        var coverageBeginDate = $scope.plans.plans.selected.memberEffectiveDate;
                        var coverageEndDate = $scope.plans.plans.selected.memberCancellationDate;
                        var csbParams = {brand: sessionData.data.memberInfo.brand };  //{brand: ums.brand};
                        // we gotta go a bit of a distance for product number/ID
                        //          to send it out to get coverageSummary returned
                        // NOTE: we have to wait till the watch is hit cause that is when plans is populated
                        // run getPortalSelectedPlan to populate some global variables in finderLogic
                        finderLogic.getPortalSelectedPlan($scope.memberInfo);

						var currentProduct = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical");
						if (!currentProduct) {
							return;
						}
                        csbParams.payload = {
                            // TODO : Set Proper values here from curGrp and curPrd
                            "startDate": coverageBeginDate.split("/").join("") , // "05012015", //TheDate.formatDate(curPrd.coverageBeginDate, "MMddyyyy") , // "05012015",  // curPrd.coverageBeginDate,  // 05/01/2014
                            "endDate": coverageEndDate.split("/").join("") , // "04302016", //TheDate.formatDate(curPrd.coverageEndDate, "MMddyyyy") , // "04302016",   // curPrd.coverageEndDate   // 04/30/2015
                            "groupNum": thisGroupNumber,
                            "lob": "M",
                            "productId": currentProduct.productNumber,
                            "memberPin": currentProduct.memberList[0].pinNumber
                        };

                        services.rbsmbl.costShareBenefit().fetch(csbParams).then(
							function(covInfoResponse) {

                             // var covInfoResponse = JSON.parse(covInfoResponse.data.replace(/:,/g, ': 0,'));
                             var coverageInfo = (covInfoResponse && covInfoResponse.data && covInfoResponse.data.payload && covInfoResponse.data.payload.coverageSummary )
                                 ? covInfoResponse.data.payload.coverageSummary : null;
                             if (coverageInfo) {
                                 $scope.coverageInfo = coverageInfo;
                                 displayCoverageInfoSection();
                                 } else {
                                     throw new Error('The coverage section web service returned null.');
                                 }
                             },
						 	function(err) {
								throw new Error(err);
							}
                        );
                    }
                	});
            }
            ]
        };
    });
}