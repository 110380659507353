module.exports = function (app) {
    require('./wellness/wellnessOverview')(app);
    require('./wellness/additionalResources')(app);
    require('./wellness/wellnessSearch')(app);
    require('./wellness/contentDetail')(app);
    require('./wellness/dentalHealthCenter')(app);
    require('./wellness/dentalHealthArticle')(app);
    require('./wellness/mySharecare')(app);

    app.controller('pages.wellness', ["$state", "session", "$scope", "teamsite", "coremetrics", "sessionData","brand",
        function ($state, session, $scope, teamsite, coremetrics, sessionData, brand) {
            window.$scope =$scope;
            window.document.title = "Wellness Overview";
            var isWYBCBS = brand.current.sharedBrandCode == "wybcbs";
            var isMN = brand.current.sharedBrandCode == "mincr";
            var isNDBCBS = brand.current.sharedBrandCode == "ndbcbs";
            $scope.mySharecare =false;
            $scope.sidebar.links = [];
		// todo: what happens if this doesn't exist, or on load?
		// todo: what is the default, this comes from the bootstrap template and needs assigned in the controller
		// todo: can we ng-if the categoryDetail.html's back button so that if it doesn't exist, it doesn't show
		// todo: can we add a meta content block to populate these on a page-by-page basis
		// todo: if we have a meta block, we would need the information BEFORE the controller load to correctly handle SEO
            if ($state.current.name == "wellness"){
                $state.go("wellness.wellnessOverview");
            }

        if(isWYBCBS){
            $scope.sidebar.links.push({
                heading: "It's All About You",
                label: "Wellness Overview",
                state: "wellness.wellnessOverview",
                description: "We're here to help you take charge of your health. See our tools for valuable information and resources to be healthy."
        });

        }else {
            var desc;

            if(isNDBCBS){ // remove call a health text
                desc = "We're here to help you take charge of your health. Set goals &amp; track your progress. Search our library &amp; more."
            }else{
                desc = "We're here to help you take charge of your health. Call a health coach. Set goals &amp; track your progress. Search our library &amp; more."
            }
            $scope.sidebar.links.push({
                heading: "It's All About You",
                label: "Wellness",
                state: "wellness.wellnessOverview",
                description: desc
            });
        }

            for(var i=0; i<session.data.memberInfo.clientList[0].groupList.length;i++){

                    if (session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator === "ShareCare" && !isMN && !isWYBCBS && !isNDBCBS) {
                        $scope.mySharecare =true;
                        $scope.sidebar.links.push({
                            label: "Sharecare",
                            state: "wellness.mySharecare",
                            description: "We're here to help you take charge of your health. Call a health coach. Set goals &amp; track your progress. Search our library &amp; more.",
                            heading: "It's All About You"

                        });

                       break;
                    }
                    else{
                        $scope.mySharecare = false;
                    }




            }


            var hasBlueEdgeDental = !!(sessionData && sessionData.blueEdgeDentalIndv);
            var hasStandAlone = !!(sessionData && sessionData.benefits && sessionData.benefits.dental);
            var hasDentalCoverage = hasStandAlone || hasBlueEdgeDental;
            var showDentalLink = sessionData.appConfig &&
                sessionData.appConfig.WELLNESS &&
                sessionData.appConfig.WELLNESS.WELLNESS_DENTAL_MAINTENANCE.booleanValue;

            if(hasDentalCoverage && showDentalLink){
                $scope.sidebar.links.push({
                    heading: "Dental Health Center",
                    label: "Dental Health Center",
                    href: "/chmptl/chm/jsp/dentalHealthCenter.do?oid=431181&type=program",
                description: 'Learn more about dental health.'
            });

        }

    teamsite.get('MBR_WELLNESS_LEFTNAV_TITLE').then(function (data) {
            $scope.headings  = data;
        });
            $scope.isAlbertsonsMember = false;



            // todo: is there a shared collection of tools for sidebar functions?
		//var setActiveFromBTS = function(link) {
		//	if (link) {
		//		link.active = true;
		//		scope.sidebar.heading = link.heading;
		//		scope.sidebar.description = link.description;
		//		scope.sidebar.selectedLink = link.label;
		//		scope.sidebar.current = link;
		//	}
		//	return link;
		//};


		//var filterWellnessLinks = function(item) {
		//	var isWellnessNavigationLink = item && item.sections && item.sections.memberWellness;
		//	var isValidEntitlement = (item.entitlements && item.entitlements.hasDental) ? hasDentalCoverage : true;
		//	return isWellnessNavigationLink && isValidEntitlement;
		//};

		// todo: should we need to write a custom filter on this or should it be in separate function?
		//var drainLinks = function(links) {
		//	while(links.length) {
		//		links.pop();
		//	}
		//};
        //contentNavigationService.updateNavigationAsync(wellnessNavigation, filterWellnessLinks).then(
		//	function (results) {
		//		drainLinks($scope.sidebar.links); // keep reference
         //       $scope.isSharecareMemberLabel = false;
         //       $scope.isSharecareMember = false;
         //       // check for Albertson -Idaho client
         //       if(session.data.memberInfo.clientList[0].clientNumber == "255721"){
         //           $scope.isAlbertsonsMember = true;
         //           $scope.isSharecareMember = true;
         //       }
         //       var today = new Date();
         //       if (today >= new Date("08/01/2018")){
        //
         //                   if(($scope.shareCareGroup === "service") && (session.data.memberInfo.brand === "IBC" || session.data.memberInfo.brand === "HMBCBS") && session.data.memberInfo.clientList.length>=1
         //                       && session.data.memberInfo.clientList[0].groupList!== null &&  session.data.memberInfo.clientList[0].groupList.length>=1
         //                   ){//wellnessVendorIndicator indicator changes
         //                       for(var i=0; i<session.data.memberInfo.clientList[0].groupList.length;i++)
         //                       {
         //                           if(session.data.groupNumber === session.data.memberInfo.clientList[0].groupList[i].groupNumber) {
         //                               if (session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator !== null &&
         //                                   session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator === "ShareCare") {
         //                                   $scope.isSharecareMember = true;
         //                                   $scope.isSharecareMemberLabel = true;
         //                               }
         //                               $scope.isSharecareMember = true;//HMHS_Sidebar_fix
         //                           }
         //                       }
         //                       $scope.isSharecareMemberLabel = true;
         //                   }
         //                   results.forEach(function (result) {
         //                       var theObj = {
         //                           heading: result.heading,
         //                           label: result["label"],
         //                           description: result["description"]
         //                       };
         //                       theObj["href"] = result["href"];
         //                       theObj["state"] = result["state"];
        //
         //                       if (!$scope.isSharecareMember) {
         //                           teamsite.get('SHARECARE_SOFTLAUNCH_GROUPS_MN').then(function (datasoft) {
         //                               $scope.shareCareGroupMN = datasoft;
         //                               if ($scope.shareCareGroupMN.indexOf(session.data.groupNumber) > -1) {
         //                                   theObj.state = null;
         //                                   $scope.sidebar.links.push(theObj);
         //                               } else if(($scope.shareCareGroupMN === "service") && session.data.memberInfo.brand === "MINCR" && session.data.memberInfo.clientList !== null &&
         //                                   session.data.memberInfo.clientList.length>=1 && session.data.memberInfo.clientList[0].groupList!== null &&
         //                                   session.data.memberInfo.clientList[0].groupList.length>=1){//wellnessVendorIndicator indicator changes
         //                                   for(var i=0; i<session.data.memberInfo.clientList[0].groupList.length;i++)
         //                                   {
         //                                       if(session.data.groupNumber === session.data.memberInfo.clientList[0].groupList[i].groupNumber) {
         //                                           if (session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator !== null &&
         //                                               session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator === "ShareCare"){
         //                                               theObj.state = null;
         //                                           $scope.sidebar.links.push(theObj);
         //                                       }
         //                                           else {
         //                                               theObj.state = null;
         //                                               $scope.sidebar.links.push(theObj);
         //                                           }
         //                                       }
         //                                   }
        //
         //                               } else {
         //                                   $scope.sidebar.links.push(theObj);
         //                               }
         //                           });
         //                       } else {
         //                           if (theObj.label == "Wellness Overview") {
         //                               theObj.label = "Wellness";
         //                               $scope.sidebar.links.push(theObj);
         //                           } else if (theObj.label !== "Additional Resources") {
         //                               $scope.sidebar.links.push(theObj);
         //                           }
        //
         //                       }
         //                   })
        //
        //
        //
         //       }
         //       else {
         //           results.forEach(function (result) {
         //               var theObj = {
         //                   heading: result.heading,
         //                   label: result["label"],
         //                   description: result["description"]
         //               };
         //               theObj["href"] = result["href"];
         //               theObj["state"] = result["state"];
         //                   //var mySharecare =true; //remove this once the service indicator is configured
         //                   //if (mySharecare) {
         //                   //    $scope.sidebar.links.push({
         //                   //        label: "Sharecare",
         //                   //        state: "wellness.mySharecare"
         //                   //
         //                   //    });
         //                   //}
         //               if($scope.isAlbertsonsMember){
         //                   if(theObj.label=="Wellness Overview"){
         //                       theObj.label="Wellness";
         //                       $scope.sidebar.links.push(theObj);
         //                   }
         //               }else{
        //
         //                   $scope.sidebar.links.push(theObj);
        //
         //               }
        //
        //
         //               }
         //           )
         //       }
        //
         //   }
		//);


            console.log("THE STATE", $state.current.name);

            $scope.uiMods = {};
        $scope.uiMods.theBlog = { showThis: null };
        $scope.webmdRecs = {
            theRecs: null,
            showThis: null
        };

        $scope.backText = "Back";
        $scope.backCoreMetricsCategory = "";
        $scope.backCoreMetricsElID = "";
        $scope.backAction = function () {
            if($scope.backCoreMetricsCategory.length > 0 && $scope.backCoreMetricsElID.length > 0){
                coremetrics.elementTag($scope.backCoreMetricsElID, $scope.backCoreMetricsCategory);
            }
            $state.go("^");
        };

    }]);

    return [
        {
            state: "wellness",
            postPassword: true,
            appConfigMaintenanceApp: 'WELLNESS',
            appConfigMaintenanceKey: 'FULL_MAINTENANCE',
            url:"/wellness",
            //resolve: {
            //    wellnessNavigation: ["contentNavigationService", function(contentNavigationService) {
            //        return contentNavigationService.getNavigationIdListAsync();
            //    }]
            //},
            views: {

                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: 'pages.wellness'
                },
                'content@wellness': {
                    template: require('pages/wellness/wellnessOverview.html'),
                    controller: 'pages.wellness.wellnessOverview'
                },
                "rightColumn@wellness": {
                }
            }
        },
        {
            state: "wellness.mySharecare",
            postPassword: true,
            url: "/mySharecare",

            views: {
                "content@wellness": {
                    template: require('pages/wellness/mySharecare.html'),
                    controller: "pages.wellness.mySharecare"
                }
            }
        },
        {
            state: "wellness.wellnessOverview",
            postPassword: true,
            appConfigMaintenanceApp: 'WELLNESS',
            appConfigMaintenanceKey: 'WELLNESS_SUMMARY_MAINTENANCE',
            url: "/wellnessOverview",
            views: {
                'content@wellness': {
                    template: require('pages/wellness/wellnessOverview.html'),
                    controller: 'pages.wellness.wellnessOverview'
                }
            }
        },
        {
            state: "wellness.additionalResources",
            postPassword: true,
            appConfigMaintenanceApp: 'WELLNESS',
            appConfigMaintenanceKey: 'ADDITIONAL_RESOURCES_MAINTENANCE',
            url: "/additional-resources",
            views: {
                "content@wellness": {
                    template: require('pages/wellness/additionalResources.html'),
                    controller: "pages.wellness.additionalResources"
                },
                "rightColumn@wellness": {
                    // Necessary to ensure a blank right sidebar
                }
            }
        },
        {
            state: "wellness.wellnessSearch",
            postPassword: true,
            appConfigMaintenanceApp: 'WELLNESS',
            appConfigMaintenanceKey: 'WELLNESS_SEARCH_MAINTENANCE',
            url: "/wellness-search/:searchQuery",
            scrollToTop: false,
            views: {
                "content@wellness": {
                    template: require('pages/wellness/wellnessSearch.html'),
                    controller: "pages.wellness.wellnessSearch"
                },
                "rightColumn@wellness": {
                    // Necessary to ensure a blank right sidebar
                }
            }
        },
        {
            state: "wellness.contentDetail",
            postPassword: true,
            appConfigMaintenanceApp: 'WELLNESS',
            appConfigMaintenanceKey: 'WELLNESS_DETAIL_MAINTENANCE',
            url: "/contentDetail?projectTypeID&productID&genContentID&linked",
            views: {
                'body@': {
                    //template: bootstrap.templates['page-templates/categoryCustom.html'],
                    templateProvider: function($templateFactory,$stateParams) {
                        if($stateParams.productID==='10') {
                            return $templateFactory.fromString(bootstrap.templates["page-templates/categoryDetail.html"]);
                        }else{
                            return $templateFactory.fromString(require('pages/wellness/detail_single_column.html'));
                        }
                    },
                    controller: 'pages.wellness.contentDetail'
                },
                "content@wellness.contentDetail": {
                    template: require('pages/wellness/contentDetail.html'),
                    controller: 'pages.wellness.contentDetail'
                },
                "rightColumn@wellness": {
                    // Necessary to ensure a blank right sidebar
                }
            }
        },
        {
            state: "wellness.dppDetails",
            postPassword: true,
            appConfigMaintenanceApp: 'WELLNESS',
            appConfigMaintenanceKey: 'WELLNESS_DETAIL_MAINTENANCE',
            url: "/dppDetails",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/detailCustom.html'],
                    controller: 'pages.wellness'
                },
                "content@wellness.dppDetails": {
                    template: require('pages/wellness/dppDetails.html'),
                    controller: 'pages.wellness.dppDetails'
                }
            },
            params: {
            wellnessOverviewModule: null
        }
        },
        {
            state: "wellness.ccs",
            postPassword: true,
            appConfigMaintenanceApp: 'WELLNESS',
            appConfigMaintenanceKey: 'WELLNESS_DETAIL_MAINTENANCE',
            url: "/ccs",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/detailCustom.html'],
                    controller: 'pages.wellness'
                },
                "content@wellness.ccs": {
                    template: require('pages/wellness/ccs.html'),
                    controller: 'pages.wellness.ccs'
                }
            },
            params: {
                wellnessOverviewModule: null
            }
        },
        {
            state: "wellness.ccsSignUp",
            postPassword: true,
            appConfigMaintenanceApp: 'WELLNESS',
            appConfigMaintenanceKey: 'WELLNESS_DETAIL_MAINTENANCE',
            url: "/ccsSignUp",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/detailCustom.html'],
                    controller: 'pages.wellness'
                },
                "content@wellness.ccsSignUp": {
                    template: require('pages/wellness/ccsSignUp.html'),
                    controller: 'pages.wellness.ccsSignUp'
                }
            },
            params: {
                wellnessOverviewModule: null
            }
        },
        {
            state: "wellness.dentalHealth",
            postPassword: true,
            appConfigMaintenanceApp: "WELLNESS",
            appConfigMaintenanceKey: "WELLNESS_DENTAL_MAINTENANCE",
            url: "/dental-health-center",
			resolve: {
				wellnessArticles: ["contentResourceServices", function(contentResourceServices) {
					return contentResourceServices.getResourceIdListAsync("Bluecore Article");
				}]
			},
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: 'pages.wellness'
                },
                "content@wellness.dentalHealth": {
                    template: require("pages/wellness/dentalHealthCenter.html"),
                    controller: "pages.wellness.dentalHealthCenter"
                }
            }
        },
        {
            state: "wellness.dentalHealth.article",
            postPassword: true,
            appConfigMaintenanceApp: "WELLNESS",
            appConfigMaintenanceKey: "WELLNESS_DENTAL_MAINTENANCE",
            url: "/:articleID",
            views: {
                "body@": {
                    template: bootstrap.templates['page-templates/categoryDetail.html'],
                    controller: "pages.wellness"
                },
                "content@wellness.dentalHealth.article": {
                    template: require("pages/wellness/dentalHealthArticle.html"),
                    controller: "pages.wellness.dentalHealth.article"
                }
            }
        }
    ];
};