module.exports = function(app) {
    app.directive('claimDrawerDownloads', function() {

        return {
            restrict: 'A',
            replace: true,

            scope: {
                claim: '=',
                buttons: '=claimDrawerDownloads'
            },

            template: require('directives/claimDrawerDownloads.html'),

            controller: ['$scope', '$state', 'claimDownloadService', 'claimService', '$filter', 'pdfRequestPrepService', 'coremetrics',
                function($scope, $state,claimDownloadService, claimService, $filter, pdfRequestPrepService, coremetrics) {
                    $scope.eobTooltip = '<span style="line-height: 1.5; " data-teamsite="MBR_Claims_ClaimsDetail_EOBDEPMSG"></span>';

                    $scope.$watchCollection('buttons', function(buttons) {
                        if (!(buttons instanceof Object)) { return; }

                        Object.keys(buttons).forEach(function(key) {
                            $scope[key] = buttons[key];
                        });
                    });

                    var _configureDCDForm = function(claim) {
                        var
                            status = claimService.getClaimStatus(claim.bilgProvClmStaCd),
                            pdfClaimObject = pdfRequestPrepService.decorateClaimForRequestPayload(claim, status),
                            configObject;

                        configObject = {
                            'name': 'GetClaimsXLS',
                            'method': 'POST',
                            'action': '/rbsmbl/x-services/statement/claimDetails/save?noCache=' + Math.random(),
                            'inputs': {
                                payload : JSON.stringify({ payload: pdfClaimObject })
                            }
                        };

                        return configObject;
                    };

                    $scope.downloadDocument = function(buttonCode, dependencies) {
                        switch(buttonCode) {
                            case 'DCD':
                                coremetrics.elementTag('DOWNLOAD CLAIM DETAILS DOWNLOAD:CLAIMS','MEMBER:CLAIMS');
                                dependencies = _configureDCDForm(dependencies);
                                return claimDownloadService.getDCD(dependencies);

                            case 'EOB':
                                coremetrics.elementTag('EOB DOWNLOAD:CLAIMS','MEMBER:CLAIMS');
                                angular.extend(dependencies, { MTM_PT: $scope.claim.details[0].sysSrcCode });
                                return claimDownloadService.getDocument(dependencies);

                            case 'EOP':
                                coremetrics.elementTag('EOP DOWNLOAD:CLAIMS','MEMBER:CLAIMS');
                                return claimDownloadService.getDocument(dependencies);

                            case 'PAS':
                                if($state.is('claims.claimsAndPayments')){
                                    coremetrics.elementTag('CLAIMS PLAN ACTIVITY STATEMENT','MEMBER:CLAIMS');
                                } else {
                                    coremetrics.elementTag('PLAN ACTIVITY STATEMENT','MEMBER:CLAIMS');
                                }
                                return claimDownloadService.getDocument(dependencies);

                            case 'CDN':
                                coremetrics.elementTag('CLAIM DELAY NOTICE DOWNLOAD','MEMBER:CLAIMS');
                                return claimDownloadService.getDocument(dependencies);

                            default:
                                return console.log('Nothing specified for download');
                        }
                    };

                    $scope.downloadDentalEOB = function(documentToken, documentType) {
                        coremetrics.elementTag('EOB DOWNLOAD:CLAIMS','MEMBER:CLAIMS');
                        return claimDownloadService.getDentalEobDoc(documentToken, documentType);
                    }
                }
            ]
        };
    });
};
