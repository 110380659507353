module.exports = function(app) {
    var securityUpdatesModal = require("../../modals/securityUpdatesModal");
    app.controller('pages.sapphireLanding.sapphireLogin', ["$state", "$scope", "$location", "services", "teamsite", "navigation", "brand", "brandConfig", "coremetrics", "modal", "storage", "session", "storageOld", "$window",
        function($state, $scope, $location, services, teamsite, navigation, brand, brandConfig, coremetrics, modal, storage, session, storageOld, $window) {
            $scope.username = "";
            $scope.password = "";
            $scope.loginErrorTitle = null;
            $scope.loginErrorText = null;

            $scope.guideWellRedirect = $location.search().returnTo;

            $scope.theDomain = function() {
                var theDomain = 'https://' + window.location.host;
                return theDomain;
            };

            var origPrePasswordMemberFooter = {};
            var origPrePasswordMemberHeader = {};
            removeNavigation();

            $scope.goToRegistration = function () {
                storageOld.set('vitalsLanding', 'true');
                window.open('/login/#/register');
            };

            $scope.login = function () {

                coremetrics.startConversion('Vitals Landing Page Login','Login');

                $scope.loginErrorTitle = null;
                $scope.loginErrorText = null;
                if ($scope.memberLoginForm.$valid) {
                    $scope.loginDisable = true;
                    services.rbsmbl.loginversion().fetch($scope.username.toUpperCase()).then(function (response) {
                        var payload = ((response && response.data && response.data.payload) ? response.data.payload : ((response) ? response.payload : null));

                        if (payload.convertCase) {
                            $scope.password = $scope.password.toUpperCase();
                        }

                        services.rbsmbl.login().fetch($scope.username.toUpperCase(), $scope.password).then(function (response) {
                            coremetrics.finishConversion('Sapphire Digital Landing Page Login','Login');

                            var payload = ((response && response.data && response.data.payload) ? response.data.payload : ((response) ? response.payload : null));

                            if (payload && payload.loginInfo && payload.loginInfo.isTempPassword && payload.loginInfo.isTempPassword === 'true') {
                                location.replace("/login/#/change-password");
                            } else if (payload && payload.loginInfo.securityStrengthEnforcement === 'FORCED') {
                                storage.set("forcedPassword", true);
                                modal.open({
                                    template: require('modals/securityUpdatesModal.html'),
                                    onClose: function() {
                                        window.location = "/login/#/change-password";
                                    }
                                });
                            } else {
                                // We have to do a lot of deep diving just to find the groupNumber for the user who just logged in
                                var userInfo = payload.loginInfo.userInfo;
                                var groups = userInfo.clientList[0].groupList;
                                var userFName = userInfo.firstName.toUpperCase();
                                var groupId = null;

                                // unfortunately, this isn't information that is floated to the top, so we have to look for a Group
                                // that has a "Medical" product that also has the currently logged in user as a member
                                for(var iGrp = 0; iGrp < groups.length; iGrp++){
                                    // get the product list for this group
                                    var grpProducts = groups[iGrp].productList;
                                    var hasMedProduct = false;

                                    for(var iPrd = 0; iPrd < grpProducts.length; iPrd++){
                                        // only look at products that are medical
                                        if(grpProducts[iPrd].benefitPlanType.toLowerCase() === "medical"){
                                            // and with in those, make sure the user is listed as a member for that product
                                            for(var iMem = 0; iMem < grpProducts[iPrd].memberList.length; iMem++){
                                                var mem = grpProducts[iPrd].memberList[iMem];
                                                if(mem.contact.firstName.toUpperCase() === userFName){
                                                    // if the firstnames match (this is the worst of the worst
                                                    // decisions, here, but we have nothing else to match on in the
                                                    // payload), we have our product / group, so no further searching
                                                    // is needed...
                                                    hasMedProduct = true;
                                                    break;
                                                }
                                            }
                                            if(hasMedProduct){
                                                // if we found the med product, we know this is the right group
                                                break;
                                            }
                                        }
                                    }
                                    if(hasMedProduct){
                                        // all of that ridiculous searching to get this groupNumber
                                        // if the service was refactored to retrieve basic user info such as GroupNumber
                                        // this would not be needed.
                                        groupId = groups[iGrp].groupNumber;
                                        break;
                                    }
                                }
                                // NOW, once we have done all of that, we have to call a service that returns a ton of
                                // data just to find the user's zipcode
                                services.rbsmbl.AccountSettingsRetrive()
                                    .retriveAccountSettings(groupId, "ccsAccountSettings", userInfo.brand, userInfo.userName)
                                    .then(function(accountSettingsData) {
                                        if (accountSettingsData && accountSettingsData.data && accountSettingsData.data.payload) {
                                            var ssoLinkBase = '/rbsmbl/x-services/public/sapphire/sso/' + $scope.brandData.sharedBrandCode + '/member?zip=' + accountSettingsData.data.payload.zip;
                                            location.href = ssoLinkBase;
                                        }
                                    });
                            }
                        }, function (error) {
                            $scope.loginDisable = false;
                            handleErrorResponse(error);
                        });
                    }, function (error) {
                        $scope.loginDisable = false;
                        handleErrorResponse(error);
                    });
                }
            };

            $scope.forgotPassword = function () {
                coremetrics.elementTag('FORGOT: USERNAME OR PASSWORD', 'FORGOT');
                window.open('/login/#/forgot-password/step1');
            };

            function removeNavigation() {
                brand.current.socialUpper = [];
                origPrePasswordMemberFooter = angular.copy(navigation.prePasswordMemberFooter);
                for (var x in navigation.prePasswordMemberFooter) {
                    if (navigation.prePasswordMemberFooter.hasOwnProperty(x)) {
                        navigation.prePasswordMemberFooter[x] = {};
                    }
                }
                origPrePasswordMemberHeader = angular.copy(navigation.prePasswordMemberHeader);
                for (var y in navigation.prePasswordMemberHeader) {
                    if (navigation.prePasswordMemberHeader.hasOwnProperty(y)) {
                        navigation.prePasswordMemberHeader[y] = {};
                    }
                }
                navigation.update();
            }

            function handleErrorResponse(error) {
                var specificErrorContent = {title:"",content:"", message:""};

                if (error && error.data) {
                    var response = error.data;
                    var errorMessage = response.errors ? response.errors[0].errorMessage : response.baseMessage.returnCodeDescription;

                    // Handle mapping of service error to error messages.
                    if (errorMessage === 'GEN-SUSPEND') {
                        var appConfig = (session.data && session.data.appConfig && session.data.appConfig.FORGOT) ? session.data.appConfig.FORGOT : {};
                        var isAccountRecoveryActive = (appConfig && appConfig.ACCOUNT_RECOVERY && appConfig.ACCOUNT_RECOVERY.booleanValue === true) ? true : false;
                        specificErrorContent.content = (isAccountRecoveryActive)? "MBR_GEN_SUSPEND_ACCOUNT_LOCKOUT_RECOVERY" : "MBR_GEN_SUSPEND_ACCOUNT_LOCKOUT";

                    } else if (errorMessage === 'GEN-NOACCESS') {
                        var errorSpecifics = response.baseMessage.additionalInfo[0].value;

                        if (errorSpecifics === 'NO_ACCESSIBLE_COVERAGE_EXCEPTION') {
                            specificErrorContent.content = "MBR_ERROR_NO_ACCESSIBLE_COVERAGE_EXCEPTION";

                        } else if (errorSpecifics === 'OVER_CHIP14') {
                            specificErrorContent.content = "MBR_ERROR_OVER_CHIP14";

                        } else if (errorSpecifics === 'CHIP14_GUARDIAN_VERIFICATION_FAIL') {
                            specificErrorContent.content = "MBR_ERROR_CHIP14_GUARDIAN_VERIFICATION_FAIL";

                        } else {
                            specificErrorContent.content = "MBR_LOGIN_GENERIC_ERROR";
                        }
                    } else if (errorMessage === 'GEN-CONFCOMM') {
                        specificErrorContent.content = "MBR_ERROR_GEN_CONFCOMM";

                    } else if (errorMessage === 'GEN-NOMATCH' ||
                            errorMessage === 'GEN-NORECCHMECS' ||
                            errorMessage === 'USER-NOT-ON-FILE') {
                        specificErrorContent.content = "MBR_ERROR_NOMATCH_NORECCHMECS_USER_NOT_ON_FILE";

                    } else if (errorMessage === 'ACC-ECS') {
                        specificErrorContent.content = "MBR_ERROR_ACC_ECS";

                    } else if (errorMessage === 'ACC-ICIS') {
                        specificErrorContent.content = "MBR_ERROR_ACC_ICIS";

                    } else if (errorMessage === 'ACC-CRMPP') {
                        specificErrorContent.content = "MBR_ERROR_ACC_CRMPP";

                    } else if (errorMessage === 'INVALID-LOGIN-BRANDCONFLICT') {
                        specificErrorContent.content = "MBR_ERROR_INVALID_LOGIN_BRANDCONFLICT";

                    } else if (errorMessage === 'ERROR_BAD_REQUEST') {
                        specificErrorContent.content = "MBR_ERROR_BAD_REQUEST";

                    } else if (errorMessage === 'UNAUTHORIZED_SIMULATION_ACCESS') {
                        specificErrorContent.content = "MBR_ERROR_UNAUTHORIZED_SIMULATION_ACCESS";

                    } else if (errorMessage === 'NO_BRAND_ACCESS') {
                        specificErrorContent.content = "MBR_ERROR_NO_BRAND_ACCESS";

                    } else if (errorMessage === 'NO_INTRACOMPANY_ACCESS') {
                        specificErrorContent.content = "MBR_ERROR_NO_INTRACOMPANY_ACCESS";

                    } else if (errorMessage === 'VALID-LOGIN-BRANDCONFLICT') {
                        specificErrorContent.content = "MBR_ERROR_VALID_LOGIN_BRANDCONFLICT";

                    }  else if (errorMessage === 'GEN-NOTONPLATFORM') {
                        var valueNotOnPlatform = response.baseMessage.additionalInfo[0].value;
                        var userNotOnPlatform = response.baseMessage.additionalInfo[0].key;
                        switch (userNotOnPlatform) {
                            case "showBanner":
                                specificErrorContent.content = "MBR_ERROR_REGISTER_UNION_PACIFIC_MESSAGE";
                                break;
                            case "showBanner-2":
                                specificErrorContent.content = "MBR_ERROR_REGISTER_CHS_CLIENT_LOGIN_FAIL";
                                break;
                            case "showBanner-3":
                                specificErrorContent.content ="MBR_ERROR_REGISTER_SELECT_MED_LOGIN_FAIL";
                                break;
                            default:
                                $window.location = valueNotOnPlatform;
                        }
                    } else {
                        specificErrorContent.content = "MBR_LOGIN_GENERIC_ERROR";
                    }
                } else {
                    specificErrorContent.content = "MBR_LOGIN_GENERIC_ERROR";
                }
                teamsite.get(specificErrorContent.content).then(function (response){
                    specificErrorContent.message = response;
                    $scope.loginErrorTitle = "<span class='hm-icon icon-alert' aria-hidden='true'></span>" + ((specificErrorContent.title.length > 0)? "<span>"+ specificErrorContent.title + "</span>" : " ");
                    $scope.loginErrorText = "<span>" + specificErrorContent.message + "</span>";
                });
            }

        }
    ]);
};