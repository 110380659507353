module.exports = function(app) {
    var clientNumber = null;
    var groupNumber = null;
    var theBrand = null;
    var myPassportModal = require("../../modals/passportModal");
    var amedaModal = require("../../modals/maternityAmeda");
    var medelaModal = require("../../modals/maternityMedela");
    app.controller('pages.wellness.wellnessOverview', ['$scope', 'session', 'services','coremetrics', 'preventiveScheduleService', 'vendorSSOUtility', 'wellnessLogic', 'displayOrHideItemsWrapper', 'finder-logic2',
        'teamsite', 'modal', 'wellnessUtility', '$state', 'brand', '$window', '$sce', '$cookies',
        function($scope, session, services, coremetrics, preventiveScheduleService, vendorSSOUtility, wellnessLogic, displayOrHideItemsWrapper, finderLogic,
                    teamsite, modal, wellnessUtility, $state, brand, $window, $sce, $cookies) {
            $scope.hmSsoLogoutURL = '';
            $scope.uiMods.needHelp = { showThis: null };
            $scope.uiMods.preventiveSchedule = { showThis: null };
            $scope.rewardsItem = {
                showThis: false,
                theProgramName: null
            };
            $scope.wellnessPrograms = {};
            $scope.wellnessProgramsFitness = {};
            $scope.showWellnessPrograms = false;
            $scope.showOverviewBanner = false;
            $scope.wellnessProgramQualifiers = {};
            $scope.isQualifiersLoaded = false;
            // $scope.webmdRecs = { theRecs: null };

            $scope.bDPPModule = false;
            $scope.isAlbertsonsMember = false;
            $scope.isSharecareMember = false;
            $scope.isSharecareMemberMN = false;
            $scope.isMNWebMDflag =false;
            $scope.isHMHSWebMDflag = false;
            $scope.isHMHSWebMDImageflag = false;
            $scope.isHMHSSharecarePopup = false;
            // Diabetes Prevention Check
            services.rbsmbl.eligibility().getData().then(function (objSuccessResponse){
                $scope.eligibilityIndicator = objSuccessResponse.data.payload.dppIndicator;

                if (($scope.eligibilityIndicator === "MA") || ($scope.eligibilityIndicator === "C")){
                    teamsite.setQualifier('dppIndicator', $scope.eligibilityIndicator);
                }

            }, function (objErrorResponse) {
                console.log("Eligibility Service Failure", objErrorResponse );
            });
            // check for Albertson -Idaho client
            if(session.data.memberInfo.clientList[0].clientNumber == "255721"){
                $scope.isAlbertsonsMember = true;
                $scope.isSharecareMember=true;
            }
            //Dupont preventive schedule check
            var isDe = finderLogic.getPropertyByBrandCode(brand.current.sharedBrandCode, 'regionIsDe');
            $scope.showPreventiveLink = true;
            if (isDe && session.data.memberInfo.clientList[0].clientName === 'DUPONT'){
                $scope.showPreventiveLink = false;
            }
            // check for sharecare - HMK soft launch
            teamsite.get('SHARECARE_SOFTLAUNCH_GROUPS').then(function (data){
                $scope.shareCareGroup = data;
                if($scope.shareCareGroup.indexOf(session.data.groupNumber)>-1){//wellnessVendorIndicator indicator changes For HMHS
                    $scope.isSharecareMember = true;
                    $scope.isHMHSWebMDImageflag = true;
                }
                else if(($scope.shareCareGroup === "service") &&
                    (session.data.memberInfo.brand != "WYBCBS" && session.data.memberInfo.brand != "MINCR" && session.data.memberInfo.brand != "NDBCBS")
                    && session.data.memberInfo.clientList.length>=1
                    && session.data.memberInfo.clientList[0].groupList!== null &&  session.data.memberInfo.clientList[0].groupList.length>=1
                ){//wellnessVendorIndicator indicator changes
                    for(var i=0; i<session.data.memberInfo.clientList[0].groupList.length;i++)
                    {
                         if(session.data.groupNumber === session.data.memberInfo.clientList[0].groupList[i].groupNumber) {
                            if(session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator!== null &&
                                session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator === "ShareCare") {
                                $scope.isSharecareMember = true;
                            }
                             $scope.isSharecareMember = true;
                        }
                    }
                    //$scope.isMNWebMDflag = true;
                    //$scope.isHMHSWebMDflag = true;
                    $scope.isHMHSWebMDImageflag = true;
                    $scope.isSharecareMemberLabel = true;
                    $scope.isSharecareMember = true;
                }
                else if(session.data.memberInfo.brand ==="HMBCBS" || session.data.memberInfo.brand ==="DE" ||
                    session.data.memberInfo.brand ==="PBS" || session.data.memberInfo.brand ==="MS"){
                    var today = new Date();
                    if (today <= new Date("12/31/2018")) {
                        $scope.isHMHSSharecarePopup = true;
                        var closebtns = document.getElementsByClassName("close");
                        var i;
                        for (i = 0; i < closebtns.length; i++) {
                            closebtns[i].addEventListener("click", function() {
                                this.parentElement.style.display = 'none';
                            });
                        }

                    }
                }
            });
            //New Links for Wellness page for Minnesota
            if(((window.location.host.indexOf('denv') > -1))||(window.location.host.indexOf('tenv') > -1)){
                teamsite.get('MBR_WELLNESS_SHARECARE_LINK_TEST').then(function (data){
                    $scope.sharecareLink = data;
                });
                teamsite.get('MBR_WELLNESS_SHARECARE_DESCRIPTION_TEST').then(function (data){
                    $scope.sharecareDescription = data;
                });
            }
            if((!(window.location.host.indexOf('denv') > -1))&& !(window.location.host.indexOf('tenv') > -1)) {
                teamsite.get('MBR_WELLNESS_SHARECARE_LINK').then(function (data) {
                    $scope.sharecareLink = data;
                });
                teamsite.get('MBR_WELLNESS_SHARECARE_DESCRIPTION').then(function (data){
                    $scope.sharecareDescription = data;
                });
            }
            if(((window.location.host.indexOf('denv') > -1))||(window.location.host.indexOf('tenv') > -1)){
                teamsite.get('MBR_WELLNESS_FITNESS_INCENTIVE_LINK_TEST').then(function (data){
                    $scope.fitnessincetiveLink = data;
                });
                teamsite.get('MBR_WELLNESS_FITNESS_INCENTIVE_DESCRIPTION_TEST').then(function (data){
                    $scope.fitnessIncentiveDescription = data;
                });
            }
            if((!(window.location.host.indexOf('denv') > -1))&& !(window.location.host.indexOf('tenv') > -1)) {
                teamsite.get('MBR_WELLNESS_FITNESS_INCENTIVE_LINK').then(function (data) {
                     $scope.fitnessincetiveLink = data;
                });
                teamsite.get('MBR_WELLNESS_FITNESS_INCENTIVE_DESCRIPTION').then(function (data){
                    $scope.fitnessIncentiveDescription = data;
                });
            }
            teamsite.get('MBR_WELLNESS_FITNESS_DESCRIPTION').then(function (data){
                $scope.fitnessDescription = data;
            });
            if(((window.location.host.indexOf('denv') > -1))||(window.location.host.indexOf('tenv') > -1)) {
                teamsite.get('MBR_WELLNESS_REALAGE_LINK_TEST').then(function (data) {
                    $scope.realageLink = data;
                });
            }
            if((!(window.location.host.indexOf('denv') > -1))&& !(window.location.host.indexOf('tenv') > -1)) {
                teamsite.get('MBR_WELLNESS_REALAGE_LINK').then(function (data) {
                    $scope.realageLink = data;
                });
            }
            if(((window.location.host.indexOf('denv') > -1))||(window.location.host.indexOf('tenv') > -1)) {
                teamsite.get('MBR_WELLNESS_ASKMD_LINK_TEST').then(function (data) {
                    $scope.askmdLink = data;
                });
            }
            if((!(window.location.host.indexOf('denv') > -1))&& !(window.location.host.indexOf('tenv') > -1)) {
                teamsite.get('MBR_WELLNESS_ASKMD_LINK').then(function (data) {
                    $scope.askmdLink = data;
                });
            }
            if(((window.location.host.indexOf('denv') > -1))||(window.location.host.indexOf('tenv') > -1)) {
                teamsite.get('MBR_WELLNESS_HEALTH_TOPICS_LINK_TEST').then(function (data) {
                    $scope.healthtopicsLink = data;
                });
            }
            if((!(window.location.host.indexOf('denv') > -1))&& !(window.location.host.indexOf('tenv') > -1)) {
                teamsite.get('MBR_WELLNESS_HEALTH_TOPICS_LINK').then(function (data) {
                    $scope.healthtopicsLink = data;
                });
            }
            if(((window.location.host.indexOf('denv') > -1))||(window.location.host.indexOf('tenv') > -1)) {
                teamsite.get('MBR_WELLNESS_TRACK_HABITS_LINK_TEST').then(function (data) {
                    $scope.trackhabitsLink = data;
                });
            }
            if((!(window.location.host.indexOf('denv') > -1))&& !(window.location.host.indexOf('tenv') > -1)) {
                teamsite.get('MBR_WELLNESS_TRACK_HABITS_LINK').then(function (data) {
                    $scope.trackhabitsLink = data;
                });
            }
            teamsite.get('MBR_WELLNESS_DOAPP_LINK').then(function (data){
                $scope.doappLink = data;
            });
            teamsite.get('MBR_WELLNESS_FITNESS_DISCOUNTS_LINK').then(function (data){
                $scope.fitnessdiscountsLink = data;
            });
            teamsite.get('SHARECARE_SOFTLAUNCH_GROUPS_MN').then(function (data){// Restring Welnesspage changes for MN to 8 Groups
                $scope.shareCareGroupMN = data;
                if($scope.shareCareGroupMN.indexOf(session.data.groupNumber)>-1){
                    $scope.isSharecareMemberMN = true;
                }
                else if(($scope.shareCareGroupMN === "service") && session.data.memberInfo.brand === "MINCR" && session.data.memberInfo.clientList.length>=1
                    && session.data.memberInfo.clientList[0].groupList!== null &&  session.data.memberInfo.clientList[0].groupList.length>=1
                ){//wellnessVendorIndicator indicator changes
                    for(var i=0; i<session.data.memberInfo.clientList[0].groupList.length;i++)
                    {
                        if(session.data.groupNumber === session.data.memberInfo.clientList[0].groupList[i].groupNumber) {
                          //  session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator = "ShareCare";
                            if(session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator!== null &&
                                session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator === "ShareCare") {
                                $scope.isSharecareMemberMN = true;
                            }
                        }
                    }
                    $scope.isMNWebMDflag = true;
                    $scope.isSharecareMemberLabel = true;
                }
            });
            teamsite.get('MBR_WELLNESS_PROGRAMS').then(function (data1) {//welness changes Fitness data changes
                var data = JSON.parse(data1);
                teamsite.get('SHARECARE_SOFTLAUNCH_GROUPS_MN').then(function (datasoft){
                    $scope.shareCareGroupMN = datasoft;
                if($scope.shareCareGroupMN.indexOf(session.data.groupNumber)>-1) {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].title === 'Fitness Discounts') {
                            $scope.wellnessProgramsFitness = data[i];
                            $scope.wellnessProgramsFitness.description = $scope.fitnessDescription;
                            data.splice(i, 1);
                        }
                        $scope.isSharecareMemberMN = true;
                    }
                } else if(($scope.shareCareGroupMN === "service") && session.data.memberInfo.brand === "MINCR" && session.data.memberInfo.clientList.length>=1
                    && session.data.memberInfo.clientList[0].groupList!== null &&  session.data.memberInfo.clientList[0].groupList.length>=1
                ){//wellnessVendorIndicator indicator changes
                    for(var i=0; i<session.data.memberInfo.clientList[0].groupList.length;i++)
                    {
                        if(session.data.groupNumber === session.data.memberInfo.clientList[0].groupList[i].groupNumber) {
                         //   session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator = "ShareCare";
                            if (session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator !== null &&
                                session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator === "ShareCare"){
                                for (var i = 0; i < data.length; i++) {
                                    if (data[i].title === 'Fitness Discounts') {
                                        $scope.wellnessProgramsFitness = data[i];
                                        $scope.wellnessProgramsFitness.description = $scope.fitnessDescription;
                                        data.splice(i, 1);
                                    }
                                }
                            $scope.isSharecareMemberMN = true;
                        }
                        }
                    }
                }
            });
                $scope.wellnessPrograms = data;
            });
            $scope.isMINCR = (brand.current.sharedBrandCode === 'mincr') ? true : false;

            $scope.isWYBCBS = (brand.current.sharedBrandCode === 'wybcbs') ? true : false;

            $scope.isNDBCBS = (brand.current.sharedBrandCode === 'ndbcbs') ? true : false;

            if (session.data.groupNumber) {
                wellnessUtility.getQualifierServiceResults().then(
                    function (data) {
                        processQualifiers(data);
                    }, function(error) {
                        processQualifiers();
                    }
                );
            } else {
                // send them to  https://tenv7.highmarkbcbs.com/chmptl/chm/jsp/memberCategory.do?tab=5
                var theDomain;
                if ((window.location.host.indexOf('denv')>-1) || (window.location.host.indexOf('tenv')>-1)) {
                    theDomain = 'tenv7';
                } else {
                    theDomain = 'www';
                }
                //window.open('https://'+ theDomain +'.highmarkbcbs.com/chmptl/chm/jsp/memberCategory.do?tab=5', '_self');
            }


            var processQualifiers = function (data) {
                data = data || {};
                $scope.isHcrPreEffecUser = (data.isHCRPreEffectuated === "true");
                $scope.isNotHcrPreEffecUser = !$scope.isHcrPreEffecUser;
                $scope.uiMods.theBlog.showThis = (data.highmarkBlogIndicator === 'true'); //showBlog
                $scope.uiMods.needHelp.showThis = (data.personalHealthCoachIndicator === 'true'); //show Need some help or advice module
                $scope.uiMods.preventiveSchedule.showThis = (data.preventiveScheduleIndicator === 'true');
                $scope.showProfileItem = (data.wellnessProfileIndicator === 'true');
                var showRewards = (data.wellnessRewardIndicator === 'true');
                if (showRewards && data.wellnessRewardType) {
                    $scope.rewardsItem.showThis = true;
                    $scope.rewardsItem.theProgramName = data.wellnessRewardType;
                }
                if ($scope.showProfileItem == false && $scope.rewardsItem.showThis == false) {
                    $scope.hideProfileAndReward = $scope.isWYBCBS ?  false : true;
                }

                $scope.showWellnessPrograms = (data.wellnessProgramsIndicator === 'true');
                $scope.showOverviewBanner = !$scope.showWellnessPrograms;
                $scope.wellnessProgramQualifiers = {
                    fitnessIndicator : (data.fitnessIndicator === 'true'),
                    incentiveFitnessIndicator : (data.incentiveFitnessIndicator === 'true'),
                    nurseAdviceLineIndicator : (data.nurseAdviceLineIndicator === 'true'),
                    employeeAssistanceIndicator : (data.employeeAssistanceIndicator === 'true'),
                    learnToLiveIndicator : (data.learnToLiveIndicator === 'true'),
                    chooseHealthyIndicator : (data.chooseHealthyIndicator === 'true'),
                    vacationsSavingIndicator : (data.vacationsSavingIndicator === 'true'),
                    wiserTogetherIndicator : (data.wiserTogetherIndicator  === 'true')
                };
                var wellnessPrds = finderLogic.portalSelectedPlanWellnessProduct();
                for(var ind =0; ind< wellnessPrds.length; ind ++){
                    if((wellnessPrds[ind].effectiveFromDate || wellnessPrds[ind].effectiveToDate) && wellnessPrds[ind].marketingStatusDescription== "Active"){
                        var today = Date.parse(new Date());
                        var effFromDate = Date.parse(wellnessPrds[ind].effectiveFromDate);
                        var dateArr = wellnessPrds[ind].effectiveToDate.split("-");
                        var furtureDate = new Date();//if effectiveToDate not available then set it to future date
                        var effectiveToDate = dateArr.length>1? Date.parse(dateArr[1]+"/"+dateArr[2]+"/"+dateArr[0]): furtureDate.setDate(furtureDate.getDate() +1);
                        if (effFromDate < today && effectiveToDate > today){
                            switch (wellnessPrds[ind].productShortDescription){
                                case "Employee Assistance Program-Core":
                                    $scope.wellnessProgramQualifiers.employeeAssitanceCore= true;
                                    break;
                                case "Employee Assistance Program-Enhanced 3 Visits":
                                    $scope.wellnessProgramQualifiers.eapEnhanced3= true;
                                    break;
                                case "Employee Assistance Program-Enhanced 4 Visits":
                                    $scope.wellnessProgramQualifiers.eapEnhanced4= true;
                                    break;
                                case "Employee Assistance Program-Enhanced 5 Visits":
                                    $scope.wellnessProgramQualifiers.eapEnhanced5= true;
                                    break;
                                case "Employee Assistance Program-Enhanced 6 Visits":
                                    $scope.wellnessProgramQualifiers.eapEnhanced6= true;
                                    break;
                                case "Employee Assistance Program-Enhanced 8 Visits":
                                    $scope.wellnessProgramQualifiers.eapEnhanced8= true;
                                    break;
                                case "Employee Assistance Program-Enhanced 10 Visits":
                                    $scope.wellnessProgramQualifiers.eapEnhanced10= true;
                                    break;
                                case "Employee Assistance Program-Enhanced 12 Visits":
                                    $scope.wellnessProgramQualifiers.eapEnhanced12= true;
                                    break;
                            }
                        }
                    }

                }


                $scope.isQualifiersLoaded = true;
            };

            // set member properties ===============================================================================
            var sd = session.data;
            groupNumber = sd.groupNumber;
            if (sd.memberInfo && sd.memberInfo.clientList && sd.memberInfo.clientList[0] && sd.memberInfo.clientList[0].clientNumber) {
                clientNumber = sd.memberInfo.clientList[0].clientNumber;
            }
            if (sd.memberInfo && sd.memberInfo.brand) {
                theBrand = sd.memberInfo.brand;
            }
            $scope.glb = {
                clientNumber: clientNumber,
                groupNumber: groupNumber,
                theBrand: theBrand
                };
            $scope.uiIcons = {};

            // GET WELLNESS SCORE ================================================================================
            $scope.hasScore = false;
            $scope.noScore = true;
            if((theBrand.toUpperCase() == "NDBCBS") || (theBrand.toUpperCase() == "WYBCBS")){
                var theScore = wellnessLogic.getWellnessScore();
                theScore.then(function(theResults) {
                    if (theResults && theResults.data && theResults.data.payload && theResults.data.payload.wellnessProfileScore) {
                        $scope.wellnessScore = theResults.data.payload.wellnessProfileScore;
                        $scope.hasScore = true;
                        $scope.noScore = false;
                    } else {
                        $scope.hasScore = false;
                        $scope.noScore = true;
                    }
                });
            }

            // RECOMMENDATIONS FOR YOU ========= getWebmdRecommendations ============================================
            //      see the directive files at directives/ wellnessRecommended.js and .html

            var useSameWindowForSSO = function (theTargetType){
                var arrSameWindow = ['WELLNESS_REWARDS', 'WELLNESS_PERSONAL_HEALTH_RECORD', 'WELLNESS_HEALTH_TRACKER', 'WELLNESS_HEALTH_ASSISTANT', 'WELLNESS_SYMPTOM_CHECKER', 'WELLNESS_PROFILE'];
                return (arrSameWindow.indexOf(theTargetType)>-1);
            };

            $scope.gblVars = {};
            $scope.gblVars.cmKeyValue = null;
            $scope.funcs = {};
            $scope.funcs.handleRewardsClick = function(theRewardsType) {
                if (theRewardsType) {
                    switch (theRewardsType) {
                        case 'Webmd Rewards':
                            $scope.funcs.hitSsoService('WELLNESS_REWARDS');
                            break;
                        case 'WellTok':
                            $scope.funcs.hitSsoService('WELLNESS_REWARDS');
                            break;
                        case 'Highmark Passport':
                            modal.open({
                                           template: require('modals/passportModal.html'),
                                           controller: myPassportModal,
                                           scope: $scope
                                       });
                            break;
                        default:
                            throw new Error('A value for theRewardsType was sent to handleRewardsClick which we dont recognize.');
                            return;
                    }
                } else {
                    throw new Error('There isnt a value for theRewardsType sent to the handleRewardsClick method.');
                    return;
                }
            };
            // HIT SSO Service ====================================================================================
            $scope.funcs.hitSsoService = function (theTargetType) {
                if (!theTargetType) {
                    throw new Error('The hitSsoService was not sent a theTargetType parameter');
                    return;
                }
                switch (theTargetType) {
                    // the value we use for Site Promotion querystring gets put on the $scope here
                    //           we use that value in handleSsoResponse
                    case 'WELLNESS_PROFILE':
                        $scope.gblVars.cmKeyValue = '?cm_sp=WebMD-_-Wellness%20Profile-_-Wellness%20Profile';
                        break;
                    case 'WELLNESS_REWARDS':
                        if ($scope.rewardsItem.theProgramName == 'Webmd Rewards') {
                            $scope.gblVars.cmKeyValue = '?cm_sp=WebMD-_-Rewards%20Program%20WebMD-_-Rewards%20Program';
                        }
                        if ($scope.rewardsItem.theProgramName == 'WellTok') {
                            $scope.gblVars.cmKeyValue = '?cm_sp=Welltok-_-Rewards%20Program%20Welltok-_-Rewards%20Program';
                        }
                        break;
                    case 'WELLNESS_PERSONAL_HEALTH_RECORD':
                        $scope.gblVars.cmKeyValue = '?cm_sp=WebMD-_-Personal%20Health%20Record-_-Personal%20Health%20Record';
                        break;
                    case 'WELLNESS_HEALTH_TRACKER':
                        $scope.gblVars.cmKeyValue = '?cm_sp=WebMD-_-Health%20Tracker-_-Health%20Tracker';
                        break;
                    case 'WELLNESS_HEALTH_ASSISTANT':
                        $scope.gblVars.cmKeyValue = '?cm_sp=WebMD-_-Health%20Assistant-_-Health%20Assistant';
                        break;
                    case 'WELLNESS_SYMPTOM_CHECKER':
                        $scope.gblVars.cmKeyValue = '?cm_sp=WebMD-_-Symptom%20Checker-_-Symptom%20Checker';
                        break;
                    case 'WELLNESS_HEALTH_TOPICS':
                        $scope.gblVars.cmKeyValue = '?cm_sp=WebMD-_-Health%20Topics-_-Health%20Topics';
                        break;
                    default:
                        $scope.gblVars.cmKeyValue = null;
                        throw new Error('The value for theTargetType sent to hitSsoService is not one that we recognize.');
                }

                //Temporary Solution: Call HMSSO Federation logout to clear cookies before going to WEBMD.
                triggerFederationLogout();

                var returnUrl = "/login/#/wellness/wellnessOverview";
                var returnPage = "Wellness";
                var myCmSitePromotion = ($scope.gblVars.cmKeyValue) ? $scope.gblVars.cmKeyValue : '';
                var isSameWindow = useSameWindowForSSO(theTargetType);
                var ssoParameters = {
                    clientNumber: clientNumber,
                    groupNumber: groupNumber,
                    targetType: theTargetType,
                    category: 'WELLNESS',
                    returnUrl: returnUrl,
                    returnPage: returnPage,
                    brand: theBrand.toUpperCase()
                };
                vendorSSOUtility.performSSO(ssoParameters, (isSameWindow ? '_self' : '_target'), myCmSitePromotion, !isSameWindow);
            };

            var triggerFederationLogout = function() {
                // Temporary Solution: Load the IFrame with the HMSSO Federation logout URL to clear the cookies on the hmsso.highmark domain.
                var domain = $window.location.hostname;
                if (domain.split('.')[0] === "www") {
                    $scope.hmSsoLogoutURL = $sce.trustAsResourceUrl('https://hmsso.highmark.com/fed/user/logout');
                } else {
                    $scope.hmSsoLogoutURL = $sce.trustAsResourceUrl('https://hmssotest.highmark.com/fed/user/logout');
                }
            };

            $scope.cmElementTagClick = function(tagDesc) {
                switch (tagDesc) {
                    case 'aBlogItem':
                        coremetrics.elementTag('Wellness blog feed', 'Member: Wellness');
                        break;
                    case 'goToBlog':
                        coremetrics.elementTag('Wellness blog', 'Member: Wellness');
                        break;
                    case 'passportRewards':
                        coremetrics.elementTag('Earn Passport Rewards Modal Link', 'Member: Wellness');
                        break;
                    case 'wellnessSearch':
                        coremetrics.elementTag('Wellness Search', 'Member: Wellness');
                        break;
                    case 'preventiveSchedule':
                        coremetrics.elementTag('Preventive Schedule', 'Member: Wellness');
                        break;
                    case 'aRecommendedArticle':
                        coremetrics.elementTag('WebMD', 'Recommended For You');
                        break;
                    case 'dppDetails':
                        coremetrics.elementTag('WELLNESS DPP LINK','MEMBER:WELLNESS:DIABETES PREVENTION PROGRAM');
                        break;
                    default:
                        throw new Error('The coremetrics element tag handler, cmElementTagClick, was passed a value it did not expect.');
                }
            };
             $scope.isWellnessSupressClient = false;
             teamsite.get('MBR_CustomClient_SupressWellnes').then(function(data){
                var suppressClientList = JSON.parse(data) || [];
                var mbrClientNumber = $cookies.get("mbrClientNumber");
                if(suppressClientList.indexOf(mbrClientNumber) > -1){
                    $scope.isWellnessSupressClient = true;
                }
             });
            $scope.wellnessProgramsFilter = function(program) {
                if (program.qualifier && program.qualifier.length > 0) {
                    return ($scope.wellnessProgramQualifiers[program.qualifier] !== undefined && $scope.wellnessProgramQualifiers[program.qualifier] !== false);
                }
                if($scope.isMINCR && $scope.isWellnessSupressClient && program.metricsId === 'Quitting Tobacco Support'){
                    return false;
                }
                return true;
            };

            $scope.cmWellnessProgramClick = function(metricsId) {
                if (metricsId && metricsId !== '') {
                    coremetrics.elementTag(metricsId, 'MEMBER:WELLNESS');
                }
            };

            // next line is the end of the controller code =========================================================

/*SHARECARE SOFT LAUNCH*/
            var targetTypes = wellnessUtility.wellnessTargetTypes,
                sessionData = wellnessUtility.wellnessSessionInfo,
                category = wellnessUtility.wellnessCategory,
                returnUrl = "/login/#/wellness",
                returnPage = "Wellness";
            var firstPathSegment = (window.location.href.indexOf('denv')>-1) ? 'hom' : 'login';

            $scope.hmSsoLogoutURL = '';
            $scope.defaultHref = "/" + firstPathSegment + "/#/wellness";
            $scope.adamContentUrlStr = wellnessUtility.wellnessAdamContentUrlStr;
            $scope.isAlbertsonsMember = false;

            if(session.data.memberInfo.clientList[0].clientNumber == "255721"){
                $scope.isAlbertsonsMember = true;
                teamsite.setQualifier('CustomClientID', "255721");
                teamsite.get('MBR_CustomClient_ArticleTwo').then(function(data){
                    $scope.wellnessConnected = JSON.parse(data);

                });
            }




            $scope.showDefaultHero = !$scope.isMINCR;
            wellnessUtility.getQualifierServiceResults().then(
                function (data){
                    processQualifiersAdd(data);
                },
                function(error) {
                    processQualifiersAdd();
                }
            );

            var processQualifiersAdd = function(data) {
                data = data || {};
                $scope.showAdvCarePdf = (data.advanceCarePdfIndicator === "true");
                $scope.isHcrPreEffecUser = (data.isHCRPreEffectuated === "true");
                $scope.isNotHcrPreEffecUser = !$scope.isHcrPreEffecUser;
                $scope.showHMBlogLink = (data.highmarkBlogIndicator === "true");
                $scope.showMaternityResources = (data.maternityResourcesIndicator === "true");
                $scope.showPBSHealthShow = (data.pbsHealthShowIndicator === "true");
                if($scope.isAlbertsonsMember){
                    $scope.showPBSHealthShow=false;
                }
                $scope.showTreatmentTools = (data.wiserTogetherIndicator === "true");
            };

            $scope.showAdvCarePdfDownloadIcon = true;
            $scope.noIconClass = "";
            if ($scope.isMINCR) {
                $scope.showAdvCarePdfDownloadIcon = false;
                $scope.noIconClass = "noIconLeftPad";
            }

            /* Check with Frank for a better approach?*/

            teamsite.get('MBR_WELLNESS_ADVANCED_DIRECTIVE_HEADER').then(function (data){$scope.adLinkText = data;});
            teamsite.get('MBR_WELLNESS_ADVANCED_DIRECTIVE_LINK').then(function (data){$scope.adLinkHref = data;});
            teamsite.get('MBR_WELLNESS_WATCH_EPS').then(function (data){$scope.watchEpsText = data;});
            teamsite.get('MBR_WELLNESS_WATCH_EPS_LINK').then(function (data){$scope.watchEpsHref = data;});
            //adam content url for careguide and bodyguide
            teamsite.get('MBR_WELLNESS_CAREGUIDE_LINK').then(function (data){$scope.careGuideLink = data;});
            teamsite.get('MBR_WELLNESS_BODYGUIDE_LINK').then(function (data){$scope.bodyGuideLink = data;});
            $scope.healthTopicsHandler = function(){
                triggerFederationLogout(); //Temporary Solution: Call HMSSO Federation logout to clear cookies before going to WEBMD.

                var sitePromotionStr = '?cm_sp=WebMD-_-Health%20Topics-_-Health%20Topics';
                var ssoParameters = {
                    clientNumber: sessionData.clientNumber,
                    groupNumber: sessionData.groupNumber,
                    targetType: targetTypes.healthTopics,
                    category: category,
                    returnUrl: returnUrl,
                    returnPage: returnPage,
                    brand: sessionData.brand
                };
                vendorSSOUtility.performSSO(ssoParameters, "_self", sitePromotionStr);
            };
            $scope.amedaHandler = function() {
                modal.open({
                    template: require('modals/maternityAmeda.html'),
                    controller: amedaModal,
                    scope: $scope
                });
            }
            $scope.medelaHandler = function() {
                modal.open({
                    template: require('modals/maternityMedela.html'),
                    controller: medelaModal,
                    scope: $scope
                });
            }
            $scope.medEncyclopediaHandler = function(){
                triggerFederationLogout(); //Temporary Solution: Call HMSSO Federation logout to clear cookies before going to WEBMD.

                var sitePromotionStr = 'cm_sp=WebMD-_-Medical%20Encyclopedia-_-Medical%20Encyclopedia';
                var ssoParameters = {
                    clientNumber: sessionData.clientNumber,
                    groupNumber: sessionData.groupNumber,
                    targetType: targetTypes.medEncyclopedia,
                    category: category,
                    returnUrl: returnUrl,
                    returnPage: returnPage,
                    brand: sessionData.brand
                };
                vendorSSOUtility.performSSO(ssoParameters, "_self", sitePromotionStr);
            };

            $scope.advCareHandler = function(){
                coremetrics.elementTag('ADVANCE CARE','MEMBER:WELLNESS');
            };

            $scope.advDirectiveHandler = function(){
                var urlHref = $scope.adLinkHref;
                var urlAppend = urlHref.indexOf("?") > -1?"&":"?";
                var sitePromotionStr = 'cm_sp=CaringInfo-_-Advance%20Directive-_-Advance%20Directive';
                $scope.adLinkHref = $scope.adLinkHref + urlAppend + sitePromotionStr;

            };

            $scope.learnMoreCoremetrics = function(){
                coremetrics.elementTag('Learn more about it on our blog','MEMBER:WELLNESS');
            };

            $scope.reimbursementFormClick = function(theTarget) {
                coremetrics.elementTag('REIMBURSEMENT FORM', 'MEMBER:WELLNESS');
                var urlBase = "https://" + window.location.host;
                var theUrl = urlBase + "/" + theTarget;
                window.open(theUrl, "_blank");
            }

            $scope.pbsTVSecondOpinionHandler = function(){
                var urlHref = $scope.watchEpsHref;
                var urlAppend = urlHref.indexOf("?") > -1?"&":"?";
                var sitePromotionStr = 'cm_sp=BCBS-_-Watch%20an%20episode-_-Watch%20an%20episode';
                $scope.watchEpsHref = $scope.watchEpsHref + urlAppend + sitePromotionStr;
            };

            $scope.webMdInteractivesHandler = function(){
                triggerFederationLogout(); //Temporary Solution: Call HMSSO Federation logout to clear cookies before going to WEBMD.

                var sitePromotionStr = 'cm_sp=WebMD-_-WebMD%20Interactives-_-WebMD%20Interactives';
                var ssoParameters = {
                    clientNumber: sessionData.clientNumber,
                    groupNumber: sessionData.groupNumber,
                    targetType: targetTypes.interactives,
                    category: category,
                    returnUrl: returnUrl,
                    returnPage: returnPage,
                    brand: sessionData.brand
                };
                vendorSSOUtility.performSSO(ssoParameters, "_self", sitePromotionStr);
            };

            $scope.careGuideHandler = function(){
                coremetrics.elementTag('CARE GUIDE','MEMBER:WELLNESS');
            };

            $scope.insideHumanBodyHandler = function(){
                coremetrics.elementTag('BODY GUIDE','MEMBER:WELLNESS');
            };

            $scope.weighTodayHandler = function(){
                triggerFederationLogout(); //Temporary Solution: Call HMSSO Federation logout to clear cookies before going to WEBMD.

                var sitePromotionStr = 'cm_sp=WebMD-_-Weigh%20Today-_-Weigh%20Today';
                var ssoParameters = {
                    clientNumber: sessionData.clientNumber,
                    groupNumber: sessionData.groupNumber,
                    targetType: targetTypes.weighToday,
                    category: category,
                    returnUrl: returnUrl,
                    returnPage: returnPage,
                    brand: sessionData.brand
                };
                vendorSSOUtility.performSSO(ssoParameters, "_self", sitePromotionStr);
            };

            $scope.dailyVictoryHandler = function(){
                triggerFederationLogout(); //Temporary Solution: Call HMSSO Federation logout to clear cookies before going to WEBMD.

                var sitePromotionStr = 'cm_sp=WebMD-_-Daily%20Victory-_-Daily%20Victory';
                var ssoParameters = {
                    clientNumber: sessionData.clientNumber,
                    groupNumber: sessionData.groupNumber,
                    targetType: targetTypes.dailyVitory,
                    category: category,
                    returnUrl: returnUrl,
                    returnPage: returnPage,
                    brand: sessionData.brand
                };
                vendorSSOUtility.performSSO(ssoParameters, "_self", sitePromotionStr);
            };

            var triggerFederationLogout = function() {
                // Temporary Solution: Load the IFrame with the HMSSO Federation logout URL to clear the cookies on the hmsso.highmark domain.
                var domain = $window.location.hostname;
                if (domain.split('.')[0] === "www") {
                    $scope.hmSsoLogoutURL = $sce.trustAsResourceUrl('https://hmsso.highmark.com/fed/user/logout');
                } else {
                    $scope.hmSsoLogoutURL = $sce.trustAsResourceUrl('https://hmssotest.highmark.com/fed/user/logout');
                }
            };
        }
    ]);
};
