module.exports = function(app) {
     var ssModal = require("modals/smartShopperModal");
  app.directive('espotSection', function() {
    return {
      template: require('directives/landing/espotSection.html'),
      controller: ['$scope', '$cookies', 'services', 'sessionData', 'finder-logic2', 'modal', 'brand', 'cceService', 'ssoHelper', 'coremetrics',
      function($scope, $cookies, services, sessionData, finderLogic, modal, brand, cceService, ssoHelper, coremetrics) {
 

      var getsSavingsAdvisor = false;
      var getsPassportReward = false;
      var isInPrimaryRegion = false;
  

      var thisBrand = sessionData.memberInfo.brand.toLowerCase();
      var sharedBrandCode = brand.current.sharedBrandCode;
      var glbArrWvBrandCodes = ["hmbcbswv", "ms", "highmarkbcbswv", "mbh:wv"];
      var isHHIC = (glbArrWvBrandCodes.indexOf(sharedBrandCode) > -1);
      var getTheDomain = function() {
        var fullDomainName = finderLogic.theDomain();
        var domainSplitArr = fullDomainName.split('.');
        domainSplitArr.shift();
        return domainSplitArr.join('.');
      };
      var theRegion = getTheDomain();
      var theseRegions = (theRegion == 'highmarkbcbs.com' || theRegion == 'highmarkbcbsde.com' || theRegion == 'highmarkblueshield.com' || theRegion == 'highmarkbcbswv.com' );
      var theseBrands = (thisBrand == 'hmbcbs' || thisBrand == 'hmbcbsde' || thisBrand == 'hmbs' || thisBrand == 'hmbcbswv');
      var theProductList = finderLogic.portalSelectedPlanActiveProducts();


      var hasThisPlanType = function(theType){
        var hasIt = -1;
        var theProductList = getCurrentProducts();
        if (theProductList) {
          for (var i = 0; i < theProductList.length; i++) {
            if (theProductList[i].benefitPlanName == theType) {
              hasIt = i;
            }
          }
        }
        return hasIt;
      };

      var hasThisPlanTypeIgnoreCase = function(theType){
        var hasIt = -1;
        if (getCurrentProducts()) {
          var theProductList = getCurrentProducts();
          for (var i = 0; i < theProductList.length; i++) {
            if ( (theProductList[i].benefitPlanName.replace(" ","")).toUpperCase() == (theType.replace(" ","")).toUpperCase() ) {
                hasIt = i;
            }
          }
        }
        return hasIt;
      };

      var hasThisBenefitPlanType= function(theType) {
        // This is a copy of hasThisPlanType, but swapped benefitPlanName check for benefitPlanType
        var hasIt = -1;
        if(theProductList){
          for (var i = 0; i < theProductList.length; i++) {
            if(theProductList[i].benefitPlanType === theType){
            hasIt = i;
            }
          } 
        }
        return hasIt;
      };

      var getCurrentProducts= function() {
        var currentPlans = [];
        var portalSelectedPlan =  finderLogic.getPortalSelectedPlan();
        var millisNow =  Date.parse(new Date());
        if (portalSelectedPlan && portalSelectedPlan.productList && portalSelectedPlan.productList.length > 0) {
          var the_prods = portalSelectedPlan.productList;
        } else {
            return currentPlans;
        }
        for (var i = 0; i < the_prods.length; i++) {
          var millisBeginDate = Date.parse(the_prods[i].coverageBeginDate);
          var millisEndDate = Date.parse(the_prods[i].coverageEndDate);
          if ((millisBeginDate < millisNow) && (millisNow < millisEndDate)) {
            currentPlans.push(the_prods[i])
          }
        }
        return currentPlans;
      };

      var isBlueExtraBasic = function() {
        var blueExtraBasicList = [];              
        for (var i = 0; i < theProductList.length; i++) {
          blueExtraBasicList.push(theProductList[i].isBlueExtraBasic);                    
        }
        var blueExtraBasic = (blueExtraBasicList.indexOf('true') > -1);
        return blueExtraBasic;              
      };

      var isBlueEdgeDental = function() {
        var hasBlueEdgeDentalList = [];
        for (var i = 0; i < theProductList.length; i++) {
          hasBlueEdgeDentalList.push(theProductList[i].dentalCoverage);
        }
        return (hasBlueEdgeDentalList.indexOf('true') > -1);
      };
  
      var getIDTheftVendor = function(){
        var idTheftVendorList = [];
        var theClientList = sessionData.memberInfo.clientList;
        
        for (var i = 0; i < theClientList.length; i++) {
          for (var j = 0; j < theClientList[i].groupList.length; j++) {
            idTheftVendorList.push(theClientList[i].groupList[j].idTheftProtectionVendor)
          }
        }
        
        for(var k = 0; k < idTheftVendorList.length; k++) {
          if (idTheftVendorList[k] === null || idTheftVendorList[k] === false || idTheftVendorList[k] === "" || idTheftVendorList[k] === "false") {
            idTheftVendorList.splice[k, 1];
          }
        }
        
        if (idTheftVendorList.length > 0) {
          return idTheftVendorList;
        }
        return false;
      };

      var getsPreventiveCare = function() {
        
        var preventiveScheduleQualifierList = [];

        for (var i = 0; i < theProductList.length; i++) {
          preventiveScheduleQualifierList.push(theProductList[i].benefitPlanType);
        }
        var preventiveScheduleQualifier = (preventiveScheduleQualifierList.indexOf('Medical') > -1);
        return (preventiveScheduleQualifier && !isBlueEdgeDental());
      };

      var getsPreventiveCareURLbyBrand= function(){
        var preventiveCareURL;              
        if(theRegion == 'highmarkbcbs.com'){
          preventiveCareURL ="/pdffiles/PreventiveSchedule_HMK.pdf";
        } else if(theRegion == 'highmarkblueshield.com'){
          preventiveCareURL ="/pdffiles/PreventiveSchedule_HMK.pdf";
        } else if(theRegion == 'highmarkbcbsde.com'){
          preventiveCareURL ="/pdffiles/PreventiveSchedule_DE.pdf";
        } else if(theRegion == 'highmarkbcbswv.com'){
          preventiveCareURL ="/pdffiles/PreventiveSchedules_MS.pdf";
        } else if(isMbhMember()){
          preventiveCareURL ="/pdffiles/PreventiveSchedule_MBH.pdf";
        } else {
          preventiveCareURL ="/pdffiles/PreventiveSchedule_MBH.pdf";
        }
        return preventiveCareURL;
      };
  
      var getsWellnessProfile= function() {

        var theseRegions = (theRegion == 'highmarkbcbs.com' || theRegion == 'highmarkbcbsde.com' ||
        theRegion == 'highmarkblueshield.com' || theRegion == 'highmarkbcbswv.com' );
        var brandState = sharedBrandCode;
        var arrayStateCodes = ['hmbcbswv', 'ms', 'highmarkbcbswv', 'mbh:wv', 'hhic', 'az', 'fl', 'mbh:fl', 'exc', 'lou', 'neb', 'regbcbs', 'regbs', 'wi','mn', 'mncr', 'idh', 'la'];
        var isMbhRegn = (arrayStateCodes.indexOf(brandState) > -1);
        
        var hasWellnessQualifier = (hasThisBenefitPlanType('Wellness Programs') > -1);
        var blueExtraBasic = isBlueExtraBasic();
        var currentMemberInfo = finderLogic.setLibStorageAndReturnCurMember();
        var isAlbertsonMember = (brandState==="idh") && (currentMemberInfo.clientNumber ==="255721");
        return (theseRegions || isMbhRegn) && hasWellnessQualifier && !blueExtraBasic && !isHHIC && !isAlbertsonMember;
      };

      var isWellnessRewards = function() {
        //theRegion is the current domain minus the www.  it's the return value of the getTheDomain function
        var theseRegions = (theRegion === 'highmarkbcbs.com' || theRegion === 'highmarkbcbsde.com' || theRegion === 'highmarkblueshield.com' || theRegion === 'highmarkbcbswv.com' );
        var isNationalAllianceMember = ((sharedBrandCode == 'ibc') || (sharedBrandCode == 'mn'));
        var arrayStateCodes = ['hmbcbs', 'hbcbs', 'highmarkbcbs', 'hbs', 'pbs', 'hmbs', 'hmbcbswv', 'ms', 'highmarkbcbswv', 'mbh:wv', 'hhic', 'az', 'fl','mbh:fl', 'exc', 'lou', 'neb', 'regbcbs', 'regbs', 'wi', 'mn', 'mincr', 'idh', 'la'];
        var isMemberDiscountsRegion = (arrayStateCodes.indexOf(sharedBrandCode) > -1);
        var rewardsProgramRegion = (isHHIC || theseRegions || isMemberDiscountsRegion || isNationalAllianceMember);
        var hasWebMdRewardsList = (hasThisPlanType('Service - WebMD Rewards') > -1);
        return rewardsProgramRegion && hasWebMdRewardsList && !isBlueExtraBasic() && !isHHIC;
      };
     
      var hasSeniorProduct = function() {
        var hasIt = false;
          if(finderLogic.hasSeniorProduct()){
          hasIt = true;
        }

        return hasIt;
      };

      var isMbhMember= function() {
        // Plan Partners: LA, AZ, FL, IBC National
        var brandState = sharedBrandCode;
        var arrayStateCodes = ['az', 'fl', 'mbh:fl', 'lou', 'ibc', 'neb', 'idh', 'la'];
        var isMbhRegion = (arrayStateCodes.indexOf(brandState) > -1);
        console.log(isMbhRegion);
        return isMbhRegion;
      };

      var isAgOnlyMember= function() {
        var portalSelectedPlan = finderLogic.getPortalSelectedPlan();
        var ps = portalSelectedPlan.productList;
        var isAgOnly = true;
        for (var i = 0; i < ps.length; i++) {
          isAgOnly = (ps[i].lob != 'AG') ? false : isAgOnly;
        }
        return isAgOnly;
      };

      var isDeMember= function() {
        var arrDe = ["hmbcbsde", "highmarkbcbsde", "de"];
        var isDe = (arrDe.indexOf(sharedBrandCode) > -1);
        return isDe;
      };

      var isWvMember= function() {
        var arrWV = ["hmbcbswv", "ms", "highmarkbcbswv", "mbh:wv"];
        var brandCode = sharedBrandCode;
        var isWV = (arrWV.indexOf(brandCode) > -1);
        return isWV;
      };

      var isTraditionalPlanMember= function(){
        var portalSelectedPlan = finderLogic.getPortalSelectedPlan();
          var ps = portalSelectedPlan.productList;
          var isTraditionalPlan = true;
          for (var i = 0; i < ps.length; i++) {
              isTraditionalPlan = (ps[i].lob != 'TDM') ? false : isTraditionalPlan;
          }
          return isTraditionalPlan;
      };

      var hasFullyInsured = function() {
        var hasIt = false;
        var portalSelectedPlan = finderLogic.getPortalSelectedPlan();
        var theProductList = portalSelectedPlan.productList;
        if (theProductList) {  
          for (var i = 0; i < theProductList.length; i++) {
            var theValue = theProductList[i].hcrProduct;
            hasIt = (( theValue === false) || (theValue === 'false')) ? true : hasIt;
          }
        }
        return hasIt;
      };

      var getsCareNavigatorSpot = function() {
        var hmRegions = (
        theRegion === 'highmarkbcbs.com' ||
        theRegion === 'highmarkblueshield.com' ||
        theRegion === 'highmarkbcbswv.com' ||
        theRegion === 'highmarkbcbsde.com'
        );
        var isDEMember = isDeMember() ;
        var isMbh = isMbhMember();
        var brandState = sharedBrandCode;
        var arrayStateCodes = ['hmbcbswv', 'ms', 'highmarkbcbswv', 'mbh:wv', 'hhic', 'az', 'fl', 'mbh:fl', 'exc', 'lou', 'neb', 'regbcbs', 'regbs', 'wi','mn', 'mncr', 'idh', 'la'];
        var isMbhRegn = (arrayStateCodes.indexOf(brandState) > -1);
        var myCareProgramRegion = (hmRegions && !isHHIC && !isMbh && !isMbhRegn);
        var hasSeniorProduct = finderLogic.hasSeniorProduct();
        var isfullyinsured = false;
        if(isDEMember){
            isfullyinsured = hasFullyInsured();
        } else {
            isfullyinsured = true;
        }
        var hasMyCarePlanType = hasThisPlanType("Service - myCare Navigator") > -1;
        return myCareProgramRegion && !hasSeniorProduct && !isHHIC && isfullyinsured && hasMyCarePlanType;
      };

      var getsCCE= function() {
        var selectedPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan();
        var isCCE = selectedPlan.hasCareCostEstimator && selectedPlan.hasCareCostEstimator === "true";      
        return isCCE;
      };

      var getsCcoeIndicator= function() {
        var currentPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan(sessionData.memberInfo);
        var isCCOE = currentPlan.ccoeIndicator === true || currentPlan.ccoeIndicator === "true";
        return isCCOE;
      };

      var getsSavingsAdvisor= function() {
        var isCHC = (finderLogic.getPortalSelectedPlan().chcIndicator === "true");
        return isCHC;
      };

      var getsPassportRewards=function(){
        var getsIt = false;
        var theseRegions = (theRegion == 'highmarkbcbs.com' || theRegion == 'highmarkbcbsde.com' || theRegion == 'highmarkblueshield.com' || theRegion == 'highmarkbcbswv.com' );
        var isInIncludedPlanName = false;
        var arrPlanNames = ['FREEDOMBLUE','FREEDOMBLUE PPO','FREEDOM BLUE PPO','SECURITY BLUE','SECURITY BLUE HMO','Medigap Blue','Medigap Blue Plan A','Medigap Blue Plan B','Medigap Blue Plan C','Medigap Blue Plan D','Medigap Blue Plan E','Medigap Blue Plan F'];
        for (var i = 0; i < arrPlanNames.length; i++) {
            isInIncludedPlanName = (hasThisPlanTypeIgnoreCase(arrPlanNames[i]) > -1 ) ? true : isInIncludedPlanName;
        }
        arrPlanNames = ['PPO', 'EPO','Medicare Blue RX','PPO BLUE','EPO BLUE']; // do not show
        var isNotInIncludedPlanName = false;
        for (var j = 0; j < arrPlanNames.length; j++) {
            isNotInIncludedPlanName = (hasThisPlanTypeIgnoreCase(arrPlanNames[j]) > -1 );
        }
        var passportRewardRegn = theseRegions ||  isDeMember() || isWvMember();
        if( passportRewardRegn && isInIncludedPlanName && !(isNotInIncludedPlanName) && !isAgOnlyMember() && !isTraditionalPlanMember() ) {
            getsIt = true;
        }
        getsPassportReward = getsIt;
        return getsIt;
      };

      var getsEPassportApp= function() {
        var isHCR = false;
        var isEligibleRegion = (theRegion == 'highmarkbcbs.com' || theRegion == 'highmarkbcbsde.com' || theRegion == 'highmarkblueshield.com' || theRegion == 'highmarkbcbswv.com' );
        
        if (theProductList) {
          
          for (var i = 0; i < theProductList.length; i++) {
          isHCR = Boolean(theProductList[i].hcrProduct);
            if (isHCR) { break; }
          }
        }
        var isMedicareAdvantage = (theProductList.medicareIndicator === 'true' && theProductList.medigapIndicator === 'false');
        return ( !isMbhMember() && !isHHIC && isEligibleRegion && (isHCR || isMedicareAdvantage) );
      };

      var vitalsSmartshopper = finderLogic.getPortalSelectedPlan().vitalsSmartShopper;
      var popSmartShopper = function(){
        modal.open({
            template: require('modals/smartShopperModal.html'),
            controller: ssModal,
            scope: $scope
        });
      };

       // for(var spotIndex = 0; spotIndex < spots.length; spotIndex++) {
       //     //the Espot sections need to be numbered left to right, top to bottom starting at 1
       //     spots[spotIndex].realEstateVersion = window.location.hostname.split('.')[1];
       //     spots[spotIndex].realEstateSection = 'section 1';
       //     spots[spotIndex].realEstateLink = 'Espot '+(spotIndex+1);
       // }

      $scope.clickEvent = function(){
        if(vitalsSmartshopper){
          coremetrics.pageviewTag('MEMBER:MEMBER HOME:VITAL SMARTSHOPPER MODAL','MEMBER:MEMBER HOME');
          popSmartShopper();
        }else if(enableD20DMS && ltpa && brand){
          coremetrics.elementTag('DR MATCH QUIZ LINK','MEMBER:MEMBER HOME');
        }
        // else if(sharedBrandCode === 'wybcbs' && $scope.showThis3 = true){
        //   var rePageArea = document.getElementsByTagName('html')[0].className.trim();
          // var s="-_-";
        //   var reLinkName = 'cm_re='+spot.realEstateVersion+s+
        //      rePageArea+'-'+spot.realEstateSection+s+spot.realEstateLink;
        //   var separator = spot.theTarget.indexOf('?') > -1 ? '&' : '?';
        //   var reHr = spot.theTarget+separator+reLinkName.replace(' ','%20');
        //   coremetrics.createManualLinkClickTag(reHr,reLinkName,'MEMBER:HOME PAGE');
        // }
      };


      //POSITION 1

      var ltpa = $cookies.get('LtpaToken2').toLowerCase();
      var umsBrandCookie = $cookies.get('umsBrand').toLowerCase();
      var enableD20DMS = !(sessionData && sessionData.appConfig && sessionData.appConfig.D20_DMS && sessionData.appConfig.D20_DMS.DISABLE_DMS && sessionData.appConfig.D20_DMS.DISABLE_DMS.booleanValue);
      var isMedicareMember = finderLogic.hasSeniorProduct() || finderLogic.isOver65();

      if(theseRegions && isMedicareMember && new Date(2017,9,31,13) > new Date()){ 
        $scope.espotImage1 = 'images/landing/espots/medicare_umbrella.png';
        $scope.espotTarget1 = 'https://www.highmarkpassportrewards.com/hmsurvey1';
        $scope.espotAltText1 = "Share your thoughts, get rewarded. Complete your survey for a FREE umbrella.";
        $scope.showThis1 = true;
      } else if(sharedBrandCode === 'ndbcbs') {
        $scope.espotImage1 = ' ';
        $scope.espotAltText1 = " ";
        $scope.showThis1 = false;
      } else if(thisBrand === 'wybcbs') {
        $scope.espotImage1 = 'images/landing/espots/write-a-review.png';
        $scope.espotTarget1 = '/login/#/finder';
        $scope.espotAltText1 = "Share your experience to help others find a great doctor.";
        $scope.showThis1 = true;
      } else if(enableD20DMS && ltpa && umsBrandCookie) {
        $scope.espotImage1 = 'images/landing/espots/DrMatchQuiz_espot.png';
        $scope.espotTarget1 = "https://www.drmatchquiz.com";
        $scope.espotAltText1 = "Doctor Match Quiz promotion";
        $scope.showThis1 = true;
      } else if (isWellnessRewards() && !isHHIC) {
        $scope.espotImage1 = 'images/landing/espots/WellnessRewards.png';
        $scope.espotTarget1 = '../chmptl/chm/jsp/webMDSingleSignOn.do?startId=384&hmLinkcodeValue=MBR_POST_LAND&hmLinkName=Member Home';
        $scope.espotAltText1= "Wellness Rewards Promotion";
        $scope.showThis1 = true;
      } else if (getsEPassportApp() ) {
        $scope.espotImage1 = 'images/landing/espots/ePassportApp.png';
        $scope.espotTarget1 = 'https://shop.highmark.com/medicare/index.html?producer=true#!/learn/passport-rewards ';
        $scope.espotAltText1 = "Download the ePassport app";
        $scope.showThis1 = true;
      } else {
          if((isMbhMember() && !isWvMember()) || isHHIC) { 
            $scope.espotImage1 = 'images/landing/espots/rateProviders.png';
            $scope.espotTarget1 = '/find-a-doctor/#/home'; 
            $scope.espotAltText1= "Provider Search Promotion";
            $scope.showThis1 = true;
            
          } else{
            $scope.espotImage1 = 'images/landing/espots/HM_blog_DIYHealth_espot.png';
            $scope.espotTarget1 = 'https://blog.highmarkhealth.org/tag/diy-health/';
            $scope.espotAltText1 = "DIY Health Promotion";
            $scope.showThis1 = true;
            
          }
      }

       //POSITION 2
       if (thisBrand === 'wybcbs') {
        $scope.espotImage2 = ' ';
        $scope.espotTarget2 = ' ';
        $scope.espotAltText2 = " ";
        $scope.showThis2 = false;
      } else if(sharedBrandCode === 'ndbcbs') {
        $scope.espotImage2 = ' ';
        $scope.espotTarget2 = ' ';
        $scope.espotAltText2 = " ";
        $scope.showThis2 = false;     
      } else if(getIDTheftVendor()){
        $scope.espotImage2 = 'images/landing/espots/ID_theft_02022016.png';
        $scope.espotTarget2 = 'https://highmark.allclearid.com/'; 
        $scope.espotAltText2 = "ID Theft Promotion";
        $scope.showThis2 = true;       
      } else if(getsPreventiveCare() && !isHHIC){
        $scope.espotImage2 = 'images/landing/espots/PreventiveCare.png';
        $scope.espotAltText2 = "Preventative Care Promotion";
        $scope.espotTarget2 = getsPreventiveCareURLbyBrand();
        $scope.showThis2 = true;
      } else {
          if(isHHIC){
            $scope.espotImage2 = 'images/landing/espots/highmarkBlog.png';
            $scope.espotTarget2 = 'https://blog.highmarkhealth.org';
            $scope.espotAltText2 = "Highmark Blog Promotion";
            $scope.showThis2 = true;
          }
          else if(!isMbhMember()) {
            $scope.espotImage2 = 'images/landing/espots/HM_blog_info_espot.png';
            $scope.espotTarget2 = 'https://blog.highmarkhealth.org/category/highmark-health-plans/';
            $scope.espotAltText2 = "Highmark Blog Promotion";
            $scope.showThis2 = true;
          } else{
            $scope.espotImage2 = 'images/landing/espots/WeighToday.png';
            $scope.espotTarget2 = '../chmptl/chm/jsp/webMDSingleSignOn.do?startId=8492827&hmLinkcodeValue=MBR_POST_LAND&hmLinkName=Member Home';
            $scope.espotAltText2 = "WebMD Promotion";
            $scope.showThis2 = true;
          }
      }

      //POSITION 3      

      if(vitalsSmartshopper){
        $scope.espotImage3 = 'images/landing/espots/smartshopper-espotcheck.jpg';
        $scope.espotAltText3 = "Vitals Smart shopper espot";
        $scope.showThis3 = true;
      } else if(isHHIC){
          if(getsPassportRewards()){
            $scope.espotImage3  = 'images/landing/espots/passportRewards.png';
            $scope.espotTarget3 = 'https://www.cafewell.com/code/highmarkpassport';
            $scope.espotAltText3 = "Password Rewards Promotion";
            $scope.showThis3 = true;
          }

        
      } else if (!isHHIC && getsSavingsAdvisor()) {
          $scope.espotImage3 = 'images/landing/espots/savingsAdvisor.png';
          $scope.espotTarget3 = ssoHelper.chcSsoCall();
          $scope.espotAltText3 = "Savings Advisor Promotion";
          $scope.showThis3 = true;
      } else if (sharedBrandCode === 'wybcbs') {
          $scope.espotImage3  = 'images/landing/espots/grant-authorization.png';
          $scope.espotTarget3 = 'https://www.bcbswy.com/docs/members/Authorization_to_Release_Information_Form_12_1_14.pdf';
          $scope.espotAltText3 = "Give permission for someone to view and manage your personal health information.";
          $scope.showThis3 = true;
      } else if (!isHHIC && getsCCE()) {
        $scope.espotImage3 = 'images/landing/espots/careCostEstimator.png';
        $scope.espotTarget3 = finderLogic.getCCEUrl();
        $scope.espotAltText3 = "Care Cost Estimator Promotion";
        $scope.showThis3 = true;
      } else if (sharedBrandCode === 'ndbcbs') {
        $scope.espotImage3 = ' ';
        $scope.espotTarget3 = ' ';
        $scope.espotAltText3 = " ";
        $scope.showThis3 = false;
      } else{
        if(!isMbhMember()){
            $scope.espotImage3 = 'images/landing/espots/TravelMedicalInsurance.png';
            $scope.espotTarget3 = 'http://discoverhighmark.com/individuals-families/insurance/travel-insurance';
            $scope.espotAltText3 = "Travel Insurance Promotion";
            $scope.showThis3 = true;
        }
      }

      //POSITION 4
       if(isHHIC){
          $scope.espotImage4 = 'images/landing/espots/TravelMedicalInsurance.png';
          $scope.espotTarget4 = 'http://discoverhighmark.com/individuals-families/insurance/travel-insurance';
          $scope.espotAltText4 = "Travel Insurance Promotion";
          $scope.showThis4 = true;
       } else if( !isMbhMember() && getsPassportRewards() ){
          $scope.espotImage4 = 'images/landing/espots/passportRewards.png';
          $scope.espotTarget4 = 'https://www.cafewell.com/code/highmarkpassport';
          $scope.espotAltText4 = "Passport Rewards Promotion";
          $scope.showThis4 = true;
       } else if (getsCcoeIndicator()){
          $scope.espotImage4 = 'images/landing/espots/centerOfExcellence.png';
          $scope.espotTarget4 = '/login/#/coverage/centers-of-excellence';
          $scope.espotAltText4 = "My Centers of Excellence";
          $scope.showThis4 = true;
       } 
       else if (!isMbhMember() &&  getsCareNavigatorSpot()) {
          $scope.espotImage4 = 'images/landing/espots/myCareNavigator.png';
          $scope.espotTarget4 = 'http://www.mycarenavigator.com';
          $scope.espotAltText4 = "My Care Navigator Promotion";
          $scope.showThis4 = true;
       }

       else {

         if (isMbhMember() &&  getsWellnessProfile()) {
            $scope.espotImage4 = 'images/landing/espots/DailyVictory.png';
            $scope.espotTarget4 = '../chmptl/chm/jsp/webMDSingleSignOn.do?startId=4000&hmLinkcodeValue=MBR_POST_LAND&hmLinkName=Member Home';
            $scope.espotAltText4 = "Daily Victory Promotion";
            $scope.showThis4 = true;
        } else if(sharedBrandCode === 'ndbcbs'){
            $scope.espotImage4 = '';
            $scope.espotTarget4 = '';
            $scope.espotAltText4 = "";
            $scope.showThis4 = false;
        } else if(sharedBrandCode === 'wybcbs'){
            $scope.espotImage4 = 'images/landing/espots/member-resources.png';
            $scope.espotTarget4 = '/login/#/coverage/health-insurance-101';
            $scope.espotAltText4 = "Find forms, tips and terms";
            $scope.showThis4 = true;
        } else if(isMbhMember()  && !getsWellnessProfile()){
            $scope.espotImage4 = '';
            $scope.espotTarget4 = '';
            $scope.espotAltText4 = "";
            $scope.showThis4 = false;
        } else{
            $scope.espotImage4 = 'images/landing/espots/highmarkBlog.png';
            $scope.espotTarget4 = 'https://blog.highmarkhealth.org';
            $scope.espotAltText4 = "Highmark Blog Promotion";
            $scope.showThis4 = true;
        }
      }
    }
              
   ]
  }; 
});
}













     






