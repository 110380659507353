module.exports = function(app) {

    app.controller('pages.secureRegistration.secureRegister.step3', ["$state", "$scope", "services", "coremetrics",
        function($state, $scope, services, coremetrics) {

            $scope.sectionHeading = "To create your password, please enter the information below.  Remember, your password needs to be between 6-10 characters in length and must contain both letters and numbers.  You will also need to choose a security question and answer.  When you have supplied all of the information, click submit.";
            $scope.showPasswordMismatch = false;
            $scope.showSpinner = false;
            var userId = $state.params.userId || "";
            var token = $state.params.token || "";
            var loginId = $state.params.loginId || "";

            if (userId === "" || token === "") {
                $state.go('secureRegistration.error', {status: "NO_USER"});
            }

            $scope.user = {
                newPassword: "",
                verifyPassword: "",
                securityQuestion: "",
                securityAnswer: ""
            };

            $scope.securitySelect = {
                options: [
                    {label: "In what city were you born?", value: "1"},
                    {label: "What was the name of your first pet?", value: "2"},
                    {label: "What is your favorite food?", value: "3"},
                    {label: "What was your first car?", value: "4"},
                    {label: "What is your favorite vacation spot?", value: "5"}
                ],
                defaultOption: "Please choose your question"
            };

            $scope.nextStep = function() {
                $scope.verifyPasswordMatch();
                if ($scope.createPasswordForm.$valid && !$scope.showPasswordMismatch) {
                    $scope.showSpinner = true;
                    var payload = {
                        username: userId,
                        oldPassword:"",
                        newPassword: $scope.user.newPassword.toUpperCase(),
                        newSecurityQuestion:$scope.user.securityQuestion.label,
                        newSecurityAnswer:$scope.user.securityAnswer,
                        brand: $scope.brandData.sharedBrandCode.toLowerCase()
                    };

                     services.rbsmbl.passwordSetup().postData(payload, token).then(function (response) {
                         $scope.showSpinner = false;
                            if (response.status != 200) {
                                $state.go('secureRegistration.error', { status: response.status, statusText: response.statusText });
                            } else {
                                services.rbsmbl.login().login(loginId,$scope.user.newPassword.toUpperCase()).then(function (response) {
                                    if(response.status == 200){
                                        if(response.data && response.data.payload && response.data.payload.loginInfo && response.data.payload.loginInfo.role.toLowerCase().indexOf("mcu_admin") >= 0){
                                            window.location = "/employer/client-selection"
                                        }
                                        else {
                                            window.location = "/employer/home"
                                        }


                                    }else{
                                        $state.go('secureRegistration.error', { status: response.status, statusText: response.statusText });
                                    }

                                })
                            }
                        },
                        function(error) {
                            //console.log(error);
                            $state.go('secureRegistration.error', error);
                        }
                    );
                }
            };

            $scope.cancelButton = function() {
                $state.go('secureRegistration.error', { status: "CANCEL_PWD", statusText: "" });
            };

            $scope.verifyPasswordMatch = function () {
                if ($scope.user && $scope.user.newPassword && $scope.user.verifyPassword && $scope.user.newPassword !== $scope.user.verifyPassword) {
                    $scope.showPasswordMismatch = true;
                } else {
                    $scope.showPasswordMismatch = false;
                }
            };
        }
    ]);

};
