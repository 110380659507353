module.exports = function (app) {
    var teleMedicineVendorName = "DEFAULT";
    var hasTeleleMedicine=false;

    app.controller("pages.virtual-medicine", ["$scope", "$window", "$location", "services", "teamsite", "finder-logic2", "finderService", "modal", "vendorSSOUtility", "sessionData",
        function ($scope, $window, $location, services, teamsite, finderLogic, finderService, modal, vendorSSOUtility, sessionData) {


            $scope.backAction = function () {
                $window.history.back();
            };
            $scope.popMyChartModal = function() {
                modal.open({
                    template: require("modals/myChartModal.html"),
                    controller: require("modals/myChartModal"),
                    scope: $scope
                });
            };
            $scope.popTeladocModal = function() {
                // NOTE: in finderLogic.getTeamsiteContent( )  we set properties in $scope for the various modals
                modal.open({
                    template: require("modals/teladocModal.html"),
                    controller: require("modals/dentalModal"),
                    scope: $scope
                });
            };

            $scope.closeTeladocModal = function(theTarget) {
                if ($scope.teleMedicineContent.sso !== undefined) {
                    var ssoParams = $scope.teleMedicineContent.sso[theTarget];

                    var ssoAppConfig = null;
                    if (ssoParams && ssoParams.disableAppConfigId && ssoParams.disableAppConfigId.length > 0 && sessionData && sessionData.appConfig && sessionData.appConfig.FIND_A_DOCTOR && sessionData.appConfig.FIND_A_DOCTOR[ssoParams.disableAppConfigId]) {
                        ssoAppConfig = sessionData.appConfig.FIND_A_DOCTOR[ssoParams.disableAppConfigId];
                    }
                    if (ssoAppConfig !== null && ssoAppConfig.booleanValue === true) {
                        // If SSO is disabled by AppConfig, then just open as a URL in new window.
                        window.open($scope.teleMedicineContent.url[theTarget].href);
                    } else {
                        var ssoParameters = {
                            clientNumber: glbFullMemberInfo.clientList[0].clientNumber,
                            groupNumber: glbPsp.groupNumber,
                            targetType: ssoParams.targetType,
                            category: ssoParams.category,
                            vendorName: ssoParams.vendorName,
                            brand: $scope.brandData.sharedBrandCode.toUpperCase()
                        };
                        vendorSSOUtility.performSSO(ssoParameters, ssoParams.targetLocation, ssoParams.sitePromotionStr);
                    }
                } else {
                    window.open($scope.teleMedicineContent.url[theTarget].href);
                }
                modal.close({
                    template: require("modals/teladocModal.html"),
                    controller: require("modals/dentalModal"),
                    scope: $scope
                });
            };

            finderService.getCurrentMember().then(function(fullMemberInfo) {
                $scope.isAlbertsons = (sessionData.memberInfo.clientList[0].clientNumber ==="255721")? true : false;
                var theBrand = fullMemberInfo.brand.toLowerCase();

                var latestCoverage = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical");
                if (latestCoverage && latestCoverage.telemedicineVendorName != null && latestCoverage.telemedicineVendorName) {
                    teleMedicineVendorName = latestCoverage.telemedicineVendorName.replace(/\s+/g, "").replace("&", "_");
                }
                if (theBrand.toLowerCase() === "mincr") {
                    teleMedicineVendorName = ((teleMedicineVendorName && teleMedicineVendorName.toUpperCase() === "YES") ? teleMedicineVendorName = "OnlineCareAnywhere" : "DEFAULT");
                }
                var pennStatePortalClients = "069151,223885,240156";
                var cnBasedContent = (pennStatePortalClients.indexOf(sessionData.memberInfo.clientList[0].clientNumber) < 0) ? "" : "_PSHOD";
                //generate Telemedicine vendor key.
                var teleMedicineKey = "MBR_TELEMEDICINE_PROVIDER_" + teleMedicineVendorName.toUpperCase() + cnBasedContent;
                var teleMedicineVendorKey = {
                    "SUPRESS_ONLINECARE_GROUPS": "SUPRESS_ONLINECARE_GROUPS"
                };
                teleMedicineVendorKey[teleMedicineKey] = teleMedicineKey;
                services.rbsmbl.contentInfo().getContent(teleMedicineVendorKey).then(function (content) {

                    if (content && content.data && content.data.payload && content.data.payload.contentResponse) {
                        if (($scope.brandData.sharedBrandCode.toUpperCase() == "MINCR") && (content.data.payload.contentResponse.length > 0)) {
                            var arrGrps = content.data.payload.contentResponse[0].value.replace(/\s/g, "").split(",");
                            hasTeleleMedicine = (arrGrps.indexOf(finderLogic.getPortalSelectedPlan().groupNumber) == -1);
                            $scope.hasTeleleMedicine = hasTeleleMedicine;
                            $scope.teleMedicineContent = JSON.parse(content.data.payload.contentResponse[1].value);
                        }
                        else {
                            hasTeleleMedicine = true;
                            if (content.data.payload.contentResponse.length > 1) {
                                $scope.teleMedicineContent = JSON.parse(content.data.payload.contentResponse[1].value);
                            }
                            $scope.hasTeleleMedicine = true;
                            $scope.hasTeleleMedicine = ($scope.brandData.sharedBrandCode.toUpperCase() === "WYBCBS") ? false : true;
                            if ($scope.isAlbertsons) {
                                $scope.teleMedicineContent = JSON.parse(content.data.payload.contentResponse[1].value);
                                $scope.teleMedicineContent.link = "Teledoc";
                            } else {
                                $scope.teleMedicineContent = JSON.parse(content.data.payload.contentResponse[1].value);
                            }
                        }
                    }
                }, function (errObj) {
                    console.log('errObj', errObj)
                });
            });
        }
    ]);

    return [{
        state: "virtual-medicine",
        url: "/virtual-medicine",
        appConfigMaintenanceApp: "LANDING",
        appConfigMaintenanceKey: "FULL_MAINTENANCE",
        views: {
            'body@': {
                template: require("pages/virtual-medicine.html"),
                controller: "pages.virtual-medicine"
            }
        }
    }];
};