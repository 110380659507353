module.exports = function(app) {
    app

        .controller('libraryBannerCtrl',["$scope","$window", "$filter", "$q", "$cookies", "sessionData", "services", "teamsite", "coremetrics", "brand", "emailConfirmationAlert",
            function($scope,$window, $filter, $q, cookies,sessionData, services, teamsite, coremetrics, brand, emailConfirmationAlert){


                $scope.showFormsLibraryBanner = false;
                var brandCode = brand.current.sharedBrandCode;
                var formsLibraryBannerAppConfig = (sessionData && sessionData.appConfig && sessionData.appConfig.LANDING && sessionData.appConfig.LANDING.SHOW_FORMS_LIBRARY_BANNER && sessionData.appConfig.LANDING.SHOW_FORMS_LIBRARY_BANNER.booleanValue);

                if(brandCode!=="ndbcbs"){
                    teamsite.get("MBR_MBRHOME_FORMS_LIBRARY_LINK_HREF").then(function(resp){
                        $scope.MBR_MBRHOME_FORMS_LIBRARY_LINK_HREF = resp;
                        console.log("link library",$scope.MBR_MBRHOME_FORMS_LIBRARY_LINK_HREF);
                        return $scope.MBR_MBRHOME_FORMS_LIBRARY_LINK_HREF;

                    });
                    $scope.goToFormsLibrary = function(){
                        coremetrics.elementTag('FORMS LIBRARY','MEMBER:MEMBER HOME');
                        window.open($scope.MBR_MBRHOME_FORMS_LIBRARY_LINK_HREF, "_self");
                    };
                }


                if(brandCode==="ndbcbs"){
                    teamsite.get("MBR_MBRHOME_FORMS_LIBRARY_LINK_HREF_ND").then(function(resp){
                        $scope.MBR_MBRHOME_FORMS_LIBRARY_LINK_HREF_ND = resp;
                        console.log("link library",$scope.MBR_MBRHOME_FORMS_LIBRARY_LINK_HREF_ND);
                        return $scope.MBR_MBRHOME_FORMS_LIBRARY_LINK_HREF_ND;

                    });
                    $scope.goToFormsLibrary = function(){
                        coremetrics.elementTag('FORMS LIBRARY','MEMBER:MEMBER HOME');
                        window.open($scope.MBR_MBRHOME_FORMS_LIBRARY_LINK_HREF_ND, "_self");
                    };
                }
                $scope.closeMessage = function() {
                    if ($scope.showFormsLibraryBanner === true) {
                        $scope.showFormsLibraryBanner = false;
                    }
                };
                var formsLibraryBrands = ['hmbcbs', 'hbs', 'hmbcbswv', 'hmbcbsde', 'pp'];
                if ((formsLibraryBrands.indexOf(brandCode.toLowerCase()) > -1) && (formsLibraryBannerAppConfig == true)) {
                    $scope.showFormsLibraryBanner = true;
                }


            }])
        .directive('formsLibraryDirective', function() {
                       return {
                           restrict: 'A',

                           replace: true,
                           template: require('directives/landing/formsLibraryBanner.html')


                       };
                   });
};