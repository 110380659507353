module.exports = function(app) {
    app.controller('pages.message-center.readMore', ['$scope', '$stateParams', 'storage', '$filter', 'coremetrics', '$location', 'messageCenterService', 'modal', 'services','$window','$state',
        function($scope, $stateParams, storage, $filter, coremetrics, $location, messageCenterService, modal, services,$window,$state) {

            coremetrics.pageviewTag("MEMBER:MESSAGE CENTER READ MORE", "MEMBER:MESSAGE CENTER");
            $scope.headings = "Message Center";
            $scope.backText = "< Back";


            $scope.backAction = function() {
                $window.history.back();
            };
            $scope.message = $stateParams.msgData;
            if($scope.message){
                console.log(""+$scope.message);
            }

            $scope.printMessage =function(){

               window.print();

            }


        }
    ]);
}
