/**
 * Created by lid8gag on 8/2/2018.
 */
module.exports = function(app) {
    app.directive('coveragePrescription', function() {
        return {
            restrict: 'A',
            template: require('directives/coverage-summary/prescriptionCoverage.html'),
            controller: ['$scope', '$cookies', 'services', 'sessionData', 'finder-logic2', 'modal', 'coremetrics', 'teamsite',
                function($scope, cookies, services, sessionData, finderLogic, modal, coremetrics, teamsite) {
                    var ums = sessionData.memberInfo;

                    $scope.bShowOnlinePharmacy = true;
                    $scope.covType = "Drug";
                    $scope.bIsMN = (ums.brand === "MINCR");
                    $scope.isPlanCancelled = function (currentProd) {

                        var bRetVal = false;
                        var nMemberIdx = 0;
                        var mbr = currentProd.memberList[nMemberIdx];
                        if (mbr.coverageCancellationDate != '')
                            bRetVal = ((new Date(mbr.coverageCancellationDate)) < (new Date()));

                        return bRetVal;
                    };

                    var showOtherPlansLink = function () {
                        var medicalMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical");

                        if (medicalMod !== null) {
                            return false;
                        } else {
                            return true;
                        }
                    };

                    var curClnt = ums.clientList[0];
                    var curGrp = finderLogic.getPortalSelectedPlan(ums);
                    //var curGrp = finderLogic.getPortalSelectedPlan(fullMemberInfo);
                    var curPrd = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Drug");
                    var curPBMIndicator = curGrp.pharmacyBenefitProviderIndicator;

                    $scope.prescPlanName = curPrd.benefitPlanName;
                    $scope.bMultiGroup = (showOtherPlansLink()) && (curClnt.groupList.length > 1);

                    console.log('CURGRP', curGrp);
                    console.log('CURPRD', curPrd);

                    //PRESCRIPTION COST AND REFILLS
                    $scope.bShowPrescBenefitsLink = true;
                    $scope.bShowOnlniePharmacy = true;
                    $scope.bShowDrug = true;
                    $scope.bCancelled = false;
                    $scope.bCancelled = $scope.isPlanCancelled(curPrd);
                    var user = ums;

                    if ((user.brand == 'IBC')||(user.brand == 'IBCCR') || (user.brand == 'IBCAH') ) {
                        $scope.bShowPrescBenefitsLink = false  ;
                    }

                    if ((curPrd.rxClaims === "true") || (curPrd.isBlueExtraBasic === "true") || (curPrd.isBlueExtraPlus === "true")) {
                        $scope.bShowDrug = false;
                    }

                    $scope.theMailOrderPharmacyURL = "";
                    $scope.isCoverageIBCandInFuture = false ;
                    var bCallPrescriptionServices = true;
                    var sitePromo = "" ;
                    // coremeterics extra params added to Mail order link
                    var link;
                    teamsite.get('MER_COVERAGE_SUMMARY_PRESCRIP_LINK_ONLINEPHARM_HREF').then(function(data) {
                        link = data;
                    });


                    if ( (curPBMIndicator == "Catalyst") || (curPBMIndicator == "RX Claim") || (curPBMIndicator == "RX Claim - Commercial") ) {
                        bCallPrescriptionServices = false;

                        $scope.PresServClick = function() {
                            // coremetrics call here.
                                window.open(link + "&cm_sp=Catamaran-_-Prescription%20Services%20Catamaran-_-PRESCRIPTION%20SERVICES");
                        };

                        // If Current Product has members, and if the first member has a non-empty coverageEffectiveDate, check if it is in future.
                        if ((curPrd.memberList.length > 0) && (curPrd.memberList[0].coverageEffectiveDate !== '')) {
                            $scope.isCoverageIBCandInFuture = ( (new Date(curPrd.memberList[0].coverageEffectiveDate)) > (new Date()) );
                        }
                    }
                    else if(curPBMIndicator == "Medco"){
                        //Removed the call to prescription services as service failed for Medco users - FYI Kashyap.
                        bCallPrescriptionServices = false;
                        // Confirmed with Fomeeka : suppress the Mail order option 90 days after the member's cancellation date.
                        if ((curPrd.memberList[0].coverageCancellationDate || '') !== '') {
                            var dtCancel = new Date(curPrd.memberList[0].coverageCancellationDate);
                            dtCancel.setDate(dtCancel.getDate() + 90);  // Add 90 days.

                            if (dtCancel < new Date()) {
                                $scope.bShowOnlinePharmacy = false;
                            }
                        }

                        $scope.PresServClick = function() {
                            // coremetrics call here.
                                window.open(link + "&cm_sp=Medco-_-Prescription%20Services%20Medco-_-PRESCRIPTION%20SERVICES");//"/chmptl/chm/jsp/medco/medco.jsp?typeMEDCO=mailrx";


                        };
                    }
                    else if (((curPBMIndicator == "None") || (curPBMIndicator == null)) && (curPrd.rxClaims == "false")) {
                        if ((curPrd.benefitPlanType == "Medical") && (curPrd.integratedCoverageIndicator =="true") && (curPrd.drugCoverage =="true") && ((curPrd.lob =="EPO")||(curPrd.lob =="PPO")||(curPrd.lob =="HMO")||(curPrd.lob == "HDHP PPO")||(curPrd.lob =="HDHP HMO"))) {
                            if ((user.brand == 'IBC') || (user.brand == 'IBCCR') || (user.brand == 'IBCAH')) {
                                $scope.bShowPrescBenefitsLink = false;
                                $scope.bShowOnlinePharmacy = false;
                                bCallPrescriptionServices = false;
                            }
                            else {
                                $scope.PresServClick = function () {
                                    // coremetrics call here.
                                       window.open(link + "&cm_sp=Medco-_-Prescription%20Services%20Medco-_-PRESCRIPTION%20SERVICES");
                                };

                            }
                        }
                    }
                    else if (((curPBMIndicator == "None") || (curPBMIndicator == null)) && (curPrd.rxClaims == "true")) {
                        $scope.bShowDrug = false ;
                        bCallPrescriptionServices = false;
                    }

                    if (bCallPrescriptionServices) { // if (curPBMIndicator == "Medco" || curPBMIndicator == "Prime" ){
                        // $scope.theMailOrderPharmacyURL = self.model.teamsiteContent.MER_COVERAGE_SUMMARY_PRESCRIP_LINK_ONLINEPHARM_HREF+"&cm_sp=Medco-_-Prescription%20Services%20Medco-_-PRESCRIPTION%20SERVICES";//"/chmptl/chm/jsp/medco/medco.jsp?typeMEDCO=mailrx";
                        // Confirmed with Fomeeka : suppress the Mail order option 90 days after the member's cancellation date.
                        if ((curPrd.memberList[0].coverageCancellationDate || '') !== '') {
                            var dtCancel = new Date(curPrd.memberList[0].coverageCancellationDate);
                            dtCancel.setDate(dtCancel.getDate() + 90);  // Add 90 days.

                            if (dtCancel < new Date() ) {
                                $scope.bShowOnlinePharmacy = false;
                            }
                        }

                        $scope.PresServClick = function() {
                            var prescSvcPayload = {
                                "umi": user.umi,
                                "firstName": curPrd.memberList[0].contact.firstName,
                                "lastName": curPrd.memberList[0].contact.lastName,
                                "dateOfBirth": curPrd.memberList[0].contact.birthDate,
                                "gender": curPrd.memberList[0].gender,
                                "relationshipCode": curPrd.memberList[0].relationshipCode,
                                "directPaySeniorMember": curGrp.directPayIndicator,
                                "targetType": "Coverage",
                                "relationship": curPrd.memberList[0].relationship,
                                "clientid": curClnt.clientNumber,
                                "vendorName":curPBMIndicator
                            };

                            // TODO: Update once IE fix is determined...
                            //var ssoWindowName = ('sso_pharmacy' + prescSvcPayload.vendorName).toLowerCase();
                            //vendorSSOUtility.launchNewSsoWindow(ssoWindowName);
                            var ssoWindowName = "_blank";

                            services.sso.PrescriptionServices.getData(user.userName, user.brand,  prescSvcPayload).then(function (presResp){

                                //added site promotion tags to the post
                                if ( (curPBMIndicator == "Medco") || (curPBMIndicator == "Prime") )
                                    sitePromo =  ((presResp.data.payload.ssoUrl.indexOf("?") == -1) ? "?" : "&") + "cm_sp=" + curPBMIndicator + "-_-Prescription%20Services%20" + curPBMIndicator + "-_-PRESCRIPTION%20SERVICES";


                                var objModel = {
                                    "formName": "PrescriptionSvcsFormName",
                                    "formId": "PrescriptionSvcsFormId",
                                    "formMethod": "POST",
                                    "formAction": presResp.data.payload.ssoUrl + sitePromo,
                                    "formTarget": ssoWindowName,
                                    "formVariables": []
                                };

                                arrayFunctions.forEach(presResp.data.payload.ssoFormParams, function(curParam, idx) {
                                    objModel.formVariables.push({"varName": curParam.name, "varId": curParam.name, "varValue": curParam.value});
                                    if (curParam.name == "SAMLResponse")
                                        objModel.formId += curParam.value;
                                });

                                var newArt = new Article({
                                    controller: FormForPostRequest,
                                    name: 'FormForPostRequest',
                                    target: 'middle',
                                    position: 'last',
                                    model: objModel
                                });

                                newArt.ready(function() {
                                    // coremetrics call here.
                                    if (document.getElementById(objModel.formId))
                                        document.getElementById(objModel.formId).submit();
                                });
                            }, function(errObj){
                                vendorSSOUtility.handleSSOFailure(error, ssoWindowName);
                            });
                        };
                    }

                }
            ]
        };
    });
};