module.exports = function(app) {
    var amedaModal = require("../../modals/maternityAmeda");
    var medelaModal = require("../../modals/maternityMedela");
    app.controller('pages.wellness.additionalResources', ['$scope', 'coremetrics', 'preventiveScheduleService', 'wellnessUtility', 'vendorSSOUtility', 'session', 'teamsite', 'brand', 'modal', '$window', '$sce',
        function($scope, coremetrics, preventiveScheduleService, wellnessUtility, vendorSSOUtility, session, teamsite, brand, modal, $window, $sce) {

            var targetTypes = wellnessUtility.wellnessTargetTypes,
                sessionData = wellnessUtility.wellnessSessionInfo,
                category = wellnessUtility.wellnessCategory,
                returnUrl = "/login/#/wellness/additional-resources",
                returnPage = "Wellness";
            var firstPathSegment = (window.location.href.indexOf('denv')>-1) ? 'hom' : 'login';

            $scope.hmSsoLogoutURL = '';
            $scope.defaultHref = "/" + firstPathSegment + "/#/wellness/additional-resources";
            $scope.adamContentUrlStr = wellnessUtility.wellnessAdamContentUrlStr;
            $scope.isAlbertsonsMember = false;
            $scope.isSharecareMember = false;
            $scope.isSharecareMemberMN =false;
            if(session.data.memberInfo.clientList[0].clientNumber == "255721"){
                $scope.isAlbertsonsMember = true;
            }

            // check for sharecare - HMK soft launch
            teamsite.get('SHARECARE_SOFTLAUNCH_GROUPS').then(function (data){
                $scope.shareCareGroup = data;
                if($scope.shareCareGroup.indexOf(session.data.memberInfo.clientList[0].groupList[0].groupNumber)>-1){
                    $scope.isSharecareMember = true;
                }
                else if(($scope.shareCareGroup === "service") && session.data.memberInfo.brand === "HMBCBS" && session.data.memberInfo.clientList.length>=1
                    && session.data.memberInfo.clientList[0].groupList!== null &&  session.data.memberInfo.clientList[0].groupList.length>=1
                ){//wellnessVendorIndicator indicator changes
                    for(var i=0; i<session.data.memberInfo.clientList[0].groupList.length;i++)
                    {
                        if(session.data.groupNumber === session.data.memberInfo.clientList[0].groupList[i].groupNumber) {
                            if(session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator!== null &&
                                session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator === "ShareCare") {
                                $scope.isSharecareMember = true;
                            }
                            $scope.isSharecareMember = true;
                        }
                    }
                //    $scope.isMNWebMDflag = true;
                    $scope.isSharecareMemberLabel = true;
                }
            });
            teamsite.get('SHARECARE_SOFTLAUNCH_GROUPS_MN').then(function (data){// Restring Welnesspage changes for MN to 8 Groups
                $scope.shareCareGroupMN = data;
                if($scope.shareCareGroupMN.indexOf(session.data.groupNumber)>-1){
                    $scope.isSharecareMemberMN = true;
                }
                else if(($scope.shareCareGroupMN === "service") && session.data.memberInfo.brand === "MINCR" && session.data.memberInfo.clientList.length>=1
                    && session.data.memberInfo.clientList[0].groupList!== null &&  session.data.memberInfo.clientList[0].groupList.length>=1
                ){//wellnessVendorIndicator indicator changes
                    for(var i=0; i<session.data.memberInfo.clientList[0].groupList.length;i++)
                    {
                        if(session.data.groupNumber === session.data.memberInfo.clientList[0].groupList[i].groupNumber) {
                            //  session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator = "ShareCare";
                            if(session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator!== null &&
                                session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator === "ShareCare") {
                                $scope.isSharecareMemberMN = true;
                            }
                        }
                    }
                    $scope.isMNWebMDflag = true;
                    $scope.isSharecareMemberLabel = true;
                }
            });
            $scope.isMINCR = (brand.current.sharedBrandCode === 'mincr') ? true : false;
            $scope.showDefaultHero = !$scope.isMINCR;
            wellnessUtility.getQualifierServiceResults().then(
                function (data){
                    processQualifiers(data);
                },
                function(error) {
                    processQualifiers();
                }
            );

            var processQualifiers = function(data) {
                data = data || {};
                $scope.showAdvCarePdf = (data.advanceCarePdfIndicator === "true");
                $scope.isHcrPreEffecUser = (data.isHCRPreEffectuated === "true");
                $scope.isNotHcrPreEffecUser = !$scope.isHcrPreEffecUser;
                $scope.showHMBlogLink = (data.highmarkBlogIndicator === "true");
                $scope.showMaternityResources = (data.maternityResourcesIndicator === "true");
                $scope.showPBSHealthShow = (data.pbsHealthShowIndicator === "true");
                $scope.showTreatmentTools = (data.wiserTogetherIndicator === "true");
            };

            $scope.showAdvCarePdfDownloadIcon = true;
            $scope.noIconClass = "";
            if ($scope.isMINCR) {
                $scope.showAdvCarePdfDownloadIcon = false;
                $scope.noIconClass = "noIconLeftPad";
            }

            /* Check with Frank for a better approach?*/

            teamsite.get('MBR_WELLNESS_ADVANCED_DIRECTIVE_HEADER').then(function (data){$scope.adLinkText = data;});
            teamsite.get('MBR_WELLNESS_ADVANCED_DIRECTIVE_LINK').then(function (data){$scope.adLinkHref = data;});
            teamsite.get('MBR_WELLNESS_WATCH_EPS').then(function (data){$scope.watchEpsText = data;});
            teamsite.get('MBR_WELLNESS_WATCH_EPS_LINK').then(function (data){$scope.watchEpsHref = data;});
            //adam content url for careguide and bodyguide
            teamsite.get('MBR_WELLNESS_CAREGUIDE_LINK').then(function (data){$scope.careGuideLink = data;});
            teamsite.get('MBR_WELLNESS_BODYGUIDE_LINK').then(function (data){$scope.bodyGuideLink = data;});

            $scope.healthTopicsHandler = function(){
                triggerFederationLogout(); //Temporary Solution: Call HMSSO Federation logout to clear cookies before going to WEBMD.

                var sitePromotionStr = '?cm_sp=WebMD-_-Health%20Topics-_-Health%20Topics';
                var ssoParameters = {
                    clientNumber: sessionData.clientNumber,
                    groupNumber: sessionData.groupNumber,
                    targetType: targetTypes.healthTopics,
                    category: category,
                    returnUrl: returnUrl,
                    returnPage: returnPage,
                    brand: sessionData.brand
                };
                vendorSSOUtility.performSSO(ssoParameters, "_self", sitePromotionStr);
            };
            $scope.amedaHandler = function() {
                modal.open({
                               template: require('modals/maternityAmeda.html'),
                               controller: amedaModal,
                               scope: $scope
                           });
            }
            $scope.medelaHandler = function() {
                modal.open({
                               template: require('modals/maternityMedela.html'),
                               controller: medelaModal,
                               scope: $scope
                           });
            }
            $scope.medEncyclopediaHandler = function(){
                triggerFederationLogout(); //Temporary Solution: Call HMSSO Federation logout to clear cookies before going to WEBMD.

                var sitePromotionStr = 'cm_sp=WebMD-_-Medical%20Encyclopedia-_-Medical%20Encyclopedia';
                var ssoParameters = {
                    clientNumber: sessionData.clientNumber,
                    groupNumber: sessionData.groupNumber,
                    targetType: targetTypes.medEncyclopedia,
                    category: category,
                    returnUrl: returnUrl,
                    returnPage: returnPage,
                    brand: sessionData.brand
                };
                vendorSSOUtility.performSSO(ssoParameters, "_self", sitePromotionStr);
            };

            $scope.advCareHandler = function(){
                coremetrics.elementTag('ADVANCE CARE','MEMBER:WELLNESS');
            };

            $scope.advDirectiveHandler = function(){
                var urlHref = $scope.adLinkHref;
                var urlAppend = urlHref.indexOf("?") > -1?"&":"?";
                var sitePromotionStr = 'cm_sp=CaringInfo-_-Advance%20Directive-_-Advance%20Directive';
                $scope.adLinkHref = $scope.adLinkHref + urlAppend + sitePromotionStr;

            };

            $scope.learnMoreCoremetrics = function(){
                coremetrics.elementTag('Learn more about it on our blog','MEMBER:WELLNESS');
            };

            $scope.reimbursementFormClick = function(theTarget) {
                coremetrics.elementTag('REIMBURSEMENT FORM', 'MEMBER:WELLNESS');
                var urlBase = "https://" + window.location.host;
                var theUrl = urlBase + "/" + theTarget;
                window.open(theUrl, "_blank");
            }

            $scope.pbsTVSecondOpinionHandler = function(){
                var urlHref = $scope.watchEpsHref;
                var urlAppend = urlHref.indexOf("?") > -1?"&":"?";
                var sitePromotionStr = 'cm_sp=BCBS-_-Watch%20an%20episode-_-Watch%20an%20episode';
                $scope.watchEpsHref = $scope.watchEpsHref + urlAppend + sitePromotionStr;
            };

            $scope.webMdInteractivesHandler = function(){
                triggerFederationLogout(); //Temporary Solution: Call HMSSO Federation logout to clear cookies before going to WEBMD.

                var sitePromotionStr = 'cm_sp=WebMD-_-WebMD%20Interactives-_-WebMD%20Interactives';
                var ssoParameters = {
                    clientNumber: sessionData.clientNumber,
                    groupNumber: sessionData.groupNumber,
                    targetType: targetTypes.interactives,
                    category: category,
                    returnUrl: returnUrl,
                    returnPage: returnPage,
                    brand: sessionData.brand
                };
                vendorSSOUtility.performSSO(ssoParameters, "_self", sitePromotionStr);
            };

            $scope.careGuideHandler = function(){
                coremetrics.elementTag('CARE GUIDE','MEMBER:WELLNESS');
            };

            $scope.insideHumanBodyHandler = function(){
                coremetrics.elementTag('BODY GUIDE','MEMBER:WELLNESS');
            };

            $scope.weighTodayHandler = function(){
                triggerFederationLogout(); //Temporary Solution: Call HMSSO Federation logout to clear cookies before going to WEBMD.

                var sitePromotionStr = 'cm_sp=WebMD-_-Weigh%20Today-_-Weigh%20Today';
                var ssoParameters = {
                    clientNumber: sessionData.clientNumber,
                    groupNumber: sessionData.groupNumber,
                    targetType: targetTypes.weighToday,
                    category: category,
                    returnUrl: returnUrl,
                    returnPage: returnPage,
                    brand: sessionData.brand
                };
                vendorSSOUtility.performSSO(ssoParameters, "_self", sitePromotionStr);
            };

            $scope.dailyVictoryHandler = function(){
                triggerFederationLogout(); //Temporary Solution: Call HMSSO Federation logout to clear cookies before going to WEBMD.

                var sitePromotionStr = 'cm_sp=WebMD-_-Daily%20Victory-_-Daily%20Victory';
                var ssoParameters = {
                    clientNumber: sessionData.clientNumber,
                    groupNumber: sessionData.groupNumber,
                    targetType: targetTypes.dailyVitory,
                    category: category,
                    returnUrl: returnUrl,
                    returnPage: returnPage,
                    brand: sessionData.brand
                };
                vendorSSOUtility.performSSO(ssoParameters, "_self", sitePromotionStr);
            };

            var triggerFederationLogout = function() {
                // Temporary Solution: Load the IFrame with the HMSSO Federation logout URL to clear the cookies on the hmsso.highmark domain.
                var domain = $window.location.hostname;
                if (domain.split('.')[0] === "www") {
                    $scope.hmSsoLogoutURL = $sce.trustAsResourceUrl('https://hmsso.highmark.com/fed/user/logout');
                } else {
                    $scope.hmSsoLogoutURL = $sce.trustAsResourceUrl('https://hmssotest.highmark.com/fed/user/logout');
                }
            };
        }
    ]);
};
