module.exports = function (app) {
    app.controller('pages.account.contact-information', ['$scope', 'sessionData', 'modal', 'session', 'services', 'brand', 'teamsite',
        function($scope, sessionData, modal, session, services, brand, teamsite) {

            $scope.initFunction = function(){
                $scope.addAddressIndicator = true;
                $scope.showTempAddress = false;
                $scope.editing = {};
                $scope.editing.addresses = false;
                $scope.editing.phones = false;
                $scope.editing.emails = false;
                $scope.phoneMessage = false;
                $scope.emailUpdated = false;
            };

            $scope.initFunction();

            var lowerCaseBrand = sessionData.memberInfo.brand.toLowerCase();
            var ibcRelatedBrands = ["inac", "ibc", "ibccr", "ibx", "ibcah"];
            // if their brand is NOT IBC, show the item and link it like Medco
            if (ibcRelatedBrands.indexOf(lowerCaseBrand) == -1) {
                $scope.isIBC = false;
            }
            else{
                $scope.isIBC = true;
            }

            if(brand.current.sharedBrandCode ==='ndbcbs'){
                $scope.isNDBCBS = true;
                $scope.toolTip1 =  '<p>This address is where you will receive information about your benefits and health plan.</p> <p>If you have coverage through your employer,</p><p> please be sure to also notify your employer of any address change.</p>';
                $scope.toolTip1Alt =  'This address is where you will receive information about your benefits and health plan.If you have coverage through your employer, please be sure to also notify your employer of any address change.';
                $scope.toolTipUnconfirmed = '<p>You will not receive notifications and</p><p> information via email until your email is confirmed.</p>';
                $scope.toolTipUnconfirmedAlt = 'You will not receive notifications and information via email until your email is confirmed.';
                $scope.toolTip2 = '<p>This will not affect</p> <p>your coverage</p>';
                $scope.toolTip2Alt = 'This will not affect your coverage';
            } else{
                $scope.isNDBCBS = false;
                $scope.toolTip1 =   '<p>Check your Contact Preferences</p> <p>to see what we will send by mail.</p>';
                $scope.toolTip1Alt = 'Check your Contact Preferences to see what we will send by mail.';
                $scope.toolTipUnconfirmed = '<p>We need to confirm your email</p> <p>before we send any benefits</p><p>and service information.</p>';
                $scope.toolTipUnconfirmedAlt = 'We need to confirm your email before we send any benefits and service information.';
                $scope.toolTip2 = '<p>This will not affect</p> <p>your coverage</p>';
                $scope.toolTip2Alt = 'This will not affect your coverage';
            }

            $scope.accountSettingsRetrieveFunction = function()
            {
                services.rbsmbl.AccountSettingsRetrive()
                    .retriveAccountSettings(session.data.groupNumber, "ccsAccountSettings", session.data.memberInfo.brand, session.data.memberInfo.userName)
                    .then(function(accountSettingsData) {
                        if (accountSettingsData && accountSettingsData.data && accountSettingsData.data.payload) {

                            $scope.accountRetrieveData = accountSettingsData.data.payload;
                            var acctData = accountSettingsData.data.payload;
                             $scope.addresses = {};
                             $scope.addresses.permanent = {
                             "type" : "Permanent",
                             "line1": acctData.address1,
                             "line2": acctData.address2,
                             "line3": acctData.address3,
                             "line4": acctData.address4,
                             "county":acctData.county,
                             "city": acctData.city,
                             "state": acctData.state,
                             "zip": acctData.zip
                             };
                            $scope.addresses.temporary = {
                                "type" : "Other",
                                "line1": acctData.tempAddress1,
                                "line2": acctData.tempAddress2,
                                "line3": acctData.tempAddress3,
                                "line4": acctData.tempAddress4,
                                "county":null,
                                "city": acctData.tempCity,
                                "state": acctData.tempState,
                                "zip": acctData.tempZip
                            };

                            var selectedPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan();

                            if (acctData.getRelationshipCode != "Subscriber") {
                                $scope.disallowEdit = true;
                                teamsite.get('MER_CNTCTINFO_DISABLE_EDIT_POLICYHOLDER').then(function (data){
                                    $scope.disallowEditReason=data;
                                });
                            }
                            else if (selectedPlan.productList[0] && selectedPlan.productList[0].hcrProduct && acctData.isDirectPay){
                                // If user is HCR ON-Exchange
                                if (selectedPlan.productList[0].hcrProduct === "ON"){
                                    $scope.disallowEdit = true;
                                    teamsite.get('MER_CNTCTINFO_UPDATE_ADDRESS').then(function (data){
                                        $scope.disallowEditReason=data;
                                    });
                                    // If user is HCR OFF-Exchange
                                }else{
                                    $scope.allowTempAddress = true;
                                    teamsite.get('MER_CNTCTINFO_UPDATE_PERM_ADDRESS').then(function (data){
                                        $scope.disallowEditReason=data;
                                    });
                                }
                            }
                            else if (!(acctData.isDirectPay || acctData.isDirectPayMedicare || acctData.isGroupMedicare)) {
                                $scope.disallowEdit = true;
                                teamsite.get('MER_CNTCTINFO_DISABLE_EDIT_MEDICARE').then(function (data){
                                    $scope.disallowEditReason=data;
                                });
                            }
                            else {
                                // if (model.isDirectPay || model.isDirectPayMedicare || model.isGroupMedicare) {
                                $scope.allowTempAddress = true;
                                $scope.showCounty = true;
                                teamsite.get('MER_CNTCTINFO_CALL_NUMBER').then(function (data){
                                    $scope.disallowEditReason=data;
                                });
                            }

                            if (acctData.isDirectPayMedicare || acctData.isGroupMedicare){
                                teamsite.get('MER_CNTCTINFO_MAIL_DIFFERENT_ADDRESS_SEASONAL').then(function (data){
                                    $scope.addAddressMessage=data;
                                });
                            }else{
                                teamsite.get('MER_CNTCTINFO_MAIL_DIFFERENT_ADDRESS').then(function (data){
                                    $scope.addAddressMessage=data;
                                });
                            }

                            if (acctData.tempAddress1) {
                                $scope.hasTempAddress = true;

                            }else{
                                $scope.hideFooter = true;
                            }

                            if (acctData.address1) {
                                $scope.hasAddress = true;
                            }

                            if ($scope.allowTempAddress && $scope.hasTempAddress) {
                                $scope.showTempAddress = true;
                            }
                            if ($scope.allowTempAddress && !$scope.hasTempAddress && !$scope.disallowEdit) {
                                $scope.showAddAnotherAddress = true;
                            }


                            if(brand.current.sharedBrandCode === 'mincr' || brand.current.sharedBrandCode === 'mn'){
                                $scope.isMn = true;

                                teamsite.get('MER_CNTCTINFO_EXPIRED_CODE_EMAIL_FORMN').then(function (data){
                                    if (data == undefined){
                                        $scope.MER_CNTCTINFO_EXPIRED_CODE_EMAIL_FORMN = 'Please enter the code we sent to this email address. <br />' +
                                            'If it was more than 7 days ago, you will have to <a data-click="emailConfirmRequest()">request a new confirmation email.</a> <br />' +
                                            'Please click the request link only once so you don\'t receive multiple emails.<br /> It may take up to an hour to receive your email.';
                                    }
                                });
                            }

                            $scope.phones = [];
                            $scope.phones.push({ type: 'home', value: $scope.formatPhoneCustom(acctData.homePhone) });
                            $scope.phones.push({ type: 'work', value: $scope.formatPhoneCustom(acctData.workPhone) });
                            $scope.phones.push({ type: 'mobile', value: $scope.formatPhoneCustom(acctData.mobilePhone) });

                            $scope.emails = {};
                            $scope.emails.personal = { type: 'Personal', email: {value: acctData.emailAddress} };
                            if(acctData.workEmailAddress){
                                $scope.emails.work = { type: 'Work', email: {value: acctData.workEmailAddress} };
                                $scope.emails.work = { type: 'Work', email: {value: acctData.workEmailAddress ? acctData.workEmailAddress : 'None'} };
                            };
                            $scope.emails.isConfirmed = (acctData.emailConfirmedInd && acctData.emailConfirmedInd.toLowerCase() === 'confirmed');
                            $scope.emails.validationCode = null;
                        }
                    });
            };
            $scope.accountSettingsRetrieveFunction();

            $scope.formatPhoneCustom = function(phoneNum) {
                if (phoneNum && phoneNum.length > 0) {
                    var newNum = phoneNum.match(/[0-9]*/g).join('');
                    if (newNum.charAt(0) === '1') {
                        newNum = newNum.substr(1);
                    }
                    return '('+newNum.substr(0,3)+') '+newNum.substr(3,3)+'-'+newNum.substr(6,4);
                }
            };

            $scope.heading = "Contact Information";
            $scope.editPhone = false;
            $scope.emailEdit = false;
            $scope.changeNumber = {
            	home: "1231234123",
            	work: "1231231234",
            	mobile: "1231231235"
            };

            window.$scope = $scope;
            $scope.editSwitch = function (key) {
                $scope[key] = !$scope[key];
            };

            $scope.switchToFalse = function (key) {
                $scope[key] = false;
            };

            $scope.openModal = function () {
            	modal.open({
            		template: require('modals/contactInformationEmailModal.html'),
            		scope: $scope,
            		classes: 'contct-information-email-modal'
            	})
            };

            $scope.saveemails = function(){
                $scope.buildPayloadEmail = $scope.emails.personal.email.value;
                services.rbsmbl.AccountSettingsRetrive()
                    .retriveAccountSettings(session.data.groupNumber, "AccountSettings", session.data.memberInfo.brand, session.data.memberInfo.userName)
                    .then(function(accountSettingsData) {
                        if (accountSettingsData && accountSettingsData.data && accountSettingsData.data.payload) {
                            $scope.accountRetrieveData = accountSettingsData.data.payload;
                            var payload = {
                                "brand": session.data.memberInfo.brand,
                                "groupNumber":session.data.groupNumber,
                                "address1":$scope.accountRetrieveData.address1,
                                "address2":$scope.accountRetrieveData.address2,
                                "address3":$scope.accountRetrieveData.address3,
                                "address4":$scope.accountRetrieveData.address4,
                                "city":$scope.accountRetrieveData.city,
                                "state":$scope.accountRetrieveData.state,
                                "zip":$scope.accountRetrieveData.zip,
                                "mobilePhone": $scope.accountRetrieveData.mobilePhone,
                                "secureTextMessageOptInOut":"",
                                "intakeRequest":{
                                    "bdUserId":session.data.memberInfo.bvUserId,
                                    "contactFirstName":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.firstName,
                                    "contactLastName":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.lastName,
                                    "memberFirstName":session.data.memberInfo.firstName,
                                    "memberLastName":session.data.memberInfo.lastName,
                                    "birthDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.birthDate,
                                    "gender":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].gender,
                                    "relationshipCode":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].relationshipCode,
                                    "enrollmentSourceCode":session.data.memberInfo.clientList[0].enrollmentSourceCode,
                                    "ecId":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].ecId,
                                    "umi":session.data.memberInfo.umi,
                                    "lob":session.data.memberInfo.clientList[0].groupList[0].productList[0].lob,
                                    "alphaPrefix":session.data.memberInfo.clientList[0].groupList[0].productList[0].alphaPrefix,
                                    "groupNumber":session.data.memberInfo.clientList[0].groupList[0].groupNumber,
                                    "benefitPlanName":session.data.memberInfo.clientList[0].groupList[0].productList[0].benefitPlanName,
                                    "coverageBeginDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].coverageBeginDate,
                                    "coverageEndDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].coverageEndDate,
                                    "agreementId":session.data.memberInfo.agreementId,
                                    "clientName":session.data.memberInfo.clientList[0].clientName,
                                    "clientId":session.data.memberInfo.clientList[0].clientId,
                                    "coveredIndividualId":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].coveredIndividualId,
                                    "internalGroupNumber":session.data.memberInfo.clientList[0].groupList[0].internalGroupNumber
                                },
                                "emailAddress":$scope.buildPayloadEmail
                            };

                            services.rbsmbl.AccountSettingsRetrive()
                                .saveAccountSettings(payload, "AccountSettings", session.data.memberInfo.brand, session.data.memberInfo.userName)
                                .then(function(accountSettingsDataResponse) {
                                    $scope.editSwitch('emailEdit');
                                    $scope.emailUpdated = true;
                                    $scope.emails.isConfirmed = false;
                                });
                        }
                    });
            };

            $scope.savephones = function(){
                $scope.buildPayloadPhone = $scope.phones;
                services.rbsmbl.AccountSettingsRetrive()
                    .retriveAccountSettings(session.data.groupNumber, "AccountSettings", session.data.memberInfo.brand, session.data.memberInfo.userName)
                    .then(function(accountSettingsData) {
                        if (accountSettingsData && accountSettingsData.data && accountSettingsData.data.payload) {
                            $scope.accountRetrieveData = accountSettingsData.data.payload;
                            var payload = {
                                "brand": session.data.memberInfo.brand,
                                "groupNumber":session.data.groupNumber,
                                "address1":$scope.accountRetrieveData.address1,
                                "address2":$scope.accountRetrieveData.address2,
                                "address3":$scope.accountRetrieveData.address3,
                                "address4":$scope.accountRetrieveData.address4,
                                "city":$scope.accountRetrieveData.city,
                                "state":$scope.accountRetrieveData.state,
                                "zip":$scope.accountRetrieveData.zip,
                                "mobilePhone": $scope.buildPayloadPhone[2].value.replace(/\D/g,''),
                                "secureTextMessageOptInOut":"",
                                "intakeRequest":{
                                    "bdUserId":session.data.memberInfo.bvUserId,
                                    "contactFirstName":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.firstName,
                                    "contactLastName":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.lastName,
                                    "memberFirstName":session.data.memberInfo.firstName,
                                    "memberLastName":session.data.memberInfo.lastName,
                                    "birthDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.birthDate,
                                    "gender":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].gender,
                                    "relationshipCode":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].relationshipCode,
                                    "enrollmentSourceCode":session.data.memberInfo.clientList[0].enrollmentSourceCode,
                                    "ecId":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].ecId,
                                    "umi":session.data.memberInfo.umi,
                                    "lob":session.data.memberInfo.clientList[0].groupList[0].productList[0].lob,
                                    "alphaPrefix":session.data.memberInfo.clientList[0].groupList[0].productList[0].alphaPrefix,
                                    "groupNumber":session.data.memberInfo.clientList[0].groupList[0].groupNumber,
                                    "benefitPlanName":session.data.memberInfo.clientList[0].groupList[0].productList[0].benefitPlanName,
                                    "coverageBeginDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].coverageBeginDate,
                                    "coverageEndDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].coverageEndDate,
                                    "agreementId":session.data.memberInfo.agreementId,
                                    "clientName":session.data.memberInfo.clientList[0].clientName,
                                    "clientId":session.data.memberInfo.clientList[0].clientId,
                                    "coveredIndividualId":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].coveredIndividualId,
                                    "internalGroupNumber":session.data.memberInfo.clientList[0].groupList[0].internalGroupNumber
                                },
                                "homePhone":$scope.buildPayloadPhone[0].value.replace(/\D/g,''),
                                "workPhone":$scope.buildPayloadPhone[1].value.replace(/\D/g,'')
                            };

                            services.rbsmbl.AccountSettingsRetrive()
                                .saveAccountSettings(payload, "AccountSettings", session.data.memberInfo.brand, session.data.memberInfo.userName)
                                .then(function(accountSettingsDataResponse) {
                                    $scope.buildPayloadPhone[0].value = $scope.formatPhoneCustom($scope.buildPayloadPhone[0].value);
                                    $scope.buildPayloadPhone[1].value = $scope.formatPhoneCustom($scope.buildPayloadPhone[1].value);
                                    $scope.buildPayloadPhone[2].value = $scope.formatPhoneCustom($scope.buildPayloadPhone[2].value);
                                    $scope.editSwitch('editPhone');
                                    $scope.phoneMessage = true;
                                });
                        }
                    });

            };

            $scope.saveconfirmcode = function(){
                $scope.validationCodeTemp = $scope.emails.validationCode;
                services.rbsmbl.AccountSettingsRetrive()
                    .retriveAccountSettings(session.data.groupNumber, "AccountSettings", session.data.memberInfo.brand, session.data.memberInfo.userName)
                    .then(function(accountSettingsData) {
                        if (accountSettingsData && accountSettingsData.data && accountSettingsData.data.payload) {
                            $scope.accountRetrieveData = accountSettingsData.data.payload;
                            var payload = {
                                "brand": session.data.memberInfo.brand,
                                "groupNumber":session.data.groupNumber,
                                "address1":$scope.accountRetrieveData.address1,
                                "address2":$scope.accountRetrieveData.address2,
                                "address3":$scope.accountRetrieveData.address3,
                                "address4":$scope.accountRetrieveData.address4,
                                "city":$scope.accountRetrieveData.city,
                                "state":$scope.accountRetrieveData.state,
                                "zip":$scope.accountRetrieveData.zip,
                                "mobilePhone": $scope.accountRetrieveData.mobilePhone,
                                "secureTextMessageOptInOut":"",
                                "intakeRequest":{
                                    "bdUserId":session.data.memberInfo.bvUserId,
                                    "contactFirstName":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.firstName,
                                    "contactLastName":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.lastName,
                                    "memberFirstName":session.data.memberInfo.firstName,
                                    "memberLastName":session.data.memberInfo.lastName,
                                    "birthDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.birthDate,
                                    "gender":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].gender,
                                    "relationshipCode":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].relationshipCode,
                                    "enrollmentSourceCode":session.data.memberInfo.clientList[0].enrollmentSourceCode,
                                    "ecId":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].ecId,
                                    "umi":session.data.memberInfo.umi,
                                    "lob":session.data.memberInfo.clientList[0].groupList[0].productList[0].lob,
                                    "alphaPrefix":session.data.memberInfo.clientList[0].groupList[0].productList[0].alphaPrefix,
                                    "groupNumber":session.data.memberInfo.clientList[0].groupList[0].groupNumber,
                                    "benefitPlanName":session.data.memberInfo.clientList[0].groupList[0].productList[0].benefitPlanName,
                                    "coverageBeginDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].coverageBeginDate,
                                    "coverageEndDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].coverageEndDate,
                                    "agreementId":session.data.memberInfo.agreementId,
                                    "clientName":session.data.memberInfo.clientList[0].clientName,
                                    "clientId":session.data.memberInfo.clientList[0].clientId,
                                    "coveredIndividualId":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].coveredIndividualId,
                                    "internalGroupNumber":session.data.memberInfo.clientList[0].groupList[0].internalGroupNumber
                                },
                                "validationCode":$scope.validationCodeTemp
                            };
                            services.rbsmbl.AccountSettingsRetrive()
                                .saveAccountSettings(payload, "AccountSettings", session.data.memberInfo.brand, session.data.memberInfo.userName)
                                .then(function(accountSettingsDataResponse) {
                                    $scope.emails.isConfirmed = true;
                                    $scope.emailUpdated = false;
                                });
                        }
                    });
            };
            
            $scope.$watch(function(scope) { return scope.changeNumber.home },
              function(newValue, oldValue) {
              	newValue = newValue.replace(/\D/g,'');
              	if (/[0-9]/.test(newValue)) {
                    $scope.changeNumber.home = newValue;
                }else if($scope.changeNumber.home = undefined) {
                	$scope.changeNumber.home = "";
                }
                console.log($scope.changeNumber.home);
              }
            );
            $scope.$watch(function(scope) { return scope.changeNumber.work },
              function(newValue, oldValue) {
              	newValue = newValue.replace(/\D/g,'');
              	if (/[0-9]/.test(newValue)) {
                    $scope.changeNumber.work = newValue;
                }
                console.log($scope.changeNumber.work);
              }
            );
            $scope.$watch(function(scope) { return scope.changeNumber.mobile },
              function(newValue, oldValue) {
              	newValue = newValue.replace(/\D/g,'');
              	if (/[0-9]/.test(newValue)) {
                    $scope.changeNumber.mobile = newValue;
                }
                console.log($scope.changeNumber.mobile);
              }
            );
        }
    ]);
};
