module.exports = function(app) {
    app.directive('coverageDental', function() {
        return {
            restrict: 'A',
            template: require('directives/coverage-summary/dentalCoverage.html'),
            controller: ['$scope', '$cookies', 'services', 'sessionData', 'finder-logic2',
                function($scope, cookies, services, sessionData, finderLogic) {
                    $scope.isPlanCancelled = function (currentProd) {
                        var bRetVal = false;
                        var nMemberIdx = 0;
                        var mbr = currentProd.memberList[nMemberIdx];
                        if (mbr.coverageCancellationDate !== '') {
                            bRetVal = ((new Date(mbr.coverageCancellationDate)) < (new Date()));
                        }

                        return bRetVal;
                    };

                    var showOtherPlansLink = function () {
                        var medicalMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical");
                        var drugMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Drug");

                        if ((medicalMod !== null) || (drugMod !== null)) {
                            return false;
                        } else {
                            return true;
                        }
                    };

                    $scope.covType = "Dental";

                    var ums = sessionData.memberInfo;
                    var curClnt = ums.clientList[0];
                    var curGrp = finderLogic.getPortalSelectedPlan(ums);
                    var curPrd = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Dental");
                    console.log('curPrd in Dental', curPrd);

                    $scope.dentalPlanName = curPrd.benefitPlanName;
                    $scope.bShowOrthoLink = true;

                    if (curGrp.directPayIndicator === "true") {
                        return $scope.bShowOrthoLink = false;
                    }

                    $scope.bCancelled = false;
                    $scope.bCancelled = $scope.isPlanCancelled(curPrd);

                    $scope.bMultiGroup = (showOtherPlansLink()) && (curClnt.groupList.length > 1);
                }
            ]
        };
    });
};