module.exports = function(app) {
    app.factory('finderService', ['$q', 'services',
        function($q, services) {
            var getCurrentMember;
            getCurrentMember = function() {
                return services.rbsmbl.memberInfo().fetch().then(
                    function(response) {
                        var memberInfo = response.data.payload;
                        return memberInfo;
                    },
                    function(err) {
                     //   console.error('fetch failed in getCurrentMember', err);
                        return $q.reject(err);
                    }
                );
            };
            return {
                getCurrentMember: getCurrentMember
            };
        }
    ]);

    // Wellness Blog ========================================================================
    app.factory('localWellnessBlog', ['$q', 'services',
        function($q, services) {

            var getWellnessBlogItems;
            getWellnessBlogItems = function() {
                return services.rbsmbl.wellnessBlog().fetch().then(
                    function(response) {
                        if (response && response.data && response.data.payload)
                        {
                            return response.data.payload;
                        } else {
                            return $q.reject('We did not get the response.data.payload we expected in localWebmdRecommendations.');
                        }
                    },
                    function(err) {
                            return $q.reject(err);
                    }
                );
            };
            return {
                getWellnessBlogItems: getWellnessBlogItems
            };
        }
    ]);

    // WEB MD RECOMMENDATIONS FOR YOU ========================================================================
    app.factory('localWebmdRecommendations', ['$q', 'services',
        function($q, services) {
            var getWebmdRecommendations;
                getWebmdRecommendations = function(groupNumber, clientNumber, theBrand) {
                    if(theBrand == "NDBCBS" || theBrand == "WYBCBS"){
                        return services.rbsmbl.webmdRecommendations().fetch(groupNumber, clientNumber, theBrand).then(
                            function(response) {
                                if (response && response.data && response.data.payload)
                                {
                                    return response.data.payload;
                                } else {
                                    return $q.reject('We did not get the response.data.payload we expected in localWebmdRecommendations.');
                                }
                            },
                            function(err) {
                                //   console.error('fetch failed in getCurrentMember', err);
                                if ((window.location.host.indexOf('denv') > -1) && (err.statusText == 'Not Found')) {
                                    var pl = {};
                                    pl.recommendations = [
                                        {
                                            linkTitle: "Move More to Prevent Heart Failure",
                                            linkUrl: "http://staging.webmdhealth"
                                        },
                                        {
                                            linkTitle: "Eat More to Prevent Long Life",
                                            linkUrl: "http://staging.webmdhealth"
                                        },
                                        {
                                            linkTitle: "Think More to Prevent Voting For A Billionaire",
                                            linkUrl: "http://staging.webmdhealth"
                                        }
                                ];
                                    return pl;
                                } else {
                                    return $q.reject(err);
                                }
                            }
                        );
                    }
                    else{
                        return $q.reject("WebMD services are decommissioned for highmark");
                    }
            };
            return {
                getWebmdRecommendations: getWebmdRecommendations
            };
        }
    ]);

    // displayOrHideItems ====================================================================
    app.factory('displayOrHideItemsWrapper', ['$q', 'services',
        function($q, services) {
            var displayOrHideItemsCall;
            displayOrHideItemsCall = function(paramClientNumber, paramGroupNumber, paramItemsToDisplayOrHide, theBrand) {
                if (!paramClientNumber || !paramGroupNumber || !paramItemsToDisplayOrHide || !theBrand) {
                    throw new Error("Missing required parameter sent to displayOrHideItemsCall.");
                    return null;
                }
                if (!Array.isArray(paramItemsToDisplayOrHide)) {
                    throw new Error("paramItemsToDisplayOrHide has to be an array.");
                    return null;
                }
                var displayOrHideItemsConfig = {
                    brand: theBrand.toUpperCase(),
                    APPID: 'CHMHOM',
                    clientNumber: paramClientNumber,
                    groupNumber: paramGroupNumber,
                    itemsToDisplayOrHide: paramItemsToDisplayOrHide
                };
                return services.rbsmbl.displayOrHideItems().fetch(displayOrHideItemsConfig).then(
                    function(response) {
                        // grab the true or false value here and send that back to the caller
                        if (response && response.data && response.data.payload) {
                            var ssoStuff = response.data.payload;
                            return ssoStuff;
                        } else {
                            return null;
                        }
                    },
                    function(err) {
                        //   console.error('fetch failed in getCurrentMember', err);
                        return $q.reject(err);
                    }
                );
            }
            return {
                displayOrHideItemsCall: displayOrHideItemsCall
            }
        }
    ])

    app.factory('drugVendorWrapper', ['$q', 'services',
        function($q, services) {
            var getDrugVendor;
            getDrugVendor = function(clientNumber, groupNumber, targetType, category, brand) {
                if (!clientNumber|| !groupNumber || !targetType || !category ) {
                    throw new Error("Missing required parameter sent to drugVendorWrapper.getDrugVendor");
                    return null;
                }
                var getDrugVendorConfig = {
                    brand: brand,
                    clientNumber: clientNumber,
                    groupNumber: groupNumber,
                    vendorName : '',
                    targetType : targetType,
                    category   : category
                };
                return services.rbsmbl.getDrugVendorService().fetch(getDrugVendorConfig).then(
                    function(response) {
                        if (response && response.data && response.data.payload) {
                            var drugVendorStuff = response.data.payload;
                            return drugVendorStuff;
                        } else {
                            return null;
                        }
                    },
                    function(err) {
                        //   console.error('fetch failed in getCurrentMember', err);
                        return $q.reject(err);
                    }
                );
            }
            return {
                getDrugVendor: getDrugVendor
            }
        }
    ])

    app.factory('pharmacyServiceWrapper', ['$q', 'services', '$cookies',
        function($q, services, $cookies) {
            var doPharmacySsoCall;
            doPharmacySsoCall = function(fullMemberInfo, curMember, psp, theTargetType, rxCarveOutVendor) {
                if (!fullMemberInfo || !curMember || !psp || !theTargetType) {
                    return null;
                }
                var pharmacyConfig = {
                    // pds: the next item is not currently used (9/15/15).
                    //      However, it is to be the service's APPID, not the current page's
                    APPID: 'CHMHOM',
                    FromDP: fullMemberInfo.userName,
                    brand: fullMemberInfo.brand,
                    "umi": fullMemberInfo.umi, // user.umi,
                    "firstName": fullMemberInfo.firstName,
                    "lastName": curMember.contact.lastName,
                    "dateOfBirth": curMember.contact.birthDate,
                    "gender": curMember.gender,
                    "relationshipCode": curMember.relationshipCode,
                    "directPaySeniorMember": psp.directPayIndicator,
                    "targetType": theTargetType,
                    "relationship": curMember.relationship,
                    "Clientid": $cookies.get("mbrClientNumber"), 
                    "vendorName": (psp.pharmacyBenefitProviderIndicator) ? psp.pharmacyBenefitProviderIndicator : null
                };

                if (rxCarveOutVendor && rxCarveOutVendor.length > 0) {
                    pharmacyConfig.vendorName = rxCarveOutVendor;
                }

                return services.rbsmbl.prescriptionServices().fetch(pharmacyConfig).then(
                    function(response) {
                        if (response && response.data && response.data.payload) {
                            var ssoStuff = response.data.payload;
                            return ssoStuff;
                        } else {
                            return null;
                        }
                    },
                    function(err) {
                        //   console.error('fetch failed in getCurrentMember', err);
                        return $q.reject(err);
                    }
                );
            }
            return {
                doPharmacySsoCall: doPharmacySsoCall
            }
        }
    ])

};
