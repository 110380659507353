module.exports = function(app) {
    app.controller('pages.wellness.contentDetail', ['$scope', '$state','$location', '$stateParams', 'coremetrics', 'wellnessUtility', 'session', 'teamsite', 'brand','$sce','$window','$anchorScroll',
        function($scope, $state, $location, $stateParams, coremetrics, wellnessUtility, session, teamsite, brand, $sce,$window,$anchorScroll) {

            teamsite.get('MBR_WELLNESS_LEFTNAV_TITLE').then(function (data){ $scope.headings  = data;});
            $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {

                if (fromState.name === 'wellness.additionalResources') {
                    teamsite.get('MBR_WELLNESS_BACK_TO_ADD_RES_BTN').then(function (data){ $scope.backText  = data;});
                    $scope.backAction = function() {
                        $state.go('wellness.additionalResources');
                    };
                } else if (fromState.name === 'wellness.wellnessSearch'){
                    teamsite.get('MBR_WELLNESS_BACK_TO_SEARCH_BTN').then(function (data){ $scope.backText  = data;});
                    $scope.backAction = function() {
                        $state.go('wellness.wellnessSearch', fromParams);
                    };
                } else {
                    if(toParams.linked === 'true'){
                        $scope.backText = 'Back';
                    }
                    else{
                        $scope.backText = 'Back to search results';
                    }
                    $scope.backAction = function() {
                        $window.history.back();
                    };
                }


            });



            var detailHandler = function(){
                $scope.projectTypeID=$stateParams.projectTypeID;
                $scope.genContentID=$stateParams.genContentID;
                $scope.productID=$stateParams.productID;

                $scope.linked=$stateParams.linked;
                $scope.sidebar.links=[];
                $scope.numberOfImages=0;
                $scope.sidebar.links.push({
                    heading: '<span >IN THIS REPORT</span>',
                    description: '<span>Here are the topics in this report</span>'
                });
                $scope.presentationImage={};
                $scope.currentDate=new Date();

                if ($scope.productID === 10) {
                    $scope.sidebar.currentPage = 2;
                } else {
                    $scope.currentPage = 0;

                }
                $scope.terms=[];
                $scope.subTitles={};
                $scope.description={};
                $scope.noOfTimesPrintClicked=0;
                $scope.removeTitles=["noTitle:  Header","*BMI CHART","*READ_MORE BOX","*RELATED CONDITIONS","*HANDOUT BOX","vGroup","visHeader"];
                //default value
                $scope.adam_domain_url='https://ssl.adam.com';
                teamsite.get('MBR_WELLNESS_SSL_ADAM_URL').then(function (data){
                    $scope.adam_domain_url  = data;
                });
                $scope.resourceConfig={};
                $scope.resourceConfig.mm_parent="/graphics/multimedia/en/";
                $scope.resourceConfig.image_parent="/graphics/images/en/";
                $scope.resourceConfig.thumbnail="/graphics/tnail/";//
                $scope.resourceConfig.rollover_globals="/graphics/rollover/";
                $scope.resourceConfig.sounds="/graphics/multimedia/en/";
                $scope.resourceConfig.global="/graphics/global";
                $scope.resourceConfig.pdf="/graphics/pdf/en/";
            };

            $scope.nextPage = function () {
                $scope.currentPage= $scope.currentPage<$scope.ordinal? $scope.currentPage+1: $scope.currentPage;
            }


            $scope.prevPage = function () {
                $scope.currentPage= $scope.currentPage>0? $scope.currentPage-1: $scope.currentPage;
            }

            $scope.showPage = function (pageNumber) {
                    $scope.currentPage = pageNumber;
                    $scope.sidebar.currentPage = pageNumber;
            }

            $scope.goToSection=function(title){
                $scope.sidebar.currentPage=parseInt(title);
                $scope.sidebar.current.active=false;
                $scope.sidebar.current=$scope.sidebar.links[title-1];
                $scope.sidebar.current.active=true;
                $scope.sidebar.selectedLink=$scope.sidebar.current.label;
            };

            $scope.goToNextPage=function(){
                var nextPage=$scope.sidebar.currentPage<$scope.ordinal?$scope.sidebar.currentPage+1:2;
                $scope.sidebar.currentPage=nextPage;
                $scope.sidebar.current.active=false;
                $scope.sidebar.current=$scope.sidebar.links[nextPage-1];
                $scope.sidebar.current.active=true;
                $scope.sidebar.selectedLink=$scope.sidebar.current.label;
                $location.hash("adam_content");
                $anchorScroll();
            };

            $scope.range = function(n,offset) {
                if(n){
                return new Array(parseInt(n));
                }else{
                    return [];
                }
            };

            $scope.goBack = function() {
                $window.history.back();
            };

            $scope.filterTitle=function(title){
                title=title.replace("linkGroup: ","");
                title=title.replace("PregnancyTV: ","");
                title=title.replace(/\bStep\s\d*\b:/g,"");
                return title.trim();
            };

            $scope.initialize=function(){
                console.log("initialize");
            };

            $scope.letters = [];

            for(var alpha = 0; alpha < 26; alpha++){
                var newLetter = {
                    letter: String.fromCharCode(65 + alpha),
                    exists: false
                };
                $scope.letters.push(newLetter);
            }

            $scope.scrollTo = function (letter) {

                //alphabetize terms
                var terms = $scope.terms,
                    breakTerm = null,
                    compare = function (a, b) {
                        if (a.title < b.title) {
                            return -1;
                        }
                        if (a.title > b.title) {
                            return 1;
                        }
                        return 0;
                    };

                terms.sort(compare);

                for (var i=0; i<terms.length; i++) {
                    if (terms[i].title.substring(0,1) === letter) {
                        breakTerm = terms[i].title;
                        break;
                    }
                }

                if (breakTerm) {
                    $location.hash(breakTerm);
                    $anchorScroll();
                }
            };

            $scope.printMessage = function(){
                $scope.noOfTimesPrintClicked=$scope.noOfTimesPrintClicked+1;
            };

            $scope.calculateHeartRate=function(thrcAge){
                if(thrcAge !== 'undefined'){
                    $scope.thrcAge=thrcAge;
                    $scope.thrcMaxRate=220-$scope.thrcAge;
                    $scope.thrcLowerZone= Math.round($scope.thrcMaxRate * 0.5);
                    $scope.thrcUpperZone=Math.round($scope.thrcMaxRate *0.85);
                }
            };

            $scope.clearTHRC=function(){
                $scope.thrcAge="";
                $scope.thrcMaxRate="";
                $scope.thrcLowerZone= "";
                $scope.thrcUpperZone="";
            };

            $scope.calculateBMI=function(bmiFeet,bmiInches,bmiPounds){
                $scope.bmiFeet=bmiFeet;
                $scope.bmiInches=bmiInches ;
                $scope.bmiPounds=bmiPounds;
                $scope.bmiIndex=Math.round (parseFloat(bmiPounds/2.2) / Math.pow((((parseFloat(bmiFeet * 12) + parseFloat(bmiInches)) * 2.54) /100) , 2) * 10) /10;
            };


            $scope.trustHtml=function (recordingUrl) {
                return $sce.trustAsHtml(recordingUrl);
            };

            $scope.clearBMI=function(){
                $scope.bmiIndex= "";
            };

            detailHandler();
        }
    ]);
};