module.exports = function(app) {
    app.directive('claimSummaryDownload', function() {

        return {
            restrict: 'A',
            replace: true,
            scope: {},

            template: require('directives/claimSummaryDownload.html'),

            controller: ['services', 'claimService', 'invisibleFormService', '$scope', 'coremetrics', 'storage',
                function(services, claimService, invisibleFormService, $scope, coremetrics, storage) {

                    var
                        _createExcelServicePayload,
                        downloadSummary;


                    _createExcelServicePayload = function(summary) {
                        summary = Array.isArray(summary)
                                ? summary
                                : [];

                        var payload = summary.map(function(claim) {
                            var
                                claimStatusText = claimService.getClaimStatus(claim.bilgProvClmStaCd).description,

                                bilgProvOrgNm = typeof claim.bilgProvOrgNm === 'string'
                                                ? claim.bilgProvOrgNm.toLowerCase()
                                                : '',

                                bilgProvTotMbrLiab = claim.bilgProvTotMbrLiab
                                                   ? claim.bilgProvTotMbrLiab.toFixed(2)
                                                   : 0;

                            return {
                                "mbrFirstNm": claim.mbrFirstNm,
                                "mbrMiddleNm": claim.mbrMiddleNm || "",
                                "mbrLastNm": claim.mbrLastNm,
                                "mbrSuffixNm": claim.mbrSuffixNm || "",
                                "mbrBirthDate": claim.mbrBirthDate,
                                "bilgProvOrgNm" : bilgProvOrgNm,
                                "bilgProvClmStaCd": "1",
                                "bilgProviderId": claim.bilgProviderId,
                                "clnCnRuleSetClaimsPcsgNo": claim.displayGroupNumber,
                                "claimNumber": claim.claimNumber,
                                "bilgProvSvcBgnDt": claim.bilgProvSvcBgnDt,
                                "bilgProvTotMbrLiab": bilgProvTotMbrLiab == 0 ? "0.00" : bilgProvTotMbrLiab,
                                "claimStatusText": claimStatusText,
                                "paymentInfo": claim.paymentInfo
                            };
                        });

                        return {
                            name: 'GetClaimXLS',
                            method: 'POST',
                            action: '/rbsmbl/x-services/download/claimdetails/file',
                            inputs: {
                                payload: JSON.stringify({ 'payload': payload })
                            }
                        };
                    };


                    downloadSummary = function(){
                        var claimsAndPaymentsDateRange = JSON.parse(storage.get('claimRefineDateRange'));

                        invisibleFormService.submit(
                            {
                                name: 'GetClaimXLS',
                                method: 'GET',
                                action: '/rbsmbl/x-services/download/claimdetails/file',
                                target: '_blank',
                                inputs: {
                                    startDate :claimsAndPaymentsDateRange.startDate,
                                    endDate : claimsAndPaymentsDateRange.endDate
                                }
                            }
                        );


                        /*var claimsAndPaymentsDateRange = storage.get('claimRefineDateRange');
                        claimService.getSummary(claimsAndPaymentsDateRange).then(
                            function(response) {
                               coremetrics.elementTag('DOWNLOAD SUMMARY DOWNLOAD:CLAIMS','MEMBER:CLAIMS');
                               invisibleFormService.submit(
                                   _createExcelServicePayload(response)
                               );
                            },

                            function(err) {
                                console.error('getSummary failed with error', err);
                            }
                        );*/
                    };

                    $scope.downloadSummary = downloadSummary;
                }
            ]
        };
    });
};
