module.exports = function (app) {
    app.controller('pages.account.other-insurance.OIStep4', ['$scope', 'coremetrics',
        function ($scope, coremetrics) {
            console.log("==================================================STEP 4==================================================");

            $scope.fixSidebar();
            
            $scope.otherInsuranceChevron.step = 4;

            var cmPrint = false;

            $scope.print = function () {
                if(cmPrint !== true){
                    coremetrics.elementTag('PRINT OTHER INSURANCE','MEMBER:ACCOUNT SETTINGS:OTHER INSURANCE');
                    cmPrint = true;
                };
                window.print();
            };

            $scope.handleKeyPressPrint = function() {
                if (mouseClick(event) || keyPressEnter(event)) {
                    $scope.print();
                };
            };

            $scope.clickEdit = function (step) {
                $scope.editClicked(step);
            };


        }
    ]);
};
