module.exports = function(app) {
    app.directive('wellnessSearchBox',
        function() {

            return {
                restrict: 'A',
                template: require('directives/wellnessSearchBox.html'),
                controller: ['$scope', '$state', 'storage', 'vendorSSOUtility', 'wellnessUtility', 'coremetrics',
                    function($scope, $state, storage, vendorSSOUtility, wellnessUtility, coremetrics) {

                        var targetTypes = wellnessUtility.wellnessTargetTypes,
                            sessionData = wellnessUtility.wellnessSessionInfo,
                            category = wellnessUtility.wellnessCategory,
                            returnUrl = "/login/#/wellness/wellnessOverview",
                            returnPage = "Wellness";

                        $scope.performWellnessSearch = function(){
                            if($scope.searchFieldValue){
                                $state.go('wellness.wellnessSearch', {searchQuery: $scope.searchFieldValue});
                                coremetrics.elementTag('Wellness Search','MEMBER:WELLNESS');
                            }

                        };

                        $scope.goToHealthTopics = function(){
                            var sitePromotionStr = 'cm_sp=WebMD-_-Health%20Topics-_-Health%20Topics';

                            var ssoParameters = {
                                clientNumber: sessionData.clientNumber,
                                groupNumber: sessionData.groupNumber,
                                targetType: targetTypes.healthTopics,
                                category: category,
                                returnUrl: returnUrl,
                                returnPage: returnPage,
                                brand: sessionData.brand
                            };
                            vendorSSOUtility.performSSO(ssoParameters, '_self', sitePromotionStr);
                        };
                    }
                ]
            };
        }
    );
};
