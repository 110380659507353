module.exports = function (bootstrap) {
    bootstrap.run(['Service', function(Service) {
        new Service({
            name: "prenatalFormService",
            group: "rbsmbl",
            url: "/rbsmbl/x-services/contactus/addMessage",

            fetch: function (params) {
                var opts = params || {};
                return this.post(opts);
            }
        });
    }]);
};