module.exports = function(app) {
    require("./sapphire-login/sapphire-login")(app);
    app.controller('pages.sapphireLanding', ["$window", "$scope", "brand", "services", "sessionData", "$location",
        function($window, $scope, brand, services, sessionData, $location) {
            $window.document.title = "Home";

            $scope.showSection = false;
            $scope.sidebar = {
                description: '<span data-teamsite="MBR_CUSTOM_CLIENT_LOGIN as content">{{content.leftNav.description}}</span>',
            };
            if (sessionData.loggedIn) {
                $window.location = "/member/home";
            }

            $scope.standAloneLoginImg = brand.current.sharedBrandCode === "mincr" ? "mn-logo" : "generic-logo";

        }
    ]);
    return [
        {
            state: "sapphireLanding",
            appConfigMaintenanceApp: "SPLASH",
            appConfigMaintenanceKey: "SAPPHIRE_LANDING",
            url: "/sapphire-landing",
            views: {
                'body@': {
                    template: require('pages/sapphire-landing.html'),
                    controller: "pages.sapphireLanding",
                },
                "content@sapphireLanding": {
                    template: require('pages/sapphire-login/sapphire-login.html'),
                    controller: "pages.sapphireLanding.sapphireLogin"
                }
            },
        }, {
            state: "sapphireLanding.uom",
            url: "/:client"
        }
    ];
};
