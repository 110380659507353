module.exports = function(app) {
    var myModal = require("../modals/dentalModal");
    var amedaModal = require("../modals/maternityAmeda");
    var medelaModal = require("../modals/maternityMedela");
    var ssModal = require("../modals/smartShopperModal");
    //var myPharmacyModal = require("../modals/pharmacyModal");
    var hasTeleleMedicine=false;
    var teleMedicineVendorName = "DEFAULT";
    var glbFullMemberInfo;
    var glbPsp;
    var glbCurProd;
    var glbCurMember;
    var leftNavDescription = '<span data-teamsite= "MBR_FINDADOCTOR_LEFTNAVIGATION_TEXT"></span>';
    var showDentalIcon = false;

    var pharmObj;
    var validateRegion;

    app.controller('pages.finder', ["$state","services", "$scope", "$sce", "$location", "finder-logic2", "modal", "finderService", "pharmacyServiceWrapper", "teamsite", "vendorSSOUtility", "sessionData", "displayOrHideItemsWrapper", "ssoHelper", "cceService", "$cookies",
        function($state, services, $scope, $sce, $location, finderLogic, modal, finderService, pharmacyServiceWrapper, teamsite, vendorSSOUtility, sessionData, displayOrHideItemsWrapper, ssoHelper, cceService, $cookies) {

            var startYourSearch = {
                heading:'<span data-teamsite="MBR_FINDADOCTOR_LEFTNAVIGATION_HEADER"></span>',
                label: '<span data-teamsite="MBR_FINDADOCTOR_LEFTNAV_ITEM"></span>',
                //label: 'Find a Doctor or Hospital',
                description: leftNavDescription,
                state: 'finder'
            };

            var links = []; //Array to hold the links to be displayed in the sidebar
            $scope.pharmacySso = {};

            var rxCarveOutVendor = null;
            $scope.showNewRx = false;
            $scope.isWpaNewRx = finderLogic.isWpaNewRx;
            $scope.isCpaNewRx = finderLogic.isCpaNewRx;
            $scope.isWvNewRx = finderLogic.isWvNewRx;

            var arrEliteBrands = ["HMBCBS","HMBCBSDE","HMBCBSWV","HBS"];
            var eliteBrand = $scope.brandData.sharedBrandCode.toUpperCase();
            if(arrEliteBrands.indexOf(eliteBrand) > -1){
                $scope.isElitePlus = true;
            }

            // Set up vitals live flag
            $scope.isVitalsLive = ($scope.brandData.isVitalsActive) &&
                (sessionData.appConfig && sessionData.appConfig.PROVIDER && sessionData.appConfig.PROVIDER.VITALS_LIVE) ? sessionData.appConfig.PROVIDER.VITALS_LIVE.booleanValue : false;
            $scope.vitalsSmartshopper = finderLogic.getPortalSelectedPlan().vitalsSmartShopper;
            $scope.isVitalsLiveCCE =  (sessionData.appConfig && sessionData.appConfig.PROVIDER && sessionData.appConfig.PROVIDER.VITALS_LIVE_CCE) ? sessionData.appConfig.PROVIDER.VITALS_LIVE_CCE.booleanValue : false;

            $scope.formsLibraryAccess = false;


            //breastpumb show checking start from here
            var firstPathSegment = (window.location.href.indexOf('denv')>-1) ? 'login' : 'login';
            $scope.defaultHref = "/" + firstPathSegment + "/#/finder";
            $scope.breastpumpShow = false;
            $scope.portalSelectedPlan = finderLogic.getPortalSelectedPlan();
            $scope.isAlbertsons = (sessionData.memberInfo.clientList[0].clientNumber ==="255721")? true : false;
            // set up the activeDrawer flag that we're going to use to know whether the SD panels is open
            $scope.activeDrawer = "";
            $scope.switchConfig=function(){
                teamsite.get("AMWELL_REMOVE_POPUP_UPDATE").then(function(content) {
                    $scope.switchFlow=JSON.parse(content)
                    $scope.switchConfigVal=  $scope.switchFlow.popUpSwitchConfig.toUpperCase()=="TRUE"?true:false
                    $scope.isplannedPartnerVal=$scope.isAmwellPlannedPartner()
                    if(($scope.isplannedPartnerVal)&&($scope.switchConfigVal)&&(teleMedicineVendorName.toUpperCase().includes("AMWELL"))){
                        this.validateRegion=true
                        $scope.validateRegion=true 
                    }
                   
                    else{
                        $scope.validateRegion=false
                    }
 
                });
            };

  
            $scope.getCurrentProducts =  function() { 
                var currentPlans = [];
                var portalSelectedPlan =  $scope.portalSelectedPlan;
                //var millisNow = Date.parse(this.model.serverDate);
                var millisNow =  Date.parse(new Date());//Date.parse(this.model.serverDate);
                if (portalSelectedPlan && portalSelectedPlan.productList && portalSelectedPlan.productList.length > 0) {
                    var the_prods = portalSelectedPlan.productList;
                } else {
                    return currentPlans; 
                }
                for (var i = 0; i < the_prods.length; i++) {
                    var millisBeginDate = Date.parse(the_prods[i].coverageBeginDate);
                    var millisEndDate = Date.parse(the_prods[i].coverageEndDate);
                    if ((millisBeginDate < millisNow) && (millisNow < millisEndDate)) {
                        currentPlans.push(the_prods[i])
                    }
                }
                return currentPlans;
            };
            $scope.hasThisPlanTypeIgnoreCase =  function(theType){
                var hasIt = -1;
                if ($scope.getCurrentProducts()) {
                    var theProductList = $scope.getCurrentProducts();
                    for (var i = 0; i < theProductList.length; i++) {

                        if ( (theProductList[i].lob.replace(" ","")).toUpperCase() == (theType.replace(" ","")).toUpperCase() ) {

                            hasIt = i;
                        } } }
                return hasIt;
            };

            $scope.isAgOnlyMember =  function() {
                var ps = $scope.portalSelectedPlan.productList;
                var isAgOnly = true;
                for (var i = 0; i < ps.length; i++) {
                    isAgOnly = (ps[i].lob != 'AG') ? false : isAgOnly;
                }
                return isAgOnly;
            };

            $scope.isTraditionalPlanMember =  function(){
                var ps = $scope.portalSelectedPlan.productList;
                var isTraditionalPlan = true;
                for (var i = 0; i < ps.length; i++) {
                    isTraditionalPlan = (ps[i].lob != 'TDM') ? false : isTraditionalPlan;
                }
                return isTraditionalPlan;
            };
            $scope.isWvMember =  function() {
                var arrWv = ["hmbcbswv", "ms", "highmarkbcbswv", "mbh:wv"];
                var brandCode =$scope.brandData.sharedBrandCode;
                // console.log("brandCode"+brandCode);
                var isWv = (arrWv.indexOf(brandCode) > -1);
                return isWv;
            };

            $scope.isAmwellPlannedPartner = function() {
                var arrApp = ["HMBCBS", "HMBS","HBS","HMBCBSDE","HMBCBSWV","HHIC","NEB","IDH","LOU","LA","PBS","","IBC","FL","HMBS","DE","MS","AZ",];
                var brandCode =$scope.brandData.sharedBrandCode.toUpperCase();
                // console.log("brandCode"+brandCode);
                var isApp = (arrApp.indexOf(brandCode) > -1);
                console.log(isApp)
                return isApp;
            };

            //ppo/epo check
            var arrPlanNames = ['PPO', 'EPO', 'HMO', 'Security Blue']; // includes Freedom Blue PPO
            var theRegion =   $scope.brandData.domain;
            var theseRegions = (theRegion == 'highmarkbcbs.com' || theRegion == 'highmarkbcbsde.com' || theRegion == 'highmarkblueshield.com' || theRegion == 'highmarkbcbswv.com' );
            var isInIncludedPlanName = false;
            var arrPlanNames = ['PPO', 'EPO','PPO BLUE','EPO BLUE'];
            for (var i = 0; i < arrPlanNames.length; i++) {
                isInIncludedPlanName = ($scope.hasThisPlanTypeIgnoreCase(arrPlanNames[i]) > -1 ) ? true : isInIncludedPlanName;
            }

            var breastPumpRegn = theseRegions ||   $scope.isWvMember();

            if( theseRegions && isInIncludedPlanName  && !$scope.isAgOnlyMember() && !$scope.isTraditionalPlanMember()){
                $scope.breastpumpShow = true;
            }

            //Forms library access points
            var formsLibraryBrands = ['hmbcbs', 'hbs', 'hmbcbswv', 'hmbcbsde', 'pp'];
            var brandCode =$scope.brandData.sharedBrandCode;
            if (formsLibraryBrands.indexOf(brandCode) > -1) {
                $scope.formsLibraryAccess = true;                
            }

            //modals

            $scope.popSmartShopper = function(){
                modal.open({
                    template: require('modals/smartShopperModal.html'),
                    controller: ssModal,
                    scope: $scope
                });
            };

            $scope.amedaHandler = function() {
                modal.open({
                    template: require('modals/maternityAmeda.html'),
                    controller: amedaModal,
                    scope: $scope
                });
            };
            $scope.medelaHandler = function() {
                modal.open({
                    template: require('modals/maternityMedela.html'),
                    controller: medelaModal,
                    scope: $scope
                });
            };

            // set up the medical search modal
            $scope.openMedicalSearch = function(){
                var brandCodeValue = theBrand.toLowerCase();
                if($scope.isVitalsLive) {
                    var targUrl = finderLogic.getSapphireSSOUrl($scope.brandData.sharedBrandCode);

                    if ( ['wybcbs', 'ndbcbs', 'mincr'].indexOf(brandCodeValue) > -1) {
                        window.open(targUrl);
                    } else {
                        // if it's vitals, we need a disclaimer for SD
                        $scope.continueToDestination = function () {
                            // var targUrl = finderLogic.getSapphireSSOUrl($scope.brandData.sharedBrandCode);
                            window.open(targUrl);
                            $scope.closeModal();
                        };
                        $scope.siteToLeave = window.location.host.indexOf("mybenefitshome.com") !== -1 ? "Your health plan" : "Highmark";
                        modal.open({
                            heading: "YOU WILL BE REDIRECTED TO SAPPHIRE DIGITAL",
                            template: require('modals/vitalsDisclaimerModal.html'),
                            scope: $scope
                        });
                    }
                } else {
                    window.location = finderLogic.getPropertyByBrandCode($scope.brandData.sharedBrandCode, 'medicalIconTarget');
                }
            };
            
            var theBrand;
            finderLogic.setTeamsiteContent($scope);
            // make ws call ======================================================
            finderService.getCurrentMember().then(function(fullMemberInfo) {
                $scope.switchConfig();
                glbFullMemberInfo = fullMemberInfo;
                $scope.amwellRegUserIndicator=fullMemberInfo.clientList[0].amwellIndicator;
                finderLogic.setFullMemberInfo(fullMemberInfo);
                var theDomain = finderLogic.theDomain();
                theBrand = fullMemberInfo.brand.toLowerCase();
                glbCurMember = finderLogic.setLibStorageAndReturnCurMember(fullMemberInfo);
                finderLogic.currentActiveProducts(fullMemberInfo); // sets an object that the next line needs
                var psp = glbPsp = finderLogic.getPortalSelectedPlan();

                $scope.isMedicare = finderLogic.isMedicare;
                $scope.isNewRx = finderLogic.isNewRx;
                $scope.showLongTerm = finderLogic.showLongTerm();
                $scope.isWYNDMN= ['wybcbs', 'ndbcbs', 'mincr'].indexOf(theBrand.toLowerCase()) > -1;

                // Medical Icon ==============================================================
                var currentMedicalProduct = finderLogic.specificActiveProduct('Medical');
                var latestCoverage = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate('Medical');
                //console.log("Latest Coverage: " + latestCoverage);
                var showMedicalIcon = false;
                var displayBCWorldWide = false;
                var displayProviderCredentials = false;

                var isBlueExtra = finderLogic.isBlueExtra();

                showMedicalIcon = (!isBlueExtra);

                if (showMedicalIcon) { // determine which url the user gets for Medical -----------------------
                    if($scope.isVitalsLive){
                        // set up the medical links in the left menu for the SD changes
                        var medical = {
                            heading: '<span data-teamsite="MBR_FINDADOCTOR_LEFTNAV_ITEM_MEDICAL"></span>',
                            label: '<span data-teamsite="MBR_FINDADOCTOR_LEFTNAV_ITEM_MEDICAL"></span>',
                            onClick: $scope.openMedicalSearch,
                            newWindow: $scope.isVitalsLive && !(brandCode === "ndbcbs" || brandCode === "wybcbs")
                        };
                    } else {
                        var medicalIconTarget = finderLogic.getPropertyByBrandCode(theBrand, 'medicalIconTarget');
                        //Creates the Medical Link

                        var medicalLabel = 'MBR_FINDADOCTOR_LEFTNAV_ITEM_MEDICAL';
                        // everyone except MN stays in same window
                        var newWindowValue = false;

                        // 5/24/16 - switch MN to new window for now
                        //Once all platforms go to In house, change the value to false for everyone.
                        if (theBrand.toLowerCase() === 'mincr') {
                            newWindowValue = false;
                        }
                        var medical = {
                            heading: '<span data-teamsite="MBR_FINDADOCTOR_LEFTNAV_ITEM_MEDICAL"></span>',
                            label: '<span data-teamsite="MBR_FINDADOCTOR_LEFTNAV_ITEM_MEDICAL"></span>',
                            href: medicalIconTarget,
                            newWindow: newWindowValue
                        };
                    }
                    links.push(medical); // pushed the medical link to the leftNav
                }

                $scope.FINDER_HIDE_DEFAULT_HERO = false;
                //TODO: Switch to using AppConfig to check if we should use the default hero or not...
                if (theBrand.toLowerCase() === 'mincr') {
                    $scope.FINDER_HIDE_DEFAULT_HERO = true;
                }

                if (latestCoverage && latestCoverage.telemedicineVendorName != null && latestCoverage.telemedicineVendorName) {
                    teleMedicineVendorName = latestCoverage.telemedicineVendorName.replace(/\s+/g, '').replace("&", "_");
                    $scope.teleMedicineVendorName=teleMedicineVendorName;
                }

                if (theBrand.toLowerCase() === 'mincr') {
                    teleMedicineVendorName = ((teleMedicineVendorName && teleMedicineVendorName.toUpperCase() === 'YES') ? teleMedicineVendorName = "OnlineCareAnywhere" : "DEFAULT");
                }
                displayBCWorldWide = finderLogic.getPropertyByBrandCode(theBrand, 'displayBCWorldWide');
                displayProviderCredentials = finderLogic.getPropertyByBrandCode(theBrand, 'displayProviderCredentials');

                // Vision Icon ===============================================================
                var showVisionIcon = false;
                // var useVisionFunction = false;
                // if they have benefitPlanType = Vision, that's aka Blue Branded
                // ToDo use the method like portalSelectedPlan in next line
                var hasBlueBranded = finderLogic.portalSelectedPlanSpecificProduct('Vision'); //specificActiveProduct('Vision');
                //These next two lines are to check to see whether someone has vision in an embedded medical product. 
                var hasEmbeddedVision = finderLogic.portalSelectedPlanSpecificProduct('Medical') || false; 

                if(hasEmbeddedVision) {
                    if(hasEmbeddedVision.visionCoverage !== null) {
                    hasEmbeddedVision = hasEmbeddedVision.visionCoverage;

                    } else {
                        hasEmbeddedVision = false;
                    }
                }
                
                if(hasEmbeddedVision === "true") {hasEmbeddedVision = true} else {hasEmbeddedVision = false}; 
            
                var isIbcHcrOrExtraBasicOrPlus = finderLogic.isIbcHcrOrExtraBasicOrPlus(fullMemberInfo); // this method includes IBC and IBCAH
                showVisionIcon = (hasBlueBranded || isIbcHcrOrExtraBasicOrPlus || hasEmbeddedVision) ? true : false;

                var inTest = (theDomain.indexOf('https://tenv') > -1 || theDomain.indexOf('https://denv') > -1);
                // 23086    13552     service    tenv3service

            
                //  NOTE: use this when TESTREGION url has a value:
                var tsVisionUrl = (inTest) ? "MBR_FINDADOCTOR_VISION_URL_TESTREGION" : "MBR_FINDADOCTOR_VISION_URL";
                // var tsVisionUrl = "MBR_FINDADOCTOR_VISION_URL";
                teamsite.get(tsVisionUrl).then(
                    function(content) {

                        if(brandCode==='ndbcbs'){
                            $scope.arrCategoryIcons[2].theTarget = theDomain + "/emssvc/x-services/secure/sso/outbound/auto/v1/vendor/VSP";

                        }else if(brandCode==='wybcbs'){
                            $scope.arrCategoryIcons[2].theTarget =  "/login/#/vision-provider-search";
                        }else{
                            $scope.arrCategoryIcons[2].theTarget = theDomain + content;
                        }

                        var visionUrl = theDomain + content;
                        var NDVisionUrl = theDomain + "/emssvc/x-services/secure/sso/outbound/auto/v1/vendor/VSP";
                        if(showVisionIcon){
                            var vision = {};
                        
                            if(brandCode==='ndbcbs'){
                                vision = {
                                    label: '<span data-teamsite="MBR_FINDADOCTOR_LEFTNAV_ITEM_VISION"></span>',
                                    href: NDVisionUrl,
                                    newWindow:true
                                };
                            }
                            else if(brandCode==='wybcbs'){
                                vision = {
                                    label: '<span data-teamsite="MBR_FINDADOCTOR_LEFTNAV_ITEM_VISION"></span>',
                                    href: "/login/#/vision-provider-search",
                                    newWindow:true
                                };
                            }
                            else{
                                vision = {
                                    label: '<span data-teamsite="MBR_FINDADOCTOR_LEFTNAV_ITEM_VISION"></span>',
                                    href: visionUrl
                                };
                            }

                        }
                        // we have to put the vision item in the correct order in the left nav
                        // at this point all the left nave items are in the links array
                        if (showDentalIcon) {
                            links.splice(links.length-1, 0, vision);
                        } else {
                            links.push(vision); // pushed the medical link to the leftNav
                        }
                    }

                );


                // PHARMACY ICON (The Pill)  ======================================================================
                // the next method returns an object with showPharmacyIcon, pharmacyTarget, and usePharmFunction;
                pharmObj = finderLogic.setupFindAPharmacy(currentMedicalProduct, theBrand);

                //backdoor url for testing the ExpressScripts SSO service
                if($location.search()['useESSSO'] == 'true') {
                    pharmObj.usePharmFunction = true;
                }

                //Pushes Pharmacy Link into LeftNav
                $scope.pharmacyTarget = pharmObj.pharmacyTarget;
                $scope.theBrand = theBrand;
                if(pharmObj.showFindAPharmacyMod) {
                    var leftNavPharmacy = {

                        label: '<span data-teamsite="MBR_FINDADOCTOR_LEFTNAV_ITEM_PHARMACY"></span>',
                        // label: '<span>snot</span>'
                        "onClick": function() { $scope.funcs.categoryIconFunction('pharmacy')},
                        // href: 'snot'
                        newWindow: true

                    };
                    if (pharmObj.usePharmFunction) {
                        leftNavPharmacy.onClick = function() { $scope.funcs.categoryIconFunction('pharmacy'); };
                    }
                    links.push(leftNavPharmacy); // pushed the pharmacy link to the leftNav
                    //console.log('Links: ' + JSON.stringify(links));

                } else {
                    // Rx carve-outs do not have drug products or a value returned for pharmacyBenefitProviderIndicator.
                    // So we must check if the member is part of a client that has a Rx carve-out pharmacy vendor.
                    var callDisplayHide = displayOrHideItemsWrapper.displayOrHideItemsCall(glbFullMemberInfo.clientList[0].clientNumber, sessionData.groupNumber, ["rxCarveOutVendor"], theBrand);

                    callDisplayHide.then(function (data) {
                        rxCarveOutVendor = null;

                        if (data && data.qualifierInfo && data.qualifierInfo.rxCarveOutVendor && data.qualifierInfo.rxCarveOutVendor.length > 0) {
                            rxCarveOutVendor = data.qualifierInfo.rxCarveOutVendor;
                            $scope.pharmacyTarget = null;

                            var leftNavPharmacy = {
                                "label": '<span data-teamsite="MBR_FINDADOCTOR_LEFTNAV_ITEM_PHARMACY"></span>',
                                "newWindow": true,
                                "onClick": function() { $scope.funcs.categoryIconFunction('pharmacy'); }
                            };
                            links.push(leftNavPharmacy);

                            $scope.arrCategoryIcons[1].showThisCategoryIcon = pharmObj.showThisCategoryIcon = true;
                            $scope.arrCategoryIcons[1].useFunction = pharmObj.useFunction = true;
                        }
                    });
                }

                // Dental Icon ===============================================================

                var useDentalFunction = false;
                var dentalUrlTarget = '';
                var currentDentalProduct = finderLogic.specificActiveProduct('Dental');
                var hasIntegratedDentalProduct = (currentMedicalProduct && currentMedicalProduct.dentalCoverage == 'true');
                var hasSomeKindOfDentalCoverage = (hasIntegratedDentalProduct || currentDentalProduct);
                var theyareDe =  finderLogic.getPropertyByBrandCode(theBrand, 'regionIsDe');
                if (hasSomeKindOfDentalCoverage || isIbcHcrOrExtraBasicOrPlus) {
                    showDentalIcon = true;
                    var theyareWpaCreWv = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsWpaCreWv');
                    var theyareIbccrOrIbcah = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsAnIbc');

                    if (theyareWpaCreWv || theBrand==='mincr' || theyareDe) {
                        var leaving = finderLogic.getDomainTheyAreLeaving(theBrand);


                        var theyareInABlueEdgeGroup = finderLogic.isInBlueEdgeGroup(currentMedicalProduct, currentDentalProduct);
                        useDentalFunction = (theyareInABlueEdgeGroup || theyareDe);

                    }
                    if (theyareIbccrOrIbcah) {
                        var regionIsIbcCore = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsIbcCore');
                        if (regionIsIbcCore) {
                            useDentalFunction = true;

                        } else {
                            // they are IBC AH ------------------------------
                            useDentalFunction = true;

                        }
                    }

                    if (showDentalIcon) {
                        var dental = {
                            label: '<span data-teamsite="MBR_FINDADOCTOR_LEFTNAV_ITEM_DENTAL"></span>',
                            onClick: function() {
                            var hasMedicareDentalMN = finderLogic.getPortalSelectedPlan().mnMedicareDentalIndicator;
                            if (hasMedicareDentalMN) {
                                teamsite.get("MBR_FINDADOCTOR_DENTAL_LINK").then(function (data) {
                                    $scope.dentalLink = data;
                                    window.open($scope.dentalLink);
                                });
                            } else {
                                popDentalModal();
                            }
                            },
                            newWindow:true
                        };
                        links.push(dental); // pushed the dental link to the leftNav
                    }
                }

                if(showMedicalIcon){
                    if (pharmObj.showFindAPharmacyMod){
                        leftNavDescription = '<span data-teamsite="MBR_FINDADOCTOR_LEFTNAVIGATION_MEDICALPHARMACY_TEXT"></span>';
                    }
                    else {
                        leftNavDescription = '<span data-teamsite="MBR_FINDADOCTOR_LEFTNAVIGATION_MEDICAL_TEXT"></span>';
                    }
                }

                startYourSearch.description = leftNavDescription;

                links.unshift(startYourSearch);

                //Retrieves TS content for the icons
                teamsite.get("MBR_FINDADOCTOR_MEDICAL_ICON_IMG").then(function(content) {$scope.arrCategoryIcons[0].thePath = content });
                teamsite.get("MBR_FINDADOCTOR_PHARMACY_ICON_IMG").then(function(content) { $scope.arrCategoryIcons[1].thePath = content });
                teamsite.get("MBR_FINDADOCTOR_VISION_ICON_IMG").then(function(content) { $scope.arrCategoryIcons[2].thePath = content });
                teamsite.get("MBR_FINDADOCTOR_DENTAL_ICON_IMG").then(function(content) { $scope.arrCategoryIcons[3].thePath = content });
                teamsite.get("MBR_FINDADOCTOR_TALKTODOC_LINK").then(function(content) {  $scope.talkToDocLink = content });
                var arrShowIcons = [showMedicalIcon, pharmObj.showFindAPharmacyMod, showVisionIcon, showDentalIcon];
                var numIcons = 0;
                for (var i = 0; i < arrShowIcons.length; i++) {
                    numIcons = (arrShowIcons[i] == true) ? numIcons + 1 : numIcons;
                }
            
                $scope.moreThanTwoIcons = (numIcons > 2);

                var medicalWindowTarget    =   "_blank";
                //For Minnesota, we go In house provider search. Hence, it should open in the same window.
                //Once all platforms go to In house, change the value to false for everyone.
                // if(theBrand.toLowerCase() === 'mincr'){
                //     medicalWindowTarget = "_self";
                // }

                if($scope.isVitalsLive) {
                    // These are the category icons for the SD changes
                    $scope.arrCategoryIcons = [
                        {
                            theCaption: 'MBR_FINDADOCTOR_MEDICAL_ICON',
                            //theTarget: medicalIconTarget,
                            // thePath: 'images/finder/medical.png',
                            // thePath: 'images/finder/dental.png',
                            useFunction: $scope.openMedicalSearch,
                            showThisCategoryIcon: showMedicalIcon,
                            altText: '',
                            isFirst: true,
                            newWindow: "_self",
                            iconName: 'medical'
                        },
                        {
                            theCaption: 'MBR_FINDADOCTOR_PHARMACY_ICON',
//                        theTarget: pharmObj.pharmacyTarget,
                            showThisCategoryIcon: pharmObj.showFindAPharmacyMod,
                            altText: 'Find Pharmacy Resource',
//                        useFunction: pharmObj.usePharmFunction,
                            iconName: 'pharmacy',
                            useFunction: true,
                            newWindow: '_blank'
                        },
                        {
                            theCaption: 'MBR_FINDADOCTOR_VISION_ICON',
                            // theTarget: visionUrl,
                            // thePath: 'images/finder/vision.png',
                            // thePath: 'images/finder/dental.png',
                            // useFunction: useVisionFunction,
                            showThisCategoryIcon: showVisionIcon,
                            altText: '',
                            newWindow: (theBrand.toLowerCase() === 'ndbcbs' || theBrand.toLowerCase() === 'wybcbs') ? "_blank" : "_self",
                            iconName: 'vision'
                        },
                        {
                            theCaption: 'MBR_FINDADOCTOR_DENTAL_ICON',
                            //theTarget: dentalUrlTarget,
                            // thePath: 'images/finder/dental.png',
                            useFunction: true,
                            showThisCategoryIcon: showDentalIcon,
                            altText: '',
                            iconName: 'dental'

                        }
                    ];
                } else {
                    $scope.arrCategoryIcons = [
                        {
                            theCaption: 'MBR_FINDADOCTOR_MEDICAL_ICON',
                            theTarget: medicalIconTarget,
                            // thePath: 'images/finder/medical.png',
                            // thePath: 'images/finder/dental.png',
                            showThisCategoryIcon: showMedicalIcon,
                            altText: '',
                            isFirst: true,
                            newWindow: "_self"
                        },
                        {
                            theCaption: 'MBR_FINDADOCTOR_PHARMACY_ICON',
//                        theTarget: pharmObj.pharmacyTarget,
                            showThisCategoryIcon: pharmObj.showFindAPharmacyMod,
                            altText: 'Find Pharmacy Resource',
//                        useFunction: pharmObj.usePharmFunction,
                            iconName: 'pharmacy',
                            useFunction: true,
                            newWindow: '_blank'
                        },
                        {
                            theCaption: 'MBR_FINDADOCTOR_VISION_ICON',
                            // theTarget: visionUrl,
                            // thePath: 'images/finder/vision.png',
                            // thePath: 'images/finder/dental.png',
                            // useFunction: useVisionFunction,
                            showThisCategoryIcon: showVisionIcon,
                            altText: '',
                            newWindow:(theBrand.toLowerCase() === 'ndbcbs' || theBrand.toLowerCase() === 'wybcbs') ? "_blank" : "_self",
                            iconName: 'vision'
                        },
                        {
                            theCaption: 'MBR_FINDADOCTOR_DENTAL_ICON',
                            //theTarget: dentalUrlTarget,
                            // thePath: 'images/finder/dental.png',
                            useFunction: true,
                            showThisCategoryIcon: showDentalIcon,
                            altText: '',
                            iconName: 'dental'

                        }
                    ];
                }



                /**** Right Column Items  *********************************************************************/

                var showCHC = finderLogic.getPortalSelectedPlan().chcIndicator == "true" ? true : false;
                var chcTargetUrl = null;
                if(showCHC){
                    chcTargetUrl = '#';
                }

                var showCCOE = finderLogic.getPortalSelectedPlan().ccoeIndicator;
                $scope.centerOfExcellenceShow = (showCCOE === true || showCCOE === "true");

                $scope.showBoysTown = $cookies.get('mbrClientNumber') == '271006' ? true : false;
                $scope.chNetwork = $cookies.get('mbrClientNumber') == '276555' ? true : false;

                //Item2: My Care Navigator
                var showMyCareNavigator = finderLogic.isMyCareNavigator(theBrand);

                //Item3: BDC; Eg: https://tenv3.highmarkbcbs.com/bdc/index.html?group=01515104&userName=BOFLING&targetSite=hmbcbs
                var isBDCMember = finderLogic.isBDC();
                var showBDCToolTip = isBDCMember ? false : true;
                var bdcTitle = isBDCMember ? 'MBR_FINDADOCTOR_BLUEDISTINCTIONCENTER_HEADLINE_BDC' : 'MBR_FINDADOCTOR_BLUEDISTINCTIONCENTER_HEADLINE_NONBDC';
                var bdcContent = isBDCMember ? 'MBR_FINDADOCTOR_BLUEDISTINCTIONCENTER_TEXT_BDC' : 'MBR_FINDADOCTOR_BLUEDISTINCTIONCENTER_TEXT_NONBDC';
                var bdcHrefTitle = isBDCMember ? 'MBR_FINDADOCTOR_BLUEDISTINCTIONCENTER_HREFTEXT_BDC' : 'MBR_FINDADOCTOR_BLUEDISTINCTIONCENTER_HREFTEXT_NONBDC';
                var bdcPostHrefContent = isBDCMember ? 'MBR_FINDADOCTOR_BLUEDISTINCTIONCENTER_POSTHREFTEXT_BDC' : 'MBR_FINDADOCTOR_BLUEDISTINCTIONCENTER_POSTHREFTEXT_NONBDC';
                var bdcMyBenHref = isBDCMember ? '{{domain}}/bdc/index.html?group={{groupNumber}}&userName={{userAlias}}&targetSite={{brand}}' : "" ;
                var bdcMyBenText = isBDCMember ? 'MBR_FINDADOCTOR_MYBENHREFTEXT' : "";
                var bdcMyBenPostHrefText = isBDCMember ? 'MBR_FINDADOCTOR_MYBENPOSTHREFTEXT' : "";
                // This ensures the tooltip that was requested for this change is in place
                var bdcToolTipContent = !isBDCMember || $scope.isVitalsLive ? "MBR_FINDADOCTOR_BLUEDISTINCTIONCENTER_TOOLTIP" : "";

                var bdcMyBenHrefRefined = finderLogic.processBDCContent(bdcMyBenHref);


                //Item4: Hospital Advisor
                var isExchangeUser = finderLogic.isExchangeUser(psp);
                var showHospitalAdvisor = finderLogic.isHospitalAdvisor(psp);
                var hospitalAdvisorUrl = finderLogic.getHospitalAdvisorUrl(isExchangeUser);
                var isGeneralMills = false; //setting this indicator to false even though it is General Mills user
               // RF664648 When a General Mills member logs into the member portal,
               // they have a link to Castlight on the post-login member dashboard page.
               // General Mills agreement with Castlight ended on 1/1/2019
               // and the Castlight link should be replaced with a link to the Care Cost Estimator.
               // BCBSMN should not allow GMI members to access Castlight through the member portal.

                // modified these items to display differently
                // depending on whether the vitals flag is on or off
                $scope.arrLeftColItems = [
                    {   // Added for Boys Town users
                        theTitle: 'MBR_FINDADOCTOR_BOYSTOWN_HEADLINE',
                        titleWithLink:true,
                        theP: 'Search for Boys Town providers for Tier One Benefits.',
                        theHrefTitle: '',
                        postHrefP: '',
                        toolTipContent: '',
                        icon: 'icon-agent',
                        iconClass: 'hm-icon',
                        iconImagePath: '',
                        iconImage: false,
                        showThisFocusIcon: $scope.showBoysTown,
                        showToolTip: false,
                        showNewWindowIcon: false,
                        vitalsContent: true,
                        isTopLeftColSection: 'first-item'
                    },
                    {   // Added for Boys Town users
                        theTitle: "MBR_FAD_CHILDREN_HOSPITAL_NETWORK",
                        titleWithLink:true,
                        theP: "Search for Children's Hospital Network providers for Tier One Benefits.",
                        theHrefTitle: '',
                        postHrefP: '',
                        toolTipContent: '',
                        icon: 'icon-agent',
                        iconClass: 'hm-icon',
                        iconImagePath: '',
                        iconImage: false,
                        showThisFocusIcon: $scope.chNetwork,
                        showToolTip: false,
                        showNewWindowIcon: false,
                        vitalsContent: true,
                        isTopLeftColSection: 'first-item'
                    },
                    {   //Item2: My Care Navigator
                        theTitle: 'MBR_FINDADOCTOR_MYCARENAVIGATOR_HEADLINE',
                        theP: 'MBR_FINDADOCTOR_MYCARENAVIGATOR_TEXT',
                        theHrefTitle: '',
                        postHrefP: '',
                        toolTipContent: '',
                        icon: 'icon-agent',
                        iconClass: 'hm-icon',
                        iconImagePath: '',
                        iconImage: false,
                        showThisFocusIcon: showMyCareNavigator,
                        showToolTip: false,
                        showNewWindowIcon: false,
                        vitalsContent: false
                    },
                    {//Item3: BDC - Search Blue Distinction
                        theTitle: bdcTitle,
                        theP: bdcContent,
                        hasHref: true,
                        theHref: (theBrand.toLowerCase() === 'wybcbs') ? 'https://www.bcbs.com/about-us/capabilities-initiatives/blue-distinction' : '#/finder',
                        theHrefTitle: bdcHrefTitle,
                        hasPostHrefP: true,
                        postHrefP: bdcPostHrefContent,
                        bdcMyBenHref: bdcMyBenHrefRefined,
                        hasBDCMyBenHrefText: (bdcMyBenText)?true:false,
                        bdcMyBenHrefText: bdcMyBenText,
                        hasBDCMyBenPostHrefText: (bdcMyBenPostHrefText)?true:false,
                        bdcMyBenPostHrefText: bdcMyBenPostHrefText,
                        toolTipContent: bdcToolTipContent,
                        icon: 'icon-clinic',
                        iconClass: 'hm-icon',
                        iconImagePath: '',
                        iconImage: false,
                        modalType: (theBrand.toLowerCase() === 'wybcbs') ? 'wybcbs' :'BDC',
                        showThisFocusIcon: true,
                        showToolTip: showBDCToolTip,
                        showNewWindowIcon: true,
                        vitalsContent: false
                    },
                    {   //Item4: Hospital Advisor temp comment
                        theTitle: 'MBR_FINDADOCTOR_COMPAREHOSPITALS_HEADLINE',
                        theP: 'MBR_FINDADOCTOR_COMPAREHOSPITALS_TEXT',
                        theHrefTitle: '',
                        postHrefP: '',
                        toolTipContent: '',
                        iconImagePath: 'images/finder/HospitalAdvisor.png',
                        iconImage: true,

                        altIconImage: 'Hospital Advisor Icon',
                        showThisFocusIcon: showHospitalAdvisor,
                        showToolTip: false,
                        showNewWindowIcon: false,
                        hasTitleIcon: false,
                        titleIcon: 'icon-webmd',
                        webMdStyle: 'webMdStyle',
                        vitalsContent: false
                    },
                    {   //Item1-Vitals: My Care Navigator
                        theTitle: 'My Care Navigator',
                        theP: '<a href="tel:1888BLUE428" target="_blank">Call&nbsp;<span class="iconHeightZero hm-icon icon-openInNewWindow"></span></a> 1-888-BLUE-428. We\'ll help you find a doctor, make appointments, and more',
                        theHrefTitle: '',
                        postHrefP: '',
                        toolTipContent: '',
                        icon: 'icon-agent',
                        iconClass: 'hm-icon',
                        iconImagePath: '',
                        iconImage: false,
                        showThisFocusIcon: true,
                        showToolTip: false,
                        showNewWindowIcon: false,
                        vitalsContent: true,
                        isTopLeftColSection: ($scope.showBoysTown || $scope.chNetwork)? false :'first-item',
                        titleWithLink:false
                    },
                    {   //Item1-Vitals: My Care Navigator
                        theTitle: 'Compare Hospitals',
                        theP: '<a href="https://www.medicare.gov/hospitalcompare/search.html?cm_sp=Medicare.gov%20Provider%20Search-_-FIND%20A%20DOCTOR-_-HOME" target="_blank">Compare&nbsp;<span class="iconHeightZero hm-icon icon-openInNewWindow"></span></a> quality measures for your unique health needs.',
                        theHrefTitle: '',
                        postHrefP: '',
                        toolTipContent: '',
                        iconImagePath: 'images/finder/HospitalAdvisor.png',
                        iconImage: true,
                        altIconImage: 'Hospital Advisor Icon',
                        showThisFocusIcon: true,
                        showToolTip: false,
                        showNewWindowIcon: false,
                        vitalsContent: true,
                        titleWithLink:false
                    },
                    {   //Item1-Vitals: My Care Navigator
                        theTitle: 'Blue Distinction Centers',
                        theP: '<a href="http://www.bcbs.com/innovations/bluedistinction/center-list/selector-map.html?cm_sp=Blue%20Distinction%20Center%20Finder-_-FIND%20A%20DOCTOR-_-HOME" target="_blank">Find&nbsp;<span class="iconHeightZero hm-icon icon-openInNewWindow"></span></a> superior care in 6 key health categories.',
                        theHrefTitle: '',
                        postHrefP: '',
                        toolTipContent: bdcToolTipContent,
                        icon: 'icon-clinic',
                        iconClass: 'hm-icon',
                        iconImagePath: '',
                        iconImage: false,
                        showThisFocusIcon: true,
                        showToolTip: true,
                        showNewWindowIcon: false,
                        vitalsContent: true,
                        titleWithLink:false
                    }
                ];

                if(isGeneralMills){//this if block will never be executed. We are getting rid of castlight.
                    var castlightTargetUrl = finderLogic.getCastlightUrl();
                    $scope.arrLeftColItems.unshift(
                        {
                            theTitle:'MBR_FINDADOCTOR_CASTLIGHT_HEADER',
                            theP: 'MBR_FINDADOCTOR_CASTLIGHT_TEXT',
                            theHrefTitle: 'MBR_FINDADOCTOR_CASTLIGHT_LINKTEXT',
                            hasHref: true,
                            theHref: castlightTargetUrl,
                            postHrefP: '',
                            toolTipContent: '',
                            icon: 'icon-savings',
                            iconClass: 'hm-icon',
                            iconImagePath: '',
                            iconImage: false,
                            showThisFocusIcon: true,
                            showToolTip: false,
                            showNewWindowIcon: false,
                            isTopLeftColSection: 'isTopLeftColSection',
                            vitalsContent: false
                        }
                    );

                }else{

                    cceService.shouldDisplay().then(function (hasCCE) {
                        if (hasCCE) {
                            var cceTargetUrl = finderLogic.getCCEUrl();
                            var external = false;
                            if($scope.isVitalsLiveCCE && ['wybcbs', 'ndbcbs', 'mincr'].indexOf(theBrand.toLowerCase()) > -1){
                                cceTargetUrl = finderLogic.getSapphireSSOUrl($scope.brandData.sharedBrandCode);
                                external = true; //commented line for build
                            }
                            $scope.arrLeftColItems.unshift(
                                {   //Item1: CCE
                                    theTitle:'MBR_FINDADOCTOR_COMPARECOSTS_HEADER',
                                    theP: 'MBR_FINDADOCTOR_COMPARECOSTS_TEXT',
                                    theHrefTitle: 'MBR_FINDADOCTOR_COMPARECOSTS_LINKTEXT',
                                    hasHref: true,
                                    theHref: cceTargetUrl,
                                    target: external ? '_blank': '_self',
                                    postHrefP: '',
                                    toolTipContent: '',
                                    icon: 'icon-savings',
                                    iconClass: 'hm-icon',
                                    iconImagePath: '',
                                    iconImage: false,
                                    showThisFocusIcon: hasCCE,
                                    showToolTip: false,
                                    showNewWindowIcon: $scope.isVitalsLive && $scope.isWYNDMN,
                                    isTopLeftColSection: 'isTopLeftColSection',
                                    vitalsContent:  false
                                }
                            );
                        } else if(showCHC && chcTargetUrl){
                            $scope.arrLeftColItems.unshift(
                                {   //Item1: CHC
                                    theTitle:'MBR_FINDADOCTOR_COMPARECOSTS_HEADER',
                                    theP: 'MBR_FINDADOCTOR_COMPARECOSTS_CHC_TEXT',
                                    theHrefTitle: 'MBR_FINDADOCTOR_COMPARECOSTS_CHC_LINKTEXT',
                                    hasHref: false,
                                    theHref: null,
                                    postHrefP: '',
                                    toolTipContent: '',
                                    icon: 'icon-savings',
                                    iconClass: 'hm-icon',
                                    iconImagePath: '',
                                    iconImage: false,
                                    showThisFocusIcon: showCHC,
                                    showToolTip: false,
                                    showNewWindowIcon: false,
                                    isTopLeftColSection: 'isTopLeftColSection',
                                    ngClick: function(){
                                        ssoHelper.chcSsoCall();
                                    },
                                    vitalsContent: false
                                }
                            );
                        }
                    });
                }

                /***** End of Left Column *************************/

            ////Allina Fix for MN groups
                var pennStatePortalClients = "069151,223885,240156";
                var cnBasedContent = (pennStatePortalClients.indexOf(sessionData.memberInfo.clientList[0].clientNumber) < 0) ? "": "_PSHOD";
                //generate Telemedicine vendor key.
                var teleMedicineKey = "MBR_TELEMEDICINE_PROVIDER_" + teleMedicineVendorName.toUpperCase() + cnBasedContent;
                var teleMedicineVendorKey = {
                    "SUPRESS_ONLINECARE_GROUPS" : "SUPRESS_ONLINECARE_GROUPS"
                };
                teleMedicineVendorKey[teleMedicineKey] = teleMedicineKey;
                services.rbsmbl.contentInfo().getContent(teleMedicineVendorKey).then(function(content) {

                    if (content && content.data && content.data.payload && content.data.payload.contentResponse){
                        if (($scope.brandData.sharedBrandCode.toUpperCase() == "MINCR") && (content.data.payload.contentResponse.length > 0)) {
                            var arrGrps = content.data.payload.contentResponse[0].value.replace(/\s/g, '').split(",");
                            hasTeleleMedicine = (arrGrps.indexOf(finderLogic.getPortalSelectedPlan().groupNumber) == -1) ;
                            $scope.hasTeleleMedicine = hasTeleleMedicine ;
                            $scope.teleMedicineContent = JSON.parse(content.data.payload.contentResponse[1].value);
                        }
                        else {
                            hasTeleleMedicine = true;
                            if(content.data.payload.contentResponse.length > 1) {
                                $scope.teleMedicineContent = JSON.parse(content.data.payload.contentResponse[1].value);
                            }
                            $scope.hasTeleleMedicine = true;
                            $scope.hasTeleleMedicine = ($scope.brandData.sharedBrandCode.toUpperCase() === "WYBCBS") ?  false : true;
                            if($scope.isAlbertsons){
                                $scope.teleMedicineContent = JSON.parse(content.data.payload.contentResponse[1].value);
                                $scope.teleMedicineContent.link = "Teledoc";
                            }else{
                            $scope.teleMedicineContent = JSON.parse(content.data.payload.contentResponse[1].value);
                            }
                        }
                    }
                }, function(errObj) {console.log('errObj', errObj)});

                $scope.rightColNonEmpty = false;
                if(hasTeleleMedicine == true || displayBCWorldWide == true){
                    $scope.rightColNonEmpty = true;
                }
                var BCworldWidePara = 'MBR_FINDADOCTOR_BLUECARDWORLDWIDE_TEXT';
                if(displayBCWorldWide && $scope.isVitalsLive &&  theBrand.toLowerCase() == 'wybcbs'){
                    BCworldWidePara ='MBR_FINDADOCTOR_BLUECARDWORLDWIDE_TEXT_SAPPHIRE';
                }
                $scope.arrRightColItems = [
                    {
                        theTitle: 'MBR_FINDADOCTOR_BLUECARDWORLDWIDE_HEADLINE',
                        theP: BCworldWidePara,
                        showItem: displayBCWorldWide && !glbPsp.isNDMedicaid,
                        showNewWindowIcon:false,
                        hasHref: false
                    },
                    {
                        theTitle: 'MBR_FINDADOCTOR_PROVIDER_CRENDTIALS_HEADLINE',
                        theP: 'MBR_FINDADOCTOR_PROVIDER_CRENDTIALS_DESCRIPTION',
                        showItem: displayProviderCredentials,
                        showNewWindowIcon:false,
                        hasHref: false
                    }

                ];


                $scope.sidebar.links = links;

                if(glbPsp.isNDMedicaid){
                    $scope.arrLeftColItems = []
                    $scope.arrCategoryIcons = [$scope.arrCategoryIcons[0]];
                    $scope.sidebar.links = [links[0], links[1]];
                }

            });
            // end of handling initial ws call ==========================

            window.myScope = $scope;
            $scope.theHeading = "MBR_FINDADOCTOR_PAGETITLE";
            // $scope.imgs = {};


            var popDentalModal =  function() {
                // modified call so that it opens the drawer if SD is active
                $('.isFirst-').removeClass("isActive");
                if($scope.isVitalsLive && !$scope.isWYNDMN ){
                    $scope.activeDrawer = 'dental';
                    $('.dental').addClass("isActive");

                }  else {
                    $scope.findADocUrl = "/find-a-doctor/#/home";
                    if($scope.isVitalsLive && ['ndbcbs'].indexOf(theBrand.toLowerCase()) > -1){
                        $scope.findADocUrl =  finderLogic.getSapphireSSOUrl(theBrand.toLowerCase());
                    }
                    // NOTE: in finderLogic.getTeamsiteContent( )  we set properties in $scope for the various modals
                    modal.open({
                        template: require('modals/dentalModal.html'),
                        controller: myModal,
                        scope: $scope
                    });
                }
            };

            var popPharmacyModal = function () {
                $scope.currentYear = new Date().getFullYear();
                if (!$scope.pharmacyContent) {
                    teamsite.get("MBR_FINDAPHARMACY_CONTENT").then(function (data) {
                        $scope.pharmacyContent = angular.fromJson(data);
                        $scope.pharmacyLinks = finderLogic.getPharmacyNetworks($scope.pharmacyContent, $scope.theBrand);
                    });
                }
                modal.open({
                    template: require('modals/pharmacyModal.html'),
                    controller: myPharmacyModal,
                    scope: $scope
                });

            };
            var redirectNewWindow = function(theTarget){
                window.open(theTarget, '_blank');
            };

            $scope.funcs = {};
            // This handles Dental icon click and Prescription Services (the pill) click ----------------------
            $scope.funcs.categoryIconFunction = function(iconClicked) {
                // On Medical click ----------------------------------------------
                if(iconClicked == 'medical'){
                    $scope.openMedicalSearch();
                }

                // On Tooth click ------------------------------------------------
                if (iconClicked == 'dental') {
                    var hasMedicareDentalMN = finderLogic.getPortalSelectedPlan().mnMedicareDentalIndicator;
                    if (hasMedicareDentalMN) {
                        teamsite.get("MBR_FINDADOCTOR_DENTAL_LINK").then(function (data) {
                            $scope.dentalContent = data;
                            window.open($scope.dentalContent);
                        });
                    } else {
                        popDentalModal();
                    }
                }

                // }
                // On Pill click -------------------------------------------------
                if (iconClicked == 'pharmacy') {
                    // check to see if the member is Medicare, and if they are in WPA/WV/CPA region.
                    if ($scope.isNewRx) {
                        if ($scope.showLongTerm) {
                            window.open(finderLogic.newRxHREF(),"searchRXWindow");
                        } else {
                            $scope.showNewRx = !$scope.showNewRx;
                        }
                    } else {
                        var sitePromo = "";
                        var curPBMIndicator = finderLogic.getPortalSelectedPlan().pharmacyBenefitProviderIndicator;

                        if (curPBMIndicator === "Medco" || curPBMIndicator === "Prime" || curPBMIndicator === "ExpressScripts") {
                            sitePromo = "cm_sp=" + curPBMIndicator + "-_-Prescription%20Services%20" + curPBMIndicator + "-_-PRESCRIPTION%20SERVICES";
                        }
                        // hit the initial service -----------------------------------
                        var prescriptServiceSsoResponse = pharmacyServiceWrapper.doPharmacySsoCall(glbFullMemberInfo, glbCurMember, glbPsp, 'locatePharmacy', rxCarveOutVendor);
                        var ssoWindowName = "_blank";
                        //SAML PKI changes.
                        var isWpaCre = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsWpaCre');
                        var isDe = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsDe');
                        var isWv = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsWv');
                        var isAllMbh = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsAllMbh');
                        if (curPBMIndicator === "Medco" && (isWpaCre || isDe || isWv || isAllMbh)) {
                            var ssoPPB = (isWpaCre || isDe) ? '' : 'PPB';
                            var ssoUrl = window.location.origin + '/emssvc/x-services/secure/sso/outbound/auto/v1/vendor/MEDCO' + ssoPPB + '?redirectInfo=locatepharmacy';
                            var vendorSSODetails = {
                                ssoUrl: ssoUrl,
                                ssoRequestType: 'REDIRECT'
                            };
                            vendorSSOUtility.genericHandleSSOResponse(vendorSSODetails, sitePromo, ssoWindowName);
                        } else if (prescriptServiceSsoResponse !== null) {
                            prescriptServiceSsoResponse.then(function (vendorSSOStuff) {
                                vendorSSOUtility.genericHandleSSOResponse(vendorSSOStuff, sitePromo, ssoWindowName);
                            }, function(error) {
                                vendorSSOUtility.genericHandleSSOFailure(error, ssoWindowName);
                            });
                        } else {
                            vendorSSOUtility.genericHandleSSOFailure("", ssoWindowName);
                        }
                    }
                }
            };
            $scope.funcs.getFindDoctorTarget = function() {
                return 'https://psa.webmdhealth.com/Fprovider.aspx?f=psaIBCHighmark100109&acp=IN11,IN25,IN26,IN27,IN28,IN29,IN30&xf=ibc100109';
            };

            //******************************************************************************************
            $scope.funcs.popTeladocModal = function() {

                var togetherBluePlan = false;
                var selectedGroup = $cookies.get('mbrGroupNumber');
                for (var i = 0; i < sessionData.memberInfo.clientList.length; i++) {
                    var clientInfo = sessionData.memberInfo.clientList[i];
                    for (var j = 0; j < clientInfo.groupList.length; j++) {
                        var groupInfo = clientInfo.groupList[j];
                        if ((selectedGroup === groupInfo.groupNumber) && (groupInfo.togetherBlueIndicator)) {
                            togetherBluePlan = true;
                            break;
                        }
                    }
                }
                // NOTE: in finderLogic.getTeamsiteContent( )  we set properties in $scope for the various modals
                if(!togetherBluePlan){
                    modal.open({
                        template: require('modals/teladocModal.html'),
                        controller: myModal,
                        scope: $scope
                    });
                } else {
                    window.open('/login/#/virtual-medicine', "_self");
                }
            };

            $scope.funcs.closeTeladocModal = function(theTarget) {
                if ($scope.teleMedicineContent.sso !== undefined) {
                    var ssoParams = $scope.teleMedicineContent.sso[theTarget];

                    var ssoAppConfig = null;
                    if (ssoParams && ssoParams.disableAppConfigId && ssoParams.disableAppConfigId.length > 0 && sessionData && sessionData.appConfig && sessionData.appConfig.FIND_A_DOCTOR && sessionData.appConfig.FIND_A_DOCTOR[ssoParams.disableAppConfigId]) {
                        ssoAppConfig = sessionData.appConfig.FIND_A_DOCTOR[ssoParams.disableAppConfigId];
                    }

                    if (ssoAppConfig !== null && ssoAppConfig.booleanValue === true) {
                        // If SSO is disabled by AppConfig, then just open as a URL in new window.
                        window.open($scope.teleMedicineContent.url[theTarget].href);
                    } else {
                        var ssoParameters = {
                            clientNumber: glbFullMemberInfo.clientList[0].clientNumber,
                            groupNumber: glbPsp.groupNumber,
                            targetType: ssoParams.targetType,
                            category: ssoParams.category,
                            vendorName: ssoParams.vendorName,
                            brand: $scope.brandData.sharedBrandCode.toUpperCase()
                        };
                        vendorSSOUtility.performSSO(ssoParameters, ssoParams.targetLocation, ssoParams.sitePromotionStr);
                    }
                } else {
                    window.open($scope.teleMedicineContent.url[theTarget].href);
                }
                modal.close({
                    template: require('modals/teladocModal.html'),
                    controller: myModal,
                    scope: $scope
                });
            };

            //Act207 Modal Begin

            $scope.funcs.popAct207Modal = function() {
                modal.open({
                    template: require('modals/act207Modal.html'),
                    controller: myModal,
                    scope: $scope
                });
            };


            //GENERIC FUNCTION TO POP MODAL; Configure the Modal below
            $scope.funcs.popModal = function(theModalType) {
                if(theModalType == 'BDC') {
                    $scope.funcs.popBDCModal();
                }

                if(theModalType == 'Teladoc') {
                    $scope.funcs.popTeladocModal();
                }
                if(theModalType == 'AmwellPopRem') {
                    var environment = window.location.hostname.split('.')[0];
                    var env = environment.slice(1);
                    var targetUrl = "";
                    var ssoUrl = "";
                    var stagingEnv=["env7","env4","env1","env3","envc","envb"];
                    var iotEnv=["env6","env2"];
                    if (stagingEnv.indexOf(env)!=-1 ) { 
                        targetUrl = $scope.switchFlow.staging.regUrl ;
                        ssoUrl = $scope.switchFlow.staging.ssoUrl ;                                        
                        } 
                    else if (iotEnv.indexOf(env)!=-1) {
                        targetUrl = $scope.switchFlow.iot.regUrl ;
                        ssoUrl = $scope.switchFlow.iot.ssoUrl ; 
                        } 
                    else {
                        targetUrl = $scope.switchFlow.production.regUrl;
                        ssoUrl = $scope.switchFlow.production.ssoUrl;
                    }
                    if($scope.amwellRegUserIndicator && $scope.switchFlow.ssoSwitch.toUpperCase()=="TRUE"){
                        window.open(ssoUrl, "_blank");
                    }else{
                        window.open(targetUrl, "_blank");
                    }
                }
            };

            /**************
             BDC MODAL BEGIN
             **************/
            $scope.funcs.popBDCModal = function() {
                // NOTE: in finderLogic.getTeamsiteContent( )  we set properties in $scope for the various modals
                modal.open({
                    template: require('modals/bdcModal.html'),
                    controller: myModal,
                    scope: $scope
                });
            };

            $scope.funcs.closeBDCModal = function(theTarget) {
                window.open('http://www.bcbs.com/innovations/bluedistinction/center-list/selector-map.html');
                //this.closeModal();
                modal.close({
                    template: require('modals/bdcModal.html'),
                    controller: myModal,
                    scope: $scope
                });
            };
            /**************
             BDC MODAL END
             **************/
    }]);
    return [
        {
            state: "finder",
            postPassword: true,
            appConfigMaintenanceApp: 'FIND_A_DOCTOR',
            appConfigMaintenanceKey: 'FULL_MAINTENANCE',
            url: "/finder",
            views: {
                'body@': {
                    template: require('pages/finder.html'),
                    controller: "pages.finder"
                }
            }
        }
    ];
};