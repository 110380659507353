module.exports = function(app) {
	app.filter("copayLabel", function(){
		return function($value) {
			switch($value) {
				case "EMR":
					return "ER:"
				case "SPC":
					return "Specialist:";
				case "PHY":
					return "PCP:";
				default:
					return "PCP:";
			}
		};
	});
	app.directive('landingPageHero', function(){
		return {
			restrict: 'A',
			template: require('directives/landing/landingPageHero.html'),
			scope: {
				landingPageHero: "="
			},
			controller: ["$scope", "$filter", "services", "teamsite", "coremetrics", "session", "finder-logic2", "claimService", "spendingActionsNeeded", "emailConfirmationAlert",
				function($scope, $filter, services, teamsite, coremetrics, session, finderLogic, claimService, spendingActionsNeeded, emailConfirmationAlert){

				$scope.selectedPlan = {};
				$scope.selectedMember = {};
				$scope.coverageSummary = {};
				$scope.volatileInfo = {};

				$scope.claimsStatus = 'loading';
				$scope.spendingStatus = 'loading';
				$scope.hsaEligible = 'loading'; // 'okay'
				$scope.showGoPaperless = false;
				$scope.showCoverageSummaryTooltip = false;

				$scope.numberOfNewClaims = 0;
				$scope.numberOfSpendingAlerts = 0;

				teamsite.get('MBR_COSTS_MAY_VARY_TOOLTIP').then(
					function(response){
						$scope.coverageTooltipMessage = response;
					},
					function(error){
						console.error(error);
						$scope.coverageTooltipMessage = "";
					}

				);

				var theBrand = session.data.memberInfo.brand;
				var getCostShareGridParams = function(selectedPlan, brand) {

					var currentProduct = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical");
					var coverageEndDate = $filter('date')(new Date(currentProduct.coverageEndDate), 'MMddyyyy');
					var endDate = (selectedPlan.memberCancellationDate) ?
						selectedPlan.memberCancellationDate.split("/").join("") : coverageEndDate;
					return {
						brand: brand,
						payload: {
							// TODO : Set Proper values here from curGrp and curPrd
							"startDate": selectedPlan.memberEffectiveDate.split("/").join(""), // "05012015", //TheDate.formatDate(curPrd.coverageBeginDate, "MMddyyyy") , // "05012015",  // curPrd.coverageBeginDate,  // 05/01/2014
							"endDate": endDate, // "04302016", //TheDate.formatDate(curPrd.coverageEndDate, "MMddyyyy") , // "04302016",   // curPrd.coverageEndDate   // 04/30/2015
							"groupNum": selectedPlan.groupNumber,
							"lob": "M",
							"productId": currentProduct.productNumber,
							"memberPin": currentProduct.memberList[0].pinNumber
						}
					};
				};
				$scope.setCoverageSummaryTooltipVisibility = function(){
					return $scope.coverageSummary.accumulations.tierCode === 'INP' ||
						$scope.coverageSummary.accumulations.tierCode === 'INE';
				};
				var updateCostShareGrid = function(csbParams) {
					$scope.coverageStatus = 0;
					services.rbsmbl.costShareBenefit().fetch(csbParams).then(
						function (coverageInfo) {
							var getCoverageSummary = function(coverage) {
								return coverage &&
									coverage.data &&
									coverage.data.payload &&
									coverage.data.payload.coverageSummary;
							};

							$scope.coverageStatus = coverageInfo.status;
							if (!!getCoverageSummary(coverageInfo)) {
								$scope.coverageSummary = getCoverageSummary(coverageInfo);
								$scope.showCoverageSummaryTooltip = $scope.setCoverageSummaryTooltipVisibility();
								console.log("coverage summary", $scope.coverageSummary);
							} else {
								throw new Error('The coverage section web service returned null.');
							}
						},
						function (err) {
							$scope.coverageStatus = err.status;
							throw new Error('The coverage summary service is not available');
						}
					);
				};



				// selected plan from view site by plan
				services.rbsmbl.vsbpPlans().fetch(theBrand).then(
					function(plans){
						var hasSelectedPlan = function(plans) {
							return plans &&
								plans.data &&
								plans.data.payload &&
								plans.data.payload.planInfo &&
								plans.data.payload.planInfo.plans &&
								plans.data.payload.planInfo.plans.selected
						};
						var hasSelectedMember = function(plans){
							return plans &&
								plans.data &&
								plans.data.payload &&
								plans.data.payload.planInfo &&
								plans.data.payload.planInfo.members;
						};
						if (hasSelectedPlan(plans)) {
							$scope.selectedPlan = angular.extend($scope.selectedPlan, plans.data.payload.planInfo.plans.selected);
						}
						if (hasSelectedMember(plans)){
							$scope.selectedMember = angular.extend($scope.selectedMember, plans.data.payload.planInfo.members);
						}
					},
					function(error){
						// what to do when this fails?
						console.log(error);
					}
				);

				// COVERAGE AND SPENDING benefits change when plans change
				$scope.$watch(function(){ return $scope.selectedPlan; },
					function(newPlan){
						console.log("newplan", newPlan);
						if (newPlan.groupNumber) {
							var params = getCostShareGridParams(newPlan, theBrand);
							updateCostShareGrid(params);
							getSpendingAccountUpdates(newPlan);
						}
					}, true
				);
				$scope.reloadCoverage = function() {
					var params = getCostShareGridParams($scope.selectedPlan, theBrand);
					updateCostShareGrid(params);
				};

				services.rbsmbl.volatileMemberInfo().fetch().then(
					function(volatileStuff) {
						var hasVolatileData = function(volatileStuff) {
							return volatileStuff &&
								volatileStuff.data &&
								volatileStuff.data.payload;
						};
						$scope.volatileInfo = hasVolatileData(volatileStuff) ?
							angular.extend($scope.volatileInfo, hasVolatileData(volatileStuff)) : {};
						$scope.loadingAlerts = false;
					},
					function(err) {
						throw new Error(err);
					}
				);



				// CLAIMS =================================================================================
				var getNumberOfNewClaims = function (lastClaimPaymentVisitDate) {
					var canHaveClaims = !!(session.data.benefits.dental == true ||
						session.data.benefits.drug == true ||
						session.data.benefits.medical == true
						);

					// THIS IS MISSING A lastClaimPaymentVisitDate
					if (lastClaimPaymentVisitDate && canHaveClaims) {
						var thisEndDate = session.startTime.toJSON().substring(0,10).replace(/-/g, '');
						var startDate = Date.parse(lastClaimPaymentVisitDate) - (60 * 24 * 60 * 60 * 1000);
						startDate = $filter('date')(new Date(startDate), 'yyyyMMdd');
						var claimsDateRange = {
							endDate: thisEndDate,
							startDate: startDate  // this is to be 60
						};
						var claimsResponse = claimService.getSummary(JSON.stringify(claimsDateRange));
						claimsResponse.then(
							function (claimsResp) {
								var numberOfClaims = 0;
								if (claimsResp && claimsResp.summary && claimsResp.summary.totalClaimsSinceLastVisit) {
									numberOfClaims = claimsResp.summary.totalClaimsSinceLastVisit;
								}
								$scope.numberOfNewClaims  = numberOfClaims;
								$scope.claimsStatus = 'okay';
							},
							function(error){
								$scope.claimsStatus = 'error';
								console.log(error);
							});
					}
				};
				$scope.$watch(function() { return $scope.volatileInfo.lastClaimPaymentVisitDate; }, getNumberOfNewClaims);


				// SPENDING ===============================================================================
				var getSpendingAccountUpdates = function(plan) {
					var umiWithSuffix = session.data.memberInfo.umi + '0';
					$scope.spendingStatus = 'loading';
					if (session.data.benefits.spending) {
						var spendingActionsNeededResponse =
							spendingActionsNeeded.theActions(umiWithSuffix,
								plan.clientNumber,
								session.data.memberInfo.brand);
						var hasSpendingUpdates = function(theActions) {
							var hasSuccessfulStatusCode = function(theActions){
								return theActions &&
									theActions.data &&
									theActions.data.repActionNeededItemsReply &&
									theActions.data.repActionNeededItemsReply.baseMessage &&
									theActions.data.repActionNeededItemsReply.baseMessage.returnCode === 200;
							};
							var hasSpendingItems = function(theActions) {
								return theActions &&
								theActions.data &&
								theActions.data.repActionNeededItemsReply &&
								theActions.data.repActionNeededItemsReply.payload &&
								theActions.data.repActionNeededItemsReply.payload.repActionNeededItemsData &&
								theActions.data.repActionNeededItemsReply.payload.repActionNeededItemsData.actionNeededItems &&
								theActions.data.repActionNeededItemsReply.payload.repActionNeededItemsData.actionNeededItems.length > 0;
							};
							return hasSuccessfulStatusCode(theActions) && hasSpendingItems(theActions);
						};
						spendingActionsNeededResponse.then(
							function (theActions) {
								if (hasSpendingUpdates(theActions)) {
									var arrS = theActions.data.repActionNeededItemsReply.payload.repActionNeededItemsData.actionNeededItems;
									var arrNamesOnly = [];
									var arrNoDups = [];
									for (var i = 0; i < arrS.length; i++) {
										arrNamesOnly.push(arrS[i].name);
									}
									for (var j = 0; j < arrNamesOnly.length; j++) {
										var thisName = arrNamesOnly[j];
										// that name is NOT in arrNoDups already, push it there
										if (arrNoDups.indexOf(thisName) == -1) {
											arrNoDups.push(arrNamesOnly[j]);
										}
									}
									var the_count = arrNoDups.length; // alertArray.length;

									$scope.numberOfSpendingAlerts = the_count;
									$scope.spendingStatus = (parseInt(the_count) > 0) ? 'okay' : 'do_not_show';
								} else {
									$scope.spendingStatus = 'error';
								}
							},
							function(error){
								$scope.spendingStatus == 'error';
							}
						);
					}
				};
				$scope.reloadSpendingService = function() {
					getSpendingAccountUpdates();
				};

				// MESSAGE CENTER ALERTS =================================================================
				$scope.showMessageCenter = false;
				services.rbsmbl.countUnreadMessages().fetch(session.data.memberInfo).then(
					function(response) {
						$scope.numNewMessage = response && response.data && response.data.payload &&
						response.data.payload[0] && response.data.payload[0].unreadMessagesCount || 0;

						if ($scope.numNewMessage > 0) {
							$scope.showMessageCenter = true;
						}
					},
					function (error){
						console.error(error);
					}
				);

				$scope.goToMessageCenter = function(url){
					coremetrics.elementTag('Customer Service Message Center','MEMBER:MEMBER HOME');
					window.location.href = url;
				};

				// HSA ===================================================================================
				var isEligibleForHSA = function(currentMember) {
					console.log("member", currentMember);
					var allowHsa = false;
					if (currentMember.loggedIn.type == "Subscriber") {
						// Must be subscriber
						var clients = session.data.memberInfo.clientList;
						console.log("clients", clients);
						for (var i = 0; i < clients.length; i++) {
							var client = clients[i];
							var clientHasHsa = false;
							var clientHsaEligible = false;
							if (client.spendingAccountBlackoutIndicator == "false") {
								// Not blacked out, loop through groups and check for hsa flag
								for (var j = 0; j < client.groupList.length; j++) {
									var group = client.groupList[j];
									if (group.hsaExistsIndicator == "true" || group.hsaEnrollmentInProcessIndicator == "true") {
										// Client has an HSA already, don't allow any HSA's for this client.
										clientHasHsa = true;
									}
									if (!clientHasHsa) {
										if (group.autoEnrollIndicator == "false"
											&& group.hsaSetupIndicator == "true"
											&& group.openHsaAccessIndicator == "true"
											&& group.hsaExistsIndicator == "false"
											&& group.hsaEnrollmentInProcessIndicator == "false")
										{
											// Passes initial checks, check product for HDHP and coverage dates
											for (var pl = 0; pl < group.productList.length; pl++) {
												var product = group.productList[pl];
												var currentDate = new Date();

												//don't allow members to open HSAs for expired coverages
												if ( product.coverageEndDate != "" && product.coverageEndDate != null) {
													var coverageEndDateParts = product.coverageEndDate.split("/");
													var coverageEndDate = new Date(coverageEndDateParts[2], coverageEndDateParts[0] - 1, coverageEndDateParts[1]);
													if ( coverageEndDate.getTime() < currentDate.getTime() ) {
														continue;
													}
												}

												//don't allow members with void/cancelled dates to open HSAs
												var isCoverageCancelled = false;
												if (product.coverageVoid == "true") {
													isCoverageCancelled = true;
												}
												else if (product.coverageCancelDate != "" ) {
													var cancelDateParts = product.coverageCancelDate.split("/");
													var cancelDate = new Date(cancelDateParts[2], cancelDateParts[0] - 1, cancelDateParts[1]);
													if (cancelDate.getTime() < currentDate.getTime() ) {
														isCoverageCancelled = true;
													}
												}
												if (isCoverageCancelled) {
													continue;
												}

												if (product.benefitPlanType.indexOf("Medical") != -1 && product.hdhpIndicator == "true") {
													clientHsaEligible = true;
												}
											}
										}
									}
								}
								if (!clientHasHsa && clientHsaEligible) {
									allowHsa = true;
								}
							}
						}
					}
					return allowHsa;
				};

				// HSA eligibility based on selected member
				$scope.$watch(function(){ return $scope.selectedMember; },
					function(selectedMember){
						if (selectedMember.umi) {
							$scope.hsaEligible = isEligibleForHSA(selectedMember) ? 'okay': 'not_eligible';
						}
					}, true
				);

				// EMAIL ALERT ============================================================================
				var getEmailConfirmation = emailConfirmationAlert.theAlert(session.data.memberInfo.brand);
				getEmailConfirmation.then(function(emailAlertResponse) {
					var emailAlert = (emailAlertResponse.data && emailAlertResponse.data.payload
						&& emailAlertResponse.data.payload.communicationAuthenticationStatusDesc )
						? emailAlertResponse.data.payload.communicationAuthenticationStatusDesc : '';
					if (emailAlert == '') {
						console.log('The service at x-services/alerts/email returned an unexpected response.');
					}
					$scope.showEmailConfirmAlert = ((emailAlert == 'Unauthenticated') || (emailAlert == 'Pending')) ? true : false;
				});

				// GO PAPERLESS ===========================================================================
				services.rbsmbl.AccountSettingsRetrive()
					.retriveAccountSettings(session.data.groupNumber, "goPaperlessAlert", session.data.memberInfo.brand, session.data.memberInfo.userName)
					.then(function(accountSettingsData) {
						if (accountSettingsData && accountSettingsData.data && accountSettingsData.data.payload) {
						// NOTES: "IE" is not semantic - we can't tell what it means just from the letters "IE" but the service guy says
						//        it means that the user has chosen to 'go paperless'.  The value "UM" here means US Mail (now that's semantic)
						//        The logic below comes from CHMMBR, using bootstrap 3.0.5 in file = articles\account\contactPreferenceHelper.js
						//        where they set the values for the properties they ultimately use:
						//                     preferences.healthStatements.canView
						//                     preferences.healthStatements.selected.originalValue
						var acctData = accountSettingsData.data.payload;
						if (
							acctData.selectedHealthStatements != 'IE'
							&& session.data.isSubscriber
							&& acctData.lobCode.toUpperCase() != 'AG'
							&& acctData.lobCode.toUpperCase() != 'NC'
							&& !(   // the member does NOT have vision and nothing else
							acctData.visionCoverage == true
							&& acctData.medicalCoverage == false
							&& acctData.dentalCoverage == false
							&& acctData.DrugCoverage == false
							)
							) {
							$scope.showGoPaperless = true;
						}
					}
					});
			}]
		};
	});
}