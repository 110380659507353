module.exports = function(app) {
    app.directive('educationDrawer',
        function() {

            return {
                restrict: 'A',
                replace: true,
                template: require('directives/educationDrawer.html'),

                scope: {
                    material: '=educationDrawer',
                    section: '='
                },


                controller: ['$scope','coremetrics','brand', function($scope,coremetrics,brand) {
                    var material = $scope.material;
                    if (material.isOpen === undefined || material.isOpen === false) {
                        $scope.isExpanded = false;
                    } else {
                        $scope.isExpanded = true;
                    }

                    $scope.$on('drawerOpen', function (event, args) {
                        $scope.isExpanded = material.isOpen;
                    });

                    $scope.$on('drawerClose', function (event, args) {
                        $scope.isExpanded = material.isOpen;
                    });

                    $scope.isVideo = material.type === 'video';
                    $scope.isPDF = material.type === 'pdf';

                    $scope.edubrand = brand.current.sharedBrandCode;
                    if($scope.edubrand==='wybcbs'){
                        $scope.pdfBrand = true;
                    }

                    var videoClicked;
                    $scope.emPlayVideoCoremetrics = function(){
                        if(!videoClicked){
                            if($scope.section === 'memberSpendingEducationalMaterials'){
                                coremetrics.elementTag('SPENDING:PLAY VIDEO:'+material.title,'MEMBER:SPENDING');
                            }else{
                                coremetrics.elementTag('CLAIMS:PLAY VIDEO:'+material.title,'MEMBER:CLAIMS');
                            }
                            videoClicked = true;
                        }
                    };

                    $scope.emDownloadPDFCoremetrics = function(){
                        if($scope.section === 'memberSpendingEducationalMaterials'){
                            coremetrics.elementTag('SPENDING:DOWNLOAD PDF:'+material.title,'MEMBER:SPENDING');
                        }else {
                            coremetrics.elementTag('CLAIMS:DOWNLOAD PDF:'+material.title,'MEMBER:CLAIMS');
                        }

                    };

                    if ($scope.isPDF) {
                        material.url = material.linkHref;
                    }
                }]
            };
        }
    );
};