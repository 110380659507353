module.exports = function(app) {
    app.service("claimsMtm", ["services", "$timeout", function(services, $timeout) {
        this.queueDelay = 200; // delay in MS to wait for the queue to populate before sending a service request
        this.queue = [];
        this.addSummary = function(data) {
            this.queue.push(data);
            this.processSummaryQueue();
        };
        this.processSummaryQueue = function() {
            if (this.processSummaryQueueTimeout) {
                $timeout.cancel(this.processSummaryQueueTimeout);
            }
            this.processSummaryQueueTimeout = $timeout((function() {
                var currentQueue = this.queue;
                this.queue = [];
                services.rbsmbl.capabilityInfo().persistMTMInfo({'claims': currentQueue}, 'claimSummary');
            }).bind(this), this.queueDelay);
        };
    }]);
    app.directive('claimDrawer', ['sessionData', function(sessionData) {
        return {
            restrict: 'A',
            template: require('directives/claimDrawer.html'),
            scope: {
                claim: '=claimDrawer',
                bestTier: "=?",
                bestTierText: '=?',
                hasTiers: "=?"
            },
            link: function(scope){

                var cancelLowerTierWatch = scope.$watch('claim.isLowerTier', function(newVal){
                    if(newVal){
                        scope.lowerTierHeaderMessage = "You'll pay less at doctors and facilities in the " + scope.bestTierText +
                            " Level. <a href='/login/#/coverage/health-insurance-101#save' target='_blank'>Learn all about in-network levels</a>.";
                        scope.lowerTierRibbonMessage = "Make sure you visit " + scope.bestTierText + "-Level doctors and facilities. You'll pay the lowest out-of-pocket costs for quality care. <a href='/find-a-doctor' target='_blank'>Find an " + scope.bestTierText + "-Level doctor.</a>";

                        cancelLowerTierWatch();
                    }
                });
                var cancelOONWatch = scope.$watch('claim.isOON', function(newVal){
                    if(newVal){
                        if(!scope.hasTiers()){ //non-tiered plan
                            scope.lowerTierHeaderMessage = "You'll pay less for care from in-network doctors and facilities." +
                                " <a href='/login/#/coverage/health-insurance-101#save' target='_blank'>Learn all about in-network and out-of-network doctors</a>.";
                            scope.lowerTierRibbonMessage = "Visit in-network doctors and facilities for lower out-of-pocket costs. Next time, save money by visiting <a href='/find-a-doctor' target='_blank'>doctors and facilities that are in-network</a>.";
                        }
                        else{ //tiered plan
                            scope.lowerTierHeaderMessage = "You'll pay less at in-network doctors and facilities at the " + scope.bestTierText +
                                " Level. <a href='/login/#/coverage/health-insurance-101#save' target='_blank'>Learn all about in-network levels</a>.";
                            scope.lowerTierRibbonMessage = "Make sure you visit in-network " + scope.bestTierText + "-Level doctors and facilities. You'll pay the lowest out-of-pocket costs for quality care. <a href='/find-a-doctor' target='_blank'>Find an " + scope.bestTierText + "-Level doctor.</a>";
                        }

                        cancelOONWatch();
                    }
                });

                scope.getTierIcon = function(){
                    var returnString = "";

                    if(scope.claim.pealbndtCd === "OON"){
                        returnString =  "icon-denied";
                    }
                    else if(scope.bestTier){
                        var tierCode =  scope.claim.tierPealbndtCd;
                        switch(scope.bestTier){
                            case "INP":
                                if(tierCode === "INP" || tierCode === "PRE"){
                                    returnString = "icon-3tiers-preferred";
                                }
                                else if(tierCode === "INE") {
                                    returnString = "icon-3tiers-enhanced";
                                }
                                else if(tierCode === "INS") {
                                    returnString = "icon-3tiers-standard";
                                }
                                break;
                            case "INE":
                                if(tierCode === "INE") {
                                    returnString = "icon-2tiers-enhanced";
                                }
                                else if(tierCode === "INS") {
                                    returnString = "icon-2tiers-standard";
                                }
                                break;
                            default:
                                returnString = "";
                        }
                    }

                    return returnString;
                };


                scope.getTierLabelClass = function(){

                    if(!scope.bestTier){
                        return "";
                    }

                    switch(scope.bestTier){
                        case "INP":
                        case "PRE":
                            return "three-tier";
                        case "INE":
                            return "two-tier";
                        default:
                            return "";
                    }

                };

                scope.isTieredClaim = function(){
                    return scope.claim.tierPealbndtCd === 'PRE' || scope.claim.tierPealbndtCd === 'INP' || scope.claim.tierPealbndtCd === 'INE';
                };

            },
            controller: ['sessionData', '$scope', '$state', 'services', 'claimService', 'isTruthyOrZero', 'modal', 'storage', '$timeout', 'claimsMtm', '$q', 'coremetrics', 'session', 'brand','$location','$anchorScroll','$filter',
                function(sessionData, $scope, $state, services, claimService, isTruthyOrZero, modal, storage, $timeout, claimsMtm, $q, coremetrics, session, brand,$location,$anchorScroll, $filter) {

                    var today = new Date();
                    sessionData.vendorDetails.forEach(function (account) {
                        var startDate = new Date(account.effectiveStartDate);
                        if (account.vendorName.toUpperCase() === "ALEGEUS"
                            && today >= startDate) {
                            $scope.showUnifiedCSS = true;
                        }
                        else{
                            $scope.showUnifiedCSS = false;
                        }
                    });

                    var
                        claim = $scope.claim,
                        buttons = {},
                        _mapToothNumbers,
                        _hasEOB,
                        _hasEOP,
                        _hasPAS,
                        _hasCDN,
                        _hasSeniorMarketStatement,
                        _toggleMarkAsPaid,
                        _setDownloadAvailability,
                        _configureFooterButtons,
                        calculateAge;

                    $scope.currentState = $state.current.name;
                    $scope.toolTipContentMarkAsPaid = "<span data-teamsite='MBR_Claims_toolTipMarkAsPaid'></span>";
                    //to scroll to exact claim from MN landing recent claims
                    if ($location.search().clmNum && (claim.claimNumber === $location.search().clmNum)) {
                        $scope.$parent.$evalAsync(
                            function(){
                                var claimId = 'CN-'+claim.claimNumber;
                                $location.hash(claimId);
                                $anchorScroll();
                            }
                        );
                    }

                    /*Coremetrics Element Tag for see dental procedure history*/
                    $scope.dentalProcedureHistoryCoremetrics = function(){
                        coremetrics.elementTag('SEE DENTAL PROCEDURE HISTORY','MEMBER:CLAIMS');
                    };

                    if ($scope.claim.sysSrcCode === 'medical') {
                        claimsMtm.addSummary($scope.claim.originalData);
                    }

                    _toggleMarkAsPaid = function(checkBoxValue,claimDetails){
                            for(var q = 0; q < claimDetails.length; q++){
                                claimDetails[q].isIgnored = checkBoxValue;
                            }
                    };

                    _mapToothNumbers = function(aDetail) {
                        if (!(Array.isArray(aDetail.toothNumber))) { return; }

                        var list = aDetail.toothNumberList = [];

                        aDetail.toothNumber.forEach(
                            function(tooth) {
                                if (tooth.toothNbr) {
                                    list.push(tooth.toothNbr);
                                }
                            }
                        );
                    };

                    // TODO: Revisit eobDoc/footer-buttons implementation
                    // *** Begin footer-buttons helper methods *** //
                    _hasEOB = function(docTypeCode, seqNumPrefix) {
                        return ( docTypeCode === 'EOB' || docTypeCode === 'DEB' || docTypeCode === 'MEB' || docTypeCode === 'IEB' || docTypeCode === 'NDB' || docTypeCode === 'WYB' )
                            && ( seqNumPrefix !== 'HC' && seqNumPrefix !== 'WC' && seqNumPrefix !== 'DC' && seqNumPrefix !== 'IC' && 
                            		!(seqNumPrefix[0] === 'M' && seqNumPrefix[1] >= '0' && seqNumPrefix[1] <= '9')
                            	);
                    };

                    _hasEOP = function(docTypeCode, seqNumPrefix) {
                        return docTypeCode === 'EOP'
                            && seqNumPrefix !== 'HC'
                            && seqNumPrefix !== 'WC'
                            && seqNumPrefix !== 'DC'
                            && seqNumPrefix !== 'IC';
                    };


                    _hasPAS = function(docTypeCode, seqNumPrefix) {
                        return ( docTypeCode === 'EOB' || docTypeCode === 'DEB' || docTypeCode === 'IEB' || docTypeCode === 'EOP' )
                            && ( seqNumPrefix === 'HC' || seqNumPrefix === 'WC' || seqNumPrefix === 'DC' || seqNumPrefix === 'IC' );
                    };

                    _hasCDN = function(docTypeCode) {
                        return docTypeCode === 'ALG';
                    };
                    
                    _hasSeniorMarketStatement = function(docTypeCode, seqNumPrefix) {                    	
                    	return docTypeCode === 'EOB' && seqNumPrefix[0] === 'M' && seqNumPrefix[1] >= '0' && seqNumPrefix[1] <= '9';
                    }

                    _setDownloadAvailability = function(documentTypeCode, sequenceNumberPrefix, endDate, queryPayload) {
                        var downloadInformation = {
                            documentType: null,
                            queryPayload: queryPayload,
                            endDate: endDate
                        }
                        var isSubscriber = session.data.isSubscriber;
                        var isMINCR = brand.current.sharedBrandCode === 'mincr';
                        var isNDBCBS = brand.current.sharedBrandCode === 'ndbcbs';
                        var isWYBCBS = brand.current.sharedBrandCode === 'wybcbs';
                        // this replicates the prior implementation wherein a true occurrence takes precedence
                        // however, it is unclear if there will ever actually be more than one object in the eobDataList array

                        // Check for Dependent With EOB

                        if((!isSubscriber && !isMINCR) && _hasEOB(documentTypeCode, sequenceNumberPrefix)){
                            downloadInformation.documentType = 'dependentWithEob';
                        }
                        if(( !isSubscriber && (isNDBCBS || isWYBCBS)) && _hasEOB(documentTypeCode, sequenceNumberPrefix)) {
                            downloadInformation.documentType = 'eob';
                        }
                        // Check for EOB
                        if ((isSubscriber || isMINCR) && _hasEOB(documentTypeCode, sequenceNumberPrefix)) {
                            downloadInformation.documentType = 'eob';

                        }

                        // Check for EOP
                        if ((isSubscriber || isMINCR) && _hasEOP(documentTypeCode, sequenceNumberPrefix)) {
                            downloadInformation.documentType = 'eop';
                        }

                        // Check for PAS
                        if ((isSubscriber || isMINCR) && _hasPAS(documentTypeCode, sequenceNumberPrefix)) {
                            downloadInformation.documentType = 'pas';

                        }

                        // Check for CDN
                        if ((isSubscriber || isMINCR) && _hasCDN(documentTypeCode)) {
                            downloadInformation.documentType = 'cdn';
                        }

                        // Check for Senior Market Statement
                        if ((isSubscriber || isMINCR) && _hasSeniorMarketStatement(documentTypeCode, sequenceNumberPrefix)) {
                            downloadInformation.documentType = 'seniorMarketStatement';
                            var stmtDate = new Date(endDate);
                            stmtDate.setMonth(stmtDate.getMonth()-1);
                            downloadInformation.endDate = stmtDate;
                        }

                        return downloadInformation;
                    };

                    calculateAge = function(dob){
                        var currentDate = new Date();
                        var dateOfBirth = new Date(dob);
                        var timeFrame = 1000 * 60 * 60 * 24 * 365;
                        return (currentDate - dateOfBirth) / timeFrame;
                    }

                    var checkIfFEPorMedicare = function(agreementId) {
                        return (agreementId.charAt(0) === "R" || agreementId.charAt(agreementId.length-1) === "A" || agreementId.charAt(agreementId.length-1) === "B");
                    }
                                                        // TODO: Figure out 3rd param
                    _configureFooterButtons = function(claim, buttons, mobileFlag) {
                        if (!claim.claimType.isDrug && !claim.claimType.isDental && claim.clmPartialSupressInd && claim.clmPartialSupressInd.toLowerCase() === 'n') {
                            services.rbsmbl.eobDocByClaim().fetch(claim.claimNumber).then(
                                function(response) {
                                    $scope.buttons = {};
                                    var
                                        payload = response.data.repEobDocumentByClaimResp.payload || {},
                                        eobDataList = payload.repEobDocumentByClaimData || [],
                                        firstEobObject = eobDataList[0] || {};

                                    for (var i = 0; i < eobDataList.length; i++) {
                                        var eobObject = eobDataList[i];
                                        var docTypeCode = eobObject.docTypeCode.toUpperCase(),
                                            seqNumberPrefix,
                                            docKeyParams = {},
                                            statementFileParams,
                                            keyName,
                                            keyValue;

                                        eobObject.docKey = eobObject.docKey || [];
                                        for (var j = 0; j < eobObject.docKey.length; j++) {
                                            var obj = eobObject.docKey[j];
                                            if (obj.docKeyName.toLowerCase() === 'sequence number') {
                                                seqNumberPrefix = obj.docKeyValue.slice(0, 2).toUpperCase();
                                            }

                                            keyName = obj.docKeyName.toUpperCase().replace(' ', '_');
                                            keyValue = obj.docKeyValue.toUpperCase();

                                            docKeyParams[keyName] = keyValue;
                                        }


                                        if ( docTypeCode === 'EOB' || docTypeCode === 'DEB' || docTypeCode === 'IEB' || docTypeCode === 'EOP' || docTypeCode === 'MEB' || docTypeCode === 'HMN' || docTypeCode === 'NDB' || docTypeCode === 'WYB') {
                                            statementFileParams = {
                                                ODFolder: eobObject.docOdFolderName.toUpperCase() || '',
                                                BEGIN_DATE: eobObject.docBeginDate || '',
                                                END_DATE: eobObject.docEndDate || '',
                                                MTM_CLAIM: claim.claimNumber,
                                                MTM_PT: '',
                                                // TODO: actually pass a mobileFlag at some point
                                                MTM_ISM: mobileFlag || 'No',
                                                CONTENT_DISPOSITION: 'inline'
                                            };

                                            statementFileParams = angular.extend(statementFileParams, docKeyParams);

                                            var documentInformation = angular.copy(_setDownloadAvailability(docTypeCode, seqNumberPrefix, firstEobObject.docEndDate, statementFileParams));
                                            $scope.buttons[documentInformation.documentType] = {
                                                queryPayload: documentInformation.queryPayload,
                                                endDate: documentInformation.endDate
                                            };                                         
                                        }
                                    }
                                },
                                function(err) {
                                    console.error('eobDocByClaim.fetch failed with error', err);
                                    return err;
                                }
                            );
                        }

                        //Changes for displaying the Dental EOB for Wyoming members
                        if(claim.claimType.isDental
                            && brand.current.sharedBrandCode === 'wybcbs'
                            && claim.clmPartialSupressInd
                            && claim.clmPartialSupressInd.toLowerCase() === 'n'
                            && !checkIfFEPorMedicare(session.data.memberInfo.agreementId)){

                            services.rbsmbl.eobDocForDentalClaim().checkIfClaimHasEOB(claim.clmFnlnDate, session.data.memberInfo.agreementId ,claim.claimNumber).then(
                                function(response){
                                    if(response && response.data
                                        && response.data.onDemandDocuments[0]
                                        && response.data.onDemandDocuments[0].documentToken !== undefined
                                        && response.data.onDemandDocuments[0].documentToken !== null){

                                        $scope.buttons = {};
                                        $scope.buttons['wyDentalEob'] = {
                                            endDate : response.data.onDemandDocuments[0].documentPostingDate,
                                            documentType : response.data.onDemandDocuments[0].documentType,
                                            documentToken : response.data.onDemandDocuments[0].documentToken
                                        };
                                    }
                                },
                                function(error){
                                    console.error('eobDocForDentalClaim.checkIfClaimHasEOB failed with error', error);
                                    return error;
                                }

                            );
                        }
                    };

                    // *** End footer-buttons helper methods *** //

                    Object.keys(claim && claim.claimType || {}).forEach(function(key) {
                        $scope[key] = claim.claimType[key]; // Example: 'claim.claimType.isVision: true' sets '$scope.isVision: true'
                    });

                    var detailCachePromise;
                    $scope.getClaimDetails = function() {
                        if (detailCachePromise) {
                            return detailCachePromise;
                        }
                        var detailPromise = claimService.getDetails(claim);

                        detailPromise.then(function(details) {
                            if (claim.sysSrcCode === 'medical') {
                                services.rbsmbl.capabilityInfo().persistMTMInfo({claim: claim.originalData, services: details.originalData}, 'claimDetail');
                            }

                            if (claim.claimType.isDental) {
                                details.forEach(function(detail) {
                                    _mapToothNumbers(detail);
                                });
                            }

                            claim.details = details;
                            _configureFooterButtons(claim, buttons);
                        });

                        detailCachePromise = detailPromise;
                        return detailPromise;
                    };
                    $scope.$on('drawerOpen', function() {
                        /*coremetrics.elementTag('CLAIM DETAIL EXPAND','MEMBER:CLAIMS');*/
                        $scope.getClaimDetails();
                    });

                    $scope.isOpen = false;
                    if ($scope.claim && $scope.claim.isOpen) {
                        $scope.getClaimDetails().then(function() {
                            $timeout(function() {
                                $scope.isOpen = true;
                            }, 100);
                        });
                    }

                    $scope.isTruthyOrZero = isTruthyOrZero;
                    $scope.isInProcess = claim && claim.bilgProvClmStaCd === '5';
                    $scope.showMarkedAsPaidControls = claim && (claim.alreadyPaid === 'TRUE' || claim.alreadyPaid === 'FALSE');
                    $scope.isAlreadyPaid = claim && claim.alreadyPaid === 'TRUE';
                    $scope.showsPayButton = $scope.showMarkedAsPaidControls && !$scope.isAlreadyPaid;
                    $scope.spendingBlackout = sessionData.spendingAccountBlackoutIndicator;

                    $scope.goPayClaim = function(claimNumber) {
                        storage.set('payClaimBackButtonDetails', {
                            scrollTop: $(document).scrollTop(),
                            isOpen: $scope.claim.isOpen,
                            claimNumber: $scope.claim.claimNumber
                        });
                        coremetrics.elementTag('PAY CLAIMS:CLAIMS','MEMBER:CLAIMS');
                        coremetrics._conversionEventTagWithAttrs(1, "PAY CLAIM PROCESS", "MEMBER:CLAIMS", "CLAIMS:PAY CLAIM");
                        //need to set partial pay or old pay claims

                        var isPartialPayTurnOn=true;
                        if(sessionData &&  sessionData.appConfig && sessionData.appConfig.CLAIMS && sessionData.appConfig.CLAIMS.PARTIAL_PAY ){
                            isPartialPayTurnOn=sessionData.appConfig.CLAIMS.PARTIAL_PAY.booleanValue;
                            console.log('appconfig settings for PARTIAL_PAY', isPartialPayTurnOn);
                        }
                        if(isPartialPayTurnOn){
                            $state.go('claims.claimsAndPayments.partialPay', { claimNumber: claimNumber }, { location: true });
                        }else{
                            $state.go('claims.claimsAndPayments.select', { claimNumber: claimNumber }, { location: true });
                        }
                    };

                    var paidModal = function() {
                        modal.open(
                            {
                                heading: 'Changed Payment Status',
                                template: '<p><span class="hm-icon icon-approved paidModalAlignment"></span> Success! Payment Status Updated.</p>'
                            }
                        );
                    };

                    var servicesRunning = [];
                    $scope.markAsPaidServiceLine = function(claim,line){
                        if (servicesRunning[line.lnNo] && servicesRunning[line.lnNo].$$state && servicesRunning[line.lnNo].$$state.status !== 1) {
                            servicesRunning[line.lnNo].reject();
                        }
                        if (line.isIgnored) {
                            servicesRunning[line.lnNo] = $q.defer();
                            servicesRunning[line.lnNo].promise.then(paidModal);
                            services.rbsmbl.spendingAccountIgnore().fetch(claim.clientId,claim.claimNumber,line.lnNo).then(function(response) {
                                servicesRunning[line.lnNo].resolve(response);
                            });
                        }
                        else {
                            servicesRunning[line.lnNo] = $q.defer();
                            servicesRunning[line.lnNo].promise.then(paidModal);
                            services.rbsmbl.spendingAccountUnignore().fetch(claim.clientId,claim.claimNumber,line.lnNo).then(function(response) {
                                servicesRunning[line.lnNo].resolve(response);
                            });
                            claim.alreadyPaid = 'FALSE';
                            $scope.isAlreadyPaid = false;
                            $scope.showsPayButton = true;
                        }
                        var count = claim.details.reduce(function(sum, line) {
                            if (line.isPotentiallyPayable) {
                                sum++;
                            }
                            return sum;
                        }, 0);
                        var match = 0;
                        claim.details.forEach(function(line) {
                            if (line.isPotentiallyPayable && line.isIgnored) {
                                match++;
                            }
                        });
                        if (count === match) {
                            claim.alreadyPaid = 'TRUE';
                            $scope.isAlreadyPaid = true;
                            $scope.showsPayButton = false;
                        }

                        services.rbsmbl.claimDetail().flushCache();
                        detailCachePromise = null;
                        services.rbsmbl.claimSummary().flushCache();
                    };

                    var markAllRunning;
                    $scope.markAsPaidAll = function(claim) {
                        if (markAllRunning && markAllRunning.$$state && markAllRunning.$$state.status !== 1) {
                            markAllRunning.reject();
                        }
                        markAllRunning = $q.defer();

                        claim.alreadyPaid = $scope.isAlreadyPaid
                                          ? 'TRUE'
                                          : 'FALSE';

                        $scope.showsPayButton = !$scope.isAlreadyPaid;

                        $scope.getClaimDetails().then(function() {
                            var allPromises = [];
                            claim.details.forEach(function(line) {
                                if ($scope.isAlreadyPaid && !line.isIgnored) {
                                    line.isIgnored = true;
                                    allPromises.push(services.rbsmbl.spendingAccountIgnore().fetch(claim.clientId,claim.claimNumber,line.lnNo));
                                }
                                else if (!$scope.isAlreadyPaid && line.isIgnored) {
                                    line.isIgnored = false;
                                    allPromises.push(services.rbsmbl.spendingAccountUnignore().fetch(claim.clientId,claim.claimNumber,line.lnNo));
                                }
                            });
                            markAllRunning.promise.then(paidModal);
                            $q.all(allPromises).then(function() {
                                markAllRunning.resolve();
                                services.rbsmbl.claimDetail().flushCache();
                                detailCachePromise = null;
                                services.rbsmbl.claimSummary().flushCache();
                            });
                        });
                    };

                }
            ]
        };
    }]);

/*    app.directive('claimDrawerUpdated', ['sessionData', function(sessionData) {
        return {
            restrict: 'A',
            template: require('directives/claimDrawerUpdated.html'),
            scope: {
                claim: '=claimDrawerUpdated',
                bestTier: "=?",
                bestTierText: '=?',
                hasTiers: "=?"
            },
            link: function(scope){

                var cancelLowerTierWatch = scope.$watch('claim.isLowerTier', function(newVal){
                    if(newVal){
                        scope.lowerTierHeaderMessage = "You'll pay less at doctors and facilities in the " + scope.bestTierText +
                            " Level. <a href='/login/#/coverage/health-insurance-101#save' target='_blank'>Learn all about in-network levels</a>.";
                        scope.lowerTierRibbonMessage = "Make sure you visit " + scope.bestTierText + "-Level doctors and facilities. You'll pay the lowest out-of-pocket costs for quality care. <a href='/find-a-doctor' target='_blank'>Find an " + scope.bestTierText + "-Level doctor.</a>";

                        cancelLowerTierWatch();
                    }
                });
                var cancelOONWatch = scope.$watch('claim.isOON', function(newVal){
                    if(newVal){
                        if(!scope.hasTiers()){ //non-tiered plan
                            scope.lowerTierHeaderMessage = "You'll pay less for care from in-network doctors and facilities." +
                                " <a href='/login/#/coverage/health-insurance-101#save' target='_blank'>Learn all about in-network and out-of-network doctors</a>.";
                            scope.lowerTierRibbonMessage = "Visit in-network doctors and facilities for lower out-of-pocket costs. Next time, save money by visiting <a href='/find-a-doctor' target='_blank'>doctors and facilities that are in-network</a>.";
                        }
                        else{ //tiered plan
                            scope.lowerTierHeaderMessage = "You'll pay less at in-network doctors and facilities at the " + scope.bestTierText +
                                " Level. <a href='/login/#/coverage/health-insurance-101#save' target='_blank'>Learn all about in-network levels</a>.";
                            scope.lowerTierRibbonMessage = "Make sure you visit in-network " + scope.bestTierText + "-Level doctors and facilities. You'll pay the lowest out-of-pocket costs for quality care. <a href='/find-a-doctor' target='_blank'>Find an " + scope.bestTierText + "-Level doctor.</a>";
                        }

                        cancelOONWatch();
                    }
                });

                scope.getTierIcon = function(){
                    var returnString = "";

                    if(scope.claim.pealbndtCd === "OON"){
                        returnString =  "icon-denied";
                    }
                    else if(scope.bestTier){
                        var tierCode =  scope.claim.tierPealbndtCd;
                        switch(scope.bestTier){
                            case "INP":
                                if(tierCode === "INP" || tierCode === "PRE"){
                                    returnString = "icon-3tiers-preferred";
                                }
                                else if(tierCode === "INE") {
                                    returnString = "icon-3tiers-enhanced";
                                }
                                else if(tierCode === "INS") {
                                    returnString = "icon-3tiers-standard";
                                }
                                break;
                            case "INE":
                                if(tierCode === "INE") {
                                    returnString = "icon-2tiers-enhanced";
                                }
                                else if(tierCode === "INS") {
                                    returnString = "icon-2tiers-standard";
                                }
                                break;
                            default:
                                returnString = "";
                        }
                    }

                    return returnString;
                };


                scope.getTierLabelClass = function(){

                    if(!scope.bestTier){
                        return "";
                    }

                    switch(scope.bestTier){
                        case "INP":
                        case "PRE":
                            return "three-tier";
                        case "INE":
                            return "two-tier";
                        default:
                            return "";
                    }

                };

                scope.isTieredClaim = function(){
                    return scope.claim.tierPealbndtCd === 'PRE' || scope.claim.tierPealbndtCd === 'INP' || scope.claim.tierPealbndtCd === 'INE';
                };

            },
            controller: ['sessionData', '$scope', '$state', 'services', 'claimService', 'isTruthyOrZero', 'modal', 'storage', '$timeout', 'claimsMtm', '$q', 'coremetrics', 'session', 'brand',
                function(sessionData, $scope, $state, services, claimService, isTruthyOrZero, modal, storage, $timeout, claimsMtm, $q, coremetrics, session, brand) {

                    var today = new Date();
                    sessionData.vendorDetails.forEach(function (account) {
                        var startDate = new Date(account.effectiveStartDate);
                        if (account.vendorName.toUpperCase() === "ALEGEUS"
                            && today >= startDate) {
                            $scope.showUnifiedCSS = true;
                        }
                        else{
                            $scope.showUnifiedCSS = false;
                        }
                    });

                    var
                        claim = $scope.claim,
                        buttons = {},
                        _mapToothNumbers,
                        _hasEOB,
                        _hasEOP,
                        _hasPAS,
                        _hasCDN,
                        _hasSeniorMarketStatement,
                        _toggleMarkAsPaid,
                        _setDownloadAvailability,
                        _configureFooterButtons;

                    $scope.currentState = $state.current.name;
                    $scope.toolTipContentMarkAsPaid = "<span data-teamsite='MBR_Claims_toolTipMarkAsPaid'></span>";


                    /*Coremetrics Element Tag for see dental procedure history
                    $scope.dentalProcedureHistoryCoremetrics = function(){
                        coremetrics.elementTag('SEE DENTAL PROCEDURE HISTORY','MEMBER:CLAIMS');
                    };

                    if ($scope.claim.sysSrcCode === 'medical') {
                        claimsMtm.addSummary($scope.claim.originalData);
                    }

                    _toggleMarkAsPaid = function(checkBoxValue,claimDetails){
                        for(var q = 0; q < claimDetails.length; q++){
                            claimDetails[q].isIgnored = checkBoxValue;
                        }
                    };

                    _mapToothNumbers = function(aDetail) {
                        if (!(Array.isArray(aDetail.toothNumber))) { return; }

                        var list = aDetail.toothNumberList = [];

                        aDetail.toothNumber.forEach(
                            function(tooth) {
                                if (tooth.toothNbr) {
                                    list.push(tooth.toothNbr);
                                }
                            }
                        );
                    };

                    // TODO: Revisit eobDoc/footer-buttons implementation
                    // *** Begin footer-buttons helper methods *** //
                    _hasEOB = function(docTypeCode, seqNumPrefix) {
                        return ( docTypeCode === 'EOB' || docTypeCode === 'DEB' || docTypeCode === 'MEB' || docTypeCode === 'IEB' )
                            && ( seqNumPrefix !== 'HC' && seqNumPrefix !== 'WC' && seqNumPrefix !== 'DC' && seqNumPrefix !== 'IC' &&
                            !(seqNumPrefix[0] === 'M' && seqNumPrefix[1] >= '0' && seqNumPrefix[1] <= '9')
                            );
                    };

                    _hasEOP = function(docTypeCode, seqNumPrefix) {
                        return docTypeCode === 'EOP'
                            && seqNumPrefix !== 'HC'
                            && seqNumPrefix !== 'WC'
                            && seqNumPrefix !== 'DC'
                            && seqNumPrefix !== 'IC';
                    };


                    _hasPAS = function(docTypeCode, seqNumPrefix) {
                        return ( docTypeCode === 'EOB' || docTypeCode === 'DEB' || docTypeCode === 'IEB' || docTypeCode === 'EOP' )
                            && ( seqNumPrefix === 'HC' || seqNumPrefix === 'WC' || seqNumPrefix === 'DC' || seqNumPrefix === 'IC' );
                    };

                    _hasCDN = function(docTypeCode) {
                        return docTypeCode === 'ALG';
                    };

                    _hasSeniorMarketStatement = function(docTypeCode, seqNumPrefix) {
                        return docTypeCode === 'EOB' && seqNumPrefix[0] === 'M' && seqNumPrefix[1] >= '0' && seqNumPrefix[1] <= '9';
                    }

                    _setDownloadAvailability = function(buttons, documentTypeCode, sequenceNumberPrefix, endDate, queryPayload) {
                        buttons = buttons || {};
                        var isSubscriber = session.data.isSubscriber;
                        var isMINCR = brand.current.sharedBrandCode === 'mincr';
                        // this replicates the prior implementation wherein a true occurrence takes precedence
                        // however, it is unclear if there will ever actually be more than one object in the eobDataList array
                        buttons.dependentWithEOB = (!isSubscriber && !isMINCR) && (buttons.dependentWithEOB || _hasEOB(documentTypeCode, sequenceNumberPrefix));
                        buttons.hasEOB = (isSubscriber || isMINCR) && (buttons.hasEOB || _hasEOB(documentTypeCode, sequenceNumberPrefix));
                        buttons.hasEOP = (isSubscriber || isMINCR) && (buttons.hasEOP || _hasEOP(documentTypeCode, sequenceNumberPrefix));
                        buttons.hasPAS = (isSubscriber || isMINCR) && (buttons.hasPAS || _hasPAS(documentTypeCode, sequenceNumberPrefix));
                        buttons.hasCDN = (isSubscriber || isMINCR) && (buttons.hasCDN || _hasCDN(documentTypeCode));
                        buttons.hasSeniorMarketStatement = (isSubscriber || isMINCR) && (buttons.hasSeniorMarketStatement || _hasSeniorMarketStatement(documentTypeCode, sequenceNumberPrefix));
                        buttons.queryPayload = queryPayload || {};

                        if (buttons.hasEOB) {
                            buttons.eobDate = endDate;
                        }

                        if (buttons.hasPAS) {
                            buttons.pasDate = endDate;
                        }

                        if (buttons.hasSeniorMarketStatement) {
                            var stmtDate = new Date(endDate);
                            stmtDate.setMonth(stmtDate.getMonth()-1);
                            buttons.seniorMarketStatementDate = stmtDate;
                        }

                        return buttons;
                    };
                    // TODO: Figure out 3rd param
                    _configureFooterButtons = function(claim, buttons, mobileFlag) {
                        if (!claim.claimType.isDrug && claim.clmPartialSupressInd && claim.clmPartialSupressInd.toLowerCase() === 'n') {
                            services.rbsmbl.eobDocByClaim().fetch(claim.claimNumber).then(
                                function(response) {
                                    var
                                        payload = response.data.repEobDocumentByClaimResp.payload || {},
                                        eobDataList = payload.repEobDocumentByClaimData || [],
                                        firstEobObject = eobDataList[0] || {};

                                    eobDataList.forEach(function(eobObject) {
                                        var docTypeCode = eobObject.docTypeCode.toUpperCase(),
                                            seqNumberPrefix,
                                            docKeyParams = {},
                                            statementFileParams;


                                        eobObject.docKey = eobObject.docKey || [];
                                        eobObject.docKey.forEach(function(obj) {
                                            var
                                                keyName,
                                                keyValue;

                                            if (obj.docKeyName.toLowerCase() === 'sequence number') {
                                                seqNumberPrefix = obj.docKeyValue.slice(0, 2).toUpperCase();
                                            }

                                            keyName = obj.docKeyName.toUpperCase().replace(' ', '_');
                                            keyValue = obj.docKeyValue.toUpperCase();

                                            docKeyParams[keyName] = keyValue;
                                        });


                                        if ( docTypeCode === 'EOB' || docTypeCode === 'DEB' || docTypeCode === 'IEB' || docTypeCode === 'EOP' || docTypeCode === 'MEB' || docTypeCode === 'HMN') {
                                            statementFileParams = {
                                                ODFolder: eobObject.docOdFolderName.toUpperCase() || '',
                                                BEGIN_DATE: eobObject.docBeginDate || '',
                                                END_DATE: eobObject.docEndDate || '',
                                                MTM_CLAIM: claim.claimNumber,
                                                MTM_PT: '',
                                                // TODO: actually pass a mobileFlag at some point
                                                MTM_ISM: mobileFlag || 'No',
                                                CONTENT_DISPOSITION: 'inline'
                                            };

                                            statementFileParams = angular.extend(statementFileParams, docKeyParams);

                                            $scope.buttons = _setDownloadAvailability(buttons, docTypeCode, seqNumberPrefix, firstEobObject.docEndDate, statementFileParams);
                                        }
                                    });
                                },
                                function(err) {
                                    console.error('eobDocByClaim.fetch failed with error', err);
                                    return err;
                                }
                            );
                        }
                    };
                    // *** End footer-buttons helper methods *** //

                    Object.keys(claim && claim.claimType || {}).forEach(function(key) {
                        $scope[key] = claim.claimType[key]; // Example: 'claim.claimType.isVision: true' sets '$scope.isVision: true'
                    });

                    var detailCachePromise;
                    $scope.getClaimDetails = function() {
                        if (detailCachePromise) {
                            return detailCachePromise;
                        }
                        var detailPromise = claimService.getDetails(claim);

                        detailPromise.then(function(details) {
                            if (claim.sysSrcCode === 'medical') {
                                services.rbsmbl.capabilityInfo().persistMTMInfo({claim: claim.originalData, services: details.originalData}, 'claimDetail');
                            }

                            if (claim.claimType.isDental) {
                                details.forEach(function(detail) {
                                    _mapToothNumbers(detail);
                                });
                            }

                            claim.details = details;
                            // _configureFooterButtons(claim, buttons);
                        });

                        detailCachePromise = detailPromise;
                        return detailPromise;
                    };
                    $scope.$on('drawerOpen', function() {
                        /*coremetrics.elementTag('CLAIM DETAIL EXPAND','MEMBER:CLAIMS');
                        $scope.getClaimDetails();
                    });

                    $scope.isOpen = false;
                    if ($scope.claim && $scope.claim.isOpen) {
                        $scope.getClaimDetails().then(function() {
                            $timeout(function() {
                                $scope.isOpen = true;
                            }, 100);
                        });
                    }

                    $scope.isTruthyOrZero = isTruthyOrZero;
                    $scope.isInProcess = claim && claim.bilgProvClmStaCd === '5';
                    $scope.showMarkedAsPaidControls = claim && (claim.alreadyPaid === 'TRUE' || claim.alreadyPaid === 'FALSE');
                    $scope.isAlreadyPaid = claim && claim.alreadyPaid === 'TRUE';
                    $scope.showsPayButton = $scope.showMarkedAsPaidControls && !$scope.isAlreadyPaid;
                    $scope.spendingBlackout = sessionData.spendingAccountBlackoutIndicator;

                    $scope.goPayClaim = function(claimNumber) {
                        storage.set('payClaimBackButtonDetails', {
                            scrollTop: $(document).scrollTop(),
                            isOpen: $scope.claim.isOpen,
                            claimNumber: $scope.claim.claimNumber
                        });
                        coremetrics.elementTag('PAY CLAIMS:CLAIMS','MEMBER:CLAIMS');
                        coremetrics._conversionEventTagWithAttrs(1, "PAY CLAIM PROCESS", "MEMBER:CLAIMS", "CLAIMS:PAY CLAIM");
                        //need to set partial pay or old pay claims

                        var isPartialPayTurnOn=true;
                        if(sessionData &&  sessionData.appConfig && sessionData.appConfig.CLAIMS && sessionData.appConfig.CLAIMS.PARTIAL_PAY ){
                            isPartialPayTurnOn=sessionData.appConfig.CLAIMS.PARTIAL_PAY.booleanValue;
                            console.log('appconfig settings for PARTIAL_PAY', isPartialPayTurnOn);
                        }
                        if(isPartialPayTurnOn){
                            $state.go('claims.claimsAndPayments.partialPay', { claimNumber: claimNumber }, { location: true });
                        }else{
                            $state.go('claims.claimsAndPayments.select', { claimNumber: claimNumber }, { location: true });
                        }
                    };

                    var paidModal = function() {
                        modal.open(
                            {
                                heading: 'Changed Payment Status',
                                template: '<p><span class="hm-icon icon-approved paidModalAlignment"></span> Success! Payment Status Updated.</p>'
                            }
                        );
                    };

                    var servicesRunning = [];
                    $scope.markAsPaidServiceLine = function(claim,line){
                        if (servicesRunning[line.lnNo] && servicesRunning[line.lnNo].$$state && servicesRunning[line.lnNo].$$state.status !== 1) {
                            servicesRunning[line.lnNo].reject();
                        }
                        if (line.isIgnored) {
                            servicesRunning[line.lnNo] = $q.defer();
                            servicesRunning[line.lnNo].promise.then(paidModal);
                            services.rbsmbl.spendingAccountIgnore().fetch(claim.clientId,claim.claimNumber,line.lnNo).then(function(response) {
                                servicesRunning[line.lnNo].resolve(response);
                            });
                        }
                        else {
                            servicesRunning[line.lnNo] = $q.defer();
                            servicesRunning[line.lnNo].promise.then(paidModal);
                            services.rbsmbl.spendingAccountUnignore().fetch(claim.clientId,claim.claimNumber,line.lnNo).then(function(response) {
                                servicesRunning[line.lnNo].resolve(response);
                            });
                            claim.alreadyPaid = 'FALSE';
                            $scope.isAlreadyPaid = false;
                            $scope.showsPayButton = true;
                        }
                        var count = claim.details.reduce(function(sum, line) {
                            if (line.isPotentiallyPayable) {
                                sum++;
                            }
                            return sum;
                        }, 0);
                        var match = 0;
                        claim.details.forEach(function(line) {
                            if (line.isPotentiallyPayable && line.isIgnored) {
                                match++;
                            }
                        });
                        if (count === match) {
                            claim.alreadyPaid = 'TRUE';
                            $scope.isAlreadyPaid = true;
                            $scope.showsPayButton = false;
                        }

                        services.rbsmbl.claimDetail().flushCache();
                        detailCachePromise = null;
                        services.rbsmbl.claimSummary().flushCache();
                    };

                    var markAllRunning;
                    $scope.markAsPaidAll = function(claim) {
                        if (markAllRunning && markAllRunning.$$state && markAllRunning.$$state.status !== 1) {
                            markAllRunning.reject();
                        }
                        markAllRunning = $q.defer();

                        claim.alreadyPaid = $scope.isAlreadyPaid
                            ? 'TRUE'
                            : 'FALSE';

                        $scope.showsPayButton = !$scope.isAlreadyPaid;

                        $scope.getClaimDetails().then(function() {
                            var allPromises = [];
                            claim.details.forEach(function(line) {
                                if ($scope.isAlreadyPaid && !line.isIgnored) {
                                    line.isIgnored = true;
                                    allPromises.push(services.rbsmbl.spendingAccountIgnore().fetch(claim.clientId,claim.claimNumber,line.lnNo));
                                }
                                else if (!$scope.isAlreadyPaid && line.isIgnored) {
                                    line.isIgnored = false;
                                    allPromises.push(services.rbsmbl.spendingAccountUnignore().fetch(claim.clientId,claim.claimNumber,line.lnNo));
                                }
                            });
                            markAllRunning.promise.then(paidModal);
                            $q.all(allPromises).then(function() {
                                markAllRunning.resolve();
                                services.rbsmbl.claimDetail().flushCache();
                                detailCachePromise = null;
                                services.rbsmbl.claimSummary().flushCache();
                            });
                        });
                    };

                }
            ]
        };
    }]);
*/
};