module.exports = function(app) {
    app.controller('pages.message-center.messageCenter', ['$scope', '$state', 'storage', '$filter', 'coremetrics', '$location', 'messageCenterService', 'modal', 'services','$location','$window',
        function($scope, $state, storage, $filter, coremetrics, $location, messageCenterService, modal, services,$location,$window) {

            coremetrics.pageviewTag("MEMBER:MESSAGE CENTER HOME 1", "MEMBER:MESSAGE CENTER");
            $scope.viewState = 'loading';
            $scope.sidebar.messageCategories = [];
            $scope.pagination = {
                itemsPerPage: '10'
            };

            $scope.headings = "Message Center";
            $scope.backText = 'Back';
            $scope.sidebar.links = [];
            var downloadAttachmentUrl ="/rbsmbl/x-services/download/securemessage/attachment";

            $scope.backAction = function () {
                $state.go('msg');
            }
            $scope.detailMessage="";
            var nl = $scope.sidebar.links.push({
                heading: '<span data-teamsite="MSG_CNTR_LEFTNAV_TITLE"></span>',
                label: "Customer Service",
                state: "message",
                description: '<span data-teamsite="MSG_CNTR_LEFTNAV_DESC"></span>',
                refineTemplate: 'pages/message-center/messageCenterRefine.html'
            });
            messageCenterService.getMessages(365).then((function(pos) { return function(messages) {

                $scope.sidebar.links[pos].starburst = Number(messages.unreadMessages);
            }; })(nl - 1));

            $scope.pagination.page = 1;
            $scope.$watch('pagination.page', function(v) {
                storage.set('messageCenterRefinePage', v);
            });
            $scope.sidebar.filtersChanged = function() {
                $scope.pagination.page = 1;
            };
            $scope.messageSortOption = "-messageTimestamp"
            $scope.$on('prevClick', function() {
                var prevPage = $scope.pagination.page;
                prevPage -= 1;
                coremetrics.pageviewTag("MEMBER:MESSAGE CENTER HOME " + prevPage, "MEMBER:MESSAGE CENTER");
                coremetrics.elementTag("PREV:MESSAGE CENTER", "MEMBER:MESSAGE CENTER");
            });

            $scope.$on('nextClick', function() {
                var nextPage = $scope.pagination.page;
                nextPage += 1;
                coremetrics.pageviewTag("MEMBER:MESSAGE CENTER HOME " + nextPage, "MEMBER:MESSAGE CENTER");
            });

            $scope.$on('goToPageClick', function() {
                coremetrics.elementTag("SELECT PAGE NUMBER:MESSAGE CENTER", "MEMBER:MESSAGE CENTER");
            });

            var toggleAll = function(checked, messages) {

                coremetrics.elementTag("SELECT ALL:MESSAGE CENTER", "MEMBER:MESSAGE CENTER");

                if (checked) {
                    messages.forEach(function(message) {
                        message.isChecked = true;
                    });
                }
                else {
                    messages.forEach(function(message) {
                        message.isChecked = false;
                    });
                }
            };

            $scope.sidebar.fetchCategories = function() {
                var categoryPromise = messageCenterService.getCategories();
                return categoryPromise.then(function(category) {
                    var categoriesArray = category.data.payload.subcategory;
                    categoriesArray.forEach(function(subcat){
                        $scope.sidebar.messageCategories.push({
                            value: subcat.campaignMessage[0].campaignMessageId,
                            label: subcat.subCategoryDesc,
                            isChecked: true
                        })
                    })
                })
            };

            $scope.sidebar.fetchCategories();
            // use the checked to determine what should be deleted

            $scope.sidebar.fetchMessages = function(dateRange) {
                dateRange = JSON.parse(dateRange || createDateRange(30));
                var startDate = Number(new Date(dateRange.startDate.substr(4,2) + '/' + dateRange.startDate.substr(6,2) + '/' + dateRange.startDate.substr(0,4))),
                endDate = Number(new Date(dateRange.endDate.substr(4,2) + '/' + dateRange.endDate.substr(6,2) + '/' + dateRange.endDate.substr(0,4)));
//fromDate, toDate
                messageCenterService.getMessages(startDate, endDate).then(function(messages) {
                        console.log("messages", messages);
                        $scope.viewState = 'success';
                        $scope.messageList = messages.messageList;
                        $scope.testing = "testing";


                        if($location.search().msgID && $location.search().msgID.length>0 && $scope.messageList){
                            var msgPartIDs = $location.search().msgID.split("_");
                            if(msgPartIDs.length==4){
                                for(var i in $scope.messageList){
                                    if($scope.messageList[i].clientNumber==msgPartIDs[0] && $scope.messageList[i].groupNumber==msgPartIDs[1] && $scope.messageList[i].conversationID==msgPartIDs[3] && $scope.messageList[i].communicationID==msgPartIDs[2]){
                                        $scope.detailMessage = $scope.messageList[i];
                                        console.log("$$$$"+$scope.detailMessage);
                                    }
                                }
                            }

                        }
                       $scope.toggleAll = toggleAll;
                    },
                    function(err) {
                        $scope.viewState = 'error';
                        console.error('getMessages failed with error', err);
                    }
                );
            };

            $scope.messageCategoryFilter = function(message) {
                var categoryValues = []
                var checkedCategories  = $scope.sidebar.messageCategories.filter(function(category){
                    return category.isChecked
                })
                checkedCategories.forEach(function(category) {
                    categoryValues.push(category.value);
                })
               if (categoryValues && categoryValues.includes(message.campaignMessageId)) {
                   return true
               }
            };

            $scope.doDelete = function(listOfMessages) {
                if ($scope.allChecked) {
                    coremetrics.elementTag("SELECT ALL:MESSAGE CENTER", "MEMBER:MESSAGE CENTER");
                }
                else {
                    coremetrics.elementTag('DELETE:MESSAGE CENTER", "MEMBER:MESSAGE CENTER');
                }

                if (!(listOfMessages && listOfMessages.length)) {
                    modal.open({
                        template: require('modals/messageDeleteErrorModal.html')
                    });
                    return;
                }

                $scope.queuedForDeletion = listOfMessages.filter(
                    function (message) {
                        return message.isChecked;
                    }
                );

                if (!$scope.queuedForDeletion.length) {
                    modal.open({
                        template: require('modals/messageDeleteErrorModal.html')
                    });
                    return;
                }

                showDeleteModal();
            }

            var showDeleteModal = function() {
                modal.open({
                    template: require('modals/messageDeleteModal.html'),
                    scope: $scope
                })

            };
            $scope.deleteMessagesFromModal = function() {

                messageCenterService.deleteMessages($scope.queuedForDeletion).then(
                    function() {
                        modal.close();
                        $scope.sidebar.fetchMessages($scope.sidebar.messageDateRange);
                        $scope.allChecked = false;
                    },
                    function(err) {
                        modal.close();
                        console.error('messageService.deleteMessages failed with error', err);
                    }
                );
            };



            var createDateRange = function(startDaysAgo, endDaysAgo) {
                    startDate = $filter('date')(new Date() - startDaysAgo*60*60*24*1000, 'yyyyMMdd'),

                    endDate = endDaysAgo
                        ? $filter('date')(new Date() - endDaysAgo*60*60*24*1000, 'yyyyMMdd')
                        : $filter('date')(new Date(), 'yyyyMMdd');
                return JSON.stringify({ startDate: startDate, endDate: endDate });
            };

            $scope.sidebar.messageDateRange = createDateRange(30);

            $scope.sidebar.messageDateRangeOptions = [
                { label: "Last 7 days", value: createDateRange(7) },
                { label: "Last 30 Days", value: createDateRange(30) },
                { label: "Last 60 Days", value: createDateRange(60) }
            ];

            // var value = storage.get('messageRefineDateRange');
            // if (value) {
            //     console.log("message refine date range from storage", value);
            //
            //     var parsedValue = JSON.parse(value);
            //     var label =
            //         parsedValue.startDate.substr(4,2) + '/' +
            //         parsedValue.startDate.substr(6,2) + '/' +
            //         parsedValue.startDate.substr(0,4) + ' - ' +
            //         parsedValue.endDate.substr(4,2) + '/' +
            //         parsedValue.endDate.substr(6,2) + '/' +
            //         parsedValue.endDate.substr(0,4);
            //
            //     var found = false;
            //     for (var i = 0; i < $scope.sidebar.messageDateRangeOptions.length; i++) {
            //         if ($scope.sidebar.messageDateRangeOptions[i].value === value) {
            //             $scope.sidebar.messageDateRange = value;
            //             found = true;
            //         }
            //     }
            //     if (!found) {
            //         $scope.sidebar.messageDateRangeOptions.push({ label: label, value: value });
            //         $scope.sidebar.messageDateRange = value;
            //     }
            // }
            // else {
            //     $scope.sidebar.messageDateRange = createDateRange(365);
            // }

            $scope.$watch('sidebar.messageDateRange', function(r) {
                for (var i = 0, options = $scope.sidebar.messageDateRangeOptions, value; i < options.length; i++) {
                    value = options[i].value;

                    if (value === r) {
                        if (i < 5) {
                            options.splice(5, 1);
                        }
                    }
                }
                $scope.sidebar.fetchMessages($scope.sidebar.messageDateRange);
            });

            $scope.messageSortOptions = [
                {"label": "Date", "value": "-messageTimestamp"},
                {"label": "Message Topic", "value": "campaignMessageId"}
            ];

            $scope.headings = "Message Center";
            $scope.backText = "< Back";

            $scope.backAction = function() {
                $window.history.back();
            };

            $scope.printMessage =function(){

                window.print();

            }

            $scope.downloadAttachment = function(attachmentId) {

                var mimeType = 'application/octet-stream';
                var downloadAttachment = "";
                if (attachmentId){
                    $scope.detailMessage.attachmentList.forEach(function (attachment) {
                        if (attachment.attachmentId === attachmentId) {
                            downloadAttachment = attachment;
                        }
                    });
                }

                if (downloadAttachment && downloadAttachment.attachmentName) {
                    switch ((downloadAttachment.attachmentName.split('.')[1]).toLowerCase()) {
                        case 'txt':
                            mimeType = 'text/plain';
                            break;
                        case 'pdf':
                            mimeType = 'application/pdf';
                            break;
                        case 'doc':
                            mimeType = 'application/msword';
                            break;
                        case 'docx':
                            mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                            break;
                        case 'xls':
                            mimeType = 'application/vnd.ms-excel';
                            break;
                        case 'xlsx':
                            mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                            break;
                        case 'jpg':
                        case 'gif':
                        case 'png':
                            mimeType = 'image/*';
                            break;
                        default:
                            mimeType = 'application/octet-stream';
                    }
                }

                var fileName = downloadAttachment.attachmentName;

                var uri = null;


                //for now we will reach the RBSMBL Wrapper for this, regardless of browser

                uri = downloadAttachmentUrl + "?documentToken=" + downloadAttachment.attachmentPath + "&mimeType=" + mimeType + "&fileName=" + fileName;
                var link = document.createElement("a");
                link.href = uri;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            }


        }
    ]);
}
