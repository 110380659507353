
module.exports = function (app) {
    app
        .controller('preBlackoutAlertCtrl',["$scope", "$filter", "$sce", "$cookies", "sessionData", "services", "teamsite",
            function($scope, $filter, $sce, cookies, sessionData, services, teamsite) {

                $scope.showPreBlackout = false ;


                $scope.closeMessage = function() {
                    $scope.showPreBlackout = false;
                };



                teamsite.get('MBR_SPENDING_PRE_BLACKOUT_LANDING_MESSAGE').then(function(data){
                    $scope.MBR_SPENDING_PRE_BLACKOUT_LANDING_MESSAGE = data;
                    return $scope.MBR_SPENDING_PRE_BLACKOUT_LANDING_MESSAGE;

                });

                var useThis = function(tsValue, fallBackValue) {
                    var strThis = (tsValue && tsValue.length > 0) ? tsValue : fallBackValue;
                    return strThis;

                };




                var populateModel = function() {

                    var startDate = new Date(sessionData.memberInfo.clientList[0].spendingAccountBlackoutstartdate);
                    var endDate = new Date(sessionData.memberInfo.clientList[0].spendingAccountBlackoutenddate);
                    var preBlackoutMsg,beginD,endD;
                    if(startDate && endDate) {

                        this.startDate = new Date(startDate);
                        this.endDate = new Date(endDate);
                        if(this.startDate !== "Invalid Date" && this.endDate !== "Invalid Date") {

                            var daysTilBlackout = ((startDate - new Date()) / 86400000);

                            if(startDate <= endDate && daysTilBlackout > 0 && daysTilBlackout < 30) {

                                $scope.showPreBlackout = true;
                                beginD = this.startDate.getMonth() + 1 + "/" + this.startDate.getDate() + "/" + this.startDate.getFullYear();

                                endD = this.endDate.getMonth() + 1 + "/" + this.endDate.getDate() + "/" + this.endDate.getFullYear();
                                preBlackoutMsg = useThis($scope.MBR_SPENDING_PRE_BLACKOUT_LANDING_MESSAGE,'<b>We are performing scheduled spending account site enhancements from {{beginDate}} through {{endDate}}.</b> This will temporarily impact your ability to view your spending account balances and pay claims. You will be receiving a letter in the mail containing detailed information about what to expect during the enhancement period.');

                                preBlackoutMsg = preBlackoutMsg.split('{{beginDate}}').join(beginD);
                                preBlackoutMsg = preBlackoutMsg.split('{{endDate}}').join(endD);
                                $scope.message = preBlackoutMsg;

                            }
                        }
                    }






                };

                populateModel();

            }

        ])

        .filter('html', function($sce) {

                    return function(val) {
                        return $sce.trustAsHtml(val);
                    };
                })
        .directive('spendingPreBlackoutDirective', function() {
                       return {
                           restrict: 'A',

                           replace: true,
                           template: require('directives/landing/spendingPreBlackout.html')


                       };
                   });



};
