module.exports = function(bootstrap) {
    bootstrap.run(['Service', function(Service) {
        new Service(
            {
                name: 'wellnessSearchResults',
                group: 'rbsmbl',
                url: '/rbsmbl/x-services/webmd/v2/wellnessSearch',
                cached: true,

                fetch: function(keyWord,category,pageloc,resultsPerPage,aggregate,sharecare) {
                    return this.get(
                        {
                            keyWord : keyWord,
                            category : category,
                            pageloc : pageloc,
                            resultsPerPage : resultsPerPage,
                            aggregate: aggregate,
                            sharecare: sharecare
                        }
                    );
                }
            }
        );
    }]);
};

