module.exports = function(app) {
    app.factory('splashLinkService', ['brand', 'location', function(brand, location) {
        var
            HMBCBS = 'hmbcbs',
            HBCBS = 'hbcbs',
            HIGHMARKBCBS = 'highmarkbcbs',
            HBS = 'hbs',
            HIGHMARKBLUESHIELD = 'highmarkblueshield',
            PBS = 'pbs',
            HMBS = 'hmbs',
            HMBCBSWV = 'hmbcbswv',
            MS = 'ms',
            HIGHMARKBCBSWV = 'highmarkbcbswv',
            MBHWV = 'mbh:wv',
            WV = 'wv',
            HMBCBSDE = 'hmbcbsde',
            HIGHMARKBCBSDE = 'highmarkbcbsde',
            DE = 'de',
            PP = 'pp',
            MINCR = 'mincr',
            WYBCBS = 'wybcbs',
            NDBCBS = 'ndbcbs';

        var helpfulLinks = [
            {
                label: 'newPlan',
                brandVisibility: [HMBCBS, HBS, HMBCBSWV, HMBCBSDE],
                text: 'Enrolled in a New Plan?',
                href: '/login/#/post-enroll',
                target: '_self',
                sortOrder: 1
            },
            {
                label: 'recentlyLostCoverage',
                brandVisibility: [HIGHMARKBCBS, HMBCBS, HBCBS, HMBCBSDE, HBS, HIGHMARKBLUESHIELD, MS, HMBCBSWV, HIGHMARKBCBSWV],
                text: 'Recently Lost Coverage?',
                href: 'http://discoverhighmark.com/individuals-families/',
                target: '_self',
                sortOrder: 1
            },
            {
                label: 'findProvider',
                brandVisibility: [HIGHMARKBCBS, HMBCBS, HBCBS],
                text: 'Provider Resource Center',
                href: 'https://prc.highmark.com/rscprc/hbcbs/pub',
                target: '_blank',
                sortOrder: 1
            },
            {
                label: 'findProvider',
                brandVisibility: [HMBCBSDE, HIGHMARKBCBSDE, DE],
                text: 'Provider Resource Center',
                href: 'https://prc.highmark.com/rscprc/hdebcbs/pub',
                target: '_blank',
                sortOrder: 1
            },
            {
                label: 'findProvider',
                brandVisibility: [MS, HMBCBSWV, HIGHMARKBCBSWV, WV],
                text: 'Provider Resource Center',
                href: 'https://prc.highmark.com/rscprc/hwvbcbs/pub',
                target: '_blank',
                sortOrder: 1
            },
            {
                label: 'findProvider',
                brandVisibility: [HBS, HIGHMARKBLUESHIELD, PBS],
                text: 'Provider Resource Center',
                href: 'https://prc.highmark.com/rscprc/hbs/pub',
                target: '_blank',
                sortOrder: 1
            },
            {
                label: 'findProvider',
                brandVisibility: [MINCR],
                text: 'For Providers',
                href: 'http://providers.bluecrossmn.com/',
                target: '_blank',
                sortOrder: 3
            },
            {
                label: 'producerAccess',
                brandVisibility: [MINCR],
                text: 'For Producers',
                href: 'https://producer{env}.bluecrossmnonline.com/',
                target: '_blank',
                sortOrder: 1
            },
            {
                label: 'producerAccess',
                brandVisibility: [HIGHMARKBCBS, HMBCBS, HBCBS, HMBCBSDE, HBS, HIGHMARKBLUESHIELD, MS, HMBCBSWV, HIGHMARKBCBSWV],
                text: 'Producer Home',
                href: 'https://producer.highmark.com/',
                target: '_blank',
                sortOrder: 1
            },
            {
                label: 'disputeAppeals',
                brandVisibility: [HMBCBSDE],
                text: 'Dispute & Appeals',
                href: '/chmptl/chm/jsp/disputes.do',
                target: '_self',
                sortOrder: 1
            },
            {
                label: 'importantForms',
                brandVisibility: [MINCR],
                text: 'Member Forms',
                href: 'https://www.bluecrossmn.com/healthy/public/personal/home/mybluecross/mybc-forms-standard',
                target: '_blank',
                sortOrder: 5
            },
            {
                label: 'importantForms',
                brandVisibility: [WYBCBS],
                text: 'Member Forms',
                href: 'https://www.bcbswy.com/members/forms/',
                target: '_blank',
                sortOrder: 1
            },
            {
                label: 'formsLibrary',
                brandVisibility: [HIGHMARKBCBS, HMBCBS, HBCBS, HMBCBSDE, HBS, HIGHMARKBLUESHIELD, MS, HMBCBSWV, HIGHMARKBCBSWV],
                text: 'Forms Library',
                href: '/login/#/forms',
                target: '_self',
                sortOrder: 1
            },
            {
                label: 'individualsFamilies',
                brandVisibility: [MINCR],
                text: 'Individual & Family Plans',
                href: 'https://shop{env}.bluecrossmnonline.com',
                target: '_blank',
                sortOrder: 4
            },
            {
                label: 'individualsFamilies',
                brandVisibility: [HIGHMARKBCBS, HMBCBS, HBCBS, HMBCBSDE, HBS, HIGHMARKBLUESHIELD, MS, HMBCBSWV, HIGHMARKBCBSWV],
                text: 'Individuals & Families',
                href: 'http://discoverhighmark.com/individuals-families/',
                target: '_blank',
                sortOrder: 1
            },
            {
                label: 'medicareEligible',
                brandVisibility: [HIGHMARKBCBS, HMBCBS, HBCBS, HMBCBSDE, HBS, HIGHMARKBLUESHIELD, MS, HMBCBSWV, HIGHMARKBCBSWV],
                text: 'Medicare Eligible',
                href: 'https://medicare.highmark.com/',
                target: '_blank',
                sortOrder: 1
            },
            {
                label: 'groupsCompanies',
                brandVisibility: [HIGHMARKBCBS, HMBCBS, HBCBS],
                text: 'Groups & Companies',
                href: 'http://discoverhighmark.com/employer/?region=westernpa',
                target: '_self',
                sortOrder: 1
            },
            {
                label: 'groupsCompanies',
                brandVisibility: [HMBCBSDE, HIGHMARKBCBSDE, DE],
                text: 'Groups & Companies',
                href: 'http://discoverhighmark.com/employer/?region=delaware',
                target: '_self',
                sortOrder: 1
            },
            {
                label: 'groupsCompanies',
                brandVisibility: [MS, HMBCBSWV, HIGHMARKBCBSWV, WV],
                text: 'Groups & Companies',
                href: 'http://discoverhighmark.com/employer/?region=westvirginia',
                target: '_self',
                sortOrder: 1
            },
            {
                label: 'groupsCompanies',
                brandVisibility: [HBS, HIGHMARKBLUESHIELD, PBS],
                text: 'Groups & Companies',
                href: 'http://discoverhighmark.com/employer/?region=centralpa',
                target: '_self',
                sortOrder: 1
            },
            {
                label: 'federalEmployees',
                brandVisibility: [HIGHMARKBCBS, HMBCBS, HBCBS],
                text: 'Federal Employees',
                href: '/login/#/home/federalEmployees',
                target: '_self',
                sortOrder: 1
            },
            {
                label: 'federalEmployees',
                brandVisibility: [HMBCBSDE, HIGHMARKBCBSDE, DE],
                text: 'Federal Employees',
                href: 'http://www.fepblue.org',
                target: '_self',
                sortOrder: 1
            },
            {
                label: 'federalEmployees',
                brandVisibility: [MS, HMBCBSWV, HIGHMARKBCBSWV, WV],
                text: 'Federal Employees',
                href: '/login/#/home/federalEmployees',
                target: '_self',
                sortOrder: 1
            },
            {
                label: 'federalEmployees',
                brandVisibility: [HBS, HIGHMARKBLUESHIELD, PBS],
                text: 'Federal Employees',
                href: 'http://fepblue.org',
                target: '_self',
                sortOrder: 1
            },
            {
                label: 'fepMembers',
                brandVisibility: [WYBCBS],
                text: 'FEP Members',
                href: 'https://www.bcbswy.com/members/fep',
                target: '_blank',
                sortOrder: 5
            },
            {
                label: 'pharmacyGuide',
                brandVisibility: [WYBCBS],
                text: 'Pharmacy Guide',
                href: 'https://www.bcbswy.com/pharmacy/',
                target: '_blank',
                sortOrder: 2
            },
            {
                label: 'howTos',
                brandVisibility: [WYBCBS],
                text: "How To's",
                href: 'https://www.bcbswy.com/howto/',
                target: '_blank',
                sortOrder: 3
            },
            {
                label: 'medicalPolicies',
                brandVisibility: [WYBCBS],
                text: 'Medical Policies',
                href: 'https://www.bcbswy.com/providers/policy',
                target: '_blank',
                sortOrder: 4
            },
            {
                label: 'bcbswy.com',
                brandVisibility: [WYBCBS],
                text: 'BCBSWY.com',
                href: 'https://www.bcbswy.com/',
                target: '_blank',
                sortOrder: 6
            },
            {
                label: 'employers',
                brandVisibility: [MINCR],
                text: 'For Employers',
                href: 'http://employers.bluecrossmn.com/',
                target: '_blank',
                sortOrder: 2
            }
        ];
        var getQualifiedLinks = function(links, brand, env) {
            var qualifiedLinks = helpfulLinks.filter(function(link) {
                return link.brandVisibility.indexOf(brand) !== -1;
            });

            qualifiedLinks = qualifiedLinks.map(function(link) {
                if (env === 'www') {
                    env = '';
                }
                link.href = link.href.replace('{env}', env);

                return link;
            });

            return qualifiedLinks;
        };

        var sortLinks = function(links) {
            return links.sort(function(a, b) {
                return a.sortOrder > b.sortOrder;
            });
        };

        var getLinks = function() {
            var split = window.location.hostname.split('.');
            var env = split.slice(0, split.length-2)[0] || '';
            return sortLinks( getQualifiedLinks(helpfulLinks, brand.current.sharedBrandCode, env));
        };


        return {
            getLinks: getLinks
        };
    }]);
};
