module.exports = function(app) {
    app.controller('pages.wellness.wellnessSearch', ['$scope', '$state', '$location', 'storage', 'coremetrics', 'wellnessUtility', 'session', 'teamsite', 'brand', 'vendorSSOUtility', 'wellnessSearchResultsService','$filter',

        function($scope, $state, $location, storage, coremetrics, wellnessUtility, session, teamsite, brand, vendorSSOUtility, wellnessSearchResultsService, $filter) {

            var _showSearchResults,
                searchRefineCategories = [],
                searchCount,
                refineTriggered = false;
            
            $scope.pagination = {
                itemsPerPage : '10'
            };
            $scope.wellnessSearchViewState = 'loading';
            $scope.searchFieldValue = $state.params.searchQuery;
            $scope.adamContentUrlStr = wellnessUtility.wellnessAdamContentUrlStr;
            $scope.sidebar.wellnessSearchCategories = [];
            $scope.searchResults = {};
            $scope.resultsCount = 0;
            $scope.sidebar.resultsCount = 0;
            $scope.pagination.page = 1;
            $scope.sidebar.selectedSubCategory = 'All';

            $scope.sharecare = false;
           // $scope.isSharecareMemberLabel = false;
            $scope.isSharecareMember = false;

            //This is hard coded true for WY webmd search to filter out webmd results
            if(brand.current.sharedBrandCode === 'wybcbs'){
                $scope.sharecare = true;
            }
            var today = new Date();
            if (today >= new Date("08/01/2018")){
                teamsite.get('SHARECARE_SOFTLAUNCH_GROUPS').then(function (data){
                    $scope.shareCareGroup = data;
                    if($scope.shareCareGroup.indexOf(session.data.memberInfo.clientList[0].groupList[0].groupNumber)>-1){
                        $scope.sidebar.isSharecareMember = true;
                        $scope.isSharecareMemberLabel = true;
                        $scope.sharecare = true;
                    }
                    else if(($scope.shareCareGroup === "service") && session.data.memberInfo.brand === "HMBCBS" && session.data.memberInfo.clientList.length>=1
                        && session.data.memberInfo.clientList[0].groupList!== null &&  session.data.memberInfo.clientList[0].groupList.length>=1
                    ){//wellnessVendorIndicator indicator changes
                        for(var i=0; i<session.data.memberInfo.clientList[0].groupList.length;i++)
                        {
                            if(session.data.groupNumber === session.data.memberInfo.clientList[0].groupList[i].groupNumber) {
                                if(session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator!== null &&
                                    session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator === "ShareCare") {
                                    $scope.sidebar.isSharecareMember = true;
                                    $scope.isSharecareMemberLabel = true;
                                    $scope.sharecare = true;
                                }
                                $scope.sharecare = true;
                            }
                        }
                        //$scope.isMNWebMDflag = true;
                        $scope.isSharecareMemberLabel = true;
                    }
                });
                teamsite.get('SHARECARE_SOFTLAUNCH_GROUPS_MN').then(function (data) {
                    $scope.shareCareGroup = data;
                    if ($scope.shareCareGroup.indexOf(session.data.memberInfo.clientList[0].groupList[0].groupNumber) > -1) {
                        $scope.sidebar.isSharecareMember = true;
                        $scope.isSharecareMemberLabel = true;
                        $scope.sharecare = true;
                    }
                    else if(($scope.shareCareGroupMN === "service") && session.data.memberInfo.brand === "MINCR" && session.data.memberInfo.clientList.length>=1
                        && session.data.memberInfo.clientList[0].groupList!== null &&  session.data.memberInfo.clientList[0].groupList.length>=1
                    ){//wellnessVendorIndicator indicator changes
                        for(var i=0; i<session.data.memberInfo.clientList[0].groupList.length;i++)
                        {
                            if(session.data.groupNumber === session.data.memberInfo.clientList[0].groupList[i].groupNumber) {
                                //  session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator = "ShareCare";
                                if(session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator!== null &&
                                    session.data.memberInfo.clientList[0].groupList[i].wellnessVendorIndicator === "ShareCare") {
                                    $scope.sidebar.isSharecareMember = true;
                                    $scope.isSharecareMemberLabel = true;
                                    $scope.sharecare = true;
                                }
                                $scope.sharecare = true;
                            }
                        }
                        $scope.isMNWebMDflag = true;
                        $scope.isSharecareMemberLabel = true;
                    }
                });
            }

            var wellnessRefineTemplate = "pages/wellness/wellnessSearchRefineTemplate.html";

			// Sidebar link
			var wellnessLink = {
				heading: 'Your Search Results',
				label: 'Wellness Search',
				state: "wellness.wellnessSearch"
			};
			var DEEP_SEARCH = true;
			$scope.$watch(
				function(){ return $scope.sidebar.links; },
				function(newValue){
					var totalSidebarLinks = $scope.sidebar.links && $scope.sidebar.links.length;
					var needsLink = true;
					while(totalSidebarLinks--){
						if($scope.sidebar.links[totalSidebarLinks].state === "wellness.wellnessSearch") {
							needsLink = false;
						}
					}
					if (needsLink) {
						$scope.sidebar.links.push(wellnessLink);
					}
				},
				DEEP_SEARCH
			);
			$scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
				if(fromState.name !== toState.name && fromState.name === 'wellness.wellnessSearch'){
					var i = $scope.sidebar.links.length;
					while(i--) {
						if ($scope.sidebar.links[i].state === "wellness.wellnessSearch") {
							var item = $scope.sidebar.links.slice(i,1);
							console.log("removed items", item);
							break;
						}
					}
				}
			});



            var targetTypes = wellnessUtility.wellnessTargetTypes,
                sessionData = wellnessUtility.wellnessSessionInfo,
                category = wellnessUtility.wellnessCategory,
                returnUrl = "/login/#/wellness/wellness-search/"+$scope.searchFieldValue,
                returnPage = "Wellness";
// keep this code for testing - sharecare
            /*$scope.sharecare = false;
             if(!$scope.sidebar.isSharecareMember){
             $scope.sharecare = true;
             }*/

            _showSearchResults = function(searchKeyword, category, pageLoc, resultsPerPage,sharecare){
                //Hardcoded Share care as true for decommissioning WEBMD
                var searchResultsPromise = wellnessSearchResultsService.getwellnessSearchResults(searchKeyword, category, pageLoc, resultsPerPage,true,true);
                searchResultsPromise.then(
                    function(response){
                        $scope.searchResults = response.searchResults;
                        if(!$scope.sidebar.wellnessSearchCategories || $scope.sidebar.wellnessSearchCategories.length === 0){
                            $scope.sidebar.wellnessSearchCategories = response.refineCategories;
                        }

                        if (!category || category === 'All') {
                            $scope.sidebar.selectedSubCategory = $scope.sidebar.wellnessSearchCategories[0].subCategory;
                            $scope.resultsCount = response.searchResultsCount;
                        } else {
                            $scope.resultsCount = $scope.sidebar.selectedCategoryCount;
                            $scope.resultsCount = $scope.sidebar.selectedCategoryCount || response.searchResultsCount; 
                        }

                        $scope.sidebar.description = 'You have a total of '+ $scope.resultsCount +' results';

                        if ($scope.resultsCount > 0) {
                            wellnessLink.refineTemplate = wellnessRefineTemplate;
                        }
                        else if (wellnessLink.refineTemplate) {
                            delete wellnessLink.refineTemplate;
                        }

                        if(response.searchResultsCount > 0){
                            $scope.wellnessSearchViewState = 'success';
                        } else {
                            $scope.wellnessSearchViewState = 'noResults';
                        }
                    },

                    function(err) {
                        console.error('searchResultsPromise failed with error', err);
                        $scope.wellnessSearchViewState = 'error';
                        return searchResultsPromise;
                });
            };

            var returnFromSearchDetail = storage.get('wellnessSearchDetailVisited');

            if (returnFromSearchDetail) {
                $scope.pagination.page = storage.get('wellnessSearchPage') || 1;
                $scope.sidebar.selectedSubCategory = storage.get('wellnessSearchCategory') || 'All';
                $scope.sidebar.selectedCategoryCount = storage.get('wellnessSearchCategoryCount');
                $scope.pagination.itemsPerPage = storage.get('wellnessSearchItemsPerPage') || '10';
                storage.del('wellnessSearchDetailVisited');
                storage.del('wellnessSearchPage');
                storage.del('wellnessSearchCategory');
                storage.del('wellnessSearchItemsPerPage');
            }

            //_showSearchResults($scope.searchFieldValue, $scope.sidebar.selectedCategory.subCategory, $scope.pagination.page, $scope.pagination.itemsPerPage);

            var setStorage = function() {
                storage.set('wellnessSearchDetailVisited',true);
                storage.set('wellnessSearchCategoryCount', $scope.sidebar.selectedCategoryCount);
                storage.set('wellnessSearchPage', $scope.pagination.page);
                storage.set('wellnessSearchCategory', $scope.sidebar.selectedSubCategory);
                storage.set('wellnessSearchItemsPerPage',$scope.pagination.itemsPerPage);
            };

            $scope.wellnessSearchResultItemSSO = function(startID, title){
                setStorage();
                coremetrics.elementTag('WELLNESS SEARCH RESULT '+ title,'MEMBER:WELLNESS');

                var sitePromotionStr = 'cm_sp=WebMD-_-Health%20Topics-_-Health%20Topics';
                var ssoParameters = {
                    clientNumber: sessionData.clientNumber,
                    groupNumber: sessionData.groupNumber,
                    targetType: startID,
                    category: category,
                    returnUrl: returnUrl,
                    returnPage: returnPage,
                    brand: sessionData.brand
                };
                vendorSSOUtility.performSSO(ssoParameters, "_self", sitePromotionStr);
            };

            $scope.goToAdamContentDetail = function(title){
                var filteredTitle = $filter('adamFilter')(title);
                setStorage();
                coremetrics.elementTag('WELLNESS SEARCH RESULT ' + filteredTitle, 'MEMBER:WELLNESS');

            };

            $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
                if (fromState.name !== toState.name) {
                    // search happened from another page, scroll to top
                    $('html, body').animate({scrollTop: 0}, 1); // Cross browser friendly way to control scroll top.  Duration of 1ms because we don't want flicker.
                }
            });

            $scope.sidebar.wellnessSearchRefine = function(category){
                $scope.wellnessSearchViewState = 'loading';
                $scope.sidebar.selectedSubCategory = category;
                $scope.pagination.page = 1;
                _showSearchResults($scope.searchFieldValue, category,$scope.pagination.page, $scope.pagination.itemsPerPage,$scope.sharecare);
            };

            $scope.$watch('pagination.page', function(value) {
                $scope.wellnessSearchViewState = 'loading';
                _showSearchResults($scope.searchFieldValue, $scope.sidebar.selectedSubCategory, $scope.pagination.page, $scope.pagination.itemsPerPage,$scope.sharecare);
            });

            $scope.$watch('pagination.itemsPerPage', function(value) {
                $scope.wellnessSearchViewState = 'loading';
                _showSearchResults($scope.searchFieldValue, $scope.sidebar.selectedSubCategory, $scope.pagination.page, $scope.pagination.itemsPerPage,$scope.sharecare);
            });

            $scope.searchResultCoremetrics = function(title){
                coremetrics.elementTag('WELLNESS SEARCH RESULT '+ title,'MEMBER:WELLNESS');
            };

            $scope.sidebar.searchRefineCoremetrics = function(){
                if (!refineTriggered) {
                    coremetrics.elementTag('WELLNESS SEARCH REFINE', 'MEMBER:WELLNESS');
                    refineTriggered = true;
                }
            };

        }
    ]);
};


