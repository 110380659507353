module.exports = function (app) {
    app.controller('pages.account.other-insurance.OIStep1', ['$scope', 'finder-logic2', 'sessionData', 'services', 'brand', 'coremetrics',
        function ($scope, finderLogic, sessionData, services, brand, coremetrics) {
            console.log("==================================================STEP 1==================================================");
            
            $scope.fixSidebar();

            var currentPlan = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate('Medical');
            var members = currentPlan.memberList;
            var currGrp = finderLogic.getPortalSelectedPlan()
            var clientInfo = services.rbsmbl.memberInfo().getPortalSelectedClient();
            var theBrand = brand.current.sharedBrandCode;
            var currentPlans = finderLogic.portalSelectedPlanActiveProducts();

            var COB = services.rbsmbl.otherInsurance().getCOB(currGrp, theBrand);

            $scope.otherInsuranceChevron.step = 1;
            $scope.model.atLeastOneActive = false;
            $scope.model.atLeastOneMedicare = false;

            $scope.nextButton = function (step) {
                $scope.nextButtonClick(step);
            };

            var memberList = $scope.$parent.removeDuplicateMembers(members);
            var memberListInfo = [];
            var DOBInfo;

            memberListInfo = $scope.getmemberListInfo(memberList);
            DOBInfo = $scope.getDOBShowFlag(memberListInfo);
            var user = $scope.getFullMemberList(memberList);
            var onExchange = (currentPlan.hcrProduct === 'ON') ? true : false;

            if (onExchange) {
                $scope.model.offExchange = false;
                $scope.model.isDependent = (user[0].relationship !== 'Subscriber') ? true : false;
            } else {
                $scope.model.onExchange = false;
                services.rbsmbl.otherInsurance().getCOB(currGrp, theBrand).then(function (data) {
                    $scope.model.otherInsurancesResponse = data.data.payload;
                    $scope.mergeUMSCOB();
                    $scope.populateModelfromUMS(user);
                });
            }
            ;


        }
    ])
}