module.exports = function(app) {
    var teleDocModal = require("../../modals/teladocModal")
    app.directive('toolsUi', function() {
        // var teleDocModal = require("../../modals/teladocModal")
        return {
            restrict: 'A',
            replace: true,
            template: require('directives/landing/toolsUi.html'),
            scope: true,
            controller: function($scope, teamsite, $element, $attrs, sessionData, vendorSSOUtility, modal, ebillUserExists, myChartUtil) {
                // $scope.telaDocModal = telaDocModal;
                $scope.showFindProvider = false;
                $scope.showPrescriptionServices = false;
                $scope.showCompareCosts = false;
                $scope.showPayPlanPremium = false;
                $scope.showSavingsAdvisor = false;
                $scope.showTelemedicine = false;

                // Savings Advisor =======================================================================
                var psp = $scope.psp;
                var booShowSA =  (psp && psp.chcIndicator && psp.chcIndicator && psp.chcIndicator == 'true') ? true : false;
                // var booShowSA = ((acctSettings.isCHC == 'true') || (acctSettings.isCHC == true)) ? true : false;

                //For Minnesota - Show CHC link for subscribers and dependents
                //Everyone else - Show only for subscribers
                if ((sessionData.memberInfo.brand.toLowerCase() !== 'mincr') && (!sessionData.isSubscriber)) {
                        booShowSA = false;
                    }
                $scope.showSavingsAdvisor = booShowSA;
                $scope.funcs.savingsAdvisor = function() {
                    var returnUrl = "/mbr/#/landing";
                    var returnPage = "LANDING";

                    var ssoParameters = {
                        clientNumber: psp.theClientNumber,
                        groupNumber: psp.groupNumber,
                        targetType: 'landingPage',
                        category: 'savingsAdvisor',
                        returnUrl: returnUrl,
                        returnPage: returnPage,
                        brand: $scope.brandData.sharedBrandCode.toUpperCase()
                    };
                    vendorSSOUtility.performSSO(ssoParameters, "", "", true);
                };
                // END SAVINGS ADVISOR ====================================================================

                // FIND A DOCTOR ==========================================================================
                $scope.showFindProvider = true;
                var newPVDLink; // = (btsGlobal && btsGlobal.appConfig && btsGlobal.appConfig.LANDING && btsGlobal.appConfig.LANDING.NEW_PVD_HREF && btsGlobal.appConfig.LANDING.NEW_PVD_HREF.booleanValue && btsGlobal.appConfig.LANDING.NEW_PVD_HREF.overrideValue) ? btsGlobal.appConfig.LANDING.NEW_PVD_HREF.overrideValue + "#/home?" : false;
                teamsite.get('MBR_MBRHOME_HEALTHTOOLS_PROVIDER_LINK_HREF').then(function(providerHref) {
                    providerHref = 'https://' + window.location.host + providerHref;
                    providerHref = providerHref.replace('denv', 'tenv');
                    $scope.targetUrls.findAProvider = (newPVDLink) ? newPVDLink : providerHref;
                });
                // MYCHART ================================================================================
                $scope.showMyChart = myChartUtil.hasActivePlan() && sessionData.memberInfo.brand.toLowerCase() == 'hmbcbs';
                teamsite.get('MBR_MBRHOME_HEALTHTOOLS_PROVIDER_LINK_HREF').then(function(myChartHref) {
                    $scope.targetUrls.myChartUrl = myChartHref;
                });
                // END MYCHART ============================================================================

                // COMPARE COSTS ==========================================================================
                // Loveson is putting the logic for this in the service ===================================
                // https://tenv7.highmarkbcbs.com/cce/landing-page.html?gn=01704101&ed=05012012
                $scope.showCompareCosts = $scope.psp.hasCareCostEstimator;  // false;

                // we build a querystring when the planInfo service returns
                // we use the same querystring in espotsUi_DONOTUSE.js
                $scope.$watch('groupNumberAndEffectiveDateQuerystring', function(theQuerystring) {
                    var compareCostsUrl;
                    if (theQuerystring) {
                        // https://tenv7.highmarkbcbs.com/cce/landing-page.html?gn=01353204&ed=02012011
                        compareCostsUrl = $scope.funcs.urlBuilder('/cce/landing-page.html' + theQuerystring);
                    } else {
                        compareCostsUrl = $scope.funcs.urlBuilder('/cce/landing-page.html');
                    }
                    $scope.targetUrls.compareCosts = compareCostsUrl;
                });

                // this is hit when the planInfo service response hits landing.js
                $scope.$watch('plans', function() {
                    if ($scope.plans && $scope.plans.plans) {
                        // set any values needed in psp
                        psp.theClientNumber = $scope.plans.plans.selected.clientNumber;
                        psp.effectiveDate = $scope.plans.plans.selected.memberEffectiveDate;
                    }
                    // PRESCRIPTION SERVICES =============================================================
                    if ($scope.plans && sessionData.benefits.drugActive) {
                        var pharmacyBenefitProvider = (sessionData.pharmacyBenefitProviderIndicator.length > 0) ?
                            sessionData.pharmacyBenefitProviderIndicator : null;
                        pharmacyBenefitProvider = (pharmacyBenefitProvider == 'None') ? null : pharmacyBenefitProvider;
                        var arrPharmacyProvider = ['Medco', 'Catalyst', 'RX Claim', 'RX Claim – Commercial', 'Prime'];
                        if (arrPharmacyProvider.indexOf(pharmacyBenefitProvider) > -1) {
                            $scope.showPrescriptionServices = true;
                            // MBR NOTE:  these urls do NOT show up on hover in the browser. The Core Metrics programmer
                            // MBR NOTE:  hard coded cmPrescriptionService method in the html
                            // MBR NOTE:  so these urls are now used there
                            switch(pharmacyBenefitProvider) {
                                case 'Medco':
                                    $scope.targetUrls.prescriptionService = '/chmptl/chm/jsp/medcoRouter.do?typeMEDCO=mailrx';
                                    break;
                                case 'Prime':
                                    $scope.targetUrls.prescriptionService = "/chmptl/chm/jsp/singleSignOn.do?vendor=prime";
                                    break;
                                default:
                                    $scope.targetUrls.prescriptionService = "/chmptl/chm/jsp/singleSignOn.do?vendor=catamaran";
                                    break;
                            }
                        } else {
                            // they do not have one of the specified pharmacyBenefitProvider but they still may get the item
                            // 1. if they do not have rxClaims, and
                            // 2. they have a LOB we are looking for
                            // 3. they have active drug coverage
                            if (!sessionData.rxClaims) {
                                // pds: in consultation with Frank I am not trying to determine if the
                                //      member has integrated drug coverage - which is what we did in MBR.
                                //      The properties in session.data make that unnecessary
                               var currentMedicalProduct = $scope.plans.plans.selected.planName.benefit.medical
                                var arrPossibleLobValues = ['HMO', 'PPO', 'EPO', 'HDHP', 'CV', 'POS', 'TDL'];
                                var hasRequiredLob = false;
                                for (var i = 0; i < arrPossibleLobValues.length; i++) {
                                    hasRequiredLob = (currentMedicalProduct.indexOf(arrPossibleLobValues[i]) > -1)
                                                        ? true
                                                        : hasRequiredLob;  }
                                // if we are here, session.data.benefits.drugActive == true
                                if (hasRequiredLob) {
                                    var lowerCaseBrand = sessionData.memberInfo.brand.toLowerCase();
                                    var ibcRelatedBrands = ["inac", "ibc", "ibccr", "ibx", "ibcah"];
                                    // if their brand is NOT IBC, show the item and link it like Medco
                                    if (ibcRelatedBrands.indexOf(lowerCaseBrand) == -1) {
                                        $scope.targetUrls.prescriptionService =
                                            this.useThis(m.tsResponse.MBR_MBRHOME_HEALTHTOOLS_RX_LINK_HREF, '{{MBR_MBRHOME_HEALTHTOOLS_RX_LINK_HREF}}');
                                        $scope.showPrescriptionServices = true;
                                    } else {
                                        // hide the item
                                        $scope.showPrescriptionServices = false;
                                    }
                                }
                            } else {
                                // rxClaims is true (and other conditions are not true) so we do not show the item
                                $scope.showPrescriptionServices = false;
                            }
                        }
                     }
                });

                // PAY PLAN PREMIUM =======================================================================
                var theProducts = $scope.activeProducts;
                // we have a default url to take them to if they do not have an eBill Account
                teamsite.get('MBR_MBRHOME_HEALTHTOOLS_PAYPREMIUM_LINK_HREF').then(function(theValue) {
                    // theTarget = 'https://' + window.location.host + '/' + theTarget;
                    var theUrl = 'https://' + window.location.host + theValue;
                    theUrl = theUrl.replace('denv', 'tenv');
                    $scope.targetUrls.payPlanUrl = theUrl;
                    runEbillUserExists();  // we wait till we have the TS value for the url
                });
                // we hit a web service to see if the user has an eBill account
                // and if they do, the service sends us the target url to take them to.
                var runEbillUserExists = function () {
                    var getPayPlanStuff = ebillUserExists.theUrl(sessionData.memberInfo.brand);
                    getPayPlanStuff.then(function (theResponse) {
                        var payPlanPremiumUrl = (theResponse && theResponse.data && theResponse.data.payload
                            && theResponse.data.payload.ebillAccountexists && theResponse.data.payload.ebillAccountexists != 'N'
                            && theResponse.data.payload.ebillRedirectUrl && theResponse.data.payload.ebillRedirectUrl.length > 0 )
                            ? theResponse.data.payload.ebillRedirectUrl
                            : null;
                        if (payPlanPremiumUrl != null) {
                            // both TS and this service resolve to the same url for wpa, cballiet
                            // "https://tenv7.highmarkbcbs.com/chmptl/chm/jsp/memberEbillGateway.do"
                            var theUrl = 'https://' + window.location.host + payPlanPremiumUrl;
                            theUrl = theUrl.replace('denv', 'tenv');
                            $scope.targetUrls.payPlanUrl = theUrl;
                        } else {
                            // hide the item
                            $scope.showPayPlanPremium = false;
                        }
                    });  }
                // in bts 3, this medicalPlan.seniorProductIndicator is the following in bts4 ... I think
                // so this  var isQualifiedSenior = isSeniorProductHolder && hasActiveMedicalProduct
                // m.showPayPlanPremium = (isQualifiedSenior || isDirectPay) && isASubscriber;  becomes this:
                var hasActiveMedicalProduct = false;
                if (theProducts) {
                    for (var i = 0; i < theProducts.length; i++) {
                        hasActiveMedicalProduct = (theProducts[i].benefitPlanType == 'Medical') ? true : hasActiveMedicalProduct
                    }
                }
                if (
                    ((hasActiveMedicalProduct && sessionData.isSeniorProductIndicator) || sessionData.directPayIndicator)
                    && sessionData.isSubscriber
                    ) {
                    $scope.showPayPlanPremium = true;
                } else {
                    $scope.showPayPlanPremium = false;
                }
                // END PAY PLAN PREMIUM ====================================================================

                // TELEDOC / TELEMEDICINE / VIRTUAL MEDICINE ===============================================
                // jimcov sends this TS ID MBR_TELEMEDICINE_PROVIDER_AMWELL_DOCTORONDEMAND
                // $scope.psp.productList[0].telemedicineVendorName
                var telemedicineVendors = [];
                if (theProducts) {
                    for (var m = 0; m < theProducts.length; m++) {
                        if (theProducts[m].telemedicineVendorName && theProducts[m].telemedicineVendorName.length > 0) {
                            if ((telemedicineVendors.toString()).indexOf("Teladoc") == -1) {
                                // we dont want duplicates
                                telemedicineVendors.push(theProducts[m].telemedicineVendorName);
                            }
                        }
                    }
                }
                $scope.teleMedicineContent = {};
                // $scope.teleMedicineContent.disclaimer = 'snot';
                if (telemedicineVendors.length == 0) {
                    $scope.showTelemedicine = false;
                } else {
                    $scope.showTelemedicine = true;
                    var telemedicineTeamsiteSuffix = telemedicineVendors[0].replace(/\s+/g, '').replace("&", "_");
                    var thisTsContentId = 'MBR_TELEMEDICINE_PROVIDER_' + telemedicineTeamsiteSuffix.toUpperCase();
                    teamsite.get(thisTsContentId).then(function(theTsValue) {
                        theTsValue = JSON.parse(theTsValue);
                        $scope.teleMedicineContent.title = theTsValue.title;
                        $scope.teleMedicineContent.disclaimer = theTsValue.disclaimer;
                        $scope.teleMedicineContent.url = theTsValue.url;
                        $scope.teleMedicineContent.link = theTsValue.link;
                        // $scope.$apply();  error: digest already in progress
                    });
                }
                $scope.funcs.popTeleDoc = function() {
                    modal.open({
                        template: require('modals/teladocModal.html'),
                        controller: teleDocModal,
                        scope: $scope
                    });
                };

                $scope.funcs.closeTeladocModal = function(theTarget) {
                    if ($scope.teleMedicineContent.sso !== undefined) {
                        var ssoParams = $scope.teleMedicineContent.sso[theTarget];

                        var ssoAppConfig = null;
                        if (ssoParams && ssoParams.disableAppConfigId && ssoParams.disableAppConfigId.length > 0 && sessionData && sessionData.appConfig && sessionData.appConfig.FIND_A_DOCTOR && sessionData.appConfig.FIND_A_DOCTOR[ssoParams.disableAppConfigId]) {
                            ssoAppConfig = sessionData.appConfig.FIND_A_DOCTOR[ssoParams.disableAppConfigId];
                        }

                        if (ssoAppConfig !== null && ssoAppConfig.booleanValue === true) {
                            // If SSO is disabled by AppConfig, then just open as a URL in new window.
                            window.open($scope.teleMedicineContent.url[theTarget].href);
                        } else {
                            var ssoParameters = {
                                clientNumber: psp.theClientNumber,
                                groupNumber: psp.groupNumber,
                                targetType: ssoParams.targetType,
                                category: ssoParams.category,
                                vendorName: ssoParams.vendorName,
                                brand: $scope.brandData.sharedBrandCode.toUpperCase()
                            };
                            vendorSSOUtility.performSSO(ssoParameters, ssoParams.targetLocation, ssoParams.sitePromotionStr);
                        }
                    } else {
                        window.open($scope.teleMedicineContent.url[theTarget].href);
                    }
                    modal.close({
                        template: require('modals/teladocModal.html'),
                        controller: teleDocModal,
                        scope: $scope
                    });
                };
            }
        };
    });
}