module.exports = function(app) {
    app

        .controller('preEffectuatedBannerCtrl',["$scope","$window", "$filter", "$q", "$cookies", "sessionData", "services", "teamsite", "coremetrics", "brand",
            function($scope,$window, $filter, $q, cookies,sessionData, services, teamsite, coremetrics, brand){

                $scope.showPreEffectuatedBanner = false;

                $scope.closeMessage = function() {
                    if (this.showPreEffectuatedBanner === true) {
                        this.showPreEffectuatedBanner = false;
                    }
                };

                var brandCode = brand.current.sharedBrandCode;

                var formsLibraryBannerAppConfig = (sessionData &&
                                                    sessionData.appConfig &&
                                                    sessionData.appConfig.LANDING &&
                                                    sessionData.appConfig.LANDING.SHOW_FORMS_LIBRARY_BANNER &&
                                                    sessionData.appConfig.LANDING.SHOW_FORMS_LIBRARY_BANNER.booleanValue);

                teamsite.get('MBR_MBRHOME_BANNER_PRE_EFFECTUATED_TITLE').then(function(resp){
                    $scope.MBR_MBRHOME_BANNER_PRE_EFFECTUATED_TITLE = resp;
                    return $scope.MBR_MBRHOME_BANNER_PRE_EFFECTUATED_TITLE;
                });

                teamsite.get('MBR_MBRHOME_BANNER_PRE_EFFECTUATED_DESCRIPTION').then(function(resp){
                    $scope.MBR_MBRHOME_BANNER_PRE_EFFECTUATED_DESCRIPTION = resp;
                    return $scope.MBR_MBRHOME_BANNER_PRE_EFFECTUATED_DESCRIPTION;
                });

                teamsite.get('MBR_MBRHOME_BANNER_PRE_EFFECTUATED_RIBBON_ITEMS').then(function(resp){
                    $scope.MBR_MBRHOME_BANNER_PRE_EFFECTUATED_RIBBON_ITEMS = resp;
                    return $scope.MBR_MBRHOME_BANNER_PRE_EFFECTUATED_RIBBON_ITEMS;
                });
            //Commenting out old logic. New requirement- need whole experience instead of just this banner
                //services.rbsmbl.memberInfo().fetch().then(function (fullMemberInfo) {
                //      var memberInfoData = fullMemberInfo.data.payload;
                //      var CoverageBegin  =  memberInfoData.clientList[0].groupList[0].productList[0].coverageBeginDate;
                //      var covBegDate = new Date (CoverageBegin);
                //      var today = new Date();
                //
                //      var isFutureCheckBoolean = (today <= covBegDate);
                //      var preEffectuatedBrands = ['hmbcbs', 'hbs', 'hmbcbswv', 'hmbcbsde', 'pp'];
                //      if ((preEffectuatedBrands.indexOf(brandCode.toLowerCase()) > -1) &&
                //          (formsLibraryBannerAppConfig == true) && isFutureCheckBoolean) {
                //             $scope.showPreEffectuatedBanner = true;
                //         }
                //
                //   }
                //);

            }])
        .directive('preEffectuatedDirective', function() {
                       return {
                           restrict: 'A',

                           replace: true,
                           template: require('directives/landing/preEffectuatedBanner.html')


                       };
                   });
};