module.exports = function(app) {
    app.directive('messageCenterDrawer', ['$state', 'messageCenterService', 'sidebar','$window','storage',
        function($state, messageCenterService, sidebar,$window,storage) {

            return {
                restrict: 'A',
               // replace: true,
                template: require('directives/message-center/messageCenterDrawer.html'),

                scope: {
                    message: '=messageCenterDrawer'
                },


                controller: ['$scope', 'coremetrics', '$element','$window','$rootScope',
                    function($scope, coremetrics, $element,$window,$rootScope) {
                        var
                            message = $scope.message,
                           setMessageStatus;
                        var downloadAttachmentUrl ="/rbsmbl/x-services/download/securemessage/attachment";


                     setMessageStatus = function(isOpen) {
                            if (isOpen && (message.communicationActionCode == "U")) {
                                coremetrics.elementTag("MESSAGE READ:MESSAGE CENTER", "MEMBER:MESSAGE CENTER");
                                console.log("hitting message status")

                                messageCenterService.setStatus(message, 'R').then(
                                    function() {
                                        message.messageStatus = 'R';
                                        message.custProp = "";
                                        sidebar.decrementStarburst(sidebar.getLinkByLabel('Customer Service'));
                                    },
                                    function(err) {
                                        console.error('messageStatus.setStatus failed with error:', err);
                                    }
                                );
                            }
                        };

                        $scope.$watch('message.isOpen', function(newVal) {
                            setMessageStatus(newVal);
                            if(message.bIsTruncated){
                                $scope.readMore =true;
                            }
                        });

                        $scope.toggleReadMore=function(){
                            $scope.readMore = !$scope.readMore;
                        }

                        $scope.printMessage =function(msgData){

                            var innerContents = $element[0].getElementsByClassName("printMessageVersion")[0].innerHTML;

                            msgData.msgDate = msgData.messageDate;
                            msgData.msgTime = msgData.messageTime;


                            var popupWindow = $window.open('',  'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
                            popupWindow.document.open();
                            popupWindow.document.write('<html><head><link media="print" rel="stylesheet" type="text/css" src="/etc/designs/mbrweb/app/resources/msgCen.css"></head><body onload="window.print();this.close();">' + innerContents + '</html>');
                            popupWindow.document.close();

                        }



                        $scope.downloadAttachment = function(attachmentId) {

                            var mimeType = 'application/octet-stream';
                            var downloadAttachment = "";
                            if (attachmentId){
                                message.attachmentList.forEach(function (attachment) {
                                    if (attachment.attachmentId === attachmentId) {
                                        downloadAttachment = attachment;
                                    }
                                });
                            }

                                        if (downloadAttachment && downloadAttachment.attachmentName) {
                                            switch ((downloadAttachment.attachmentName.split('.')[1]).toLowerCase()) {
                                                case 'txt':
                                                    mimeType = 'text/plain';
                                                    break;
                                                case 'pdf':
                                                    mimeType = 'application/pdf';
                                                    break;
                                                case 'doc':
                                                    mimeType = 'application/msword';
                                                    break;
                                                case 'docx':
                                                    mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                                                    break;
                                                case 'xls':
                                                    mimeType = 'application/vnd.ms-excel';
                                                    break;
                                                case 'xlsx':
                                                    mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                                                    break;
                                                case 'jpg':
                                                case 'gif':
                                                case 'png':
                                                    mimeType = 'image/*';
                                                    break;
                                                default:
                                                    mimeType = 'application/octet-stream';
                                            }
                                        }

                                        var fileName = downloadAttachment.attachmentName;

                                        var uri = null;


                                        //for now we will reach the RBSMBL Wrapper for this, regardless of browser

                                        uri = downloadAttachmentUrl + "?documentToken=" + downloadAttachment.attachmentPath + "&mimeType=" + mimeType + "&fileName=" + fileName;
                                        var link = document.createElement("a");
                                        link.href = uri;
                                        link.download = fileName;
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);

                        }

                        $scope.replyToMesssage=function(){
                           // $rootScope.$broadcast('replyMessage', $scope.message);
                            storage.set("replyMessage",$scope.message);
                            $window.location = "/login/#/msg/contact-us?reply="+$scope.message.clientNumber+"_"+message.groupNumber+"_"+message.communicationID+"_"+message.conversationID;
                        }

                    }
                ]
            };
        }
    ]);
};