module.exports = function (app) {

	/**
	* @ngedoc ngController
	* @name pages.claimsAndSpending
	*
	* @requires $scope
	* @description
	* The pages.claimsAndSpending controller is a category level controller in Member Portal 
	* for the combined Claims & Spending page. 
	*/
	app.controller("pages.claimsAndSpending", ["services", "$scope", "teamsite", "sessionData", "coremetrics", "$state", "$sce", '$rootScope', '$cookies', 'manualHsaService', "brand", "modal", "finder-logic2",
		function (services, $scope, teamsite, sessionData, coremetrics, $state, $sce, $rootScope, $cookies, manualHsaService, brand, modal, finderLogic) {
		$scope.headings = "Claims & Spending";
		$scope.sidebar.links = [];
		$scope.sidebar.data = {};
		$scope.sidebar.data.accumsFilter = 'activePlans';
		$scope.backText = 'Back';
		$scope.backAction = function() {
			$state.go('claimsAndSpending');
		}
		$scope.isVitalsActive = (brand.current.isVitalsActive) &&
			(sessionData && sessionData.appConfig && sessionData.appConfig.PROVIDER && sessionData.appConfig.PROVIDER.VITALS_LIVE && sessionData.appConfig.PROVIDER.VITALS_LIVE.booleanValue == true);

		$scope.showVitalsModal = function(){
			$scope.continueToDestination = function(){
                var targUrl = finderLogic.getSapphireSSOUrl(brand.current.sharedBrandCode);
                window.open(targUrl);
                $scope.closeModal();
			};
            $scope.siteToLeave = window.location.host.indexOf("mybenefitshome.com") !== -1 ?"Your health plan": "Highmark";
			modal.open({
				heading: "YOU WILL BE REDIRECTED TO SAPPHIRE DIGITAL",
				template: require('modals/vitalsDisclaimerModal.html'),
				scope:$scope
			});
		};

		// var coveredExpenses = teamsite.get("CLAIMS_AND_SPENDING_NAV_COVERED_EXPENSES");
		
		var portalSelectedPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan();
		var hasSpendingAcct = portalSelectedPlan.spendingAccountExistsIndicator;

		if(hasSpendingAcct === "false" && !manualHsaService.shouldUserSeeHSAPage(sessionData.memberInfo)) {
			var hideSpending = true;
		}

		var payPremiumEnabledBrands = ['hmbcbs', 'hbs', 'hmbcbswv', 'hhic', 'fl', 'lou', 'az', 'neb', 'exc', 'regbcbs', 'regbs', 'wi', 'hz', 'inac', 'hmbcbsde', 'mn', 'pp', '6011', 'la','wybcbs'];
		var shouldGoToBilling = payPremiumEnabledBrands.indexOf(brand.current.sharedBrandCode) !== -1;
		var hidePayPremium = null;
		var directPay = sessionData.memberInfo.clientList[0].groupList[0].directPayIndicator;
		var medicare = sessionData.memberInfo.clientList[0].groupList[0].medicareIndicator;
		if(directPay === "true" || medicare === "true" && shouldGoToBilling){

			hidePayPremium = false;
		}
		else{
			hidePayPremium = true;
		}


		services.rbsmbl.ebillUserExists().getTargetUrl(brand).then(
			function (response) {
				if (response) {
					var unregistered = response &&
						response.data &&
						response.data.payload &&
						response.data.payload.ebillAccountexists === "N";
					if(unregistered){
						$scope.sidebar.links.push({
							"state": "ebill",
							"label": "Pay Health Plan Premium",
							"externalLink": "Yes",
							"newWindow": true,
							"hide": hidePayPremium,
							"heading": "Pay Premium",
							"description": "You can pay your plan premium"
						});
					}
					else{
						$scope.sidebar.links.push({
							"label": "Pay Health Plan Premium",
							"href": "/emssvc/x-services/secure/sso/outbound/auto/v1/vendor/EBILL?cm_sp=Ebill-_-Pay%20Monthly%20Premium-_-PAY%20MONTHLY%20PREMIUM",
							"externalLink": "Yes",
							"newWindow": true,
							"hide": hidePayPremium,
							"heading": "Pay Premium",
							"description": "You can pay your plan premium"
						});
					}
				}
			},
			function (err) {
				$scope.sidebar.links.push({
					"state": "ebill",
					"label": "Pay Health Plan Premium",
					"externalLink": "Yes",
					"newWindow": true,
					"hide": hidePayPremium,
					"heading": "Pay Premium",
					"description": "You can pay your plan premium"
				});
			}

		);

		$scope.sidebar.links.push({
			"state": "claimsAndSpending.spendingAccounts",
			"label": "Spending Accounts",
			"hide": hideSpending,
			"heading": "Review Your Plan Activity",
			"description": "View Your Claims, check account balances and see your progress towards your deductible"
		});

		$scope.sidebar.links.push({
			"state": "claimsAndSpending.claimsHistory",
			"label": "Claims History",
			"heading": "Review Your Claims",
			"description": "See your progress towards your deductible and review information about your claims by date, plans or other members on your plan.",
			"refineTemplate": "pages/claims-and-spending/claims-history/refine.html",
			"refineController": "pages.claimsAndSpending.claimsHistory"
		});
		
		$scope.sidebar.links.push({
			"label": "Covered Expenses",
			"href": "https://tax.thomsonreuters.com/hcet-ebia/hmrk",
			"externalLink": "Yes",
			"newWindow": true,
			"coremetricsCategory": "MEMBER:CLAIMS AND SPENDING",
			"coremetricsElement": "COVERED EXPENSES"
		});

		// if (brand.current.sharedBrandCode === "mincr" ||brand.current.sharedBrandCode === "wybcbs" || $state.current.name === "claimsAndSpending.healthPlans") {
		// 	$scope.sidebar.links.push({
		// 		"heading": '<span data-teamsite="MBR_Claims_LeftNav_HealthPlansHeadline"></span>',
		// 		"label": "Health Plans",
		// 		"state": "claimsAndSpending.healthPlans",
		// 		"description": '<span data-teamsite="MBR_Claims_LeftNav_HealthPlansHeadlineDesc"></span>',
		// 		"refineTemplate": "pages/claims/healthPlansRefine.html",
		// 		"refineController": "claims.claimsAndPaymentsRefine"
		// 	});
		// }

		if (brand.current.sharedBrandCode === "mincr" ||brand.current.sharedBrandCode === "wybcbs") {
			$scope.sidebar.links.push({
                heading: '<span data-teamsite="MBR_Claims_LeftNav_HealthPlansHeadline"></span>',
                label: "Health Plans",
                href: "/mhs/#/healthPlans",
                description: '<span data-teamsite="MBR_Claims_LeftNav_HealthPlansHeadlineDesc"></span>'
			});
		}

		$scope.sidebar.links.push({
			"state": "claimsAndSpending.forms",
			"label": "Forms Library",
			"heading": "Find A Form",
			"description": "Find claim forms and other helpful resources.",
			"refineTemplate": "pages/claims-and-spending/forms-library/forms-refine.html"
		});

		$scope.sidebar.links.push({
			"state": "claimsAndSpending.costSavingTools",
			"label": "Cost-Saving Tools",
			"heading": "Cost-Saving Tools",
			"description": "Find ways to save on your health care expenses."
		});

		$scope.sidebar.links.push({
			"state": "claimsAndSpending.messageCenter",
			"label": "Messages",
			"heading": "Claims & Benefits Messages",
			"description": "Review important messages about your health plan, eligibility for preventive care and more.",
            "refineTemplate": 'pages/claims-and-spending/message-center/casMessageCenterRefine.html'
		});

		$scope.sidebar.links.push({
			"state": "claimsAndSpending.statementArchive",
			"label": "Statement Archive",
			"heading": "Recent Claims Statements",
			"description": "Access health plan statements. The information here is as of 10/16/17.",
			"refineTemplate": "pages/claims-and-spending/statement-archive/casStatementArchiveRefine.html"
		});

		var eduMaterialSuppress = false;
		if(brand.current.sharedBrandCode !== "mincr" ||brand.current.sharedBrandCode !== "wybcbs" || brand.current.sharedBrandCode !== "ndbcbs"){
            var homeHostClientNumber = [];
            teamsite.get("HOME_HOST_CLIENT_NUMBERS").then(function(content) {
                homeHostClientNumber = JSON.parse(content);
                var isHomeHostClientIndex = (homeHostClientNumber.indexOf($cookies.get("mbrClientNumber")) > -1);
                if(isHomeHostClientIndex){
                   var portalSelectedPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan();
                    for(var i = 0; i < portalSelectedPlan.optOutList.length; i++) {
                        var optOutListInfo = portalSelectedPlan.optOutList[i];
                        if(optOutListInfo.code === "904"){
                            eduMaterialSuppress = true;
                        }
                    }
                }
                $scope.sidebar.links.push({
                    "state": "claimsAndSpending.educationalMaterial",
                    "label": "Educational Material",
					"hide": eduMaterialSuppress,
                    "heading": "Review Available Educational Materials",
                    "description": "You can review the educational documents and videos here to learn more about your claims section and EOBs.",
                    "refineTemplate": "page/claims-and-spending/educational-material/casEducationalMaterialRefine.html"
                });
            });
        }

		$rootScope.$on('$stateChangeError', function (ev, to, toParams, from, fromParams) {
			if (to && to.name) {
				var cookieName = null;
				if (to.name === 'claims' || to.name.startsWith('claims.')) {
					cookieName = 'statePriorToClaims';
				} else if (to.name === 'spending' || to.name.startsWith('spending.')) {
					cookieName = 'statePriorToSpending';
				}

				if (cookieName !== null) {
					var priorState = $cookies.get(cookieName);
					if (priorState) {
						$cookies.remove(cookieName);
						$state.go(priorState);
					} else if (from && from.name && from.name !== "") {
						if (from.name.indexOf("claimsAndSpending") > -1) {
							window.location = "/mbr/#!/landing"
						} else {
							$cookies.put(cookieName, from.name); 
						}
					}
				}
			}
        });
	}]);


	require('./claims-and-spending/spendingAccounts')(app);
	require('./claims-and-spending/hsaGroupSelection')(app);
	require("./claims-and-spending/claims-history/claimsHistory")(app);
	require("./claims-and-spending/sales-demo/salesDemo")(app);
	require("./claims-and-spending/cost-saving-tools/casCostSavingTools")(app);
	require("./claims-and-spending/educational-material/casEducationalMaterial")(app);
	require("./claims-and-spending/statement-archive/casStatementArchive")(app);
	require("./claims-and-spending/message-center/casMessageCenter")(app);
    require('./claims/healthPlans')(app);
    require('./claims/healthPlansRefine')(app);

	return [
		{
			state: "claimsAndSpending",
			postPassword: true,
			url: "/claims-and-spending",
			appConfigMaintenanceApp: "CLAIMS_AND_SPENDING",
			appConfigMaintenanceKey: "MAINTENANCE",
			views: {
				"body@": {
					"template": bootstrap.templates['page-templates/categoryCustom.html'],
					"controller": "pages.claimsAndSpending"
				},
				"content@claimsAndSpending": {
					"template": require('./claims-and-spending/spendingAccounts.html'),
					"controller": "pages.claimsAndSpending.spendingAccounts"
				}
			},
			resolve: {
				claimsAndSpendingLogic:["services", "sessionData", "$q", "$state", "$window", "manualHsaService", "$cookies",
					function (services, sessionData, $q, $state, $window, manualHsaService, $cookies) {
					var deferred = $q.defer();					
					var currentPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan(sessionData.memberInfo);
					var salesDemoDD = $cookies.get('DDValue');	
					var clientNumber = $cookies.get("mbrClientNumber");	
					var theDate = new Date().getFullYear() + "-12-31";
					if (salesDemoDD) {
						deferred.resolve({"redirect": "claimsAndSpending.salesDemo"});
						//$state.go("claimsAndSpending.salesDemo");
					} else {
						services.rbsmbl.vendorDetails().getVendorDetails(clientNumber, theDate, currentPlan.groupNumber).then(function (response) {
							var vendorDetails = (response
								&& response.data
								&& response.data.payload)
									? response.data.payload : [];							
							for (var i=0; i < vendorDetails.length; i++) {
								if (vendorDetails[i].claimAndSpending) {
									if (currentPlan.spendingAccountExistsIndicator === "false"
										&& !manualHsaService.shouldUserSeeHSAPage(sessionData.memberInfo)) {
										deferred.resolve({"redirect": "claimsAndSpending.claimsHistory"});
									}
									deferred.resolve({"redirect": false});
								} else {
										$window.location = "/mbr/#!/landing";
										deferred.reject();
								}
							}
						}, function (error) {
							sessionData.vendorDetails = []
						});
	

					}
						return deferred.promise;
				}]
			}
		},
		{
			state: "claimsAndSpending.spendingAccounts",
			postPassword: true,
			url: "/spending-accounts",
			appConfigMaintenanceApp: "CLAIMS_AND_SPENDING",
			appConfigMaintenanceKey: "MAINTENANCE",
			views: {}
		},
		{
			state: "claimsAndSpending.hsaGroupSelection",
			postPassword: true,
			url: "/hsa-group-selection",
			appConfigMaintenanceApp: "CLAIMS_AND_SPENDING",
			appConfigMaintenanceKey: "MAINTENANCE",
			params: { hsaGroups: [] },
			views: {
				"body@": {
					"template": require('./claims-and-spending/page-templates/detailOneColumn.html'),
					"controller": "pages.claimsAndSpending"
				},
				"content@claimsAndSpending.hsaGroupSelection": {
					"template": require('./claims-and-spending/hsaGroupSelection.html'),
					"controller": "pages.claimsAndSpending.hsaGroupSelection"
				}
			}
		},
		{
			state: "claimsAndSpending.claimsHistory",
			postPassword: true,
			url: "/claims-history",
			appConfigMaintenanceApp: "CLAIMS_AND_SPENDING",
			appConfigMaintenanceKey: "MAINTENANCE",
			views: {
				"content@claimsAndSpending": {
					template: require("./claims-and-spending/claims-history/claimsHistory.html"),
					controller: "pages.claimsAndSpending.claimsHistory"
				}
			}
		},
		{
			state: "claimsAndSpending.salesDemo",
			postPassword: true,
			url: "/sales-demo",
			appConfigMaintenanceApp: "CLAIMS_AND_SPENDING",
			appConfigMaintenanceKey: "MAINTENANCE",
			views: {
				"content@claimsAndSpending": {
					template: require("./claims-and-spending/sales-demo/salesDemo.html"),
					controller: "pages.claimsAndSpending.salesDemo"
				}
			}
		},
		{
			state: "claimsAndSpending.forms",
			postPassword: true,
			url: "/forms",
			    resolve: {
                resourcesList: ["contentResourceServices", function(contentResourceServices){
                    return contentResourceServices.getResourceIdListAsync();
                }]
            },
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/category.html"],
                    controller: "pages.claimsAndSpending"
                },
                "content@claimsAndSpending.forms": {
                    template:
                    "<section class='spending-forms' data-coremetrics-pageview='CLAIMS AND SPENDING:FORMS 1' data-coremetrics-category='MEMBER: CLAIMS AND SPENDING'>"+
                    "<h2>Forms Library</h2>"+
                    "<div data-ng-if='!session.loggedIn'>"+
                    "<div class='margin-top'  data-teamsite='MBR_SPENDING_FORMS_PRE_PASSWORD as content' data-ng-bind-html='content'></div>"+
                    "</div>"+
                    "<div data-ng-if='session.loggedIn'>"+
                    "<div class='margin-top' data-teamsite='MBR_SPENDING_FORMS_POST_PASSWORD as content' data-ng-bind-html='content'></div>"+
                    "</div>"+
                    "<div class='margin-top' data-ng-if='loading'>Loading...</div>"+
                    "<div class='margin-top' data-ng-if='!resources.length && !loading'>No forms available</div>"+
                    "<div class='margin-top' data-ng-if='resources.length > 0'>" +
                    "<h3>Recommended Items ({{filteredResources.length}})</h3>" +
                    "<ul class='margin-top' >" +
                    "<li data-drawer='item.open' data-ng-repeat='item in filteredResources = (resources | filter:sidebar.keywordRefine.value | orderBy:\"+title\") | pagination: {page: pagination.page, itemsPerPage: pagination.itemsPerPage}'>" +
                    "<div data-drawer-header role='button' tabindex='0' aria-expanded={{item.open}}>" +
                    "<div class='margin-bottom'>" +
                    "<button class='margin-bottom'>" +
                    "<span data-ng-if='item.type==\"video\"' class='hm-icon icon-video'></span>"+
                    "<span data-ng-if='item.type==\"pdf\"' class='hm-icon icon-product-info'></span>"+
                    "{{item.title}}</button>" +
                    "</div>"+
                    "</div>" +
                    "<div data-drawer-body>" +
                    "<div data-ng-if='item.description' class='margin-bottom'>" +
                    "<div data-ng-bind-html='item.description'></div>" +
                    "</div>" +
                    "<button type='button' data-ng-click='download(item);' class='margin-top download'>" +
                    "<span data-ng-if='item.type==\"video\"'><span class='hm-icon icon-video'></span> Download Video</span>"+
                    "<span data-ng-if='item.type==\"pdf\"'><span class='hm-icon icon-product-info'></span> Download PDF</span>"+
                    "</button>" +
                    "</div>" +
                    "</li>"+
                    "</ul>" +
                    "<div data-pagination-controls data-length='filteredResources.length' data-items-per-page='pagination.itemsPerPage' data-page='pagination.page'></div>"+
                    "</div>" +
                    "</section>",
                    controller: "pages.spending.forms"
                }
            }
        },
        {
        state: "claimsAndSpending.costSavingTools",
			postPassword: true,
			url: "/cost-saving-tools",
			resolve: {
				 resourcesList: ["contentResourceServices", function(contentResourceServices){
                    return contentResourceServices.getResourceIdListAsync();
                }]
			},
		views: {
                "body@": {
                    template: bootstrap.templates["page-templates/category.html"],
                    controller: "pages.claimsAndSpending"
                },
                "content@claimsAndSpending.costSavingTools": {
                    template: require("./claims-and-spending/cost-saving-tools/casCostSavingTools.html"),
                    controller: "pages.claimsAndSpending.casCostSavingTools"
                }
            }
        },
        {
			state: 'claimsAndSpending.messageCenter',
            appConfigMaintenanceApp: 'CLAIMS',
            appConfigMaintenanceKey: 'MESSAGE_CENTER_MAINTENANCE',
			url: '/messageCenter?filterType',
            params: { id: '' },
			views: {
				'content@claimsAndSpending': {
					template: require('pages/claims-and-spending/message-center/casMessageCenter.html'),
					controller: 'pages.claimsAndSpending.casMessageCenter'
				},
                'rightColumn@claims': {}
			}
		},
		 {
			state: 'claimsAndSpending.statementArchive',
            appConfigMaintenanceApp: 'CLAIMS',
            appConfigMaintenanceKey: 'STATEMENT_ARCHIVE_MAINTENANCE',
			url: '/statementArchives',
			views: {
				'content@claimsAndSpending': {
					template: require('pages/claims-and-spending/statement-archive/casStatementArchive.html'),
					controller: 'pages.claimsAndSpending.casStatementArchive'
				},
                'rightColumn@claims': {}
			}
		},
		 {
			state: 'claimsAndSpending.educationalMaterial',
            appConfigMaintenanceApp: 'CLAIMS',
            appConfigMaintenanceKey: 'EDUCATIONAL_MATERIAL_MAINTENANCE',
			url: '/educational-materials',
            resolve: {
                resourcesList: ["contentResourceServices", function(contentResourceServices){
					var CONTENT_TYPE = "Bluecore Resources";
                    return contentResourceServices.getResourceIdListAsync(CONTENT_TYPE);
                }]
            },
			views: {
				'content@claimsAndSpending': {
					template: require('pages/claims-and-spending/educational-material/casEducationalMaterial.html'),
					controller: 'pages.claimsAndSpending.casEducationalMaterial'
				},
                'rightColumn@claims': {}
			}
		},
        {
            state: 'claimsAndSpending.healthPlans',
            appConfigMaintenanceApp: 'CLAIMS',
            appConfigMaintenanceKey: 'HEALTH_PLANS_MAINTENANCE',
            url: '/healthPlans',
            views: {
                'content@claimsAndSpending': {
                    template: require('pages/claims/healthPlans.html'),
                    controller: 'pages.claims.healthPlans'
                },
                "rightColumn@claimsAndSpending": {
                    template: require('pages/claims/rightSidebar.html'),
                    controller: "pages.claimsAndSpending"
                }
            }
        },
		{
			state: 'ebill',
			url: '/ebill'
		}
	];
}