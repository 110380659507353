module.exports = function(app) {
    app.service('finder-logic2', ['$q', 'services', 'sessionData', 'finderService', 'teamsite', 'storage',
        function($q, services, sessionData, finderService, teamsite, storage) {
            var libStorage = {};
            libStorage.uniqueProducts = null;

            var theBrandCode = sessionData.memberInfo
                && sessionData.memberInfo.brand
                    ? sessionData.memberInfo.brand.toLowerCase() : '';

            var rxLinkage = {
                'preferredRxURL': teamsite.get("MBR_RX_PHARM_SEARCH_HREF_PREFERRED"),
                'preferredRxURL_MEDICARE_BPN' : teamsite.get("MBR_RX_PHARM_SEARCH_HREF_MEDICARE_PREFERRED_BPN"), 
                'preferredRxURL_BPN' : teamsite.get("MBR_RX_PHARM_SEARCH_HREF_PREFERRED_BPN"),
                'standardRxURL': teamsite.get("MBR_RX_PHARM_SEARCH_HREF_STANDARD")
            },
                rxTierLinks = {};

            $q.all(rxLinkage).then(
                function(content){
                    rxTierLinks.preferredRxURL = content.preferredRxURL;
                    rxTierLinks.preferredRxURL_MEDICARE_BPN =content.preferredRxURL_MEDICARE_BPN;
                    rxTierLinks.preferredRxURL_BPN = content.preferredRxURL_BPN;
                    rxTierLinks.standardRxURL = content.standardRxURL;
                },
                function(error){
                    console.log(error);
                }
            );

            var _findCurrentMemberZipCode = function(){
                services.rbsmbl.AccountSettingsRetrive()
                    .retriveAccountSettings(sessionData.groupNumber, "ccsAccountSettings", sessionData.memberInfo.brand, sessionData.memberInfo.userName)
                    .then(function(accountSettingsData) {
                        if (accountSettingsData && accountSettingsData.data && accountSettingsData.data.payload) {
                            sessionData.memberInfo.zipCode = accountSettingsData.data.payload.zip;
                        }
                    });
            };

            //CR00808428 - Pre password forms library page not loading - Adding conditions to call this method.
            if(sessionData.groupNumber && sessionData.memberInfo.brand && sessionData.memberInfo.userName){
                _findCurrentMemberZipCode();
            }

            this.newPVDLink = function () {
                var appConfigVal = ( sessionData && sessionData.appConfig && sessionData.appConfig.FIND_A_DOCTOR && sessionData.appConfig.FIND_A_DOCTOR.NEW_PVD_HREF && sessionData.appConfig.FIND_A_DOCTOR.NEW_PVD_HREF.booleanValue && sessionData.appConfig.FIND_A_DOCTOR.NEW_PVD_HREF.overrideValue ) ? sessionData.appConfig.FIND_A_DOCTOR.NEW_PVD_HREF.overrideValue : false;
                return appConfigVal;
            },

            this.showLongTerm = function () {
                var appConfigVal = ( sessionData && sessionData.appConfig && sessionData.appConfig.FIND_A_DOCTOR && sessionData.appConfig.FIND_A_DOCTOR.LONG_TERM_RX && sessionData.appConfig.FIND_A_DOCTOR.LONG_TERM_RX.booleanValue ) ? sessionData.appConfig.FIND_A_DOCTOR.LONG_TERM_RX.booleanValue : false;
                return appConfigVal;
            },

            this.showPreferredBPN = function (){
                var appConfigVal = ( sessionData && sessionData.appConfig && sessionData.appConfig.FIND_A_DOCTOR && sessionData.appConfig.FIND_A_DOCTOR.RX_PREFERRED_BPN && sessionData.appConfig.FIND_A_DOCTOR.RX_PREFERRED_BPN.booleanValue ) ? sessionData.appConfig.FIND_A_DOCTOR.RX_PREFERRED_BPN.booleanValue : false;
                return appConfigVal;
            },

            this.theDomain = function() {
                var theDomain = 'https://' + window.location.host;
                theDomain = theDomain.split('den').join('ten');
                return theDomain;
            },

            this.setFullMemberInfo = function(fmi) {
                libStorage.fullMemberInfo = fmi;
            },
            this.isInBlueEdgeGroup = function(medicalProd, dentalProd) {
                var isInGroup = false;
                // look for blueEdgeDentalIndv in Dental plan and in Medical plan
                isInGroup = (medicalProd && (medicalProd.blueEdgeDentalIndv == 'false')) ? true : isInGroup;
                isInGroup = (dentalProd && (dentalProd.blueEdgeDentalIndv == 'false')) ? true : isInGroup;
                return isInGroup;
            },
            this.getPharmacyBenefitProvider = function(psp) {
                var pharmacyBenefitProvider = (psp && psp.pharmacyBenefitProviderIndicator && psp.pharmacyBenefitProviderIndicator.length > 0) ?
                    psp.pharmacyBenefitProviderIndicator : null;
                return pharmacyBenefitProvider;
            },
            this.theyAreRxClaimOrCatalyst = function(psp) {
                var theyAre = false;
                var pharmacyBenefitProvider = (psp && psp.pharmacyBenefitProviderIndicator && psp.pharmacyBenefitProviderIndicator.length > 0) ?
                    psp.pharmacyBenefitProviderIndicator : null;
                var arrPharmacyProvider = ['Catalyst', 'RX Claim', 'RX Claim – Commercial']
                angular.forEach(arrPharmacyProvider, function(value, key) {
                    theyAre = (value == pharmacyBenefitProvider) ? true : theyAre;
                });
                return theyAre;
            },
            this.showingOneOrTwoIcons = function(arrIcons) {
                if (Array.isArray(arrIcons)) {
                    var trueValues = 0;
                    for (var i = 0; i < arrIcons.length; i++) {
                        trueValues = (arrIcons[i] == true) ? trueValues + 1 : trueValues;
                    }
                    var isPlural = (trueValues <= 2);
                    return isPlural;
                } else {
                    return false;
                }
            };
            this.getTeamsiteContent = function() {
                // storing the TS content IDs here for now
                // Banner ===============================================
                // MBR_FINDADOCTOR_BANNER_HEADLINE   Start your search
                // MBR_FINDADOCTOR_BANNER_ALT_TEXT   Banner Alt text
                // MBR_FINDADOCTOR_MEDICAL_ICON  Medical icon
                // MBR_FINDADOCTOR_PHARMACY_ICON  Pharmacy icon
                // MBR_FINDADOCTOR_VISION_ICON  Vision icon
                // MBR_FINDADOCTOR_DENTAL_ICON  Dental icon
                // Dental Modal stuff ===================================
                // MBR_FINDADOCTOR_BLUEEDGEDENTAL_HEADLINE  SEARCH FOR DENTISTS, ORTHODONTISTS AND MORE
                // MBR_FINDADOCTOR_BLUEEDGEDENTAL_TEXT     Check your open enrollment kit .... 2 paragraphs
                // MBR_FINDADOCTOR_IBCCORE_TEXT      If you have a PPO dental ...    3 paragraphs
                // MBR_FINDADOCTOR_IBCAH_TEXT    If you have a PPO dental ...     3 paragraphs
                // Write the ts call results to the console if on denv
                //                if (window.location.host.indexOf('denv') > -1) {
                //                    for (var x in data) {
                //                        // console.log(x + ' = ' + data[x])
                //                    }
                //                }
            };
            this.getDomainTheyAreLeaving = function(theBrand) {
                switch (theBrand) {
                    case 'hmbcbs':
                    case 'hbcbs':
                    case 'highmarkbcbs':
                        return 'highmarkbcbs.com';
                        break;
                    case 'hbs':
                    case 'pbs':
                    case 'hmbs':
                        return 'highmarkblueshield.com';
                        break;
                    case 'hmbcbswv':
                    case 'ms':
                    case 'highmarkbcbswv':
                    case 'mbh:wv':
                        return 'highmarkbcbswv.com';
                        break;
                    default:
                        return 'this website';
                        break;
                }
            },
            this.setTeamsiteContent = function(theScope) {
                theScope.dentalModal = {};


                //Begin - Content for Refine Block Items
                theScope.refine = {};
                theScope.refine.bdc = {};
                theScope.refine.bdc.nonBdcMemberTitle = "Find top-quality specialty care";
                theScope.refine.bdc.nonBdcMemberContent = "Search"; //"Search Blue Distinction Centers to find superior care in six key health areas:";
                theScope.refine.bdc.nonBdcMemberHrefTitle = "Blue Distinction Centers"; //"http://www.bcbs.com/innovations/bluedistinction/center-list/selector-map.html";
                theScope.refine.bdc.nonBdcMemberPostHrefContent = " to find superior care in six key health areas:"; //"Search Blue Distinction Centers to find superior care in six key health areas:";
                theScope.refine.bdc.nonBdcMemberToolTip = ["Bariatric Surgery", "Cardiac Care", "Complex and Rare Cancers", "Knee and Hip Replacement", "Spine Surgery", "Transplants"];
                theScope.refine.bdc.bdcMemberTitle = "Save on top-quality care";
                theScope.refine.bdc.bdcMemberContent = "Search"; //"Search Blue Distinction Centers to find superior care for certain high-risk, high-cost procedures. Visit My Benefits to learn how to save with your plan.";
                theScope.refine.bdc.bdcMemberHrefTitle = "Blue Distinction Centers"; //"Search Blue Distinction Centers to find superior care for certain high-risk, high-cost procedures. Visit My Benefits to learn how to save with your plan.";
                theScope.refine.bdc.bdcMemberPostHrefContent = "to find superior care for certain high-risk, high-cost procedures. Visit <a href='{{domain}}/bdc/index.html?group={{groupNumber}}&userName={{userAlias}}&targetSite={{brand}}'>My Benefits</a> to learn how to save with your plan."; //"Search Blue Distinction Centers to find superior care for certain high-risk, high-cost procedures. Visit My Benefits to learn how to save with your plan.";
                //End - Content for Refine Block Items
            },
            this.isIbcHcrOrExtraBasicOrPlus = function(theMember) {
                var itIs = false;
                var theCode = theMember.brand.toLowerCase();
                var arrIbc = ['ibcna', 'ibcah', 'ibc', 'ibccr', 'ibx'];
                var isIbc = (arrIbc.indexOf(theCode) > -1);
                var isHcr = false;

                var medicalProduct = this.portalSelectedPlanSpecificProduct('Medical');
                var isBlueExtraBasic = false;
                var isBlueExtraPlus = false;
                if (medicalProduct) {
                    isHcr = (medicalProduct.hcrProduct == 'ON' || medicalProduct.hcrProduct == 'OFF');
                    isBlueExtraBasic = (medicalProduct.isBlueExtraBasic == 'true');
                    isBlueExtraPlus = (medicalProduct.isBlueExtraPlus == 'true');
                }
                itIs = ((isIbc && isHcr) || isBlueExtraBasic || isBlueExtraPlus);
                return itIs;
            },
            this.setLibStorageAndReturnCurMember = function(fullMemberInfo) {
                if (libStorage.fullMemberInfo == null) {
                    libStorage.fullMemberInfo = fullMemberInfo;
                }
                libStorage.currentMember = services.rbsmbl.memberInfo().currentMember(libStorage.fullMemberInfo);
                libStorage.uniqueProducts = services.rbsmbl.memberInfo().getUniqueProducts(libStorage.fullMemberInfo);

                if (window.location.host.indexOf('denv') > -1) {
                    var ln = libStorage.uniqueProducts[0].productData.memberList[0].contact.lastName;
                    if (ln == 'DOE') {
                        alert('Looks like stubMode is ON.');
                    }
                }
                if (libStorage.currentMember) {
                    return libStorage.currentMember;
                } else {
                    return null;
                }
            },
            this.getPropertyByBrandCode = function(theBrandCode, theProperty) {
                // this used to be setMedicalIconUrl ------------------------------------------------
                //  switch (theProperty)    regionIsIbcCore
                theBrandCode = theBrandCode.toLowerCase(); // this may be redundant
                var isWpaCreWv = false;
                switch (theProperty) {

                    case 'regionIsDe':
                        switch (theBrandCode) {
                            case 'de':
                            case 'hmbcbsde':
                            case 'highmarkbcbsde':
                                return true;
                            default:
                                return false;
                                break;
                        }
                    case 'regionIsIbcCore':
                        switch (theBrandCode) {
                            case 'ibc':
                            case 'ibccr':
                            case 'ibx':
                                return true;
                                break;
                            default:
                                return false;
                                break;
                        }
                    case 'regionIsAnIbc':
                        switch (theBrandCode) {
                            case 'ibah':
                            case 'ibcah':
                            case 'ibc':
                            case 'ibccr':
                            case 'ibx':
                                return true;
                                break;
                            default:
                                return false;
                                break;
                        }
                    case 'regionIsWv':
                        switch (theBrandCode) {
                            case 'wv':
                            case 'ms':
                            case 'hmbcbswv':
                            case 'highmarkbcbswv':
                            case 'mbh:wv':
                                return true;
                                break;
                            default:
                                return false;
                                break;
                        }
                    case 'regionIsWpaCre':
                        switch (theBrandCode) {
                            case 'hmbcbs':
                            case 'hbcbs':
                            case 'highmarkbcbs':
                            case 'hbs':
                            case 'pbs':
                            case 'hmbs':
                                return true;
                                break;
                            default:
                                return false;
                                break;
                        }
                    case 'regionIsCre':
                        switch (theBrandCode) {
                            case 'hbs':
                            case 'pbs':
                            case 'hmbs':
                                return true;
                                break;
                            default:
                                return false;
                                break;
                        }
                    case 'regionIsWpa':
                        switch (theBrandCode) {
                            case 'hmbcbs':
                            case 'hbcbs':
                            case 'highmarkbcbs':

                                return true;
                                break;
                            default:
                                return false;
                                break;
                        }
                    case 'regionIsWpaCreWv':
                        switch (theBrandCode) {
                            case 'hmbcbs':
                            case 'hbcbs':
                            case 'highmarkbcbs':
                            case 'hbs':
                            case 'pbs':
                            case 'hmbs':
                            case 'wv':
                            case 'ms':
                            case 'hmbcbswv':
                            case 'highmarkbcbswv':
                            case 'mbh:wv':
                                return true;
                                break;
                            default:
                                return false;
                                break;
                        }
                    case 'regionIsMbh':
                        // var wi = ["wi"];
                        switch (theBrandCode) {
                            case 'az':
                            case 'la':
                            case 'idh':
                            case 'fl':
                            case 'mbh:fl':
                            case 'lou':
                            case 'neb':
                            case 'mn':
                                return true;
                                break;
                            default:
                                return false;
                                break;
                        }
                    case 'regionIsAllMbh':
                        switch (theBrandCode) {
                            case 'az':
                            case 'la':
                            case 'idh':
                            case 'fl':
                            case 'mbh:fl':
                            case 'lou':
                            case 'neb':
                            case 'mn':
                            case 'ibah':
                            case 'ibcah':
                            case 'ibc':
                            case 'hz':
                            case 'exc':
                            case 'regbcbs':
                            case 'regbs':
                            case 'wi':
                            case 'hhic':
                                return true;
                                break;
                            default:
                                return false;
                                break;
                        }

                    // *************************************************
                    case 'displayBCWorldWide':
                        //var displayBCWorldWide = false;
                        switch (theBrandCode) {
                            //WPA,CRE,WV
                            case 'hmbcbs':
                            case 'hbcbs':
                            case 'highmarkbcbs':
                            case 'hbs':
                            case 'pbs':
                            case 'hmbs':
                            case 'wv':
                            case 'ms':
                            case 'hmbcbswv':
                            case 'highmarkbcbswv':
                            case 'mbh:wv':

                            //DE
                            case 'de':
                            case 'hmbcbsde':
                            case 'highmarkbcbsde':

                            //IBC
                            case 'ibc':
                            case 'ibccr':
                            case 'ibx':

                            //MBH
                            case 'az':
                            case 'la':
                            case 'idh':
                            case 'fl':
                            case 'mbh:fl':
                            case 'lou':
                            case 'neb':

                            //MN - what are the cases for this?
                            case 'mincr':
                            case 'mn':
                                return true;
                                break;
                            case 'wybcbs':
                                return true;
                                break;
                            case 'ndbcbs':
                                return true;
                            //IBC AmeriHealth
                            case 'ibah':
                            case 'ibcah':
                                return false;
                                break;
                            default:
                                return false;
                                break;
                        }

                    case 'displayProviderCredentials':
                        switch (theBrandCode) {
                            case 'mincr':
                                return true;
                                break;
                            default:
                                return false;
                                break;
                        }

                    //TODO get brand codes MN
                    // *************************************************
                    case 'medicalIconTarget':
                        var medicalIconTarget;
                        switch (theBrandCode) {
                            case 'hmbcbs':
                            case 'hbcbs':
                            case 'highmarkbcbs':
                            case 'hbs':
                            case 'pbs':
                            case 'hmbs':
                            case 'de':
                            case 'hmbcbsde':
                            case 'highmarkbcbsde':
                                // o	/chmptl/chm/jsp/categorydetail.do?oid=434441&parentId=-13552&type=program
                                // ps://tenv6.highmarkbcbs.com/chmptl/chm/jsp/categorydetail.do?oid=434441&parentId=-13552&type=program
                                // medicalIconTarget = this.theDomain() + '/chmptl/chm/jsp/categorydetail.do?oid=434441&parentId=-13552&type=program'
                                //  https://tenv3.mybenefitshome.com/chmptl/chm/jsp/providerSearchLink.do
                                //  From Kash, 10/3                 /chmptl/chm/jsp/providerSearchLink.do
                                medicalIconTarget = (this.newPVDLink()) ? this.newPVDLink() : this.theDomain() + '/find-a-doctor/#/home'; ///chmptl/chm/jsp/providerSearchLink.do';
                                break;
                            case 'wv':
                            case 'ms':
                            case 'hmbcbswv':
                            case 'highmarkbcbswv':
                            case 'mbh:wv':
                                medicalIconTarget = (this.newPVDLink()) ? this.newPVDLink() : this.theDomain() + '/find-a-doctor/#/home'; //'/chmptl/chm/jsp/providerSearchLink.do';
                                break;
                            case 'fl':
                            case 'mbh:fl':
                            case 'az':
                            case 'la':
                            case 'idh':
                            case 'neb':
                            case 'mn':
                            case 'lou':
                                medicalIconTarget = (this.newPVDLink()) ? this.newPVDLink() : this.theDomain() + '/find-a-doctor/#/home'; // '/chmptl/chm/jsp/providerSearchLink.do';
                                break;
                            case 'ibcna':
                                medicalIconTarget = (this.newPVDLink()) ? this.newPVDLink() : this.theDomain() + '/find-a-doctor/#/home'; //'/chmptl/chm/jsp/providerSearchLink.do';
                                break;
                            case 'ibcah':
                                medicalIconTarget = 'https://psa.webmdhealth.com/provider.aspx?f=psaIBCHighmark100109&acp=IN11,IN25,IN26,IN27,IN28,IN29,IN30&xf=ibc100109';
                                break;
                            case 'ibc':
                            case 'ibccr':
                            case 'ibx':
                                medicalIconTarget = (this.newPVDLink()) ? this.newPVDLink() : this.theDomain() + '/find-a-doctor/#/home'; //'/chmptl/chm/jsp/providerSearchLink.do';
                                break;
                            case 'mincr':
                                medicalIconTarget = this.theDomain() + '/find-a-doctor/#/home'; //'/pvd/';
                                break;
                            case 'wybcbs':
                                medicalIconTarget = this.theDomain() + '/find-a-doctor/#/home'; //'/pvd/';
                                break;
                            case 'ndbcbs':
                                medicalIconTarget = this.theDomain() + '/find-a-doctor/#/home'; //'/pvd/';
                                break;
                            default:
                                medicalIconTarget = 'notAvailable';
                                break;
                        }
                        // console.log('the setMedicalIconUrl method was passed ' + theBrandCode + ' and returned ' + medicalIconTarget)
                        return medicalIconTarget;
                        break;
                    default:
                        alert('I dont recognize that property name');
                        break;

                    case 'regionIsMyCareNavigatorMbh':
                        // var wi = ["wi"];
                        switch (theBrandCode) {
                            case 'az':
                            case 'la':
                            case 'idh':
                            case 'fl':
                            case 'mbh:fl':
                            case 'ibc':
                            case 'lou':
                                return true;
                                break;
                            default:
                                return false;
                                break;
                        }

                }
            },
            this.currentActiveProducts = function() {
                if (libStorage.uniqueProducts) {
                    // console.log('94')

                    var prods = libStorage.uniqueProducts;
                    libStorage.activeProducts = [];
                    var milliServerDate = Date.parse(new Date()); // use serverDate( ) when that is ready in bts
                    for (var i = 0; i < prods.length; i++) {
                        var millisBeginDate = Date.parse(prods[i].productData.coverageConBeginDate);
                        var millisEndDate = Date.parse(prods[i].productData.coverageEndDate);
                        var coverageIsCurrent = false;
                        coverageIsCurrent = ((millisBeginDate < milliServerDate) && (milliServerDate < millisEndDate)); // 12/31/2014
                        if (coverageIsCurrent) libStorage.activeProducts.push(prods[i].productData);
                    }
                    // console.log('activeProducts')
                    // console.log(libStorage.activeProducts);
                    return libStorage.activeProducts;
                } else {
                    return null;
                }
            },
            this.specificActiveProduct = function(theBenefitPlanType) {
                if (libStorage.activeProducts && libStorage.activeProducts.length && libStorage.activeProducts.length > 0) {
                    var theProduct = null;
                    var prods = libStorage.activeProducts;
                    for (i = 0; i < prods.length; i++) {
                        if (prods[i].benefitPlanType == theBenefitPlanType)
                            theProduct = prods[i];
                    }
                } else {
                    return null;
                }
                return theProduct;
            },

            this.getPortalSelectedPlan = function() {
                if (sessionData.memberInfo) {
                    var fm = libStorage.fullMemberInfo;
                    var psp = services.rbsmbl.memberInfo().getPortalSelectedPlan(fm);
                    //        services.rbsmbl.memberInfo().getPortalSelectedPlan(libStorage.fullMemberInfo);

                    if (psp) {
                        return psp;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            },
            this.portalSelectedPlanActiveProducts = function() {
				var psp = this.getPortalSelectedPlan();
                if (psp && psp.productList) {
                    var prods = psp.productList;
                    libStorage.portalSelectedPlanActiveProducts = [];
                    var milliServerDate = Date.parse(new Date()); // use serverDate( ) when that is ready in bts
                    for (var i = 0; i < prods.length; i++) {
                        if(prods[i].coverageConBeginDate){
                            var millisBeginDate = Date.parse(prods[i].coverageConBeginDate);
                        }else {
                            var millisBeginDate = Date.parse(prods[i].coverageBeginDate);
                        }

                        var millisEndDate = Date.parse(prods[i].coverageEndDate);
                        var coverageIsCurrent = false;
                        coverageIsCurrent = ((millisBeginDate < milliServerDate) && (milliServerDate < millisEndDate)); // 12/31/2014
                        if (coverageIsCurrent) {
                            libStorage.portalSelectedPlanActiveProducts.push(prods[i]);
                        }
                    }
                    return libStorage.portalSelectedPlanActiveProducts;
                } else {
                    return null;
                }
            },
            this.portalSelectedPlanProducts = function() {
				var psp = this.getPortalSelectedPlan();
                if (psp && psp.productList) {
                    var prods = psp.productList;
                    libStorage.portalSelectedPlanProducts = [];
                    var milliServerDate = Date.parse(new Date()); // use serverDate( ) when that is ready in bts
                    for (var i = 0; i < prods.length; i++) {
                        libStorage.portalSelectedPlanProducts.push(prods[i]);
                    }
                    return libStorage.portalSelectedPlanProducts;
                } else {
                    return null;
                }
            },
            this.portalSelectedPlanSpecificProduct = function(theBenefitPlanType) {
                if (this.portalSelectedPlanProducts() && this.portalSelectedPlanProducts().length > 0) {
                    var theProduct = null;
                    var prods = libStorage.portalSelectedPlanProducts;
                    for (i = 0; i < prods.length; i++) {
                        if (prods[i].benefitPlanType == theBenefitPlanType)
                            theProduct = prods[i];
                    }
                } else {
                    return null;
                }
                return theProduct;
            },
                this.portalSelectedPlanWellnessProduct = function() {
                    if (this.portalSelectedPlanProducts() && this.portalSelectedPlanProducts().length > 0) {
                        var theProduct = [];
                        var prods = libStorage.portalSelectedPlanProducts;
                        for (i = 0; i < prods.length; i++) {
                            if (prods[i].benefitPlanType == "Wellness Programs")
                                theProduct.push(prods[i]);
                        }
                    } else {
                        return null;
                    }
                    return theProduct;
                },
            this.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate = function(theBenefitPlanType) {
                if (this.portalSelectedPlanProducts() && this.portalSelectedPlanProducts().length > 0) {
                    var theProduct = null;
                    var prods = libStorage.portalSelectedPlanProducts;
                    var millisBeginDate = null;
                    var latestMillisBeginDate = null;
                    for (i = 0; i < prods.length; i++) {
                        if (prods[i].benefitPlanType == theBenefitPlanType)
                            millisBeginDate = Date.parse(prods[i].coverageConBeginDate);
                        if (millisBeginDate > latestMillisBeginDate) {
                            theProduct = prods[i];
                            latestMillisBeginDate = millisBeginDate;
                        }
                    }
                } else {
                    return null;
                }
                return theProduct;
            },

            this.isBlueExtra = function() {
                var blueExtra = false;
                if (this.portalSelectedPlanProducts() && this.portalSelectedPlanProducts().length > 0) {
                    var prods = libStorage.portalSelectedPlanProducts;
                    for (i = 0; i < prods.length; i++) {
                        if (prods[i].isBlueExtraBasic == "true" || prods[i].isBlueExtraPlus == "true") {
                            blueExtra = true;
                        }
                    }
                }
                return blueExtra;
            },

            this.getBrandCode = function() {

            },

            this.newRxLinkBrands = function() {
                switch (theBrandCode) {
                    case 'hmbcbs':
                    case 'hbcbs':
                    case 'highmarkbcbs':
                        return 'wpa';
                        break;
                    case 'hbs':
                    case 'pbs':
                    case 'hmbs':
                        return 'cpa';
                        break;
                    case 'hmbcbswv':
                    case 'ms':
                    case 'highmarkbcbswv':
                    case 'mbh:wv':
                        return 'wv';
                        break;
                    case 'hmbcbsde':
                    case 'highmarkbcbsde':
                    case 'de':
                        return 'de';
                        break;  
                    default:
                        return 'noNewRxLinks';
                        break;
                }
            },

            this.hasSeniorProduct = function() {
                var hasIt = false;
                if (this.portalSelectedPlanProducts()) {
                    var theProductList = this.portalSelectedPlanProducts();
                    for (var i = 0; i < theProductList.length; i++) {
                        var theValue = theProductList[i].seniorProductIndicator;
                        hasIt = ((theValue == true) || (theValue == 'true')) ? true : hasIt;
                    }
                }
                return hasIt;
            },

            this.isOver65 = function () {
                var dataDate = (sessionData &&
                                sessionData.memberInfo &&
                                sessionData.memberInfo.clientList[0] &&
                                sessionData.memberInfo.clientList[0].groupList[0] &&
                                sessionData.memberInfo.clientList[0].groupList[0].productList[0] &&
                                sessionData.memberInfo.clientList[0].groupList[0].productList[0].memberList[0] &&
                                sessionData.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact &&
                                sessionData.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.birthDate)
                                ? sessionData.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.birthDate : null,
                    theBirthDate = dataDate;
                    if (theBirthDate) {
                        var dd = new Date(theBirthDate),
                            ageDifMs = Date.now() - dd.getTime(),
                            ageDate = new Date(ageDifMs),
                            ageNumber = Math.abs(ageDate.getUTCFullYear() - 1970);
                        return (ageNumber >= 65);
                    } else {
                        // If we don't have a birthdate in the data, we'll return true, so that the hasSeniorProduct check can make the decision
                        return true;
                    }
            },
            this.showPreferred = this.showPreferredBPN();
            this.isNewRxLinkBrand = function() {
                var deMedicare = this.showPreferred && this.newRxLinkBrands () === "de" ? true : false;
                return (this.isMedicare && (this.newRxLinkBrands() === "wpa" || this.newRxLinkBrands() === "cpa" || this.newRxLinkBrands() === "wv" || deMedicare));
            },

            this.isWpaNewRx = (this.newRxLinkBrands() === "wpa"),
            this.isCpaNewRx = (this.newRxLinkBrands() === "cpa"),
            this.isWvNewRx = (this.newRxLinkBrands() === "wv"),

            this.isMedicare = this.hasSeniorProduct(),
            this.isNewRx = this.isNewRxLinkBrand(),

            this.WpaPreferredRx = (this.isWpaNewRx && this.isPreferredRx),
            this.CpaWvPreferredRx = ((this.isCpaNewRx || this.isWvNewRx) && this.isPreferredRx),

            // TODO: get the indicator from the service
            this.isPreferredRx = sessionData.medicarePrefValPharmNetInd,
            this.isBroadPerformance = this.getPortalSelectedPlan() && this.getPortalSelectedPlan().broadPerformanceNetInd === "true" ? true : false;
            this.newRxHREF = function () {
                if(this.showPreferred){
                    if(this.isPreferredRx && this.isBroadPerformance){
                        return rxTierLinks.preferredRxURL_MEDICARE_BPN;
                    }
                     else if(this.isPreferredRx && !this.isBroadPerformance && this.newRxLinkBrands() !== "de"){
                        return rxTierLinks.preferredRxURL;
                    }
                     else if(!this.isPreferredRx && this.isBroadPerformance){
                       return rxTierLinks.preferredRxURL_BPN;
                    }
                   // isPreferredRx=No and isBroadPerformance=No,it will redirect to standard
                    else{
                      return rxTierLinks.standardRxURL;
                    }
                }
                else{
                return this.isPreferredRx ? rxTierLinks.preferredRxURL : rxTierLinks.standardRxURL;
                }
            },

            this.setupFindAPharmacy = function(currentMedicalProduct, theBrand) {
                // returns an object with showFindAPharmacyMod, pharmacyTarget, and usePharmFunction;

                var returnObj = {};
                var theDomain = this.theDomain();
                var regionIsWpaCpaMnNatEtAl = (this.getPropertyByBrandCode(theBrand, 'regionIsWpaCreWv') ||
                this.getPropertyByBrandCode(theBrand, 'regionIsDe') ||
                this.getPropertyByBrandCode(theBrand, 'regionIsMbh') ||
                theBrand == 'mn');
                var regionIsAnIbc = this.getPropertyByBrandCode(theBrand, 'regionIsAnIbc');
                var regionIsMnBcbs = (theBrand == 'mincr');

                var regionIsWYBCBS = (theBrand == 'wybcbs');

                var regionIsNDBCBS = (theBrand == 'ndbcbs');

                var selectedPlanDrugProduct = this.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate('Drug');
                var selectedPlanMedicalProduct = this.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate('Medical');
                var hasDrugCoverageProperty = (selectedPlanMedicalProduct &&  selectedPlanMedicalProduct.drugCoverage && selectedPlanMedicalProduct.drugCoverage == 'true') ? true : false
                var hasIntegratedDrugCov = (selectedPlanMedicalProduct && selectedPlanMedicalProduct.integratedCoverageIndicator && hasDrugCoverageProperty) ? true : false;
                var currentDrugCoverage = (selectedPlanDrugProduct && selectedPlanDrugProduct !== null && selectedPlanDrugProduct !== undefined);

                var showFindAPharmacyMod = (currentDrugCoverage || hasIntegratedDrugCov) ? true : false;

                // pds 9/23/15: We uncommented the following block cause Kash is saying the service will not
                //              necessarily be ready for all regions on 10/9 release due to express scripts issues
                var usePharmFunction = false;
                // on landing page JIMCOV has this target
                // medco  /chmptl/chm/jsp/medco/medco.jsp?typeMEDCO=mailrx
                // https://tenv3.highmarkbcbs.com/chmptl/chm/jsp/medco/medco.jsp?typeMEDCO=mailrx&cm_sp=Medco-_-Prescription%20Services%20Medco-_-PRESCRIPTION%20SERVICES
                var pharmacyTarget = null;
                if (showFindAPharmacyMod) {
                    // handle MN ----------------------------------------
                    if (regionIsMnBcbs) {
                        // we make the click go to $scope.funcs.categoryIconFunction('pharmacy')
                        usePharmFunction = true;
                    }
                    if (regionIsWpaCpaMnNatEtAl) {
                        // pharmacyTarget = theDomain + '/singleSignon/v1/prescriptionServices';  /chmptl/chm/jsp/medco/medco.jsp?typeMEDCO
                        pharmacyTarget = theDomain + '/chmptl/chm/jsp/medcoRouter.do?typeMEDCO=locatepharmacy';
                    }
                    if (regionIsAnIbc) {
                        // https://denv3service.ibx.com/chmptl/chm/jsp/singleSignOn.do?vendor=catamaran
                        pharmacyTarget = theDomain + '/chmptl/chm/jsp/singleSignOn.do?vendor=catamaran';
                    }
                    if (this.isNewRx) {
                        usePharmFunction = true;
                    }
                    if(regionIsWYBCBS) {
                        //pharmacyTarget = 'https://qa.myprime.com/en/error/single-sign-on-error.html?error=INVALID_SINGLE_SIGN_ON';
                        usePharmFunction = true;
                    }
                }

                if (this.isNewRx) {
                    pharmacyTarget = null;
                }
                if(regionIsNDBCBS) {
                   // pharmacyTarget = 'https://qa.myprime.com/en/error/single-sign-on-error.html?error=INVALID_SINGLE_SIGN_ON';
                    usePharmFunction = true;
                }

                returnObj.showFindAPharmacyMod = showFindAPharmacyMod;
                returnObj.pharmacyTarget = pharmacyTarget;
                returnObj.usePharmFunction = usePharmFunction;
                return returnObj;
            },
            this.displayPharmacyNetwork = function(regionCode, theBrand) {
                switch (regionCode) {
                    case 'de':
                        return this.getPropertyByBrandCode(theBrand, 'regionIsDe');
                    case 'wv':
                        return this.getPropertyByBrandCode(theBrand, 'regionIsWv');
                    case 'cpa':
                        return this.getPropertyByBrandCode(theBrand, 'regionIsCre');
                    case 'wpa':
                        return this.getPropertyByBrandCode(theBrand, 'regionIsWpa');
                    case 'mbh':
                        return this.getPropertyByBrandCode(theBrand, 'regionIsAllMbh');
                    default:
                        return false;
                }
            },
            //see resources/pharmacyLinksTeamSite.json for the teamsite content of pharmacy links
            this.getPharmacyNetworks = function(data, theBrand) {
                var pharmacyLinks = [];
                var network = {};
                for (var j = 0; j < data.networks.length; j++) {
                    network = data.networks[j];
                    for (var i = 0; i < data[network].regions.length; i++) {
                        var region = data[network].regions[i];
                        if (this.displayPharmacyNetwork(region, theBrand)) {
                            pharmacyLinks.push({
                                network: data[network]
                            });
                            break;
                        }
                    }
                }
                return pharmacyLinks;
            },
            /************************
             REFINE BLOCK ITEMS
             *************************/
            this.getCastlightUrl = function(){
                var targetCastlightUrl = this.theDomain() + "/emssvc/x-services/secure/sso/outbound/auto/v1/vendor/CASTLIGHT";

                return targetCastlightUrl;
            },
            this.getCCEUrl = function() {
                var targetCCEUrl = this.theDomain() + "/cce/landing-page.html?gn={{groupNumber}}&ed={{effectiveDate}}";
                var groupNumber = this.getPortalSelectedPlan().groupNumber;
                var product = this.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate('Medical');
                var effectiveDate = null;
                if (product) {
                    effectiveDate = product.coverageConBeginDate;
                }

                //change the effectiveDate to MMDDYYYY from MM/DD/YYYY
                effectiveDate = effectiveDate && effectiveDate.replace(/\//gi, '');

                //replace placeholders with actual values
                targetCCEUrl = targetCCEUrl.split('{{groupNumber}}').join(groupNumber);
                targetCCEUrl = targetCCEUrl.split('{{effectiveDate}}').join(effectiveDate);

                return targetCCEUrl;
            },
            this.getSapphireSSOUrl = function(brandCode){
                var qs = location.search;
                var portalType = (qs.search(/from=employer/gi) > -1) ? "employer" : "member";
                var bcPrefix = (portalType == "employer") ? "e" : (qs.search(/medicare=yes/gi) > -1)? "m" : "";
                return '/rbsmbl/x-services/public/sapphire/sso/' + bcPrefix + brandCode + '/' + portalType + "?zip=" + sessionData.memberInfo.zip;
            },

            this.isAGOnlyMember = function() {

                var isAGOnlyCoverage = true;

                var activeProds = this.portalSelectedPlanActiveProducts();

                if ((activeProds) && (activeProds.length > 0)) {
                    for (var i = 0; i < activeProds.length; i++) {
                        if (activeProds[i].lob != "AG") {
                            isAGOnlyCoverage = false;
                        }

                    }
                } else {
                    if (this.portalSelectedPlanProducts()) {
                        var theProductList = this.portalSelectedPlanProducts();
                        for (var i = 0; i < theProductList.length; i++) {
                            if (theProductList[i].lob != "AG") {
                                isAGOnlyCoverage = false;
                            }
                        }
                    }
                }

                return isAGOnlyCoverage;
            },

            this.checkLOBs = function(lobsArr) {

                var doesLobsExist = false;

                if (lobsArr) {

                    var activeProds = this.portalSelectedPlanActiveProducts();

                    if ((activeProds) && (activeProds.length > 0)) {
                        for (var i = 0; i < activeProds.length; i++) {
                            for (var j = 0; j < lobsArr.length; j++) {
                                if (activeProds[i].lob == lobsArr[j]) {
                                    doesLobsExist = true;
                                }
                            }

                        }
                    } else {
                        if (this.portalSelectedPlanProducts()) {
                            var theProductList = this.portalSelectedPlanProducts();
                            for (var i = 0; i < theProductList.length; i++) {
                                for (var j = 0; j < lobsArr.length; j++) {
                                    if (theProductList[i].lob == lobsArr[j]) {
                                        doesLobsExist = true;
                                    }
                                }
                            }
                        }
                    }
                }

                return doesLobsExist;
            },

            //Refine Block Item2: My Care Navigator
            this.isMyCareNavigator = function(theBrandCode) {
                brandCode = theBrandCode.toLowerCase()
                //My care Navigator link to be shown for the regions WPA,CRE,WV,DE,MBH(LA;AZ;IDH;FL;IBCNAT)
                isCareNavigatorRegion = this.getPropertyByBrandCode(brandCode, 'regionIsWpaCreWv') || this.getPropertyByBrandCode(brandCode, 'regionIsDe') || this.getPropertyByBrandCode(brandCode, 'regionIsMyCareNavigatorMbh');
                //is >65
                var hasSeniorProduct = this.hasSeniorProduct();
                return (isCareNavigatorRegion) && !hasSeniorProduct;
            },



            //Refine Block Item3: BDC
            this.isBDC = function() {

                var isBdc = false;
                var umsBdc = ((this.getPortalSelectedPlan().bdcIndicator == 'true') || (this.getPortalSelectedPlan().bdcIndicator == true));
                var umsBdcPlus = ((this.getPortalSelectedPlan().bdcPlusIndicator == 'true') || (this.getPortalSelectedPlan().bdcPlusIndicator == true));

                if (umsBdc || umsBdcPlus) {
                    isBdc = true;
                }

                return isBdc;
            },

            this.processBDCContent = function(href) {

                var domain = 'https://' + window.location.host;
                var groupNumber = this.getPortalSelectedPlan().groupNumber;
                var userAlias = libStorage.fullMemberInfo.userName;
                var brand = libStorage.fullMemberInfo.brand.toLowerCase();

                //replace the placeholders with in bdc content
                href = href.split('{{domain}}').join(domain);
                href = href.split('{{groupNumber}}').join(groupNumber);
                href = href.split('{{userAlias}}').join(userAlias);
                href = href.split('{{brand}}').join(brand);

                return href;
            },

            //Refine Block Item4: Hospital Advisor
            //Hides for pre-effectuated users
            this.isHospitalAdvisor = function(psp) {
                var showHospitalAdvisor = true;

                for (var i = 0; i < psp.productList.length; i++) {
                    if (psp.productList[i].effectuatedDate && psp.productList[i].effectuatedDate != '') {

                        var currentMilliServerDate = Date.parse(new Date()); // use serverDate( ) when that is ready in bts
                        var effectuatedMilliServerDate = Date.parse(psp.productList[i].effectuatedDate);
                        var isEffectuated = (effectuatedMilliServerDate < currentMilliServerDate); // 12/31/2014

                        showHospitalAdvisor = (isEffectuated);
                        if (!showHospitalAdvisor) { // If any time it happens to be a preEffectuated user under any product, then just return false for Hospital Advisor
                            return showHospitalAdvisor;
                        }
                    }
                }
                return showHospitalAdvisor;
            },

            this.getHospitalAdvisorUrl = function(isExchangeUser) {
                //links point to prod sites
                var domainString = this.theDomain();
                //var region = domainString.substring(domainString.indexOf('.') + 1, domainString.length);
                /*if(isExchangeUser){
                 return domainString+ "/chmptl/chm/jsp/categorydetail.do?oid=248266&parentId=-13552&type=program"; //return "http://" + region + "/chmptl/chm/jsp/categorydetail.do?oid=248266&parentId=-13552&type=program";
                 }else{
                 return domainString+ "/chmptl/chm/jsp/webMDSingleSignOn.do?startId=550&hmLinkcodeValue=MBR_CHOOSE_PROVIDER&hmLinkName=Choose%20Providers"; //return "http://" + region + "/chmptl/chm/jsp/webMDSingleSignOn.do?startId=550&hmLinkcodeValue=MBR_CHOOSE_PROVIDER&hmLinkName=Choose%20Providers";
                 }*/

                return domainString + "/chmptl/chm/jsp/webMDSingleSignOn.do?startId=550&hmLinkcodeValue=MBR_CHOOSE_PROVIDER&hmLinkName=Find%20a%20Doctor";
            },
            this.isExchangeUser = function(psp) {
                var plan = psp;
                //console.log(plan.productList[0].hcrProduct.toLowerCase());
                if (plan.productList[0].hcrProduct.toLowerCase() == 'on' || plan.productList[0].hcrProduct.toLowerCase() == 'off') {
                    return true;
                }
                return false;
            }
        }
    ])
};