module.exports = function(app) {

    var bbb = require('../../pages/coverage/medical-benefits');

    app.directive('coverageVision', function() {
        return {
            restrict: 'A',
            template: require('directives/coverage-summary/visionCoverage.html'),
            controller: ['$scope', '$cookies', 'services', 'sessionData', 'finder-logic2', 'modal',
                function($scope, cookies, services, sessionData, finderLogic, modal) {

                    $scope.covType = "Vision";
                    $scope.isBlueBrandedVision = false;

                    $scope.isPlanCancelled = function (currentProd) {
                        var bRetVal = false;
                        var nMemberIdx = 0;
                        var mbr = currentProd.memberList[nMemberIdx];
                        if (mbr.coverageCancellationDate !== '') {
                            bRetVal = ((new Date(mbr.coverageCancellationDate)) < (new Date()));
                        }
                        return bRetVal;

                    };

                    var ums = sessionData.memberInfo;
                    var curClnt = ums.clientList[0];
                    var curGrp = finderLogic.getPortalSelectedPlan(ums);
                    var curPrd = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Vision");

                    var showOtherPlansLink = function () {
                        var medicalMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical");
                        var drugMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Drug");
                        var dentalMod = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Dental");

                        if ((medicalMod !== null) || (dentalMod !== null) || (drugMod !== null)) {
                            return false;
                        } else {
                            return true;
                        }
                    };

                    $scope.visionPlanName = curPrd.benefitPlanName;
                    $scope.isBlueBrandedVision = curPrd.isBlueBrandedVision == "true" ? true : false;

                    $scope.bMultiGroup = (showOtherPlansLink()) && (curClnt.groupList.length > 1);

                    $scope.bCancelled = false;
                    $scope.bCancelled = $scope.isPlanCancelled(curPrd);
                }
            ]
        };
    });
};
